import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@demandscience/ui';

import useMutateMember from 'components/Members/useMutateMember';
import { Account, BulkCredits, NotificationMessage } from 'types';
import CreditsUsage from './CreditsUsage';
import AvatarWithName from 'components/Account/AvatarWithName';
import useOrganization from 'components/Organization/useOrganization';
import useMutateCredits from './useMutateCredits';
import MessageInputController from 'components/Input/MessageInputController';
import CreditsQuotaInputController from 'components/Input/CreditsQuotaInputController';
import { AxiosError } from 'axios';

type AcceptCreditsRequestFormValues = {
  bulk_credits: BulkCredits;
  notification_message: NotificationMessage;
};

interface AcceptCreditsRequestFormProps {
  id: string;
  onCancel: () => void;
  onSuccess: () => void;
  user: Account;
}

const AcceptCreditsRequestForm = ({
  id,
  user,
  onSuccess,
  onCancel,
}: AcceptCreditsRequestFormProps) => {
  const { data: organization } = useOrganization(); // loading organization info to get total available credits

  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit, formState, watch } = useForm<AcceptCreditsRequestFormValues>({
    defaultValues: { bulk_credits: user.bulk_credits },
  });
  const { isSubmitting } = formState;
  const { update } = useMutateMember();
  const { acceptCreditsRequest } = useMutateCredits();
  const currentValue = watch('bulk_credits');

  const formBulkCredits = useMemo(() => {
    if (!organization) {
      return currentValue;
    }

    const { bulk_credits_usage: { available } = { available: 0 } } = organization;

    return {
      ...currentValue,
      available:
        currentValue.quota === -1 || currentValue.quota > available
          ? available
          : currentValue.quota - currentValue.used,
    };
    // only quta is changing and watch result can't be used a a dependency as it triggers no change
  }, [organization, currentValue.quota]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit: SubmitHandler<AcceptCreditsRequestFormValues> = async (data) => {
    setApiError(null);

    try {
      const { bulk_credits, notification_message } = data;

      await acceptCreditsRequest.mutateAsync({ id, notification_message });

      const memberUpdatePayload = {
        bulk_credits: { quota: bulk_credits.quota },
      };

      await update.mutateAsync({ id: user.username, ...memberUpdatePayload });

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="flex flex-col">
          <div className="py-3">
            <AvatarWithName user={user} />
          </div>
          <div className="py-2">
            <CreditsUsage bulkCredits={formBulkCredits} />
          </div>
          <div className="py-4">
            <div className="space-y-2">
              <div className="space-y-4">
                <CreditsQuotaInputController<AcceptCreditsRequestFormValues>
                  name="bulk_credits.quota"
                  control={control}
                />
                <MessageInputController<AcceptCreditsRequestFormValues>
                  name="notification_message"
                  control={control}
                />
              </div>

              {apiError && <div className="text-error-500 text-center">{apiError}</div>}
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-2 mt-6">
          <Button borderless onClick={onCancel} type="button">
            Cancel
          </Button>
          <Button type="submit" theme="primary" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AcceptCreditsRequestForm;
