import { Modal, ModalProps } from '@demandscience/ui';

import { SavedSearch, Visibility } from 'types';
import EditPersonaForm from './EditPersonaForm';
import usePersona from './usePersona';

interface EditPersonaOverlayProps extends Omit<ModalProps, 'children'> {
  data?: SavedSearch;
}

const EditPersonaOverlay = ({ data: defaultData, open, ...props }: EditPersonaOverlayProps) => {
  const { onClose } = props;

  // for restricted personas need to load personas by id in order to get shared_with property
  // given property is not present when persona is fetched as part of the list of personas
  const { data = defaultData, isFetching } = usePersona(defaultData?.id!, {
    enabled: defaultData?.visibility === Visibility.Restricted,
  });

  return (
    <Modal
      aria-labelledby="edit-search-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      open={open && !isFetching} // wait to open overlay after data is loaded
      {...props}
    >
      <Modal.Title id="edit-search-dialog-title" className="flex mb-4">
        Edit Persona
      </Modal.Title>
      <EditPersonaForm data={data} onSuccess={onClose} onCancel={onClose} />
    </Modal>
  );
};

export default EditPersonaOverlay;
