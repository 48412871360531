import { Button } from '@demandscience/ui';
import { useCallback, useEffect, useState } from 'react';
import OnboardingTooltip from './OnboardingTooltip';

interface OnboardingTooltipsNo2Props {
  onClose: () => void;
  open: boolean;
}

const OnboardingTooltipsNo2 = ({ open, onClose }: OnboardingTooltipsNo2Props) => {
  const [index, setIndex] = useState(0);

  const handleClose = useCallback(() => {
    setIndex(0);
    onClose();
  }, [onClose]);

  const handleNext = useCallback(() => {
    setIndex((state) => state + 1);
  }, []);

  useEffect(() => {
    if (open) {
      setIndex(1);
    } else {
      setIndex(0);
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <>
      <OnboardingTooltip
        anchorElId="dashboard_search_tabs_spacing"
        open={index === 1}
        onClose={handleClose}
        placement="top"
        title="Welcome to visual filtering"
        subtitle="Get a bird’s eye view of your market size along with firmographic and demographic breakdown."
        info="1 of 4"
        button={
          <Button size="sm" onClick={handleNext}>
            Next
          </Button>
        }
      />
      <OnboardingTooltip
        anchorElId="dashboard_data_profile_job_level_filter"
        open={index === 2}
        onClose={handleClose}
        placement="top"
        title="Define your market"
        subtitle="Select any criteria to update the market size and breakdown."
        info="2 of 4"
        button={
          <Button size="sm" onClick={handleNext}>
            Next
          </Button>
        }
        showArrow
      />
      <OnboardingTooltip
        anchorElId="dashboard_search_tabs"
        open={index === 3}
        onClose={handleClose}
        placement="bottom"
        title="List view"
        subtitle="View corresponding lists of contacts and companies."
        info="3 of 4"
        button={
          <Button size="sm" onClick={handleNext}>
            Next
          </Button>
        }
        showArrow
      />
      <OnboardingTooltip
        anchorElId="dashboard_search_filters_expand_button_right"
        open={index === 4}
        onClose={handleClose}
        placement="right"
        title="Show filters"
        subtitle="View entire list of filters."
        info="4 of 4"
        button={
          <Button
            size="sm"
            onClick={onClose}
            data-event="tutorial_complete"
            data-event-value="Visualise pipeline"
          >
            Finish
          </Button>
        }
        showArrow
      />
    </>
  );
};

export default OnboardingTooltipsNo2;
