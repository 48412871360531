import { Modal } from '@demandscience/ui';
import { HTMLAttributes } from 'react';
import AddExclusionListForm from './AddExclusionListForm';

export interface AddListOverlayProps extends HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  open: boolean;
}

const AddListOverlay = (props: AddListOverlayProps) => {
  const { onClose } = props;

  return (
    <Modal
      aria-labelledby="create-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="create-list-dialog-title" className="flex mb-4">
        Add a suppression list
      </Modal.Title>
      <AddExclusionListForm onSuccess={onClose} onCancel={onClose} />
    </Modal>
  );
};

export default AddListOverlay;
