import { useCallback, useState } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';

import { Link } from '@demandscience/ui';
import AuthSignin from 'components/Auth/Signin';
import AuthNewPasswordRequired from 'components/Auth/NewPasswordRequired';
import Heading from 'layouts/Auth/Heading';

import useSnackbar from 'components/Snackbar/useSnackbar';
import { Account, CodeDeliveryDetails } from 'types';
import { truncate } from 'lodash';

interface SigninProps {
  fromInvite?: boolean;
}

const Signin = ({ fromInvite }: SigninProps) => {
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const { showMessage } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSignin = useCallback(
    (user: Account) => {
      if (user.name) {
        showMessage(`Signed in as ${truncate(user.name, { length: 36 })}`, 'success');

        // if this is the 1st signin, show onboarding view
        if (user.number_of_signins === 1) {
          navigate('/welcome/onboarding', { replace: true });
        } else {
          const state = location.state as Record<string, any>;
          const from = state?.from || '/';

          navigate(from, { replace: true });
        }
      } else {
        navigate('/welcome', { replace: true });
      }
    },
    [location.state, navigate, showMessage],
  );

  const handleSignupConfirm = useCallback(
    (username: string, codeDeliveryDetails: CodeDeliveryDetails) => {
      const state = location.state as Record<string, any>;
      const from = state?.from;

      navigate('../signup/confirm', {
        replace: true,
        state: { codeDeliveryDetails, username, from },
      });
    },
    [location.state, navigate],
  );

  const handleEmailConfirm = useCallback(
    (codeDeliveryDetails: any) => {
      const state = location.state as Record<string, any>;
      const from = state?.from;

      navigate('../email/confirm', {
        state: { codeDeliveryDetails, from },
      });
    },
    [location.state, navigate],
  );

  const handleNewPasswordRequired = useCallback(() => {
    setNewPasswordRequired(true);
  }, []);

  if (newPasswordRequired) {
    return (
      <>
        <Heading
          title="Set a password"
          description="In order to secure your account, please enter a new password"
        />
        <AuthNewPasswordRequired onSignin={handleSignin} />
      </>
    );
  }

  return (
    <>
      <Heading title="Welcome to Klarity" description="Sign in to access your account" />
      <AuthSignin
        showPrivacyAndTerms={fromInvite}
        passwordHelperText={fromInvite ? 'Use the temporary password received by email' : undefined}
        onSignin={handleSignin}
        onSignupConfirm={handleSignupConfirm}
        onEmailConfirm={handleEmailConfirm}
        onNewPasswordRequired={handleNewPasswordRequired}
      />
      {!fromInvite && (
        <div className="mt-4 text-center">
          <Link id="action_forgot_password" as={RouterLink} to="../forgotPassword">
            Forgot password?
          </Link>
        </div>
      )}
    </>
  );
};

export default Signin;
