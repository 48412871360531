import { ContactFilterName, badgeDisplayCap } from 'types/filters';
import TextFilter from './TextFilter';
import { FilterProps } from './types';

const ContactNameFilter = (props: FilterProps) => {
  return (
    <TextFilter
      {...props}
      name={ContactFilterName.ContactName}
      placeholder="Enter contact name"
      showIncludeExclude
      multiple
      badgeDisplayCap={badgeDisplayCap}
    />
  );
};

export default ContactNameFilter;
