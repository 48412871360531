import { useQuery } from '@tanstack/react-query';

import { fetchMember } from 'api/organization';

const useMember = (id: string, options?: Record<string, any>) => {
  const query = useQuery(['members', id], () => fetchMember(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export default useMember;
