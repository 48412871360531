import TimeAgo from 'react-timeago';
import classNames from 'classnames';
import { useLists } from './useLists';
import { isEmpty, map, size } from 'lodash';
import { ListRecord, Visibility } from 'types';
import IconBox from 'components/Layout/IconBox';
import Section from 'components/Layout/Section';
import { Button, Link } from '@demandscience/ui';
import ListItem from 'components/Layout/ListItem';
import defaultListParams from './DefaultListParams';
import ListRecordActions from './ListRecordActions';
import SearchListControl from './SearchListControl';
import BadgeShared from 'components/Layout/BadgeShared';
import Paragraph from 'components/Typography/Paragraph';
import ListItemSkeleton from './Skeletons/ListItemSkeleton';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import OpenListControl from 'components/LinkedInExtension/OpenListControl';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import ListIcon from '@demandscience/ui/icons/list-02';
import PlusIcon from '@demandscience/ui/icons/plus';
import { stopPropagation } from 'utils/event';

const defaultParams = defaultListParams(3);

interface ListsShortListProps {
  title: string;
}

const ListsShortList = ({ title }: ListsShortListProps) => {
  const { data, isLoading } = useLists(defaultParams);
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Section title={title}>
        <div className="divide-y">
          <ListItemSkeleton count={3} />
        </div>
      </Section>
    );
  }

  const lists = data?.lists;

  const handleClick = (list: ListRecord) => () => {
    const { id } = list;
    navigate(`/lists/${id}`);
  };

  return (
    <Section title={title}>
      {isEmpty(lists) && (
        <EmptyResults
          message="You haven't created any lists yet"
          illustration={<Illustration.EmptyAlt className="w-16 h-16 mb-2" />}
          className="mt-4"
        >
          <Paragraph className="text-gray-500 mt-1 text-center whitespace-pre-line">
            {
              'Organize your contacts and companies of interests into\nlists for easy access and sharing.'
            }
          </Paragraph>
          <div className="flex items-center mt-4">
            <Button className="mt-4" size="md" borderless as={RouterLink} to="/lists">
              <PlusIcon size={18} className="mr-3" />
              <span>Create new list</span>
            </Button>
          </div>
        </EmptyResults>
      )}
      <div className="divide-y">
        {map(lists, (list) => (
          <ListItem
            key={list.id}
            role="button"
            onClick={handleClick(list)}
            leadingContent={
              <IconBox className="px-2 py-2 bg-amber-100">
                <ListIcon className="w-4 h-4 text-amber-500" />
              </IconBox>
            }
            title={
              <div className="flex flex-row items-center gap-2">
                <span>{list.name}</span>
                {list.visibility !== Visibility.Private && <BadgeShared title="Shared list" />}
              </div>
            }
            subtitle={<TimeAgo date={list.created_at} />}
            trailingContent={
              <div
                className="flex flex-row gap-2 items-center cursor-default"
                onClick={stopPropagation}
              >
                <SearchListControl list={list} />
                <SearchListControl list={list} showDataProfile />
                <OpenListControl list={list} />
                <ListRecordActions list={list} />
              </div>
            }
          />
        ))}
      </div>
      {!isEmpty(lists) && (
        <div className="pt-4 border-t flex flex-row items-center justify-between">
          <Link
            className={classNames('no-underline hover:underline', {
              invisible: size(lists) <= -1,
            })}
            as={RouterLink}
            to="../lists"
          >
            View all
          </Link>
        </div>
      )}
    </Section>
  );
};

export default ListsShortList;
