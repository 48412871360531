import { map, range } from 'lodash';
import { Skeleton } from '@demandscience/ui';

interface ListRecordsSkeletonProps {
  count: number;
}

const ListRecordsSkeleton = ({ count }: ListRecordsSkeletonProps) => {
  return (
    <div data-testid="list-records-skeleton" className="divide-y">
      {map(range(count), (index) => (
        <div key={index} className="flex justify-between items-center px-2 py-6">
          <div className="flex items-center space-x-6">
            <Skeleton variant="rectangle" width={5} height={5} className="rounded bg-gray-200" />
            <div className="flex space-x-2">
              <Skeleton variant="circle" width={10} height={10} className="bg-gray-200" />
              <div className="space-y-4">
                <Skeleton
                  variant="rectangle"
                  width={24}
                  height={3}
                  className="rounded bg-gray-200"
                />
                <Skeleton
                  variant="rectangle"
                  width={4}
                  height={4}
                  className="rounded bg-gray-200"
                />
              </div>
            </div>
          </div>
          <Skeleton variant="rectangle" width={64} height={3} className="rounded bg-gray-200" />
          <Skeleton variant="rectangle" width={6} height={3} className="rounded bg-gray-200" />
          <div className="space-y-2">
            <Skeleton variant="rectangle" width={36} height={3} className="rounded bg-gray-200" />
            <Skeleton variant="rectangle" width={28} height={3} className="rounded bg-gray-200" />
          </div>
          <div className="space-y-2">
            <Skeleton variant="rectangle" width={36} height={3} className="rounded bg-gray-200" />
            <Skeleton variant="rectangle" width={28} height={3} className="rounded bg-gray-200" />
          </div>
          <Skeleton variant="rectangle" width={2} height={8} className="rounded bg-gray-200" />
        </div>
      ))}
    </div>
  );
};

export default ListRecordsSkeleton;
