import Lists from 'components/Bounces/Lists';
import BounceIcon from '@demandscience/ui/icons/bounce';
import UploadBouncesControl from 'components/Bounces/UploadBouncesControl';

const Bounces = () => {
  return (
    <div className="max-w-3xl">
      <div className="text-sm text-gray-700">Report recorded bounces to Klarity for analysis.</div>
      <div className="mt-6 mb-10 flex items-center gap-4">
        <UploadBouncesControl leftIcon={<BounceIcon size={20} />}>
          Upload list of bounces
        </UploadBouncesControl>
      </div>
      <Lists title="Previously uploaded lists" />
    </div>
  );
};

export default Bounces;
