import { useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { fetchNotifications, markNotificationAsRead } from 'api/account';
import useAuth from 'components/Auth/useAuth';

const useNotifications = ({ count = 25, ...options }: Record<string, any> = {}) => {
  const [showReadNotifications, setShowReadNotifications] = useState(false);
  const queryClient = useQueryClient();

  const { user } = useAuth();

  const query = useInfiniteQuery(
    ['notifications', { count, showReadNotifications }],
    ({ pageParam: index = 0 }) =>
      fetchNotifications({ index, count, include_read: showReadNotifications }),
    {
      staleTime: 60 * 1000, // 1 minute
      refetchOnWindowFocus: true,
      ...options,
      enabled: !!user,
    },
  );

  const markAsRead = useMutation(markNotificationAsRead, {
    onSettled: () => {
      queryClient.invalidateQueries(['notifications']);
    },
  });

  return { showReadNotifications, setShowReadNotifications, query, markAsRead };
};

export default useNotifications;
