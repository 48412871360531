import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import Search from 'components/Input/Search';
import { Link, Pagination } from '@demandscience/ui';
import DataGridHeader from 'components/DataGrid/DataGridHeader';
import ListRecordDetailSidebar from './ListRecordDetailsSidebar';
import ListRecordsSkeleton from './Skeletons/ListRecordsSkeleton';
import useSelection from 'components/DataGrid/Selection/useSelection';
import { filter, findIndex, isEmpty, size } from 'lodash';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import { defaultPaginationPageSize } from './useListRecords';
import {
  Account,
  ListOrigin,
  ListStatus,
  Model,
  ModelId,
  ModelType,
  RowLayout,
  ViewLayout,
  Visibility,
} from 'types';
import ActionsProvider from 'components/ListRecords/ActionsProvider';
import Paragraph from 'components/Typography/Paragraph';
import useCustomization from 'components/SearchCustomization/useCustomization';
import DataGridListLayout from 'components/DataGrid/DataGridListLayout';
import DataGridTableLayout from 'components/DataGrid/DataGridTableLayout';
import { useLocation } from 'react-router-dom';

interface ListDetailsRecordsProps {
  className?: string;
  id: string;
  listRecords?: any;
  owner: Account;
  records_origin: ListOrigin;
  records_type: ModelType;
  status?: ListStatus;
  visibility: Visibility;
}

const ListDetailsRecords = ({
  id,
  records_origin,
  records_type,
  className,
  status,
  listRecords,
  owner,
  visibility,
}: ListDetailsRecordsProps) => {
  const {
    query: { data, isLoading, isFetching, isError, refetch },
    page,
    setPage,
    pageSize,
    sort,
    setSort,
    search,
    setSearch,
  } = listRecords;
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
  const {
    selection,
    selectionDisabled,
    batchSelection,
    add,
    remove,
    viewSelected,
    isSelected,
    limit,
  } = useSelection();

  const location = useLocation();
  const isListRecordsPage = location.pathname.startsWith('/lists');

  useUpdateEffect(() => {
    if (status === ListStatus.Completed) refetch();
  }, [status, refetch]);

  const { layout } = useCustomization();

  const handleSearchChange = useCallback(
    (value?: string) => {
      setSearch(value ?? '');
    },
    [setSearch],
  );

  const handleSeeAll = useCallback(() => {
    handleSearchChange();
  }, [handleSearchChange]);

  const searchSection = (
    <div className={classNames(['flex justify-end', className])}>
      <div className="w-64">
        <Search
          placeholder={`Search by ${records_type}`}
          value={search}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );

  const { hits: records, pagination } = data || {};

  const rows = viewSelected ? selection : records;
  const currentSelected = filter(rows, isSelected);
  const selectedRow = selectedRowIndex > -1 ? rows[selectedRowIndex] : undefined;

  const handleRowSelect = useCallback(
    (row: Model) => (selected: boolean) => {
      if (selected) {
        if (batchSelection) {
          remove(row);
        } else {
          add(row);
        }
      } else {
        if (batchSelection) {
          add(row);
        } else {
          remove(row);
        }
      }
    },
    [add, batchSelection, remove],
  );

  const handleDetailsShow = useCallback(
    (row: Model) => () => {
      const index = findIndex(rows, { dsid: row.dsid });
      setSelectedRowIndex(index);
    },
    [rows],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const handleSelectCurrentRows = useCallback(() => {
    if (batchSelection) {
      remove(records);
    } else {
      add(records);
    }
  }, [add, batchSelection, records, remove]);

  const handleClearCurrentRows = useCallback(() => {
    if (batchSelection) {
      add(records);
    } else {
      remove(records);
    }
  }, [add, batchSelection, records, remove]);

  const handleCheckboxChange = useCallback(
    (selected: boolean) => {
      if (selected) {
        handleSelectCurrentRows();
      } else {
        handleClearCurrentRows();
      }
    },
    [handleClearCurrentRows, handleSelectCurrentRows],
  );

  const handleDetailsClose = useCallback(() => {
    setSelectedRowIndex(-1);
  }, []);

  const isSelectedRow = useCallback(
    (row: ModelId, index: number) => isSelected(row, page * pageSize + index),
    [isSelected, page, pageSize],
  );

  const handleDetailsSelect = useCallback(
    (checked: boolean) => {
      if (checked && selectedRow) {
        if (batchSelection) {
          remove(selectedRow);
        } else {
          add(selectedRow);
        }
      } else {
        if (!selectedRow || !selectedRow.dsid) {
          return;
        }
        if (batchSelection) {
          add(selectedRow);
        } else {
          remove(selectedRow);
        }
      }
    },
    [add, batchSelection, remove, selectedRow],
  );

  const handlePrev = useCallback(() => {
    if (selectedRowIndex > 0) {
      setSelectedRowIndex(selectedRowIndex - 1);
    }
  }, [selectedRowIndex]);

  const handleNext = useCallback(() => {
    if (selectedRowIndex < size(records) - 1) {
      setSelectedRowIndex(selectedRowIndex + 1);
    }
  }, [records, selectedRowIndex]);

  const handleCombinedPaginationPageChange = useCallback(
    (_: any, value: number) => {
      setPage(value - 1);
    },
    [setPage],
  );

  if (isError) {
    return (
      <EmptyResults
        message="Unexpected error fetching the records."
        illustration={<Illustration.EmptyAlt className="w-40 h-40 mb-6" />}
      />
    );
  }

  if (isLoading || !data) {
    return (
      <>
        {searchSection}
        <ListRecordsSkeleton count={10} />
      </>
    );
  }

  if (records_origin === ListOrigin.Search && isEmpty(records) && pagination?.count) {
    return (
      <EmptyResults
        message="No record found matching the criteria within this list."
        illustration={<Illustration.EmptyAlt className="w-40 h-40 mb-6" />}
      />
    );
  }

  if (records_origin === ListOrigin.Search && search && isEmpty(records)) {
    return (
      <>
        {searchSection}
        <EmptyResults
          message="No matching record found"
          illustration={<Illustration.Empty className="w-36 h-36 mb-6" />}
        >
          <Paragraph className="text-gray-500 mt-1 text-center whitespace-pre-line">
            <Link aria-label="See all" onClick={handleSeeAll}>
              See all records
            </Link>
          </Paragraph>
        </EmptyResults>
      </>
    );
  }

  if (records_origin === ListOrigin.Search && !search && isEmpty(records)) {
    return (
      <EmptyResults
        message="You haven't added any records to this list."
        illustration={<Illustration.Empty className="w-36 h-36 mb-6" />}
      />
    );
  }

  if (isEmpty(records)) {
    return (
      <>
        {searchSection}
        <EmptyResults
          message="No matching result found."
          illustration={<Illustration.Empty className="w-40 h-40 mb-6" />}
        />
      </>
    );
  }

  return (
    <>
      {searchSection}
      <ActionsProvider
        listId={id}
        listOrigin={records_origin}
        owner={owner}
        visibility={visibility}
        modelType={records_type}
        sort={sort}
        advancedSelection={limit ? { limit } : undefined}
      >
        <div className="flex flex-col isolate pb-40">
          {!isLoading && !isEmpty(records) && (
            <DataGridHeader
              kind={records_type}
              selectCurrentPage={handleSelectCurrentRows}
              clearCurrentPage={handleClearCurrentRows}
              sort={sort}
              setSort={setSort}
              setPage={handlePageChange}
              loading={isFetching || status === ListStatus.Processing}
              checkboxProps={{
                checked: !isEmpty(currentSelected),
                onChange: handleCheckboxChange,
                checkType: size(currentSelected) === size(rows) ? 'check' : 'minus',
                disabled: selectionDisabled,
              }}
              paginationProps={{
                page,
                pageSize,
                setPage,
                count: pagination?.count,
              }}
            />
          )}
          {layout.view === ViewLayout.List && (
            <DataGridListLayout
              kind={records_type}
              rows={rows}
              isSelected={isSelectedRow}
              selectionDisabled={selectionDisabled}
              selectedRow={selectedRow}
              handleRowSelect={handleRowSelect}
              handleDetailsShow={handleDetailsShow}
              rowLayout={RowLayout.Wide}
            />
          )}
          {layout.view === ViewLayout.Table && (
            <DataGridTableLayout
              kind={records_type}
              rows={rows}
              isSelected={isSelectedRow}
              selectionDisabled={selectionDisabled}
              selectedRow={selectedRow}
              handleRowSelect={handleRowSelect}
              handleDetailsShow={handleDetailsShow}
              isList
            />
          )}
          <div
            className={classNames(
              `flex justify-end`,
              layout.view === ViewLayout.Table || isListRecordsPage ? '-mt-[210px]' : 'mt-5',
            )}
          >
            <Pagination.Combined
              page={page + 1}
              count={pagination?.count < 10000 ? pagination?.count : 10000}
              totalCount={pagination?.count}
              rowsPerPage={defaultPaginationPageSize}
              onPageChange={handleCombinedPaginationPageChange}
            />
          </div>
          <ListRecordDetailSidebar
            kind={records_type}
            open={Boolean(selectedRow)}
            row={selectedRow}
            onClose={handleDetailsClose}
            selected={selectedRow ? isSelectedRow(selectedRow, selectedRowIndex) : false}
            selectionDisabled={selectionDisabled}
            onSelectChange={handleDetailsSelect}
            onPrev={selectedRowIndex > 0 ? handlePrev : undefined}
            onNext={selectedRowIndex < size(rows) - 1 ? handleNext : undefined}
          />
        </div>
      </ActionsProvider>
    </>
  );
};

export default ListDetailsRecords;
