import { Modal, ModalProps, Button } from '@demandscience/ui';

import CreditsControl from 'components/Credits/CreditsControl';

interface NoCreditOverlayProps extends Omit<ModalProps, 'children'> {}

const NoCreditOverlay: React.FC<NoCreditOverlayProps> = (props) => {
  const { onClose } = props;

  return (
    <Modal
      aria-labelledby="no-credit-dialog-title"
      // className="max-w-md sm:max-w-md overflow-visible"
      // rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="no-credit-dialog-title" className="mb-4">
        Sorry, you have run out of credits
      </Modal.Title>
      <Modal.Description>
        Request more credits from your team manager to continue your prospecting.
      </Modal.Description>
      <div className="flex flex-row gap-2 justify-end mt-10">
        <Button size="md" borderless onClick={onClose}>
          Dismiss
        </Button>
        <CreditsControl size="md" theme="primary" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default NoCreditOverlay;
