import { AccountBase } from 'types';
import classNames from 'classnames';
import { avatarUrl } from 'api/avatar';
import UserIcon from '@demandscience/ui/icons/user';
import { Avatar as BaseAvatar, AvatarProps as BaseAvatarProps } from '@demandscience/ui';
import useAvatarUpdate from './useAvatarUpdate';

export interface Props extends BaseAvatarProps {
  user: Pick<AccountBase, 'username' | 'name' | 'has_avatar'>;
}

const Avatar = ({ className, user, ...props }: Props) => {
  const { username, name, has_avatar } = user ?? {};
  // LNG-266 fix, workaround to detect that avatar got updated
  const { data: time } = useAvatarUpdate(user?.username);
  let src;
  if (has_avatar) {
    src = avatarUrl(username);

    if (time) {
      src += `?_=${time}`;
    }
  }

  if (!user) {
    return null;
  }

  return (
    <BaseAvatar
      className={classNames(className, 'bg-gray-200 hover:bg-gray-200 text-gray-600')}
      alt={name}
      src={src}
      {...props}
    >
      <UserIcon />
    </BaseAvatar>
  );
};

export default Avatar;
