import { useResizeDetector } from 'react-resize-detector';
import SavedSearchesSection from 'components/SavedSearches/SavedSearchesSection';
import RecentSearchSection from 'components/RecentSearches/RecentSearchesSection';
import EmptySearches from './EmptySearches';
import { HTMLAttributes } from 'react';
import classNames from 'classnames';

const Searches = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const { height, ref } = useResizeDetector();

  // workaround to show EmptySearches component when no saved & recent search section is present
  // this is achived by checking height of sections container down, which becomes 0 when no search is rendered
  if (height === 0) {
    return <EmptySearches />;
  }

  return (
    <div
      className={classNames('max-w-lg w-full mx-auto space-y-20 mt-20', className)}
      ref={ref}
      {...props}
    >
      <SavedSearchesSection
        title="Saved searches"
        titleClassName="text-sm text-gray-600"
        hideCreateNew
        hideIfOnlyChild // this will hide given section if empty and no recent search section down rendered (no recent search)
      />
      <RecentSearchSection title="Recent searches" titleClassName="text-sm text-gray-600" />
    </div>
  );
};

export default Searches;
