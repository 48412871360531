import { memo } from 'react';
import SearchProvider from 'components/Search/SearchProvider';
import useCustomization from 'components/SearchCustomization/useCustomization';
import Tabs from './ResultTabs';
import useFilters from 'components/Filters/useFilters';
import { isEmpty } from 'lodash';
import EmptyResultTabs from './EmptyResultTabs';
import ModulesCustomizationProvider from 'components/ModulesCustomization/ModulesCustomizationProvider';

const Results = memo(() => {
  const {
    results: { contactsSortField, companiesSortField },
  } = useCustomization();

  const { filters } = useFilters();

  if (isEmpty(filters)) {
    return (
      <ModulesCustomizationProvider>
        <EmptyResultTabs />
      </ModulesCustomizationProvider>
    );
  }

  return (
    <SearchProvider contactsSortField={contactsSortField} companiesSortField={companiesSortField}>
      <ModulesCustomizationProvider>
        <Tabs />
      </ModulesCustomizationProvider>
    </SearchProvider>
  );
});

Results.displayName = 'Results';

export default Results;
