import { CopyOrMoveToList } from 'types';
import CopyToListForm from './CopyOrMoveActionForm';
import { Modal, ModalProps } from '@demandscience/ui';

interface CopyOrMoveActionOverlayProps extends CopyOrMoveToList, Omit<ModalProps, 'children'> {
  count: number;
  onSuccess: () => void;
}

const CopyOrMoveActionOverlay = ({
  method,
  listId,
  filters,
  sort,
  advanced_selection,
  onSuccess,
  type,
  count,
  ...props
}: CopyOrMoveActionOverlayProps) => {
  const { onClose } = props;

  return (
    <Modal
      {...props}
      aria-labelledby="copy-dialog-title"
      aria-describedby="copy-dialog-description"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
    >
      <Modal.Title id="export-dialog-title" className="mb-9">
        {method.charAt(0).toUpperCase() + method.substring(1)} to list
      </Modal.Title>
      <CopyToListForm
        listId={listId}
        onSuccess={onSuccess}
        onCancel={onClose}
        method={method}
        filters={filters}
        sort={sort}
        type={type}
        advanced_selection={advanced_selection}
        count={count}
      />
    </Modal>
  );
};

export default CopyOrMoveActionOverlay;
