const Logo = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="186"
    height="33"
    viewBox="0 0 186 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M49.2615 16.8802H54.0002C53.618 15.6573 52.7773 15.0459 51.6308 15.0459C50.4079 15.0459 49.5672 15.7337 49.2615 16.8802ZM56.6752 17.8738C56.6752 18.1031 56.6752 18.4088 56.5988 18.6381H49.2615C49.4908 19.9374 50.3315 20.7017 51.6308 20.7017C52.548 20.7017 53.3123 20.2431 53.6945 19.5553H56.4459C55.7581 21.6953 53.8473 22.9946 51.6308 22.9946C48.7265 22.9946 46.5864 20.7781 46.5864 17.8738C46.5864 14.9694 48.7265 12.753 51.6308 12.753C54.6116 12.8294 56.6752 15.1223 56.6752 17.8738Z"
      fill="#233857"
    />
    <path
      d="M72.7251 16.7274V22.8418H70.1265V17.1859C70.1265 15.8866 69.5915 15.1987 68.6743 15.1987C67.5279 15.1987 66.84 16.0395 66.84 17.5681V22.8418H64.2414V17.1859C64.2414 15.8866 63.7828 15.1987 62.7892 15.1987C61.5663 15.1987 60.8784 16.0395 60.8784 17.5681V22.8418H58.2798V13.0587H60.1141L60.5727 14.2816C61.2606 13.4409 62.3306 12.8294 63.477 12.8294C64.6999 12.8294 65.77 13.4408 66.305 14.358C66.9929 13.4408 68.0629 12.8294 69.2858 12.8294C71.273 12.8294 72.7251 14.4344 72.7251 16.7274Z"
      fill="#233857"
    />
    <path
      d="M95.8071 16.8802V22.7654H93.2085V17.2624C93.2085 15.8102 92.6735 15.0459 91.527 15.0459C90.1513 15.0459 89.3105 16.0395 89.3105 17.5681V22.6889H86.7119V13.0587H88.5462L89.0812 14.2816C89.8456 13.4409 90.992 12.8294 92.2913 12.8294C94.4314 12.8294 95.8071 14.4344 95.8071 16.8802Z"
      fill="#233857"
    />
    <path
      d="M113.845 23.0711C111.399 23.0711 109.794 21.6953 109.794 19.6317H112.393C112.393 20.4724 113.08 20.931 113.998 20.931C114.762 20.931 115.373 20.6253 115.373 19.9374C115.373 19.2495 114.533 19.0967 113.539 18.8674C112.01 18.6381 110.023 18.1795 110.023 15.8866C110.023 14.0523 111.552 12.753 113.921 12.753C116.291 12.753 117.819 14.0523 117.819 16.0395H115.297C115.297 15.2752 114.762 14.893 113.845 14.893C113.004 14.893 112.545 15.2752 112.545 15.8102C112.545 16.4216 113.386 16.5745 114.38 16.8038C115.985 17.1095 117.972 17.4152 117.972 19.7846C117.896 21.7717 116.291 23.0711 113.845 23.0711Z"
      fill="#D02C2F"
    />
    <path
      d="M124.316 23.0711C121.412 23.0711 119.119 20.8546 119.119 17.8738C119.119 14.9694 121.335 12.753 124.316 12.753C126.838 12.753 128.825 14.358 129.284 16.7273H126.685C126.227 15.8102 125.386 15.1987 124.316 15.1987C122.864 15.1987 121.794 16.3452 121.794 17.8738C121.794 19.4024 122.864 20.5489 124.316 20.5489C125.386 20.5489 126.227 19.9374 126.685 18.9438H129.36C128.902 21.3896 126.838 23.0711 124.316 23.0711Z"
      fill="#D02C2F"
    />
    <path
      d="M137.997 16.8802H142.736C142.353 15.6573 141.513 15.0459 140.366 15.0459C139.143 15.0459 138.303 15.7338 137.997 16.8802ZM145.334 18.7145H137.997C138.226 20.0139 139.067 20.7782 140.366 20.7782C141.283 20.7782 142.048 20.3196 142.43 19.6317H145.181C144.493 21.7718 142.583 23.0711 140.366 23.0711C137.462 23.0711 135.322 20.8546 135.322 17.9502C135.322 15.0459 137.462 12.8294 140.366 12.8294C143.347 12.8294 145.411 15.1223 145.411 17.9502C145.334 18.1795 145.334 18.4088 145.334 18.7145Z"
      fill="#D02C2F"
    />
    <path
      d="M156.111 16.8802V22.7654H153.512V17.2624C153.512 15.8102 152.977 15.0459 151.831 15.0459C150.455 15.0459 149.614 16.0395 149.614 17.5681V22.6889H147.016V13.0587H148.85L149.385 14.2816C150.149 13.4409 151.296 12.8294 152.595 12.8294C154.735 12.8294 156.111 14.4344 156.111 16.8802Z"
      fill="#D02C2F"
    />
    <path
      d="M162.455 23.0711C159.55 23.0711 157.257 20.8546 157.257 17.8738C157.257 14.9694 159.474 12.753 162.455 12.753C164.977 12.753 166.888 14.358 167.423 16.7273H164.824C164.365 15.8102 163.525 15.1987 162.455 15.1987C161.002 15.1987 159.932 16.3452 159.932 17.8738C159.932 19.4024 161.002 20.5489 162.455 20.5489C163.525 20.5489 164.365 19.9374 164.824 18.9438H167.499C166.964 21.3896 164.977 23.0711 162.455 23.0711Z"
      fill="#D02C2F"
    />
    <path
      d="M171.321 16.8802H176.06C175.677 15.6573 174.837 15.0459 173.69 15.0459C172.391 15.0459 171.627 15.7338 171.321 16.8802ZM178.582 18.7145H171.244C171.474 20.0139 172.314 20.7782 173.614 20.7782C174.531 20.7782 175.295 20.3196 175.677 19.6317H178.429C177.741 21.7718 175.83 23.0711 173.614 23.0711C170.709 23.0711 168.569 20.8546 168.569 17.9502C168.569 15.0459 170.709 12.8294 173.614 12.8294C176.595 12.8294 178.658 15.1223 178.658 17.9502C178.658 18.1795 178.582 18.4088 178.582 18.7145Z"
      fill="#D02C2F"
    />
    <path
      d="M45.2105 8.85501H42.6118V13.6701C41.8475 13.1351 40.9304 12.8294 39.9368 12.8294C36.956 12.8294 34.8159 15.0459 34.8159 17.9502C34.8159 20.8546 36.956 23.0711 39.9368 23.0711C41.0832 23.0711 42.1532 22.6889 42.994 22.001L43.2997 22.8418H45.2105V8.85501ZM40.166 20.6253C38.6374 20.6253 37.491 19.4788 37.491 17.9502C37.491 16.4216 38.6374 15.2752 40.166 15.2752C41.6947 15.2752 42.8411 16.4216 42.8411 17.9502C42.7647 19.4788 41.6947 20.6253 40.166 20.6253Z"
      fill="#233857"
    />
    <path
      d="M105.132 13.5937C104.368 13.0587 103.45 12.753 102.457 12.753C99.476 12.753 97.3359 14.9694 97.3359 17.8738C97.3359 20.8546 99.476 22.9946 102.457 22.9946C103.603 22.9946 104.673 22.6125 105.514 21.9246L105.82 22.7653H107.73V8.85501H105.132V13.5937ZM102.61 20.6253C101.081 20.6253 99.9346 19.4788 99.9346 17.9502C99.9346 16.4216 101.081 15.2752 102.61 15.2752C104.138 15.2752 105.285 16.4216 105.285 17.9502C105.285 19.4788 104.138 20.6253 102.61 20.6253Z"
      fill="#233857"
    />
    <path d="M133.717 13.0587L131.042 13.1351V22.7653H133.717V13.0587Z" fill="#D02C2F" />
    <path d="M131.042 10.4601V13.0587L133.717 10.4601H131.042Z" fill="#233857" />
    <path
      d="M18.3144 16.7055C18.3144 17.8226 17.4217 18.7163 16.3059 18.7163C15.19 18.7163 14.2974 17.8226 14.2974 16.7055C14.2974 15.5884 15.19 14.6948 16.3059 14.6948C17.4217 14.6948 18.3144 15.5884 18.3144 16.7055Z"
      fill="#233857"
    />
    <path
      d="M29.6957 9.33494C29.6957 10.4508 28.8031 11.3435 27.6872 11.3435C26.5714 11.3435 25.6787 10.4508 25.6787 9.33494C25.6787 8.21904 26.5714 7.32632 27.6872 7.32632C28.8031 7.25193 29.6957 8.14465 29.6957 9.33494Z"
      fill="#D02C2F"
    />
    <path
      d="M27.6872 12.7568C26.7946 12.7568 25.9763 12.2072 25.6787 11.3435V22.9639C25.6787 24.1417 26.5714 25.0839 27.6872 25.0839C28.8031 25.0839 29.6957 24.1417 29.6957 22.9639V11.4221C29.3238 12.2072 28.5799 12.7568 27.6872 12.7568Z"
      fill="#D02C2F"
    />
    <path
      d="M21.9958 16.4807C21.1031 16.4807 20.2849 15.9165 19.9873 15.0299V19.2211C19.9873 20.4302 20.88 21.3974 21.9958 21.3974C23.1117 21.3974 24.0043 20.4302 24.0043 19.2211V15.0299C23.6324 15.9165 22.8885 16.4807 21.9958 16.4807Z"
      fill="#D02C2F"
    />
    <path
      d="M16.3059 20.0874C15.4132 20.0874 14.5949 19.5542 14.2974 18.7164V21.6868C14.2974 22.8293 15.19 23.7433 16.3059 23.7433C17.4217 23.7433 18.3144 22.8293 18.3144 21.6868V18.7164C17.9424 19.5542 17.1986 20.0874 16.3059 20.0874Z"
      fill="#D02C2F"
    />
    <path
      d="M24.0043 13.0214C24.0043 14.1373 23.1117 15.03 21.9958 15.03C20.88 15.03 19.9873 14.1373 19.9873 13.0214C19.9873 11.9055 20.88 11.0128 21.9958 11.0128C23.1117 10.9384 24.0043 11.8311 24.0043 13.0214Z"
      fill="#D02C2F"
    />
    <path
      d="M3.25 9.33051C3.25 8.22124 4.14924 7.32199 5.25852 7.32199C6.36779 7.32199 7.26703 8.22123 7.26703 9.33051V13.0879C7.27601 13.4499 7.57223 13.7405 7.93633 13.7405C8.30608 13.7405 8.60583 13.4408 8.60583 13.071V19.3953C8.60583 19.0256 8.30608 18.7258 7.93633 18.7258C7.57223 18.7258 7.27601 19.0165 7.26703 19.3784V23.0753C7.26703 24.1846 6.36779 25.0839 5.25852 25.0839C4.14924 25.0839 3.25 24.1846 3.25 23.0753V9.33051ZM8.60607 13.1315C8.60607 12.0223 9.50531 11.123 10.6146 11.123C11.7239 11.123 12.6231 12.0222 12.6231 13.1315V19.2744C12.6231 20.3837 11.7239 21.2829 10.6146 21.2829C9.50531 21.2829 8.60607 20.3837 8.60607 19.2744V13.1315Z"
      fill="#233857"
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M179.523 13.4119C179.514 13.3285 179.477 13.2636 179.411 13.2172C179.345 13.1707 179.26 13.1475 179.155 13.1475C179.081 13.1475 179.018 13.1586 178.965 13.1809C178.912 13.2032 178.872 13.2334 178.844 13.2716C178.816 13.3098 178.801 13.3533 178.801 13.4023C178.801 13.4431 178.81 13.4784 178.828 13.5083C178.847 13.5382 178.873 13.5637 178.906 13.5847C178.938 13.605 178.974 13.6222 179.013 13.6362C179.053 13.6502 179.093 13.662 179.133 13.6715L179.316 13.7174C179.39 13.7345 179.461 13.7578 179.529 13.787C179.598 13.8163 179.659 13.8532 179.713 13.8978C179.768 13.9423 179.811 13.9961 179.843 14.0591C179.875 14.1221 179.891 14.1959 179.891 14.2805C179.891 14.3951 179.861 14.496 179.803 14.5831C179.744 14.6697 179.66 14.7375 179.549 14.7865C179.439 14.8348 179.306 14.859 179.149 14.859C178.997 14.859 178.865 14.8355 178.753 14.7884C178.642 14.7413 178.554 14.6725 178.491 14.5822C178.429 14.4918 178.395 14.3817 178.39 14.2519H178.739C178.744 14.32 178.765 14.3766 178.802 14.4218C178.838 14.467 178.887 14.5007 178.946 14.523C179.006 14.5453 179.072 14.5564 179.146 14.5564C179.223 14.5564 179.291 14.545 179.349 14.522C179.407 14.4985 179.453 14.466 179.486 14.4247C179.519 14.3827 179.536 14.3337 179.537 14.2777C179.536 14.2268 179.521 14.1848 179.492 14.1517C179.462 14.1179 179.421 14.0899 179.369 14.0677C179.316 14.0448 179.255 14.0244 179.185 14.0066L178.963 13.9493C178.802 13.9079 178.675 13.8453 178.581 13.7613C178.488 13.6766 178.442 13.5643 178.442 13.4243C178.442 13.3091 178.473 13.2083 178.535 13.1217C178.598 13.0352 178.684 12.968 178.792 12.9203C178.9 12.8719 179.023 12.8477 179.16 12.8477C179.298 12.8477 179.42 12.8719 179.524 12.9203C179.629 12.968 179.712 13.0345 179.771 13.1198C179.831 13.2044 179.862 13.3018 179.864 13.4119H179.523Z"
      fill="#D02C2F"
    />
    <path
      d="M180.206 12.8745H180.64L181.22 14.291H181.243L181.823 12.8745H182.257V14.8294H181.917V13.4863H181.899L181.359 14.8237H181.105L180.564 13.4835H180.546V14.8294H180.206V12.8745Z"
      fill="#D02C2F"
    />
    <path
      d="M84.7251 22.7654H82.6615L82.4322 21.9246C81.5915 22.6889 80.5215 23.0711 79.2986 23.0711C76.3942 23.0711 74.1777 20.8546 74.1777 17.9502C74.1777 15.0459 76.3178 12.8294 79.2986 12.8294C80.5215 12.8294 81.6679 13.288 82.5087 14.0523L82.8144 13.1351H84.7251V22.7654ZM79.4515 20.6454C80.94 20.6454 82.1466 19.4387 82.1466 17.9502C82.1466 16.4618 80.94 15.2551 79.4515 15.2551C77.963 15.2551 76.7563 16.4618 76.7563 17.9502C76.7563 19.4387 77.963 20.6454 79.4515 20.6454Z"
      fill="#233857"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default Logo;
