import useAuth from 'components/Auth/useAuth';

import Avatar from 'components/Avatar';
import AvatarUpload from 'components/Avatar/Upload';
import NameEditor from 'components/Account/NameEditor';
import TextTooltip from 'components/Tooltip/TextTooltip';
import EmailEditor from 'components/Account/EmailEditor';

const Profile = () => {
  const { user } = useAuth();
  const { username, name, has_avatar, email } = user!;

  return (
    <div className="space-y-8 max-w-lg">
      <div>
        <div className="text-base font-medium mb-2">Profile photo</div>
        <div className="flex">
          <Avatar className="w-20 h-20 text-2xl" user={user!} />
          <div className="grow" />
          <AvatarUpload
            className="flex-row gap-2 self-end"
            size="sm"
            id={username}
            hasAvatar={has_avatar}
          />
        </div>
      </div>
      <div>
        <TextTooltip
          title={user?.impersonated ? 'Name change is not possible in impersonate mode' : undefined}
          placement="top"
          showArrow
        >
          <NameEditor value={name} disabled={user?.impersonated} />
        </TextTooltip>
        <div className="mt-6">
          <TextTooltip
            title={
              user?.impersonated ? 'Email change is not possible in impersonate mode' : undefined
            }
            placement="top"
            showArrow
          >
            <EmailEditor value={email} disabled={user?.impersonated} />
          </TextTooltip>
        </div>
      </div>
    </div>
  );
};

export default Profile;
