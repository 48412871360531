import { useQuery } from '@tanstack/react-query';

import { fetchIntegrations } from 'api/integrations';

const useIntegration = (options?: Record<string, any>) => {
  const query = useQuery(['integration'], fetchIntegrations, {
    refetchOnWindowFocus: false,
    staleTime: 60 * 1000,
    ...options,
  });

  return query;
};

export default useIntegration;
