import { useCookies } from 'react-cookie';

const useOnboardingCookie = () => {
  const [cookies, setCookie] = useCookies(['onboarding']);

  const updateCookie = (key: string, value: any) => {
    const oneYearInSeconds = 365 * 24 * 60 * 60;

    setCookie(
      'onboarding',
      { ...cookies.onboarding, [key]: value },
      {
        path: '/', // use of /  to be accessible on all pages
        maxAge: oneYearInSeconds, // in seconds
        sameSite: true,
      },
    );
  };

  return {
    cookie: cookies.onboarding,
    updateCookie,
  };
};

export default useOnboardingCookie;
