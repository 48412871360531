import { Contact } from 'types';
import { Link } from '@demandscience/ui';
import MailIcon from '@demandscience/ui/icons/mail';
import CopyIcon from '@demandscience/ui/icons/copy';
import ListItemText from 'components/Layout/ListItemText';
import { isEmpty, map } from 'lodash';
import classNames from 'classnames';
import TextTooltip from 'components/Tooltip/TextTooltip';
import { useCallback, useState } from 'react';
import { copyToClipboard } from 'utils/misc';

interface EmailProps {
  row: Contact;
}

const Email: React.FC<EmailProps> = ({ row }) => {
  const [copyText, setCopyText] = useState('copy');
  const { revealed, emails } = row;

  const handleCopy = useCallback(
    (number: string) => () => {
      copyToClipboard(number);
      setCopyText('copied!');
    },
    [],
  );

  const handleClose = useCallback(() => {
    setCopyText('copy');
  }, []);

  if (isEmpty(emails)) {
    return null;
  }

  return (
    <ListItemText
      leadingContent={
        <div className="flex">
          <TextTooltip title="Business email" placement="top-start" showArrow offset={[-4, 8]}>
            <MailIcon className={classNames('w-5 h-5', { 'text-emerald-500': revealed })} />
          </TextTooltip>
        </div>
      }
      title={
        <>
          {revealed && (
            <div className="flex flex-col items-start">
              {map(emails, ({ email_address }) => (
                <div key={email_address} className="flex flex-row gap-2 items-center w-full">
                  <TextTooltip
                    key={email_address}
                    className="grow min-w-0"
                    title="Click to send email"
                    placement="top"
                  >
                    <Link
                      className="font-normal block ellipsis"
                      href={`mailto:${email_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {email_address}
                    </Link>
                  </TextTooltip>
                  <TextTooltip title={copyText} placement="top-end" showArrow onClose={handleClose}>
                    <button
                      className="text-gray-400 hover:text-gray-500"
                      onClick={handleCopy(email_address)}
                    >
                      <CopyIcon className="w-5 h-5" />
                    </button>
                  </TextTooltip>
                </div>
              ))}
            </div>
          )}
          {!revealed && (
            <div className="flex flex-col items-start">
              {map(emails, ({ email_address }) => (
                <div
                  key={email_address}
                  className="overflow-hidden text-ellipsis whitespace-nowrap"
                >
                  <div>{email_address}</div>
                </div>
              ))}
            </div>
          )}
        </>
      }
    />
  );
};

export default Email;
