import axios from 'axios';
import qs from 'qs';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

const getPrevNextIndexes = (params: any, totalCount: number) => {
  const { index = -1, count } = params;

  if (index > -1 && totalCount) {
    const prevIndex = index > 0 ? index - 1 : undefined;
    const nextIndex = count * (index + 1) < totalCount ? index + 1 : undefined;

    return {
      prevIndex,
      nextIndex,
    };
  }

  return null;
};

instance.interceptors.response.use(
  async (response) => {
    const { config, data } = response;
    const isApiUrl = config.baseURL === process.env.REACT_APP_API_ENDPOINT;

    if (isApiUrl) {
      const {
        method,
        headers,
        data: postPayload, // is json structure with pagination params in case of post request
        params = method === 'post' &&
          postPayload &&
          headers &&
          headers['Content-Type'] === 'application/json' &&
          JSON.parse(postPayload),
      } = config;

      const indexes = getPrevNextIndexes(params, data.count);

      if (indexes) {
        return {
          ...indexes,
          ...data,
        };
      }
    }

    return data;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
