import { useCallback, useState } from 'react';
import { Pagination } from 'types';
import { useQuery } from '@tanstack/react-query';
import { fetchPreviousExports } from 'api/export';

export const usePreviousExports = (defaultParams: Pagination, options?: any) => {
  const [params, setParams] = useState<Pagination>(defaultParams);

  const handlePageChange = useCallback((page: number) => {
    setParams((state) => ({ ...state, index: page - 1 }));
  }, []);

  const query = useQuery(['previousExports', params], () => fetchPreviousExports(params), {
    staleTime: 60 * 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    ...options,
  });

  return {
    query,
    params,
    handlePageChange,
  };
};
