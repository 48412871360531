import classNames from 'classnames';
import { DropdownMenu, DropdownMenuProps, IconButton } from '@demandscience/ui';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';
import { SavedSearch } from 'types';

interface SavedSearchDropdownProps extends DropdownMenuProps {
  data: SavedSearch;
  openDeleteModal: () => void;
  openEditModal: () => void;
  setPersonaSelected: (data: SavedSearch) => void;
}

const SavedSearchDropdown = ({
  className,
  data,
  openDeleteModal,
  openEditModal,
  setPersonaSelected,
  ...props
}: SavedSearchDropdownProps) => {
  const handleEdit = () => {
    setPersonaSelected(data);
    openEditModal();
  };

  const handleDelete = () => {
    setPersonaSelected(data);
    openDeleteModal();
  };

  return (
    <DropdownMenu
      buttonType="custom"
      customButton={
        <IconButton
          className="hover:bg-gray-100"
          size="sm"
          theme="default"
          Icon={MoreVerticalIcon}
        />
      }
      className={classNames('grid place-items-center', className)}
      origin="bottom-right"
      {...props}
    >
      <DropdownMenu.Item onClick={handleEdit}>Edit</DropdownMenu.Item>
      <DropdownMenu.Item onClick={handleDelete}>Delete</DropdownMenu.Item>
    </DropdownMenu>
  );
};

export default SavedSearchDropdown;
