import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchAutocomplete } from 'api/search';
import size from 'lodash/size';
import map from 'lodash/map';

import { AutocompleteFieldName, LocationAutocompleteParams } from 'types';
import { Option } from '../types';
import { getLocationLabel } from '../utils';
import { getFlagUrl } from 'utils/flags';

const fetchOptions = async (params: LocationAutocompleteParams) => {
  const locations = await fetchAutocomplete(AutocompleteFieldName.Location, params);
  const data = map(locations, (value) => {
    const { designation } = value;
    const label = getLocationLabel(value);
    const avatar = value.country_code_iso2 ? getFlagUrl(value.country_code_iso2) : null;

    return {
      id: `${designation}-${label}`,
      value,
      label,
      avatar,
      // eslint-disable-next-line
    } as Option<typeof locations[number]>;
  });

  return data;
};

const useLocationAutocomplete = (
  params: LocationAutocompleteParams,
  options?: Record<string, any>,
) => {
  const query = useInfiniteQuery(
    ['suggestions', AutocompleteFieldName.Location, params],
    ({ pageParam: index = 0 }) => fetchOptions({ ...params, index }),
    {
      refetchOnWindowFocus: false, // no need to refetch on window focus
      staleTime: 12 * 60 * 60 * 1000, // 12 hours
      enabled: size(params.value) >= 3, // api endpoints requires min 3 characters
      getNextPageParam: (lastPage: any, pages) =>
        size(lastPage) === (params.count ?? 20) ? size(pages) : undefined,
      ...options,
    },
  );

  return query;
};

export default useLocationAutocomplete;
