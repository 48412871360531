import { useState, useEffect, useCallback } from 'react';
import { Modal, ModalProps } from '@demandscience/ui';

import { ExportResponse, ModelType, Filters, SortBy, AdvancedSelection, Constraints } from 'types';
import useMutateSearchResults from 'components/Search/useMutateSearchResults';
import { AxiosError } from 'axios';
import { downloadExport } from 'api/export';
import { getFileTimestamp } from './utils';
import ExportInfo from './ExportInfo';
import ExportOverlayTitle from './ExportOverlayTitle';

interface ExportCsvOverlayProps extends Omit<ModalProps, 'children'> {
  advancedSelection?: AdvancedSelection;
  constraints?: Constraints;
  count: number;
  filters?: Filters;
  sort?: SortBy;
  type: ModelType;
}

const ExportCsvOverlay = ({
  type,
  filters,
  sort,
  count,
  advancedSelection,
  constraints,
  ...props
}: ExportCsvOverlayProps) => {
  const [asyncExport, setAsyncExport] = useState(false);
  const [exportInfo, setExportInfo] = useState<ExportResponse | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const { open, onClose } = props;
  const { exportRecords } = useMutateSearchResults();
  const { mutateAsync } = exportRecords;

  const handleSubmit = useCallback(
    async ({
      name: customName,
      includePurchased,
    }: {
      includePurchased?: boolean;
      name?: string;
    }) => {
      if (!filters) {
        return;
      }

      try {
        const name = customName || `klarity-${type}-${getFileTimestamp()}`;

        const response = await mutateAsync({
          record_type: type,
          export_type: 'csv',
          export_name: name,
          exclude_acquired_records: includePurchased ? undefined : true,
          filters,
          sort,
          advanced_selection: advancedSelection,
          constraints,
        });

        if (response.status === 'processing') {
          setAsyncExport(true);
        } else {
          const id = response.export_id;

          if (id) {
            await downloadExport(id, `${name}.csv`);
          }

          onClose();
        }
      } catch (e: any) {
        if (e instanceof AxiosError) {
          setApiError(e.response?.data?.error || 'Unable to export records');
        } else {
          setApiError('Unexpected error, please try again later');
        }
      }
    },
    [advancedSelection, filters, constraints, mutateAsync, onClose, sort, type],
  );

  useEffect(() => {
    const handleError = (e: Error) => {
      if (e instanceof AxiosError) {
        if (
          e.response?.data?.error === 'not enough credits at account level' ||
          e.response?.data?.error === 'not enough bulk credits available at the organization'
        ) {
          setExportInfo(e.response?.data);
        } else {
          setApiError(e.response?.data?.error || 'Unexpected error, please try again later');
        }
      } else {
        setApiError('Unexpected error, please try again later');
      }
    };

    const fetchExportInfo = async () => {
      if (!filters) {
        return;
      }

      setApiError(null);

      try {
        const data = await mutateAsync({
          dry_run: true,
          record_type: type,
          filters,
          sort,
          advanced_selection: advancedSelection,
          constraints,
        });

        setExportInfo(data);
      } catch (e: any) {
        handleError(e);
      }
    };

    if (open) {
      if (count > 0) {
        fetchExportInfo();
      }
    } else {
      setExportInfo(null);
      setAsyncExport(false);
    }
  }, [open, filters, sort, advancedSelection, type, mutateAsync, constraints, count]);

  return (
    <Modal aria-labelledby="export-to-csv-dialog-title" {...props}>
      <ExportOverlayTitle id="export-to-csv-dialog-title" count={count} exportTo="CSV" />
      <ExportInfo
        asyncExport={asyncExport}
        apiError={apiError}
        count={count}
        exportInfo={exportInfo}
        hasAdvancedSelection={!!advancedSelection}
        isLoading={exportRecords.isLoading}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default ExportCsvOverlay;
