import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';

interface ListItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  condensed?: boolean;
  disableGutters?: boolean;
  leadingContent?: React.ReactNode;
  overline?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  title: React.ReactNode | string;
  trailingContent?: React.ReactNode;
}

const ListItemText: React.FC<ListItemProps> = ({
  className,
  overline,
  leadingContent,
  title,
  subtitle,
  trailingContent,
  condensed,
  disableGutters,
  ...props
}) => (
  <div
    className={overrideTailwindClasses(
      classNames('flex flex-row items-center', className, {
        'px-6': !disableGutters,
        'py-4': subtitle || overline,
        'py-3': !subtitle && !overline,
        'py-1': condensed,
      }),
    )}
    {...props}
  >
    {leadingContent && (
      <div className="flex-shrink-0 min-w-[44px] text-gray-500">{leadingContent}</div>
    )}
    <div className="flex-1 min-w-0">
      {overline && <div className="text-xs text-gray-500 font-medium">{overline}</div>}
      <div className="text-sm text-gray-700">{title}</div>
      {subtitle && <div className="text-xs text-gray-500">{subtitle}</div>}
    </div>
    {trailingContent && <div className="flex-shrink-0">{trailingContent}</div>}
  </div>
);

export default ListItemText;
