import { AggregateField, Filters, ModelType } from 'types';
import { useQuery } from '@tanstack/react-query';
import { fetchAggregateSearch } from 'api/search';

const aggregateFields = {
  contact: [
    {
      field_name: AggregateField.JobLevel,
    },
    {
      field_name: AggregateField.JobFunction,
    },
  ],
  company: [
    {
      field_name: AggregateField.CompanyLocationCountry,
    },
    {
      field_name: AggregateField.CompanyEmployees,
    },
    {
      field_name: AggregateField.CompanyIndustry,
    },
    {
      field_name: AggregateField.CompanyRevenue,
    },
  ],
};

const useAggregate = (kind: ModelType, filters: Filters, options?: Record<string, any>) => {
  const { [kind]: aggregations } = aggregateFields;
  const params = {
    filters,
    aggregations,
    pagination: { index: 0, count: 1 }, // no need to fetch more then one record
  };

  const query = useQuery(['search', kind, { params }], () => fetchAggregateSearch(kind, params), {
    refetchOnWindowFocus: false, // no need to refetch on window focus
    staleTime: 5 * 60 * 1000, // cache search queries for max 5 minutes
    ...options,
  });

  return query;
};

export default useAggregate;
