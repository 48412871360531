import { forwardRef, SyntheticEvent, useCallback } from 'react';
import { Button, ButtonOwnProps } from '@demandscience/ui';

import useAuth from 'components/Auth/useAuth';
import useCreditsContext from './useCreditsContext';
import useOrganization from 'components/Organization/useOrganization';
import { useNavigate } from 'react-router-dom';
import { delay } from 'lodash';

interface RequestCreditsControlProps extends Omit<ButtonOwnProps, 'onClick' | 'children'> {
  as?: React.ElementType;
  onClick?: (e: SyntheticEvent) => void;
  organizatonLimitReached?: boolean;
}

const CreditsControl = forwardRef(
  (
    {
      as: Component = Button,
      onClick,
      organizatonLimitReached,
      ...props
    }: RequestCreditsControlProps,
    ref,
  ) => {
    const { requestCredits, manageCredits, buyCredits } = useCreditsContext();
    const { isManager } = useAuth();
    const { data: organization } = useOrganization();
    const navigate = useNavigate();

    const handleClick = useCallback(
      (e: SyntheticEvent) => {
        if (onClick) onClick(e);

        if (isManager) {
          if (organizatonLimitReached) {
            if (organization?.selfservice_subscription_disabled) {
              buyCredits();
            } else {
              // using delay as in most cases buy button is used from an overlay
              // so it's to give some time overlay to close before navigating to different page
              delay(() => {
                navigate('/buy');
              }, 250);
            }
          } else {
            manageCredits();
          }
        } else {
          requestCredits();
        }
      },
      [
        buyCredits,
        isManager,
        manageCredits,
        navigate,
        onClick,
        organization?.selfservice_subscription_disabled,
        organizatonLimitReached,
        requestCredits,
      ],
    );

    return (
      <Component ref={ref} onClick={handleClick} {...props}>
        {isManager && !organizatonLimitReached && 'Manage credits'}
        {isManager && organizatonLimitReached && 'Buy more credits'}
        {!isManager && 'Request credits'}
      </Component>
    );
  },
);

CreditsControl.displayName = 'CreditsControl';

export default CreditsControl;
