import classNames from 'classnames';
import Section, { SectionProps } from 'components/Layout/Section';
import ListItemSkeleton from 'components/Lists/Skeletons/ListItemSkeleton';
import { Skeleton } from '@demandscience/ui';
import { isEmpty } from 'lodash';
import { Show } from 'types';

import SavedSearchesShortList from './SavedSearchesShortList';
import useSavedSearches from './useSavedSearches';

interface SavedSearchesSectionProps extends SectionProps {
  hideCreateNew?: boolean;
  hideIfOnlyChild?: boolean;
}

const SavedSearchesSection = ({
  hideCreateNew = false,
  hideIfOnlyChild = false,
  className,
  ...props
}: SavedSearchesSectionProps) => {
  const query = useSavedSearches({ filter: { show: Show.All, is_a_persona: false } });
  const { data, isLoading, isSuccess } = query;

  if (isLoading) {
    return (
      <Section title={<Skeleton variant="text" height={5} width={28} className="bg-gray-200" />}>
        <div className="divide-y">
          <ListItemSkeleton count={3} />
        </div>
      </Section>
    );
  }

  return (
    <Section
      className={classNames(className, {
        'only:hidden': hideIfOnlyChild && isSuccess && !isEmpty(data),
      })}
      {...props}
    >
      <SavedSearchesShortList query={query} hideCreateNew={hideCreateNew} />
    </Section>
  );
};

export default SavedSearchesSection;
