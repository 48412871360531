import Info from './Info';
import Dropdown from './Dropdown';
import classNames from 'classnames';
import TimeAgo from 'react-timeago';
import { KlarityFeature, SavedSearch, Visibility } from 'types';
import useAuth from 'components/Auth/useAuth';
import { useNavigate } from 'react-router-dom';
import IconBox from 'components/Layout/IconBox';
import ListItem from 'components/Layout/ListItem';
import BadgeShared from 'components/Layout/BadgeShared';
import BookmarkIcon from '@demandscience/ui/icons/bookmark';
import useModelPermissions from 'hooks/useModelPermissions';
import { stopPropagation } from 'utils/event';
import useCompare from 'components/Filters/useCompare';

interface SavedSearchesListProps {
  data: SavedSearch[];
  featureContext?: KlarityFeature;
  searchQuery?: string;
  showDivider?: boolean;
}

const SavedSearchesList = ({
  data,
  featureContext,
  showDivider = true,
  searchQuery,
}: SavedSearchesListProps) => {
  const navigate = useNavigate();

  const { user } = useAuth();

  const { canEditModel } = useModelPermissions();

  const { addSearchToCompareList } = useCompare();

  const handleClick = (search: SavedSearch) => () => {
    const { filters, current_view } = search;

    if (featureContext === KlarityFeature.CompareSearches) {
      addSearchToCompareList(search, filters);
      return;
    }

    navigate('/search', { state: { filters: { ...filters }, tab: current_view } });
  };

  const filteredData = data.filter((search) => {
    if (searchQuery === '' || searchQuery === undefined) {
      return search;
    } else {
      return search.name.toLowerCase().includes(searchQuery!);
    }
  });

  return (
    <div className={classNames({ 'divide-y': showDivider })}>
      {filteredData.map((search) => {
        const isOwnSearch = user?.username === search.owner.username;
        const isEditable = canEditModel(user, search.owner, search.visibility);

        const isSharedSearch =
          search.visibility === Visibility.Public || search.visibility === Visibility.Restricted;

        return (
          <ListItem
            key={search.id}
            className="group cursor-pointer"
            role="button"
            onClick={handleClick(search)}
            leadingContent={
              <IconBox className="px-2 py-2 bg-emerald-100">
                <BookmarkIcon className="w-4 h-4 text-emerald-500" />
              </IconBox>
            }
            title={
              <div className="flex flex-row items-center gap-2">
                <span className="break-all">{search.name}</span>
                {isSharedSearch && <BadgeShared title="Shared search" />}
              </div>
            }
            subtitle={<TimeAgo date={search.created_at} />}
            trailingContent={
              <div className="flex flex-row gap-2" onClick={stopPropagation}>
                <div className="invisible group-hover:visible">
                  <Info data={search} showAccountInfo={!isOwnSearch} />
                </div>
                {isEditable && <Dropdown data={search} />}
              </div>
            }
          />
        );
      })}
    </div>
  );
};

export default SavedSearchesList;
