import { useLocation, useNavigate, Navigate } from 'react-router-dom';

import AuthForgotPasswordConfirm from 'components/Auth/ForgotPasswordConfirm';
import Heading from 'layouts/Auth/Heading';

import useSnackbar from 'components/Snackbar/useSnackbar';
import { CodeDeliveryDetails } from 'types';
import { useCallback } from 'react';

const SignupConfirm = () => {
  const { showMessage } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as Record<string, any>;
  const { codeDeliveryDetails, username } = state || {};

  const handleForgotPasswordConfirm = useCallback(() => {
    showMessage('Password was changed, please sign in', 'success');

    const state = location.state as Record<string, any>;
    const from = state?.from;

    navigate('../../signin', { replace: true, state: { from } });
  }, [location.state, navigate, showMessage]);

  // case when not delivery details is not present shouldn't hanppen in normal auth flow
  if (!codeDeliveryDetails) {
    return <Navigate to="../../forgotPassword" replace />;
  }

  const { Destination } = codeDeliveryDetails as CodeDeliveryDetails;

  return (
    <>
      <Heading
        title="New password confirm"
        description={`Enter the verification code sent to ${Destination} and set a new password.`}
      />
      <AuthForgotPasswordConfirm
        username={username}
        onForgotPasswordConfirm={handleForgotPasswordConfirm}
      />
    </>
  );
};

export default SignupConfirm;
