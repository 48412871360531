import React, { useCallback, useMemo, useRef, useState } from 'react';

import {
  uniqBy,
  reject,
  map,
  isEqual,
  omit,
  reverse,
  split,
  forEach,
  find,
  some,
  isEmpty,
  uniq,
} from 'lodash';
import TextOption, { Option } from '../Filter/Dropdown/TextOption';
import CheckboxOption from '../Filter/Dropdown/CheckboxMenuItem';
import { FilterProps } from './types';
import { FilterName, FreeTextFilter } from 'types/filters';
import useFilter from './useFilter';
import FilterInput from './FilterInput';
import Inline from 'components/Filter/Inline';
import { getFreetextLabel } from './utils';

const getBadgeProps = (value: FreeTextFilter) => {
  const label = getFreetextLabel(value);

  return {
    key: value.freetext,
    label,
    exclude: value.exclude,
  };
};

interface TextFilterProps extends FilterProps {
  badgeDisplayCap?: number;
  container: React.RefObject<HTMLDivElement>;
  dense?: boolean;
  multiple?: true;
  name: FilterName;
  placeholder: string;
  showIncludeExclude?: boolean;
}

const TextFilter = ({
  name,
  placeholder,
  dense,
  multiple,
  showIncludeExclude,
  clearInputOnChange,
  badgeDisplayCap,
}: TextFilterProps) => {
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { value, onChange, onClear } = useFilter(name);
  const options: Option<FreeTextFilter>[] = useMemo(() => {
    let res: Option<FreeTextFilter>[] = [];
    const tokens = multiple ? uniq(reverse(split(query, /\s*,\s*/))) : [query];
    forEach(tokens, (token, index) => {
      if (token) {
        // @ts-expect-error
        const dataFromValue = find(value, { freetext: token });

        const optionValue = dataFromValue || {
          freetext: token,
        };
        const label = showIncludeExclude ? `All containing "${token}"` : `Search for "${token}"`;

        // @ts-expect-error
        res = [{ id: `freetext${index}`, value: optionValue, label }, ...res];
      }
    });

    return res;
  }, [multiple, query, showIncludeExclude, value]);

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
  }, []);

  const handleChange = useCallback(
    (value: any[] | undefined) => {
      onChange(uniqBy(value, 'freetext'));

      // focus input on change
      inputRef.current?.focus();

      // workaround to clear filter input and the suggestions dropdown
      if (clearInputOnChange) {
        handleInputClear();
      }
    },
    [clearInputOnChange, handleInputClear, onChange],
  );

  const handleInputChange = useCallback((e: React.SyntheticEvent) => {
    const { target } = e;

    setQuery((target as HTMLInputElement).value);
  }, []);

  const handleToggle = useCallback(
    (option: Option<any>) => {
      const newValue = map(value, (v) => {
        if (isEqual(option.value, v)) {
          return option.value.exclude
            ? omit(option.value, 'exclude')
            : { ...option.value, exclude: true };
        }

        return v;
      });

      onChange(newValue);
    },
    [onChange, value],
  );

  const handleDelete = useCallback(
    (deleted: any) => {
      const newValue = reject(value, deleted);

      onChange(newValue);
    },
    [onChange, value],
  );

  const handleClear = useCallback(() => {
    handleInputClear();

    onClear();
  }, [handleInputClear, onClear]);

  const handleEnterKey = useCallback(() => {
    // @ts-expect-error
    const missing = reject(options, (option) => some(value, option.value));

    if (isEmpty(missing)) {
      // all are present, remove them all
      onChange(
        // @ts-expect-error
        reject(value, (val) => some(options, (option) => option.value.freetext === val.freetext)),
      );
    } else {
      // @ts-expect-error
      // add those missing
      onChange([...(value || []), ...map(missing, 'value')]);
    }
  }, [onChange, options, value]);

  return (
    <Inline
      value={value}
      onChange={handleChange}
      multiple
      by="freetext"
      button={(props) => (
        <FilterInput
          {...props}
          ref={inputRef}
          name={name}
          dense={dense}
          onClear={handleClear}
          onDelete={handleDelete}
          onInputChange={handleInputChange}
          onInputClear={handleInputClear}
          placeholder={placeholder}
          getBadgeProps={getBadgeProps}
          onEnterKey={handleEnterKey}
          badgeDisplayCap={badgeDisplayCap}
        />
      )}
    >
      {!multiple && (
        <TextOption option={options[0]} onToggle={showIncludeExclude ? handleToggle : undefined} />
      )}
      {multiple &&
        map(options, (option) => (
          <CheckboxOption
            // @ts-expect-error
            key={option.id}
            // @ts-expect-error
            option={option}
            onToggle={showIncludeExclude ? handleToggle : undefined}
          />
        ))}
    </Inline>
  );
};

export default TextFilter;
