import { useEffect, useCallback, useState } from 'react';
import { Tabs } from '@demandscience/ui';
import DataProfileView from 'pages/Dashboard/Search/Results/DataProfileView';
import useCustomization from 'components/ModulesCustomization/useModulesCustomization';
import { ResultsView } from 'types';

export interface DataProfileViewsProps {
  setTab: (tab: ResultsView) => void;
}

const DataProfileViews = ({ setTab }: DataProfileViewsProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { allViews, isMultiViews, setIsMultiViews, setCurrentView } = useCustomization() || [];

  // In order to display the tabs for the views
  // there need to be more views than just the initial one
  const isMoreThanOneViews = allViews?.length! > 1;

  useEffect(() => {
    if (isMoreThanOneViews) {
      setIsMultiViews(true);
    }
    setCurrentView(allViews?.[selectedIndex]);
  }, [isMoreThanOneViews, setIsMultiViews, selectedIndex, setCurrentView, allViews]);

  const handleTabChange = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  // We don't show any tabs if there aren't many Views
  if (!isMultiViews) {
    return (
      <div className="mt-4">
        <DataProfileView
          id="search_tab_data_profile"
          onTabChange={setTab}
          modules={allViews?.[0]?.modules}
        />
      </div>
    );
  }

  return (
    <Tabs selectedIndex={selectedIndex} onChange={handleTabChange}>
      <div className="border-b mt-5 mb-5">
        <Tabs.List>
          {allViews?.map((tab, index) => (
            <Tabs.Item key={index}>{tab.name}</Tabs.Item>
          ))}
        </Tabs.List>
        <Tabs.Panels className="grow overflow-y-auto mt-5">
          {allViews?.map((tab, index) => (
            <Tabs.Panel key={index} unmount>
              <DataProfileView
                id="search_tab_data_profile"
                onTabChange={setTab}
                modules={tab.modules}
              />
            </Tabs.Panel>
          ))}
        </Tabs.Panels>
      </div>
    </Tabs>
  );
};

export default DataProfileViews;
