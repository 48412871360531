import { useEffect, useMemo } from 'react';
import useScript from 'hooks/useScript';

export interface ZendeskWindow extends Window {
  zE?: (action: 'messenger:set', property: 'zIndex', value: number) => void;
  zEACLoaded?: any;
  zEWebpackACJsonp?: any;
  zEmbed?: any;
}
declare let window: ZendeskWindow;

interface WebWidgetProps {
  instanceKey: string;
}

const WebWidget = ({ instanceKey }: WebWidgetProps) => {
  const attributes = useMemo(
    () => ({
      src: `https://static.zdassets.com/ekr/snippet.js?key=${instanceKey}`,
      id: 'ze-snippet',
      onload: () => {
        if (window.zE) {
          // adjust zendesk widget z-index
          window.zE('messenger:set', 'zIndex', 5);
        }
      },
    }),
    [instanceKey],
  );
  useScript(attributes);

  useEffect(() => {
    return () => {
      // this is bit hacky as not sure if the zendesk widget would always render the same way
      // find the three tags added by script and remove them
      document.querySelector('iframe[data-product="web_widget"]')?.remove();
      document.querySelector('iframe[title="Messaging window"]')?.parentElement?.remove();
      document
        .querySelector('iframe[title="Button to launch messaging window"]')
        ?.parentElement?.remove();

      if (window.zE) {
        delete window.zE;
        delete window.zEmbed;
        delete window.zEACLoaded;
        delete window.zEWebpackACJsonp;
      }
    };
  }, []);

  return null;
};

export default WebWidget;
