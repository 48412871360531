import { Modal, Button, ModalProps } from '@demandscience/ui';
import { useCallback } from 'react';

interface ConfirmOverlayProps extends ModalProps {
  cancelLabel?: string;
  children: React.ReactNode;
  confirmLabel?: string;
  onConfirm: () => void;
}

const ConfirmOverlay: React.FC<ConfirmOverlayProps> = ({
  onConfirm,
  children,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  ...props
}) => {
  const { onClose } = props;

  const handleConfirm = useCallback(() => {
    onClose();

    onConfirm();
  }, [onClose, onConfirm]);

  return (
    <Modal rootClassName="z-30" {...props}>
      {children}
      <div className="flex gap-2 justify-end">
        <Button className="sm:col-start-1" theme="white" onClick={onClose}>
          {cancelLabel}
        </Button>
        <Button className="sm:col-start-2" onClick={handleConfirm}>
          {confirmLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmOverlay;
