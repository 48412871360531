import CreditsAndSubscriptionsInfo from 'components/Organization/CreditsAndSubscriptionsInfo';
import ActivityInfo from 'components/Organization/ActivityInfo';
import InviteControl from 'components/Members/InviteControl';
import UserPlusIcon from '@demandscience/ui/icons/user-plus';
import AvailableSeatsBadge from 'components/Organization/AvailableSeatsBadge';

const PlanAndUsage = () => {
  return (
    <>
      <div className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-6">
        <div className="lg:col-span-8">
          <div className="space-y-10">
            <CreditsAndSubscriptionsInfo title="Team credits usage" />
            <ActivityInfo title="Last 30 days activity" />
          </div>
        </div>
        <div className="lg:col-span-4">
          <div className="border border-gray-200 bg-blue-50 p-6 space-y-4">
            <div className="text-base text-gray-700 font-semibold">
              Invite team members to Klarity
            </div>
            <div className="text-sm text-gray-700">
              Enable everyone on your team to use Klarity and collaborate seamlessly using shared
              searches and lists.
            </div>
            <div className="flex flex-row items-center gap-2">
              {/* @ts-expect-error demandscience-ui issue */}
              <InviteControl size="sm" leftIcon={<UserPlusIcon size={18} />}>
                Invite
              </InviteControl>
              <AvailableSeatsBadge className="bg-white" />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-8" />
    </>
  );
};

export default PlanAndUsage;
