import React, { cloneElement, forwardRef, MouseEventHandler } from 'react';
import classNames from 'classnames';
import PlusIcon from '@demandscience/ui/icons/plus';
import MinusIcon from '@demandscience/ui/icons/minus';

interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  dense?: boolean;
  expanded?: boolean;
  filled?: boolean;
  icon: React.ReactElement;
  label: React.ReactElement;
  onClear?: () => void;
}

const Button = forwardRef<HTMLDivElement, ButtonProps>(
  ({ icon, label, filled, expanded, dense, onClear, className, ...props }, ref) => {
    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
      if (!expanded) {
        e.preventDefault();
      }

      if (onClear) onClear();
    };

    return (
      <div
        ref={ref}
        className={classNames('flex flex-row gap-4 px-4', {
          'py-4': !dense,
          'py-2': dense,
        })}
        {...props}
      >
        {icon && (
          <div
            className={classNames('flex-shrink-0', {
              'text-gray-700': !filled && !expanded,
              'text-blue-500': filled || expanded,
            })}
          >
            {cloneElement(icon, { size: 18 })}
          </div>
        )}
        <div className="flex-grow">
          <div className="flex flex-row items-center gap-2">
            <div className="text-sm text-gray-700 font-medium">{label}</div>
            <div className="grow" />
            {filled && onClear && (
              <button className="text-xs text-blue-500" onClick={handleClear}>
                Clear
              </button>
            )}
            {!expanded && <PlusIcon className="stroke-gray-700" size={18} />}
            {expanded && <MinusIcon className="stroke-gray-700" size={18} />}
          </div>
        </div>
      </div>
    );
  },
);

Button.displayName = 'Button';

export default Button;
