import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  updateCurrentUserName,
  updateCurrentUserPassword,
  updateCurrentUserEmail,
  deleteAccount,
} from 'api/account';

const useMutateAccount = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();

  const updateName = useMutation(updateCurrentUserName, {
    onSettled: () => {
      queryClient.invalidateQueries(['account']);
    },
    ...options,
  });

  const updateEmail = useMutation(updateCurrentUserEmail, {
    onSettled: () => {
      queryClient.invalidateQueries(['account']);
    },
    ...options,
  });

  const updatePassword = useMutation(updateCurrentUserPassword, {
    ...options,
  });

  const destroyAccount = useMutation((query: string) => deleteAccount(query), {
    onSuccess: () => {
      queryClient.invalidateQueries(['account']);
      queryClient.invalidateQueries(['organization']);
    },
    ...options,
  });

  return { updateName, updatePassword, updateEmail, destroyAccount };
};

export default useMutateAccount;
