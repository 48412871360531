import { IconButton, TextField } from '@demandscience/ui';
import { ChangeEventHandler, KeyboardEventHandler, useCallback, useContext, useState } from 'react';
import ChatSearchContext from '../ChatSearchContext';
import { GptMessageType } from 'types/aissistant';
import Send from '@demandscience/ui/icons/send';

const ChatInput = () => {
  const [value, setValue] = useState('');
  const { addMessage } = useContext(ChatSearchContext) || {};

  const handleKeyEnter: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (value === '') return;

      const { key } = e;

      if (key === 'Enter') {
        addMessage?.({
          role: GptMessageType.user,
          content: value,
        });

        setValue('');
      }
    },
    [addMessage, value],
  );

  const handleClickSendMessage = useCallback(() => {
    if (value === '') return;

    addMessage?.({
      role: GptMessageType.user,
      content: value,
    });

    setValue('');
  }, [addMessage, value]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const {
      target: { value },
    } = e;

    setValue(value);
  }, []);

  return (
    <div className="mx-6">
      <TextField
        type="text"
        theme="primary"
        placeholder="Enter message"
        variant="outlined"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyEnter}
        trailingIcon={
          <IconButton
            type="button"
            size="xs"
            theme="default"
            round
            onClick={handleClickSendMessage}
            Icon={Send}
          />
        }
      />
    </div>
  );
};

export default ChatInput;
