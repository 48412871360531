import includes from 'lodash/includes';

import { Badge } from '@demandscience/ui';
import { Member, Role } from 'types';

interface StateBadgeProps {
  member: Member;
}

const StateBadge: React.FC<StateBadgeProps> = ({ member }) => {
  const { state, roles } = member;
  const isManager = includes(roles, Role.Manager);

  if (state === 'active') {
    if (isManager) {
      return (
        <Badge size="sm" color="emerald">
          Manager
        </Badge>
      );
    }

    return (
      <Badge size="sm" color="blue">
        User
      </Badge>
    );
  } else if (state === 'suspended') {
    return (
      <Badge size="sm" color="gray">
        Deactivated
      </Badge>
    );
  } else if (state === 'invited') {
    return (
      <Badge size="sm" color="amber">
        Pending
      </Badge>
    );
  }

  return null;
};

export default StateBadge;
