import { BounceParams } from 'types';

const defaultListParams = (count = 25): BounceParams => ({
  index: 0,
  count,
  sort: {
    by: 'updated_at',
    descending: true,
  },
});

export default defaultListParams;
