import { Modal, TitleProps } from '@demandscience/ui';

interface ExportOverlayTitleProps extends Omit<TitleProps, 'children'> {
  count: number;
  exportTo: string;
}

const ExportOverlayTitle = ({ count, exportTo, ...props }: ExportOverlayTitleProps) => (
  <Modal.Title className="mb-4" {...props}>
    Export {count?.toLocaleString()} records to {exportTo}
  </Modal.Title>
);

export default ExportOverlayTitle;
