import { useCallback, useMemo, useState, useEffect } from 'react';
import { Views, ModuleType } from 'types';
import useSnackbar from 'components/Snackbar/useSnackbar';
import useFetchModulesCustomization from './useFetchModulesCustomization';
import useFetchViews from './useFetchViews';

import ModulesCustomizationContext, {
  ModulesCustomizationContextInterface,
} from './ModulesCustomizationContext';
import useMutateDataModules from './useMutateDataModules';
import CustomizeViewOverlay from './CustomizeViewOverlay';

interface ModulesCustomizationProviderProps {
  children: React.ReactNode;
}

const ModulesCustomizationProvider: React.FC<ModulesCustomizationProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [customizeModules, setCustomizeModules] = useState({
    open: false,
  });
  const [isMultiViews, setIsMultiViews] = useState(false);

  const { showMessage } = useSnackbar();
  const { data: allModules, isLoading } = useFetchModulesCustomization();
  const { updateViews } = useMutateDataModules();
  const { data: allViews } = useFetchViews();
  const [currentView, setCurrentView] = useState<Views | undefined>(allViews?.[0]);

  const setCustomizeModulesOpen = useCallback(() => {
    setCustomizeModules({ open: true });
  }, []);

  const handleCloseCustomizeModules = useCallback(() => {
    setCustomizeModules((state) => ({ ...state, open: false }));
  }, []);

  useEffect(() => {
    setCurrentView(allViews?.[0]);
  }, [allViews]);

  const onChange = useCallback(
    async (value: any) => {
      // Get the id of the current View
      const currentViewId = currentView?.id;

      // Add the default visual attribute 'list'
      // (this should be updated once modules return the "visual" attribute)
      const newModules = value?.map((e: ModuleType) => {
        return { ...e, visual: 'list' };
      });

      try {
        const payload = {
          name: currentView?.name,
          modules: newModules,
        };
        await updateViews.mutateAsync({ id: currentViewId, payload: payload });
      } catch (e: any) {
        showMessage('Unable to update view customization', 'error');
      }
    },
    [showMessage, currentView, updateViews],
  );

  const value: ModulesCustomizationContextInterface = useMemo(
    () => ({
      allModules,
      allViews,
      isMultiViews,
      onChange,
      open,
      setOpen,
      setCustomizeModulesOpen,
      setIsMultiViews,
      setCurrentView,
    }),
    [
      onChange,
      open,
      setCustomizeModulesOpen,
      allModules,
      allViews,
      setIsMultiViews,
      isMultiViews,
      setCurrentView,
    ],
  );

  if (isLoading) {
    return null;
  }

  return (
    <ModulesCustomizationContext.Provider value={value}>
      {children}

      <CustomizeViewOverlay {...customizeModules} onClose={handleCloseCustomizeModules} />
    </ModulesCustomizationContext.Provider>
  );
};

export default ModulesCustomizationProvider;
