import { useCallback, useState } from 'react';
import { Company } from 'types';
import { Link } from '@demandscience/ui';
import PhoneIcon from '@demandscience/ui/icons/phone';
import CopyIcon from '@demandscience/ui/icons/copy';
import ListItemText from 'components/Layout/ListItemText';
import { isEmpty, map } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
import { copyToClipboard } from 'utils/misc';
import TextTooltip from 'components/Tooltip/TextTooltip';

interface PhoneProps {
  row: Company;
}

const Phone: React.FC<PhoneProps> = ({ row }) => {
  const [copyText, setCopyText] = useState('copy');
  const { phones } = row;

  const handleCopy = useCallback(
    (number: string) => () => {
      copyToClipboard(number);
      setCopyText('copied!');
    },
    [],
  );

  const handleClose = useCallback(() => {
    setCopyText('copy');
  }, []);

  if (isEmpty(phones)) {
    return null;
  }

  return (
    <ListItemText
      leadingContent={
        <div className="flex">
          <TextTooltip title="Company phone" placement="top-start" showArrow offset={[-4, 8]}>
            <PhoneIcon className="w-5 h-5" />
          </TextTooltip>
        </div>
      }
      title={
        <div className="flex flex-col items-start">
          {map(phones, ({ number }) => {
            const parsed = parsePhoneNumber(number);

            return (
              <div key={'company-' + number} className="flex flex-row gap-2 items-center w-full">
                <TextTooltip className="grow min-w-0" title="Click to call" placement="top">
                  <Link
                    className="font-normal block ellipsis"
                    href={parsed?.getURI()}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {parsed?.formatInternational()}
                  </Link>
                </TextTooltip>
                <TextTooltip
                  key={`copy-' + ${number}`}
                  title={copyText}
                  placement="top-end"
                  showArrow
                  onClose={handleClose}
                >
                  <button
                    className="text-gray-400 hover:text-gray-500"
                    onClick={handleCopy(number)}
                  >
                    <CopyIcon className="w-5 h-5" />
                  </button>
                </TextTooltip>
              </div>
            );
          })}
        </div>
      }
    />
  );
};

export default Phone;
