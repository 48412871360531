import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateRecentSearch, createRecentSearch, deleteRecentSearch } from 'api/recentSearch';

const useMutateRecentSearch = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();

  const update = useMutation(updateRecentSearch, {
    onSettled: () => {
      queryClient.invalidateQueries(['recentSearches']);
    },
    ...options,
  });

  const create = useMutation(createRecentSearch, {
    onSettled: () => {
      queryClient.invalidateQueries(['recentSearches']);
    },
    ...options,
  });

  const destroy = useMutation(deleteRecentSearch, {
    onSuccess: () => {
      queryClient.invalidateQueries(['recentSearches']);
    },
    ...options,
  });

  return { update, create, destroy };
};

export default useMutateRecentSearch;
