import { useState, useEffect, useCallback } from 'react';
import { Alert, Button, Modal, ModalProps, Spinner } from '@demandscience/ui';
import CheckCircleIcon from '@demandscience/ui/icons/check-circle';

import { AdvancedSelection, Constraints, Filters, RevealContactsResponse, SortBy } from 'types';
import useMutateSearchResults from 'components/Search/useMutateSearchResults';
import CreditsControl from 'components/Credits/CreditsControl';
import { AxiosError } from 'axios';

interface RevealMultipleContactsOverlayProps extends Omit<ModalProps, 'children'> {
  advancedSelection?: AdvancedSelection;
  constraints?: Constraints;
  count: number;
  filters?: Filters;
  sort?: SortBy;
}

const RevealMultipleContactsOverlay = ({
  filters,
  sort,
  count,
  advancedSelection,
  constraints,
  ...props
}: RevealMultipleContactsOverlayProps) => {
  const [asyncReveal, setAsyncReveal] = useState(false);
  const [revealInfo, setRevealInfo] = useState<RevealContactsResponse | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const { open, onClose } = props;
  const { reveal } = useMutateSearchResults();
  const { mutateAsync } = reveal;

  const handleClick = useCallback(async () => {
    if (!filters) {
      return;
    }

    try {
      const response = await mutateAsync({
        filters,
        sort,
        advanced_selection: advancedSelection,
        constraints,
      });

      if (response.status === 'processing') {
        setAsyncReveal(true);
      } else {
        onClose();
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || 'Unable to reveal records');
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  }, [advancedSelection, constraints, filters, mutateAsync, onClose, sort]);

  useEffect(() => {
    const handleError = (e: Error) => {
      if (e instanceof AxiosError) {
        if (
          e.response?.data?.error === 'not enough credits at account level' ||
          e.response?.data?.error === 'not enough bulk credits available at the organization'
        ) {
          setRevealInfo(e.response?.data);
        } else {
          setApiError(e.response?.data?.error || 'Unexpected error, please try again later');
        }
      } else {
        setApiError('Unexpected error, please try again later');
      }
    };

    const fetchRevealInfo = async () => {
      if (!filters) {
        return;
      }

      setApiError(null);

      try {
        const data = await mutateAsync({
          dry_run: true,
          filters,
          sort,
          advanced_selection: advancedSelection,
          constraints,
        });

        setRevealInfo(data);
      } catch (e: any) {
        handleError(e);
      }
    };

    if (open) {
      if (count > 0) {
        fetchRevealInfo();
      }
    } else {
      setRevealInfo(null);
      setAsyncReveal(false);
    }
  }, [open, mutateAsync, filters, sort, advancedSelection, constraints, count]);

  const missingCredits = revealInfo
    ? revealInfo.requested - revealInfo.already_acquired - revealInfo.can_be_revealed
    : 0;
  const hasAdvancedSelection = !!advancedSelection;

  return (
    <Modal aria-labelledby="reveal-contacts-dialog-title" {...props}>
      <Modal.Title id="reveal-contacts-dialog-title" className="mb-4">
        Reveal {count?.toLocaleString()} records
      </Modal.Title>
      {!revealInfo && (
        <div>
          {reveal.isLoading && <Spinner />}
          {apiError && <div className="text-error-500 text-center">{apiError}</div>}
        </div>
      )}
      {revealInfo && asyncReveal && (
        <>
          <div className="space-y-2">
            <Alert
              className="bg-transparent"
              severity="neutral"
              variant="borderless"
              customIcon={<CheckCircleIcon className="w-5 h-5 mr-4 text-success-500" />}
            >
              <Alert.Header>
                <Alert.SubTitle>
                  Reveal request is being processed in the background...
                </Alert.SubTitle>
              </Alert.Header>
            </Alert>
          </div>
          <div className="flex flex-row gap-2 justify-end mt-10">
            <Button size="md" borderless onClick={onClose}>
              Close
            </Button>
          </div>
        </>
      )}
      {revealInfo && !asyncReveal && (
        <>
          <div className="space-y-2">
            {revealInfo.already_acquired > 0 && (
              <Alert
                className="bg-transparent"
                severity="neutral"
                variant="borderless"
                customIcon={<CheckCircleIcon className="w-5 h-5 mr-4 text-success-500" />}
              >
                <Alert.Header>
                  <Alert.SubTitle>
                    You already purchased {revealInfo.already_acquired.toLocaleString()} records
                  </Alert.SubTitle>
                </Alert.Header>
              </Alert>
            )}
            {missingCredits > 0 && (
              <Alert severity="warning">
                <Alert.Header>
                  <Alert.Title>Missing {missingCredits.toLocaleString()} credits</Alert.Title>
                  <CreditsControl
                    as={Alert.ActionButton}
                    onClick={onClose}
                    organizatonLimitReached={
                      revealInfo.error === 'not enough bulk credits available at the organization'
                    }
                  />
                </Alert.Header>
              </Alert>
            )}
            {missingCredits === 0 && revealInfo.can_be_revealed > 0 && (
              <Alert severity="info">
                {hasAdvancedSelection && (
                  <>
                    <Alert.Header>
                      <Alert.Title>
                        This will use up to {revealInfo.can_be_revealed.toLocaleString()} credits
                      </Alert.Title>
                    </Alert.Header>
                    <div className="text-sm mt-1 text-gray-700 ml-9">
                      We will deduct the number of records you already acquired
                    </div>
                  </>
                )}
                {!hasAdvancedSelection && (
                  <Alert.Header>
                    <Alert.Title>
                      This will use {revealInfo.can_be_revealed.toLocaleString()} credits
                    </Alert.Title>
                  </Alert.Header>
                )}
              </Alert>
            )}
            {apiError && <div className="text-error-500 text-center">{apiError}</div>}
          </div>
          <div className="flex flex-row gap-2 justify-end mt-10">
            {missingCredits === 0 && (
              <Button size="md" borderless onClick={onClose}>
                Dismiss
              </Button>
            )}
            {missingCredits === 0 && revealInfo.can_be_revealed > 0 && (
              <Button size="md" theme="primary" onClick={handleClick} disabled={reveal.isLoading}>
                Reveal {revealInfo.can_be_revealed.toLocaleString()} records
              </Button>
            )}
            {missingCredits > 0 && (
              <Button size="md" theme="primary" onClick={onClose}>
                Change selection
              </Button>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default RevealMultipleContactsOverlay;
