import ListIcon from '@demandscience/ui/icons/list-02';
import BookmarkIcon from '@demandscience/ui/icons/bookmark';
import LayersIcon from '@demandscience/ui/icons/layers';

import Section, { SectionProps } from 'components/Layout/Section';
import IconBox from 'components/Layout/IconBox';
import useActivity from './useActivity';
import { Skeleton } from '@demandscience/ui';

const ActivityInfo: React.FC<SectionProps> = (props) => {
  const { data, isLoading } = useActivity();

  const skeleton = (
    <Skeleton className="inline-block align-top" variant="text" height={6} width={6} />
  );

  return (
    <Section {...props}>
      <div className="flex flex-row gap-6">
        <div className="flex-1 border rounded-md p-2">
          <div className="text-xl text-gray-600 font-medium pb-1 text-center">
            {isLoading && skeleton}
            {!isLoading && (data?.saved_searches_created.toLocaleString() || '-')}
          </div>
          <div className="flex flex-row items-center justify-center gap-2">
            <IconBox className="h-5 w-5 text-emerald-500 bg-emerald-100">
              <BookmarkIcon className="w-3 h-3" />
            </IconBox>
            <div className="text-xs text-gray-500 font-medium">Searches saved</div>
          </div>
        </div>
        <div className="flex-1 border rounded-md p-2">
          <div className="text-xl text-gray-600 font-medium pb-1 text-center">
            {isLoading && skeleton}
            {!isLoading && (data?.lists_created.toLocaleString() || '-')}
          </div>
          <div className="flex flex-row items-center justify-center gap-2">
            <IconBox className="h-5 w-5 text-amber-500 bg-amber-100">
              <ListIcon className="w-3 h-3" />
            </IconBox>
            <div className="text-xs text-gray-500 font-medium">Lists created</div>
          </div>
        </div>
        <div className="flex-1 border rounded-md p-2">
          <div className="text-xl text-gray-600 font-medium pb-1 text-center">
            {isLoading && skeleton}
            {!isLoading && (data?.exports_created.toLocaleString() || '-')}
          </div>
          <div className="flex flex-row items-center justify-center gap-2">
            <IconBox className="h-5 w-5 text-indigo-500 bg-indigo-100">
              <LayersIcon className="w-3 h-3" />
            </IconBox>
            <div className="text-xs text-gray-500 font-medium">Exports</div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ActivityInfo;
