import { useBookmarkList } from 'components/Lists/useBookmarkList';
import BookMarkHeader from './BookMarkHeader';
import { memo, useCallback } from 'react';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import { Button, Progress } from '@demandscience/ui';
import useTabs from 'pages/Dashboard/Search/Tabs/useTabs';
import { Route, Routes, useNavigate } from 'react-router-dom';
import BookMarkList from './BookMarkList';
import OpenBookmark from '../OpenBookmark/OpenBookmark';
import { BookmarkSelectionProvider } from './useBookmarkSelection';
import classNames from 'classnames';

const BookMarkHome = () => {
  const { data, setPage, page, setShow, setName, isLoading, setSort, sort } = useBookmarkList();

  const { setTab, getTabName } = useTabs();
  const navigate = useNavigate();

  const handleChange = useCallback(() => {
    setTab(getTabName(2));
  }, [setTab, getTabName]);

  const handleNavigation = useCallback(() => {
    navigate('/search');
    handleChange();
  }, [handleChange, navigate]);

  const onChange = (props) => {
    // todo
  };
  if (isLoading) {
    return (
      <Progress
        theme="primary"
        className={classNames('absolute top-0 bg-white h-[1.5px] invisible', {
          visible: true,
        })}
      />
    );
  }

  const allbookmarkids = data?.bookmarks.map((bookmark) => bookmark.id);

  const EmptyResultsIllustration = (
    <div className="flex flex-col items-center mt-1">
      <EmptyResults
        message="You haven't saved any bookmarks yet"
        illustration={<Illustration.EmptyAlt className="w-40 h-40 mb-6" />}
      ></EmptyResults>
      <div className="flex items-center mt-3">
        <p className="text-sm text-gray-500">
          Bookmarks are great way to record database changes for contacts or companies of intrest{' '}
        </p>
      </div>
      <Button
        className="flex-shrink-0 px-1 py-1 border-none group text-blue-500 hover:bg-primary-50 disabled:text-gray-300 disabled:hover:bg-transparent focus:ring-0 ml-2"
        borderless
        onClick={handleNavigation}
        aria-label="undo filter"
      >
        Save your first view now
      </Button>
    </div>
  );

  return (
    <BookmarkSelectionProvider>
      <BookMarkHeader
        handleNavigation={handleNavigation}
        setPage={setPage}
        page={page}
        count={data?.count}
        onChange={onChange}
        setShow={setShow}
        setName={setName}
        allBookmarkIds={allbookmarkids}
        setSort={setSort}
        sort={sort}
      ></BookMarkHeader>
      {data && !data?.bookmarks && EmptyResultsIllustration}
      <div className="columns-sm gap-4 py-4 space-y-4">
        {data &&
          data.bookmarks.map((bookmark) => (
            <div className="break-inside-avoid" key={bookmark.id}>
              <BookMarkList bookmark={bookmark}></BookMarkList>
              <Routes>
                <Route path={`bookmark/id`} element={<OpenBookmark />} />
              </Routes>
            </div>
          ))}{' '}
      </div>
    </BookmarkSelectionProvider>
  );
};

export default memo(BookMarkHome);
