import { Link as RouterLink } from 'react-router-dom';

import { DropdownMenu, DropdownMenuProps, IconButton } from '@demandscience/ui';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';
import { Member } from 'types';

import useModal from 'hooks/useModal';
import ReactivateOverlay from './ReactivateOverlay';
import SuspendOverlay from './SuspendOverlay';
import ResendInviteControl from './ResendInviteControl';
import DeleteControl from './DeleteControl';

interface MemberDropdownProps extends DropdownMenuProps {
  member: Member;
}

const MemberDropdown: React.FC<MemberDropdownProps> = ({ member, ...props }) => {
  const {
    open: openReactivate,
    openModal: openReactivateModal,
    closeModal: closeReactivateModal,
  } = useModal();
  const {
    open: openSuspend,
    openModal: openSuspendModal,
    closeModal: closeSuspendModal,
  } = useModal();

  return (
    <>
      <DropdownMenu
        buttonType="custom"
        customButton={
          <IconButton
            className="hover:bg-gray-100"
            size="sm"
            theme="default"
            Icon={MoreVerticalIcon}
          />
        }
        origin="bottom-right"
        {...props}
      >
        {member.state === 'invited' && (
          <ResendInviteControl as={DropdownMenu.Item} data={member}>
            Resend invitation
          </ResendInviteControl>
        )}
        {member.state === 'invited' && (
          <DeleteControl as={DropdownMenu.Item} data={member}>
            Cancel invitation
          </DeleteControl>
        )}
        {member.state === 'active' && (
          <DropdownMenu.Item onClick={openSuspendModal}>Deactivate account</DropdownMenu.Item>
        )}
        {member.state === 'suspended' && (
          <DropdownMenu.Item onClick={openReactivateModal}>Reactivate account</DropdownMenu.Item>
        )}
        <DropdownMenu.Item
          as={RouterLink}
          to={`/manage/team/${member.username}`}
          state={{ edit: true }}
        >
          Edit member info
        </DropdownMenu.Item>
      </DropdownMenu>
      <ReactivateOverlay member={member} open={openReactivate} onClose={closeReactivateModal} />
      <SuspendOverlay member={member} open={openSuspend} onClose={closeSuspendModal} />
    </>
  );
};

export default MemberDropdown;
