import { Routes, Route, Navigate } from 'react-router-dom';
import TrackRoute from 'components/TrackRoute';

import Layout from 'layouts/Auth';
import Signin from './Signin';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordConfirm from './ForgotPasswordConfirm';
import EmailConfirm from './EmailConfirm';
import Impersonate from './Impersonate';
// Uncomment after the launch of self-service
// import Signup from './Signup';
// import SignupConfirm from './SignupConfirm';
// import { showSignUp } from 'utils/auth';

const AuthRoutes = () => (
  <Routes>
    <Route path="*" element={<Layout />}>
      <Route
        path="signin"
        element={
          <TrackRoute title="Sign in">
            <Signin />
          </TrackRoute>
        }
      />
      <Route
        path="new"
        element={
          <TrackRoute title="First sign in">
            <Signin fromInvite />
          </TrackRoute>
        }
      />
      {/* Uncomment after the launch of self-service */}
      {/* {showSignUp && (
        // <>
        //   <Route
        //     path="signup"
        //     element={
        //       <TrackRoute title="Sign up">
        //         <Signup />
        //       </TrackRoute>
        //     }
        //   />
        //   <Route
        //     path="signup/confirm"
        //     element={
        //       <TrackRoute title="Confirm sign up">
        //         <SignupConfirm />
        //       </TrackRoute>
        //     }
        //   />
        // </>
      )} */}
      <Route
        path="forgotPassword"
        element={
          <TrackRoute title="Forgot password">
            <ForgotPassword />
          </TrackRoute>
        }
      />
      <Route
        path="forgotPassword/confirm"
        element={
          <TrackRoute title="Password confirm">
            <ForgotPasswordConfirm />
          </TrackRoute>
        }
      />
      <Route
        path="email/confirm"
        element={
          <TrackRoute title="Email confirm">
            <EmailConfirm />
          </TrackRoute>
        }
      />
      <Route path="impersonate/:targetUsername" element={<Impersonate />} />
      <Route path="*" element={<Navigate to="./signin" replace />} />
    </Route>
  </Routes>
);

export default AuthRoutes;
