import { useMutation } from '@tanstack/react-query';

import * as organizationApi from 'api/organization';

const useMutateFeatureRequest = (options?: Record<string, any>) => {
  const requestFeatures = useMutation(organizationApi.requestFeatures, {
    ...options,
  });

  return { requestFeatures };
};

export default useMutateFeatureRequest;
