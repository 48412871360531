import { createContext } from 'react';

export interface NotificationsContextInterface {
  closeNotifications: () => void;
  open: boolean;
  openNotifications: () => void;
}

const NotificationsContext = createContext<NotificationsContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  NotificationsContext.displayName = 'NotificationsContext';
}

export default NotificationsContext;
