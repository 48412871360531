import { ElementType } from 'react';
import classNames from 'classnames';
import { ButtonOwnProps, IconButton } from '@demandscience/ui';
import useNotificationsContext from './useNotificationsContext';

interface NotificationsDrawerControlProps extends ButtonOwnProps {
  as?: ElementType;
}

const NotificationsDrawerControl = ({
  as: Component = IconButton,
  className,
  ...props
}: NotificationsDrawerControlProps) => {
  const { open, openNotifications } = useNotificationsContext();

  // adding classes cursor-pointer and pointer-events-none are to have a workaround
  // to ignore a click when drawer is open. drawr is closing by global click listener (outside clic)
  // preventing click bubbling didn't work and this was only solution

  return (
    <div className={classNames({ 'cursor-pointer': open })}>
      <Component
        className={classNames(className, { 'pointer-events-none': open })}
        {...props}
        onClick={openNotifications}
      />
    </div>
  );
};

export default NotificationsDrawerControl;
