import classNames from 'classnames';
import { Icon } from '@demandscience/ui/dist/types';
import { HTMLAttributes } from 'react';

export interface OnboardingBoxProps extends HTMLAttributes<HTMLDivElement> {
  Icon: Icon;
  subtitle: string;
  title: string;
}

const OnboardingBox = ({ className, Icon, title, subtitle, ...props }: OnboardingBoxProps) => (
  <div
    className={classNames(
      className,
      'flex flex-col items-center w-full max-w-xs select-none text-gray-700 hover:text-blue-500 focus:text-blue-500 pt-6 pb-10 px-4 border border-gray-200 hover:border-blue-200 focus:border-blue-200 bg-gray-50 hover:bg-blue-50 focus:bg-blue-50 rounded-lg',
    )}
    {...props}
  >
    <Icon className="stroke-1" size={40} />
    <div className="text-sm text-center whitespace-pre-line font-semibold mt-7 mb-2">{title}</div>
    <div className="text-xs">{subtitle}</div>
  </div>
);

export default OnboardingBox;
