import { Link as RouterLink } from 'react-router-dom';
import Lists from 'components/ExclusionLists/Lists';
import AddExclusionListControl from 'components/ExclusionLists/AddExclusionListControl';
import { Button } from '@demandscience/ui';
import PlusIcon from '@demandscience/ui/icons/plus';

const ExclusionLists = () => {
  return (
    <div className="max-w-3xl">
      <div className="text-sm text-gray-700">
        Team suppression lists allows you to exclude records from all searches that you already have
        from other sources. <span className="font-medium">They are applied to the whole team.</span>
      </div>
      <div className="mt-6 mb-10 flex items-center gap-4">
        <AddExclusionListControl leftIcon={<PlusIcon size={20} />}>
          Add a list
        </AddExclusionListControl>
        <Button borderless as={RouterLink} to="/lists">
          See your lists
        </Button>
      </div>
      <Lists title="Suppression lists" />
    </div>
  );
};

export default ExclusionLists;
