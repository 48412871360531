import { Button } from '@demandscience/ui';
import useApiValidation from 'hooks/useApiValidation';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AggregateFilter, ModelType, Visibility } from 'types';
import BookMarkFormFields from './BookMarkFormFields';
import { BookMarkFormValues, BookMarkPayload, moduleMap } from 'types/BookMark';
import useModulesCustomization from 'components/ModulesCustomization/useModulesCustomization';
import useFilters from 'components/Filters/useFilters';
import { Aggregate as AggregateType } from 'types';
import useMutateBookMark from './useMutateBookMark';

interface BookMarkFormProps<F extends AggregateFilter> {
  aggregate: {
    count: number;
    values: AggregateType<F>[];
  };
  aggregateField: string;
  module_type_id: string;
  module_type_name: string;
  onCancel: () => void;
  onSuccess: () => void;
  type?: ModelType;
}

const BookMarkForm = ({
  onCancel,
  module_type_name,
  aggregate,
  aggregateField,
  onSuccess,
}: BookMarkFormProps<AggregateFilter>) => {
  const { control, handleSubmit, formState, watch, setValue, setError } =
    useForm<BookMarkFormValues>({
      defaultValues: {
        name: '',
        comment: '',
        visibility: Visibility.Private,
        automated_updates: null,
      },
    });
  const { isSubmitting } = formState;
  const { allViews } = useModulesCustomization();

  const { filters } = useFilters();
  const { create } = useMutateBookMark();

  const { apiError, setApiError, handleApiValidation } =
    useApiValidation<BookMarkFormValues>(setError);

  const buildPayload = ({
    members,
    notification_message,
    visibility,
    automated_updates,
    ...data
  }: BookMarkFormValues) => {
    const search_result = {
      aggregation_results: { [aggregateField]: aggregate.values },
    };
    let payload: BookMarkPayload = {
      view_id: allViews && allViews.length > 0 ? allViews[0].id : '',
      visibility,
      search_filters: {
        filters,
        aggregations: [
          {
            field_name: aggregateField,
          },
        ],
      },
      module_type_name,
      search_result,
      module_type_id: moduleMap[module_type_name],
      ...data,
      record_type: aggregateField.split('_')[0].trim(),
    };

    if (automated_updates !== null) {
      payload.automated_updates = automated_updates;
    }

    if (
      (visibility === Visibility.Public || visibility === Visibility.Restricted) &&
      notification_message
    ) {
      payload = { notification_message, ...payload };
    }

    if (visibility === Visibility.Restricted) {
      const share_with = members?.map(({ value }) => value);

      payload = { share_with, ...payload };
    }
    return payload;
  };

  const onSubmit: SubmitHandler<BookMarkFormValues> = async (data) => {
    setApiError(null);
    const payload: BookMarkPayload = buildPayload(data);

    try {
      await create.mutateAsync(payload);
      onCancel();
      onSuccess();
    } catch (e: any) {
      handleApiValidation(e);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        // this is required as this component appears inside another form component
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      <div className="space-y-4">
        <BookMarkFormFields
          control={control}
          formState={formState}
          watch={watch}
          setValue={setValue}
        />

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-8">
        <Button type="button" borderless onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" theme="primary" disabled={isSubmitting}>
          Bookmark
        </Button>
      </div>
    </form>
  );
};

export default BookMarkForm;
