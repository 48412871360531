import { HtmlHTMLAttributes } from 'react';
import BuildingIcon from '@demandscience/ui/icons/building-02';
import OnboardingBox from './OnboardingBox';

const OnboardingButtonNo2 = (props: HtmlHTMLAttributes<HTMLDivElement>) => {
  return (
    <OnboardingBox
      Icon={BuildingIcon}
      title={'Visualize your\npipeline'}
      subtitle="Use visual filters"
      {...props}
    />
  );
};

export default OnboardingButtonNo2;
