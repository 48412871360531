import { Company, monthLabels } from 'types';
import CompassIcon from '@demandscience/ui/icons/compass';
import ListItemText from 'components/Layout/ListItemText';
import { isEmpty, join, map, size } from 'lodash';
import TopicBadge from './TopicBadge';
import TopicList from './TopicList';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';

interface InstallTopicProps {
  className?: string;
  inline?: boolean;
  inlineText?: boolean;
  row?: Company;
}
const InstallTopic = ({ className, row, inline, inlineText }: InstallTopicProps) => {
  const listItems = map(row?.tech_installs, ({ name, month }) => ({
    label: month ? `${name} (${monthLabels[month]})` : name,
  }));

  if (isEmpty(listItems)) {
    return null;
  }

  if (inlineText) {
    const intentTopics = join(map(listItems, 'label'), ', ');

    return <Paragraph className={className}>{intentTopics}</Paragraph>;
  }

  if (inline) {
    const count = size(listItems);

    return (
      <TopicBadge
        className={className}
        Icon={CompassIcon}
        count={count}
        title="Technology install matches"
      >
        <TopicList items={listItems} />
      </TopicBadge>
    );
  }

  return (
    <ListItemText
      leadingContent={<CompassIcon className="w-5 h-5" />}
      overline="Technology install matches"
      title={<TopicList className="text-sm" items={listItems} show={3} />}
    />
  );
};

export default InstallTopic;
