import map from 'lodash/map';
import { debounce, find, flatten, includes, reject } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { Show, ModelType, ListOrigin, ListRecord } from 'types';
import { Combobox } from '@demandscience/ui';
import { useInfiniteLists } from './useInfiniteLists';
import { Props as SelectProps } from 'components/Input/Select';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

interface ListComboboxProps extends Omit<SelectProps, 'options' | 'value' | 'onChange'> {
  exclude?: [string];
  onChange: (value: string, list: ListRecord) => void;
  records_type?: ModelType;
  show?: Show;
  showCounts?: boolean;
  showUploads?: boolean;
  value?: string;
}

const ListCombobox = forwardRef(
  (
    {
      placeholder: defaultPlaceholder,
      value: listId,
      onChange,
      show = Show.Own,
      records_type,
      showCounts,
      showUploads,
      exclude,
      ...props
    }: ListComboboxProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [input, setInput] = useState<string | undefined>();
    const [loading, setLoading] = useState(false);
    const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } =
      useInfiniteLists(
        {
          index: 0,
          count: 25, // keeping 25 as default
          filter: {
            name: input,
            show,
            records_type,
            records_origins: showUploads
              ? [ListOrigin.Search, ListOrigin.Upload]
              : [ListOrigin.Search],
          },
          sort: {
            by: 'updated_at',
            descending: true,
          },
        },
        {},
      );

    const { pages } = data || {};
    const lists = flatten(map(pages, 'lists'));

    const options = useMemo(() => {
      const listsWithoutExclude = reject(lists, ({ id }) => includes(exclude, id));

      return map(listsWithoutExclude, ({ id: value, name: label, number_of_records: count }) => ({
        value,
        label,
        trailingContent: showCounts && (
          <div className="text-gray-500">{count.toLocaleString()}</div>
        ),
      }));
    }, [lists, showCounts, exclude]);
    const value = find(options, (option) => option.value === listId);
    let placeholder = defaultPlaceholder;

    if (isLoading) {
      placeholder = 'Loading...';
    } else if (isError) {
      placeholder = 'Unable to load lists';
    } else if (isEmpty(options)) {
      placeholder = 'No list found';
    }

    const handleChange = useCallback(
      (selectOption?: { value: string }) => {
        if (selectOption) {
          const { value } = selectOption;
          const list = lists.find((list) => list.id === value);

          if (list && onChange) {
            onChange(list.id, list);
          }
        }
      },
      [lists, onChange],
    );

    useEffect(() => {
      if (!isFetchingNextPage) {
        setLoading(false);
      }
    }, [isFetchingNextPage]);

    const handleFetchOptions = useCallback(() => {
      if (loading || !hasNextPage) return;
      setLoading(true);
      fetchNextPage();
    }, [fetchNextPage, hasNextPage, loading]);

    const debuncedInput = useMemo(
      () =>
        debounce((value: string) => {
          setInput(value);
        }, 150),
      [],
    );

    const handleInputChange = useCallback(
      (value: string) => {
        debuncedInput(value);
      },
      [debuncedInput],
    );

    return (
      <Combobox
        {...props}
        ref={ref}
        value={value}
        // @ts-expect-error demandscience-ui issue
        onChange={handleChange}
        lazyLoading={loading}
        onLazyLoad={handleFetchOptions}
        options={options}
        placeholder={placeholder}
        disabled={isLoading || isError}
        hideClear
        onInputChange={handleInputChange}
      />
    );
  },
);

ListCombobox.displayName = 'ListCombobox';

export default ListCombobox;
