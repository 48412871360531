import { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { TextField, Button } from '@demandscience/ui';

import Alert from 'components/Alert';
import useAuth from './useAuth';
import { trim } from 'lodash';
import { CodeDeliveryDetails } from 'types';

type ForgotPasswordFormValues = {
  username: string;
};

export interface ForgotPasswordProps {
  onForgotPassword: (username: string, codeDeliveryDetails: CodeDeliveryDetails) => void;
}

const ForgotPassword = ({ onForgotPassword }: ForgotPasswordProps) => {
  const [apiError, setApiError] = useState(null);
  const { forgotPassword } = useAuth();
  const { control, handleSubmit, formState } = useForm({
    defaultValues: { username: '' },
  });
  const { isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<ForgotPasswordFormValues> = async (data) => {
    const { username } = data;
    setApiError(null);

    try {
      const { CodeDeliveryDetails: codeDeliveryDetails } = await forgotPassword(trim(username));

      onForgotPassword(username, codeDeliveryDetails);
    } catch (e: any) {
      const { message } = e;

      setApiError(message);
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="space-y-4">
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextField
              id="input_email"
              label="Email"
              placeholder="Enter an email"
              variant="outlined"
              size="lg"
              required
              autoComplete="email"
              error={errors.username?.message}
              {...field}
            />
          )}
          rules={{ required: 'Required field' }}
        />
        {apiError && <Alert message={apiError} />}
      </div>

      <Button
        id="action_reset_password"
        className="w-full mt-6"
        size="lg"
        type="submit"
        disabled={isSubmitting}
      >
        Reset password
      </Button>
    </form>
  );
};

export default ForgotPassword;
