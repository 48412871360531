import { useCallback, useMemo, useRef, useState } from 'react';
import { Button } from '@demandscience/ui';
import { find, isEmpty, isEqual, last, map } from 'lodash';
import TextMenuItem from '../Filter/Dropdown/TextMenuItem';
import { SavedSearch } from 'types';
import FilterInput from '../Filters/FilterInput';
import Inline from 'components/Filter/Inline';
import usePersonas from 'components/Personas/usePersonas';
import usePersonasFilters from './usePersonasFilters';
import SavedPersonasList from './SavedPersonasList';
import SavePersonaOverlay from './SavePersonaOverlay';
import EditPersonaOverlay from './EditPersonaOverlay';
import DeletePersonaOverlay from './DeletePersonaOverlay';
import useModal from 'hooks/useModal';
import useFilters from 'components/Filters/useFilters';
import intersection from 'lodash/intersection';

interface PersonasProps {
  dense?: boolean;
}

const getBadgeProps = (value: SavedSearch) => {
  const { id, name: label } = value;

  return {
    key: id,
    label,
  };
};

const matchesLabel = (label: string, query: string) => {
  return label.toLowerCase().includes(query.toLowerCase());
};

const Personas = ({ dense }: PersonasProps) => {
  const [query, setQuery] = useState('');
  const { open: openSave, openModal: openSaveModal, closeModal: closeSaveModal } = useModal();
  const { open: openEdit, openModal: openEditModal, closeModal: closeEditModal } = useModal();
  const { open: openDelete, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();
  const inputRef = useRef<HTMLInputElement>(null);
  const { data, isFetching, isError } = usePersonas();
  const { current: currentPersonasFilters, clear, apply } = usePersonasFilters();

  const { filters } = useFilters() || {};

  const [personaSelected, setPersonaSelected] = useState<SavedSearch>();

  const contactFilters = ['contact_name', 'job_title', 'job_function', 'job_level'];
  const selectedFiltersValues = Object.keys(filters);
  const contactFiltersSelected = intersection(selectedFiltersValues, contactFilters);
  const isContactFilterSelected = contactFiltersSelected.length > 0;

  let options = map(data?.searches, (value) => ({
    value,
    label: value.name,
    owner: value.owner,
    visibility: value.visibility,
  }));

  if (query) {
    options = options.filter((option) => matchesLabel(option.label, query));
  }

  const value = useMemo(() => {
    const personas = find(data?.searches, (value) =>
      isEqual(currentPersonasFilters, value.filters),
    );

    return personas ? [personas] : [];
  }, [currentPersonasFilters, data?.searches]);

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
  }, []);

  const handleChange = useCallback(
    (value: SavedSearch[]) => {
      if (isEmpty(value)) {
        return;
      }

      // the last item is the the last selected
      // this way we have single value selection
      const savedSearch = last(value)!;

      apply(savedSearch.filters);

      // focus input on change
      inputRef.current?.focus();
    },
    [apply],
  );

  const handleInputChange = useCallback((e: React.SyntheticEvent) => {
    const { target } = e;

    setQuery((target as HTMLInputElement).value);
  }, []);

  const handleDelete = useCallback(() => {
    clear();
  }, [clear]);

  const handleClear = useCallback(() => {
    clear();

    handleInputClear();
  }, [clear, handleInputClear]);

  return (
    <>
      <Inline
        multiple // multiple cobobox is kept open after selection
        by="id"
        value={value}
        onChange={handleChange}
        button={(props) => (
          <FilterInput
            {...props}
            hideInput={isEmpty(options) && !query}
            ref={inputRef}
            name="Personas"
            dense={dense}
            onClear={handleClear}
            onDelete={handleDelete}
            onInputChange={handleInputChange}
            onInputClear={handleInputClear}
            placeholder="Select a persona to apply"
            getBadgeProps={getBadgeProps}
          />
        )}
        menuClassName="overflow-visible"
      >
        {isError && <TextMenuItem>Error fetching persona values</TextMenuItem>}
        {!isError && (
          <>
            {isFetching && <TextMenuItem>Loading...</TextMenuItem>}
            {!isFetching && isEmpty(options) && <TextMenuItem>No personas found</TextMenuItem>}
            {!isEmpty(options) && (
              <SavedPersonasList
                options={options}
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
                setPersonaSelected={setPersonaSelected}
              />
            )}
            <div className="px-4 py-4 border-t-[1px] border-gray-100">
              <Button
                size="md"
                onClick={openSaveModal}
                className="whitespace-nowrap"
                disabled={!isContactFilterSelected}
              >
                Save contacts filters as persona
              </Button>
            </div>
          </>
        )}
      </Inline>
      <SavePersonaOverlay
        open={openSave}
        onClose={closeSaveModal}
        filters={currentPersonasFilters}
      />
      <EditPersonaOverlay open={openEdit} onClose={closeEditModal} data={personaSelected} />
      <DeletePersonaOverlay open={openDelete} onClose={closeDeleteModal} data={personaSelected} />
    </>
  );
};

export default Personas;
