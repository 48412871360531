import { Badge } from '@demandscience/ui';
import InstallButton from './InstallButton';

const BookmarkletTip = () => {
  return (
    <div
      className="px-16 py-6 border border-gray-200 rounded-lg"
      style={{
        background: 'linear-gradient(93.68deg, #4D94FD 5.54%, #0047B0 84.87%)',
      }}
    >
      <div className="flex flex-row gap-4 items-center justify-between">
        <div>
          <div className="text-lg text-white">
            Klarity Chrome Extension
            <Badge className="ml-2 bg-black text-white">NEW</Badge>
          </div>
          <div className="text-base text-white font-lg">Prospecting on LinkedIn made easier</div>
        </div>
        <div>
          <InstallButton className="text-gray-900" theme="white">
            <span className="px-7 whitespace-nowrap">Install now</span>
          </InstallButton>
        </div>
      </div>
    </div>
  );
};

export default BookmarkletTip;
