import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import useAuth from './useAuth';

interface RequireAuthProps {
  children: React.ReactElement;
  smartRedirect?: boolean;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ smartRedirect = false, children }) => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // run navigate if user changes
  useEffect(() => {
    if (!user) {
      if (smartRedirect || /^\/(settings|manage)/.test(location.pathname)) {
        // Redirect them to the /signin page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they signin, which is a nicer user experience
        // than dropping them off on the home page.
        navigate('/auth/signin', { replace: true, state: { from: location } });
      } else {
        // not saving location to
        navigate('/auth/signin', { replace: true });
      }
    }
  }, [user, smartRedirect]); // eslint-disable-line react-hooks/exhaustive-deps

  // handle intital rendered before above effect kicks in
  if (!user) {
    return null;
  }

  return children;
};

export default RequireAuth;
