import { Button, ButtonOwnProps } from '@demandscience/ui';
import useSupportRequestContext from 'components/SupportRequest/useSupportRequestContext';
import { Link as RouterLink } from 'react-router-dom';
import useOrganization from './useOrganization';

const BuyCreditsButton = (props: ButtonOwnProps) => {
  const { data: organization } = useOrganization();
  const { buyCredits } = useSupportRequestContext();

  if (!organization) {
    return null;
  }

  if (organization.selfservice_subscription_disabled) {
    return (
      <Button
        id="action_buy_more_credits"
        size="md"
        theme="primary"
        {...props}
        onClick={buyCredits}
      >
        Buy credits
      </Button>
    );
  }

  return (
    <Button
      id="action_buy_more_credits"
      size="md"
      theme="primary"
      as={RouterLink}
      to="/buy"
      {...props}
    >
      Buy credits
    </Button>
  );
};

export default BuyCreditsButton;
