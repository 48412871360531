import api from 'api';
import { Auth } from '@aws-amplify/auth';
import { AxiosRequestConfig } from 'axios';

const isAccessTokenRequired = (request: AxiosRequestConfig) => {
  const { method, url } = request;

  // access token is required when updating current account name, password, email or delete account/organization
  return (
    (method === 'patch' &&
      (url === '/account/me/name' ||
        url === '/account/me/password' ||
        url === '/account/me/email')) ||
    (method === 'delete' &&
      (url === '/account/me?with_org_and_siblings=true' ||
        url === '/account/me?with_org_and_siblings=false'))
  );
};

const jwtInterceptor = (onAuthError: () => void) => {
  api.interceptors.request.use(
    async (request) => {
      const isApiUrl = request.baseURL === process.env.REACT_APP_API_ENDPOINT;

      if (isApiUrl) {
        try {
          const session = await Auth.currentSession();

          if (request.headers) {
            if (isAccessTokenRequired(request)) {
              request.headers.Authorization = 'Bearer ' + session.getAccessToken().getJwtToken();
            } else {
              request.headers.Authorization = 'Bearer ' + session.getIdToken().getJwtToken();
            }
          }
        } catch {
          // thrown if no session exists
        }
      }

      return request;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  // Add a 401 response interceptor
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        onAuthError();
      }

      return Promise.reject(error);
    },
  );
};

export default jwtInterceptor;
