import React from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Comment } from 'types/BookMark';
import { Avatar } from '@demandscience/ui';
import useAuth from 'components/Auth/useAuth';

interface CommentComponentProps {
  comments: Comment[];
}

const CommentComponent: React.FC<CommentComponentProps> = ({ comments }) => {
  const { user } = useAuth();
  return (
    <div className="space-y-4">
      {comments?.map((comment, index) => (
        <div key={index} className="flex items-start space-x-4">
          <div className="flex-shrink-0">
            <Avatar className="w-8 h-8" name={comment.user.charAt(0) as string} />
          </div>
          <div className="flex-1">
            <div className="text-md ml-2">
              <span className="font-medium text-gray-900">
                {comment.user === user?.name ? 'You' : comment.user}
              </span>
              <span className="ml-2 text-gray-500">
                {formatDistanceToNow(new Date(comment.created_at))} ago
              </span>
            </div>
            <div className="mt-3 text-sm text-black-900 ml-2">{comment.comment}</div>

            {/*  // todo: add like and reply button once api is ready
            <div className="mt-2 flex space-x-2">
              <button className="text-sm text-blue-600">Like</button>
              <button className="text-sm text-blue-600">Reply</button>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentComponent;
