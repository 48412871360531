import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchAutocomplete } from 'api/search';
import size from 'lodash/size';
import map from 'lodash/map';

import { AutocompleteFieldName, AutocompleteParams } from 'types';
import { Option } from '../types';

const fetchOptions = async (params: AutocompleteParams) => {
  const keywords = await fetchAutocomplete(AutocompleteFieldName.BusinessKeyword, params);
  const data = map(keywords, (value) => {
    const { keyword } = value;

    return {
      id: keyword,
      value,
      label: keyword,
      // eslint-disable-next-line
    } as Option<typeof keywords[number]>;
  });

  return data;
};

const useBusinessKeywordAutocomplete = (
  params: AutocompleteParams,
  options?: Record<string, any>,
) => {
  const query = useInfiniteQuery(
    ['suggestions', AutocompleteFieldName.BusinessKeyword, params],
    ({ pageParam: index = 0 }) => fetchOptions({ ...params, index }),
    {
      refetchOnWindowFocus: false, // no need to refetch on window focus
      staleTime: 12 * 60 * 60 * 1000, // 12 hours
      getNextPageParam: (lastPage: any, pages) =>
        size(lastPage) === (params.count ?? 20) ? size(pages) : undefined,
      ...options,
      enabled: size(params.value) >= 3 && options?.enabled !== false, // api endpoints requires min 3 characters
    },
  );

  return query;
};

export default useBusinessKeywordAutocomplete;
