import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { IconButton, IconButtonProps } from '@demandscience/ui';

import { Navigation } from './types';

interface NavButtonProps extends IconButtonProps {
  navigation: Navigation;
}

const NavButton = ({ navigation, theme, ...props }: NavButtonProps) => {
  const { id, to, label, Icon } = navigation;

  return (
    <NavLink
      key={to}
      id={id}
      to={to}
      end={to === '.'}
      tabIndex={-1}
      className="flex flex-col items-center gap-1 group"
    >
      {({ isActive }) => (
        <>
          <IconButton
            className={classNames('p-[5px]', {
              // we need darker background as sidebar background is bit gray-ish
              [`bg-${theme}-100 hover:bg-${theme}-100`]: isActive,
              'group-hover:bg-gray-200': !isActive,
            })}
            {...props}
            theme={isActive ? theme : 'default'}
            Icon={Icon}
          />
          <div
            className={classNames('text-xs text-gray-700', {
              'font-semibold': isActive,
            })}
          >
            {label}
          </div>
        </>
      )}
    </NavLink>
  );
};

export default NavButton;
