import useAuth from 'components/Auth/useAuth';
import { KlarityFeature, ModelType, listTypeOptions } from 'types';

const useKlarityFeatures = () => {
  const { user } = useAuth();
  const { organization } = user ?? {};
  const { disabled_features, lab_disabled } = organization ?? {};

  const listTypeToKlarityFeature = (listType: ModelType) => {
    switch (listType) {
      case ModelType.Contact:
        return KlarityFeature.Contacts;
      case ModelType.Company:
        return KlarityFeature.Companies;
    }
  };

  const availableListTypeOptions = listTypeOptions.filter((type) => {
    if (!disabled_features) {
      return type;
    }
    if (!disabled_features.includes(listTypeToKlarityFeature(type.value))) {
      return type;
    }
    return false;
  });

  return { availableListTypeOptions, disabled_features, lab_disabled };
};

export default useKlarityFeatures;
