import OnboardingList from 'components/Onboarding/OnboardingList';
import { Button } from '@demandscience/ui';
import ArrowRightIcon from '@demandscience/ui/icons/arrow-right';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const Onboarding = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  return (
    <div className="w-[100vw] relative left-1/2 right-1/2 -ml-[50vw] -mr-[50vw]">
      <div className="flex flex-col gap-10 items-center px-4">
        <div className="text-center">
          <div className="text-lg font-medium text-gray-500 mb-1">Welcome to Klarity!</div>
          <div className="text-xl font-semibold text-gray-700">Start prospecting in minutes</div>
        </div>
        <OnboardingList className="w-full max-w-[740px]" />
        <Button
          onClick={handleClick}
          size="lg"
          theme="primary"
          borderless
          rightIcon={<ArrowRightIcon size={22} />}
        >
          Just show my account
        </Button>
      </div>
    </div>
  );
};

export default Onboarding;
