import { useState, useEffect } from 'react';
import { forEach } from 'lodash';

type ErrorState = ErrorEvent | null;

interface ScriptAttributes extends Partial<HTMLScriptElement> {}

const useScript = (attributes: ScriptAttributes): [boolean, ErrorState] => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorState>(null);

  useEffect(() => {
    let scriptEl: HTMLScriptElement;

    const handleLoad = () => {
      setLoading(false);
    };
    const handleError = (error: ErrorEvent) => {
      setError(error);
    };

    scriptEl = document.createElement('script');

    forEach(attributes, (value, key) => {
      if (typeof value === 'string') {
        scriptEl.setAttribute(key, value);
      } else {
        // @ts-expect-error
        scriptEl[key] = value;
      }
    });

    scriptEl.addEventListener('load', handleLoad);
    scriptEl.addEventListener('error', handleError);

    document.head.appendChild(scriptEl);

    return () => {
      if (scriptEl) {
        scriptEl.removeEventListener('load', handleLoad);
        scriptEl.removeEventListener('error', handleError);

        scriptEl.remove();
      }
    };
  }, [attributes]);

  return [loading, error];
};

export default useScript;
