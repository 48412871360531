import { Alert, Button, Modal, RadioList } from '@demandscience/ui';
import { useState } from 'react';
import useMutateBookMark from './useMutateBookMark';
import CheckCircleIcon from '@demandscience/ui/icons/check-circle';
import { downloadExport } from 'api/bookmark';
interface DownloadModalProps {
  getName: (id: string) => string;
  onClose: () => void;
  onDownload?: (format: string) => void;
  open: boolean;
  selectedBookmarks?: string[];
}
export const BookmarkDownload = ({
  open,
  onClose,
  selectedBookmarks,
  getName,
}: DownloadModalProps) => {
  const [selectedFormat, setSelectedFormat] = useState<string | null>('csv');
  const [async, setasync] = useState<boolean>(false);
  const { ExportMultipleBookmarks } = useMutateBookMark();
  const { mutateAsync } = ExportMultipleBookmarks;
  const handleDownload = async () => {
    if (selectedFormat) {
      if (selectedBookmarks && selectedBookmarks?.length === 1) {
        const id = selectedBookmarks[0];
        downloadExport(id, getName(id));
        setasync(false);
        onClose();
        return;
      }

      if (selectedBookmarks && selectedBookmarks?.length > 0) {
        const ExportResponds = await mutateAsync({
          bookmark_ids: selectedBookmarks,
          export_type: selectedFormat,
        });

        ExportResponds?.status && setasync(true);
      }
    }
  };

  const closePopup = () => {
    onClose();
    setasync(false);
  };

  const asyncAlert = (
    <>
      <div className="space-y-2">
        <Alert
          className="bg-transparent"
          severity="neutral"
          variant="borderless"
          customIcon={<CheckCircleIcon className="w-5 h-5 mr-4 text-success-500" />}
        >
          <Alert.Header>
            <Alert.SubTitle>
              Your export is being processed in the background...
              <br />
              We will notify you when it is ready.
            </Alert.SubTitle>
          </Alert.Header>
        </Alert>
      </div>
      <div className="flex flex-row gap-2 justify-end mt-10">
        <Button size="md" borderless onClick={closePopup}>
          Close
        </Button>
      </div>
    </>
  );

  return (
    <div>
      <Modal
        aria-labelledby="create-list-dialog-title"
        className="max-w-md sm:max-w-md overflow-visible"
        rootClassName="z-30"
        open={open}
        onClose={onClose}
      >
        <Modal.Title id="create-list-dialog-title" className="flex mb-2">
          Download bookmarks
        </Modal.Title>
        {async ? (
          asyncAlert
        ) : (
          <>
            <div className="mb-4">Choose a format</div>
            <div className="mb-4 flex items-center space-x-2">
              <div className="max-w-xs">
                <RadioList
                  onChange={(value) => setSelectedFormat(value)}
                  options={[
                    {
                      label: 'CSV',
                      value: 'csv',
                    },
                  ]}
                  radioIconClassNames="mr-2"
                  size="lg"
                  theme="primary"
                  value="csv"
                />
              </div>
            </div>{' '}
            <div className="flex justify-end space-x-4">
              <Button type="button" borderless onClick={onClose}>
                Cancel
              </Button>
              <Button
                theme="primary"
                onClick={handleDownload}
                disabled={ExportMultipleBookmarks.isLoading}
              >
                Download
              </Button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};
