import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import useAuth from 'components/Auth/useAuth';
import { Button, Spinner, TextField, Combobox, Link, DrawerRootProps } from '@demandscience/ui';
import Drawer from 'components/Drawer';
import Avatar from 'components/Avatar';
import Select from 'components/Input/Select';
import ListItemText from 'components/Layout/ListItemText';
import MailIcon from '@demandscience/ui/icons/mail';
import DollarSignIcon from '@demandscience/ui/icons/dollar-sign';
import UploadCloudIcon from '@demandscience/ui/icons/upload-cloud';
import SearchIcon from '@demandscience/ui/icons/search';
import ClockIcon from '@demandscience/ui/icons/clock';
import LogInIcon from '@demandscience/ui/icons/log-in';
import ListIcon from '@demandscience/ui/icons/list-02';
import includes from 'lodash/includes';
import { EditMemberFormValues, Role, allocation } from 'types';
import useSnackbar from 'components/Snackbar/useSnackbar';
import AvatarUpload from 'components/Avatar/Upload';
import useMember from './useMember';
import useMutateMember from './useMutateMember';
import ToggleStateControl from './ToggleStateControl';
import ResendInviteControl from './ResendInviteControl';
import DeleteControl from './DeleteControl';
import StateBadge from './StateBadge';
import CreditsQuotaInputController from 'components/Input/CreditsQuotaInputController';
import { AxiosError } from 'axios';
import { dirtyValues } from 'utils/reactHookForm';

const dateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
} as Intl.DateTimeFormatOptions;

interface DetailsSidebarProps extends DrawerRootProps {
  edit?: boolean;
  id: string;
  onNext?: () => void;
  onPrev?: () => void;
}

const DetailsSidebar: React.FC<DetailsSidebarProps> = ({
  id,
  onPrev,
  onNext,
  edit: defaultEdit,
  ...props
}) => {
  const [edit, setEdit] = useState(defaultEdit);
  const { control, handleSubmit, reset, formState, watch, setValue } =
    useForm<EditMemberFormValues>();
  const { data: member, isLoading, isError, error } = useMember(id);
  const { update } = useMutateMember();
  const { showMessage } = useSnackbar();
  const { isSubmitting, errors } = formState;

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      const { code } = e;

      if (code === 'ArrowLeft' && onPrev) {
        onPrev();
      } else if (code === 'ArrowRight' && onNext) {
        onNext();
      }
    },
    [onNext, onPrev],
  );

  const allocation: allocation = watch('allocation');
  useEffect(() => {
    if (allocation === 'unlimited') {
      setValue('bulk_credits.quota', -1);
    }
  }, [allocation, setValue]);

  const handleEdit = useCallback(() => {
    setEdit(true);
  }, []);

  const handleCancel = useCallback(() => {
    setEdit(false);

    reset();
  }, [reset]);

  const getDirtyFields = formState.dirtyFields;
  const onSubmit: SubmitHandler<EditMemberFormValues> = async (data) => {
    const { allocation, role, ...rest } = data;
    let payload = dirtyValues(getDirtyFields, rest);

    if (payload.bulk_credits) {
      payload.bulk_credits = {
        quota: parseInt(payload.bulk_credits.quota, 10),
        allocation: allocation,
      };
    }
    if (getDirtyFields.role) {
      payload = {
        ...payload,
        roles: (role === 'user' ? ['user'] : ['user', 'manager']) as Role[],
      };
    }

    try {
      await update.mutateAsync({ id, ...payload });

      setEdit(false);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to update a member', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  };

  const { user } = useAuth();
  const isAccountOwner = member?.username !== user?.username;
  const isManager = includes(member?.roles, Role.Manager);
  const isMemberActive = member?.state === 'active';
  const isMemberSuspended = member?.state === 'suspended';
  const isMemberInvited = member?.state === 'invited';

  let errorStatus: any = {};
  errorStatus.member = error;
  const isStatus404 = errorStatus?.member?.response.status === 404;

  return (
    <Drawer id="member_detail_drawer" title="Member profile" onKeyDown={handleKeyDown} {...props}>
      {(isLoading || isError) && (
        <div className="px-6 py-4">
          {isLoading && <Spinner size="lg" />}

          {isError &&
            (isStatus404 ? (
              <>
                <p className="text-sm text-gray-600 mb-1">No member found</p>
                <p className="text-sm text-gray-500">Maybe the user left</p>
              </>
            ) : (
              <p className="text-sm text-error-500">
                {(error instanceof Error && error?.message) || 'Unexpected error'}
              </p>
            ))}
        </div>
      )}

      {member && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-6 py-3 border-b">
            {!edit && (
              <div className="flex flex-row items-center justify-between">
                <Button size="sm" borderless onClick={handleEdit}>
                  Edit
                </Button>
                {!isMemberInvited && (
                  <ToggleStateControl
                    member={member}
                    // @ts-expect-error demandscience-ui issue
                    size="sm"
                    className="text-gray-500"
                    theme="white"
                    type="button" // needed to prevent outer form submission
                  >
                    {isMemberActive && 'Deactivate account'}
                    {isMemberSuspended && 'Reactivate account'}
                  </ToggleStateControl>
                )}
                {isMemberInvited && (
                  <div className="space-x-2">
                    <ResendInviteControl
                      data={member}
                      // @ts-expect-error demandscience-ui issue
                      size="sm"
                      className="text-gray-500"
                      theme="white"
                      type="button" // needed to prevent outer form submission
                    >
                      Resend invitation
                    </ResendInviteControl>
                    <DeleteControl
                      data={member}
                      onSuccess={props.onClose}
                      // @ts-expect-error demandscience-ui issue
                      size="sm"
                      className="text-gray-500"
                      theme="white"
                      type="button" // needed to prevent outer form submission
                    >
                      Cancel invitation
                    </DeleteControl>
                  </div>
                )}
              </div>
            )}
            {edit && (
              <div className="space-x-2">
                <Button size="sm" type="submit" disabled={isSubmitting || !formState.isDirty}>
                  Save
                </Button>
                <Button size="sm" type="button" borderless onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            )}
          </div>

          {edit && (
            <div className="px-6 py-4 space-y-4 ">
              <div className="flex flex-row justify-between pb-4">
                <Avatar className="w-20 h-20 text-2xl" user={member} />
                <AvatarUpload
                  className="flex-row gap-2 self-end"
                  size="sm"
                  id={member.username}
                  hasAvatar={member.has_avatar}
                />
              </div>

              <Controller
                name="name"
                defaultValue={member.name}
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Name"
                    variant="outlined"
                    required
                    error={errors.name?.message}
                    {...field}
                  />
                )}
                rules={{ required: 'Required field' }}
              />

              <Controller
                name="role"
                defaultValue={isManager ? Role.Manager : Role.User}
                control={control}
                render={({ field }) => (
                  <Select
                    label="Member role"
                    component={Combobox}
                    // @ts-expect-error demandscience-ui issue
                    hideClear
                    disabled={isAccountOwner}
                    options={[
                      { label: 'User', value: 'user' },
                      { label: 'Manager', value: 'manager' },
                    ]}
                    {...field}
                  />
                )}
              />

              <Controller
                name="email"
                defaultValue={member.email}
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Email"
                    variant="outlined"
                    required
                    error={errors.email?.message}
                    {...field}
                  />
                )}
                rules={{ required: 'Required field' }}
              />

              <Controller
                name="allocation"
                defaultValue={member.bulk_credits.allocation}
                control={control}
                render={({ field }) => (
                  <Select
                    label="Credit allocation"
                    component={Combobox}
                    // @ts-expect-error demandscience-ui issue

                    hideClear
                    options={[
                      { label: 'Unlimited', value: 'unlimited' },
                      { label: 'Monthly', value: 'monthly' },
                      { label: 'Fixed', value: 'fixed' },
                    ]}
                    {...field}
                  />
                )}
                rules={{ required: 'Required field' }}
              />

              <CreditsQuotaInputController<EditMemberFormValues>
                allocation={allocation}
                name="bulk_credits.quota"
                defaultValue={member.bulk_credits.quota}
                control={control}
              />
            </div>
          )}

          {!edit && (
            <>
              <ListItemText
                leadingContent={<Avatar className="w-8 h-8" user={member} />}
                title={<div className="font-semibold">{member.name}</div>}
              />
              <ListItemText title={<StateBadge member={member} />} />
              <ListItemText
                leadingContent={<MailIcon className="w-5 h-5" />}
                overline="Email"
                title={
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                    <Link
                      className="inline font-normal"
                      href={`mailto:${member.email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {member.email}
                    </Link>
                  </div>
                }
              />
              <ListItemText
                leadingContent={<DollarSignIcon className="w-5 h-5" />}
                overline="Monthly credits"
                title={`${member.bulk_credits.current_period_used.toLocaleString()} / ${member.bulk_credits.available.toLocaleString()}`}
              />

              <ListItemText
                leadingContent={<DollarSignIcon className="w-5 h-5" />}
                overline="Total credits"
                title={member.bulk_credits.used.toLocaleString()}
              />
              <ListItemText
                leadingContent={<UploadCloudIcon className="w-5 h-5" />}
                overline="Total exports"
                title={member.number_of_exports?.toLocaleString() ?? 0}
              />
              <ListItemText
                leadingContent={<ListIcon className="w-5 h-5" />}
                overline="Lists"
                title={member.number_of_lists?.toLocaleString() ?? 0}
              />
              <ListItemText
                leadingContent={<SearchIcon className="w-5 h-5" />}
                overline="Saved searches"
                title={member.number_of_saved_searches?.toLocaleString() ?? 0}
              />
              {isMemberActive && (
                <>
                  {member.last_signin_at && (
                    <ListItemText
                      leadingContent={<ClockIcon className="w-5 h-5" />}
                      overline="Last Sign-in"
                      title={
                        <>
                          <div>
                            {new Date(member.last_signin_at).toLocaleDateString(
                              undefined,
                              dateOptions,
                            )}
                          </div>
                          <div>({member.number_of_signins} Sign-ins)</div>
                        </>
                      }
                    />
                  )}

                  <ListItemText
                    leadingContent={<LogInIcon className="w-5 h-5" />}
                    overline="Joined"
                    title={new Date(member.created_at).toLocaleDateString(undefined, dateOptions)}
                  />
                </>
              )}
              {isMemberInvited && (
                <ListItemText
                  leadingContent={<LogInIcon className="w-5 h-5" />}
                  overline="Invited at"
                  title={new Date(member.invited_at).toLocaleDateString(undefined, dateOptions)}
                />
              )}
            </>
          )}
        </form>
      )}
    </Drawer>
  );
};

export default DetailsSidebar;
