import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateOrganization } from 'api/organization';

const useTeamDetails = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();

  const updateOrganizationName = useMutation(updateOrganization, {
    onSettled: () => {
      queryClient.invalidateQueries(['organization']);
    },
    ...options,
  });

  return { updateOrganizationName };
};

export default useTeamDetails;
