import { Routes, Route, Navigate } from 'react-router-dom';

import RequireAuth from 'components/Auth/RequireAuth';
import RequireNoAuth from 'components/Auth/RequireNoAuth';

import Home from 'pages/Home';
import Auth from 'pages/Auth';
import Welcome from 'pages/Welcome';
import Dashboard from 'pages/Dashboard';

const AppRoutes = () => (
  <Routes>
    <Route path="invitation" element={<Navigate to="/auth/new" replace />} />
    <Route path="home/*" element={<Home />} />
    <Route
      path="auth/*"
      element={
        <RequireNoAuth>
          <Auth />
        </RequireNoAuth>
      }
    />
    <Route
      path="welcome/*"
      element={
        <RequireAuth>
          <Welcome />
        </RequireAuth>
      }
    />
    <Route
      path="*"
      element={
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      }
    />
  </Routes>
);

export default AppRoutes;
