import { Button, Modal, ModalProps } from '@demandscience/ui';
import Paragraph from 'components/Typography/Paragraph';
import { map, sortBy, without } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { moduleColumns, DataModule, defaultModuleColumns, allModuleColumns } from 'types';
import SortableContainer from 'components/SortableList/SortableContainer';
import SortableItem from 'components/SortableList/SortableItem';
import useCustomization from 'components/ModulesCustomization/useModulesCustomization';
import ColumnBox from 'components/SearchCustomization/CustomizeColumnsOverlay/ColumnBox';
import DragPreview from './DragPreview';

export interface CustomizeViewOverlayProps extends Omit<ModalProps, 'children'> {}

const CustomizeViewOverlay = ({ ...props }: CustomizeViewOverlayProps) => {
  const { onClose } = props;
  const { allModules, onChange } = useCustomization() || [];
  const [modules, setModules] = useState<DataModule[]>([]);

  // This is the list of available modules
  const modulesAvailable = useMemo(() => {
    let res: DataModule[] = modules || [];
    res = without(allModuleColumns, ...(modules || []));
    // available modules must be alphabetically sorted
    res = sortBy(res, (module) => moduleColumns[module]);

    return res;
  }, [modules]);

  // Create the list of type: {id, name} of the selected modules in order
  // to submit the selected modules to the patch endpoint of the provider
  const selectedModules = modules.map((id) => ({
    type: { id, name: allModules?.find((x) => x.id === id)?.name },
  }));

  // Add a module
  const handleAddModule = useCallback(
    (module: DataModule) => () => {
      setModules((state = []) => [...state, module]);
    },
    [],
  );

  // Remove a module
  const handleRemoveModule = useCallback(
    (module: DataModule) => () => {
      setModules((state) => without(state, module));
    },
    [],
  );

  // Change the order of the modules
  const handleReorderChange = useCallback((modules: DataModule[]) => {
    setModules(modules);
  }, []);

  // Reset to the default modules list
  const handleReset = () => {
    setModules(defaultModuleColumns);
  };

  useEffect(() => {
    setModules(defaultModuleColumns);
  }, []);

  // On "Save" send the list of the selected modules to the Provider
  const handleSave = useCallback(() => {
    onClose();
    onChange(selectedModules);
  }, [onChange, onClose, selectedModules]);

  return (
    <Modal
      aria-labelledby="customize-modules-dialog-title"
      className="max-w-xl sm:max-w-xl overflow-visible"
      {...props}
    >
      <Modal.Title id="customize-modules-dialog-title" className="flex mb-4 whitespace-pre">
        Customize data modules
      </Modal.Title>
      <div className="flex flex-row gap-4 justify-between w-full">
        <div className="w-full max-w-[240px]">
          <Paragraph className="text-gray-500 mb-4">Drag to reorder</Paragraph>
          <SortableContainer<DataModule>
            items={modules}
            onChange={handleReorderChange}
            renderDragPreview={(module) => <DragPreview module={module} />}
          >
            <div className="space-y-1 h-80 overflow-y-scroll">
              {map(modules, (module) => {
                return (
                  <SortableItem key={module} id={module}>
                    <ColumnBox
                      label={moduleColumns[module]}
                      checked
                      onChange={handleRemoveModule(module)}
                    />
                  </SortableItem>
                );
              })}
            </div>
          </SortableContainer>
        </div>
        <div className="w-full max-w-[240px]">
          <Paragraph className="text-gray-500 mb-4">Select to add</Paragraph>
          <div className="space-y-1 h-80 overflow-y-scroll">
            {map(modulesAvailable, (module) => {
              return (
                <ColumnBox
                  key={module}
                  label={moduleColumns[module]}
                  onChange={handleAddModule(module)}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <Button borderless onClick={handleReset} type="button">
          Reset to default
        </Button>
        <div className="flex space-x-2">
          <Button borderless onClick={onClose} type="button">
            Cancel
          </Button>
          <Button theme="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CustomizeViewOverlay;
