import classNames from 'classnames';
import { Skeleton } from '@demandscience/ui';
import { map, range } from 'lodash';
import ListItem from '../../Layout/ListItem';

interface ListItemSkeletonProps {
  count: number;
  leadingContentVariant?: 'circle' | 'rectangle';
}

const ListItemSkeleton = ({
  count,
  leadingContentVariant = 'rectangle',
}: ListItemSkeletonProps) => (
  <>
    {map(range(count), (index) => (
      <ListItem
        key={index}
        leadingContent={
          <Skeleton
            variant={leadingContentVariant}
            width={8}
            height={8}
            className={classNames('bg-gray-200', {
              rounded: leadingContentVariant === 'rectangle',
            })}
          />
        }
        title={
          <div className="w-96">
            <Skeleton variant="text" height={5} className="bg-gray-200" />
          </div>
        }
        subtitle={
          <div className="w-40 mt-1">
            <Skeleton variant="text" height={3} className="bg-gray-200" />
          </div>
        }
      />
    ))}
  </>
);

export default ListItemSkeleton;
