import { useQuery } from '@tanstack/react-query';
import { fetchBillingInfo } from 'api/organization';

const useBillingInfo = () => {
  const query = useQuery(['billingInfo'], () => fetchBillingInfo(), {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  return query;
};

export default useBillingInfo;
