import { memo, useCallback } from 'react';
import { ModelType, ResultsView, KlarityFeature } from 'types';
import useTabs from '../Tabs/useTabs';
import isNumber from 'lodash/isNumber';
import { Tabs, Badge, Button } from '@demandscience/ui';
import DataGrid from 'components/DataGrid/DataGrid';
import useSearchCounts from 'components/Search/useSearchCounts';
import { isEmpty } from 'lodash';
import SaveControl from 'components/SavedSearches/SaveControl';
import useFilters from 'components/Filters/useFilters';
import Searches from './Searches';
import SavedSearchesSidebarControl from 'components/SavedSearches/SavedSearchesSidebarControl';
import DataProfileDropdownActions from './DataProfileDropdownActions';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

import DataProfileViews from 'components/DataProfileViews';

const ResultTabs = memo(() => {
  const { tab, setTab, getTabName, selectedIndex } = useTabs();
  const { contactCount, companyCount } = useSearchCounts({ useTotalCount: true });
  const { filters } = useFilters();
  const isEmptySearch = isEmpty(filters);
  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const handleChange = useCallback(
    (index: number) => {
      setTab(getTabName(index));
    },
    [setTab, getTabName],
  );

  return (
    <div className="flex flex-col h-full isolate">
      <Tabs selectedIndex={selectedIndex} onChange={handleChange}>
        <div className="h-20 pt-3 border-b flex-shrink-0 flex flex-row items-center gap-2">
          <Tabs.List id="dashboard_search_tabs" className="self-end">
            {!isContactRenderDisabled && (
              <Tabs.Item id="tab_contacts">
                Contacts
                {isNumber(contactCount) && !isEmptySearch && (
                  <Badge
                    color={tab === ResultsView.Contacts ? 'blue' : 'gray'}
                    className="ml-2 my-[-10px]"
                  >
                    {contactCount.toLocaleString()}
                  </Badge>
                )}
              </Tabs.Item>
            )}
            <Tabs.Item id="tab_companies">
              Companies
              {isNumber(companyCount) && !isEmptySearch && (
                <Badge
                  color={tab === ResultsView.Companies ? 'blue' : 'gray'}
                  className="ml-2 my-[-10px]"
                >
                  {companyCount.toLocaleString()}
                </Badge>
              )}
            </Tabs.Item>
            <Tabs.Item id="tab_data_profile">
              <span>Data&nbsp;Profile</span>
              {tab === ResultsView.DataProfile && <DataProfileDropdownActions />}
            </Tabs.Item>
          </Tabs.List>
          <div id="dashboard_search_tabs_spacing" className="grow self-end" />
          <div className="flex flex-row gap-4 items-start">
            <SavedSearchesSidebarControl
              as={Button}
              id="action_open_search"
              className="whitespace-nowrap"
              size="sm"
              theme="primary"
              outline
            >
              Open search
            </SavedSearchesSidebarControl>
            {!isEmptySearch && (
              <SaveControl
                as={Button}
                id="action_save_search"
                className="whitespace-nowrap"
                size="sm"
                theme="primary"
                filters={filters}
                currentView={tab}
              >
                Save search
              </SaveControl>
            )}
          </div>
        </div>
        <Tabs.Panels className="grow min-h-0">
          {isEmptySearch && (
            <>
              {!isContactRenderDisabled && (
                <Tabs.Panel unmount>
                  <Searches />
                </Tabs.Panel>
              )}
              <Tabs.Panel unmount>
                <Searches />
              </Tabs.Panel>
            </>
          )}
          {!isEmptySearch && (
            <>
              {!isContactRenderDisabled && (
                <Tabs.Panel className="h-full" unmount>
                  <DataGrid id="search_tab_contacts" kind={ModelType.Contact} />
                </Tabs.Panel>
              )}
              <Tabs.Panel className="h-full" unmount>
                <DataGrid id="search_tab_companies" kind={ModelType.Company} />
              </Tabs.Panel>
            </>
          )}
          <Tabs.Panel unmount>
            <DataProfileViews setTab={setTab} />
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </div>
  );
});

ResultTabs.displayName = 'ResultTabs';

export default ResultTabs;
