import map from 'lodash/map';

import { Notification as NotificationType } from 'types';
import CreditsProvider from 'components/Credits/CreditsProvider';
import Notification from './Notification';
import { UseMutationResult } from '@tanstack/react-query';

interface NotificationsListProps {
  items: NotificationType[];
  markAsRead: UseMutationResult<void, unknown, string, unknown>;
}

const NotificationsList = ({ items, markAsRead }: NotificationsListProps) => (
  <CreditsProvider>
    <div className="grid grid-cols-1 divide-y">
      {map(items, (notification) => (
        <Notification key={notification.id} notification={notification} markAsRead={markAsRead} />
      ))}
    </div>
  </CreditsProvider>
);

export default NotificationsList;
