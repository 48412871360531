import useModal from 'hooks/useModal';
import { Button } from '@demandscience/ui';
import { ElementType, ReactElement, ReactNode } from 'react';
import AddExclusionListOverlay from './AddExclusionListOverlay';

interface AddExclusionListControlProps {
  as?: ElementType;
  borderless?: boolean;
  children: ReactNode;
  className?: string;
  leftIcon: ReactElement;
  size?: string;
  theme?: string;
}

const AddExclusionListControl = ({
  as: Component = Button,
  ...props
}: AddExclusionListControlProps) => {
  const { open, openModal, closeModal } = useModal();

  return (
    <>
      <Component onClick={openModal} {...props} />
      <AddExclusionListOverlay open={open} onClose={closeModal} />
    </>
  );
};

export default AddExclusionListControl;
