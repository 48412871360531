import { useState } from 'react';
import useMutateList from 'components/Lists/useMutateList';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Modal } from '@demandscience/ui';
import { ListRecord } from 'types';
import { AxiosError } from 'axios';

interface RemoveExclusionListOverlayProps extends ListRecord {
  onClose: () => void;
  onSuccess?: () => void;
  open: boolean;
}

const RemoveExclusionListOverlay = ({
  id,
  name,
  open,
  onClose,
  onSuccess,
}: RemoveExclusionListOverlayProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const { updateGlobalExclusion } = useMutateList();

  const onSubmit: SubmitHandler<{}> = async () => {
    setApiError(null);

    try {
      await updateGlobalExclusion.mutateAsync({ id, flag: false });

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <Modal
      aria-labelledby="delete-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      open={open}
      onClose={onClose}
    >
      <Modal.Title id="delete-list-dialog-title" className="mb-8 break-words">
        Are you sure you want to remove {name} from suppression lists?
      </Modal.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4 mt-10">
          {apiError && <div className="text-error-500 text-center mt-8">{apiError}</div>}
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <Button type="button" borderless onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Remove
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RemoveExclusionListOverlay;
