import { Link } from 'react-router-dom';

import map from 'lodash/map';

import Logo from './Logo';
import NavButton from './NavButton';
import { Navigation } from './types';
import { Tooltip } from '@demandscience/ui';
import classNames from 'classnames';

interface SidebarProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  impersonated?: true;
  navigation: Navigation[];
}

const Sidebar = ({ navigation, impersonated, children, ...props }: SidebarProps) => (
  <div {...props}>
    <div
      className={classNames('h-full w-[72px] flex flex-col items-center', {
        'bg-gray-100': !impersonated,
        'bg-rose-100': impersonated,
      })}
    >
      <div className="pt-7 pb-8">
        <Tooltip className="text-xs px-3 py-2" position="right" showArrow title="Home">
          <Link id="action_menu_dashboard" to="/">
            <Logo className="w-8 h-8" />
          </Link>
        </Tooltip>
      </div>
      <nav className="mt-4 mb-6 flex flex-col items-center gap-4 grow">
        {map(navigation, (navItem) => (
          <NavButton key={navItem.to} navigation={navItem} size="sm" theme="primary" />
        ))}
        <div className="grow" />
        {children}
      </nav>
    </div>
  </div>
);

export default Sidebar;
