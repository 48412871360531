import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Button } from '@demandscience/ui';

import { RecentSearch } from 'types';
import useMutateRecentSearch from './useMutateRecentSearch';
import { AxiosError } from 'axios';

interface DeleteFormProps {
  data: RecentSearch;
  onCancel: () => void;
  onSuccess: () => void;
}

const DeleteForm: React.FC<DeleteFormProps> = ({ data, onSuccess, onCancel }) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const { destroy } = useMutateRecentSearch();

  const onSubmit: SubmitHandler<{}> = async () => {
    setApiError(null);

    try {
      await destroy.mutateAsync(data.id);

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || 'Unable to delete recent search');
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button type="submit" theme="primary" disabled={isSubmitting}>
          Delete search
        </Button>
      </div>
    </form>
  );
};

export default DeleteForm;
