import useConsentManager from 'components/ConsentManager/useConsentManager';
import WebWidget from './WebWidget';

const Zendesk = () => {
  const { functional } = useConsentManager();

  if (!functional) {
    return null;
  }

  return <WebWidget instanceKey="57e2af3e-68ad-4cd5-a5e5-56892f775f64" />;
};

export default Zendesk;
