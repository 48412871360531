import { CompanyFilterName } from 'types/filters';
import useRevenueOptions from './Options/useRevenueOptions';
import SelectFilter from './SelectFilter';
import { FilterProps } from './types';

const CompanyRevenueFilter = (props: FilterProps) => {
  const query = useRevenueOptions();

  return (
    <SelectFilter
      {...props}
      name={CompanyFilterName.CompanyRevenue}
      placeholder="Enter company revenue"
      showSelectAll
      optionsQuery={query}
      hideInput
    />
  );
};

export default CompanyRevenueFilter;
