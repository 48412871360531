import { ReactNode, useCallback, useMemo, useState } from 'react';
import NotificationsDrawer from './NotificationsDrawer';
import NotificationsContext, { NotificationsContextInterface } from './NotificationsContext';

interface NotificationsProviderProps {
  children: ReactNode;
}

const NotificationsProvider = ({ children }: NotificationsProviderProps) => {
  const [open, setOpen] = useState(false);

  const openNotifications = useCallback(() => {
    setOpen(true);
  }, []);

  const closeNotifications = useCallback(() => {
    setOpen(false);
  }, []);

  const value: NotificationsContextInterface = useMemo(
    () => ({
      open,
      openNotifications,
      closeNotifications,
    }),
    [closeNotifications, open, openNotifications],
  );

  return (
    <NotificationsContext.Provider value={value}>
      {children}
      <NotificationsDrawer open={open} onClose={closeNotifications} anchor="left" />
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
