import { useState } from 'react';
import BookmarkChart from './BookmarkChart';
import { CheckboxIcon, IconButton, Progress } from '@demandscience/ui';
import { Bookmark } from '@demandscience/ui/icons/index';
import useMutateBookMark from '../useMutateBookMark';
import { AxiosError } from 'axios';
import { SearchResult2 } from 'types/BookMark';
import { Tooltip } from '@demandscience/ui';

const BookmarkGraph = ({
  formattedData,
  setClickedLabelsIndex,
  clickedLabels,
  label,
  icon,
  id,
  aggregate,
  fieldName,
  includeNow,
  handleToggleName,
  includedNames,
}: {
  aggregate: any;
  clickedLabels: number[];
  fieldName: string;
  formattedData: any;
  handleToggleName: (name: string) => void;
  icon: React.ElementType;
  id: string;
  includeNow: boolean;
  includedNames: string[];
  label: string;
  setClickedLabelsIndex: (value: []) => void;
}) => {
  const [apiError, setApiError] = useState<string | null>(null);

  const childClass = clickedLabels.length === 1 ? 'w-full' : 'w-1/2';

  const Icon = icon;

  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  const { update } = useMutateBookMark();
  const payload: SearchResult2 = {
    id,
    search_result: { aggregation_results: { [fieldName]: aggregate.values } },
  };
  const updateBookmark = async () => {
    try {
      await update.mutateAsync(payload);
      setApiError(null);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <div
      className="flex-[6_1_0%] h-633 gap-0 rounded-tl-5 bg-white border border-solid border-300 br-5px"
      style={{ borderColor: '#CBD5E1', borderRadius: '5px' }}
    >
      {' '}
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center pt-[14px]">
          <div className="pl-[13px] pr-3">
            <Icon className="w-5 h-5 text-primary-500" />
          </div>
          <div className="grow">
            <div className="w-[95px] h-[24px] top-[187px] left-[173px] gap-2 font-inter text-[16px] font-semibold leading-[24px] text-left text-gray-800">
              {label}
            </div>
          </div>
        </div>
        {includeNow && (
          <IconButton
            className="hover:bg-gray-100 h-full mr-2 mt-2"
            size="sm"
            theme="default"
            aria-label="show customization options"
            Icon={Bookmark}
            onClick={updateBookmark}
          />
        )}
        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>
      <BookmarkChart
        id="list"
        categories={formattedData.categories}
        data={formattedData.data.filter((entry: { color: string; data: []; name: string }) =>
          includedNames.includes(entry.name),
        )}
        setClickedLabelsIndex={setClickedLabelsIndex}
        clickedLabels={clickedLabels}
      />
      <div className="h-[230px] overflow-y-auto flex flex-row">
        {clickedLabels.map((selectedIndex: number) => {
          const totalCount = formattedData.data.reduce(
            (sum: any, entry: any) => sum + entry.data[selectedIndex],
            0,
          );

          return (
            <div className={`${childClass} flex flex-col gap-2`} key={selectedIndex}>
              {formattedData.data.map(
                ({ name, data, color }: { color: string; data: number[]; name: string }) => {
                  const count = data[selectedIndex];
                  const isHovered = hoveredItem === name;

                  return (
                    <button
                      key={name}
                      className={`flex flex-row items-center gap-3 w-full p-2 transition outline-none text-left ${
                        isHovered ? 'bg-gray-200' : 'hover:bg-gray-100'
                      }`}
                      onMouseEnter={() => setHoveredItem(name)}
                      onMouseLeave={() => setHoveredItem(null)}
                      disabled={includedNames.length >= 5 && !includedNames.includes(name)}
                    >
                      <div className="flex-shrink-0 p-[5px]" onClick={() => handleToggleName(name)}>
                        <Tooltip
                          title="Maximum 5 modules can be selected"
                          position="bottom-left"
                          disabled={includedNames.length < 5 || includedNames.includes(name)}
                        >
                          <CheckboxIcon
                            size={20}
                            theme="primary"
                            checked={includedNames.includes(name)}
                            disabled={includedNames.length >= 5 && !includedNames.includes(name)}
                          />
                        </Tooltip>
                      </div>
                      <div className="w-full mr-1">
                        <div className="flex justify-between text-xs">
                          <span className="text-800" style={{ color }}>
                            {name}
                          </span>
                          <span className="text-500" style={{ color }}>
                            {count?.toLocaleString()}
                          </span>
                        </div>
                        <Progress
                          value={Math.floor((count / totalCount) * 100)}
                          size="sm"
                          variant="solid"
                          className="mt-1"
                          color={color}
                        />
                      </div>
                    </button>
                  );
                },
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BookmarkGraph;
