import { Alert, Progress, Skeleton } from '@demandscience/ui';
import classNames from 'classnames';
import { max } from 'lodash';

import { Organization } from 'types';

interface CreditsUsageProps {
  organization?: Organization;
}

const CreditsUsage = ({ organization }: CreditsUsageProps) => {
  if (!organization) {
    return <Skeleton className="w-full" height={9} variant="text" />;
  }

  const { bulk_credits_usage: { used, available } = { used: 0, available: 0 } } = organization;
  const total = used + available;
  const percentAvailable = max([(available / total) * 100, 1]);
  const lowOnCredits = available < 10;

  if (available === 0) {
    return (
      <Alert severity="warning">
        <Alert.Header>
          <Alert.Title>No credits available</Alert.Title>
        </Alert.Header>
        <Alert.Description>Buy credits for full feature access in Klarity</Alert.Description>
      </Alert>
    );
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between text-sm">
        <div
          className={classNames({
            'text-primary-500': !lowOnCredits,
            'text-error-500': lowOnCredits,
          })}
        >
          {available.toLocaleString()} left
        </div>
        <div className="text-gray-500">{used.toLocaleString()} used</div>
      </div>
      <div className="py-2">
        <Progress value={percentAvailable} size="md" theme={lowOnCredits ? 'error' : 'primary'} />
      </div>
    </div>
  );
};

export default CreditsUsage;
