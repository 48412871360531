import { useCallback, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { Link } from '@demandscience/ui';
import classNames from 'classnames';

import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import size from 'lodash/size';

import SavedSearchesSidebar from 'components/SavedSearches/SavedSearchesSidebar';
import RecentSearchesList from './RecentSearchesList';
import { RecentSearchesResponse } from 'types';
import ListItemSkeleton from 'components/Lists/Skeletons/ListItemSkeleton';

interface RecentSearchesListProps {
  count?: number;
  query: UseQueryResult<RecentSearchesResponse>;
  showDivider?: boolean;
  showViewAll?: boolean;
}

const RecentSearchesShortList = ({
  query,
  showViewAll = true,
  count = 3,
  showDivider = true,
}: RecentSearchesListProps) => {
  const [open, setOpen] = useState(false);
  let { data: searches, isLoading } = query;

  // Temporarily filter out searches containing a contact_location filter
  searches = searches?.filter((search) => {
    const { filters } = search;
    if (filters?.hasOwnProperty('contact_location')) {
      return false;
    }
    return search;
  });

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (isLoading) {
    return (
      <div className="divide-y">
        <ListItemSkeleton count={3} />
      </div>
    );
  }

  return (
    <>
      {isEmpty(searches) && (
        <div className="text-sm font-medium text-gray-500 pb-2">No recent search</div>
      )}
      {!isEmpty(searches) && (
        <RecentSearchesList data={take(searches, count)} showDivider={showDivider} />
      )}
      {showViewAll && (
        <div className={'py-4 border-t flex flex-row justify-between'}>
          <Link
            className={classNames('no-underline hover:underline', {
              invisible: size(searches) <= count,
            })}
            as="button"
            onClick={handleClick}
          >
            View all
          </Link>
        </div>
      )}
      <SavedSearchesSidebar open={open} onClose={handleClose} tab="History" />
    </>
  );
};

export default RecentSearchesShortList;
