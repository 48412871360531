import { map } from 'lodash';
import useMutateList from './useMutateList';
import { useCallback, useState } from 'react';
import ListFormFields from './ListFormFields';
import { Button, RadioList } from '@demandscience/ui';
import useApiValidation from 'hooks/useApiValidation';
import { SubmitHandler, useForm } from 'react-hook-form';
import useKlarityFeatures from 'hooks/useKlarityFeatures';
import { ModelType, Visibility, CreateListPayload, CreateListFormValues } from 'types';

interface CreateListFormProps {
  onCancel: () => void;
  onSuccess: (id: string, visibility: Visibility) => void;
  type?: ModelType;
}

const CreateListForm = ({ onSuccess, onCancel, type }: CreateListFormProps) => {
  const [typeSelection, setTypeSelection] = useState<ModelType>(type || ModelType.Company);

  const { control, handleSubmit, formState, watch, setValue, setError } =
    useForm<CreateListFormValues>({
      defaultValues: { name: '', description: '', visibility: Visibility.Private },
    });
  const { isSubmitting } = formState;

  const { apiError, setApiError, handleApiValidation } =
    useApiValidation<CreateListFormValues>(setError);

  const { create } = useMutateList();

  const { availableListTypeOptions } = useKlarityFeatures();

  const handleTypeSelection = useCallback(
    (type: string) => {
      setTypeSelection(type as ModelType);
      setApiError(null);
    },
    [setApiError],
  );

  const buildPayload = ({
    members,
    notification_message,
    visibility,
    ...data
  }: CreateListFormValues) => {
    let payload: CreateListPayload = { records_type: typeSelection, visibility, ...data };

    if (
      (visibility === Visibility.Public || visibility === Visibility.Restricted) &&
      notification_message
    ) {
      payload = { notification_message, ...payload };
    }

    if (visibility === Visibility.Restricted) {
      const share_with = members?.map(({ value }) => value);

      payload = { share_with, ...payload };
    }

    return payload;
  };

  const onSubmit: SubmitHandler<CreateListFormValues> = async (data) => {
    setApiError(null);

    const payload: CreateListPayload = buildPayload(data);

    try {
      const response = await create.mutateAsync(payload);

      onSuccess(response.id, payload.visibility);
    } catch (e: any) {
      handleApiValidation(e);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        // this is required as this component appears inside another form component
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      <div className="space-y-4">
        {!type && (
          <RadioList
            value={typeSelection}
            onChange={handleTypeSelection}
            options={availableListTypeOptions}
          >
            <div className="space-y-4">
              {map(availableListTypeOptions, (option) => (
                <RadioList.Option key={option.value} option={option}>
                  <RadioList.Icon
                    theme="primary"
                    checked={option.value === typeSelection}
                    className="mr-2"
                  />
                  <RadioList.Label className="text-md">{option.label}</RadioList.Label>
                </RadioList.Option>
              ))}
            </div>
          </RadioList>
        )}

        <ListFormFields control={control} formState={formState} watch={watch} setValue={setValue} />

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-8">
        <Button type="button" borderless onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" theme="primary" disabled={isSubmitting}>
          Create list
        </Button>
      </div>
    </form>
  );
};

export default CreateListForm;
