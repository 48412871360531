import classNames from 'classnames';
import { every } from 'lodash';
import { forwardRef } from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

export interface MenuProps {
  children: React.ReactNode;
  className?: string;
  rootClassName?: string;
}

const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ rootClassName, className, children }, ref) => {
    // no dropdown render if every child is false (due to conditional rendering)
    if (every(children as any, (child) => child === false)) {
      return null;
    }

    return (
      <div
        ref={ref}
        className={overrideTailwindClasses(
          classNames(
            'py-2 mx-[2px] max-h-[320px] rounded border bg-white border-gray-200 shadow-xl flex',
            rootClassName,
          ),
        )}
      >
        <div className={overrideTailwindClasses(classNames('grow overflow-y-auto', className))}>
          {children}
        </div>
      </div>
    );
  },
);

Menu.displayName = 'Menu';

export default Menu;
