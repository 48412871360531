import { CanceledError } from 'axios';
import CheckIcon from '@demandscience/ui/icons/check';
import FilePlusIcon from '@demandscience/ui/icons/file-plus';
import XIcon from '@demandscience/ui/icons/x';
import { CircularProgress, Link } from '@demandscience/ui';
import { ModelType, UploadBounceResponse } from 'types';
import { useCallback, useRef, useState } from 'react';

import { isEmpty, round } from 'lodash';
import { FileRejection, useDropzone } from 'react-dropzone';
import useMutateBouncesList from './useMutateBouncesList';
import { stopPropagation } from 'utils/event';

interface UploadBounceListFileProps {
  onSuccess: (res: UploadBounceResponse) => void;
}

const UploadBounceListFile = ({ onSuccess }: UploadBounceListFileProps) => {
  const controllerRef = useRef<AbortController | null>(null);
  const [fileRejectionError, setFileRejectionError] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const { upload } = useMutateBouncesList();

  const handleUploadProgress = (event: ProgressEvent) => {
    setProgress(round((event.loaded * 100) / event.total));
  };

  const handleFileChange = async (files: File[]) => {
    if (isEmpty(files)) {
      return;
    }

    setProgress(0);

    try {
      // instantiate new controller for each upload
      controllerRef.current = new AbortController();

      const [file] = files;
      const response = await upload.mutateAsync({
        file,
        onUploadProgress: handleUploadProgress,
        controller: controllerRef.current,
        records_type: ModelType.Company,
      });

      onSuccess(response);
    } catch (e: any) {
      if (e instanceof CanceledError) {
        // ignoring cancel error and just reset the upload
        upload.reset();
      }
    }
  };

  const handleFileRejection = (files: FileRejection[]) => {
    if (files?.length > 0) setFileRejectionError(true);
  };

  const handleCancel = useCallback(() => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    setFileRejectionError(false);
  }, []);

  const handleTryAgain = useCallback(() => {
    upload.reset();
    setFileRejectionError(false);
  }, [upload]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileChange,
    maxFiles: 1,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    onDropRejected: handleFileRejection,
  });

  return (
    <div className="border border-blue-400 rounded bg-gray-50 min-h-[200px]">
      {upload.isSuccess && (
        <div className="flex flex-col items-center gap-2 pt-8">
          <div className="bg-success-50 text-success-500 rounded-full p-3">
            <CheckIcon size={32} />
          </div>
          <div className="text-sm text-gray-700 text-center">File uploaded successfully</div>
        </div>
      )}
      {upload.isError && !fileRejectionError && (
        <div className="flex flex-col items-center gap-2 pt-8">
          <div className="bg-error-100 text-error-500 rounded-full p-3">
            <XIcon size={32} />
          </div>
          <div className="text-sm text-gray-700 text-center">
            There was a problem uploading your bounce list{' '}
            <Link className="font-normal" as="button" type="button" onClick={handleTryAgain}>
              Try again
            </Link>
          </div>
        </div>
      )}
      {fileRejectionError && (
        <div className="flex flex-col items-center gap-2 pt-8">
          <div className="bg-error-100 text-error-500 rounded-full p-3">
            <XIcon size={32} />
          </div>
          <div className="text-sm text-gray-700 text-center">
            An invalid file type was selected.
            <br /> Please select an Excel or a CSV file to upload. <br />
            <Link className="font-normal" as="button" type="button" onClick={handleTryAgain}>
              Try again
            </Link>
          </div>
        </div>
      )}
      {upload.isLoading && (
        <div className="flex flex-col items-center gap-2 pt-5">
          <CircularProgress value={progress} />
          <div className="text-sm text-gray-700 text-center">
            Uploading file...{' '}
            <Link className="font-normal" as="button" type="button" onClick={handleCancel}>
              Cancel
            </Link>
          </div>
        </div>
      )}
      {upload.isIdle && (
        <div
          {...getRootProps({
            className: 'flex flex-col items-center pt-8 cursor-pointer',
          })}
        >
          <input {...getInputProps()} />
          <FilePlusIcon className="text-blue-500 stroke-1" size={35} />
          <div className="mt-4 text-center space-y-1 max-w-[300px]">
            <div className="text-xs text-gray-600">
              Drop your Excel or CSV file here or <span className="text-blue-500">browse</span>
            </div>
            <div className="text-xs text-gray-500">
              Your file should contain a column for emails and another for notes.
              <br />
              <Link
                className="text-xs mt-2"
                href={`/download/bounce.xlsx`}
                onClick={stopPropagation}
                download
              >
                Download example
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadBounceListFile;
