import { useEffect, useState } from 'react';

const useImage = (src?: string) => {
  const [loading, setLoading] = useState(!!src);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (src) {
      const img = document.createElement('img');
      img.onload = () => {
        setLoading(false);
        setLoaded(true);
      };
      img.onerror = () => {
        setLoading(false);
      };
      img.src = src;
    }
  }, [src]);

  return { loading, loaded };
};

export default useImage;
