import React from 'react';

export interface CollapseContextInterface {
  collapse: boolean;
  collapseGroup: Record<string, boolean>;
  hideEmpty: boolean;
  toggle: () => void;
  toggleGroup: (group: string) => void;
  toggleHideEmpty: () => void;
}

const CollapseContext = React.createContext<CollapseContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  CollapseContext.displayName = 'FiltersPositionContext';
}

export default CollapseContext;
