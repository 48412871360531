import { Badge } from '@demandscience/ui';
import { ListOrigin, ListRecord, ModelType } from 'types';
import User from '@demandscience/ui/icons/user';
import OfficeBuildingIcon from '@demandscience/ui/icons/building';
import BadgeStatus from './ListStatusBadge';

export interface ListTypeWidgetProps {
  list: ListRecord;
}

const ListTypeWidget = ({ list }: ListTypeWidgetProps) => {
  const { records_type, records_origin, is_global_exclusion } = list;

  return (
    <div data-testid="list-type-widget" className="flex items-center space-x-3">
      <span>
        {records_type === ModelType.Contact && <User data-testid="icon-user" className="w-4 h-4" />}
        {records_type === ModelType.Company && (
          <OfficeBuildingIcon data-testid="icon-building" className="w-4 h-4" />
        )}
      </span>
      <span>
        {records_type === ModelType.Contact && 'Contacts'}
        {records_type === ModelType.Company && 'Companies'}
      </span>
      {records_origin === ListOrigin.Upload && (
        <Badge
          label={records_origin}
          color="indigo"
          size="sm"
          data-testid="list-type-widget-origin-upload-badge"
          className="capitalize"
        />
      )}
      {records_origin === ListOrigin.Automated && (
        <Badge
          label="Automated"
          color="emerald"
          size="sm"
          data-testid="list-type-widget-origin-automated-badge"
          className="capitalize"
        />
      )}
      <BadgeStatus list={list} />
      {is_global_exclusion && <Badge label="Global suppression list" color="amber" size="sm" />}
    </div>
  );
};

export default ListTypeWidget;
