import { ContactFilterName } from 'types/filters';
import useJobLevelOptions from './Options/useJobLevelOptions';
import SelectFilter from './SelectFilter';
import { FilterProps } from './types';

const ContactJobLevelFilter = (props: FilterProps) => {
  const query = useJobLevelOptions();

  return (
    <SelectFilter
      {...props}
      name={ContactFilterName.JobLevel}
      placeholder="Enter management level"
      showSelectAll
      optionsQuery={query}
      hideInput
    />
  );
};

export default ContactJobLevelFilter;
