import classNames from 'classnames';
import { ElementType, ReactNode } from 'react';
import IllustrationAbsurd1 from 'components/Illustrations/IllustrationAbsurd1';
import IllustrationAbsurd2 from 'components/Illustrations/IllustrationAbsurd2';
import IllustrationAbsurd4 from 'components/Illustrations/IllustrationAbsurd4';
import IllustrationAbsurd5 from 'components/Illustrations/IllustrationAbsurd5';
import IllustrationEmpty from 'components/Illustrations/IllustrationEmpty';
import IllustrationEmptyAlt from 'components/Illustrations/IllustrationEmptyAlt';
import IllustrationNotFound from 'components/Illustrations/IllustrationNotFound';
import IllustrationNoUnreadNotification from 'components/Illustrations/IllustrationNoUnreadNotification';
import { overrideTailwindClasses } from 'tailwind-override';

export const Illustration: Record<string, ElementType> = {
  Absurd1: IllustrationAbsurd1,
  Absurd2: IllustrationAbsurd2,
  Absurd4: IllustrationAbsurd4,
  Absurd5: IllustrationAbsurd5,
  Empty: IllustrationEmpty,
  EmptyAlt: IllustrationEmptyAlt,
  NotFound: IllustrationNotFound,
  NoUnread: IllustrationNoUnreadNotification,
};

interface EmptyResultsProps {
  children?: ReactNode;
  className?: string;
  illustration?: ReactNode;
  message: string;
}

const EmptyResults = ({ message, illustration, children, className }: EmptyResultsProps) => {
  return (
    <div
      data-testid="empty-results"
      className={overrideTailwindClasses(classNames('flex flex-col items-center mt-36', className))}
    >
      {illustration}
      <p className="text-sm text-gray-700 font-medium text-center">{message}</p>
      {children}
    </div>
  );
};

export default EmptyResults;

// w-40 h-40 mb-6
