import classNames from 'classnames';
import useFilters from 'components/Filters/useFilters';
import useSearchCounts from 'components/Search/useSearchCounts';
import { isEmpty, isNumber, round } from 'lodash';

interface ContactsCountProps {
  display: 'prospects' | 'contacts';
  hideTotal?: boolean;
}

const ContactsCount = ({ display, hideTotal = false }: ContactsCountProps) => {
  const { contactCount, isPreviousData } = useSearchCounts();
  const { filters } = useFilters();

  return (
    <div
      className={classNames(
        'text-lg font-semibold text-center text-gray-700 p-4 w-full rounded-b-lg',
        {
          'bg-gray-100': !hideTotal || !isEmpty(filters),
        },
      )}
    >
      {isNumber(contactCount) && (
        <>
          {!hideTotal &&
            isEmpty(filters) &&
            `${round(
              contactCount / 1000000,
            ).toLocaleString()} million contacts available worldwide`}
          {!isEmpty(filters) &&
            display === 'prospects' &&
            `Your list now contains ${contactCount.toLocaleString()} prospects`}
          {!isEmpty(filters) &&
            display === 'contacts' &&
            !isPreviousData &&
            `We have found ${contactCount.toLocaleString()} contacts`}
        </>
      )}
      &nbsp;
    </div>
  );
};

export default ContactsCount;
