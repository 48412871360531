import { showListRecords } from 'api/list';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CompaniesSort, ContactsSort, ListRecordsParams, ModelType, SortBy } from 'types';
import { mergeCompanyPhoneNumbers } from 'components/Search/utils';

export const defaultPaginationPageSize = 25;

export const useListRecords = (
  id: string,
  kind: ModelType,
  {
    sortField = kind === ModelType.Company ? CompaniesSort.Name : ContactsSort.JobLevel,
    ...options
  } = {},
) => {
  const [search, setSearch] = useState<string>('');
  const [params, setParams] = useState<ListRecordsParams>({
    name: '',
    pagination: {
      index: 0,
      count: defaultPaginationPageSize,
    },
    sort: {
      by: sortField,
      descending: true,
    },
  });
  const { sort } = params;
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
    setParams((state) => ({
      ...state,
      name: search,
      pagination: { index: 0, count: defaultPaginationPageSize },
    }));
  }, [search]);

  const handleSetPage = useCallback((newPage: number) => {
    setParams((state) => ({
      ...state,
      pagination: {
        index: newPage,
        count: defaultPaginationPageSize,
      },
    }));
    setPage(newPage);
  }, []);

  const handleSetSort = useCallback((newSort: SortBy) => {
    setParams((state) => ({ ...state, sort: newSort }));
  }, []);

  const query = useQuery(['lists', id, params], () => showListRecords({ id, params }), {
    refetchOnWindowFocus: false,
    select: kind === ModelType.Contact ? mergeCompanyPhoneNumbers : undefined,
    ...options,
  });

  return {
    query,
    page,
    pageSize: defaultPaginationPageSize,
    setPage: handleSetPage,
    sort,
    setSort: handleSetSort,
    search,
    setSearch,
  };
};
