import { IconButton, IconButtonProps } from '@demandscience/ui';
import SettingsIcon from '@demandscience/ui/icons/settings';
import TextTooltip from 'components/Tooltip/TextTooltip';
import { useCallback } from 'react';

import useCustomization from './useCustomization';

const SettingsButton: React.FC<IconButtonProps> = (props) => {
  const { setOpen } = useCustomization();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return (
    <TextTooltip title="Customize" placement="bottom" showArrow>
      <IconButton
        className="hover:bg-gray-100"
        onClick={handleOpen}
        size="sm"
        theme="default"
        aria-label="show customization options"
        Icon={SettingsIcon}
        {...props}
      />
    </TextTooltip>
  );
};

export default SettingsButton;
