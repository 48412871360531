import SupportRequestControl from 'components/SupportRequest/SupportRequestControl';

const CustomPlan = () => (
  <div className="w-44 h-[152px] flex flex-col justify-between border rounded text-gray-700 p-4">
    <div>
      <div className="text-sm">Custom plan</div>
      <div className="text-lg font-bold">Get a quote</div>
    </div>
    <SupportRequestControl
      action="customPlan"
      id="action_contact_sales"
      outline
      className="w-full"
      size="sm"
    >
      Contact sales
    </SupportRequestControl>
  </div>
);

export default CustomPlan;
