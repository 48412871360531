import api from 'api';

import { UploadAvatarPayload } from 'types';

export const uploadAvatar = ({ id, payload }: UploadAvatarPayload) =>
  api.put(`/avatar/${id}`, payload, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });

export const deleteAvatar = (id: string): Promise<void> => api.delete(`/avatar/${id}`);

export const avatarUrl = (id: string) => `${api.defaults.baseURL}/public/avatar/${id}`;
