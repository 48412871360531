import { ExportType } from 'types';
import { Link as RouterLink } from 'react-router-dom';
import { DropdownMenu, DropdownMenuProps } from '@demandscience/ui';
import PlusIcon from '@demandscience/ui/icons/plus';
import ExportMenuItems from './ExportMenuItems';

export interface ExportDropdownProps extends Omit<DropdownMenuProps, 'onClick'> {
  hideReveal?: boolean;
  onClick: (value: ExportType) => () => void;
  onPinClick?: (value: ExportType) => void;
  pinned?: ExportType[];
}

const ExportDropdown = ({
  onClick,
  pinned,
  onPinClick,
  hideReveal,
  children,
  ...props
}: ExportDropdownProps) => (
  <DropdownMenu origin="bottom-left" dropdownClassName="py-2 max-h-fit z-10" {...props}>
    {children}

    <ExportMenuItems
      hideReveal={hideReveal}
      onClick={onClick}
      onPinClick={onPinClick}
      pinned={pinned}
    />

    <div className="my-2 border-b" />

    {/* Add CRM integration */}
    <DropdownMenu.Item
      as={RouterLink}
      to="/settings/integrations"
      className="flex flex-row items-center gap-4"
    >
      <PlusIcon className="w-5 h-5" />
      <div className="text-sm flex-1">Add CRM Integration</div>
    </DropdownMenu.Item>
  </DropdownMenu>
);

export default ExportDropdown;
