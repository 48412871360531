import { CompanyFilterName } from 'types/filters';
import useEmployeeSizeOptions from './Options/useEmployeeSizeOptions';
import SelectFilter from './SelectFilter';
import { FilterProps } from './types';

const CompanyEmployeesFilter = (props: FilterProps) => {
  const query = useEmployeeSizeOptions();

  return (
    <SelectFilter
      {...props}
      name={CompanyFilterName.CompanyEmployees}
      placeholder="Enter number of employees"
      showSelectAll
      optionsQuery={query}
      hideInput
    />
  );
};

export default CompanyEmployeesFilter;
