import { Modal, ModalProps, Button } from '@demandscience/ui';

import XIcon from '@demandscience/ui/icons/x';

import Providers from './Providers';
import ExploreTAM from './index';
import { CompanyFilters, Filters } from 'types/filters';
import { ResultsView } from 'types';

interface ExploreTAMOverlayProps extends Omit<ModalProps, 'children'> {
  companyCount: number;
  companyFilters: CompanyFilters;
  contactCount: number;
  filters: Filters;
  setFilters: (filters: Filters, tab?: ResultsView) => void;
}

const ExploreTAMOverlay = ({
  open,
  onClose,
  filters,
  companyFilters,
  ...props
}: ExploreTAMOverlayProps) => (
  <Modal
    aria-labelledby="tam-dialog-title"
    className="max-w-2xl sm:max-w-lg md:max-w-3xl"
    rootClassName="z-30"
    open={open}
    onClose={onClose}
  >
    <Modal.Title id="tam-dialog-title" className="flex mb-4">
      <div className="text-xl grow">Explore TAM</div>
      <Button
        className="flex-shrink-0 px-0.5 py-0.5 transition-none disabled:bg-transparent group -mr-2"
        onClick={onClose}
        borderless
        round
        tabIndex={-1}
      >
        <XIcon className="h-5 w-5 text-gray-500" />
      </Button>
    </Modal.Title>
    <Providers filters={filters} companyFilters={companyFilters}>
      {/* @ts-expect-error - missing props are set by provider */}
      <ExploreTAM {...props} />
    </Providers>
  </Modal>
);

export default ExploreTAMOverlay;
