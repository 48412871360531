import { isEmpty, map, reject, uniqBy } from 'lodash';
import { BaseSearchResponse, Contact, ExclusionListFilter, Filters, ListFilterName } from 'types';

export const mergeCompanyPhoneNumbers = (response: BaseSearchResponse) => {
  // merge company phone numbers into contact phone numbers
  const hits = map(response.hits, (contact: Contact) => {
    // NOTE: removal of duplicate phone numbers is disabled so it's possible to notice such value coming from Vault
    //
    // make sure to have unique values
    // const phones = uniqBy(
    //   [...(contact.phones || []), ...(contact.companies[0].phones || [])],
    //   'number',
    // );

    const phones = [...(contact.phones || []), ...(contact.companies[0].phones || [])];

    return {
      ...contact,
      phones,
    };
  });

  return {
    ...response,
    hits,
  };
};

export const mergeExclusionListFilter = (
  filters: Filters,
  excludeList: ExclusionListFilter[number],
) => {
  const { [ListFilterName.ExclusionList]: exclusionList = [], ...rest } = filters;
  const exclusionListFilter = uniqBy([...exclusionList, excludeList], 'id');

  return { ...rest, [ListFilterName.ExclusionList]: exclusionListFilter };
};

export const omitExclusionListFilter = (
  filters: Filters,
  excludeList: ExclusionListFilter[number],
) => {
  const { [ListFilterName.ExclusionList]: exclusionList = [], ...rest } = filters;
  const newExclusionList = reject(exclusionList, excludeList);

  if (isEmpty(newExclusionList)) {
    return rest;
  }

  return {
    [ListFilterName.ExclusionList]: newExclusionList,
    ...rest,
  };
};
