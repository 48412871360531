import { ReactElement, useCallback, useState } from 'react';
import TextTooltip from 'components/Tooltip/TextTooltip';
import { now } from 'lodash';
import { overrideTailwindClasses } from 'tailwind-override';
import classNames from 'classnames';
import { Placement } from '@popperjs/core';

interface SplitterProps {
  className?: string;
  onClick: () => void;
  placement?: Placement;
  tooltip: ReactElement;
}

const Splitter = ({ className, onClick, placement = 'right', tooltip }: SplitterProps) => {
  const [clickedAt, setClickedAt] = useState(0);

  const handleMouseDown = useCallback(() => {
    setClickedAt(now());
  }, []);

  const handleMouseUp = useCallback(() => {
    const diff = now() - clickedAt;

    if (diff < 200) {
      onClick();
    }
  }, [clickedAt, onClick]);

  return (
    <TextTooltip title={tooltip} virtual placement={placement} delay={1000} offset={[0, 0]}>
      <div
        className={overrideTailwindClasses(classNames('group px-6 h-full', className))}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <div className="h-full border-r transition-colors border-gray-200 group-hover:border-blue-500" />
      </div>
    </TextTooltip>
  );
};

export default Splitter;
