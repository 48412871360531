import { useState, useEffect, useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from '@demandscience/ui';

import Heading from 'layouts/Main/Heading';
import { forEach } from 'lodash';

const TABS = [
  {
    path: '/settings/profile',
    label: 'Profile',
  },
  {
    path: '/settings/security',
    label: 'Security & login',
  },
  {
    path: '/settings/previous-exports',
    label: 'Previous exports',
  },
  {
    path: '/settings/integrations',
    label: 'Integrations',
  },
];

const Settings = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabChange = useCallback(
    (index: number) => {
      const { path } = TABS[index];
      navigate(path);
    },
    [navigate],
  );

  useEffect(() => {
    forEach(TABS, ({ path }, index) => {
      if (location.pathname.startsWith(path)) {
        setSelectedIndex(index);
      }
    });
  }, [location.pathname]);

  return (
    <>
      <Heading>Settings</Heading>
      <Tabs selectedIndex={selectedIndex} onChange={handleTabChange}>
        <div className="border-b mb-8">
          <Tabs.List>
            <Tabs.Item>Profile</Tabs.Item>
            <Tabs.Item>Security &#38; login</Tabs.Item>
            <Tabs.Item>Previous exports</Tabs.Item>
            <Tabs.Item>Integrations</Tabs.Item>
          </Tabs.List>
        </div>

        <Outlet />
      </Tabs>
      <div className="pb-8" />
    </>
  );
};

export default Settings;
