import { SavedSearch } from 'types';
import { DropdownMenu, IconButton } from '@demandscience/ui';
import InfoIcon from '@demandscience/ui/icons/info';

import FilterInfo from 'components/Filter/FilterInfo';
import Avatar from 'components/Avatar';

interface PersonaInfoProps {
  data?: SavedSearch;
  showAccountInfo?: boolean;
}

const PersonaInfo = ({ data, showAccountInfo = false }: PersonaInfoProps) => (
  <DropdownMenu
    buttonType="custom"
    customButton={
      <IconButton className="hover:bg-gray-100" size="sm" theme="default" Icon={InfoIcon} />
    }
    origin="bottom-right"
  >
    <div className="p-6 w-full max-w-[260px] max-h-80">
      {showAccountInfo && (
        <div className="flex flex-row gap-2 items-center mb-8">
          <div className="flex-shrink-0">
            <Avatar user={(data as SavedSearch).owner} />
          </div>
          <div className="flex-1">
            <div className="text-xs text-gray-500">Owner</div>
            <div className="text-sm text-gray-700 break-all">
              {(data as SavedSearch).owner.name}
            </div>
          </div>
        </div>
      )}
      {(data as SavedSearch).description && (
        <div className="flex flex-row items-center gap-6 mb-8">
          <div className="flex-shrink-0">
            <InfoIcon className="w-5 h-5 text-gray-500" />
          </div>
          <div className="flex-1">
            <div className="text-xs text-gray-500">Description</div>
            <div className="text-sm text-gray-700 break-all">
              {(data as SavedSearch).description}
            </div>
          </div>
        </div>
      )}
      <div className="text-sm text-gray-600 mb-3">Filters</div>
      <FilterInfo data={data!.filters} />
    </div>
  </DropdownMenu>
);

export default PersonaInfo;
