import { useState } from 'react';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';

const DashboardLayout = () => {
  const [fullWidth, setFullWidth] = useState(false);

  return (
    <div className="flex xl:flex-none">
      <Sidebar className="z-[1] xl:fixed xl:top-0 xl:bottom-0" />

      <div className="w-full h-screen overflow-auto relative">
        <main
          className={classNames('px-6 h-full', {
            'xl:px-32 2xl:mx-auto 2xl:px-0 2xl:max-w-7xl': !fullWidth,
            'xl:pl-24': fullWidth,
          })}
        >
          <Outlet context={{ setFullWidth }} />
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
