import { useEffect } from 'react';
import useFilters from 'components/Filters/useFilters';
import useJuiceExtension from './useJuiceExtension';
import { FilterName, Filters } from 'types';
import { flatten, join, map } from 'lodash';
import { getLabels } from 'components/Filters/utils';

const getFilterText = (filters: Filters) => {
  const labels = map(filters, (filter, filterName: FilterName) => getLabels(filterName, filter));

  return join(flatten(labels), ', ');
};

const MessageHandler = () => {
  const { installed, extensionId } = useJuiceExtension();
  const { filters, setFilters } = useFilters();

  const submitFilters = (win: Window, filters?: Filters, id?: string) => {
    win.postMessage({
      source: 'klarity',
      type: 'KLARITY_FILTERS',
      filters,
      id,
    });
  };

  const submitAck = (win: Window, id: string, message?: string) => {
    win.postMessage({
      source: 'klarity',
      type: 'KLARITY_ACK',
      id,
      message,
    });
  };

  useEffect(() => {
    if (!installed) {
      return;
    }

    // communication with juice extension in order to provide current filters
    const handleMessage = async (event: MessageEvent) => {
      const { source, data } = event;

      if (source !== window) {
        // we only accept messages from ouselves
        return;
      }

      if (data.source === extensionId) {
        const { id, type } = data;

        if (type === 'GET_KLARITY_FILTERS') {
          submitFilters(source, filters, id);
        } else if (type === 'SET_KLARITY_FILTERS') {
          setFilters(data.filters);
          const message = getFilterText(data.filters);

          submitAck(source, id, message);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    // always submit filters when they change
    submitFilters(window, filters);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [extensionId, filters, installed, setFilters]);

  useEffect(() => {
    return () => {
      // this is to submit undefined as filters on unmount
      submitFilters(window);
    };
  }, []);

  return null;
};

export default MessageHandler;
