import classNames from 'classnames';
import ChevronLeftIcon from '@demandscience/ui/icons/chevron-left';
import ChevronRightIcon from '@demandscience/ui/icons/chevron-right';
import useCustomization from 'components/SearchCustomization/useCustomization';
import { Badge } from '@demandscience/ui';
import useFiltersCollapse from './useFiltersCollapse';
import useFilters from 'components/Filters/useFilters';
import { size } from 'lodash';
import Splitter from '../Splitter';

interface ExpandButtonProps {
  className?: string;
  expand: 'left' | 'right';
}

const ExpandButton = ({ className, expand }: ExpandButtonProps) => {
  const { filters } = useFilters();
  const {
    filters: { position },
  } = useCustomization();
  const { collapse, toggle } = useFiltersCollapse();

  if (expand && expand === position) {
    return null;
  }

  if (!collapse && expand) {
    return null;
  }

  let Icon = position === 'left' ? ChevronLeftIcon : ChevronRightIcon;
  if (expand) {
    Icon = expand === 'left' ? ChevronLeftIcon : ChevronRightIcon;
  }

  return (
    <div
      className={classNames(className, 'border-gray-200 flex', {
        'mr-2 flex-row': expand === 'right',
        'ml-2 flex-row-reverse': expand === 'left',
      })}
    >
      <button
        id={`dashboard_search_filters_expand_button_${expand}`}
        className="flex flex-col items-center mt-9 pb-4 w-5 text-gray-500"
        onClick={toggle}
      >
        <Icon className="w-4 h-4" />
        <div className="flex flex-row items-center rotate-90 mt-12">
          <div className="text-sm text-gray-700">Filters</div>
          <Badge className="ml-2" label={size(filters).toLocaleString()} size="sm" color="blue" />
        </div>
      </button>
      <div className="flex-shrink-0">
        <Splitter
          className="px-4 cursor-pointer"
          onClick={toggle}
          placement={expand}
          tooltip={
            <span>
              <strong>Click</strong> to open
            </span>
          }
        />
      </div>
    </div>
  );
};

export default ExpandButton;
