import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { ReportedBouncesListPayload } from 'types';
import { fetchBouncesList } from 'api/organization';

export const useBouncesLists = (
  params: ReportedBouncesListPayload,
  options?: { refetchInterval: number | false },
) => {
  const query = useQuery(['bounces', params], () => fetchBouncesList(params), {
    staleTime: 60 * 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export const useInfiniteLists = ({ count = 25, filter, ...options }: Record<string, any> = {}) => {
  const query = useInfiniteQuery(
    ['bounces', 'infinite', { count, filter }],
    ({ pageParam: index = 0 }) =>
      fetchBouncesList({ index, count, filter, sort: { by: 'updated_at', descending: true } }),
    {
      staleTime: 60 * 1000, // 1 minute
      refetchOnWindowFocus: false,
      ...options,
    },
  );

  return query;
};
