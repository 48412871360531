import UsersIcon from '@demandscience/ui/icons/user';
import OfficeBuildingIcon from '@demandscience/ui/icons/building';

interface CountsProps {
  companyCount?: string;
  companyStats: React.ReactElement;
  contactCount?: string;
  contactStats: React.ReactElement;
}

const Counts = ({ contactCount, companyCount, contactStats, companyStats }: CountsProps) => (
  <ul className="space-y-4 p-4">
    <li className="flex items-start">
      <UsersIcon className="h6 w-6 mr-4 text-gray flex-shrink-0" />
      <div className="grow">
        <p className="text-sm">Contacts</p>
        {contactCount === undefined && <div className="h-8 w-3/4 animate-pulse bg-slate-200" />}
        {contactCount !== undefined && (
          <div className="mt-1 text-xl text-black">{contactCount}</div>
        )}
        {contactStats}
      </div>
    </li>
    <li className="flex items-start">
      <OfficeBuildingIcon className="h6 w-6 mr-4 text-gray flex-shrink-0" />
      <div className="grow">
        <p className="text-sm">Companies</p>
        {companyCount === undefined && <div className="h-8 w-3/4 animate-pulse bg-slate-200" />}
        {companyCount !== undefined && (
          <div className="mt-1 text-xl text-black">{companyCount}</div>
        )}
        {companyStats}
      </div>
    </li>
  </ul>
);

export default Counts;
