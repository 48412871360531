import useAuth from 'components/Auth/useAuth';
import { useCallback, useEffect, useState } from 'react';
import { useTracking } from 'react-tracking';
import {
  AdvancedSelection,
  CheckInstallationExtensionMessage,
  Constraints,
  ExtensionMessageResponse,
  FilterRecordsExtensionMessage,
  Filters,
  ListRecord,
  MessageType,
  ModelType,
  OpenListExtensionMessage,
  SortBy,
} from 'types';
import { ViewLinkedinEvent } from 'types/tracking';

const useLinkedInExtension = () => {
  const { user } = useAuth();
  const extensionId = process.env.REACT_APP_LINKEDIN_EXTENSION_ID;
  const [installed, setInstalled] = useState<boolean | null>(null);
  const { trackEvent } = useTracking<ViewLinkedinEvent>({ type: 'event', event: 'view_linkedin' });

  const assertExtension = () => {
    if (!extensionId) {
      throw new Error('Extension id not configured');
    }
    if (!installed) {
      throw new Error('Extension not installed');
    }
  };

  const sendMessage = useCallback(
    (
      payload:
        | FilterRecordsExtensionMessage
        | OpenListExtensionMessage
        | CheckInstallationExtensionMessage,
    ) =>
      new Promise<ExtensionMessageResponse>((resolve, reject) => {
        chrome.runtime.sendMessage(extensionId!, payload, (res?: ExtensionMessageResponse) => {
          if (chrome.runtime.lastError) {
            reject(chrome.runtime.lastError);
          } else {
            if (
              res &&
              res.type === 'EXTERNAL_MESSAGE_ACKNOWLEDGE' &&
              res.payload?.status === 'success'
            ) {
              resolve(res);
            } else {
              reject(new Error(res?.payload?.message || 'Unexpected error'));
            }
          }
        });
      }),
    [extensionId],
  );

  const exportRecords = async (
    type: ModelType,
    filters: Filters,
    sort?: SortBy,
    advancedSelection?: AdvancedSelection,
    constraints?: Constraints,
    selectionCount?: number,
  ) => {
    assertExtension();

    const payload: FilterRecordsExtensionMessage = {
      type: MessageType.SelectedRecords,
      payload: {
        type,
        filters,
        sort,
        advancedSelection,
        constraints,
      },
      userId: user!.username,
    };

    await sendMessage(payload);

    trackEvent({ numberRecords: selectionCount, listView: undefined });
  };

  const openList = async (list: ListRecord) => {
    assertExtension();

    const payload: OpenListExtensionMessage = {
      type: MessageType.GoToList,
      payload: {
        id: list.id,
        name: list.name,
        description: list.description,
      },
      userId: user!.username,
    };

    await sendMessage(payload);

    trackEvent({ numberRecords: list.number_of_records, listView: true });
  };

  useEffect(() => {
    const checkExtensionInstalation = async () => {
      if (window.chrome?.runtime && extensionId) {
        try {
          const payload: CheckInstallationExtensionMessage = {
            type: MessageType.CheckInstallation,
          };

          await sendMessage(payload);

          setInstalled(true);
        } catch (e) {
          setInstalled(false);
        }
      } else {
        setInstalled(false);
      }
    };

    checkExtensionInstalation();

    // add focus listener as it can happen that extension was installed/deinstalled/disabled
    window.addEventListener('focus', checkExtensionInstalation);

    return () => {
      window.removeEventListener('focus', checkExtensionInstalation);
    };
  }, [extensionId, sendMessage]);

  return { extensionId, installed, exportRecords, openList };
};

export default useLinkedInExtension;
