import Building02Icon from '@demandscience/ui/icons/building-02';

import {
  AggregateField,
  CompanyFilterName,
  CompanyFilters,
  CompanyIndustryFilter,
  Filter,
  FilterName,
  ModelType,
} from 'types';
import useFieldAggregate from 'hooks/useFieldAggregate';
import Aggregate from 'components/DataProfile/Aggregate';
import { filter as filterFn, isEmpty, map, omit } from 'lodash';
import { getIndustryLabel } from 'components/Filters/utils';
import { useCallback } from 'react';

const getLabel = (value: CompanyIndustryFilter[number]) => getIndustryLabel(value, true) as string;

interface CompanyIndustryAggregateProps {
  companyFilters: CompanyFilters;
  setFilter: (name: FilterName, value?: Filter) => void;
}

const CompanyIndustryAggregate = ({ companyFilters, setFilter }: CompanyIndustryAggregateProps) => {
  const { aggregate, isFetching } = useFieldAggregate(
    ModelType.Company,
    AggregateField.CompanyIndustry,
    companyFilters,
  );

  const { [CompanyFilterName.CompanyIndustry]: filter = [] } = companyFilters;
  // keep only included industries
  const value = filterFn(filter, ({ exclude }) => !exclude);

  const handleFilterChange = useCallback(
    (value: CompanyIndustryFilter) => {
      setFilter(CompanyFilterName.CompanyIndustry, isEmpty(value) ? undefined : value);
    },
    [setFilter],
  );

  // NOTE:
  // remove subcategories from data so these are not used as part of a filter when aggregate value clicked
  // here is only possible to filter by top industry category
  const industryAggregateWithoutSubCategory: typeof aggregate = {
    ...aggregate,
    values: map(aggregate.values, (agg) => ({ ...agg, data: omit(agg.data, 'sub_category') })),
  };

  return (
    <Aggregate
      label="Industry"
      icon={Building02Icon}
      value={value}
      onChange={handleFilterChange}
      loading={isFetching}
      aggregate={industryAggregateWithoutSubCategory}
      getLabel={getLabel}
      mouseOverFilterId={CompanyFilterName.CompanyIndustry}
      aggregateField={AggregateField.CompanyIndustry}
    />
  );
};

export default CompanyIndustryAggregate;
