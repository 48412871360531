import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import MenuItem from './MenuItem';
import { Combobox } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { RadioList } from '@demandscience/ui';

interface RadioButtonMenuItemProps {
  option: {
    description?: string;
    label: string;
    value: any;
  };
  trailingContent?: ReactNode;
  useValue?: true;
}

const RadioButtonMenuItem = ({ option, useValue, trailingContent }: RadioButtonMenuItemProps) => {
  return (
    <Combobox.Option value={useValue ? option.value : option} as={Fragment}>
      {({ active, selected }) => (
        <MenuItem
          className={overrideTailwindClasses(
            classNames('group cursor-pointer hover:bg-gray-50', {
              'bg-gray-50': !selected && active,
              'bg-gray-100 hover:bg-gray-200': selected && !active,
              'bg-gray-200 hover:bg-gray-200': selected && active,
            }),
          )}
          titleClassName="ellipsis"
          title={option.label}
          subtitleClassName="ellipsis"
          subtitle={option.description}
          leadingContent={<RadioList.Icon checked={selected} size={18} theme="primary" />}
          trailingContent={trailingContent}
        />
      )}
    </Combobox.Option>
  );
};

export default RadioButtonMenuItem;
