import useModal from 'hooks/useModal';

import useSnackbar from 'components/Snackbar/useSnackbar';
import useMutateSearchResults from 'components/Search/useMutateSearchResults';
import RevealSingleContactContext, {
  RevealSingleContactContextInterface,
} from './RevealSingleContactContext';
import NoCreditOverlay from './NoCreditOverlay';
import CreditsProvider from 'components/Credits/CreditsProvider';
import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import { getExportFilters } from 'components/Export/utils';
import { KlarityFeature, ModelType } from 'types';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

interface RevealSingleContactProviderProps {
  children: React.ReactNode;
}

const RevealSingleContactProvider = ({ children }: RevealSingleContactProviderProps) => {
  const {
    open: openNoCredit,
    openModal: openNoCreditModal,
    closeModal: closeNoCreditModal,
  } = useModal();
  const { reveal } = useMutateSearchResults();
  const { featureStatus, requestFeatures } = useFeatureFlag(KlarityFeature.Contacts);
  const { showMessage } = useSnackbar();

  const handleRevealSingleContact = useCallback(
    async (id: string) => {
      if (featureStatus) {
        requestFeatures();
        return;
      }

      try {
        const filters = getExportFilters(ModelType.Contact, { dsid: [id] });

        await reveal.mutateAsync({ filters });
      } catch (e: any) {
        if (e instanceof AxiosError) {
          if (
            e.response?.data?.error === 'not enough credits at account level' ||
            e.response?.data?.error === 'not enough bulk credits available at the organization'
          ) {
            openNoCreditModal();
          } else {
            showMessage(e.response?.data?.error || 'Unable to show contact details', 'error');
          }
        } else {
          showMessage('Unexpected error, please try again later', 'error');
        }
      }
    },
    [featureStatus, openNoCreditModal, requestFeatures, reveal, showMessage],
  );

  const value: RevealSingleContactContextInterface = useMemo(
    () => ({
      revealSingleContact: handleRevealSingleContact,
      isLoading: reveal.isLoading,
    }),
    [handleRevealSingleContact, reveal.isLoading],
  );

  return (
    <RevealSingleContactContext.Provider value={value}>
      {children}
      <CreditsProvider>
        <NoCreditOverlay open={openNoCredit} onClose={closeNoCreditModal} />
      </CreditsProvider>
    </RevealSingleContactContext.Provider>
  );
};

export default RevealSingleContactProvider;
