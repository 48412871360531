import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import CollapseContext, { CollapseContextInterface } from './CollapseContext';

interface CollapseProviderProps {
  children: React.ReactNode;
  collapse?: boolean;
}

const CollapseProvider: React.FC<CollapseProviderProps> = ({
  collapse: defaultCollapse = false,
  children,
}) => {
  const [collapse, setCollapse] = useState(defaultCollapse);
  const [collapseGroup, setCollapseGroup] = useState<Record<string, boolean>>({});
  const [hideEmpty, setHideEmpty] = useState(false);

  const toggle = useCallback(() => {
    setCollapse((state) => !state);
  }, []);

  const toggleGroup = useCallback((group: string) => {
    setCollapseGroup((state) => ({ ...state, [group]: !state[group] }));
  }, []);

  const toggleHideEmpty = useCallback(() => {
    setHideEmpty((state) => !state);
  }, []);

  const value: CollapseContextInterface = useMemo(
    () => ({
      collapse,
      toggle,
      collapseGroup,
      toggleGroup,
      hideEmpty,
      toggleHideEmpty,
    }),
    [collapse, collapseGroup, hideEmpty, toggle, toggleGroup, toggleHideEmpty],
  );

  return <CollapseContext.Provider value={value}>{children}</CollapseContext.Provider>;
};

CollapseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CollapseProvider;
