import XIcon from '@demandscience/ui/icons/x';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import Search from 'components/Input/Search';

import { ListFilter, ListOrigin, ModelType, Show } from 'types';
import Dropdown from 'components/Filter/Dropdown';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';
import CheckboxMenuItem from 'components/Filter/Dropdown/CheckboxMenuItem';
import DividerMenuItem from 'components/Filter/Dropdown/DividerMenuItem';

import { includes, isEqual, join, map, reduce } from 'lodash';
import classNames from 'classnames';
import { useCallback } from 'react';

const LABELS: Record<string, string> = {
  [Show.Own]: 'Owned by me',
  [Show.Shared]: 'Shared with me',
  [ModelType.Contact]: 'Contacts',
  [ModelType.Company]: 'Companies',
  [ListOrigin.Search]: 'Klarity lists',
  [ListOrigin.Upload]: 'Uploaded lists',
  [ListOrigin.Automated]: 'Automated lists',
};

const getLabel = (value: any[]) => {
  if (isEqual(value, [Show.All])) {
    return 'All lists';
  }

  return join(
    map(value, (key: string) => LABELS[key]),
    ', ',
  );
};

const getSelectValue = (value?: ListFilter) => {
  let res: Array<ListOrigin | ModelType | Show> = [];

  if (isEqual(value, { show: Show.All })) {
    res = [Show.All];
  } else {
    if (value?.records_origin) {
      res = [...res, value.records_origin];
    }
    if (value?.records_type) {
      res = [...res, value.records_type];
    }
    if (value?.show && value?.show !== Show.All) {
      res = [...res, value.show];
    }
  }

  return res;
};

interface ListsFiltersProps {
  onChange: (value: any) => void;
  value?: ListFilter;
}

const ListsFilters = ({ value, onChange }: ListsFiltersProps) => {
  const selectValue = getSelectValue(value);

  const handleChange = useCallback(
    (field: string, fieldValue: any) => {
      if (isEmpty(fieldValue)) {
        onChange(omit(value, field));
      } else {
        onChange({
          ...value,
          [field]: fieldValue,
        });
      }
    },
    [onChange, value],
  );

  const handleSearchChange = useCallback(
    (name?: string) => {
      handleChange('name', name);
    },
    [handleChange],
  );

  const handleSelectChange = useCallback(
    (newValue: any) => {
      if (isEmpty(newValue) || (!includes(selectValue, Show.All) && includes(newValue, Show.All))) {
        onChange({
          ...omit(value, 'records_origin', 'records_type', 'show'),
          show: Show.All,
        });
      } else {
        const res = reduce(
          newValue,
          (res, val) => {
            if (val === Show.All) {
              return res;
            }

            let key = 'show';
            if (includes(ListOrigin, val)) {
              key = 'records_origin';
            }
            if (includes(ModelType, val)) {
              key = 'records_type';
            }

            return {
              ...res,
              [key]: val,
            };
          },
          {} as Partial<ListFilter>,
        );

        onChange({
          ...omit(value, 'records_origin', 'records_type', 'show'),
          show: Show.All,
          ...res,
        });
      }
    },
    [onChange, selectValue, value],
  );

  const handleClearAll = useCallback(() => {
    onChange({ show: Show.All });
  }, [onChange]);

  return (
    <>
      <div className="md:w-64">
        <Search value={value?.name} onChange={handleSearchChange} placeholder="Find a list" />
      </div>
      <Dropdown
        menuClassName="max-h-auto"
        value={selectValue}
        onChange={handleSelectChange}
        multiple
        button={({ open, value }) => (
          <button className="px-4 py-2 text-sm text-gray-700 text-left w-full md:w-52 flex flex-row items-center gap-1 border-none rounded ring-inset ring-1 ring-gray-300 hover:ring-gray-700 focus:ring-primary-500 focus:hover:ring-primary-500 focus:outline-none">
            <div className="grow ellipsis">{getLabel(value)}</div>
            {!isEqual(value, [Show.All]) && (
              <XIcon size={18} className="flex-shrink-0 text-gray-500" onClick={handleClearAll} />
            )}
            <ChevronDownIcon
              size={18}
              className={classNames('flex-shrink-0', { 'rotate-180': open })}
            />
          </button>
        )}
      >
        <CheckboxMenuItem option={{ id: Show.All, label: 'All lists', value: Show.All }} />
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">List type</TextMenuItem>
        {map([ModelType.Company, ModelType.Contact], (value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label: LABELS[value], value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">List ownership</TextMenuItem>
        {map([Show.Own, Show.Shared], (value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label: LABELS[value], value }} />
        ))}
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">List origin</TextMenuItem>
        {map([ListOrigin.Search, ListOrigin.Upload, ListOrigin.Automated], (value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label: LABELS[value], value }} />
        ))}
      </Dropdown>
    </>
  );
};

export default ListsFilters;
