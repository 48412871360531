import classNames from 'classnames';

interface ListItemProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  leadingContent?: React.ReactNode;
  subtitle?: React.ReactNode | string;
  title?: React.ReactNode | string;
  trailingContent?: React.ReactNode;
}

const ListItem = ({
  className,
  leadingContent,
  title,
  subtitle,
  trailingContent,
  ...props
}: ListItemProps) => (
  <div
    className={classNames('flex flex-row gap-2 items-center px-4 py-4 hover:bg-gray-50', className)}
    {...props}
  >
    {leadingContent && <div className="flex-shrink-0 mr-4">{leadingContent}</div>}
    <div className="flex-1">
      <div className="text-sm text-gray-700">{title}</div>
      <div className="text-xs text-gray-500">{subtitle}</div>
    </div>
    {trailingContent && <div className="flex-shrink-0">{trailingContent}</div>}
  </div>
);

export default ListItem;
