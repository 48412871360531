import { FC } from 'react';
import map from 'lodash/map';
import { companiesSortOptions, contactsSortOptions, ModelType, SortBy } from 'types';
import classNames from 'classnames';
import { DropdownMenu, IconButton, Tooltip } from '@demandscience/ui';
import SortByIcon from '@demandscience/ui/icons/sort';

interface SortByMenuProps {
  className?: string;
  kind: ModelType;
  onChange: (newSort: SortBy) => void;
  value: SortBy;
}

const orderingFields = {
  [ModelType.Company]: companiesSortOptions,
  [ModelType.Contact]: contactsSortOptions,
} as Record<
  ModelType,
  {
    label: string;
    value: string;
  }[]
>;

const orders = [
  {
    value: false,
    label: 'Ascending (1-9, A-Z)',
  },
  {
    value: true,
    label: 'Descending (9-1, Z-A)',
  },
];

const SortByMenu: FC<SortByMenuProps> = ({ kind, value, onChange }) => {
  const fields = orderingFields[kind];
  const { by: field, descending } = value;

  const handleFieldChange = (newField: string) => () => {
    onChange({
      by: newField,
      descending,
    });
  };

  const handleOrderChange = (newDescending: boolean) => () => {
    onChange({
      by: field,
      descending: newDescending,
    });
  };

  return (
    <DropdownMenu
      buttonType="custom"
      customButton={
        <Tooltip title="Sort by" position="bottom" showArrow>
          <IconButton size="sm" theme="default" Icon={SortByIcon} />
        </Tooltip>
      }
      dropdownClassName="py-2 max-h-none"
    >
      <DropdownMenu.Item contentOnly className="text-xs text-gray-500">
        Sort by
      </DropdownMenu.Item>
      {map(fields, ({ value, label }) => (
        <DropdownMenu.Item
          key={value}
          className={classNames({ 'bg-gray-100': field === value })}
          onClick={handleFieldChange(value)}
        >
          <div className="text-sm">{label}</div>
        </DropdownMenu.Item>
      ))}
      <div className="border-b my-2" />
      {map(orders, ({ label, value }: { label: string; value: boolean }) => (
        <DropdownMenu.Item
          key={label}
          className={classNames({ 'bg-gray-100': descending === value })}
          onClick={handleOrderChange(value)}
        >
          <div className="text-sm">{label}</div>
        </DropdownMenu.Item>
      ))}
    </DropdownMenu>
  );
};

export default SortByMenu;
