import { Button } from '@demandscience/ui';
import { useCallback } from 'react';
import OnboardingTooltip from './OnboardingTooltip';

interface OnboardingTooltipsNo1Props {
  onClose: () => void;
  open: boolean;
}

const OnboardingTooltipsNo1 = ({ open, onClose }: OnboardingTooltipsNo1Props) => {
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <OnboardingTooltip
      anchorElId="dashboard_search_filters"
      open={open}
      onClose={handleClose}
      placement="right"
      title="Your answers were added here as filters"
      subtitle="You can now change or add more filters to create your ideal list of prospects"
      showArrow
      button={
        <Button
          size="sm"
          onClick={onClose}
          data-event="tutorial_complete"
          data-event-value="Prospect list"
        >
          Got it
        </Button>
      }
    />
  );
};

export default OnboardingTooltipsNo1;
