import { ReactNode } from 'react';
import { Badge } from '@demandscience/ui';
import useNotifications from './useNotifications';

interface NotificationsCounterProps {
  children?: (props: { count: number }) => any;
}

const defaultChildrenFn = ({ count, ...props }: { count: number }): ReactNode => {
  if (count > 0) {
    return <Badge {...props}>{count}</Badge>;
  }

  return null;
};

const NotificationsCounter = ({
  children = defaultChildrenFn,
  ...props
}: NotificationsCounterProps) => {
  const { query } = useNotifications({
    notifyOnChangeProps: ['data'],
    enabled: false, // no need to run the query, use already fetched cached value
  });
  const count = query.data?.pages?.[0]?.count || 0;

  return children({ count, ...props });
};

export default NotificationsCounter;
