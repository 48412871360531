import { ToastProps } from '@demandscience/ui';
import { Severity } from '@demandscience/ui/dist/types';
import { createContext } from 'react';

export interface SnackbarContextInterface {
  showMessage: (
    value: string,
    severity: Severity,
    options?: Omit<ToastProps, 'subtitle' | 'severity'>,
  ) => void;
}

const SnackbarContext = createContext<SnackbarContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  SnackbarContext.displayName = 'SnackbarContext';
}

export default SnackbarContext;
