import { useSortable } from '@dnd-kit/sortable';
import { UniqueIdentifier } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import DragHandle from './DragHandle';

interface SortableItemProps<T extends UniqueIdentifier> {
  children: ReactElement;
  disabled?: boolean;
  id: T;
}

const SortableItem = <T extends UniqueIdentifier>({
  id,
  disabled,
  children,
}: SortableItemProps<T>) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({
    id,
    disabled: {
      draggable: disabled,
      droppable: disabled,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className={classNames({ invisible: isDragging })}>
      {React.cloneElement(children, {
        handle: disabled ? null : (
          <DragHandle
            className="cursor-grab"
            ref={setActivatorNodeRef}
            {...listeners}
            {...attributes}
          />
        ),
      })}
    </div>
  );
};

export default SortableItem;
