import { Route, Routes, useLocation } from 'react-router-dom';
import FiltersProvider from 'components/Filters/FiltersProvider';
import CustomizationProvider from 'components/SearchCustomization/CustomizationProvider';
import JuiceExtensionMessageHandler from 'components/JuiceExtension/MessageHandler';

import Main from './Main';
import { Filters } from 'types/filters';
import CompareProvider from 'components/Filters/CompareProvider';
import FeatureFlagProvider from 'components/FeatureFlag/FeatureFlagProvider';
import BookMarkHome from 'components/BookMark/ViewBookMark/BookMarkHome';
import TabsProvider from './Tabs/TabsProvider';
import OpenBookmark from 'components/BookMark/OpenBookmark/OpenBookmark';

type LocationState = {
  collapseFilters?: boolean;
  filters?: Filters;
};

const Search = () => {
  const { state, pathname } = useLocation();

  const { filters, collapseFilters } = (state || {}) as LocationState;

  return (
    <FeatureFlagProvider>
      <CompareProvider>
        <FiltersProvider filters={filters}>
          <CustomizationProvider>
            <TabsProvider>
              {pathname !== '/search/bookmark' && pathname !== '/search/bookmarkModule' && (
                <Main collapseFilters={collapseFilters} />
              )}
              <Routes>
                <Route path="bookmark" element={<BookMarkHome />}></Route>
              </Routes>
              <Routes>
                <Route path="bookmarkModule" element={<OpenBookmark />} />
              </Routes>
            </TabsProvider>
          </CustomizationProvider>
          {/* component under must be wrapped in FiltersProvider */}
          <JuiceExtensionMessageHandler />
        </FiltersProvider>
      </CompareProvider>
    </FeatureFlagProvider>
  );
};

export default Search;
