import React, { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { Button, TextField } from '@demandscience/ui';

import Alert from 'components/Alert';
import useAuth from './useAuth';

type EmailConfirmFormValues = {
  code: string;
};

export interface EmailConfirmProps {
  onEmailConfirm: () => void;
}

const EmailConfirm: React.FC<EmailConfirmProps> = ({ onEmailConfirm }) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const [resending, setResending] = useState(false);
  const { verifyCurrentUserAttribute, verifyCurrentUserAttributeSubmit } = useAuth();
  const { control, handleSubmit, formState } = useForm({
    defaultValues: { code: '' },
  });
  const { isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<EmailConfirmFormValues> = async (data) => {
    setApiError(null);

    try {
      const { code } = data;
      await verifyCurrentUserAttributeSubmit('email', code);

      if (onEmailConfirm) onEmailConfirm();
    } catch (e: any) {
      const { message } = e;

      setApiError(message);
    }
  };

  const handleResend = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setApiError(null);
    setResending(true);

    try {
      setResending(true);

      await verifyCurrentUserAttribute('email');
    } catch (e: any) {
      const { message } = e;

      setApiError(message);
    } finally {
      setResending(false);
    }
  };

  return (
    <>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="space-y-4">
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <TextField
                label="Verification code"
                placeholder="Enter a code"
                variant="outlined"
                size="lg"
                required
                autoComplete="off"
                error={errors.code?.message}
                {...field}
              />
            )}
            rules={{ required: 'Required field' }}
          />
          {apiError && <Alert message={apiError} />}
        </div>

        <Button className="w-full mt-6" size="lg" type="submit" disabled={isSubmitting}>
          Confirm
        </Button>
      </form>
      <div className="self-end">
        <button
          className="text-sm text-primary-500 underline"
          onClick={handleResend}
          disabled={resending}
        >
          Resend verification code
        </button>
      </div>
    </>
  );
};

export default EmailConfirm;
