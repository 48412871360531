import api from 'api';
import fileDownload from 'js-file-download';
import { BookMarkPayload, SearchResult2 } from 'types/BookMark';
import axios from 'axios';

export const createBookMark = (payload: BookMarkPayload): Promise<void> =>
  api.post(`search/data-profiles/modules/bookmarks`, payload);

export const bookmarkList = (payload: any): Promise<any> =>
  api.post(`account/me/bookmarks`, payload);

export const openBookmark = (id: string): Promise<any> =>
  api.get(`/search/data-profiles/modules/bookmarks/${id}`);

export const downloadExport = async (id: string, filename: string): Promise<void> => {
  const { data: url } = await api.post(`search/data-profiles/modules/bookmarks/${id}/export`, {
    export_type: 'csv',
  });
  const { data } = await axios.get(url, { responseType: 'blob' });
  fileDownload(data, filename);
};

export const deleteBookmark = (id: string): Promise<void> =>
  api.delete(`search/data-profiles/modules/bookmarks/${id}`);

export const downloadExportList = async (payload: {}): Promise<any> =>
  api.post(`search/data-profiles/modules/bookmarks/export`, payload);

export const downloadExportfromNotification = async (
  bookmark_export_id: string,
  filename: string,
): Promise<void> => {
  const { data: url } = await api.get(
    `search/data-profiles/modules/bookmarks/${bookmark_export_id}/download`,
  );
  const { data } = await axios.get(url, { responseType: 'blob' });

  fileDownload(data, filename);
};

export const deleteBookmarkList = (payload: any): Promise<any> => {
  return api.delete(`search/data-profiles/modules/bookmarks`, { data: payload });
};

export const updateBookmark = (payload: SearchResult2 | any): Promise<void> => {
  const { id, ...restpayload } = payload;

  return api.patch(`search/data-profiles/modules/bookmarks/${id}`, restpayload);
};

export const addComment = ({ comment, id }: { comment: string; id: string }): Promise<any> =>
  api.post(`search/data-profiles/modules/bookmarks/${id}/comments`, { comment });
