import { Button, TextArea } from '@demandscience/ui';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CreateBounceFormValues, CreateBouncePayload, UploadBounceResponse } from 'types';

import UploadBounceFile from './UploadBounceFile';
import useApiValidation from 'hooks/useApiValidation';
import useMutateBouncesList from './useMutateBouncesList';
import { useState } from 'react';
import { size } from 'lodash';

interface UploadListFormProps {
  onCancel: () => void;
}

const UploadBounceForm = ({ onCancel }: UploadListFormProps) => {
  const [uploadResponse, setUploadResponse] = useState<UploadBounceResponse | undefined>();
  const { control, handleSubmit, formState, setError } = useForm<CreateBounceFormValues>({
    defaultValues: { reporter_message: '' },
  });

  const { isSubmitting } = formState;
  const { apiError, setApiError, handleApiValidation } =
    useApiValidation<CreateBounceFormValues>(setError);

  const { create } = useMutateBouncesList();

  const buildPayload = ({ file_name, ...data }: CreateBounceFormValues) => {
    // Limit the reporter_message to be length of 5000 characters
    const reporter_message =
      (data?.reporter_message as string)?.length > 5000
        ? data?.reporter_message?.substring(0, 5000)
        : data?.reporter_message;

    let payload: CreateBouncePayload = {
      object_name: file_name,
      ...data,
      reporter_message,
    };

    if (uploadResponse) {
      payload = {
        ...payload,
        bounce_id: uploadResponse?.bounce_id,
        object_name: uploadResponse?.file_name,
      };
    }

    return payload;
  };

  const onSubmit: SubmitHandler<CreateBounceFormValues> = async (data) => {
    setApiError(null);

    const payload: CreateBouncePayload = buildPayload(data);

    try {
      await create.mutateAsync(payload);
      // Close the Modal window after the bounce is submitted
      onCancel();
    } catch (e: any) {
      handleApiValidation(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        <UploadBounceFile onSuccess={setUploadResponse} />
        <Controller
          name="reporter_message"
          control={control}
          render={({ field }) => (
            <TextArea
              label="Message to Klarity"
              placeholder="Optional message"
              {...field}
              trailingLabel={`${size(field.value)}/${5000} characters`}
              maxLength={5000}
              helperText={
                (field?.value as string)?.length >= 5000
                  ? 'Message length upto 5000 characters only.'
                  : ''
              }
              helperTextClassName={'ml-1 text-error-500 '}
            />
          )}
        />
        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>
      <div className="flex justify-end space-x-2 mt-8">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button
          type="submit"
          theme="primary"
          disabled={!uploadResponse?.bounce_id || isSubmitting || create?.isSuccess}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default UploadBounceForm;
