import AuthProvider from 'components/Auth/AuthProvider';
import SnackbarProvider from 'components/Snackbar/SnackbarProvider';
import OnboardingProvider from 'components/Onboarding/OnboardingProvider';
import ConsentManagerProvider from 'components/ConsentManager/ConsentManagerProvider';

import Tracking from 'components/Tracking';
import ScrollToTop from 'components/ScrollToTop';

import Zendesk from 'components/Zendesk';
import VersionCheck from 'components/VersionCheck';
import Routes from './routes';
import UnreadNotificationsToasts from 'components/Notifications/UnreadNotificationsToasts';
import NotificationsProvider from 'components/Notifications/NotificationsProvider';

const cognitoAuthConfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    domain: process.env.REACT_APP_COGNITO_APP_DOMAIN,
    redirectSignIn: process.env.REACT_APP_COGNITO_SIGN_IN_REDIRECT_URI,
    redirectSignOut: process.env.REACT_APP_COGNITO_SIGN_OUT_REDIRECT_URI,
    responseType: 'code',
  },
};

const App = () => (
  <ConsentManagerProvider>
    <Tracking>
      <SnackbarProvider>
        <AuthProvider showSplash config={cognitoAuthConfig}>
          <NotificationsProvider>
            <UnreadNotificationsToasts />
          </NotificationsProvider>
          <OnboardingProvider>
            <ScrollToTop />
            <Routes />
          </OnboardingProvider>
        </AuthProvider>
      </SnackbarProvider>
    </Tracking>
    <Zendesk />
    <VersionCheck />
  </ConsentManagerProvider>
);

export default App;
