export interface ModelRowProps<T> {
  active: boolean;
  dense?: boolean;
  index?: number;
  lastItem?: boolean;
  nbOfRows?: number;
  onClick: () => void;
  onSelect: (selected: boolean) => void;
  row: T;
  rowLayout?: RowLayout;
  selected: boolean;
  selectionDisabled?: boolean;
}

export enum RowLayout {
  Narrow = 'Narrow',
  Wide = 'Wide',
}
