const Logo = (props: React.HTMLAttributes<SVGElement>) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.1985 16.6399C18.1985 17.8218 17.254 18.7674 16.0734 18.7674C14.8927 18.7674 13.9482 17.8218 13.9482 16.6399C13.9482 15.4579 14.8927 14.5124 16.0734 14.5124C17.254 14.5124 18.1985 15.4579 18.1985 16.6399Z"
      fill="#233857"
    />
    <path
      d="M30.2405 8.84141C30.2405 10.0221 29.296 10.9666 28.1153 10.9666C26.9347 10.9666 25.9902 10.0221 25.9902 8.84141C25.9902 7.66073 26.9347 6.71619 28.1153 6.71619C29.296 6.63748 30.2405 7.58202 30.2405 8.84141Z"
      fill="#D02C2F"
    />
    <path
      d="M28.1153 12.4619C27.1709 12.4619 26.3051 11.8804 25.9902 10.9666V23.2616C25.9902 24.5077 26.9347 25.5046 28.1153 25.5046C29.296 25.5046 30.2405 24.5077 30.2405 23.2616V11.0497C29.8469 11.8804 29.0598 12.4619 28.1153 12.4619Z"
      fill="#D02C2F"
    />
    <path
      d="M22.0939 16.402C21.1494 16.402 20.2836 15.805 19.9688 14.8669V19.3015C19.9688 20.5807 20.9132 21.604 22.0939 21.604C23.2745 21.604 24.219 20.5807 24.219 19.3015V14.8669C23.8254 15.805 23.0384 16.402 22.0939 16.402Z"
      fill="#D02C2F"
    />
    <path
      d="M16.0734 20.218C15.1289 20.218 14.2631 19.6539 13.9482 18.7675V21.9104C13.9482 23.1192 14.8927 24.0862 16.0734 24.0862C17.254 24.0862 18.1985 23.1192 18.1985 21.9104V18.7675C17.8049 19.6539 17.0178 20.218 16.0734 20.218Z"
      fill="#D02C2F"
    />
    <path
      d="M24.219 12.7419C24.219 13.9225 23.2745 14.8671 22.0939 14.8671C20.9132 14.8671 19.9688 13.9225 19.9688 12.7419C19.9688 11.5612 20.9132 10.6166 22.0939 10.6166C23.2745 10.5379 24.219 11.4825 24.219 12.7419Z"
      fill="#D02C2F"
    />
    <path
      d="M2.25977 8.83672C2.25977 7.66305 3.21121 6.71161 4.38488 6.71161C5.55854 6.71161 6.50999 7.66305 6.50999 8.83672V23.3795C6.50999 24.5531 5.55854 25.5046 4.38488 25.5046C3.21121 25.5046 2.25977 24.5531 2.25977 23.3795V8.83672ZM7.9269 12.8584C7.9269 11.6847 8.87834 10.7333 10.052 10.7333C11.2257 10.7333 12.1771 11.6847 12.1771 12.8584V19.3579C12.1771 20.5315 11.2257 21.483 10.052 21.483C8.87834 21.483 7.9269 20.5315 7.9269 19.3579V12.8584ZM6.51 12.7944V19.4858C6.51 19.0946 6.82715 18.7775 7.21837 18.7775C7.60959 18.7775 7.92674 19.0946 7.92674 19.4858V12.7944C7.92674 13.1856 7.60959 13.5027 7.21837 13.5027C6.82715 13.5027 6.51 13.1856 6.51 12.7944Z"
      fill="#233857"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default Logo;
