import { useQuery } from '@tanstack/react-query';

import { searchExports } from 'api/export';
import { SearchExportsParams } from 'types';

const useExportsSearch = (params: SearchExportsParams, options?: Record<string, any>) => {
  const query = useQuery(['exports', 'search', params], () => searchExports(params), {
    staleTime: 60 * 1000, // 1 minute
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export default useExportsSearch;
