import { Badge, BadgeProps } from '@demandscience/ui';
import useOrganization from './useOrganization';

const AvailableSeatsBadge = (props: BadgeProps) => {
  const { data: organization } = useOrganization();

  if (!organization || organization.active_accounts?.available === -1) {
    return null;
  }

  return (
    <Badge size="md" {...props}>
      {organization.active_accounts?.available.toLocaleString()} left
    </Badge>
  );
};

export default AvailableSeatsBadge;
