import useCustomization from 'components/SearchCustomization/useCustomization';
import { IconButton, Tooltip } from '@demandscience/ui';
import StretchErticalIcon from '@demandscience/ui/icons/stretch-ertical';
import TableIcon from '@demandscience/ui/icons/table';
import { useCallback } from 'react';
import { ViewLayout } from 'types';

const ViewLayoutToggle = () => {
  const customization = useCustomization();
  const { layout, onChange } = customization;
  const { view: value } = layout;

  const Icon = value === ViewLayout.Table ? StretchErticalIcon : TableIcon;
  const handleClick = useCallback(() => {
    if (value === ViewLayout.List) {
      onChange('layout', { ...layout, view: ViewLayout.Table });
    } else if (value === ViewLayout.Table) {
      onChange('layout', { ...layout, view: ViewLayout.List });
    }
  }, [layout, onChange, value]);

  return (
    <div className="inline-block">
      <Tooltip
        title={value === ViewLayout.Table ? 'Switch to list view' : 'Switch to table view'}
        position="bottom"
        showArrow
      >
        <IconButton size="sm" onClick={handleClick} theme="default" Icon={Icon} />
      </Tooltip>
    </div>
  );
};

export default ViewLayoutToggle;
