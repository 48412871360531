import { Alert, Button, Checkbox, Spinner, TextField } from '@demandscience/ui';
import CheckCircleIcon from '@demandscience/ui/icons/check-circle';
import CreditsControl from 'components/Credits/CreditsControl';
import { ReactNode } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { ExportResponse } from 'types';

interface ExportInfoProps {
  apiError: string | null;
  asyncExport?: boolean;
  children?: ({ missingCredits }: { missingCredits: number }) => ReactNode;
  count: number;
  exportInfo: ExportResponse | null;
  hasAdvancedSelection: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: ({ name }: { name?: string }) => void;
}

const ExportInfo = ({
  exportInfo,
  isLoading,
  apiError,
  asyncExport,
  count,
  hasAdvancedSelection,
  onSubmit,
  onClose,
  children,
}: ExportInfoProps) => {
  const { control, handleSubmit, formState } = useForm<{ includePurchased: boolean; name: string }>(
    {
      defaultValues: { name: '', includePurchased: true },
    },
  );
  const { isSubmitting, errors } = formState;
  const includePurchased = useWatch({ name: 'includePurchased', control });

  const missingCredits = exportInfo
    ? exportInfo.requested - exportInfo.already_acquired - exportInfo.can_be_revealed
    : 0;
  const exportCount = exportInfo && !includePurchased ? count - exportInfo.already_acquired : count;

  if (asyncExport) {
    return (
      <>
        <div className="space-y-2">
          <Alert
            className="bg-transparent"
            severity="neutral"
            variant="borderless"
            customIcon={<CheckCircleIcon className="w-5 h-5 mr-4 text-success-500" />}
          >
            <Alert.Header>
              <Alert.SubTitle>
                Your export is being processed in the background...
                <br />
                We will notify you when it is ready.
              </Alert.SubTitle>
            </Alert.Header>
          </Alert>
        </div>
        <div className="flex flex-row gap-2 justify-end mt-10">
          <Button size="md" borderless onClick={onClose}>
            Close
          </Button>
        </div>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        {isLoading && <Spinner />}
        {exportInfo && (
          <>
            {exportInfo.already_acquired > 0 && (
              <Alert
                className="bg-transparent"
                severity="neutral"
                variant="borderless"
                customIcon={<CheckCircleIcon className="w-5 h-5 mr-4 text-success-500" />}
              >
                <Alert.Header>
                  <Alert.SubTitle>
                    You already purchased {exportInfo.already_acquired.toLocaleString()} records
                  </Alert.SubTitle>
                </Alert.Header>
                <Alert.Description>
                  <Controller
                    name="includePurchased"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        label="Include in export"
                        size="sm"
                        labelClassName="font-medium"
                      />
                    )}
                  />
                </Alert.Description>
              </Alert>
            )}
            {missingCredits > 0 && (
              <Alert severity="warning">
                <Alert.Header>
                  <Alert.Title>Missing {missingCredits.toLocaleString()} credits</Alert.Title>
                  <CreditsControl
                    as={Alert.ActionButton}
                    onClick={onClose}
                    organizatonLimitReached={
                      exportInfo.error === 'not enough bulk credits available at the organization'
                    }
                  />
                </Alert.Header>
              </Alert>
            )}
            {missingCredits === 0 && exportInfo?.can_be_revealed > 0 && (
              <Alert severity="info">
                {hasAdvancedSelection && (
                  <>
                    <Alert.Header>
                      <Alert.Title>
                        This will use up to {exportInfo.can_be_revealed.toLocaleString()} credits
                      </Alert.Title>
                    </Alert.Header>
                    <div className="text-sm mt-1 text-gray-700 ml-9">
                      We will deduct the number of records you already acquired
                    </div>
                  </>
                )}
                {!hasAdvancedSelection && (
                  <Alert.Header>
                    <Alert.Title>
                      This will use {exportInfo.can_be_revealed.toLocaleString()} credits
                    </Alert.Title>
                  </Alert.Header>
                )}
              </Alert>
            )}
          </>
        )}
        {missingCredits === 0 && (
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <div className="flex-1">
                <TextField
                  variant="outlined"
                  label="Optional export name"
                  placeholder="Enter name..."
                  error={errors.name?.message}
                  {...field}
                />
              </div>
            )}
            rules={{
              maxLength: {
                value: 200,
                message: '200 characters max',
              },
              pattern: {
                value: /^[A-Za-z0-9][A-Za-z0-9\-_ ]+$/,
                message: 'Invalid value',
              },
            }}
          />
        )}
        {children && children({ missingCredits })}
        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>
      <div className="flex flex-row gap-2 justify-end mt-10">
        {missingCredits === 0 && (
          <Button type="button" size="md" borderless onClick={onClose}>
            Dismiss
          </Button>
        )}
        {missingCredits === 0 && (
          <Button
            type="submit"
            size="md"
            theme="primary"
            disabled={isLoading || exportCount === 0 || isSubmitting}
          >
            Export {exportCount.toLocaleString()} records
          </Button>
        )}
        {missingCredits > 0 && (
          <Button type="button" size="md" theme="primary" onClick={onClose}>
            Change selection
          </Button>
        )}
      </div>
    </form>
  );
};

export default ExportInfo;
