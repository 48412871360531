import chrome from './chrome.png';
import { Button, Modal, ModalProps } from '@demandscience/ui';
import LinkedinIcon from '@demandscience/ui/icons/LinkedinBrand';
import InstallButton from './InstallButton';

interface LinkedInOverlayProps extends Omit<ModalProps, 'children'> {}

const LinkedInOverlay = (props: LinkedInOverlayProps) => (
  <Modal
    {...props}
    aria-labelledby="linkedin-browse-dialog-title"
    aria-describedby="linkedin-browse-dialog-description"
    className="max-w-md sm:max-w-md p-0 sm:p-0"
    rootClassName="z-30"
  >
    <div
      className="bg-right-top bg-no-repeat bg-[length:150px] px-6 pt-4 pb-5"
      style={{ backgroundImage: `url('${chrome}')` }}
    >
      <Modal.Title id="linkedin-browse-dialog-title" className="pr-36 mb-4 text-xl font-regular">
        View your selected contacts directly on <strong>Linked</strong>
        <LinkedinIcon className="w-6 h-6 inline align-sub text-[#0e76a8]" />
      </Modal.Title>
      <div className="pr-36">
        <Modal.Description id="linkedin-browse-dialog-description" className="text-base mb-1">
          With Klarity&apos;s LinkedIn browser extension installed, you can browse all your selected
          contacts directly on LinkedIn.
        </Modal.Description>
        <Modal.Description className="text-base">
          View their profiles along with the exhaustive contact details provided by Klarity.
        </Modal.Description>
      </div>
      <div className="flex justify-end space-x-2 mt-4">
        <Button onClick={props.onClose} borderless tabIndex={-1} size="md">
          Cancel
        </Button>
        <InstallButton>Install extension</InstallButton>
      </div>
    </div>
  </Modal>
);

export default LinkedInOverlay;
