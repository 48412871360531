import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from '@demandscience/ui';
import { useMatch, useNavigate } from 'react-router-dom';
import { Filters, ResultsView } from 'types';
import OnboardingContext, { OnboardingContextInterface } from './OnboardingContext';
import OnboardingNo1 from './OnboardingNo1';
import OnboardingNo3 from './OnboardingNo3';
import OnboardingTooltipsNo1 from './OnboardingTooltipsNo1';
import OnboardingTooltipsNo2 from './OnboardingTooltipsNo2';
import OnboardingTooltipsNo3 from './OnboardingTooltipsNo3';

interface OnboardingProviderProps {
  children: React.ReactNode;
}

const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const [open, setOpen] = useState<1 | 2 | 3 | false>(false);
  const [openTooltips, setOpenTooltips] = useState<1 | 2 | 3 | false>(false);
  const navigate = useNavigate();
  const isSearch = useMatch('/search');

  const openOnboarding = useCallback(
    (index: 1 | 2 | 3) => {
      if (index === 1 || index === 3) {
        setOpen(index);

        navigate('/search', { state: { tab: ResultsView.Contacts } });
      } else {
        navigate('/search', {
          state: { tab: ResultsView.DataProfile, collapseFilters: true },
        });

        setOpenTooltips(index);
      }
    },
    [navigate],
  );

  const closeOnboarding = useCallback(() => {
    setOpen(false);
  }, []);

  const closeOnboardingTooltips = useCallback(() => {
    setOpenTooltips(false);
  }, []);

  const handleFinish = useCallback(
    (filters: Filters) => {
      navigate('/search', { replace: true, state: { filters, tab: ResultsView.Contacts } });

      closeOnboarding();

      setOpenTooltips(open);
    },
    [closeOnboarding, navigate, open],
  );

  useEffect(() => {
    // close tooltips if user navigating away from the search view
    if (!isSearch && openTooltips !== false) {
      closeOnboardingTooltips();
    }
  }, [closeOnboardingTooltips, isSearch, openTooltips]);

  const value: OnboardingContextInterface = useMemo(
    () => ({
      openOnboarding,
      closeOnboarding,
    }),
    [closeOnboarding, openOnboarding],
  );

  return (
    <>
      <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>
      <Modal
        className="sm:max-w-3xl h-96 w-ful p-0"
        rootClassName="z-10"
        open={open === 1 || open === 3}
        onClose={closeOnboarding}
      >
        {open === 1 && <OnboardingNo1 onFinish={handleFinish} onSkip={closeOnboarding} />}
        {open === 3 && <OnboardingNo3 onFinish={handleFinish} onSkip={closeOnboarding} />}
      </Modal>
      <OnboardingTooltipsNo1 open={openTooltips === 1} onClose={closeOnboardingTooltips} />
      <OnboardingTooltipsNo2 open={openTooltips === 2} onClose={closeOnboardingTooltips} />
      <OnboardingTooltipsNo3 open={openTooltips === 3} onClose={closeOnboardingTooltips} />
    </>
  );
};

export default OnboardingProvider;
