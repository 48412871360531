import { useCallback, useState } from 'react';
import useMutateList from './useMutateList';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Checkbox, Modal, Transition } from '@demandscience/ui';
import { Visibility, DeleteListPayload, DeleteListFormValues, ListRecord } from 'types';
import MessageInputController from 'components/Input/MessageInputController';
import { AxiosError } from 'axios';

interface ListRecordDeleteActionModalProps extends ListRecord {
  onClose: () => void;
  onSuccess?: () => void;
  open: boolean;
}

const ListRecordDeleteActionModal = ({
  id,
  name,
  visibility,
  open,
  onClose,
  onSuccess,
}: ListRecordDeleteActionModalProps) => {
  const [shouldNotify, setShouldNotify] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit } = useForm<DeleteListFormValues>({
    defaultValues: { notification_message: '' },
  });
  const { destroy } = useMutateList();

  const handleNotificationCheckbox = useCallback(
    (value: boolean) => {
      if (!value) {
        control.unregister('notification_message');
      }

      setShouldNotify(!shouldNotify);
    },
    [control, shouldNotify],
  );

  const onSubmit: SubmitHandler<DeleteListFormValues> = async ({ notification_message }) => {
    let payload: DeleteListPayload = {
      id,
      notify: visibility !== Visibility.Private && shouldNotify ? true : false,
    };

    if (notification_message !== '') {
      payload = { notification_message, ...payload };
    }

    try {
      await destroy.mutateAsync(payload);

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <Modal
      aria-labelledby="delete-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      open={open}
      onClose={onClose}
    >
      <Modal.Title id="delete-list-dialog-title" className="mb-8 break-words">
        Are you sure you want to delete {name}?
      </Modal.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4 mt-10">
          {(visibility === Visibility.Public || visibility === Visibility.Restricted) && (
            <>
              <Checkbox
                checked={shouldNotify}
                onChange={handleNotificationCheckbox}
                label="Notify users who share this list with you"
                labelClassName="select-none"
              />
              <Transition.Collapse show={shouldNotify} as="div">
                <MessageInputController<DeleteListFormValues>
                  name="notification_message"
                  control={control}
                />
              </Transition.Collapse>
            </>
          )}
          {apiError && <div className="text-error-500 text-center mt-8">{apiError}</div>}
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <Button type="button" borderless onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={destroy.isLoading || destroy.isSuccess}>
            Delete
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ListRecordDeleteActionModal;
