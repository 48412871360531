import { useState } from 'react';
import { capitalize } from 'lodash';
import { FieldValues, UseFormSetError } from 'react-hook-form';
import { AxiosError } from 'axios';

const useApiValidation = <T extends FieldValues>(setError: UseFormSetError<T>) => {
  const [apiError, setApiError] = useState<string | null>(null);

  const handleApiValidation = (e: any) => {
    if (e instanceof AxiosError) {
      const { error_field, error } = e.response?.data;

      if (error_field !== undefined) {
        setError(error_field, { type: 'api', message: capitalize(error) });
        return;
      }
      setApiError(error || e.message);
    } else {
      setApiError('Unexpected error, please try again later');
    }
  };

  return {
    apiError,
    setApiError,
    handleApiValidation,
  };
};

export default useApiValidation;
