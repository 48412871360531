import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateSearchCustomization } from 'api/account';
import { SearchCustomization } from 'types';

const useSearchCustomization = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();

  const update = useMutation(updateSearchCustomization, {
    // When mutate is called:
    onMutate: async (payload) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['searchCustomization']);

      // Snapshot the previous value
      const previousSearchCustomization = queryClient.getQueryData<SearchCustomization>([
        'searchCustomization',
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(['searchCustomization'], {
        ...previousSearchCustomization,
        ...payload,
      });

      // Return a context object with the snapshotted value
      return { previousSearchCustomization };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _payload, context: any) => {
      queryClient.setQueryData(['searchCustomization'], context?.previousSearchCustomization);
    },
    ...options,
  });

  return { update };
};

export default useSearchCustomization;
