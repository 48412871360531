import { memo } from 'react';
import map from 'lodash/map';
import range from 'lodash/range';
import XOctagonIcon from '@demandscience/ui/icons/x-octagon';
import {
  CompanyFilters,
  Filter,
  FilterName,
  Filters,
  ModelType,
  ResultsView,
  DataModule,
} from 'types';
import useAggregate from 'hooks/useAggregate';
import ExploreTAMButton from 'components/ExploreTAM/Button';
import TotalCounts from 'components/DataProfile/TotalCounts';
import classNames from 'classnames';
import JobFunctionAggregate from 'components/DataProfile/JobFunctionAggregate';
import JobLevelAggregate from 'components/DataProfile/JobLevelAggregate';
import CompanyCountryAggregate from 'components/DataProfile/CompanyCountryAggregate';
import CompanyEmployeesAggregate from 'components/DataProfile/CompanyEmployeesAggregate';
import CompanyIndustryAggregate from 'components/DataProfile/CompanyIndustryAggregate';
import useSearchResults from 'components/Search/useSearchResults';
import { isEmpty } from 'lodash';
import useCustomization from 'components/SearchCustomization/useCustomization';
import { mergeCompanyPhoneNumbers } from 'components/Search/utils';
import useQueryAggregate from 'hooks/useQueryAggregate';
import CompanyRevenueAggregate from 'components/DataProfile/CompanyRevenueAggregate';

interface DataProfileColumnProps {
  companyFilters: CompanyFilters;
  filters: Filters;
  id?: string;
  iscontactRendered: boolean;
  modules?: {
    type: {
      id: string;
      name: string;
    };
    visual: string;
  }[];
  onTabChange: (tab: ResultsView) => void;
  setFilter: (name: FilterName, value?: Filter) => void;
  setFilters?: (filters: Filters) => void;
}

const DataProfileColumn = memo(
  ({
    id,
    filters,
    companyFilters,
    setFilter,
    setFilters,
    onTabChange,
    modules,
    iscontactRendered,
  }: DataProfileColumnProps) => {
    const ignoreEmptyFilters = false;
    const {
      results: { contactsSortField, companiesSortField },
    } = useCustomization();

    const contact = useSearchResults(ModelType.Contact, filters, {
      sortField: contactsSortField,
      enabled: !ignoreEmptyFilters || !isEmpty(filters),
      select: mergeCompanyPhoneNumbers,
    });

    const company = useSearchResults(ModelType.Company, companyFilters, {
      sortField: companiesSortField,
      enabled: !ignoreEmptyFilters || !isEmpty(filters),
    });

    const { query: contactQuery } = contact;
    const { query: companyQuery } = company;

    const {
      data: [contactCount, companyCount],
      isLoading: queryLoading,
      isError: queryError,
      isFetching: queryFetching,
    } = useQueryAggregate(
      [contactQuery, companyQuery],
      (data) => data?.data?.pagination.totalCount || data?.data?.pagination.count,
    );

    const contactsAggregateQuery = useAggregate(ModelType.Contact, filters);
    const companiesAggregateQuery = useAggregate(ModelType.Company, companyFilters);

    const isLoading =
      queryLoading || contactsAggregateQuery.isLoading || companiesAggregateQuery.isLoading;
    const isError = queryError || contactsAggregateQuery.isError || companiesAggregateQuery.isError;

    return (
      <div id={id} className="columns-sm gap-4 py-4 space-y-4">
        {(isLoading || isError) &&
          map(range(6), (index) => (
            <div
              key={index}
              className={classNames(
                'h-[312px] rounded bg-slate-200 flex items-center justify-around',
                { 'animate-pulse': isLoading },
              )}
            >
              {isError && (
                <div className="text-center text-gray-500">
                  <XOctagonIcon className="inline-block" />
                  <div className="text-sm">Error loading data</div>
                </div>
              )}
            </div>
          ))}
        {!isLoading &&
          !isError &&
          modules?.map((module, index) => (
            <>
              {module.type.id === DataModule.Overview && (
                <div className="break-inside-avoid" key={index}>
                  <TotalCounts
                    contactCount={contactCount as number}
                    companyCount={companyCount as number}
                    loading={queryFetching}
                    iscontactRendered={iscontactRendered}
                  >
                    {setFilters ? (
                      <ExploreTAMButton
                        filters={filters}
                        companyFilters={companyFilters}
                        contactCount={contactCount}
                        companyCount={companyCount}
                        onTabChange={onTabChange}
                        setFilters={setFilters}
                      />
                    ) : null}
                  </TotalCounts>
                </div>
              )}

              {module.type.id === DataModule.ContactDepartment && iscontactRendered && (
                <div className="break-inside-avoid" key={index}>
                  <JobFunctionAggregate filters={filters} setFilter={setFilter} />
                </div>
              )}

              {module.type.id === DataModule.CompanyHeadcount && (
                <div className="break-inside-avoid" key={index}>
                  <CompanyEmployeesAggregate
                    companyFilters={companyFilters}
                    setFilter={setFilter}
                  />
                </div>
              )}

              {module.type.id === DataModule.ContactJobLevel && iscontactRendered && (
                <div className="break-inside-avoid" key={index}>
                  <JobLevelAggregate filters={filters} setFilter={setFilter} />
                </div>
              )}

              {module.type.id === DataModule.CompanyLocation && (
                <div className="break-inside-avoid" key={index}>
                  <CompanyCountryAggregate companyFilters={companyFilters} setFilter={setFilter} />
                </div>
              )}

              {module.type.id === DataModule.CompanyIndustry && (
                <div className="break-inside-avoid" key={index}>
                  <CompanyIndustryAggregate companyFilters={companyFilters} setFilter={setFilter} />
                </div>
              )}
              {module.type.id === DataModule.CompanyRevenue && (
                <div className="break-inside-avoid" key={index}>
                  <CompanyRevenueAggregate companyFilters={companyFilters} setFilter={setFilter} />
                </div>
              )}
            </>
          ))}
      </div>
    );
  },
);

DataProfileColumn.displayName = 'DataProfileColumn';

export default DataProfileColumn;
