import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { Combobox, ComboboxProps } from '@demandscience/ui';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import useSavedSearches from './useSavedSearches';
import { SelectOption } from '@demandscience/ui/dist/types';
import { SavedSearch, Show } from 'types';

interface SavedSearchComboboxProps extends Omit<ComboboxProps, 'options' | 'value' | 'onChange'> {
  onChange: (value: SavedSearch) => void;
  value?: SavedSearch;
}

const SavedSearchCombobox = forwardRef(
  (
    {
      placeholder: defaultPlaceholder,
      value: savedSearch,
      onChange,
      ...props
    }: SavedSearchComboboxProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [loading, setLoading] = useState(false);

    const { data, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage } =
      useSavedSearches({
        filter: { show: Show.Own, is_a_persona: false },
      });

    const searches = flatten(map(data?.pages, 'searches'));
    const options = useMemo(
      () =>
        map(searches, ({ id: value, name: label }) => ({
          value,
          label,
        })),
      [searches],
    );
    const value = find(options, (option) => option.value === savedSearch?.id);
    let placeholder = defaultPlaceholder;

    if (isLoading) {
      placeholder = 'Loading...';
    } else if (isError) {
      placeholder = 'Unable to load saved searches';
    } else if (searches && isEmpty(searches)) {
      placeholder = 'No saved searches found';
    }

    const handleChange = useCallback(
      (optionValue: SelectOption) => {
        const { value: id } = optionValue || {};
        const savedSearch = find(searches, (search) => search.id === id);

        if (savedSearch && onChange) {
          onChange(savedSearch);
        }
      },
      [onChange, searches],
    );

    useEffect(() => {
      if (!isFetchingNextPage) {
        setLoading(false);
      }
    }, [isFetchingNextPage]);

    const handleFetchOptions = useCallback(() => {
      if (loading || !hasNextPage) return;
      setLoading(true);
      fetchNextPage();
    }, [fetchNextPage, hasNextPage, loading]);

    return (
      <Combobox
        {...props}
        ref={ref}
        value={value}
        // @ts-expect-error demandscience-ui isue
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        lazyLoading={loading}
        onLazyLoad={handleFetchOptions}
        disabled={isLoading || isError || isEmpty(options)}
        hideClear
      />
    );
  },
);

SavedSearchCombobox.displayName = 'SavedSearchCombobox';

export default SavedSearchCombobox;
