import IllustrationArrowDown from 'components/Illustrations/IllustrationArrowDown';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import Paragraph from 'components/Typography/Paragraph';
import ExampleSearchList from '../../../../components/SavedSearches/ExampleSearchList';

const EmptySearches = () => (
  <div className="mt-24">
    <IllustrationArrowDown className="w-12" />
    <Paragraph className="text-sm text-gray-700 ml-9">Get started by applying filters</Paragraph>
    <EmptyResults
      message="Need inspiration?"
      illustration={<Illustration.Absurd1 className="w-[120px] h-[120px] mb-6" />}
      className="mt-5"
    >
      <Paragraph className="text-gray-500 mt-1">
        Here are some search examples to get you started
      </Paragraph>
    </EmptyResults>
    <div className="max-w-lg w-full mx-auto mt-10">
      <ExampleSearchList />
    </div>
  </div>
);

export default EmptySearches;
