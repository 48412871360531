import MapPinIcon from '@demandscience/ui/icons/map-pin';
import {
  AggregateField,
  CompanyFilterName,
  CompanyFilters,
  CompanyLocationFilter,
  Filter,
  FilterName,
  ModelType,
} from 'types';
import useFieldAggregate from 'hooks/useFieldAggregate';
import Aggregate from 'components/DataProfile/Aggregate';
import { filter as filterFn, isEmpty, reject } from 'lodash';
import { getLocationLabel } from 'components/Filters/utils';
import { useCallback } from 'react';

interface CompanyCountryAggregateProps {
  companyFilters: CompanyFilters;
  setFilter: (name: FilterName, value?: Filter) => void;
}

const CompanyCountryAggregate = ({ companyFilters, setFilter }: CompanyCountryAggregateProps) => {
  const { aggregate, isFetching } = useFieldAggregate(
    ModelType.Company,
    AggregateField.CompanyLocationCountry,
    companyFilters,
  );

  const { [CompanyFilterName.CompanyLocation]: filter = [] } = companyFilters;

  // keep only included countries
  const value = filterFn(
    filter,
    ({ designation, exclude }) => !exclude && designation === 'country',
  );

  const handleFilterChange = useCallback(
    (value: CompanyLocationFilter) => {
      const { [CompanyFilterName.CompanyLocation]: currentFilter } = companyFilters;
      const locationsExceptCountries = reject(currentFilter, { designation: 'country' });
      const filter = [...locationsExceptCountries, ...(value || [])];

      setFilter(CompanyFilterName.CompanyLocation, isEmpty(filter) ? undefined : filter);
    },
    [companyFilters, setFilter],
  );

  return (
    <Aggregate
      label="Company location"
      icon={MapPinIcon}
      value={value}
      onChange={handleFilterChange}
      loading={isFetching}
      aggregate={aggregate}
      getLabel={getLocationLabel}
      mouseOverFilterId={CompanyFilterName.CompanyLocation}
      aggregateField={AggregateField.CompanyLocationCountry}
    />
  );
};

export default CompanyCountryAggregate;
