import { useCallback, useMemo, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Link, Button } from '@demandscience/ui';
import Heading from 'layouts/Main/Heading';
import ToggleIntegration from './ToggleIntegration';
import User from '@demandscience/ui/icons/user';
import Building from '@demandscience/ui/icons/building';
import ArrowLeft from '@demandscience/ui/icons/arrow-left';
import useMutateIntegration from 'components/Organization/useMutateIntegration';
import useIntegration from 'components/Organization/useIntegrations';
import useSnackbar from 'components/Snackbar/useSnackbar';
import Cookies from 'universal-cookie';
import { AxiosError } from 'axios';

const SettingsHubspot = () => {
  const { showMessage } = useSnackbar();
  const { disconnect } = useMutateIntegration();
  const navigate = useNavigate();
  const { data, refetch, isRefetching }: any = useIntegration();
  const [btnText, setBtnText] = useState('Disable');
  const cookies = useMemo(() => new Cookies(), []);

  const isIntegrated = !!data?.['hubspot-authenticate'];

  const handleDisconnect = useCallback(async () => {
    if (isIntegrated) {
      try {
        setBtnText('Disabling...');
        cookies.remove('hubspot', { path: '/' });
        await disconnect.mutateAsync('hubspot');
        await refetch();
        navigate('/settings/integrations', { replace: true });
      } catch (e: any) {
        if (e instanceof AxiosError) {
          showMessage(e.response?.data?.error || 'Unable to disconnect, please try again', 'error');
        } else {
          showMessage('Unexpected error, please try again later', 'error');
        }
      }
    }
  }, [cookies, disconnect, isIntegrated, navigate, showMessage, refetch]);

  return (
    <>
      <Heading>
        <Link className="flex items-center" as={RouterLink} to={'./..'} state={{ tab: 2 }}>
          <ArrowLeft size={13} className="mr-1" />
          View settings
        </Link>
      </Heading>
      <header className="flex justify-between md:max-w-[90%] mb-6">
        <h1 className="text-xl text-grey-700 font-semibold">Hubspot integration</h1>
        <Button type="button" theme="error" onClick={handleDisconnect}>
          {btnText}
        </Button>
      </header>
      <main className="space-y-6 ">
        <p className="text-sm text-grey-700 font-normal">
          Select the required export options for contacts and companies to your Hubspot account.
        </p>
        <section className="flex flex-col gap-6 max-w-xl">
          <ToggleIntegration
            icon={<User size={20} />}
            text="Contacts to Contacts"
            integrationType="hubspot"
            mappingType="contacts to contacts"
            data={data}
            refetch={refetch}
            isRefetching={isRefetching}
          />
          <ToggleIntegration
            icon={<Building size={20} />}
            text="Companies to Companies"
            integrationType="hubspot"
            mappingType="companies to companies"
            data={data}
            refetch={refetch}
            isRefetching={isRefetching}
          />
        </section>
      </main>
    </>
  );
};

export default SettingsHubspot;
