import useAuth from 'components/Auth/useAuth';
import Alert from 'components/Alert';
import { Account } from 'types';
import { useEffect, useState } from 'react';
import Paragraph from 'components/Typography/Paragraph';

interface ImpersonateProps {
  onSignin: (user: Account) => void;
  targetUsername: string;
  token: string;
  username: string;
}

const Impersonate = ({ username, token, targetUsername, onSignin }: ImpersonateProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { impersonate } = useAuth();

  useEffect(() => {
    const handleImpersonate = async () => {
      if (username && token && targetUsername) {
        try {
          const user = await impersonate(targetUsername, username, token);

          onSignin(user);
        } catch (e: any) {
          const { message } = e;

          setApiError(message);
        }
      }
    };

    handleImpersonate();
  }, [impersonate, onSignin, targetUsername, token, username]);

  return (
    <div>
      <Paragraph className="text-lg mb-4 text-center">
        {!apiError ? 'Signing in....' : 'Oops, something is not right'}
      </Paragraph>
      {apiError && <Alert message={apiError} />}
    </div>
  );
};

export default Impersonate;
