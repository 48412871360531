import Tooltip, { TooltipProps } from 'components/Tooltip';
import { Button, IconButton } from '@demandscience/ui';
import XIcon from '@demandscience/ui/icons/x';
import useElementById from 'hooks/useElementById';
import { useCallback, useEffect, useState } from 'react';
import { isEmpty, size } from 'lodash';

export interface ErrorTooltipProps extends Omit<TooltipProps, 'anchorEl' | 'children'> {
  anchorElId: string;
  button: React.ReactNode;
  hideBackdrop?: true;
  info?: string;
  subtitle: string;
  title: string;
}

const ErrorTooltip = ({
  anchorElId,
  title,
  subtitle,
  info,
  button,
  onClose,
  hideBackdrop,
  ...props
}: ErrorTooltipProps) => {
  const element = useElementById(anchorElId);

  if (!element) {
    return null;
  }

  return (
    <>
      {!hideBackdrop && props.open && (
        <div
          className="fixed inset-0"
          aria-hidden="true"
          data-testid="tooltipBackdrop"
          onClick={onClose}
        />
      )}
      <Tooltip
        anchorEl={element}
        rootClassName="z-10 w-full max-w-xs"
        className="bg-black p-3 rounded"
        {...props}
      >
        <IconButton
          className="float-right -mt-1"
          theme="default"
          round
          size="xs"
          onClick={onClose}
          Icon={XIcon}
          aria-label="Close"
        />
        <div className="text-xs font-semibold text-white mb-1">{title}</div>
        <div className="text-xs text-white">{subtitle}</div>
        <div className="flex flex-row-reverse mt-4 justify-between">
          {button}
          {info && <div className="text-xs text-white">{info}</div>}
        </div>
      </Tooltip>
    </>
  );
};

interface InExListsFilterErrorsProps {
  ids: [string];
}

const InExListsFilterErrors = ({ ids }: InExListsFilterErrorsProps) => {
  const [index, setIndex] = useState(-1);

  const handleClose = useCallback(() => {
    setIndex(-1);
  }, []);

  const handleNext = useCallback(() => {
    setIndex((state) => state + 1);
  }, []);

  useEffect(() => {
    if (index === 0) {
      document.getElementById('filter_by_inclusion_list')?.scrollIntoView();
    }
  }, [index]);

  useEffect(() => {
    setIndex(isEmpty(ids) ? -1 : 0);
  }, [ids]);

  if (index === -1) {
    return null;
  }

  return (
    <ErrorTooltip
      anchorElId={`list_${ids[index]}`}
      open={index !== -1}
      onClose={handleClose}
      placement="right"
      title="Inaccessible list"
      subtitle="This list is either not visible to you or has been deleted"
      info={`${index + 1} of ${size(ids)}`}
      button={
        index + 1 < size(ids) ? (
          <Button size="sm" onClick={handleNext}>
            Next
          </Button>
        ) : (
          <Button size="sm" onClick={handleClose}>
            Finish
          </Button>
        )
      }
      showArrow
      hideBackdrop
    />
  );
};

export default InExListsFilterErrors;
