import { IconButton } from '@demandscience/ui';
import DownloadIcon from '@demandscience/ui/icons/download';
import { useCallback, useState } from 'react';
import { downloadInvoice } from 'api/organization';
import useSnackbar from 'components/Snackbar/useSnackbar';

interface InvoiceDownloadProps {
  id: string;
}

const InvoiceDownload = ({ id }: InvoiceDownloadProps) => {
  const [progress, setProgress] = useState(false);
  const { showMessage } = useSnackbar();

  const handleClick = useCallback(async () => {
    setProgress(true);
    try {
      await downloadInvoice(id, `invoice-${id}.pdf`);
    } catch (e: any) {
      showMessage('Unexpected error, unable to download invoice.', 'error');
    } finally {
      setProgress(false);
    }
  }, [id, showMessage]);

  return (
    <IconButton
      className="hover:bg-gray-100"
      size="sm"
      theme="default"
      Icon={DownloadIcon}
      onClick={handleClick}
      disabled={progress}
    />
  );
};

export default InvoiceDownload;
