import { useContext } from 'react';
import useQueryAggregate from 'hooks/useQueryAggregate';

import SearchContext, { SearchContextInterface } from './SearchContext';

interface SearchCountProps {
  useTotalCount?: boolean;
}

const useSearchCounts = (options?: SearchCountProps) => {
  const {
    contact: { query: contactQuery },
    company: { query: companyQuery },
  } = useContext(SearchContext) as SearchContextInterface;

  // get the data when both queries finish the fetching
  const {
    data: [contactCount, companyCount],
    isLoading,
    isFetching,
    isError,
    isPreviousData,
  } = useQueryAggregate(
    [contactQuery, companyQuery],
    options?.useTotalCount
      ? (data) => data?.data?.pagination.totalCount || data?.data?.pagination.count
      : 'data.pagination.count',
  );

  return { contactCount, companyCount, isLoading, isFetching, isError, isPreviousData };
};

export default useSearchCounts;
