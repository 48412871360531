import { ListParams } from './../../types/list';
import { fetchUserLists } from 'api/account';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ListsQueryResponse } from 'types/list';
import { useTracking } from 'react-tracking';
import { DataLayerData } from 'types/tracking';
import { isEqual, isNumber } from 'lodash';
import { Show } from 'types';

export const useInfiniteLists = (params: ListParams, options?: any) => {
  const { trackEvent } = useTracking<DataLayerData>({ type: 'event', event: 'user_info' });

  const query = useInfiniteQuery<ListsQueryResponse>(
    ['lists', 'infinite', params],
    ({ pageParam: index = 0 }) => fetchUserLists({ ...params, index }),
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const count = data.pages.at(0)?.count;

        if (isNumber(count) && isEqual(params.filter, { show: Show.All })) {
          trackEvent({
            countLists: count,
          });
        }
      },
      ...options,
    },
  );

  return query;
};
