import { Modal } from '@demandscience/ui';
import { HTMLAttributes } from 'react';
import UploadBounceForm from './UploadBounceForm';

export interface UploadBouncesListOverlayProps extends HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  open: boolean;
}

const UploadBouncesListOverlay = (props: UploadBouncesListOverlayProps) => {
  const { onClose } = props;

  return (
    <Modal
      aria-labelledby="create-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="create-list-dialog-title" className="flex mb-4">
        Upload a list of bounces
      </Modal.Title>
      <UploadBounceForm onCancel={onClose} />
    </Modal>
  );
};

export default UploadBouncesListOverlay;
