import React from 'react';

import { ModelType, SearchCustomization } from 'types';

export interface CustomizationContextInterface extends SearchCustomization {
  onChange: (setting: string, value: any) => void;
  open: boolean;
  setCustomizeColumnsOpen: (type: ModelType) => void;
  setOpen: (open: boolean) => void;
}

const Context = React.createContext<CustomizationContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  Context.displayName = 'SearchCustomizationContext';
}

export default Context;
