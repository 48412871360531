import { useEffect } from 'react';
import qs from 'qs';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import IllustrationAbsurd5 from 'components/Illustrations/IllustrationAbsurd5';
import { Link } from '@demandscience/ui';
import useMutateSubscriptionPlan from 'components/Organization/useMutateSubscriptionPlan';

const Confirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state, id } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const {
    acknowledgeSubscription: { mutate: acknowledgeSubscriptionMutate },
  } = useMutateSubscriptionPlan();

  useEffect(() => {
    if (state === 'succeeded') {
      if (id) {
        acknowledgeSubscriptionMutate({ hosted_page_id: id as string });
      }
    } else {
      navigate('..', { replace: true });
    }
  }, [navigate, state, id, acknowledgeSubscriptionMutate]);

  if (state !== 'succeeded') {
    return null;
  }

  return (
    <div className="py-7 text-center text-gray-500 max-w-sm mx-auto">
      <div className="text-xl text-gray-800 font-semibold mb-2">Success</div>
      <div className="text-base">Your payment has been accepted</div>
      <div className="my-8">
        <IllustrationAbsurd5 />
      </div>
      <div className="text-base mb-4">
        The credits you bought are now being added to your account.
      </div>
      <div className="text-base">
        Want to review your purchases? Simply head over to the{' '}
        <Link className="text-base font-normal" as={RouterLink} to="/manage/billing">
          billing page
        </Link>
        !
      </div>
    </div>
  );
};

export default Confirmation;
