import { CardIntegration } from 'components/Cards';
import SalesforceIcon from '@demandscience/ui/icons/salesforce';
import LinkedinIcon from '@demandscience/ui/icons/LinkedinBrand';
import HubspotIcon from '@demandscience/ui/icons/hubspot';
import MarketoIcon from '@demandscience/ui/icons/marketo';
import useIntegration from 'components/Organization/useIntegrations';
import useLinkedInExtension from 'components/LinkedInExtension/useLinkedInExtension';

const Integrations = () => {
  const { data, refetch } = useIntegration();
  const { extensionId, installed } = useLinkedInExtension();

  return (
    <>
      <div className="space-y-6 max-w-3xl">
        <p className="text-sm text-gray-700 mb-2">
          Leverage DemandScience Intelligence&apos;s integrations to seamlessly sync your contact
          data with our partners. All configured integrations will be available to your entire team.
        </p>
        <section className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <CardIntegration
            icon={<SalesforceIcon className="w-10 h-10" fill="#00A1E0" />}
            title="Salesforce"
            text="Upgrade your sales records with Klarity’s leading company and contact insights."
            data={data}
            integrationType="salesforce"
            refetch={refetch}
          />
          <CardIntegration
            icon={<HubspotIcon className="w-10 h-10" fill="#FF7A59" />}
            title="Hubspot"
            text="Identify your ideal customer profile and enhance your HubSpot database with Klarity."
            data={data}
            integrationType="hubspot"
            refetch={refetch}
          />
          <CardIntegration
            icon={<MarketoIcon className="w-10 h-10" fill="#663399" />}
            title="Marketo"
            text="Build your target lists in Klarity and push details to Marketo for easy follow-up from your Sales team."
            data={data}
            integrationType="marketo"
            refetch={refetch}
          />
        </section>
      </div>

      <div className="space-y-6 max-w-3xl mt-9">
        <div className="text-lg font-medium text-gray-700">Browser extension (Chrome only)</div>
        <section className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <CardIntegration
            icon={<LinkedinIcon className="w-10 h-10" fill="#0A66C2" />}
            title="Linkedin"
            text="Simplify your prospecting. Find cell phone numbers and business emails with ease."
            url={`https://chrome.google.com/webstore/detail/${extensionId}`}
            linkText={installed ? 'View in chrome web store' : 'Install extension'}
            refetch={refetch}
          />
        </section>
      </div>
    </>
  );
};

export default Integrations;
