import { map, range } from 'lodash';
import { Skeleton } from '@demandscience/ui';

interface ListsSkeletonProps {
  count: number;
}

const ListsSkeleton = ({ count }: ListsSkeletonProps) => {
  return (
    <div data-testid="lists-skeleton" className="divide-y">
      {map(range(count), (index) => (
        <div key={index} className="flex justify-between items-center py-3 px-4">
          <Skeleton variant="rectangle" width={64} height={3} className="rounded bg-gray-200" />
          <Skeleton variant="circle" width={8} height={8} className="bg-gray-200" />
          <Skeleton variant="rectangle" width={6} height={3} className="rounded bg-gray-200" />
          <Skeleton variant="rectangle" width={56} height={3} className="rounded bg-gray-200" />
          <div className="flex items-center space-x-2">
            <Skeleton variant="rectangle" width={6} height={3} className="rounded bg-gray-200" />
            <Skeleton variant="rectangle" width={12} height={3} className="rounded bg-gray-200" />
          </div>
          <Skeleton variant="rectangle" width={2} height={6} className="rounded bg-gray-200" />
        </div>
      ))}
    </div>
  );
};

export default ListsSkeleton;
