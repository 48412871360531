import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  outlined?: boolean;
}

const Wrapper = ({ className, open, outlined, ...props }: WrapperProps) => (
  <div
    className={overrideTailwindClasses(
      classNames('flex flex-col bg-gray-50 hover:bg-white', className, {
        'bg-white': open,
        'outline outline-1 outline-blue-500 border-white': open && outlined,
      }),
    )}
    {...props}
  />
);

export default Wrapper;
