import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { TextArea } from '@demandscience/ui';

import { size, get } from 'lodash';

interface MessageInputControllerProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'> {
  label?: string;
  maxLength?: number;
  placeholder?: string;
}

const MessageInputController = <T extends FieldValues>({
  label = 'Message',
  placeholder = 'Optional message',
  maxLength = 500,
  ...props
}: MessageInputControllerProps<T>) => (
  <div className="isolate">
    <Controller
      {...props}
      render={({ field, formState }) => {
        const { name } = props;
        const error = get(formState, `errors.${name}`);

        return (
          <TextArea
            label={label}
            placeholder={placeholder}
            error={!!error}
            trailingLabel={`${size(field.value)}/${maxLength} characters`}
            helperText={
              field?.value?.length >= maxLength
                ? `Message length upto ${maxLength} characters only.`
                : ''
            }
            helperTextClassName={error?.type === 'maxLength' ? 'text-error-500 ml-1' : ''}
            {...field}
          />
        );
      }}
      rules={{
        maxLength: {
          value: maxLength,
          message: `${maxLength} characters max`,
        },
      }}
    />
  </div>
);

export default MessageInputController;
