import React from 'react';
import { noop } from 'lodash';
import { CompareSearch } from 'types/compare';
import { COMPARE_SEARCH_LIST_INITIAL_STATE } from './CompareProvider';
import { Filters, RecentSearch, SavedSearch } from 'types';

export interface CompareContextProps {
  addSearchToCompareList: (search: SavedSearch | RecentSearch, filters: Filters) => void;
  clearCompareList: () => void;
  compare: boolean;
  compareDrawerOpen: boolean;
  compareSearchList: CompareSearch[];
  handleBlankSearch: (filters: Filters) => void;
  handleDuplicateSearch: (filters: Filters) => void;

  searchHasFilters: (search: CompareSearch) => boolean;
  setCompare: (e: boolean) => void;
  setCompareDrawerOpen: (e: boolean) => void;
  setCompareSearchList: React.Dispatch<React.SetStateAction<CompareSearch[]>>;
  setSearchNumber: (e: number) => void;
}

const CompareContext = React.createContext<CompareContextProps>({
  clearCompareList: noop,
  compareDrawerOpen: false,
  setCompareDrawerOpen: noop,
  compare: false,
  setCompare: noop,
  compareSearchList: COMPARE_SEARCH_LIST_INITIAL_STATE,
  addSearchToCompareList: noop,
  setCompareSearchList: () => COMPARE_SEARCH_LIST_INITIAL_STATE,
  handleBlankSearch: noop,
  handleDuplicateSearch: noop,
  setSearchNumber: noop,
  searchHasFilters: () => false,
});

export default CompareContext;
