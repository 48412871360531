import { Alert, AlertProps } from '@demandscience/ui';

export interface AuthAlertProps extends AlertProps {
  action?: string;
  actionProps?: Record<string, any>;
  message: string;
}

const AuthAlert: React.FC<AuthAlertProps> = ({ message, action, actionProps, ...props }) => (
  <Alert severity="error" {...props}>
    <Alert.Header>
      <Alert.SubTitle>{message}</Alert.SubTitle>
      {action && <Alert.ActionButton {...actionProps}>{action}</Alert.ActionButton>}
    </Alert.Header>
  </Alert>
);

export default AuthAlert;
