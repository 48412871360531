import { ReactNode, useCallback } from 'react';
import BaseSwitch, { TabSwitchProps } from './TabSwitch';

interface SwitchMenuItemProps extends Omit<TabSwitchProps, 'value' | 'onChange'> {
  children?: ReactNode;
  onChange: (value: boolean) => void;
  value: boolean;
}

const SwitchMenuItem = ({ value, onChange, children, ...props }: SwitchMenuItemProps) => {
  const handleChange = useCallback(
    (index: number) => {
      onChange(index === 0);
    },
    [onChange],
  );

  return (
    <div className="flex flex-row items-center justify-between gap-x-4 gap-y-2 flex-wrap px-4 py-2 select-none sticky top-0 bg-white z-[2]">
      <BaseSwitch value={value ? 0 : 1} onChange={handleChange} {...props} />
      <div className="ml-auto">{children}</div>
    </div>
  );
};

export default SwitchMenuItem;
