import map from 'lodash/map';
import find from 'lodash/find';
import useMembers from './useMembers';
import debounce from 'lodash/debounce';
import { forwardRef, Ref, useCallback, useMemo, useState } from 'react';
import { Combobox, ComboboxProps, Link } from '@demandscience/ui';
import { SelectOption } from '@demandscience/ui/src/types';
import InviteOverlay from './InviteOverlay';
import useModal from 'hooks/useModal';
import useAuth from 'components/Auth/useAuth';

interface MembersSelectProps extends Omit<ComboboxProps, 'options'> {
  hideInvite?: true;
  onUsersChange?: (users: any) => void;
}

const MembersSelect = forwardRef(
  (
    { placeholder, value = [], hideInvite, ...props }: MembersSelectProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { open, openModal, closeModal } = useModal();
    const { isManager } = useAuth();
    const [inputValue, setInputValue] = useState('');
    const { data, isFetching, isError } = useMembers(
      { filter: { freetext: inputValue } },
      // on initial load we want to see the list of users instead of "No results found"
      { enabled: true },
    );
    const { members } = data || {};

    // this is required because of Combobox is using comparison by reference
    const options = useMemo(
      () =>
        map(
          members,
          ({ username, name, email }) =>
            find(value as SelectOption[], { value: username }) || {
              value: username,
              label: name || email,
            },
        ),
      [members, value],
    );

    const debuncedInput = useMemo(
      () =>
        debounce((value: string) => {
          setInputValue(value);
        }, 150),
      [],
    );

    const handleInputChange = useCallback(
      (newInput: string) => {
        debuncedInput(newInput);
      },
      [debuncedInput],
    );

    return (
      <>
        <Combobox
          {...props}
          ref={ref}
          multiple
          value={value}
          options={options}
          placeholder={placeholder}
          loading={isFetching}
          error={isError ? 'Error fetching users' : props.error}
          onInputChange={handleInputChange}
          optionsHeader={
            isManager && !hideInvite ? (
              <div className="px-4 py-2">
                <Link onClick={openModal}>Invite new team member</Link>
              </div>
            ) : null
          }
        />
        <InviteOverlay open={open} onClose={closeModal} />
      </>
    );
  },
);

MembersSelect.displayName = 'MembersSelect';

export default MembersSelect;
