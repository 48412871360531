import { Checkbox } from '@demandscience/ui';
import { stopPropagation } from 'utils/event';

interface PrivacyAndTermsProps {
  onChange: (value: boolean) => void;
  value: boolean;
}

const PrivacyAndTerms = ({ value, onChange }: PrivacyAndTermsProps) => (
  <Checkbox
    checked={value}
    onChange={onChange}
    label={
      <>
        I agree to{' '}
        <a
          className="text-primary-500 hover:underline"
          href="https://demandscience.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={stopPropagation}
        >
          Klarity&apos;s Privacy Policy
        </a>{' '}
        and{' '}
        <a
          className="text-primary-500 hover:underline"
          href="https://demandscience.com/terms-of-use/klarity"
          target="_blank"
          rel="noopener noreferrer"
          onClick={stopPropagation}
        >
          Terms of Use
        </a>
        .
      </>
    }
  />
);

export default PrivacyAndTerms;
