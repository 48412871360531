import { Progress, Spinner, CheckboxIcon } from '@demandscience/ui';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { Aggregate as AggregateType, AggregateFilter } from 'types';
import DividerMenuItem from 'components/Filter/Dropdown/DividerMenuItem';
import { useNavigate } from 'react-router-dom';

interface AggregateProps<F extends AggregateFilter> {
  Component?: React.ElementType;
  aggregate: {
    count: number;
    values: AggregateType<F>[];
  };
  icon: React.ElementType;
  id: string;
  label: string;
  loading?: boolean;
  name: string;
  value?: F[];
}

const BookmarkAggregate = <F extends AggregateFilter>({
  label,
  icon,
  aggregate,
  loading,
  name,
  id,
  Component = 'div',
}: AggregateProps<F>) => {
  const navigate = useNavigate();
  const Icon = icon;
  const openModule = () => {
    navigate(`/search/bookmarkModule`, { state: { id } });
  };

  return (
    <Component className="flex flex-col rounded border" onClick={openModule}>
      <div className="p-4 min-h-[310px]">
        <div className="text-sm text-gray-700 font-medium">{name ? name : 'Bookmark'}</div>
        <DividerMenuItem className="p-1" />

        <div className="flex flex-row items-center py-[14px]">
          <div className="pl-[13px] pr-3">
            <Icon className="w-5 h-5 text-primary-500" />
          </div>
          <div className="grow">
            <div className="text-sm text-gray-700 font-medium">{label}</div>
          </div>
          {loading && <Spinner theme="primary" size="sm" />}
        </div>
        <div className="h-[230px] overflow-y-auto">
          {isEmpty(aggregate.values) && (
            <p className="text-sm text-slate-500">No data to display</p>
          )}
          {map(aggregate.values, ({ value, count }) => {
            return (
              <div
                key={value}
                className={`flex flex-row items-center gap-3 w-full p-2 transition hover:bg-gray-100 outline-none text-left`}
              >
                <div className="flex-shrink-0 p-[5px]">
                  <CheckboxIcon size={20} theme="primary" checked={false} />
                </div>
                <div className="w-full mr-1">
                  <div className="flex justify-between text-xs">
                    <span className="text-gray-800">{value}</span>
                    <span className="text-gray-500">{count.toLocaleString()}</span>
                  </div>
                  <Progress
                    value={Math.floor((count / aggregate.count) * 100)}
                    size="sm"
                    variant="solid"
                    className="mt-1"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Component>
  );
};

export default BookmarkAggregate;
