import { Clock, Search, MessageSquare } from '@demandscience/ui/icons';
import FilterInfo from 'components/Filter/FilterInfo';
import { AutomaticUpdates, DateSelector, DropdownBox } from './BookmarkHistory';
import { ApiResponse } from 'types/BookMark';
import { AutoUpdate, Visibility } from 'types';
import useMutateBookMark from '../useMutateBookMark';
import CommentComponent from './BookmarkComment';
import { Comment } from 'types/BookMark';
import CommentForm from './AddBookmarkComment';

const BookmarkSidebar = ({
  filters,
  categories,
  setClickedLabelsIndex,
  comments,
  data,
  includeNow,
  setincludeNow,
  automated_updates,
  id,
}: {
  automated_updates: AutoUpdate;
  categories: string[];
  comments: Comment[];
  data: ApiResponse;
  filters: any;
  id: string;
  includeNow: boolean;
  setClickedLabelsIndex: (value: number[]) => void;
  setincludeNow: (value: boolean) => void;
}) => {
  const { update } = useMutateBookMark();

  if (!data || !filters || !categories) {
    return null;
  }
  const created_at = categories[0];
  const updated_at = categories.slice(1);
  const filterKeys = Object.keys(filters?.filters);

  const payload: {
    automated_updates?: AutoUpdate;
    id: string;
    name: string;
    share_with?: string[];
    visibility: Visibility;
  } = {
    name: data.name,
    visibility: data.visibility as Visibility,
    id: data.id,
  };
  const onOptionChange = async (option: AutoUpdate) => {
    if (data.visibility === Visibility.Restricted && data.share_with) {
      payload.share_with = data.share_with;
    }
    payload.automated_updates = option;

    await update.mutateAsync({ ...payload });
  };

  return (
    <div className="w-full max-w-[413px] pt-[0]">
      <DropdownBox icon={Clock} title="Updates" count={updated_at?.length}>
        <DateSelector
          created_at={created_at}
          updated_at={updated_at}
          setClickedLabelsIndex={setClickedLabelsIndex}
          data={data}
          includeNow={includeNow}
          setIncludeNow={setincludeNow}
        />
        <AutomaticUpdates setAutoUpdate={onOptionChange} autoUpdate={automated_updates} />
      </DropdownBox>
      <DropdownBox icon={Search} title="Filters" count={filterKeys?.length}>
        <FilterInfo data={filters?.filters}></FilterInfo>
      </DropdownBox>
      <DropdownBox
        icon={MessageSquare}
        title="Comments"
        count={comments && comments.length}
        read={comments && comments?.some((comment) => !comment.read)}
      >
        <CommentComponent comments={comments} />
        <CommentForm id={id} />
      </DropdownBox>
    </div>
  );
};

export default BookmarkSidebar;
