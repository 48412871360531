import { DropdownMenu, Link, Tooltip } from '@demandscience/ui';
import HelpCircleIcon from '@demandscience/ui/icons/help-circle';
import SettingsIcon from '@demandscience/ui/icons/settings';
import ZapIcon from '@demandscience/ui/icons/zap';
import LogOutIcon from '@demandscience/ui/icons/log-out';

import Avatar from 'components/Avatar';
import AvatarWithName from 'components/Account/AvatarWithName';
import CreditsControl from 'components/Credits/CreditsControl';
import CreditsProvider from 'components/Credits/CreditsProvider';
import CreditsUsageInfo from 'components/Credits/CreditsUsageInfo';
import { Link as RouterLink } from 'react-router-dom';
import Signout from 'components/Auth/Signout';
import useAuth from 'components/Auth/useAuth';
import useSnackbar from 'components/Snackbar/useSnackbar';
import ConsentManagerControl from 'components/ConsentManager/ConsentManagerControl';
import { useCallback } from 'react';

const AccountButton = ({ id }: { id?: string }) => {
  const { showMessage } = useSnackbar();
  const { user } = useAuth();

  const handleError = useCallback(
    (message: string) => {
      showMessage(message, 'error');
    },
    [showMessage],
  );

  if (!user) {
    return null;
  }

  return (
    <CreditsProvider>
      <DropdownMenu
        id={id}
        origin="top-left"
        buttonType="custom"
        customButton={
          <Tooltip title="User menu" position="right" showArrow>
            <Avatar
              className="cursor-pointer transition ring-gray-500 focus-within:ring group-focus:ring hover:bg-gray-300"
              theme="white"
              size="md"
              tabIndex={0}
              user={user}
            />
          </Tooltip>
        }
        dropdownClassName="max-h-max max-w-xs z-20"
      >
        <div className="px-4 pt-4 pb-2 min-w-[280px]">
          <AvatarWithName className="mb-5" user={user} />
          <CreditsUsageInfo bulkCredits={user.bulk_credits} />
          <CreditsControl className="mt-4" size="xs" outline />
        </div>
        <div className="my-2 border-b" />
        <DropdownMenu.Item as={RouterLink} to="/settings/profile">
          <div className="flex items-center">
            <SettingsIcon className="h-4 w-4 mr-4" /> Settings
          </div>
        </DropdownMenu.Item>
        <DropdownMenu.Item as={RouterLink} to="/settings/integrations">
          <div className="flex items-center">
            <ZapIcon className="h-4 w-4 mr-4" /> Integrations
          </div>
        </DropdownMenu.Item>
        <div className="my-2 border-b" />
        <DropdownMenu.Item as="a" href="https://leadiro.zendesk.com/hc/en-us" target="_blank">
          <div className="flex items-center">
            <HelpCircleIcon className="h-4 w-4 mr-4" /> Help
          </div>
        </DropdownMenu.Item>
        <Signout component={DropdownMenu.Item} onError={handleError}>
          <div className="flex items-center">
            <LogOutIcon className="h-4 w-4 mr-4" /> Sign out
          </div>
        </Signout>
        <div className="my-2 border-b" />
        <div className="flex items-end justify-end gap-4 pt-4 pb-2 px-4">
          <ConsentManagerControl as={Link} className="text-gray-500 text-xs">
            Cookie Settings
          </ConsentManagerControl>
          <Link
            className="text-gray-500 text-xs"
            href="https://demandscience.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </Link>
          <Link
            className="text-gray-500 text-xs"
            href="https://demandscience.com/terms-of-use/klarity"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </Link>
        </div>
      </DropdownMenu>
    </CreditsProvider>
  );
};

export default AccountButton;
