import { useCallback, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, Button } from '@demandscience/ui';

import classNames from 'classnames';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { overrideTailwindClasses } from 'tailwind-override';
import useMutateAccount from './useMutateAccount';
import { UpdatePasswordPayload } from 'types';
import PasswordField from '../Auth/PasswordField';
import { AxiosError } from 'axios';
import TextTooltip from 'components/Tooltip/TextTooltip';

type PasswordChangeFormValues = {
  passwordConfirmation: string;
} & UpdatePasswordPayload;

interface PasswordChangeEditorProps {
  impersonated?: true;
}

const PasswordChangeEditor = ({ impersonated }: PasswordChangeEditorProps) => {
  const { control, handleSubmit, reset, formState, getValues, setError } = useForm({
    defaultValues: { previous_password: '', proposed_password: '', passwordConfirmation: '' },
  });
  const { updatePassword } = useMutateAccount();
  const { showMessage } = useSnackbar();
  const { isSubmitting, errors } = formState;

  const [update, setUpdate] = useState(false);

  const handleUpdateClick = useCallback(() => {
    reset();
    setUpdate((state) => !state);
  }, [reset]);

  const handleClose = useCallback(() => {
    reset();
    setUpdate((state) => !state);
  }, [reset]);

  const onSubmit: SubmitHandler<PasswordChangeFormValues> = async (data) => {
    const { passwordConfirmation, ...password } = data;
    try {
      await updatePassword.mutateAsync(password);

      handleClose();

      showMessage('Password was successfully changed', 'success');
    } catch (e: any) {
      if (e instanceof AxiosError) {
        let { error, error_code } = e.response?.data || {};

        if (error_code) {
          if (error_code === 'NotAuthorizedException') {
            setError('previous_password', { type: 'api', message: 'Incorrect password' });
          } else if (error_code === 'InvalidPasswordException') {
            setError('proposed_password', {
              type: 'api',
              message: error,
            });
          } else {
            showMessage(error, 'error');
          }
        } else {
          showMessage('Unable to update a password', 'error');
        }
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  };

  return (
    <div className={`border-b ${update ? ' pb-6' : ''}`}>
      <div
        className={overrideTailwindClasses(
          classNames('flex flex-row justify-between items-center mb-1'),
        )}
      >
        <p className="text-base font-medium text-gray-700">Password</p>
        <TextTooltip
          title={impersonated ? 'Password change is not possible in impersonate mode' : undefined}
          placement="top"
          showArrow
        >
          <Button
            className="whitespace-nowrap"
            theme="primary"
            size="md"
            borderless
            type="button"
            onClick={handleUpdateClick}
            disabled={impersonated}
          >
            {update ? 'Cancel' : 'Update'}
          </Button>
        </TextTooltip>
      </div>
      <p className={'text-base font-normal text-gray-500 pb-6'}>Set a new password</p>
      {update && (
        <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="previous_password"
            control={control}
            render={({ field }) => (
              <PasswordField
                label="Current password"
                variant="outlined"
                type="password"
                autoComplete="current-password"
                error={errors.previous_password?.message}
                {...field}
              />
            )}
            rules={{ required: 'Current password is required' }}
          />

          <Controller
            name="proposed_password"
            control={control}
            render={({ field }) => (
              <PasswordField
                label="New password"
                variant="outlined"
                autoComplete="new-password"
                error={errors.proposed_password?.message}
                {...field}
              />
            )}
            rules={{
              required: 'New password is required',
              minLength: { value: 8, message: 'Minimum length is 8 characters' },
            }}
          />
          <Controller
            name="passwordConfirmation"
            control={control}
            render={({ field }) => (
              <TextField
                autoComplete="new-password"
                label="Confirm new password"
                type="password"
                variant="outlined"
                error={errors.passwordConfirmation?.message}
                {...field}
              />
            )}
            rules={{
              required: 'Please confirm password',
              validate: {
                matchesPreviousPassword: (value) => {
                  const { proposed_password } = getValues();

                  return proposed_password === value || 'Passwords should match!';
                },
              },
            }}
          />

          <div className="space-x-3">
            <Button theme="primary" type="submit" disabled={isSubmitting || !formState.isDirty}>
              Update password
            </Button>
            <Button theme="primary" type="button" outline onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default PasswordChangeEditor;
