import { useQuery } from '@tanstack/react-query';
import { fetchRecentSearches } from 'api/recentSearch';

const useRecentSearches = (options?: Record<string, any>) => {
  const query = useQuery(['recentSearches'], fetchRecentSearches, {
    refetchOnWindowFocus: true,
    staleTime: 60 * 1000, // 1 minute
    ...options,
  });

  return query;
};

export default useRecentSearches;
