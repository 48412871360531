import api from 'api';
import axios from 'axios';
import {
  ListRecord,
  CreateListPayload,
  DeleteListPayload,
  UpdateListPayload,
  ShowListRecordsPayload,
  UpdateListContentPayload,
  ListRecordsQueryResponse,
  UploadListResponse,
  UploadListPayload,
  SignedUrlParams,
  SignedUrlResponse,
  SetGlobalExclusionPayload,
  CreateListResponse,
  UploadListValidationPayload,
} from 'types';

export const createList = (payload: CreateListPayload): Promise<CreateListResponse> =>
  api.post('/list', payload);

export const updateList = ({ id, ...data }: UpdateListPayload): Promise<void> =>
  api.patch(`/list/${id}`, data);

export const deleteList = ({ id, ...data }: DeleteListPayload): Promise<void> =>
  api.delete(`/list/${id}`, { data });

export const showList = (id: string): Promise<ListRecord> => api.get(`/list/${id}`);

export const setGlobalExclusion = ({ id, flag }: SetGlobalExclusionPayload): Promise<void> =>
  api.patch(`/list/${id}/set_global_exclusion/${flag}`);

export const showListRecords = ({
  id,
  params,
}: ShowListRecordsPayload): Promise<ListRecordsQueryResponse> =>
  api.post(`/list/${id}/records`, { ...params });

export const updateListContent = ({
  source_list_id,
  ...data
}: UpdateListContentPayload): Promise<void> =>
  api.patch(`/list/${source_list_id}/records`, { ...data });

const getSignedUrl = (params: SignedUrlParams): Promise<SignedUrlResponse> =>
  api.get('/list/upload', { params });

const validate = (payload: UploadListValidationPayload): Promise<void> =>
  api.post('/list/upload/validate', payload);

export const uploadListFile = async ({
  file,
  records_type,
  onUploadProgress,
  controller,
}: UploadListPayload): Promise<UploadListResponse> => {
  const file_name = file.name;
  const params = { objectName: file_name, records_type };
  // upload consists of the two calls, one to get signed url, and one to upload the file
  const { signed_url, list_id } = await getSignedUrl(params);

  // upload the file to signed url
  await axios.put(signed_url, file, {
    onUploadProgress,
    signal: controller?.signal,
  });

  // validation of uploaded file is additional call
  const payload = { list_id, ...params };
  await validate(payload);

  return { list_id, file_name };
};
