import { DropdownMenu, IconButton } from '@demandscience/ui';
import {
  Company,
  CompanyFilterName,
  ExportType,
  KlarityFeature,
  Model,
  ModelType,
  ResultsView,
} from 'types';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';
import ActionMenuItems from 'components/ListRecords/ActionMenuItems';
import ExportContext, { ExportContextInterface } from 'components/Export/ExportContext';
import ExportMenuItems from 'components/Export/ExportMenuItems';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

interface DataGridRecordActionsProps {
  displayContactDetailsOnTop?: boolean;
  record: Model;
}

export const DataGridRecordActions = ({
  displayContactDetailsOnTop,
  record,
}: DataGridRecordActionsProps) => {
  const { featureStatus, requestFeatures } = useFeatureFlag(KlarityFeature.Contacts);

  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const iscontactRendered: boolean = featureStatus || isContactRenderDisabled;

  const { exportTo, modelType } = useContext(ExportContext) as ExportContextInterface;
  const navigate = useNavigate();
  const exportData = useMemo(() => ({ dsid: [record.dsid] }), [record.dsid]);

  const handleExportClick = useCallback(
    (type: ExportType) => () => {
      if (modelType === ModelType.Contact && featureStatus) {
        requestFeatures();
        return;
      }

      exportTo(type, exportData);
    },
    [modelType, featureStatus, exportTo, exportData, requestFeatures],
  );

  const handleViewEmployees = useCallback(() => {
    const { dsid, company_name } = record as Company;
    const filters = {
      [CompanyFilterName.CompanyName]: [{ dsid, name: company_name }],
    };

    navigate('/search', { state: { filters, tab: ResultsView.Contacts, contactsOf: record } });
  }, [navigate, record]);

  return (
    <DropdownMenu
      buttonType="custom"
      customButton={
        <IconButton
          className="hover:bg-gray-100"
          size="sm"
          theme="default"
          Icon={MoreVerticalIcon}
        />
      }
      origin={displayContactDetailsOnTop ? 'top-right' : 'bottom-right'}
    >
      <ActionMenuItems exportData={exportData} />
      {iscontactRendered ||
        (record && 'company_name' in record && (
          <DropdownMenu.Item as="div" onClick={handleViewEmployees}>
            View employees
          </DropdownMenu.Item>
        ))}
      <ExportMenuItems
        onClick={handleExportClick}
        hideReveal={'company_name' in record || record.revealed}
      />
    </DropdownMenu>
  );
};
