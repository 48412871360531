import { useState, useEffect, useCallback } from 'react';
import { useTracking } from 'react-tracking';

import { Auth, SignUpParams } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import * as authApi from 'api/auth';

import EmailNotConfirmedException from './EmailNotConfirmedException';
import ChallengeException from './ChallengeException';
import { Account, CognitoUser } from 'types';

// Amplify.Logger.LOG_LEVEL = 'DEBUG';

const useCognitoAuth = (config: Record<string, any>) => {
  const { trackEvent } = useTracking<{ type: string; user?: Account }>({ type: 'auth' });
  const [{ loggedin, cognitoUser }, setUserData] = useState<{
    cognitoUser?: CognitoUser;
    loggedin: boolean | null;
  }>({ loggedin: null });

  const loadApiUser = useCallback(
    async (cognitoUser: CognitoUser, skipChecks?: true) => {
      if (!skipChecks) {
        if (cognitoUser.attributes?.email_verified === false) {
          throw new EmailNotConfirmedException(cognitoUser.attributes.email);
        }

        if (cognitoUser.challengeName) {
          // need to store cognito user so it can be used in e.g. completeNewPassword
          setUserData((state) => ({ ...state, cognitoUser }));

          throw new ChallengeException(cognitoUser.challengeName, cognitoUser.challengeParam);
        }
      }

      const user = await authApi.login();
      trackEvent({ user });

      setUserData({ loggedin: true, cognitoUser });

      return user;
    },
    [trackEvent],
  );

  const signIn = useCallback(
    async (username: string, password: string) => {
      const cognitoUser = await Auth.signIn(username, password);

      const user = await loadApiUser(cognitoUser);

      return user;
    },
    [loadApiUser],
  );

  const signOut = useCallback(
    async (global = false) => {
      await Auth.signOut({ global });

      setUserData({ loggedin: false });

      trackEvent({ user: undefined });
    },
    [trackEvent],
  );

  const signUp = (payload: SignUpParams) => Auth.signUp(payload);

  const changePassword = (oldPassword: string, newPassword: string) =>
    Auth.changePassword(cognitoUser, oldPassword, newPassword);

  const confirmSignUp = (username: string, code: string) => Auth.confirmSignUp(username, code);

  const resendSignUp = (username: string) => Auth.resendSignUp(username);

  const forgotPassword = (username: string) => Auth.forgotPassword(username);

  const forgotPasswordSubmit = (username: string, code: string, password: string) =>
    Auth.forgotPasswordSubmit(username, code, password);

  const completeNewPassword = async (password: string, attributes: any) => {
    const newCognitoUser = await Auth.completeNewPassword(cognitoUser, password, attributes);

    const user = await loadApiUser(newCognitoUser);

    return user;
  };

  const userAttributes = () => Auth.userAttributes(cognitoUser);

  const updateUserAttributes = (attributes: any) =>
    Auth.updateUserAttributes(cognitoUser, attributes);

  const verifyCurrentUserAttribute = (attribute: string) =>
    Auth.verifyCurrentUserAttribute(attribute);

  const verifyCurrentUserAttributeSubmit = async (attribute: string, code: string) => {
    await Auth.verifyCurrentUserAttributeSubmit(attribute, code);

    // force signout current user after email verification
    // because current user keeps old info about unverified email
    if (attribute === 'email') {
      await signOut();
    }
  };

  const impersonate = useCallback(
    async (targetUsername: string, username: string, token: string) => {
      const cognitoUser = await Auth.signIn(username);

      await Auth.sendCustomChallengeAnswer(cognitoUser, token, {
        user_to_impersonate: targetUsername,
      });

      const user = await loadApiUser(cognitoUser, true);

      return user;
    },
    [loadApiUser],
  );

  useEffect(() => {
    const handleConfigured = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();

        // there's a difference handling regular users and admins (having jumpcloud prefix on username)
        await loadApiUser(cognitoUser, cognitoUser.username?.startsWith('jumpcloud'));
      } catch (e) {
        setUserData({ loggedin: false });
      }
    };

    const listener = (e: any) => {
      const {
        payload: { event },
      } = e;

      switch (event) {
        case 'signIn':
          // ignore signin, cognito user will be set above inside signin handler
          break;
        case 'signUp':
          // nothing to do after signUp as user must sign in after account validation
          break;
        case 'signOut':
          // ignore signout, cognito user will be reset above inside signout handler
          break;
        case 'signIn_failure':
          // user account is disabled on Cognito platform and user tries to sign in
          setUserData({ loggedin: false });
          break;
        case 'tokenRefresh':
          break;
        case 'tokenRefresh_failure':
          // refresh token expired due to expiration period or if invalidated by global singout
          setUserData({ loggedin: false });
          break;
        case 'cognitoHostedUI':
          // nothing to do as 'configured' will be called and set current user
          break;
        case 'configured':
          handleConfigured();
          break;
        default:
          break;
      }
    };

    Hub.listen('auth', listener);

    return () => {
      Hub.remove('auth', listener);
    };
  }, [loadApiUser]);

  useEffect(() => {
    Auth.configure(config);
  }, [config]);

  return {
    loggedin,
    signIn,
    signOut,
    signUp,
    confirmSignUp,
    resendSignUp,
    forgotPassword,
    forgotPasswordSubmit,
    completeNewPassword,
    changePassword,
    userAttributes,
    updateUserAttributes,
    verifyCurrentUserAttribute,
    verifyCurrentUserAttributeSubmit,
    impersonate,
  };
};

export default useCognitoAuth;
