import api from 'api';

import {
  SavedSearch,
  SavedSearchParams,
  SavedSearchesResponse,
  CreateSavedSearchPayload,
  CreateSavedSearchResponse,
  UpdateSavedSearchPayload,
  DeleteSavedSearchPayload,
} from 'types';

export const fetchSavedSearches = (params: SavedSearchParams): Promise<SavedSearchesResponse> =>
  api.post('/account/me/saved_search', params);

export const createSavedSearch = (
  payload: CreateSavedSearchPayload,
): Promise<CreateSavedSearchResponse> => api.post('/saved_search', payload);

export const fetchSavedSearch = (id: string): Promise<SavedSearch> =>
  api.get(`/saved_search/${id}`);

export const updateSavedSearch = ({ id, ...data }: UpdateSavedSearchPayload): Promise<void> =>
  api.patch(`/saved_search/${id}`, data);

export const deleteSavedSearch = ({ id, ...data }: DeleteSavedSearchPayload): Promise<void> =>
  api.delete(`/saved_search/${id}`, { data });
