import useSnackbar from 'components/Snackbar/useSnackbar';
import { Modal, ModalProps } from '@demandscience/ui';
import { useCallback } from 'react';
import RequestFeaturesForm from './RequestFeaturesForm';

interface RequestFeaturesOverlayProps extends Omit<ModalProps, 'children'> {}

const RequestFeaturesOverlay = (props: RequestFeaturesOverlayProps) => {
  const { showMessage } = useSnackbar();
  const { onClose } = props;

  const handleSuccess = useCallback(() => {
    onClose();

    showMessage('Your team manager has been notified of your request for credits', 'success', {
      // @ts-expect-error demandscience-ui
      title: 'Credits request sent',
    });
  }, [onClose, showMessage]);

  return (
    <Modal
      aria-labelledby="request-credits-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="request-credits-dialog-title" className="flex mb-9">
        Enable more features
      </Modal.Title>
      <RequestFeaturesForm onSuccess={handleSuccess} onCancel={onClose} />
    </Modal>
  );
};

export default RequestFeaturesOverlay;
