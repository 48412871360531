import classNames from 'classnames';
import { ReactNode } from 'react';
import HeadingElement from 'components/Typography/Heading';

type HeadingProps = {
  children?: ReactNode;
  element?: ReactNode;
};

const Heading = ({ element, children }: HeadingProps) => (
  <div
    className={classNames([
      'w-full h-[88px] flex flex-row items-center',
      element || children ? 'justify-between' : 'justify-end',
    ])}
  >
    {element}
    {children && <HeadingElement>{children}</HeadingElement>}
  </div>
);

export default Heading;
