import { useQuery } from '@tanstack/react-query';

import { fetchMembers } from 'api/organization';

import { FetchMembersParams } from 'types';

// this will list only active users
const useMembers = (params: FetchMembersParams, options: Record<string, any>) => {
  const query = useQuery(['members', params], () => fetchMembers(params), {
    staleTime: 60 * 1000, // 1 minute
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export default useMembers;
