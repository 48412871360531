import { Company } from 'types';
import { Link } from '@demandscience/ui';
import GlobeIcon from '@demandscience/ui/icons/globe';
import ListItemText from 'components/Layout/ListItemText';
import { isEmpty, map } from 'lodash';

interface WebsiteProps {
  row: Company;
}

const Website: React.FC<WebsiteProps> = ({ row }) => {
  const { main_domains } = row;

  if (isEmpty(main_domains)) {
    return null;
  }

  return (
    <ListItemText
      leadingContent={<GlobeIcon className="w-5 h-5" />}
      title={
        <div className="flex flex-col items-start">
          {map(main_domains, (domain) => (
            <Link
              key={domain}
              className="font-normal"
              href={`https://${domain}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {domain}
            </Link>
          ))}
        </div>
      }
    />
  );
};

export default Website;
