import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';

import Search from 'components/Input/Search';

import { Visibility, VisibilityBookmarks } from 'types';
import Dropdown from 'components/Filter/Dropdown';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';

import { map } from 'lodash';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import CheckboxMenuItem from 'components/Filter/Dropdown/CheckboxMenuItem';
import DividerMenuItem from 'components/Filter/Dropdown/DividerMenuItem';
import XIcon from '@demandscience/ui/icons/x';

const ownership = ['Owned by me', 'Shared with me'];

const labels: Record<string, string> = {
  'Technology Install': 'install_topic',
  Department: 'job_function',
  'Job title': 'job_title',
};

interface ListsFiltersProps {
  onChange: (key: string, value: any) => void;
  setName: (name: string) => void;
  setShow: (show: VisibilityBookmarks | string[]) => void;
}

const ListsFilters = ({ onChange, setShow, setName }: ListsFiltersProps) => {
  const [selectValue, setSelectValue] = useState<string[]>(['All bookmarks']);
  const [searchvalue, setSearchValue] = useState<string>('');

  const handleSearchChange = useCallback(
    (name?: string) => {
      setSearchValue(name || '');
      setName(name || '');
    },
    [setName],
  );
  const labelOptions = Object.keys(labels);
  const handleSelectChange = useCallback(
    (newValue: any) => {
      const lastclicked = newValue[newValue.length - 1];
      if (Object.values(ownership).includes(lastclicked) || lastclicked === 'All bookmarks') {
        setSelectValue([lastclicked]);
        setShow(
          lastclicked === 'Owned by me'
            ? VisibilityBookmarks['Owned by me']
            : lastclicked === 'Shared with me'
            ? VisibilityBookmarks['Shared with me']
            : VisibilityBookmarks['All bookmarks'],
        );
      } else if (Object.keys(labels).includes(lastclicked)) {
        setSelectValue((selectValue) => [selectValue[0], lastclicked]);
        setShow([labels[lastclicked]]);
      }
    },
    [setShow],
  );

  const handleClearAll = useCallback(() => {
    setSelectValue(['All bookmarks']);
    onChange('show', Visibility.Public);
  }, [onChange]);
  return (
    <>
      <div className="md:w-64">
        <Search value={searchvalue} onChange={handleSearchChange} placeholder="Find a bookmark" />
      </div>
      <Dropdown
        menuClassName="max-h-80"
        value={selectValue}
        onChange={handleSelectChange}
        multiple
        button={({ open, value }) => (
          <button className="px-4 py-2 text-sm text-gray-700 text-left w-full md:w-52 flex flex-row items-center gap-1 border-none rounded ring-inset ring-1 ring-gray-300 hover:ring-gray-700 focus:ring-primary-500 focus:hover:ring-primary-500 focus:outline-none">
            <div className="grow ellipsis">{value}</div>
            {!value.includes('All bookmarks') && (
              <XIcon size={18} className="flex-shrink-0 text-gray-500" onClick={handleClearAll} />
            )}
            <ChevronDownIcon
              size={18}
              className={classNames('flex-shrink-0', { 'rotate-180': open })}
            />
          </button>
        )}
      >
        <CheckboxMenuItem
          option={{ id: 'All bookmarks', label: 'All bookmarks', value: 'All bookmarks' }}
        />
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">
          Bookmark ownership
        </TextMenuItem>
        {map(ownership, (value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label: value, value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Bookmark type</TextMenuItem>
        {map(labelOptions, (value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label: value, value }} />
        ))}
      </Dropdown>
    </>
  );
};

export default ListsFilters;
