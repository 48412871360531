import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Heading = ({ as: Component = 'h1', className, ...props }: HeadingProps) => (
  <Component
    className={overrideTailwindClasses(
      classNames('text-xl text-gray-700 font-semibold', className),
    )}
    {...props}
  />
);

export default Heading;
