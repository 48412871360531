import { join, compact, map, isArray, flatten, some } from 'lodash';
import {
  CompanyEmployeesFilter,
  CompanyFilterName,
  CompanyIndustryFilter,
  CompanyNameFilter,
  CompanyRevenueFilter,
  CompanySpecialityFilter,
  ConfirmedConnectFilter,
  ContactDetailsFilter,
  ContactFilterName,
  DataAccuracyFilter,
  DataFilterName,
  ExcludableFilter,
  FilterName,
  Filters,
  FreeTextFilter,
  InstallTopicFilter,
  Intent,
  IntentTopicFilter,
  JobFunctionFilter,
  JobLevelFilter,
  JobTitleFilter,
  ListFilter,
  ListFilterName,
  LocationFilter,
  SalesSignalsFilterName,
} from 'types';

export const getFreetextLabel = (value: FreeTextFilter) => {
  const { freetext } = value;

  return `All containing "${freetext}"`;
};

export const getCompanyLabel = (value: CompanyNameFilter[number]) => {
  if ('freetext' in value) {
    return getFreetextLabel(value);
  }

  return value.name!;
};

export const getLocationLabel = (value: LocationFilter) => {
  const { city, state, country, county, global_region } = value;

  let label = join(compact([city, state, country]), ', ');
  if (!label) {
    label = (county || global_region) as string;
  }

  return label;
};

export const getEmployeesLabel = (value: CompanyEmployeesFilter[number]) => {
  return value.size_range;
};

export const getIndustryLabel = (value: CompanyIndustryFilter[number], alwaysMain?: boolean) => {
  if ('sic' in value) {
    return `SIC: ${value.sic}`;
  }
  if ('naics' in value) {
    return `NAICS: ${value.naics}`;
  }

  if (!alwaysMain && value.sub_category) {
    return map(value.sub_category, ({ sub_category }) => sub_category);
  }

  return value.category;
};

export const getRevenueLabel = (value: CompanyRevenueFilter[number]) => {
  return value.rev_range;
};

export const getSpecialityLabel = (value: CompanySpecialityFilter[number]) => {
  if ('freetext' in value) {
    return getFreetextLabel(value);
  }

  return value.keyword;
};

export const getJobFunctionLabel = (value: JobFunctionFilter[number]) => {
  return value.function;
};

export const getJobLevelLabel = (value: JobLevelFilter[number]) => {
  return value.level;
};

export const getJobTitleLabel = (value: JobTitleFilter[number]) => {
  if ('freetext' in value) {
    return getFreetextLabel(value);
  }
  return value.title;
};

export const getDataAccuracyLabel = (value: DataAccuracyFilter) => {
  return `${value.range_min} - ${value.range_max}`;
};

export const getConfirmedConnectLabel = (value: ConfirmedConnectFilter) => {
  return `Last ${value.duration} days`;
};

export const getContactDetailsLabel = (value: ContactDetailsFilter) => {
  let labels = [];

  if (value.contact_business_email) {
    labels.push('Business email');
  }
  if (value.contact_phone) {
    labels.push('Phone');
  }
  if (value.contact_mobile_phone) {
    labels.push('Mobile phone');
  }
  if (value.contact_linkedin_handle) {
    labels.push('LinkedIn');
  }

  return join(labels, ', ');
};

export const getInstallTopicLabel = (value: InstallTopicFilter['install'][number]) => {
  if ('freetext' in value) {
    const { freetext } = value;
    return `All containing "${freetext}"`;
  }

  return (value as any).name;
};

export const getIntentTopicLabel = (value: Intent) => {
  if ('freetext' in value) {
    const { freetext } = value;
    return `All containing "${freetext}"`;
  }

  return value.topic;
};

export const getListLabel = (value: ListFilter) => {
  return value.name;
};

export const getLabel = <T extends FilterName>(name: T, value: any) => {
  switch (name) {
    case DataFilterName.DataAccuracy:
      return getDataAccuracyLabel(value);
    case DataFilterName.ConfirmedConnect:
      return getConfirmedConnectLabel(value);
    case DataFilterName.ContactDetails:
      return getContactDetailsLabel(value);
    case CompanyFilterName.CompanyEmployees:
      return getEmployeesLabel(value);
    case CompanyFilterName.CompanyIndustry:
      return getIndustryLabel(value);
    case CompanyFilterName.CompanyLocation:
      return getLocationLabel(value);
    case CompanyFilterName.CompanyName:
      return getCompanyLabel(value);
    case CompanyFilterName.CompanyRevenue:
      return getRevenueLabel(value);
    case CompanyFilterName.CompanySpeciality:
      return getSpecialityLabel(value);
    case ContactFilterName.ContactLocation:
      return getLocationLabel(value);
    case ContactFilterName.ContactName:
      return getFreetextLabel(value);
    case ContactFilterName.JobFunction:
      return getJobFunctionLabel(value);
    case ContactFilterName.JobLevel:
      return getJobLevelLabel(value);
    case ContactFilterName.JobTitle:
      return getJobTitleLabel(value);
    case SalesSignalsFilterName.InstallTopic:
      return getInstallTopicLabel(value);
    case SalesSignalsFilterName.IntentTopic:
      return getIntentTopicLabel(value);
    case ListFilterName.ExclusionList:
      return getListLabel(value);
    case ListFilterName.InclusionList:
      return getListLabel(value);
  }

  return '';
};

export const getLabelsWithExcludeInfo = <T extends FilterName>(name: T, filter: Filters[T]) => {
  let filterValues;

  if (isArray(filter)) {
    filterValues = filter;
  } else if (name === SalesSignalsFilterName.InstallTopic) {
    filterValues = (filter as InstallTopicFilter).install;
  } else if (name === SalesSignalsFilterName.IntentTopic) {
    filterValues = (filter as IntentTopicFilter).intent;
  } else {
    filterValues = [filter];
  }

  return flatten(
    map(filterValues, (value) => ({
      label: getLabel(name, value),
      exclude: (value as ExcludableFilter)?.exclude,
    })),
  );
};

export const getLabels = <T extends FilterName>(name: T, filter: Filters[T]) => {
  const labelsWithExcludeInfo = getLabelsWithExcludeInfo(name, filter);

  return map(labelsWithExcludeInfo, 'label');
};

// atm all filters are applicable to both contacts and companies
// this is kept for the future if some to be omitted from company filters
export const getCompanyFilters = (filters: Filters) => filters;

export const hasAllAcquiredCompaniesFilter = (filters: Filters) => {
  return (
    some(filters[ListFilterName.ExclusionList], {
      name: 'All acquired companies',
      records_type: 'company',
    }) ||
    some(filters[ListFilterName.InclusionList], {
      name: 'All acquired companies',
      records_type: 'company',
    })
  );
};
