import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateCurrentBillingEmail } from 'api/organization';

const useMutateBilling = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();

  const updateBillingEmail = useMutation(updateCurrentBillingEmail, {
    onSettled: () => {
      queryClient.invalidateQueries(['billingInfo']);
    },
    ...options,
  });

  return { updateBillingEmail };
};

export default useMutateBilling;
