import { forEach } from 'lodash';
import { useEffect, useState } from 'react';

export interface GTMType {
  push: (event: any) => void;
}

interface GTMWindow extends Window {
  dataLayer?: GTMType;
}

declare let window: GTMWindow;

const useGTM = (options?: { enabled: boolean }) => {
  const { enabled } = options || {};
  const [tracker, setTracker] = useState<GTMType>([] as unknown as GTMType);

  useEffect(() => {
    if (enabled && window.dataLayer) {
      setTracker((state) => {
        // push any existing events that happend before initialization to the data layer
        forEach(state, (event) => window.dataLayer!.push(event));

        return window.dataLayer!;
      });
    }
  }, [enabled]);

  return tracker;
};

export default useGTM;
