import api from 'api';

import { DataProfileTypes, DataModulesCustomization, AllViews } from 'types';

export const fetchModulesCustomization = (): Promise<DataProfileTypes> =>
  api.get('/search/data-profiles/types');

export const updateViewCustomization = (
  id: string | undefined,
  payload: DataModulesCustomization,
): Promise<void> => api.patch(`/search/data-profiles/views/${id}`, payload);

export const fetchAllViews = (): Promise<AllViews> => api.get('/search/data-profiles/views');
