import React from 'react';

import { AllViews, DataProfileTypes, Views } from 'types';

export interface ModulesCustomizationContextInterface {
  allModules: DataProfileTypes | undefined;
  allViews: AllViews | undefined;
  isMultiViews: boolean;
  onChange: (value: any) => void;
  open: boolean;
  setCurrentView: (multiViews: Views | undefined) => void;
  setCustomizeModulesOpen: () => void;
  setIsMultiViews: (multiViews: boolean) => void;
  setOpen: (open: boolean) => void;
}

const Context = React.createContext<ModulesCustomizationContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  Context.displayName = 'ModulesCustomizationContext';
}

export default Context;
