import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useModal from 'hooks/useModal';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import { Button, Modal, Text } from '@demandscience/ui';
import { noop } from 'lodash';

const VersionCheck = () => {
  const { open, openModal } = useModal();
  const location = useLocation();

  const handleClick = useCallback(() => {
    // reload page to apply new version
    window.location.reload();
  }, []);

  useEffect(() => {
    // check for sw updates on view change
    serviceWorkerRegistration.update();
  }, [location]);

  useEffect(() => {
    // Learn more about service workers: https://cra.link/PWA
    const configuration = {
      onInit: (registration: ServiceWorkerRegistration) => {
        if (registration && registration.waiting && registration.waiting.state === 'installed') {
          // on page reload just accept the new service worker version
          // no need to reload as no precache is used
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
      },
      onUpdate: (registration: ServiceWorkerRegistration) => {
        if (registration && registration.waiting) {
          // new version available if service worker in waiting state
          openModal();
        }
      },
    };

    serviceWorkerRegistration.register(configuration);
  }, [openModal]);

  return (
    <Modal
      aria-labelledby="new-version-message-overlay"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      open={open}
      onClose={noop} // ignore internal modal's close
    >
      <Modal.Title id="new-version-message-overlay" className="flex mb-4">
        New version of Klarity available
      </Modal.Title>
      <Text variant="body2" className="text-justify pt-4 font-light">
        A new version has been detected, please reload this page to enable it.
      </Text>
      <div className="flex justify-end space-x-2 mt-4">
        <Button onClick={handleClick} type="button">
          Reload now
        </Button>
      </div>
    </Modal>
  );
};

export default VersionCheck;
