import { useCallback, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, Button } from '@demandscience/ui';
import Alert from 'components/Alert';
import classNames from 'classnames';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { overrideTailwindClasses } from 'tailwind-override';
import useMutateAccount from './useMutateAccount';
import { AxiosError } from 'axios';
import useAuth from 'components/Auth/useAuth';
import useModal from 'hooks/useModal';
import DeleteConfirmationOverlay from './DeleteConfirmationOverlay';

import TextTooltip from 'components/Tooltip/TextTooltip';

interface AccountDeleteEditorProps {
  impersonated?: true;
}

const AccountDeleteEditor = ({ impersonated }: AccountDeleteEditorProps) => {
  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues: { delete: '' },
  });
  const { destroyAccount } = useMutateAccount();
  const [apiError, setApiError] = useState<string | null>(null);
  const { showMessage } = useSnackbar();
  const { isSubmitting, errors } = formState;

  const [update, setUpdate] = useState(false);

  const { open, openModal, closeModal } = useModal();
  const { signOut } = useAuth();

  const handleSignout = useCallback(async () => {
    try {
      await signOut();
    } catch (e: any) {
      const { message } = e;
      showMessage(message, 'error');
    }
  }, [signOut, showMessage]);

  const handleUpdateClick = useCallback(() => {
    reset();
    setUpdate((state) => !state);
  }, [reset]);

  const handleClose = useCallback(() => {
    reset();
    setUpdate((state) => !state);
  }, [reset]);

  const onSubmit: SubmitHandler<{}> = async () => {
    setApiError(null);
    // Always false on this first step of deletion
    const query = 'with_org_and_siblings=false';

    try {
      await destroyAccount.mutateAsync(query);

      handleClose();

      showMessage('The account has been successfully deleted', 'success');
      handleSignout();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        let { error_code } = e.response?.data || {};

        // If the current user is the last manager and the organization still has active users
        if (error_code === 'other_active_accounts_exist') {
          openModal();
        } else {
          showMessage('Unable to delete this account', 'error');
        }
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  };

  return (
    <div className={`border-b ${update ? 'pb-6' : ''}`}>
      <div
        className={overrideTailwindClasses(
          classNames('flex flex-row justify-between items-center mb-6'),
        )}
      >
        <p className="text-base font-medium text-gray-700">Delete your account</p>
        <TextTooltip
          title={impersonated ? 'Account deletion is not possible in impersonate mode' : undefined}
          placement="top"
          showArrow
        >
          <Button
            className="whitespace-nowrap"
            theme="primary"
            size="md"
            borderless
            type="button"
            onClick={handleUpdateClick}
            disabled={impersonated}
          >
            {update ? 'Cancel' : 'Delete'}
          </Button>
        </TextTooltip>
      </div>

      {update && (
        <>
          <p className={'text-base font-normal text-gray-500 pb-6'}>
            We are sorry to see you go! Are you certain about deleting your account ? Keep in mind
            that this action is irreversible, and you&apos;ll lose all your saved data and credits.
            If you&apos;re sure, type DELETE in the input box and click the Delete account button.
          </p>
          <form
            className="flex flex-col gap-6"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <Controller
              name="delete"
              control={control}
              render={({ field }) => (
                <TextField
                  placeholder="Type DELETE"
                  variant="outlined"
                  error={errors.delete?.message}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  {...field}
                />
              )}
              rules={{
                required: 'Please type DELETE',
                validate: (value) => {
                  return value === 'DELETE' || 'Please type DELETE';
                },
              }}
            />

            <div className="space-x-3">
              <Button theme="primary" type="submit" disabled={isSubmitting || !formState.isDirty}>
                Delete account
              </Button>
              <Button theme="primary" type="button" outline onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </form>

          <DeleteConfirmationOverlay open={open} onClose={closeModal} />

          {apiError && <Alert message={apiError} className="mt-6" />}
        </>
      )}
    </div>
  );
};

export default AccountDeleteEditor;
