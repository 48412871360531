import { isEmpty, map } from 'lodash';
import { getJobLevelLabel } from 'components/Filters/utils';
import { Progress } from '@demandscience/ui';
import { JobLevel } from 'types';
import { useCallback } from 'react';

interface JobLevelAggregateProps {
  aggregate: any;
  onClick: (data: JobLevel) => void;
}

const JobLevelAggregate = ({ aggregate, onClick }: JobLevelAggregateProps) => {
  const handleClick = useCallback(
    (data: JobLevel) => () => {
      onClick(data);
    },
    [onClick],
  );

  if (isEmpty(aggregate.values)) {
    return null;
  }

  return (
    <div className="space-y-2">
      <div>Job level</div>
      <div className="-mx-2">
        {map(aggregate.values, ({ value, count, data }) => (
          <button
            key={value}
            className="flex flex-row items-center gap-3 w-full p-2 transition hover:bg-gray-100 outline-none text-left"
            onClick={handleClick(data)}
          >
            <div className="w-full mr-1">
              <div className="flex justify-between text-xs">
                <span className="text-gray-800">{getJobLevelLabel(data)}</span>
                <span className="text-gray-500">{count.toLocaleString()}</span>
              </div>
              <Progress
                value={Math.floor((count / aggregate.count) * 100)}
                size="sm"
                theme="gray"
                variant="solid"
                className="mt-1"
              />
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default JobLevelAggregate;
