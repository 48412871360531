import { Company } from 'types';
import { isEmpty, join, compact } from 'lodash';
import ListItemText from 'components/Layout/ListItemText';
import MapPinIcon from '@demandscience/ui/icons/map-pin';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';

interface CompanyLocationProps {
  className?: string;
  field?: 'address_line_1';
  inline?: boolean;
  row?: Company;
}

const CompanyLocation = ({ className, row, inline, field }: CompanyLocationProps) => {
  if (!row || isEmpty(row?.offices)) {
    return null;
  }

  // displaying only 1st office address for now (Nicolas)
  const [{ address_line_1, address_line_2, city, state, country }] = row.offices;

  const address = join(compact([city, state, country]), ', ');

  if (inline) {
    if (field === 'address_line_1') {
      return <Paragraph className={className}>{address_line_1}</Paragraph>;
    }

    return <Paragraph className={className}>{address}</Paragraph>;
  }

  return (
    <ListItemText
      leadingContent={<MapPinIcon className="w-5 h-5" />}
      overline="HQ Location"
      title={
        <>
          <div>{address_line_1}</div>
          <div>{address_line_2}</div>
          <div>{address}</div>
        </>
      }
    />
  );
};

export default CompanyLocation;
