import { useCallback, useEffect, useState } from 'react';

const useJuiceExtension = () => {
  const extensionId = process.env.REACT_APP_JUICE_EXTENSION_ID;
  const [installed, setInstalled] = useState<boolean | null>(null);

  const sendMessage = useCallback(
    (payload: any) =>
      new Promise<any>((resolve, reject) => {
        chrome.runtime.sendMessage(extensionId!, payload, (res?: any) => {
          if (chrome.runtime.lastError) {
            reject(chrome.runtime.lastError);
          } else {
            if (res && res.message === 'pong') {
              resolve(res);
            } else {
              reject(new Error(res?.payload?.message || 'Unexpected error'));
            }
          }
        });
      }),
    [extensionId],
  );

  useEffect(() => {
    const checkExtensionInstalation = async () => {
      if (window.chrome?.runtime && extensionId) {
        try {
          const payload = {
            type: 'ping',
          };

          await sendMessage(payload);

          setInstalled(true);
        } catch (e) {
          setInstalled(false);
        }
      } else {
        setInstalled(false);
      }
    };

    checkExtensionInstalation();

    // add focus listener as it can happen that extension was installed/deinstalled/disabled
    window.addEventListener('focus', checkExtensionInstalation);

    return () => {
      window.removeEventListener('focus', checkExtensionInstalation);
    };
  }, [extensionId, sendMessage]);

  return { extensionId, installed };
};

export default useJuiceExtension;
