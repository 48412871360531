import { isEmpty, map, find, last } from 'lodash';
import Icon from '../Filter/FilterIcon';
import Label from '../Filter/FilterLabel';
import TextMenuItem from '../Filter/Dropdown/TextMenuItem';
import RadioButtonMenuItem from '../Filter/Dropdown/RadioButtonMenuItem';
import { DataFilterName, Duration } from 'types/filters';
import useFilter from './useFilter';
import { FilterProps } from './types';
import Inline from 'components/Filter/Inline';
import Button from 'components/Filter/Inline/Button';
import Badges from 'components/Filter/Inline/Badges';
import classNames from 'classnames';
import { useCallback } from 'react';

const options = [
  {
    id: '1',
    value: 30,
    label: 'Last 30 days',
  },
  {
    id: '2',
    value: 60,
    label: 'Last 60 days',
  },
  {
    id: '3',
    value: 90,
    label: 'Last 90 days',
  },
] as Array<{
  id: string;
  label: string;
  value: Duration;
}>;

const getBadgeProps = (value: any) => ({ key: value.id, ...value });

const ConfirmedConnectFilter = ({ dense }: FilterProps) => {
  const { value: filterValue, onChange, onClear } = useFilter(DataFilterName.ConfirmedConnect);
  const value = find(options, { value: filterValue?.duration });

  const handleChange = useCallback(
    (value: [{ value: Duration }]) => {
      if (isEmpty(value)) {
        return;
      }

      // the last item is the the last selected
      // this way we have single value selection
      const { value: duration } = last(value)!;
      onChange({
        duration,
      });
    },
    [onChange],
  );

  return (
    <Inline
      multiple // multiple cobobox is kept open adter selection
      value={isEmpty(value) ? [] : [value]}
      onChange={handleChange}
      button={({ open }) => (
        <Button
          icon={<Icon name={DataFilterName.ConfirmedConnect} />}
          label={<Label name={DataFilterName.ConfirmedConnect} />}
          filled={!isEmpty(value)}
          expanded={open}
          dense={dense}
          onClear={onClear}
        />
      )}
      info={
        <Badges
          className={classNames({
            'px-4 pb-4': !dense,
            'px-4 pb-2': dense,
          })}
          value={isEmpty(value) ? [] : [value]}
          onDelete={onClear}
          getBadgeProps={getBadgeProps}
        />
      }
    >
      <TextMenuItem className="-mt-2">
        Select only contacts who have responded to email or answered the phone within a given
        period.
      </TextMenuItem>
      {map(options, (option) => (
        <RadioButtonMenuItem key={option.value} option={option} />
      ))}
    </Inline>
  );
};

export default ConfirmedConnectFilter;
