import { Modal, ModalProps } from '@demandscience/ui';

import { Member } from 'types';
import Alert from 'components/Alert';
import useOrganization from 'components/Organization/useOrganization';
import ReactivateForm from './ReactivateForm';
import useSupportRequestContext from 'components/SupportRequest/useSupportRequestContext';

interface ReactivateOverlayProps extends Omit<ModalProps, 'children'> {
  member: Member;
}

const ReactivateOverlay: React.FC<ReactivateOverlayProps> = ({ member, ...props }) => {
  const { changePlan } = useSupportRequestContext();
  const { data: organization } = useOrganization();
  const accountsAvailable = organization?.active_accounts?.available;

  const handleUpgrade = () => {
    props.onClose();

    changePlan();
  };

  return (
    <Modal
      aria-labelledby="reactivate-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="reactivate-dialog-title" className="mb-9">
        Reactivate account
      </Modal.Title>
      {accountsAvailable !== undefined && (
        <>
          {accountsAvailable === 0 && (
            <Alert
              className="mb-4"
              message="You have no more seats available"
              action="Upgrade now"
              actionProps={{
                className: 'flex-shrink-0',
                onClick: handleUpgrade,
                size: 'md',
                borderless: true,
              }}
            />
          )}
          <ReactivateForm
            member={member}
            accountsAvailable={accountsAvailable}
            onSuccess={props.onClose}
            onCancel={props.onClose}
          />
        </>
      )}
    </Modal>
  );
};

export default ReactivateOverlay;
