import { ChangeEventHandler, useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  isEmpty,
  map,
  find,
  isEqual,
  omit,
  reject,
  flatten,
  uniq,
  split,
  forEach,
  some,
} from 'lodash';
import CheckboxOption from '../Filter/Dropdown/CheckboxMenuItem';
import TextMenuItem from '../Filter/Dropdown/TextMenuItem';
import { FilterProps, Option } from './types';
import {
  SalesSignalsFilterName,
  InstallTopicFilter as InstallTopicFilterType,
  Month,
  badgeDisplayCap,
  FreeTextFilter,
} from 'types/filters';
import useFilter from './useFilter';
import useTechInstallAutocomplete from './Autocomplete/useTechInstallAutocomplete';
import FilterInput from './FilterInput';
import Inline from 'components/Filter/Inline';
import ToggleMenuItem from 'components/Filter/Dropdown/ToggleMenuItem';
import SelectMonth from 'components/Input/SelectMonth';
import { getFreetextLabel, getInstallTopicLabel } from './utils';

const getBadgeProps = (value: any) => {
  const label = getInstallTopicLabel(value);

  return {
    key: label,
    label,
    exclude: value.exclude,
  };
};

const InstallTopicFilter = ({ clearInputOnChange, dense }: FilterProps) => {
  const name = SalesSignalsFilterName.InstallTopic;
  const freeSolo = true;
  const placeholder = 'Enter technology name';
  const showIncludeExclude = true;

  const { ref, inView } = useInView();
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { value: filterValue, onChange, onClear } = useFilter(name);
  const value = useMemo(() => filterValue?.install || [], [filterValue]);
  const [month, setMonth] = useState<Month[] | undefined>(filterValue?.month);
  const { data, isFetching, isError, hasNextPage, fetchNextPage } = useTechInstallAutocomplete(
    { value: query },
    {
      keepPreviousData: false,
      retry: false,
    },
  );
  const suggestions = flatten(data?.pages);

  const freeTextOptions: Option<FreeTextFilter>[] = useMemo(() => {
    let res: Option<FreeTextFilter>[] = [];

    if (freeSolo && query !== '') {
      const tokens = uniq(split(query, /\s*,\s*/));

      forEach(tokens, (token, index) => {
        if (token) {
          const dataFromValue = find(value, (v) =>
            isEqual(omit(v, 'exclude'), { freetext: token, name: token }),
          ) as FreeTextFilter;

          const optionValue = dataFromValue || {
            freetext: token,
            name: token,
          };
          const label = getFreetextLabel(optionValue);

          res = [...res, { id: `freetext${index}`, value: optionValue, label }];
        }
      });
    }

    return res;
  }, [freeSolo, query, value]);

  let options = suggestions || [];

  // workaround so combobox comparison by reference works (against the values)
  options = map(options, (option) => {
    const dataFromValue = find(value, (v) =>
      isEqual(omit(v, 'exclude'), omit(option.value, 'exclude')),
    );

    if (dataFromValue) {
      return {
        ...option,
        value: dataFromValue,
      };
    }

    return {
      ...option,
      value: omit(option.value, 'exclude'),
    };
  });

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
  }, []);

  const handleChange = useCallback(
    (newValue: InstallTopicFilterType['install']) => {
      if (isEmpty(newValue)) {
        onClear();
      } else {
        onChange({
          month,
          install: newValue,
        });
      }

      // focus input on change
      inputRef.current?.focus();

      // workaround to clear filter input and the suggestions dropdown
      if (clearInputOnChange) {
        handleInputClear();
      }
    },
    [clearInputOnChange, handleInputClear, month, onChange, onClear],
  );

  const handleMonthChange = useCallback(
    (month?: Month[]) => {
      setMonth(month);

      if (filterValue) {
        onChange({
          ...filterValue,
          month,
        });
      }
    },
    [filterValue, onChange],
  );

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const {
      target: { value },
    } = e;

    setQuery(value);
  }, []);

  const handleToggle = (option: Option<any>) => {
    const newValue = map(value, (v) => {
      if (isEqual(option.value, v)) {
        return option.value.exclude
          ? omit(option.value, 'exclude')
          : { ...option.value, exclude: true };
      }

      return v;
    });

    onChange({
      ...filterValue,
      install: newValue,
    });
  };

  const handleDelete = useCallback(
    (deleted: any) => {
      const newValue = reject(value, deleted);

      handleChange(newValue);
    },
    [handleChange, value],
  );

  const handleClear = useCallback(() => {
    handleInputClear();

    onClear();
  }, [handleInputClear, onClear]);

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleEnterKey = useCallback(() => {
    const missing = reject(freeTextOptions, (option) => some(value, option.value));

    if (isEmpty(missing)) {
      // all are present, remove them all
      const newInstall = [
        ...(reject(value, (val) =>
          some(freeTextOptions, (option) => option.value.freetext === val.freetext),
        ) || []),
      ];

      if (isEmpty(newInstall)) {
        onClear();
      } else {
        onChange({
          ...filterValue,
          install: newInstall,
        });
      }
    } else {
      // add those missing
      onChange({
        ...filterValue,
        install: [
          ...(filterValue?.install || []),
          ...map(missing, (m) => ({ name: m.value.freetext, freetext: m.value.freetext })),
        ],
      });
    }
  }, [filterValue, freeTextOptions, onChange, onClear, value]);

  useEffect(() => {
    if (inView) {
      handleLoadMore();
    }
  }, [inView, handleLoadMore]);

  return (
    <Inline
      value={value}
      onChange={handleChange}
      multiple
      button={(props) => (
        <FilterInput
          {...props}
          ref={inputRef}
          name={name}
          dense={dense}
          onClear={handleClear}
          onDelete={handleDelete}
          onInputChange={handleInputChange}
          onInputClear={handleInputClear}
          placeholder={placeholder}
          getBadgeProps={getBadgeProps}
          onEnterKey={handleEnterKey}
          badgeDisplayCap={badgeDisplayCap}
        />
      )}
      footer={
        <>
          <div className="border-t mx-4 mb-2" />
          <ToggleMenuItem title="Renewal month" show={!isEmpty(month)}>
            <div className="px-4 pt-2">
              <SelectMonth value={month} onChange={handleMonthChange} />
            </div>
          </ToggleMenuItem>
        </>
      }
    >
      {isError && <TextMenuItem>Error fetching filtering values</TextMenuItem>}
      {!isError && !isEmpty(query) && (
        <>
          {!isFetching && isEmpty(freeTextOptions) && isEmpty(options) && (
            <TextMenuItem>No match for &quot;{query}&quot;</TextMenuItem>
          )}
          {map(freeTextOptions, (option) => (
            <CheckboxOption
              key={option.id}
              option={option}
              onToggle={showIncludeExclude ? handleToggle : undefined}
            />
          ))}
          {isFetching && <TextMenuItem>Loading...</TextMenuItem>}
          {map(options, (option) => (
            <CheckboxOption
              key={option.id}
              option={option}
              onToggle={showIncludeExclude ? handleToggle : undefined}
            />
          ))}
          {hasNextPage && (
            <TextMenuItem ref={ref} textClassName="text-gray-500">
              Loading...
            </TextMenuItem>
          )}
        </>
      )}
    </Inline>
  );
};

export default InstallTopicFilter;
