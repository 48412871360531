import { FC } from 'react';
import { Company } from 'types';
import classNames from 'classnames';
import { Link, IconButton } from '@demandscience/ui';
import LinkIcon from '@demandscience/ui/icons/link';
import LinkedinIcon from '@demandscience/ui/icons/LinkedinBrand';
import TwitterIcon from '@demandscience/ui/icons/TwitterBrand';
import ListItemText from 'components/Layout/ListItemText';
import { isEmpty, map } from 'lodash';
import { overrideTailwindClasses } from 'tailwind-override';

interface CompanySocialProfilesProps {
  className?: string;
  iconWrapperClassName?: string;
  inline?: boolean;
  onClick?: (e: any) => void;
  row: Company;
  useBrandColors?: boolean;
}

const CompanySocialProfiles: FC<CompanySocialProfilesProps> = ({
  row,
  className,
  inline,
  useBrandColors,
  iconWrapperClassName,
  ...props
}) => {
  const { main_domains, linkedin_profiles, twitter_profiles } = row;

  if (isEmpty(main_domains) && isEmpty(linkedin_profiles) && isEmpty(twitter_profiles)) {
    return null;
  }

  const socialLinks = (
    <div className={classNames('inline-flex flex-row', className)} {...props}>
      {inline &&
        !useBrandColors &&
        map(main_domains, (domain) => (
          <IconButton
            key={domain}
            className={classNames({ 'text-gray-400': inline, 'text-gray-500': !inline })}
            size="xs"
            as={Link}
            href={`https://${domain}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="p-1">
              <LinkIcon size={16} />
            </div>
          </IconButton>
        ))}
      {map(linkedin_profiles, ({ profile_handle }) => (
        <IconButton
          key={profile_handle}
          className={classNames({
            'text-gray-400': inline && !useBrandColors,
            'text-gray-500': !inline && !useBrandColors,
            'text-linkedin hover:text-linkedin': useBrandColors,
          })}
          size="xs"
          as={Link}
          href={`https://www.linkedin.com/company/${profile_handle}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={overrideTailwindClasses(classNames('p-[3px]', iconWrapperClassName))}>
            <LinkedinIcon size={18} />
          </div>
        </IconButton>
      ))}
      {map(twitter_profiles, ({ profile_handle }) => (
        <IconButton
          key={profile_handle}
          className={classNames({
            'text-gray-400': inline && !useBrandColors,
            'text-gray-500': !inline && !useBrandColors,
            'text-linkedin hover:text-twitter': useBrandColors,
          })}
          size="xs"
          as={Link}
          href={`https://twitter.com/${profile_handle}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={overrideTailwindClasses(classNames('p-[3px]', iconWrapperClassName))}>
            <TwitterIcon size={18} />
          </div>
        </IconButton>
      ))}
    </div>
  );

  if (inline) {
    return socialLinks;
  }

  if (isEmpty(linkedin_profiles) && isEmpty(twitter_profiles)) {
    return null;
  }

  return <ListItemText leadingContent={<LinkIcon className="w-5 h-5" />} title={socialLinks} />;
};

export default CompanySocialProfiles;
