import take from 'lodash/take';
import isEmpty from 'lodash/isEmpty';

import { Link, Transition } from '@demandscience/ui';
import NotificationsDrawerControl from './DrawerControl';
import List from './List';
import useNotifications from './useNotifications';
import classNames from 'classnames';

interface NotificationsShortListProps {
  className?: string;
  limit?: number;
}

const NotificationsShortList = ({ className, limit = 5 }: NotificationsShortListProps) => {
  const { query, markAsRead } = useNotifications();
  const { data, isLoading } = query;

  const { count = 0, notifications } = data?.pages?.[0] || {};
  const notificationsToShow = take(notifications, limit);
  const hasMore = count > limit;

  return (
    <Transition.Fade open show={!isLoading && !isEmpty(notificationsToShow)} as="div">
      <div
        className={classNames(className, 'flex flex-row justify-between items-center pb-[22px]')}
      >
        <div className="text-lg text-gray-700 font-medium">Latest notifications</div>
        {hasMore && <NotificationsDrawerControl as={Link}>View all</NotificationsDrawerControl>}
      </div>
      {!isEmpty(notificationsToShow) && (
        <div className="border rounded py-2">
          <List items={notificationsToShow} markAsRead={markAsRead} />
        </div>
      )}
    </Transition.Fade>
  );
};

export default NotificationsShortList;
