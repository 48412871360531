import React from 'react';
import classNames from 'classnames';
import { Button, ButtonGroup } from '@demandscience/ui';
import { Theme } from '@demandscience/ui/dist/types';
import { overrideTailwindClasses } from 'tailwind-override';

export interface TabSwitchProps extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'onChange'> {
  activeClassName?: string;
  buttonClassName?: string;
  inactiveClassName?: string;
  label1: string;
  label2: string;
  onChange: (value: number) => void;
  theme?: Theme;
  value: 0 | 1;
}

const TabSwitch = ({
  label1,
  label2,
  value = 0,
  theme = 'primary',
  buttonClassName,
  activeClassName,
  inactiveClassName,
  onChange,
  ...props
}: TabSwitchProps) => {
  const baseClass = overrideTailwindClasses(
    classNames(
      'border-none focus:ring-0 py-0.5 px-[17px] text-xs',
      'min-w-[76px]',
      buttonClassName,
    ),
  );

  const activeTabClassNames = overrideTailwindClasses(
    classNames(`bg-${theme}-500 hover:bg-${theme}-700 text-white`, activeClassName),
  );

  const inactiveTabClassNames = overrideTailwindClasses(
    classNames(
      `bg-gray-200 text-gray-500 hover:text-gray-600 hover:bg-gray-200`,
      inactiveClassName,
    ),
  );

  const handleClick = (index: 0 | 1) => () => {
    onChange(index);
  };

  return (
    <div>
      <ButtonGroup className="border-none border-transparent" {...props}>
        <Button
          className={`${baseClass} ${value === 0 ? activeTabClassNames : inactiveTabClassNames}`}
          onClick={handleClick(0)}
        >
          {label1}
        </Button>
        <Button
          className={`${baseClass} ${value === 1 ? activeTabClassNames : inactiveTabClassNames}`}
          onClick={handleClick(1)}
        >
          {label2}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default TabSwitch;
