import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchBilling } from 'api/organization';
import { BillingParams } from 'types';

const usePreviousPurchases = (
  params: Omit<BillingParams, 'offset'>,
  options?: Record<string, any>,
) => {
  const query = useInfiniteQuery(
    ['billing', 'infinite', params],
    ({ pageParam: offset = undefined }) => fetchBilling({ ...params, offset }),
    {
      staleTime: 60 * 1000, // 1 minute
      getNextPageParam: (lastPage: any) => lastPage.next_offset,
      ...options,
    },
  );

  return query;
};

export default usePreviousPurchases;
