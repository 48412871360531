import { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, Button } from '@demandscience/ui';

import capitalize from 'lodash/capitalize';

import Alert from 'components/Alert';
import useMutateAccount from './useMutateAccount';
import { UpdateNamePayload } from 'types';
import { AxiosError } from 'axios';
import { size } from 'lodash';

export interface AccountCompleteProps {
  value?: string;
}

const AccountComplete: React.FC<AccountCompleteProps> = ({ value = '' }) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { updateName } = useMutateAccount();
  const { control, handleSubmit, formState, setError } = useForm<UpdateNamePayload>({
    defaultValues: { name: value },
  });
  const { isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<UpdateNamePayload> = async (data) => {
    setApiError(null);

    try {
      await updateName.mutateAsync(data);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        const { error_field, error } = e.response?.data;

        if (error_field !== undefined) {
          setError(error_field, { type: 'api', message: capitalize(error) });
          return;
        }

        const { message } = e;
        setApiError(error || message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="space-y-4">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <div className="flex-1">
              <TextField
                label="Name"
                trailingLabel={`${size(field.value)}/100 characters`}
                placeholder="Enter your full name"
                variant="outlined"
                size="lg"
                required
                error={errors.name?.message}
                {...field}
              />
            </div>
          )}
          rules={{
            required: 'Required field',
            maxLength: {
              value: 100,
              message: '100 characters max',
            },
          }}
        />

        {apiError && <Alert message={apiError} />}
      </div>

      <Button className="w-full mt-6" size="lg" type="submit" disabled={isSubmitting}>
        Save
      </Button>
    </form>
  );
};

export default AccountComplete;
