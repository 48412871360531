import { Button, Badge } from '@demandscience/ui';

import round from 'lodash/round';

import useFilters from 'components/Filters/useFilters';
import useTAMCounts from './useTAMCounts';
import Counts from './Counts';
import Filters from './Filters';
import { withoutInclude, includeToExclude } from './utils';
import { Aggregations, Filters as FiltersType, ResultsView } from 'types';
import { useCallback } from 'react';

interface ExploreTAMProps {
  aggregates?: Aggregations;
  companyCount: number;
  contactCount: number;
  searchFilters: FiltersType;
  setFilters: (filters: FiltersType, tab?: ResultsView) => void;
}

const ExploreTAM = ({
  contactCount,
  companyCount,
  setFilters,
  aggregates,
  searchFilters,
}: ExploreTAMProps) => {
  const { filters } = useFilters();
  const { contactCountTotal, companyCountTotal, contactCountNew, companyCountNew, isFetching } =
    useTAMCounts({ searchFilters });

  let contactCountPerc;
  let companyCountPerc;

  if (!isFetching) {
    contactCountPerc = round((contactCount / (contactCount + contactCountNew)) * 100, 0);
    companyCountPerc = round((companyCount / (companyCount + companyCountNew)) * 100, 0);
  }

  const handleClick = useCallback(() => {
    // combination of search filters with includes removed and current TAM filters

    const filtersTotal = {
      ...withoutInclude(searchFilters),
      ...filters,
    };
    setFilters(filtersTotal);
  }, [filters, searchFilters, setFilters]);

  const handleChipClick = useCallback(
    (kind: 'companies' | 'contacts') => () => {
      const filtersNew = {
        ...includeToExclude(searchFilters),
        ...filters,
      };
      setFilters(filtersNew, kind === 'companies' ? ResultsView.Companies : ResultsView.Contacts);
    },
    [filters, searchFilters, setFilters],
  );

  return (
    <>
      <div className="w-full grid grid-cols-2 grid-rows-2 grid-flow-row-dense md:grid-cols-7 md:grid-rows-1">
        <div className="col-span-1 md:col-span-2">
          <div className="text-sm">Current search</div>
          <Counts
            contactCount={contactCount.toLocaleString()}
            contactStats={
              isFetching ? (
                <div className="h-6 w-1/2 rounded animate-pulse bg-slate-200" />
              ) : (
                <p className="text-gray-400">{contactCountPerc}%</p>
              )
            }
            companyCount={companyCount.toLocaleString()}
            companyStats={
              isFetching ? (
                <div className="h-6 w-1/2 rounded animate-pulse bg-slate-200" />
              ) : (
                <p className="text-gray-400">{companyCountPerc}%</p>
              )
            }
          />
        </div>
        <div className="col-span-2 md:col-span-3 md:px-4 md:mx-4 md:border-x">
          <p className="text-sm mb-2">Match these filters</p>
          {!aggregates && (
            <div className="space-y-4">
              <div className="h-8 w-full animate-pulse bg-slate-200" />
              <div className="h-8 w-full animate-pulse bg-slate-200" />
              <div className="h-8 w-full animate-pulse bg-slate-200" />
              <div className="h-8 w-full animate-pulse bg-slate-200" />
            </div>
          )}
          {aggregates && <Filters aggregates={aggregates} />}
        </div>
        <div className="col-span-1 md:col-span-2">
          <div className="text-sm">TAM</div>
          <Counts
            contactCount={isFetching ? undefined : contactCountTotal?.toLocaleString()}
            contactStats={
              isFetching ? (
                <div className="h-6 w-3/4 animate-pulse bg-slate-200" />
              ) : (
                <Badge theme="primary" size="md" rounded onClick={handleChipClick('contacts')}>
                  {`${contactCountNew?.toLocaleString()} new`}
                </Badge>
              )
            }
            companyCount={isFetching ? undefined : companyCountTotal?.toLocaleString()}
            companyStats={
              isFetching ? (
                <div className="h-6 w-3/4 animate-pulse bg-slate-200" />
              ) : (
                <Badge theme="primary" size="md" rounded onClick={handleChipClick('companies')}>
                  {`${companyCountNew?.toLocaleString()} new`}
                </Badge>
              )
            }
          />
        </div>
      </div>
      <div className="pt-4 text-center">
        <Button onClick={handleClick} theme="primary" disabled={isFetching}>
          Apply to search
        </Button>
      </div>
    </>
  );
};

export default ExploreTAM;
