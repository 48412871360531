import { useContext } from 'react';
import FeatureFlagContext, { FeatureFlagContextInterface } from './FeatureFlagContext';
import { KlarityFeature } from 'types';

const useFeatureFlag = (feature: KlarityFeature) => {
  const { getFeatureFlagStatus, requestFeatures } = useContext(
    FeatureFlagContext,
  ) as FeatureFlagContextInterface;

  return { featureStatus: getFeatureFlagStatus(feature), requestFeatures };
};

export default useFeatureFlag;
