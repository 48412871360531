import { Button } from '@demandscience/ui';

import useSnackbar from 'components/Snackbar/useSnackbar';
import { Member } from 'types';
import useMutateMember from './useMutateMember';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

interface ResendInviteControlProps {
  as?: React.ElementType;
  children: React.ReactNode;
  data: Member;
  onClick?: () => void;
  onSuccess?: () => void;
}

const ResendInviteControl: React.FC<ResendInviteControlProps> = ({
  data,
  as: Component = Button,
  onClick,
  onSuccess,
  ...props
}) => {
  const { showMessage } = useSnackbar();
  const { resendInvite } = useMutateMember();

  const handleClick = useCallback(async () => {
    if (onClick) onClick();

    try {
      await resendInvite.mutateAsync(data.username);

      if (onSuccess) onSuccess();
      showMessage('Invitation was resent', 'success');
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to resend invitation', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  }, [data.username, onClick, onSuccess, resendInvite, showMessage]);

  return <Component onClick={handleClick} disabled={resendInvite.isLoading} {...props} />;
};

export default ResendInviteControl;
