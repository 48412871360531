interface ConsentManagerWindow extends Window {
  PrivacyManagerAPI?: {
    callApi: (
      action: 'getGDPRConsentDecision',
      domain: string,
    ) => { consentDecision: Array<number> };
  };
}

declare let window: ConsentManagerWindow;

export const getPrivacyManagerAPI = () => window.PrivacyManagerAPI;
