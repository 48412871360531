import { useState, useEffect, useCallback } from 'react';
import { Modal, ModalProps, RadioList } from '@demandscience/ui';

import {
  ExportResponse,
  ModelType,
  ExportMapping,
  Filters,
  SortBy,
  AdvancedSelection,
  Constraints,
} from 'types';
import useMutateSearchResults from 'components/Search/useMutateSearchResults';
import { AxiosError } from 'axios';
import useExport from './useExport';
import { map } from 'lodash';
import { getFileTimestamp } from './utils';
import useSnackbar from 'components/Snackbar/useSnackbar';
import ExportInfo from './ExportInfo';
import ExportOverlayTitle from './ExportOverlayTitle';

const options = [
  { value: 'contacts to contacts', label: 'Export as contacts' },
  { value: 'contacts to leads', label: 'Export as leads' },
];

interface ExportSalesforceOverlayProps extends Omit<ModalProps, 'children'> {
  advancedSelection?: AdvancedSelection;
  constraints?: Constraints;
  count: number;
  filters?: Filters;
  maxContactsPerCompany?: number;
  sort?: SortBy;
  type: ModelType;
}

const ExportSalesforceOverlay = ({
  type,
  filters,
  sort,
  count,
  advancedSelection,
  constraints,
  ...props
}: ExportSalesforceOverlayProps) => {
  const { showMessage } = useSnackbar();
  const { hasIntegrationType } = useExport();
  const [exportMapping, setExportMapping] = useState<ExportMapping | undefined>();
  const [exportInfo, setExportInfo] = useState<ExportResponse | null>(null);
  const [apiError, setApiError] = useState<string | null>(null);
  const { open, onClose } = props;
  const { exportRecords } = useMutateSearchResults();
  const { mutateAsync } = exportRecords;

  const handleClick = useCallback(
    async ({
      name: customName,
      includePurchased,
    }: {
      includePurchased?: boolean;
      name?: string;
    }) => {
      if (!filters) {
        return;
      }

      try {
        const name = customName || `klarity-salesforce-${type}-${getFileTimestamp()}`;

        await mutateAsync({
          record_type: type,
          export_name: name,
          export_type: 'salesforce',
          export_mapping: exportMapping,
          exclude_acquired_records: includePurchased ? undefined : true,
          filters,
          sort,
          advanced_selection: advancedSelection,
          constraints,
        });

        showMessage(`Export of ${type}s to Salesforce started...`, 'success');

        onClose();
      } catch (e: any) {
        if (e instanceof AxiosError) {
          setApiError(e.response?.data?.error || 'Unable to export records');
        } else {
          setApiError('Unexpected error, please try again later');
        }
      }
    },
    [
      advancedSelection,
      constraints,
      exportMapping,
      filters,
      mutateAsync,
      onClose,
      showMessage,
      sort,
      type,
    ],
  );

  const handleExportMappingChange = useCallback((value: string) => {
    setExportMapping(value as ExportMapping);
  }, []);

  useEffect(() => {
    const handleError = (e: Error) => {
      if (e instanceof AxiosError) {
        if (
          e.response?.data?.error === 'not enough credits at account level' ||
          e.response?.data?.error === 'not enough bulk credits available at the organization'
        ) {
          setExportInfo(e.response?.data);
        } else {
          setApiError(e.response?.data?.error || 'Unexpected error, please try again later');
        }
      } else {
        setApiError('Unexpected error, please try again later');
      }
    };

    const fetchExportInfo = async () => {
      if (!filters) {
        return;
      }

      setApiError(null);

      try {
        const data = await mutateAsync({
          dry_run: true,
          record_type: type,
          filters,
          sort,
          advanced_selection: advancedSelection,
          constraints,
        });

        setExportInfo(data);
      } catch (e: any) {
        handleError(e);
      }
    };

    if (open) {
      if (count > 0) {
        fetchExportInfo();
      }
    } else {
      setExportInfo(null);
    }
  }, [open, filters, sort, advancedSelection, type, mutateAsync, constraints, count]);

  useEffect(() => {
    if (type === ModelType.Contact) {
      if (hasIntegrationType('salesforce-contacts-to-contacts')) {
        setExportMapping('contacts to contacts');
      } else if (hasIntegrationType('salesforce-contacts-to-leads')) {
        setExportMapping('contacts to leads');
      }
    } else if (type === ModelType.Company) {
      if (hasIntegrationType('salesforce-companies-to-accounts')) {
        setExportMapping('companies to accounts');
      }
    }
  }, [type, hasIntegrationType]);

  return (
    <Modal aria-labelledby="export-to-salesforce-dialog-title" {...props}>
      <ExportOverlayTitle
        id="export-to-salesforce-dialog-title"
        count={count}
        exportTo="Salesforce"
      />
      <ExportInfo
        apiError={apiError}
        count={count}
        exportInfo={exportInfo}
        hasAdvancedSelection={!!advancedSelection}
        isLoading={exportRecords.isLoading}
        onClose={onClose}
        onSubmit={handleClick}
      >
        {({ missingCredits }) => {
          if (
            missingCredits === 0 &&
            type === ModelType.Contact &&
            hasIntegrationType('salesforce-contacts-to-contacts') &&
            hasIntegrationType('salesforce-contacts-to-leads')
          ) {
            return (
              <RadioList
                value={exportMapping as string}
                onChange={handleExportMappingChange}
                options={options}
              >
                <div className="space-y-4">
                  {map(options, (option) => (
                    <RadioList.Option key={option.value} option={option}>
                      <RadioList.Icon
                        className="mr-2"
                        theme="primary"
                        checked={option.value === exportMapping}
                      />
                      <RadioList.Label className="text-md">{option.label}</RadioList.Label>
                    </RadioList.Option>
                  ))}
                </div>
              </RadioList>
            );
          }

          return null;
        }}
      </ExportInfo>
    </Modal>
  );
};

export default ExportSalesforceOverlay;
