import { useQuery } from '@tanstack/react-query';

import { fetchSavedSearches } from 'api/savedSearch';
import { SavedSearchParams, Show } from 'types';

const usePersonas = (params?: Partial<SavedSearchParams>, options?: any) => {
  const query = useQuery(
    ['personas', params],
    () =>
      fetchSavedSearches({
        index: 0,
        count: 100,
        sort: { by: 'created_at', descending: true },
        filter: { show: Show.Own, is_a_persona: true },
        ...params,
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      ...options,
    },
  );

  return query;
};

export default usePersonas;
