import { useState } from 'react';
import { Button, TextField, Skeleton } from '@demandscience/ui';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import useMutateBilling from './useMutateBilling';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { AxiosError } from 'axios';
import useBillingInfo from './useBillingInfo';
import { BillingInfoPayload } from 'types';
import { omit, isEmpty } from 'lodash';

const BillingDetails = () => {
  const { data: billingData, isLoading: isBillingInfoLoading } = useBillingInfo();
  const [update, setUpdate] = useState(false);

  const { control, handleSubmit, reset, formState, watch, setValue } = useForm({
    defaultValues: {
      billing_info: {
        billing_address: {
          company: '',
          city: '',
          email: '',
          country: '',
          line1: '',
          state: '',
          zip: '',
        },
        vat_number: '',
      },
    },
  });

  const onlyBillingDetails = omit(billingData, 'billing_info.billing_address.email');

  const isDetailsEmpty =
    isEmpty(onlyBillingDetails.billing_info?.billing_address) &&
    isEmpty(onlyBillingDetails.billing_info?.vat_number);

  const { updateBillingEmail } = useMutateBilling();
  const { showMessage } = useSnackbar();
  const { isSubmitting, errors } = formState;

  const companyValue = watch('billing_info.billing_address.company');
  const cityValue = watch('billing_info.billing_address.city');
  const countryValue = watch('billing_info.billing_address.country');
  const line1Value = watch('billing_info.billing_address.line1');
  const stateValue = watch('billing_info.billing_address.state');
  const zipValue = watch('billing_info.billing_address.zip');
  const vatValue = watch('billing_info.vat_number');

  const isFormEmpty =
    (companyValue ||
      cityValue ||
      countryValue ||
      line1Value ||
      stateValue ||
      stateValue ||
      zipValue ||
      vatValue) === '';

  const onSubmit: SubmitHandler<BillingInfoPayload> = async (data) => {
    try {
      const payload = {
        billing_info: {
          billing_address: {
            email: billingData?.billing_info?.billing_address.email,
            city: data.billing_info?.billing_address?.city,
            company: data.billing_info?.billing_address?.company,
            country: data.billing_info?.billing_address?.country,
            first_name: data.billing_info?.billing_address?.first_name,
            last_name: data.billing_info?.billing_address?.last_name,
            line1: data.billing_info?.billing_address?.line1,
            line2: data.billing_info?.billing_address?.line2,
            line3: data.billing_info?.billing_address?.line3,
            phone: data.billing_info?.billing_address?.phone,
            state: data.billing_info?.billing_address?.state,
            zip: data.billing_info?.billing_address?.zip,
          },
          vat_number: data.billing_info?.vat_number,
        },
      };
      await updateBillingEmail.mutateAsync(payload);

      setUpdate(false);

      showMessage('Address was successfully changed', 'success');
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e instanceof AxiosError) {
          showMessage(e.response?.data?.error || 'Unable to update the address', 'error');
        } else {
          showMessage('Unexpected error, please try again later', 'error');
        }
      }
    }
  };

  const handleUpdateClick = () => {
    setValue(
      'billing_info.billing_address.company',
      billingData?.billing_info?.billing_address?.company!,
    );
    setValue(
      'billing_info.billing_address.email',
      billingData?.billing_info?.billing_address?.email!,
    );

    setValue(
      'billing_info.billing_address.city',
      billingData?.billing_info?.billing_address?.city!,
    );
    setValue(
      'billing_info.billing_address.country',
      billingData?.billing_info?.billing_address?.country!,
    );
    setValue(
      'billing_info.billing_address.line1',
      billingData?.billing_info?.billing_address?.line1!,
    );
    setValue(
      'billing_info.billing_address.state',
      billingData?.billing_info?.billing_address?.state!,
    );
    setValue('billing_info.billing_address.zip', billingData?.billing_info?.billing_address?.zip!);
    setValue('billing_info.vat_number', billingData?.billing_info?.vat_number!);
    setUpdate((state) => !state);
  };

  const handleClose = () => {
    setUpdate((state) => !state);
  };

  const isFullAddress =
    billingData?.billing_info?.billing_address?.country &&
    (billingData?.billing_info?.billing_address?.state ||
      billingData?.billing_info?.billing_address?.zip);

  const customerDetails = (
    <div className="flex flex-row items-end justify-between">
      <div className="text-sm text-gray-700 whitespace-pre-line">
        <p className="text-sm text-grey-700 font-normal">
          {billingData?.billing_info?.billing_address?.company}
        </p>

        <p className="text-sm text-grey-700 font-normal">
          {billingData?.billing_info?.billing_address?.line1}
        </p>
        <p className="text-sm text-grey-700 font-normal">
          {billingData?.billing_info?.billing_address?.city}
        </p>
        <p className="text-sm text-grey-700 font-normal">
          {billingData?.billing_info?.billing_address?.state}{' '}
          {billingData?.billing_info?.billing_address?.zip} {isFullAddress ? '-' : ''}
          {billingData?.billing_info?.billing_address?.country &&
            `${' '}${billingData?.billing_info?.billing_address?.country}`}
        </p>
        {billingData?.billing_info?.vat_number && (
          <p className="text-sm text-grey-700 font-normal">
            VAT ID: {billingData?.billing_info?.vat_number}
          </p>
        )}
      </div>
    </div>
  );

  const emailHoldingText = isDetailsEmpty ? 'No address on file' : customerDetails;
  const updateBtnText = isDetailsEmpty ? 'Add' : 'Change';

  const skeleton = (
    <Skeleton className="inline-block align-top mt-3" variant="circle" height={5} width={80} />
  );

  return (
    <div className="flex flex-col gap-4 max-w-xl">
      <p className="text-lg text-gray-700 font-medium">Billing Details</p>
      {isBillingInfoLoading && skeleton}
      {!isBillingInfoLoading && (
        <div className="flex flex-row justify-between items-center">
          <p className="text-sm text-gray-700 whitespace-pre-line">
            {update ? 'Change address' : emailHoldingText}
          </p>

          <Button
            className="whitespace-nowrap self-end"
            theme="primary"
            size="md"
            borderless
            type="button"
            onClick={handleUpdateClick}
            disabled={isSubmitting}
          >
            {update ? 'Cancel' : updateBtnText}
          </Button>
        </div>
      )}
      {update && (
        <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="billing_info.billing_address.company"
            control={control}
            render={({ field }) => (
              <TextField
                label="Company"
                variant="outlined"
                placeholder="Enter the company name"
                error={errors?.billing_info?.billing_address?.company?.message}
                {...field}
              />
            )}
            rules={{ required: 'Required field' }}
          />
          <Controller
            name="billing_info.vat_number"
            control={control}
            render={({ field }) => (
              <TextField
                label="VAT ID (optional)"
                variant="outlined"
                placeholder="Enter the VAT ID"
                error={errors?.billing_info?.vat_number?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="billing_info.billing_address.line1"
            control={control}
            render={({ field }) => (
              <TextField
                label="Address"
                variant="outlined"
                placeholder="Enter the address"
                error={errors?.billing_info?.billing_address?.line1?.message}
                {...field}
              />
            )}
            rules={{ required: 'Required field' }}
          />
          <Controller
            name="billing_info.billing_address.city"
            control={control}
            render={({ field }) => (
              <TextField
                label="City"
                variant="outlined"
                placeholder="Enter the city"
                error={errors?.billing_info?.billing_address?.city?.message}
                {...field}
              />
            )}
            rules={{ required: 'Required field' }}
          />

          <div className="flex gap-x-2">
            <div className="grow">
              <Controller
                name="billing_info.billing_address.state"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="State / Region"
                    variant="outlined"
                    placeholder="Enter the state / region"
                    error={errors?.billing_info?.billing_address?.state?.message}
                    {...field}
                  />
                )}
                rules={{ required: 'Required field' }}
              />
            </div>
            <div className="w-32">
              <Controller
                name="billing_info.billing_address.zip"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Zip"
                    variant="outlined"
                    placeholder="Zip code"
                    error={errors?.billing_info?.billing_address?.zip?.message}
                    {...field}
                  />
                )}
                rules={{ required: 'Required field' }}
              />
            </div>
          </div>

          <Controller
            name="billing_info.billing_address.country"
            control={control}
            render={({ field }) => (
              <TextField
                label="Country"
                variant="outlined"
                placeholder="Enter the country"
                error={errors?.billing_info?.billing_address?.country?.message}
                {...field}
              />
            )}
            rules={{
              required: 'Required field',
              maxLength: {
                value: 2,
                message: 'Use the 2 letter code for the country ',
              },
            }}
          />

          <div className="flex gap-4 space-x-3 justify-between">
            <div>
              <Button
                id="action_filters_clear_all"
                borderless
                className="text-xs font-medium text-blue-500 hover:underline whitespace-nowrap"
                onClick={() => reset()}
                disabled={isFormEmpty}
              >
                Clear
              </Button>
            </div>
            <div className="space-x-3">
              <Button theme="primary" type="button" outline onClick={handleClose}>
                Cancel
              </Button>
              <Button theme="primary" type="submit" disabled={isSubmitting || !formState.isDirty}>
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default BillingDetails;
