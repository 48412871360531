import { Fragment, MouseEventHandler, ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import { CheckboxIcon, CheckboxProps, IconButton, Transition } from '@demandscience/ui';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';
import ChevronUpIcon from '@demandscience/ui/icons/chevron-up';
import MenuItem from './MenuItem';
import { Combobox } from '@headlessui/react';
import CheckboxMenuItem from './CheckboxMenuItem';
import { every, isEmpty, isEqual, map, omit, size } from 'lodash';
import { ExcludableFilter } from 'types';

export type Option<T = any> = {
  avatar?: string;
  description?: string;
  icon?: ReactNode;
  id: string;
  label: string;
  value: T;
};

interface NestedCheckboxMenuItemProps<TMainValue, TNestedValue> {
  expanded?: boolean;
  onChange: (value: TNestedValue[]) => void;
  onToggle?: (option: Option) => void;
  option: Option<TMainValue>;
  options: Option<TNestedValue>[];
  selected: boolean;
  value: TNestedValue[];
}

const NestedCheckboxMenuItem = <
  TMainValue extends ExcludableFilter,
  TNestedValue extends ExcludableFilter,
>({
  onChange,
  option,
  options,
  value: initValue,
  selected,
  onToggle,
  expanded: defaultExpanded = false,
}: NestedCheckboxMenuItemProps<TMainValue, TNestedValue>) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const Icon = expanded ? ChevronUpIcon : ChevronDownIcon;
  const subSelection = size(initValue) > 0;
  const subSelectionInclude = subSelection && every(initValue, ({ exclude }) => !exclude);
  const subSelectionExclude = subSelection && every(initValue, ({ exclude }) => exclude);

  const value =
    isEmpty(initValue) && selected
      ? map(options, ({ value }) => {
          value.exclude = option.value.exclude;
          return value;
        })
      : initValue;

  let theme: CheckboxProps['theme'] = 'gray';
  if (subSelectionInclude || (!subSelection && !option.value.exclude)) {
    theme = 'primary';
  } else if (subSelectionExclude || (!subSelection && option.value.exclude)) {
    theme = 'secondary';
  }

  let checkType: CheckboxProps['checkType'] = option.value.exclude ? 'minus' : 'check';
  if (subSelection) {
    checkType = 'partial';
  }

  const handleClick = useCallback((e: any) => {
    e.preventDefault();

    setExpanded((state) => !state);
  }, []);

  const handleToggle = useCallback(
    (selected: boolean): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        if (selected) {
          e.preventDefault();

          if (onToggle) onToggle(option);
        } else {
          option.value.exclude = true;
        }
      },
    [onToggle, option],
  );

  const handleNestedToggle = useCallback(
    (option: Option<any>) => {
      const newValue = map(value, (v) => {
        if (isEqual(option.value, v)) {
          return option.value.exclude
            ? omit(option.value, 'exclude')
            : { ...option.value, exclude: true };
        }

        return v;
      });

      onChange(newValue);
    },
    [onChange, value],
  );

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <>
      <Combobox.Option value={option.value} as={Fragment}>
        {({ active, selected }) => (
          <MenuItem
            className={overrideTailwindClasses(
              classNames('group cursor-pointer hover:bg-gray-50', {
                'bg-gray-50': !selected && active,
                'bg-gray-100 hover:bg-gray-200': selected && !active,
                'bg-gray-200 hover:bg-gray-200': selected && active,
              }),
            )}
            titleClassName="ellipsis"
            title={option.label}
            subtitleClassName="ellipsis"
            subtitle={option.description}
            leadingContent={
              <CheckboxIcon size={18} theme={theme} checkType={checkType} checked={selected} />
            }
            trailingContent={
              <div className="flex flex-row gap-4">
                {onToggle && (
                  <button
                    className={classNames('text-xs text-gray-500 hidden group-hover:block', {
                      'hover:text-rose-500': !option.value.exclude,
                      'hover:text-emerald-500': option.value.exclude,
                    })}
                    onClick={handleToggle(selected)}
                  >
                    {option.value.exclude && 'Include'}
                    {!option.value.exclude && 'Exclude'}
                  </button>
                )}
                {isEmpty(options) ? null : (
                  <IconButton
                    className="flex -my-1"
                    size="xs"
                    theme="default"
                    onClick={handleClick}
                    Icon={Icon}
                  />
                )}
              </div>
            }
          />
        )}
      </Combobox.Option>
      <Transition.Collapse show={expanded}>
        <Combobox multiple value={value} onChange={onChange}>
          <Combobox.Options static as="div">
            {map(options, (option) => (
              <CheckboxMenuItem
                key={option.id}
                className="pl-8"
                option={option}
                onToggle={handleNestedToggle}
              />
            ))}
          </Combobox.Options>
        </Combobox>
      </Transition.Collapse>
    </>
  );
};

export default NestedCheckboxMenuItem;
