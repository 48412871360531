import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@demandscience/ui';

import { MessagePayload } from 'types';
import { capitalize } from 'lodash';
import { AxiosError } from 'axios';
import MessageInputController from 'components/Input/MessageInputController';
import { UseMutationResult } from '@tanstack/react-query';

interface RequestFormProps {
  mutation: UseMutationResult<void, unknown, MessagePayload, unknown>;
  onCancel: () => void;
  onSuccess: () => void;
}

const RequestForm = ({ mutation, onSuccess, onCancel }: RequestFormProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit, formState, setError } = useForm<MessagePayload>();
  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler<MessagePayload> = async (data) => {
    setApiError(null);

    try {
      await mutation.mutateAsync(data);

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        const { error_field, error } = e.response?.data;

        if (error_field !== undefined) {
          setError(error_field, { type: 'api', message: capitalize(error) });
          return;
        }

        const { message } = e;
        setApiError(message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        <MessageInputController<MessagePayload>
          label="Message to Klarity"
          name="message"
          maxLength={5000}
          control={control}
        />

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-10">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button type="submit" theme="primary" disabled={isSubmitting}>
          Send request
        </Button>
      </div>
    </form>
  );
};

export default RequestForm;
