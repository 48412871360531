import { Modal, Alert, Button } from '@demandscience/ui';
import { HTMLAttributes, useCallback } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AxiosError } from 'axios';
import useAuth from 'components/Auth/useAuth';
import useSnackbar from 'components/Snackbar/useSnackbar';
import useMutateAccount from './useMutateAccount';
import { UpdateEmailPayload } from 'types';

export interface DeleteConfirmationOverlayProps extends HTMLAttributes<HTMLDivElement> {
  isSubmitting?: boolean;
  newEmail?: string;
  onClose: () => void;
  open: boolean;
}

const DeleteConfirmationOverlay = (props: DeleteConfirmationOverlayProps) => {
  const { onClose, isSubmitting, ...rest } = props;

  const { showMessage } = useSnackbar();
  const { signOut } = useAuth();
  const { destroyAccount } = useMutateAccount();

  const { handleSubmit } = useForm<UpdateEmailPayload>({
    mode: 'onChange',
  });

  const handleSignout = useCallback(async () => {
    try {
      await signOut();
    } catch (e: any) {
      const { message } = e;
      showMessage(message, 'error');
    }
  }, [signOut, showMessage]);

  const onSubmit: SubmitHandler<{}> = async () => {
    // Always true for this confirmation modal, in order to delete organization & accounts
    const query = 'with_org_and_siblings=true';

    try {
      await destroyAccount.mutateAsync(query);

      handleClose();

      showMessage('The account and the organization have been successfully deleted', 'success');
      handleSignout();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to delete this account', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      aria-labelledby="create-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      onClose={onClose}
      {...rest}
    >
      <Modal.Title id="create-list-dialog-title" className="flex mb-4">
        Confirm the organization deletion
      </Modal.Title>
      <div className="flex gap-4 flex-col">
        <p className="text-sm text-gray-700">
          You are about to delete your account and the organization while there are still active
          users.
        </p>

        <Alert severity="warning">
          <>
            <Alert.Header>
              <Alert.Title>Important</Alert.Title>
            </Alert.Header>
            <p className="text-sm mt-1 text-gray-700 ml-9">
              Are you certain about deleting your account, the organization and all its accounts?
              Since that there are still active users in the organization you can assign a new
              manager for your team before deleting your account. Keep in mind that this action is
              irreversible, and you&apos;ll lose all your saved data and credits. If you&apos;re
              sure, click the Delete button below.
            </p>
          </>
        </Alert>
        <div className="flex items-center justify-end mt-2 gap-2">
          <Button theme="primary" type="button" outline onClick={handleClose}>
            Cancel
          </Button>

          <Button onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
            Delete organization
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationOverlay;
