import { Badge } from '@demandscience/ui';
import { filter, isEmpty, map, orderBy } from 'lodash';
import { Organization, Subscription as SubscriptionType } from 'types';
import { differenceInDays, formatDistanceToNow, parseISO } from 'date-fns';
import classNames from 'classnames';

interface SubscriptionsProps {
  organization?: Organization;
}

const Subscription = ({ credits_left, ended_at }: SubscriptionType) => {
  const endedAt = ended_at && parseISO(ended_at);
  const creditsAlert = credits_left > 0 && credits_left <= 20;
  const expiryAlert = credits_left > 0 && endedAt && differenceInDays(endedAt, new Date()) <= 7;
  const inAlert = creditsAlert || expiryAlert;

  return (
    <div
      className={classNames('px-4 py-2 flex flex-row items-center gap-1 rounded', {
        'bg-gray-50': !inAlert,
        'bg-rose-50': inAlert,
      })}
    >
      <span className="text-sm">{credits_left.toLocaleString()} credits</span>
      {endedAt && (
        <>
          <span className="text-sm">expiring in</span>
          <Badge size="md" color={inAlert ? 'rose' : 'gray'}>
            {formatDistanceToNow(endedAt)}
          </Badge>
        </>
      )}
    </div>
  );
};

const Subscriptions = ({ organization }: SubscriptionsProps) => {
  const subscriptions = orderBy(
    filter(organization?.subscriptions, ({ credits_left }) => credits_left > 0),
    'ended_at',
  );

  if (isEmpty(subscriptions)) {
    return null;
  }

  return (
    <div className="space-y-2">
      {map(subscriptions, (subscription) => (
        <Subscription key={subscription.started_at} {...subscription} />
      ))}
    </div>
  );
};

export default Subscriptions;
