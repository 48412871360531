import CompanyNameFilter from 'components/Filters/CompanyNameFilter';
import FiltersProvider from 'components/Filters/FiltersProvider';
import SearchProvider from 'components/Search/SearchProvider';
import { Button, Link } from '@demandscience/ui';
import UsersIcon from '@demandscience/ui/icons/users';
import { useCallback, useState } from 'react';
import { Filters } from 'types';
import ContactsCount from './ContactsCount';
import FinishButton from './FinishButton';
import Question from './Question';

interface OnboardingNo3Props {
  onFinish: (filters: Filters) => void;
  onSkip: () => void;
}

const OnboardingNo3 = ({ onFinish, onSkip }: OnboardingNo3Props) => {
  const [step, setStep] = useState(0);

  const handleNextClick = useCallback(() => {
    setStep((state) => state + 1);
  }, []);

  if (step === 0) {
    return (
      <div className="flex flex-col h-full items-center justify-center gap-8">
        <UsersIcon className="text-gray-700 stroke-1" size={80} />
        <div className="text-center text-gray-700">
          <div className="text-xl font-semibold whitespace-pre-line mb-2">
            {'We just need you to indicate your\ntarget accounts to get started'}
          </div>
        </div>
        <div className="flex flex-col items-center gap-2">
          <Button theme="primary" onClick={handleNextClick}>
            Start
          </Button>
          <Link as="button" onClick={onSkip}>
            Skip
          </Link>
        </div>
      </div>
    );
  }

  return (
    <FiltersProvider>
      <SearchProvider ignoreEmptyFilters>
        <div className="flex flex-col h-full">
          <div className="grow w-full h-full flex items-center justify-center">
            <div className="h-48 w-full max-w-lg">
              {step === 1 && (
                <Question
                  questionNo={1}
                  questionsTotal={1}
                  question="What are your target accounts?"
                  button={<FinishButton onClick={onFinish}>Show contacts</FinishButton>}
                >
                  <CompanyNameFilter variant="plain" container={{ current: null }} />
                </Question>
              )}
            </div>
          </div>
          <ContactsCount display="contacts" hideTotal />
        </div>
      </SearchProvider>
    </FiltersProvider>
  );
};

export default OnboardingNo3;
