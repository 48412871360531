import { Button, ButtonOwnProps } from '@demandscience/ui';
import useLinkedInExtension from './useLinkedInExtension';

const InstallButton = (props: ButtonOwnProps) => {
  const { extensionId } = useLinkedInExtension();

  return (
    <Button
      theme="primary"
      size="md"
      as="a"
      {...props}
      href={`https://chrome.google.com/webstore/detail/${extensionId}`}
      target="_blank"
      rel="noreferrer"
    />
  );
};

export default InstallButton;
