import { Contact } from 'types';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';

interface ContactNameProps {
  className?: string;
  row: Contact;
}

const ContactName = ({ row, className }: ContactNameProps) => {
  const { first_name: fName, last_name: lName } = row;
  const name = `${fName} ${lName}`;

  return (
    <Paragraph className={overrideTailwindClasses(classNames('font-semibold', className))}>
      {name}
    </Paragraph>
  );
};

export default ContactName;
