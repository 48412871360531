import { memo, useState } from 'react';
import { Filter, FilterName, ResultsView } from 'types';
import { CompareSearch } from 'types/compare';
import DataProfileColumn from './DataProfileColumn';
import { getCompanyFilters } from 'components/Filters/utils';
import useFilters from 'components/Filters/useFilters';
import { isUndefined, omit } from 'lodash';
import useCompare from 'components/Filters/useCompare';
import { Button, RadioList } from '@demandscience/ui';
import classNames from 'classnames';
import TrashIcon from '@demandscience/ui/icons/trash-2';
import { stopPropagation } from 'utils/event';

interface DataProfileColumnsProps {
  compareSearchs: CompareSearch[];
  id?: string;
  iscontactRendered: boolean;
  modules?: {
    type: {
      id: string;
      name: string;
    };
    visual: string;
  }[];
  onTabChange: (tab: ResultsView) => void;
}

const DataProfileColumns = memo(
  ({ id, compareSearchs, onTabChange, modules, iscontactRendered }: DataProfileColumnsProps) => {
    const { filters, companyFilters, setFilter, setFilters, resetHistory } = useFilters();
    const { setCompareSearchList } = useCompare();

    const [radioValue, setValue] = useState(compareSearchs[0].value);

    const handleRadioChange = (val: string) => {
      setValue(val);
    };

    const handleChangeSearch = (index: number) => () => {
      setCompareSearchList((prevSearches) =>
        prevSearches.map((search, idx) => {
          if (idx === index) {
            return { ...search, isActive: true };
          }
          return { ...search, isActive: false };
        }),
      );
      setFilters(compareSearchs[index].filters);
      resetHistory(compareSearchs[index].filters);
    };

    const handleDelete = (index: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
      stopPropagation(event);

      let isRemoveActivedSearch = compareSearchs[index].isActive;
      const newCompareSearchs: CompareSearch[] = [];
      compareSearchs.forEach((search, idx) => {
        if (idx !== index) {
          newCompareSearchs.push({ ...search });
        }
      });

      if (isRemoveActivedSearch) {
        newCompareSearchs[0].isActive = true;
      }

      setCompareSearchList(newCompareSearchs);
    };

    return (
      <RadioList value={radioValue} onChange={handleRadioChange} options={compareSearchs}>
        <div className="grid grid-flow-col gap-4 overflow-auto">
          {compareSearchs.map((search, idx) => {
            const searchCompanyFilters = getCompanyFilters(search.filters);

            const setSearchFilter = (idx: number) => (name: FilterName, value?: Filter) => {
              let newFilters;

              if (isUndefined(value)) {
                newFilters = omit(search.filters, name);
              } else {
                newFilters = { ...search.filters, [name]: value };
              }

              const newCompareSearchs = [...compareSearchs];
              newCompareSearchs[idx] = { ...search, filters: newFilters };

              setCompareSearchList(newCompareSearchs);
            };

            return (
              <div key={`${id}_${idx}`} className="pt-4 min-w-[24rem]">
                <RadioList.Option
                  key={search.value}
                  option={search}
                  onClick={handleChangeSearch(idx)}
                  className={classNames(
                    'flex flex-row w-full justify-between bg-white border border-white rounded-lg p-1',
                  )}
                >
                  <div className="flex flex-row text-sm flex-grow">
                    <RadioList.Icon className="mr-2" theme="primary" checked={search.isActive} />
                    <RadioList.Label className="text-md">{search.label}</RadioList.Label>
                  </div>
                  <div className="flex items-center">
                    <Button
                      className="text-gray-200 hover:text-red-400"
                      onClick={handleDelete(idx)}
                      size="md"
                      aria-label="Remove search"
                      theme="white"
                    >
                      <TrashIcon size={18} />
                    </Button>
                  </div>
                </RadioList.Option>
                <DataProfileColumn
                  id={`${id}_${idx}`}
                  onTabChange={onTabChange}
                  filters={search.isActive ? filters : search.filters}
                  companyFilters={search.isActive ? companyFilters : searchCompanyFilters}
                  setFilter={search.isActive ? setFilter : setSearchFilter(idx)}
                  setFilters={search.isActive ? setFilters : undefined}
                  modules={modules}
                  iscontactRendered={iscontactRendered}
                />
              </div>
            );
          })}
        </div>
      </RadioList>
    );
  },
);

DataProfileColumns.displayName = 'DataProfileColumns';

export default DataProfileColumns;
