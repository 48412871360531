import { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { Button, TextField } from '@demandscience/ui';

import Alert from 'components/Alert';
import useAuth from './useAuth';
import { Account } from 'types';

type NewPasswordRequiredFormValues = {
  password: string;
};

export interface NewPasswordRequiredProps {
  onSignin: (user: Account) => void;
}

const NewPasswordRequired: React.FC<NewPasswordRequiredProps> = ({ onSignin }) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { completeNewPassword } = useAuth();
  const { control, handleSubmit, formState, setError } = useForm({
    defaultValues: { password: '' },
  });
  const { isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<NewPasswordRequiredFormValues> = async (data) => {
    setApiError(null);

    try {
      const { password } = data;
      const user = await completeNewPassword(password);

      if (onSignin) onSignin(user);
    } catch (e: any) {
      const { message, name } = e;

      if (name === 'InvalidPasswordException') {
        setError('password', { type: 'api', message });
      } else {
        setApiError(message);
      }
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="space-y-4">
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              label="New password"
              placeholder="Enter a new password"
              variant="outlined"
              size="lg"
              required
              type="password"
              autoComplete="new-password"
              error={errors.password?.message}
              {...field}
            />
          )}
          rules={{ required: 'Required field' }}
        />
        {apiError && <Alert message={apiError} />}
      </div>

      <Button className="w-full mt-6" size="lg" type="submit" disabled={isSubmitting}>
        Confirm
      </Button>
    </form>
  );
};

export default NewPasswordRequired;
