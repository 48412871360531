import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@demandscience/ui';
import useOrganization from 'components/Organization/useOrganization';
import CreditsAndSubscriptionsInfo from 'components/Organization/CreditsAndSubscriptionsInfo';
import MembersCountBadge from 'components/Organization/MembersCountBadge';

const AccountInfo = () => {
  const { isError } = useOrganization();
  let children;

  if (isError) {
    children = (
      <div className="text-sm text-error-500">
        Unable to load account info, please try again later.
      </div>
    );
  } else {
    children = (
      <div className="border rounded-lg divide-y flex flex-col">
        <div className="px-6 py-4">
          <CreditsAndSubscriptionsInfo
            title="Credits"
            titleClassName="text-base"
            ButtonProps={{ className: 'w-full' }}
          />
        </div>
        <div className="px-6 py-4">
          <div className="flex-1">
            Team <MembersCountBadge color="gray" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center mb-4">
        <div className="text-lg text-gray-700 font-medium">Account</div>
        <Link as={RouterLink} to="/manage">
          Manage
        </Link>
      </div>
      {children}
    </div>
  );
};

export default AccountInfo;
