import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createBounce, deleteBounce, uploadBounceFile } from 'api/bounces';
import { defer } from 'lodash';

const requestUniqueId = ['bounces'];

const useMutateBouncesList = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();

  const invalidateQuery = () => queryClient.invalidateQueries(requestUniqueId);

  const create = useMutation(createBounce, {
    onSuccess: () => invalidateQuery(),
    ...options,
  });

  const destroy = useMutation(deleteBounce, {
    onSuccess: () => {
      // query invalidation is deferred to prevent re-fetching of queries for just deleted list
      // that happens when list is deleted from details page
      defer(invalidateQuery);
    },
    ...options,
  });

  const upload = useMutation(uploadBounceFile, options);

  return {
    destroy,
    upload,
    create,
  };
};

export default useMutateBouncesList;
