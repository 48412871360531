import { useState } from 'react';
import { Modal, ModalProps } from '@demandscience/ui';
import { Filters } from 'types/filters';
import { stopPropagation } from 'utils/event';

import SavePersonaForm from './SavePersonaForm';

interface SavePersonaOverlayProps extends Omit<ModalProps, 'children'> {
  filters: Filters;
  onClose: () => void;
  open: boolean;
}

const SavePersonaOverlay = (props: SavePersonaOverlayProps) => {
  const { filters, onClose } = props;
  const [selected, setSelected] = useState('create');

  return (
    <div onClick={stopPropagation}>
      <Modal
        aria-labelledby="create-list-dialog-title"
        className="max-w-md sm:max-w-md overflow-visible"
        onKeyDown={stopPropagation}
        {...props}
      >
        <Modal.Title id="create-list-dialog-title" className="flex mb-4">
          {selected === 'create' ? 'Save persona' : 'Update persona'}
        </Modal.Title>
        <div>
          <SavePersonaForm
            filters={filters}
            onSuccess={onClose}
            onCancel={onClose}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SavePersonaOverlay;
