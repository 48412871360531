export enum DataModuleType {
  Bubble = 'bubble',
  Custom = 'custom',
  List = 'list',
  Treemap = 'treemap',
  WordCloud = 'word_count',
}

export enum DataModule {
  CompaniesList = 'companies_list',
  CompanyHeadcount = 'company_headcount',
  CompanyIndustry = 'company_industry',
  CompanyLocation = 'company_location',
  CompanyRevenue = 'company_revenue',
  ContactDepartment = 'contact_department',
  ContactJobArea = 'contact_job_area',
  ContactJobLevel = 'contact_job_level',
  ContactsList = 'contacts_list',
  Overview = 'search_counters',
}

export const moduleColumns: Record<DataModule, string> = {
  [DataModule.CompaniesList]: 'Companies list',
  [DataModule.CompanyHeadcount]: 'Company headcount',
  [DataModule.CompanyIndustry]: 'Company industry',
  [DataModule.CompanyLocation]: 'Company location',
  [DataModule.CompanyRevenue]: 'Company revenue',
  [DataModule.ContactDepartment]: 'Contact department',
  [DataModule.ContactJobArea]: 'Contact job area',
  [DataModule.ContactJobLevel]: 'Contact job level',
  [DataModule.ContactsList]: 'Contacts list location',
  [DataModule.Overview]: 'Overview',
};

export const allModuleColumns = [
  DataModule.CompaniesList,
  DataModule.CompanyHeadcount,
  DataModule.CompanyIndustry,
  DataModule.CompanyLocation,
  DataModule.CompanyRevenue,
  DataModule.ContactDepartment,
  DataModule.ContactJobArea,
  DataModule.ContactJobLevel,
  DataModule.ContactsList,
  DataModule.Overview,
];

export const defaultModuleColumns = [
  DataModule.Overview,
  DataModule.ContactDepartment,
  DataModule.CompanyHeadcount,
  DataModule.ContactJobLevel,
  DataModule.CompanyLocation,
  DataModule.CompanyIndustry,
];

export type ModuleType = {
  id: string;
  name: string;
};

export type DataProfileTypes = ModuleType[];

export type DataModulesType = {
  type: {
    id: string;
    name: string;
  };
  visual: string;
};

export type DataModulesCustomization = {
  modules: DataModulesType[] | undefined;
  name: string | undefined;
};

export type UpdateDataProfileViews = {
  id?: string | undefined;
  payload: DataModulesCustomization;
};

export type Views = {
  created_at: string;
  id: string;
  modules: [
    {
      type: {
        id: string;
        name: string;
      };
      visual: string;
    },
  ];
  name: string;
  updated_at: string;
};

export type AllViews = Views[];
