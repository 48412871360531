import { useCallback, useState } from 'react';

import { Button } from '@demandscience/ui';
import { Member } from 'types';

import useMutateMember from './useMutateMember';
import { AxiosError } from 'axios';

interface ReactivateFormProps {
  accountsAvailable: number;
  member: Member;
  onCancel: () => void;
  onSuccess: () => void;
}

const ReactivateForm: React.FC<ReactivateFormProps> = ({
  member,
  accountsAvailable,
  onSuccess,
  onCancel,
}) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { reactivate } = useMutateMember();

  const handleReactivate = useCallback(async () => {
    setApiError(null);

    try {
      await reactivate.mutateAsync(member.username);

      onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || 'Unable to reactivate a member account');
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  }, [member.username, onSuccess, reactivate]);

  return (
    <div>
      {apiError && <div className="text-error-500 text-center">{apiError}</div>}

      <div className="flex justify-end space-x-2 mt-4">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button
          theme="primary"
          onClick={handleReactivate}
          type="button"
          disabled={reactivate.isLoading || accountsAvailable === 0}
        >
          Reactivate
        </Button>
      </div>
    </div>
  );
};

export default ReactivateForm;
