import { createContext } from 'react';
import { GptModel, Message } from 'types/aissistant';

export interface ChatPromptContextInterface {
  isLoading: boolean;
  isSuccess: boolean;
  model: GptModel | undefined;
  prompt: Array<Message> | undefined;
  promptId: string | undefined;
}

const ChatPromptContext = createContext<ChatPromptContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  ChatPromptContext.displayName = 'ChatPromptContext';
}

export default ChatPromptContext;
