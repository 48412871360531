import { useContext } from 'react';

import ModulesCustomizationContext, {
  ModulesCustomizationContextInterface,
} from './ModulesCustomizationContext';

const useModulesCustomization = () =>
  useContext(ModulesCustomizationContext) as ModulesCustomizationContextInterface;

export default useModulesCustomization;
