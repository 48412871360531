import { useResizeDetector } from 'react-resize-detector';
import DataGridComponent, { DataGridProps } from './DataGridComponent';
import { RowLayout } from 'types';
import SearchRecordsSelectionProvider from './Selection/SearchRecordsSelectionProvider';

const DataGrid = ({ id, ...props }: DataGridProps) => {
  const { width, ref } = useResizeDetector();

  return (
    <SearchRecordsSelectionProvider kind={props.kind}>
      <div id={id} className="h-full overflow-hidden" ref={ref}>
        {width !== undefined && (
          <DataGridComponent
            {...props}
            rowLayout={width <= 900 ? RowLayout.Narrow : RowLayout.Wide}
          />
        )}
      </div>
    </SearchRecordsSelectionProvider>
  );
};

export default DataGrid;
