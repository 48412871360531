import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { PageViewEvent } from 'types/tracking';

interface TrackRouteProps {
  children: React.ReactElement;
  title: string;
}

const TrackRoute: React.FC<TrackRouteProps> = ({ title, children }) => {
  const { pathname } = useLocation();
  const { trackEvent } = useTracking<PageViewEvent>({ type: 'event', event: 'page_view', title });
  const pageTitle = `${title} - Klarity`;

  // running track event in an effect to prevent event firing multiple times if route is
  // rendered again on the same path, this can happen e.g. on the signout when on home page
  useEffect(() => {
    trackEvent({ path: pathname });
  }, [pathname, trackEvent]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </>
  );
};

export default TrackRoute;
