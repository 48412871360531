import { useCallback, useEffect, useState } from 'react';
import ListCombobox from './ListCombobox';
import { Button } from '@demandscience/ui';
import useMutateList from './useMutateList';
import PlusIcon from '@demandscience/ui/icons/plus';
import CreateListControl from './CreateListControl';
import Alert from 'components/Alert';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ExportToList,
  ListRecord,
  ListRecordsAction,
  ListRecordsLimit,
  ModelType,
  Show,
  UpdateListContentPayload,
  Visibility,
} from 'types';
import { AxiosError } from 'axios';
import { getRecordsLabel } from 'utils/string';
import { AddToListEvent } from 'types/tracking';
import { useTracking } from 'react-tracking';
import useActions from 'components/ListRecords/useActions';

interface ExportToListFormProps extends ExportToList {
  count: number;
  onCancel: () => void;
  onSuccess: () => void;
}

const ExportToListForm = ({
  type,
  filters,
  sort,
  advanced_selection,
  constraints,
  onSuccess,
  onCancel,
  count,
}: ExportToListFormProps) => {
  const actions = useActions();
  const { trackEvent } = useTracking<AddToListEvent>({ type: 'event', event: 'add_to_list' });
  const [selectedList, setSelectedList] = useState<
    { id: string; visibility: Visibility } | undefined
  >();
  const [overLimit, setOverLimit] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const limit = ListRecordsLimit[ModelType.Company];

  const { updateContent } = useMutateList();

  const handleListSelection = useCallback((id: string, list: ListRecord) => {
    setSelectedList({ id, visibility: list.visibility });
  }, []);

  const handleListCreated = useCallback((id: string, visibility: Visibility) => {
    setSelectedList({ id, visibility });
  }, []);

  const onSubmit: SubmitHandler<{}> = async () => {
    setApiError(null);

    if (!selectedList || !filters) {
      return;
    }

    const payload: UpdateListContentPayload = {
      source_list_id: selectedList.id,
      method: ListRecordsAction.Add,
      filters,
      sort,
      advanced_selection,
      constraints,
    };

    try {
      await updateContent.mutateAsync(payload);

      onSuccess();

      trackEvent({ visibility: selectedList.visibility, recordType: type, numberRecords: count });
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.error_code === 'limit_exceeded') {
          setOverLimit(true);
        } else {
          setApiError(e.response?.data?.error || 'Unable to update list content');
        }
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  useEffect(() => {
    setOverLimit(false);
    setApiError(null);
  }, [selectedList]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        {overLimit && (
          <Alert
            severity="warning"
            message={`A list can contain a max of ${limit.toLocaleString()} ${getRecordsLabel(
              type,
            )}. Your selection is adding too many new contacts to this list. Select less contacts or choose another list.`}
          />
        )}
        <ListCombobox
          value={selectedList?.id}
          onChange={handleListSelection}
          records_type={type}
          show={Show.Editable}
          showCounts
          exclude={actions ? [actions.listId] : undefined}
        />
        <CreateListControl
          as={Button}
          theme="primary"
          borderless
          size="md"
          leftIcon={<PlusIcon className="w-5 h-5" />}
          type={type}
          onSuccess={handleListCreated}
        >
          Create new list
        </CreateListControl>

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>
      <div className="flex justify-end space-x-2 mt-7">
        {!overLimit && (
          <>
            <Button type="button" onClick={onCancel} borderless size="md">
              Cancel
            </Button>
            <Button
              type="submit"
              theme="primary"
              size="md"
              disabled={isSubmitting || !selectedList}
            >
              Add
            </Button>
          </>
        )}
        {overLimit && (
          <Button type="button" theme="primary" size="md" onClick={onCancel}>
            Change selection
          </Button>
        )}
      </div>
    </form>
  );
};

export default ExportToListForm;
