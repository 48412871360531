import Section, { SectionProps } from 'components/Layout/Section';
import useOrganization from './useOrganization';
import CreditsUsage from './CreditsUsage';
import { ButtonOwnProps } from '@demandscience/ui';
import Subscriptions from './Subscriptions';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import BuyCreditsButton from './BuyCreditsButton';

interface CreditsAndSubscriptionsInfoProps extends SectionProps {
  ButtonProps?: Omit<ButtonOwnProps, 'onClick'>;
}

const CreditsAndSubscriptionsInfo = ({
  ButtonProps,
  ...props
}: CreditsAndSubscriptionsInfoProps) => {
  const { data: organization, isLoading } = useOrganization();

  if (isLoading) {
    return (
      <Section {...props}>
        <div className="flex flex-row justify-between">
          <div className="animate-pulse bg-slate-200 rounded-md w-10 h-5" />
          <div className="animate-pulse bg-slate-200 rounded-md w-20 h-5" />
        </div>
        <div className="py-2">
          <div className="animate-pulse bg-slate-200 rounded-md h-2" />
        </div>
        <div className="pt-4">
          <div
            className={overrideTailwindClasses(
              classNames('animate-pulse bg-slate-200 rounded-md h-9 w-28', ButtonProps?.className),
            )}
          />
        </div>
      </Section>
    );
  }

  if (!organization) {
    return null;
  }

  return (
    <Section {...props}>
      <div className="space-y-4">
        <CreditsUsage organization={organization} />
        <Subscriptions organization={organization} />
        <BuyCreditsButton {...ButtonProps} />
      </div>
    </Section>
  );
};

export default CreditsAndSubscriptionsInfo;
