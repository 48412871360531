import useList from './useList';
import { useEffect } from 'react';
import { Button } from '@demandscience/ui';
import useMutateList from './useMutateList';
import useAuth from 'components/Auth/useAuth';
import ListFormFields from './ListFormFields';
import useApiValidation from 'hooks/useApiValidation';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Visibility, UpdateListPayload, EditListFormValues } from 'types';
import { filter, map, some } from 'lodash';

interface EditListFormProps extends EditListFormValues {
  onCancel: () => void;
  onDelete: () => void;
  onSuccess: () => void;
}

const EditListForm = ({ onSuccess, onCancel, onDelete, ...props }: EditListFormProps) => {
  const { id, name, description, visibility } = props;

  const { data: list } = useList(id ?? '');

  const { control, handleSubmit, formState, watch, setError, setValue } =
    useForm<EditListFormValues>({
      defaultValues: {
        name,
        description: description ?? '',
        visibility,
      },
    });

  const { isSubmitting } = formState;

  const { apiError, setApiError, handleApiValidation } =
    useApiValidation<EditListFormValues>(setError);

  const { update } = useMutateList();

  const { user } = useAuth();

  useEffect(() => {
    setValue(
      'members',
      list?.shared_with?.map(({ username: value, email, name: label = email }) => ({
        value,
        label,
      })),
    );
  }, [list, setValue]);

  const buildPayload = ({ members, notification_message, ...data }: EditListFormValues) => {
    const visibility = data.visibility;

    let payload: UpdateListPayload = { ...data, id };
    let share_with = [];

    if (visibility === Visibility.Restricted) {
      share_with = map(
        filter(members, ({ value }) => !some(list?.shared_with, { username: value })),
        'value',
      );

      const unshare_with = map(
        filter(list?.shared_with, ({ username: value }) => !some(members, { value })),
        'username',
      );

      payload = { share_with, unshare_with, ...payload };
    }

    if (
      notification_message &&
      (visibility === Visibility.Public ||
        (visibility === Visibility.Restricted && share_with?.length > 0))
    ) {
      payload = { notification_message, ...payload };
    }

    return payload;
  };

  const onSubmit: SubmitHandler<EditListFormValues> = async (data) => {
    setApiError(null);

    const payload: UpdateListPayload = buildPayload(data);

    try {
      await update.mutateAsync(payload);
      onSuccess();
    } catch (e: any) {
      handleApiValidation(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        <ListFormFields
          control={control}
          formState={formState}
          watch={watch}
          setValue={setValue}
          editingSharedPublicList={
            list?.visibility === Visibility.Public && user?.username !== list?.owner.username
          }
          edit
        />

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-between mt-8">
        <div>
          <Button borderless theme="error" type="button" onClick={onDelete}>
            Delete list
          </Button>
        </div>
        <div className="flex space-x-2">
          <Button borderless type="button" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" theme="primary" disabled={isSubmitting}>
            Update list
          </Button>
        </div>
      </div>
    </form>
  );
};

export default EditListForm;
