import { useCallback, useEffect, useState } from 'react';
import DetailsSidebar from 'components/Members/DetailsSidebar';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

type Params = {
  id: string;
};

interface LocationState {
  edit?: boolean;
}

const Member = () => {
  const [open, setOpen] = useState(false);
  const { id } = useParams<keyof Params>() as Params;
  const { state } = useLocation();
  const navigate = useNavigate();
  const { edit = false } = (state as LocationState) || {};

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleNavigate = useCallback(() => {
    navigate('..');
  }, [navigate]);

  useEffect(() => {
    setOpen(true);

    return () => {
      setOpen(false);
    };
  }, []);

  return (
    <DetailsSidebar
      open={open}
      onClose={handleClose}
      afterLeave={handleNavigate}
      id={id}
      edit={edit}
    />
  );
};

export default Member;
