import { useQuery } from '@tanstack/react-query';

import { fetchFilterData } from 'api/data';
import map from 'lodash/map';
import { FilterDataFieldName, IndustryByCategory, SubIndustry } from 'types';
import { OptionWithNestedOptions } from '../types';

const fetchOptions = async () => {
  const industries = await fetchFilterData(FilterDataFieldName.Industries);
  const data = map(industries, ({ sub_category, category }) => {
    let option: OptionWithNestedOptions<IndustryByCategory, SubIndustry> = {
      id: category,
      value: { category },
      label: category,
    };

    if (sub_category) {
      option = {
        ...option,
        options: map(sub_category, ({ sub_category }) => ({
          id: sub_category,
          value: { sub_category },
          label: sub_category,
        })),
      };
    }

    return option;
  });

  return data;
};

const useIndustryOptions = (options?: Record<string, any>) => {
  const query = useQuery(['filterOptions', FilterDataFieldName.Industries], fetchOptions, {
    refetchOnWindowFocus: false, // no need to refetch on window focus
    staleTime: Infinity,
    ...options,
  });

  return query;
};

export default useIndustryOptions;
