import { ReactNode } from 'react';
import classNames from 'classnames';
import MinusIcon from '@demandscience/ui/icons/minus';
import { Drawer as BaseDrawer, DrawerRootProps, IconButton, Progress } from '@demandscience/ui';

interface DrawerProps extends Omit<DrawerRootProps, 'title'> {
  headingContent?: ReactNode;
  loading?: boolean;
  title: ReactNode;
}

const Drawer = ({ title, loading, headingContent, children, ...props }: DrawerProps) => {
  const { onClose } = props;

  const removeInertAttribute = () => {
    const root = document.getElementById('root') as HTMLElement;
    if (root) {
      root.removeAttribute('inert');
      root.removeAttribute('aria-hidden');
    }
  };

  return (
    <BaseDrawer
      anchor="right"
      hideBackdrop
      className={classNames('py-0 ', {
        'w-[400px]': !props.anchor || props.anchor === 'right',
        'pl-[72px] w-[472px]': props.anchor === 'left',
      })}
      rootClassName={classNames({
        'z-10': props.anchor === 'right',
        'z-0': props.anchor === 'left',
      })}
      afterEnter={removeInertAttribute}
      {...props}
    >
      <div className="border-b">
        <div className="px-6 py-2 flex flex-row items-center justify-between bg-gray-50">
          <div className="text-sm font-medium text-gray-700 flex-1">{title}</div>
          {headingContent}
          <div className="flex-1 text-right">
            <IconButton
              className="hover:bg-gray-100"
              aria-label="Close"
              size="md"
              theme="default"
              Icon={MinusIcon}
              onClick={onClose}
            />
          </div>
        </div>
        <Progress
          theme="primary"
          className={classNames('bg-white h-[1px] invisible', { visible: loading })}
        />
      </div>
      {children}
    </BaseDrawer>
  );
};

export default Drawer;
