import { useState, memo, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

import {
  formatChartLabelNumberAbbreviation,
  formatNumberWCommaRoundOff,
} from 'utils/FormatChartValues';
import { maxDataValueOnAxisForDisplay } from './ChartHelper';

const extractNumbers = (labels) => {
  return labels.map((label) => {
    const parts = label.split('-');
    return Number(parts[1]);
  });
};
function LineChart(props) {
  const { setClickedLabelsIndex, clickedLabels: clickedLabelsindex, categories } = props;

  const maxOfArray = (array) => Math.max.apply(Math, array);

  const maxValueOfAllSeries = () => {
    if (!props.data) return 0;
    return maxOfArray(props.data.map((series) => maxOfArray(series.data)));
  };

  const maxDataValueOnYAxisForDisplay = () => maxDataValueOnAxisForDisplay(maxValueOfAllSeries());

  const [clickedLabels, setClickedLabels] = useState([]);

  useEffect(() => {
    setClickedLabels(clickedLabelsindex.map((index) => `${categories[index]}-${index}`));
  }, [categories, clickedLabelsindex]);

  const handlePointClick = (xValue, index) => {
    const pointIdentifier = `${xValue}-${index}`;
    let newClickedLabels = [...clickedLabels];
    if (newClickedLabels.includes(pointIdentifier)) {
      newClickedLabels = newClickedLabels.filter((label) => label !== pointIdentifier);
    } else {
      if (newClickedLabels.length === 2) {
        newClickedLabels.shift();
      }
      newClickedLabels.push(pointIdentifier);
    }
    setClickedLabels(newClickedLabels);
    setClickedLabelsIndex(extractNumbers(newClickedLabels));
  };

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      fontFamily: 'Metropolis',
      zoom: {
        enabled: false,
      },
      events: {
        click: (event, chartContext, config) => {
          if (config && config.dataPointIndex !== -1) {
            const xValue = categories[config.dataPointIndex];
            handlePointClick(xValue, config.dataPointIndex);
          }
        },
      },
    },
    title: {
      text: props.title,
      align: 'left',
      style: {
        fontSize: '12px',
        fontWeight: 600,
      },
    },
    xaxis: {
      title: {
        text: '',
      },
      type: 'category',
      categories: categories,
      labels: {
        style: {
          colors: categories.map((value, index) =>
            clickedLabels.includes(`${value}-${index}`) ? 'blue' : '#64748B',
          ),
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          textAlign: 'left',
        },
      },
    },
    yaxis: {
      title: {
        text: '',
      },
      labels: {
        formatter: function (value) {
          return parseInt(value) === value ? formatChartLabelNumberAbbreviation(value, 1) : '';
        },
        style: {
          colors: '#64748B',
          fontFamily: 'Inter',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '16px',
          textAlign: 'left',
        },
      },
      min: 0,
      max: maxDataValueOnYAxisForDisplay(),
      tickAmount: 5,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'left',
      fontSize: '12px',
      fontWeight: 500,
      fontFamily: 'Inter',
      lineHeight: '16px',
      textAlign: 'left',
      showForSingleSeries: true,
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (value) {
          return formatNumberWCommaRoundOff(value, 1);
        },
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 7,
      },
    },
  };
  return (
    <div>
      <ReactApexChart
        id={`line-chart-${props.id}`}
        type="line"
        options={options}
        series={props.data}
        width={props.width}
        height={260}
      />
    </div>
  );
}

LineChart.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
  width: PropTypes.string,
  categories: PropTypes.array,
  setClickedLabelsIndex: PropTypes.func,
  clickedLabels: PropTypes.array,
};

LineChart.defaultProps = {
  id: '',
  title: '',
  data: [],
  width: '100%',
  categories: [],
};

export default memo(LineChart);
