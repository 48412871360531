import { Button, Modal } from '@demandscience/ui';
import BookmarkSettingsForm from './BookmarkSettingsForm';
import { AutoUpdate, Visibility } from 'types';
import { SubmitHandler, useForm } from 'react-hook-form';
import useApiValidation from 'hooks/useApiValidation';
import { AxiosError } from 'axios';
import useMutateBookMark from '../useMutateBookMark';
import { BookMarkFormValues } from 'types/BookMark';
import { useCallback, useEffect, useState } from 'react';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { useNavigate } from 'react-router-dom';
interface BookmarkSettingsProps {
  automated_updates: AutoUpdate | null;
  id: string;
  name: string;
  onClose: () => void;
  open: boolean;
  visibility: Visibility;
}

export const BookmarkSettings = ({
  open,
  onClose,
  id,
  automated_updates,
  name,
  visibility,
}: BookmarkSettingsProps) => {
  const { control, handleSubmit, formState, watch, setValue, setError, reset } =
    useForm<BookMarkFormValues>({
      defaultValues: {
        name,
        visibility,
      },
    });
  useEffect(() => {
    setValue('visibility', visibility);
    setValue('name', name);
  }, [visibility, setValue, name]);
  const { apiError, setApiError } = useApiValidation<BookMarkFormValues>(setError);

  const navigate = useNavigate();

  const { update, deleteSingleBookmark } = useMutateBookMark();

  const { showMessage } = useSnackbar();

  const handleClose = () => {
    onClose();
    setApiError(null);
    reset();
  };

  const onSubmit: SubmitHandler<BookMarkFormValues> = async (data) => {
    setApiError(null);
    const { visibility } = data;

    if (visibility === Visibility.Restricted) {
      const { members, ...rest } = data;

      const share_with = members?.map(({ value }) => value);

      data = { share_with, ...rest };
    }

    try {
      await update.mutateAsync({ ...data, id, automated_updates });
      setApiError(null);
      reset();
      onClose();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };
  const handleDelete = useCallback(async () => {
    try {
      await deleteSingleBookmark.mutateAsync(id);
      navigate('/search/bookmark');
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to delete Bookmark', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
    onClose();
  }, [deleteSingleBookmark, id, navigate, onClose, showMessage]);

  const [openDelete, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const closeDeletePopup = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="create-list-dialog-title"
        className="max-w-md sm:max-w-md overflow-visible"
        rootClassName="z-30"
        open={open}
        onClose={onClose}
      >
        <Modal.Title id="create-list-dialog-title" className="flex mb-2">
          Bookmark settings
        </Modal.Title>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <div className="space-y-4">
            <BookmarkSettingsForm
              control={control}
              formState={formState}
              watch={watch}
              setValue={setValue}
            />

            {apiError && <div className="text-error-500 text-center">{apiError}</div>}
          </div>
          <div className="flex justify-end space-x-4 mt-4">
            <Button
              className="pl-0 pr-10"
              type="button"
              theme="error"
              borderless
              onClick={handleOpen}
            >
              Delete bookmark
            </Button>
            <Button type="button" borderless onClick={handleClose}>
              Cancel
            </Button>
            <Button theme="primary" type="submit" disabled={update.isLoading}>
              Save bookmark
            </Button>
          </div>
          <Modal
            aria-labelledby="delete-list-dialog-title"
            className="max-w-md sm:max-w-md overflow-visible"
            rootClassName="z-30"
            open={openDelete}
            onClose={onClose}
          >
            <Modal.Title id="delete-list-dialog-title" className="mb-8 break-words">
              Are you sure you want to delete this Bookmark ?
            </Modal.Title>
            {/* <div className="space-y-4 mt-10">
                  {apiError && <div className="text-error-500 text-center mt-8">{apiError}</div>}
                </div> */}

            <div className="flex justify-end space-x-4 mt-4">
              <Button type="button" borderless onClick={closeDeletePopup}>
                Cancel
              </Button>
              <Button
                type="button"
                onClick={handleDelete}
                disabled={deleteSingleBookmark.isLoading}
              >
                Delete
              </Button>
            </div>
          </Modal>
        </form>
      </Modal>
    </div>
  );
};
