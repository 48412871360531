import { DropdownMenu } from '@demandscience/ui';
import useCompare from 'components/Filters/useCompare';
import useFilters from 'components/Filters/useFilters';

const CompareMenuItems = () => {
  const { filters } = useFilters();
  const { setCompareDrawerOpen, handleBlankSearch, handleDuplicateSearch } = useCompare();

  const handleSearchDrawer = () => {
    setCompareDrawerOpen(true);
  };

  return (
    <>
      <DropdownMenu.Item onClick={() => handleBlankSearch(filters)}>
        <div className="flex items-center">Blank search</div>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => handleDuplicateSearch(filters)}>
        <div className="flex items-center">Duplicate current search</div>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={handleSearchDrawer}>
        <div className="flex items-center">Saved search & history</div>
      </DropdownMenu.Item>
    </>
  );
};

export default CompareMenuItems;
