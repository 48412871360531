import { memo, useRef } from 'react';
import map from 'lodash/map';
import range from 'lodash/range';
import XOctagonIcon from '@demandscience/ui/icons/x-octagon';
import { ModelType, ResultsView, DataModule } from 'types';
import useAggregate from 'hooks/useAggregate';
import ExploreTAMButton from 'components/ExploreTAM/Button';
import TotalCounts from 'components/DataProfile/TotalCounts';
import useSearchCounts from 'components/Search/useSearchCounts';
import useFilters from 'components/Filters/useFilters';
import classNames from 'classnames';
import JobFunctionAggregate from 'components/DataProfile/JobFunctionAggregate';
import JobLevelAggregate from 'components/DataProfile/JobLevelAggregate';
import CompanyCountryAggregate from 'components/DataProfile/CompanyCountryAggregate';
import CompanyEmployeesAggregate from 'components/DataProfile/CompanyEmployeesAggregate';
import CompanyIndustryAggregate from 'components/DataProfile/CompanyIndustryAggregate';
import { useResizeDetector } from 'react-resize-detector';
import CompanyRevenueAggregate from 'components/DataProfile/CompanyRevenueAggregate';

interface DataProfileProps {
  id?: string;
  iscontactRendered: boolean;
  modules?: {
    type: {
      id: string;
      name: string;
    };
    visual: string;
  }[];
  onTabChange: (tab: ResultsView) => void;
}

const DataProfile = memo(({ id, onTabChange, modules, iscontactRendered }: DataProfileProps) => {
  const { contactCount, companyCount, ...countsQuery } = useSearchCounts({ useTotalCount: true });
  const { filters, companyFilters, setFilter, setFilters } = useFilters();

  const contactsAggregateQuery = useAggregate(ModelType.Contact, filters);
  const companiesAggregateQuery = useAggregate(ModelType.Company, companyFilters);

  const containerRef = useRef<HTMLDivElement>(null);
  const { width = 0 } = useResizeDetector({ targetRef: containerRef });

  const isLoading =
    countsQuery.isLoading || contactsAggregateQuery.isLoading || companiesAggregateQuery.isLoading;
  const isError =
    countsQuery.isError || contactsAggregateQuery.isError || companiesAggregateQuery.isError;
  return (
    <div id={id} className="mb-8" ref={containerRef}>
      <div
        className={classNames('group/cols gap-4 space-y-4', {
          hidden: !containerRef,
          'columns-1': width <= 520,
          'columns-2': width > 520 && width <= 800,
          'columns-3': width > 800,
        })}
      >
        {(isLoading || isError) &&
          map(range(6), (index) => (
            <div
              key={index}
              className={classNames('h-[312px] rounded bg-slate-200', {
                'animate-pulse': isLoading,
              })}
            >
              {isError && (
                <div className="text-center text-gray-500">
                  <XOctagonIcon className="inline-block" />
                  <div className="text-sm">Error loading data</div>
                </div>
              )}
            </div>
          ))}
        {!isLoading &&
          !isError &&
          modules?.map((module, index) => (
            <>
              {module.type.id === DataModule.Overview && (
                <div className="break-inside-avoid" key={index}>
                  <TotalCounts
                    contactCount={contactCount as number}
                    companyCount={companyCount as number}
                    loading={countsQuery.isFetching}
                    iscontactRendered={iscontactRendered}
                  >
                    <ExploreTAMButton
                      filters={filters}
                      companyFilters={companyFilters}
                      contactCount={contactCount}
                      companyCount={companyCount}
                      onTabChange={onTabChange}
                      setFilters={setFilters}
                    />
                  </TotalCounts>
                </div>
              )}

              {module.type.id === DataModule.ContactDepartment && iscontactRendered && (
                <div className="break-inside-avoid" key={index}>
                  <JobFunctionAggregate filters={filters} setFilter={setFilter} />
                </div>
              )}

              {module.type.id === DataModule.CompanyHeadcount && (
                <div className="break-inside-avoid" key={index}>
                  <CompanyEmployeesAggregate
                    companyFilters={companyFilters}
                    setFilter={setFilter}
                  />
                </div>
              )}

              {module.type.id === DataModule.ContactJobLevel && iscontactRendered && (
                <div className="break-inside-avoid" key={index}>
                  <JobLevelAggregate filters={filters} setFilter={setFilter} />
                </div>
              )}

              {module.type.id === DataModule.CompanyLocation && (
                <div className="break-inside-avoid" key={index}>
                  <CompanyCountryAggregate companyFilters={companyFilters} setFilter={setFilter} />
                </div>
              )}

              {module.type.id === DataModule.CompanyIndustry && (
                <div className="break-inside-avoid" key={index}>
                  <CompanyIndustryAggregate companyFilters={companyFilters} setFilter={setFilter} />
                </div>
              )}
              {module.type.id === DataModule.CompanyRevenue && (
                <div className="break-inside-avoid" key={index}>
                  <CompanyRevenueAggregate companyFilters={companyFilters} setFilter={setFilter} />
                </div>
              )}
            </>
          ))}
      </div>
    </div>
  );
});

DataProfile.displayName = 'DataProfile';

export default DataProfile;
