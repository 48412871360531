import {
  Account,
  AdvancedSelection,
  ModelType,
  SortBy,
  Visibility,
  Show,
  Pagination,
  SearchParams,
  PaginationResponse,
  Filters,
  ExportData,
  NotificationMessagePayload,
  BaseSearchResponse,
  Constraints,
} from 'types';

export const listTypeOptions = [
  { value: ModelType.Company, label: 'Companies list' },
  { value: ModelType.Contact, label: 'Contacts list' },
];

export enum ListOrigin {
  Automated = 'automation',
  Search = 'search',
  Upload = 'upload',
}

export enum ListStatus {
  Completed = 'completed',
  Error = 'error',
  Processing = 'processing',
}

export type ListFilter = {
  name?: string;
  records_origin?: ListOrigin;
  records_origins?: ListOrigin[];
  records_type?: ModelType;
  show: Show;
};

export type ListParams = SearchParams<ListFilter>;

export type ListRecord = {
  created_at: string;
  cumulative_processed?: number;
  current_total?: number;
  description: string;
  id: string;
  is_global_exclusion?: boolean;
  name: string;
  number_of_records: number;
  owner: Account;
  records_origin: ListOrigin;
  records_type: ModelType;
  shared_with?: Account[];
  status: ListStatus;
  updated_at: string;
  visibility: Visibility;
};

export type CreateListFormValues = {
  description: string;
  members?: Array<{ label: string; value: string }>;
  name: string;
  notify?: boolean;
  visibility: Visibility;
} & NotificationMessagePayload;

export type EditListFormValues = {
  id?: string;
} & CreateListFormValues;

export type CreateListPayload = {
  ID?: string;
  description: string;
  file_name?: string;
  name: string;
  records_type: ModelType;
  share_with?: Array<string>;
  visibility: Visibility;
} & NotificationMessagePayload;

export type CreateListResponse = {
  id: string;
};

export type UpdateListPayload = {
  id?: string;
  unshare_with?: Array<string>;
} & Omit<CreateListPayload, 'records_type'>;

export type DeleteListPayload = {
  id: string;
  notify: boolean;
} & NotificationMessagePayload;

export type ShowListPayload = {
  id: string;
};

export type ListRecordsParams = {
  name: string;
  pagination: Pagination;
  sort: SortBy;
};

export type ShowListRecordsPayload = {
  id: string;
  params: ListRecordsParams;
};

export enum ListRecordsAction {
  Add = 'add',
  Copy = 'copy',
  Move = 'move',
  Remove = 'remove',
}

export type UpdateListContentPayload = {
  advanced_selection?: AdvancedSelection;
  all_records?: boolean;
  constraints?: Constraints;
  inex?: { [x: string]: any };
  method: ListRecordsAction;
  sort?: SortBy;
  source_list_id?: string;
  target_list_id?: string;
} & ExportData;

export type ListsQueryResponse = {
  lists: ListRecord[];
} & PaginationResponse;

export type ListQueryResponse = {
  data: ListRecord;
};

export type ListRecordsQueryResponse = BaseSearchResponse;

export type DeleteListFormValues = {
  notification_message: string;
};

export type ExportToList = {
  advanced_selection?: AdvancedSelection;
  constraints?: Constraints;
  filters?: Filters;
  sort?: SortBy;
  type: ModelType;
};

export type CopyOrMoveToList = {
  listId: string;
  method: ListRecordsAction.Copy | ListRecordsAction.Move;
} & ExportToList;

export type UploadListPayload = {
  controller?: AbortController;
  file: File;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
  records_type: ModelType;
};

export type UploadListValidationPayload = {
  list_id: string;
  objectName: string;
  records_type: ModelType;
};

export type UploadListResponse = {
  file_name: string;
  list_id: string;
};

export type SignedUrlParams = {
  objectName: string;
  records_type: ModelType;
};

export type SignedUrlResponse = {
  list_id: string;
  signed_url: string;
};

export type SetGlobalExclusionPayload = {
  flag: boolean;
  id: string;
};

export const ListRecordsLimit = {
  [ModelType.Company]: 500000,
};
