import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button, TextField } from '@demandscience/ui';

import { MessagePayload } from 'types';
import { capitalize } from 'lodash';
import { AxiosError } from 'axios';
import MessageInputController from 'components/Input/MessageInputController';
import { UseMutationResult } from '@tanstack/react-query';
import Select from 'components/Input/Select';

type FormValues = {
  credits?: number;
  validity: number;
} & MessagePayload;

interface CustomPlanFormProps {
  mutation: UseMutationResult<void, unknown, MessagePayload, unknown>;
  onCancel: () => void;
  onSuccess: () => void;
}

const CustomPlanForm = ({ mutation, onSuccess, onCancel }: CustomPlanFormProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit, formState, setError } = useForm<FormValues>({
    defaultValues: { credits: 10000, validity: 12, message: '' },
  });
  const { isSubmitting } = formState;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setApiError(null);

    try {
      const { credits, validity, message: customMessage } = data;
      const message = `
        Number of credits: ${credits}

        Validity period: ${validity} months

        Message: ${customMessage}
      `;

      await mutation.mutateAsync({ message });

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        const { error_field, error } = e.response?.data;

        if (error_field !== undefined) {
          setError(error_field, { type: 'api', message: capitalize(error) });
          return;
        }

        const { message } = e;
        setApiError(message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        <Controller
          name="credits"
          control={control}
          render={({ field }) => (
            <TextField label="Number of credits" variant="outlined" type="number" {...field} />
          )}
        />
        <Controller
          name="validity"
          control={control}
          render={({ field }) => (
            <Select
              label="Validity period"
              variant="outlined"
              options={[
                {
                  label: '1 month',
                  value: 1,
                },
                {
                  label: '3 months',
                  value: 3,
                },
                {
                  label: '6 months',
                  value: 6,
                },
                {
                  label: '9 months',
                  value: 9,
                },
                {
                  label: '12 months',
                  value: 12,
                },
              ]}
              {...field}
            />
          )}
        />
        <MessageInputController<FormValues>
          label="Message"
          placeholder="Optional message to the sales team"
          name="message"
          maxLength={5000}
          control={control}
        />

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-10">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button type="submit" theme="primary" disabled={isSubmitting}>
          Send request
        </Button>
      </div>
    </form>
  );
};

export default CustomPlanForm;
