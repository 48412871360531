import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@demandscience/ui';

import useMutateCredits from './useMutateCredits';
import MessageInputController from 'components/Input/MessageInputController';
import { AxiosError } from 'axios';

type ManageCreditsRequestFormValues = {
  notification_message: string;
};

interface RejectCreditsRequestFormProps {
  id: string;
  onCancel: () => void;
  onSuccess: () => void;
}

const RejectCreditsRequestForm = ({ id, onSuccess, onCancel }: RejectCreditsRequestFormProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit, formState } = useForm<ManageCreditsRequestFormValues>();
  const { isSubmitting } = formState;
  const { rejectCreditsRequest } = useMutateCredits();

  const onSubmit: SubmitHandler<ManageCreditsRequestFormValues> = async (data) => {
    setApiError(null);

    try {
      await rejectCreditsRequest.mutateAsync({ id, ...data });

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col">
        <div className="py-2 space-y-2">
          <MessageInputController<ManageCreditsRequestFormValues>
            name="notification_message"
            control={control}
          />

          {apiError && <div className="text-error-500 text-center">{apiError}</div>}
        </div>
      </div>

      <div className="flex justify-end space-x-2 mt-8">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button type="submit" theme="primary" disabled={isSubmitting}>
          Decline request
        </Button>
      </div>
    </form>
  );
};

export default RejectCreditsRequestForm;
