import { ListFilterName } from 'types/filters';
import useSearchSettings from 'components/Search/useSearchSettings';
import InExListsFilter from './InExListsFilter';
import { FilterProps } from './types';

const ExclusionListFilter = (props: FilterProps) => {
  const { data: { global_exclusion_list: hasGlobalExclusionList } = {} } = useSearchSettings();

  return (
    <InExListsFilter
      {...props}
      name={ListFilterName.ExclusionList}
      hasGlobalExclusionList={hasGlobalExclusionList}
    />
  );
};

export default ExclusionListFilter;
