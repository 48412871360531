import { Transition } from '@demandscience/ui';
import { every } from 'lodash';

import FilterSection from './FilterSection';

interface FilterListProps {
  children: React.ReactNode;
  className?: string;
  collapse?: boolean;
  count: number;
  dense?: boolean;
  onToggle?: () => void;
  title: string;
}

const FilterList = ({
  className,
  title,
  count,
  children,
  collapse,
  onToggle,
  dense,
}: FilterListProps) => {
  // no list render if ´very child is false (due to conditional rendering)
  if (every(children as any, (child) => child === false)) {
    return null;
  }

  return (
    <div className={className}>
      <FilterSection
        title={title}
        count={count}
        show={!collapse}
        dense={dense}
        onClick={onToggle}
        role="button"
      />
      <Transition.Collapse show={!collapse} as="div">
        <div className="border divide-y border-gray-200">{children}</div>
      </Transition.Collapse>
    </div>
  );
};

export default FilterList;
