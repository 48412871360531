import { useNavigate } from 'react-router-dom';
import Clock from '@demandscience/ui/icons/clock';
import TimeAgo from 'react-timeago';

import { flatten, join, map } from 'lodash';
import classNames from 'classnames';

import { KlarityFeature, RecentSearch } from 'types';
import { Filters, FilterName } from 'types/filters';
import ListItem from 'components/Layout/ListItem';
import IconBox from 'components/Layout/IconBox';
import Info from 'components/SavedSearches/Info';
import Dropdown from './Dropdown';
import { getLabels } from 'components/Filters/utils';
import { stopPropagation } from 'utils/event';
import useCompare from 'components/Filters/useCompare';

interface RecentSearchesListProps {
  data?: RecentSearch[];
  featureContext?: KlarityFeature;
  searchQuery?: string;
  showDivider?: boolean;
}

const RecentSearchesList = ({
  data,
  featureContext,
  showDivider = true,
  searchQuery,
}: RecentSearchesListProps) => {
  const navigate = useNavigate();

  const { addSearchToCompareList } = useCompare();

  const handleClick = (search: RecentSearch) => () => {
    const { filters, current_view } = search;

    if (featureContext === KlarityFeature.CompareSearches) {
      addSearchToCompareList(search, filters);
      return;
    }

    // fix recent search open by cloning filters value
    // this way FiltersProvider will always update after navigate
    navigate('/search', { state: { filters: { ...filters }, tab: current_view } });
  };

  const getName = (filters: Filters) => {
    const labels = map(filters, (filter, filterName: FilterName) => getLabels(filterName, filter));

    let name = join(flatten(labels), ', ');

    if (name?.length > 40) {
      name = name.substring(0, 40) + '...';
    }

    return name;
  };

  const filteredData = data?.filter((search) => {
    if (searchQuery === '' || searchQuery === undefined) {
      return search;
    } else {
      return getName(search.filters).toLowerCase().includes(searchQuery!);
    }
  });

  return (
    <div className={classNames({ 'divide-y': showDivider })}>
      {filteredData?.map((search) => {
        return (
          <ListItem
            key={search.id}
            className="group cursor-pointer"
            role="button"
            onClick={handleClick(search)}
            leadingContent={
              <IconBox className="px-2 py-2 bg-gray-100">
                <Clock className="w-4 h-4 text-gray-500" />
              </IconBox>
            }
            title={getName(search.filters)}
            subtitle={<TimeAgo date={search.created_at} />}
            trailingContent={
              <div className="flex flex-row gap-2" onClick={stopPropagation}>
                <div className="invisible group-hover:visible">
                  <Info data={search} />
                </div>
                <Dropdown data={search} />
              </div>
            }
          />
        );
      })}
    </div>
  );
};

export default RecentSearchesList;
