import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SortBy, VisibilityBookmarks } from 'types';
import { BookmarkListParams } from 'types/BookMark';
import { bookmarkList } from 'api/bookmark';

export const defaultPaginationPageSize = 6;

export const useBookmarkList = () => {
  const [search, setSearch] = useState<string>('');
  const [params, setParams] = useState<BookmarkListParams>({
    index: 0,
    count: 6,
    sort: {
      by: 'created_at',
      descending: true,
    },
    filter: {
      show: VisibilityBookmarks['All bookmarks'],
    },
  });
  const { sort } = params;
  const [page, setPage] = useState(0);

  const setShow = useCallback(
    (show: VisibilityBookmarks | string[]) => {
      if (typeof show === 'string' && !Array.isArray(show)) {
        // If `show` is of type VisibilityBookmarks
        setParams((state) => ({ ...state, filter: { show } }));
      } else if (typeof show === 'object') {
        // If `show` is of type string[]
        setParams((state) => ({ ...state, filter: { ...params.filter, module_type_id: show } }));
      }
    },
    [params.filter],
  );

  const setName = useCallback(
    (name: string) => {
      setParams((state) => ({ ...state, index: 0, filter: { ...params.filter, name } }));
    },
    [params.filter],
  );

  const handleSetPage = useCallback((newPage: number) => {
    setParams((state) => ({
      ...state,
      index: newPage,
      pagination: {
        index: newPage,
        count: defaultPaginationPageSize,
      },
    }));
    setPage(newPage);
  }, []);

  const handleSetSort = useCallback((newSort: SortBy) => {
    setParams((state) => ({ ...state, sort: newSort }));
  }, []);

  const { data, error, isLoading, isError } = useQuery(
    ['BoolmarkList', params],
    () => bookmarkList(params),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data,
    error,
    isLoading,
    isError,
    page,
    pageSize: defaultPaginationPageSize,
    setPage: handleSetPage,
    sort,
    setSort: handleSetSort,
    search,
    setSearch,
    setShow,
    setName,
  };
};
