import { useCallback } from 'react';
import { Modal, ModalProps } from '@demandscience/ui';
import useAuth from 'components/Auth/useAuth';
import useSnackbar from 'components/Snackbar/useSnackbar';
import ConfirmOverlay from 'components/ConfirmOverlay';

import { Member } from 'types';
import useMutateMember from './useMutateMember';
import { AxiosError } from 'axios';

interface SuspendOverlayProps extends Omit<ModalProps, 'children'> {
  member: Member;
}

const SuspendOverlay: React.FC<SuspendOverlayProps> = ({ member, ...props }) => {
  const { user } = useAuth();
  const { showMessage } = useSnackbar();
  const { suspend } = useMutateMember();

  const handleSuspend = useCallback(async () => {
    if (user!.username === member.username) {
      showMessage('You cannot suspend your own account', 'error');
    } else {
      try {
        await suspend.mutateAsync(member.username);

        props.onClose();
      } catch (e: any) {
        if (e instanceof AxiosError) {
          showMessage(e.response?.data?.error || 'Unable to suspend a member account', 'error');
        } else {
          showMessage('Unexpected error, please try again later', 'error');
        }
      }
    }
  }, [member.username, props, showMessage, suspend, user]);

  return (
    <ConfirmOverlay onConfirm={handleSuspend} confirmLabel="YES, deactivate" {...props}>
      <Modal.Title className="mb-4">Are you sure you want to deactivate this account?</Modal.Title>
    </ConfirmOverlay>
  );
};

export default SuspendOverlay;
