import { useCallback, useState } from 'react';

import { Button, ButtonOwnProps } from '@demandscience/ui';

import { ResultsView } from 'types';
import { Filters } from 'types/filters';
import SaveOverlay from './SaveOverlay';

interface SaveControlProps extends ButtonOwnProps {
  as?: React.ElementType;
  children: React.ReactNode;
  currentView: ResultsView;
  filters: Filters;
}

const SaveControl: React.FC<SaveControlProps> = ({
  filters,
  currentView,
  as: Component = Button,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Component onClick={handleOpen} {...props} />
      <SaveOverlay filters={filters} currentView={currentView} open={open} onClose={handleClose} />
    </>
  );
};

export default SaveControl;
