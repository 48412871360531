import React from 'react';
import { KlarityFeature } from 'types';

export interface FeatureFlagContextInterface {
  getFeatureFlagStatus: (feature: KlarityFeature) => boolean;
  isLabEnabled: boolean;
  requestFeatures: () => void;
}

const FeatureFlagContext = React.createContext<FeatureFlagContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  FeatureFlagContext.displayName = 'FeatureFlagContext';
}

export default FeatureFlagContext;
