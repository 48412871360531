import { Company } from 'types';
import ListItemText from 'components/Layout/ListItemText';
import CreditCardIcon from '@demandscience/ui/icons/credit-card';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';
import { isEmpty, join, map } from 'lodash';

interface SubIndustryProps {
  className?: string;
  inline?: boolean;
  row?: Company;
}
const SubIndustry = ({ className, row, inline }: SubIndustryProps) => {
  if (isEmpty(row?.industry.sub_categories)) {
    return null;
  }

  const subIndustries = join(map(row?.industry.sub_categories, 'sub_category'), ', ');

  if (inline) {
    return <Paragraph className={className}>{subIndustries}</Paragraph>;
  }

  return (
    <ListItemText
      leadingContent={<CreditCardIcon className="w-5 h-5" />}
      overline="Sub Industry"
      title={subIndustries}
    />
  );
};

export default SubIndustry;
