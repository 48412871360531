import { useMutation, useQueryClient } from '@tanstack/react-query';
import { revealContacts } from 'api/search';
import { exportRecords as exportRecordsApi } from 'api/export';

import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import { SearchResponse, ModelType, Contact } from 'types';
import { cloneDeep } from 'lodash';
import { useTracking } from 'react-tracking';
import { ConsumeCreditEvent } from 'types/tracking';

const useMutateSearchResults = (options?: Record<string, any>) => {
  const { trackEvent } = useTracking<ConsumeCreditEvent>({
    type: 'event',
    event: 'consume_credit',
  });
  const queryClient = useQueryClient();

  const invalidateContacts = (hits: Contact[]) => {
    const queriesData = queryClient.getQueriesData<SearchResponse>(['search', 'contact']);

    forEach(queriesData, ([queryKey, queryData]) => {
      const newQueryData = reduce(
        hits,
        (res, hit) => {
          // @ts-ignore
          const index = findIndex(res.hits, { dsid: hit.dsid });

          if (index > -1) {
            // @ts-ignore
            res.hits[index] = hit;
          }

          return res;
        },
        cloneDeep(queryData),
      );

      queryClient.setQueryData(queryKey, newQueryData);
    });
  };

  const invalidateActivity = () => {
    // invalidate activity stats query
    queryClient.invalidateQueries(['activity', 'monthly']);
  };

  const reveal = useMutation(revealContacts, {
    onSuccess: (data, params) => {
      if (!params.dry_run) {
        // dry_run has no effect so no need to invalidate cache
        if (data.revealed > 0) {
          invalidateContacts(data.hits);

          // invalidate as some credits might be used
          queryClient.invalidateQueries(['organization']);
          queryClient.invalidateQueries(['account']);
          queryClient.invalidateQueries(['lists']);

          trackEvent({
            numberCredits: data.revealed,
            recordType: ModelType.Contact,
            consumptionMethod: 'reveal',
          });
        }
      }
    },
    ...options,
  });

  const exportRecords = useMutation(exportRecordsApi, {
    onSuccess: (data, params) => {
      if (!params.dry_run) {
        // dry_run has no effect so no need to invalidate cache
        if (data.revealed > 0) {
          if (params.record_type === ModelType.Contact) {
            invalidateContacts(data.hits as Contact[]);
          }

          // invalidate as some credits might be used
          queryClient.invalidateQueries(['organization']);
          queryClient.invalidateQueries(['account']);
          queryClient.invalidateQueries(['lists']);

          trackEvent({
            numberCredits: data.revealed,
            recordType: params.record_type,
            consumptionMethod: params.export_type,
          });
        }

        invalidateActivity();
      }
    },
    ...options,
  });

  return {
    reveal,
    exportRecords,
  };
};

export default useMutateSearchResults;
