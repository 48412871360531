import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { Combobox } from '@demandscience/ui';
import Select from './Select';
import { get, isString } from 'lodash';
import { creditOptions } from 'types/organization';
import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import { allocation } from 'types';

interface CreditsQuotaInputControllerProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'> {
  allocation?: allocation;
}

const CreditsQuotaInputController = <T extends FieldValues>(
  props: CreditsQuotaInputControllerProps<T>,
) => {
  const { name, allocation } = props;
  const handleChange = useCallback(
    (field: any) => (e: any) => {
      if (props.allocation === 'unlimited') {
        return;
      }
      const selectValue = isString(e) ? e?.toLowerCase() : e;
      const isUnlimited = e?.toString().toLowerCase() === 'unlimited' || e === -1;
      const isNumber = (n: any) => !isNaN(parseFloat(n)) && !isNaN(n - 0);
      const numRegex = /^(?:[1-9]\d{0,2}(?:,\d{3})*|0)(?:\.\d+)?$/;
      const isValidStringNumber = numRegex.test(selectValue);

      if (!isUnlimited && isValidStringNumber) {
        const cleanNumber = selectValue?.toString().replace(/,/g, '');
        field.onChange(Number(cleanNumber));
      } else if (!isUnlimited && isNumber(selectValue)) {
        field.onChange(Number(selectValue));
      } else if (isUnlimited) {
        field.onChange(-1);
      } else if (isEmpty(selectValue)) {
        field.onChange('');
      }
    },
    [props.allocation],
  );

  return (
    <div>
      {allocation === 'unlimited' ? (
        <Select
          label="Monthly credits"
          component={Combobox}
          options={creditOptions}
          value={'Unlimited'}
          disabled
          onChange={() => {}}
        />
      ) : (
        <Controller
          {...props}
          render={({ field, formState }) => {
            const error = get(formState, `errors.${name}`);
            return (
              <Select
                label="Monthly credits"
                component={Combobox}
                // @ts-expect-error demandscience-ui issue
                hideClear
                freeInput
                error={error?.message as string}
                {...field}
                options={creditOptions}
                value={field?.value}
                onChange={handleChange(field)}
                disabled={formState.isSubmitting}
              />
            );
          }}
          rules={{
            required: 'Required field',
            validate: (value) =>
              (value >= 0 && Number.isInteger(value)) ||
              value === -1 ||
              'Please enter an integer number',
            pattern: {
              value: /^\d*$/,
              message: 'Please enter an integer number',
            },
          }}
        />
      )}
    </div>
  );
};

export default CreditsQuotaInputController;
