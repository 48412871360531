import { useQuery } from '@tanstack/react-query';

import { fetchFilterData } from 'api/data';
import map from 'lodash/map';
import { FilterDataFieldName, JobFunction } from 'types';
import { Option } from '../types';
import { pick } from 'lodash';

const fetchOptions = async () => {
  const functions = await fetchFilterData(FilterDataFieldName.JobFunctions);
  const data = map(
    functions,
    (value) =>
      ({
        id: value.function,
        value: pick(value, 'function'),
        label: value.function,
      } as Option<JobFunction>),
  );

  return data;
};

const useJobFunctionOptions = (options?: Record<string, any>) => {
  const query = useQuery(['filterOptions', FilterDataFieldName.JobFunctions], fetchOptions, {
    refetchOnWindowFocus: false, // no need to refetch on window focus
    staleTime: Infinity,
    ...options,
  });

  return query;
};

export default useJobFunctionOptions;
