import { forwardRef, HTMLAttributes, useState, ReactNode } from 'react';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import useCustomization from 'components/SearchCustomization/useCustomization';
import { ViewLayout } from 'types';
import { useLocation } from 'react-router-dom';

export interface TablCellProps extends HTMLAttributes<HTMLDivElement> {
  shadowRight?: boolean;
  sticky?: boolean;
  width?: number | 'auto';
}

export const TableCell = ({
  className,
  width = 'auto',
  sticky,
  shadowRight,
  ...props
}: TablCellProps) => (
  <div
    className={overrideTailwindClasses(
      classNames('px-4 flex items-center flex-shrink-0', className, {
        [`sticky bg-inherit transition-shadow`]: sticky,
        'shadow-r': shadowRight,
        [`w-[${width}px]`]: width !== 'auto', // Ensure this width is consistently applied
      }),
    )}
    {...props}
  />
);

export const TableHeaderCell = ({ className, ...props }: TablCellProps) => (
  <TableCell className={classNames('py-2 relative group', className)} {...props}>
    {props.children}
  </TableCell>
);

interface TableHeaderProps extends HTMLAttributes<HTMLDivElement> {
  dense?: boolean;
  fullWidth?: boolean;
}

export const TableHeader = ({
  className,
  children,
  fullWidth,
  dense,
  ...props
}: TableHeaderProps) => (
  <div
    className={overrideTailwindClasses(
      classNames(
        'flex flex-row items-center text-xs font-semibold text-gray-500 whitespace-nowrap bg-white border-b',
        className,
        { 'py-2': !dense, 'w-max': !fullWidth, 'min-w-max': fullWidth },
      ),
    )}
    {...props}
  >
    {children}
    {fullWidth && <div className="grow" />}
  </div>
);

interface TableRowProps extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  clickable?: boolean;
  dense?: boolean;
  fullWidth?: boolean;
  selected?: boolean;
}
export const TableRow = ({
  className,
  fullWidth,
  clickable,
  selected,
  active,
  dense,
  children,
  ...props
}: TableRowProps) => (
  <div
    className={overrideTailwindClasses(
      classNames('flex flex-row items-stretch bg-white', className, {
        'h-[61px]': !dense,
        'w-max': !fullWidth,
        'min-w-max': fullWidth,
        'cursor-pointer': clickable,
        'bg-primary-50 hover:bg-primary-100': selected,
        'bg-primary-100': selected && active,
        'hover:bg-gray-50': !selected,
        'bg-gray-50': !selected && active,
      }),
    )}
    {...props}
  >
    {children}
    {fullWidth && <div className="grow" />}
  </div>
);

export const TableBody = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={overrideTailwindClasses(classNames('divide-y', className))} {...props} />
);

interface TableProps extends HTMLAttributes<HTMLDivElement> {
  initialColumnWidths: number[];
  renderContent: (
    columnWidths: number[],
    handleMouseDown: (index: number) => React.MouseEventHandler<HTMLDivElement>,
  ) => ReactNode;
}

const Table = forwardRef<HTMLDivElement, TableProps>(
  ({ className, initialColumnWidths, renderContent, ...props }, ref) => {
    const { layout } = useCustomization();
    const location = useLocation();
    const isListRecordsPage = location.pathname.startsWith('/lists');

    // Store both the initial widths and the current widths
    const [columnWidths, setColumnWidths] = useState<number[]>(initialColumnWidths);

    const handleMouseDown =
      (index: number): React.MouseEventHandler<HTMLDivElement> =>
      (event: React.MouseEvent) => {
        const startX = event.clientX;
        const startWidth = columnWidths[index];

        const handleMouseMove = (moveEvent: MouseEvent) => {
          const newWidth = startWidth + moveEvent.clientX - startX;
          setColumnWidths((prevWidths) =>
            prevWidths.map((width, i) => (i === index ? Math.max(newWidth, 50) : width)),
          );
        };

        const handleMouseUp = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      };
    // toDO: Reset Columns in up coming tickets
    return (
      <div
        ref={ref}
        className={overrideTailwindClasses(
          classNames(
            'w-full h-full overflow-auto scroll-smooth',
            layout.view === ViewLayout.Table && isListRecordsPage ? 'pb-56' : '',
            className,
          ),
        )}
        {...props}
      >
        {/* <div className="flex justify-end p-2">
        <button className="bg-blue-500 text-white py-1 px-3 rounded" onClick={resetWidths}>
            Reset Columns
          </button>
        </div> */}
        {renderContent(columnWidths, handleMouseDown)} {/* Pass the function to the render prop */}
      </div>
    );
  },
);

Table.displayName = 'Table';

export default Table;
