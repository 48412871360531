import Plan from './Plan';
import { isEmpty } from 'lodash';
import usePlans from './usePlans';
import CustomPlan from './CustomPlan';
import { Accordion } from '@demandscience/ui';

const Plans = () => {
  const { data: plans } = usePlans();

  const sortedPlans = plans?.sort((a, b) => a.number_of_credits - b.number_of_credits);

  return (
    <div className="max-w-4xl text-center text-gray-500 mx-auto py-7">
      <h1 className="text-xl text-gray-800 font-semibold mb-2">Buy credits</h1>
      <p className="text-base max-w-sm mx-auto">
        Use credits to unlock business information and export records to CSV or your CRM
      </p>
      {!isEmpty(sortedPlans) && (
        <div className="mt-8">
          <div className="w-full flex flex-row flex-wrap items-end justify-center gap-4">
            {sortedPlans?.map((plan, index) => (
              <Plan
                key={index}
                forwardedKey={index}
                plan={plan}
                mostPopular={plan.name === 'Self 1000'}
              />
            ))}
            <CustomPlan />
          </div>
        </div>
      )}
      <div className="max-w-xl text-gray-800 mt-16 mx-auto">
        <h2 className="text-xl font-semibold mb-14">Questions you may have</h2>
        <div className="space-y-4">
          <Accordion
            borderless
            summary="How long are my credits valid?"
            className="text-left border-b pb-2"
          >
            The validity of your credits is contingent on the particular package you choose to
            purchase. Each package will have a distinct expiration timeline, but rest assured, this
            information is always transparently displayed on your dashboard. Remember to use your
            credits within their active period to make the most of your purchase.
          </Accordion>
          <Accordion
            borderless
            summary="What happens when I run out of credits?"
            className="text-left border-b pb-2"
          >
            When you have no more credits, you will not be able to unlock business business
            information or export records to CSV or your CRM.
          </Accordion>
          <Accordion
            borderless
            summary="Can credits be shared across my team?"
            className="text-left border-b pb-2"
          >
            Absolutely. You can assign any number of credits to each of your team members.
          </Accordion>
          <Accordion
            borderless
            summary="What payment options do you offer?"
            className="text-left border-b pb-2"
          >
            We accept a variety of payment methods for your convenience. These include VISA,
            Mastercard, American Express, and Discover credit cards.
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Plans;
