import api from 'api';

import {
  Account,
  UpdateNamePayload,
  UpdateEmailPayload,
  UpdatePasswordPayload,
  Credit,
  FetchNotificationsParams,
  FetchNotificationsResponse,
  SearchCustomization,
  UpdateSearchCustommizationsPayload,
  ListParams,
  ListsQueryResponse,
  NotificationMessagePayload,
} from 'types';

export const fetchCurrentUser = (): Promise<Account> => api.get('/account/me');

export const updateCurrentUserName = (payload: UpdateNamePayload): Promise<void> =>
  api.patch('/account/me/name', payload);

export const updateCurrentUserEmail = (payload: UpdateEmailPayload): Promise<void> =>
  api.patch('/account/me/email', payload);

export const updateCurrentUserPassword = (payload: UpdatePasswordPayload): Promise<void> =>
  api.patch('/account/me/password', payload);

export const fetchCredits = (): Promise<Credit> => api.get('/account/me/credit');

export const requestCredits = (payload: NotificationMessagePayload): Promise<void> =>
  api.post('/account/me/credit/request', payload);

export const fetchNotifications = (
  params: FetchNotificationsParams,
): Promise<FetchNotificationsResponse> => api.get('/account/me/notification', { params });

export const markNotificationAsRead = (id: string): Promise<void> =>
  api.patch(`/account/me/notification/${id}/mark_as_read`);

export const fetchSearchCustomization = (): Promise<SearchCustomization> =>
  api.get('/account/me/search_customization');

export const updateSearchCustomization = (
  payload: UpdateSearchCustommizationsPayload,
): Promise<void> => api.patch('/account/me/search_customization', payload);

export const fetchUserLists = (params: ListParams): Promise<ListsQueryResponse> =>
  api.post('/account/me/list', params);

export const deleteAccount = (query: string): Promise<void> => api.delete(`/account/me?${query}`);
