import useModal from 'hooks/useModal';

import SupportRequestContext, { SupportRequestContextInterface } from './SupportRequestContext';
import BuyCreditsOverlay from './BuyCreditsOverlay';
import ChangePlanOverlay from './ChangePlanOverlay';
import { useMemo } from 'react';
import CustomPlanOverlay from './CustomPlanOverlay';

interface CreditsProviderProps {
  children: React.ReactNode;
  showBuyCredits?: boolean;
}

const SupportRequestProvider = ({ showBuyCredits, children }: CreditsProviderProps) => {
  const {
    open: openChangePlan,
    openModal: openChangePlanModal,
    closeModal: closeChangePlanModal,
  } = useModal();
  const {
    open: openBuyCredits,
    openModal: openBuyCreditsModal,
    closeModal: closeBuyCreditsModal,
  } = useModal(showBuyCredits);
  const {
    open: openConfirmPlan,
    openModal: openConfirmPlanModal,
    closeModal: closeConfirmPlanModal,
  } = useModal();
  const {
    open: openCustomPlan,
    openModal: openCustomPlanModal,
    closeModal: closeCustomPlanModal,
  } = useModal();

  const value: SupportRequestContextInterface = useMemo(
    () => ({
      buyCredits: openBuyCreditsModal,
      changePlan: openChangePlanModal,
      confirmPlan: openConfirmPlanModal,
      customPlan: openCustomPlanModal,
    }),
    [openBuyCreditsModal, openChangePlanModal, openConfirmPlanModal, openCustomPlanModal],
  );

  return (
    <SupportRequestContext.Provider value={value}>
      {children}
      <BuyCreditsOverlay open={openBuyCredits} onClose={closeBuyCreditsModal} />
      <ChangePlanOverlay title="Change plan" open={openChangePlan} onClose={closeChangePlanModal} />
      <ChangePlanOverlay
        title="Confirm plan"
        open={openConfirmPlan}
        onClose={closeConfirmPlanModal}
      />
      <CustomPlanOverlay open={openCustomPlan} onClose={closeCustomPlanModal} />
    </SupportRequestContext.Provider>
  );
};

export default SupportRequestProvider;
