import { BulkCredits, Credit } from 'types';

import useCredits from './useCredits';
import CreditsUsage from './CreditsUsage';

interface CreditsUsageInfoProps {
  bulkCredits: BulkCredits;
}

const CreditsUsageInfo: React.FC<CreditsUsageInfoProps> = ({ bulkCredits }) => {
  const { data } = useCredits({ initialData: { bulk_credits: bulkCredits } });
  const { bulk_credits } = data as Credit; // data is always there because of initial data

  return <CreditsUsage bulkCredits={bulk_credits} />;
};

export default CreditsUsageInfo;
