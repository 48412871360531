import { ReactNode, useCallback, useState } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  DragOverlay,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import { createPortal } from 'react-dom';

export interface SortableContainerProps<T extends UniqueIdentifier> {
  children: ReactNode;
  items: T[];
  onChange: (items: T[]) => void;
  renderDragPreview?: (item: T) => ReactNode;
}

const SortableContainer = <T extends UniqueIdentifier>({
  items,
  onChange,
  children,
  renderDragPreview,
}: SortableContainerProps<T>) => {
  const [activeId, setActiveId] = useState<T | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    const { active } = event;

    setActiveId(active.id as T);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      setActiveId(null);

      if (over?.id && active.id !== over?.id) {
        const oldIndex = items.indexOf(active.id as T);
        const newIndex = items.indexOf(over.id as T);

        onChange(arrayMove(items, oldIndex, newIndex));
      }
    },
    [items, onChange],
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
      {renderDragPreview &&
        createPortal(
          <DragOverlay>{activeId ? renderDragPreview(activeId) : null}</DragOverlay>,
          document.body,
        )}
    </DndContext>
  );
};

export default SortableContainer;
