// shared types
import {
  EmployeesSizeRange,
  IndustryByCategory,
  IndustryBySIC,
  IndustryByNAICS,
  Intent,
  JobFunction,
  JobLevel,
  Keyword,
  Location,
  RevenueRange,
  SubIndustry,
  TechInstall,
} from 'types';
import { CompanyAutocomplete, JobAutocomplete } from './search';

export type ExcludableFilter = {
  exclude?: boolean;
};

export type FreeTextFilter = {
  freetext: string;
} & ExcludableFilter;

export type LocationFilter = Location & ExcludableFilter;

export type DsidFilter = {
  dsid: string;
};

// data filters

export enum DataFilterName {
  ConfirmedConnect = 'confirmed_connect',
  ContactDetails = 'contact_details',
  DataAccuracy = 'data_accuracy',
}

export type Operator = 'AND' | 'OR';

export type Duration = 30 | 60 | 90;

export type ConfirmedConnectFilter = {
  duration: Duration;
};

export type ContactDetailsProperty =
  | 'contact_business_email'
  | 'contact_linkedin_handle'
  | 'contact_phone'
  | 'contact_mobile_phone';

export type ContactDetailsFilter = {
  operator?: Operator;
} & Partial<Record<ContactDetailsProperty, boolean>>;

export type DataAccuracyFilter = {
  range_max: number;
  range_min: number;
};

// company filters

export enum CompanyFilterName {
  CompanyEmployees = 'employees_size_range',
  CompanyIndustry = 'company_industry',
  CompanyLocation = 'company_location',
  CompanyName = 'company_name',
  CompanyRevenue = 'company_revenue',
  CompanySpeciality = 'company_speciality',
}

export type CompanyEmployeesFilter = Array<EmployeesSizeRange>;

export type CompanyIndustryFilter = Array<
  | (IndustryByCategory &
      ExcludableFilter & {
        sub_category?: Array<SubIndustry & ExcludableFilter>;
      })
  | (IndustryBySIC & ExcludableFilter)
  | (IndustryByNAICS & ExcludableFilter)
>;

export type CompanyLocationFilter = Array<LocationFilter>;

export type CompanyNameFilter = Array<FreeTextFilter | (CompanyAutocomplete & ExcludableFilter)>;

export type CompanyRevenueFilter = Array<RevenueRange>;

export type CompanySpecialityFilter = Array<FreeTextFilter | (Keyword & ExcludableFilter)>;

// contact filters

export enum ContactFilterName {
  ContactId = 'contact_dsid',
  ContactLocation = 'contact_location',
  ContactName = 'contact_name',
  JobFunction = 'job_function',
  JobLevel = 'job_level',
  JobTitle = 'job_title',
}

export type ContactIdFilter = Array<DsidFilter & ExcludableFilter>;

export type ContactLocationFilter = Array<LocationFilter>;

export type ContactNameFilter = Array<FreeTextFilter>;

export type JobFunctionFilter = Array<JobFunction & ExcludableFilter>;

export type JobLevelFilter = Array<JobLevel>;

export type JobTitleFilter = Array<FreeTextFilter | (JobAutocomplete & ExcludableFilter)>;

// sales signals filters

export enum SalesSignalsFilterName {
  InstallTopic = 'install_topic',
  IntentTopic = 'intent_topic',
}

export const monthValues = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
export type Month = (typeof monthValues)[number];

export const monthLabels: Record<Month, string> = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};

export type InstallTopicFilter = {
  install: Array<TechInstall & FreeTextFilter>;
  month?: Array<Month>;
};

export type IntentTopicFilter = {
  intent: Array<Intent & FreeTextFilter>;
  range_max: number;
  range_min: number;
};

// list filters

export enum ListFilterName {
  ExclusionList = 'exclusion_list',
  InclusionList = 'inclusion_list',
}

type ListFilter = {
  list_id: string;
  name?: string;
  records_type?: 'contact' | 'company';
};

export type ExclusionListFilter = Array<ListFilter>;

export type InclusionListFilter = Array<ListFilter>;

// composed filters type

export type Filter =
  | ConfirmedConnectFilter
  | ContactDetailsFilter
  | DataAccuracyFilter
  | CompanyEmployeesFilter
  | CompanyIndustryFilter
  | CompanyLocationFilter
  | CompanyNameFilter
  | CompanyRevenueFilter
  | CompanySpecialityFilter
  | ContactIdFilter
  | ContactLocationFilter
  | ContactNameFilter
  | JobFunctionFilter
  | JobLevelFilter
  | JobTitleFilter
  | InstallTopicFilter
  | IntentTopicFilter
  | ExclusionListFilter
  | InclusionListFilter;

export type CompanyFilters = {
  [DataFilterName.ConfirmedConnect]?: ConfirmedConnectFilter;
  [DataFilterName.ContactDetails]?: ContactDetailsFilter;
  [DataFilterName.DataAccuracy]?: DataAccuracyFilter;
  [CompanyFilterName.CompanyEmployees]?: CompanyEmployeesFilter;
  [CompanyFilterName.CompanyIndustry]?: CompanyIndustryFilter;
  [CompanyFilterName.CompanyLocation]?: CompanyLocationFilter;
  [CompanyFilterName.CompanyName]?: CompanyNameFilter;
  [CompanyFilterName.CompanyRevenue]?: CompanyRevenueFilter;
  [CompanyFilterName.CompanySpeciality]?: CompanySpecialityFilter;
  [ContactFilterName.ContactId]?: ContactIdFilter;
  [ContactFilterName.ContactLocation]?: ContactLocationFilter;
  [ContactFilterName.ContactName]?: ContactNameFilter;
  [ContactFilterName.JobFunction]?: JobFunctionFilter;
  [ContactFilterName.JobLevel]?: JobLevelFilter;
  [ContactFilterName.JobTitle]?: JobTitleFilter;
  [SalesSignalsFilterName.InstallTopic]?: InstallTopicFilter;
  [SalesSignalsFilterName.IntentTopic]?: IntentTopicFilter;
  [ListFilterName.ExclusionList]?: ExclusionListFilter;
  [ListFilterName.InclusionList]?: InclusionListFilter;
};

// contact and company filters are identical atm
export type Filters = CompanyFilters;

export type FilterName = keyof Filters;

export type FilterHistory = {
  history: Filters[];
  index: number;
};

// Limit number of badge display in any filter
export const badgeDisplayCap = 10;
