import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  connectIntegration,
  disconnectIntegration,
  configureIntegration,
  enableMapping,
  disableMapping,
} from '../../api/integrations';

const useMutateIntegration = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();

  const connect = useMutation(connectIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['integration']);
    },
    ...options,
  });

  const disconnect = useMutation(disconnectIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['integration']);
    },
    ...options,
  });

  const enable = useMutation(enableMapping, {
    onSuccess: () => {
      queryClient.invalidateQueries(['integration']);
    },
    ...options,
  });

  const disable = useMutation(disableMapping, {
    onSuccess: () => {
      queryClient.invalidateQueries(['integration']);
    },
    ...options,
  });

  const configure = useMutation(configureIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries(['integration']);
    },
    ...options,
  });

  return { connect, disconnect, enable, disable, configure };
};

export default useMutateIntegration;
