import classNames from 'classnames';
import { DropdownMenu, DropdownMenuProps, IconButton } from '@demandscience/ui';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';

import { SavedSearch } from 'types';
import useModal from 'hooks/useModal';
import EditOverlay from './EditOverlay';
import DeleteOverlay from './DeleteOverlay';

interface SavedSearchDropdownProps extends DropdownMenuProps {
  data: SavedSearch;
}

const SavedSearchDropdown = ({ className, data, ...props }: SavedSearchDropdownProps) => {
  const { open: openEdit, openModal: openEditModal, closeModal: closeEditModal } = useModal();
  const { open: openDelete, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  return (
    <>
      <DropdownMenu
        buttonType="custom"
        customButton={
          <IconButton
            className="hover:bg-gray-100"
            size="sm"
            theme="default"
            Icon={MoreVerticalIcon}
          />
        }
        className={classNames('grid place-items-center', className)}
        origin="bottom-right"
        {...props}
      >
        <DropdownMenu.Item onClick={openEditModal}>Edit</DropdownMenu.Item>
        <DropdownMenu.Item onClick={openDeleteModal}>Delete</DropdownMenu.Item>
      </DropdownMenu>
      <EditOverlay data={data} open={openEdit} onClose={closeEditModal} />
      <DeleteOverlay data={data} open={openDelete} onClose={closeDeleteModal} />
    </>
  );
};

export default SavedSearchDropdown;
