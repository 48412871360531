import useModal from 'hooks/useModal';
import { avatarUrl } from 'api/avatar';
import Heading from 'layouts/Main/Heading';
import useAuth from 'components/Auth/useAuth';
import SearchListControl from './SearchListControl';
import { Link, useNavigate } from 'react-router-dom';
import { Account, ListOrigin, ListRecord, ListStatus, ModelType, Visibility } from 'types';
import useModelPermissions from 'hooks/useModelPermissions';
import HeadingElement from 'components/Typography/Heading';
import ListRecordEditActionModal from './ListRecordEditActionModal';
import OpenListControl from 'components/LinkedInExtension/OpenListControl';
import { Badge, Button, Collaboration, CollaborationUser } from '@demandscience/ui';
import InfoIcon from '@demandscience/ui/icons/info';
import ArrowLeftIcon from '@demandscience/ui/icons/arrow-left';
import { useCallback } from 'react';
import TextTooltip from 'components/Tooltip/TextTooltip';
import ListStatusBadge from './ListStatusBadge';

interface ListDetailsHeaderProps {
  className?: string;
  list?: ListRecord;
}

const ListDetailsHeader = ({ className, list }: ListDetailsHeaderProps) => {
  const {
    name,
    description,
    number_of_records,
    records_origin,
    records_type,
    visibility,
    owner = {} as Account,
    shared_with,
    is_global_exclusion,
    status,
  } = list || {};

  const { open, openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const { user } = useAuth();
  const { isEditable } = useModelPermissions(user, owner, visibility);

  const handleDeleted = useCallback(() => {
    navigate('/lists');
  }, [navigate]);

  const buildRecordsBadgeLabel = () => {
    let label = number_of_records?.toLocaleString();

    if (number_of_records === 1) {
      label += ` ${records_type}`;
    } else if (!number_of_records || number_of_records === 0 || number_of_records > 1) {
      if (records_type === ModelType.Contact) {
        label += ' contacts';
      } else if (records_type === ModelType.Company) {
        label += ' companies';
      }
    }

    return label;
  };

  if (!list || !owner) {
    return null;
  }

  let usersList: CollaborationUser[] = [];

  if (owner) {
    usersList = [
      {
        name: owner.name ?? owner.email,
        avatar: owner.has_avatar ? avatarUrl(owner.username) : '',
      },
    ];

    if (shared_with) {
      usersList = [
        ...usersList,
        ...shared_with.map(({ email, name = email, username, has_avatar }) => {
          return { name, avatar: has_avatar ? avatarUrl(username) : '' };
        }),
      ];
    }
  }

  return (
    <>
      <div className={className}>
        <Heading
          element={
            <Link to="/lists" className="flex items-center text-sm text-blue-500 hover:underline">
              <ArrowLeftIcon className="w-4 h-4 mr-2" />
              All lists
            </Link>
          }
        />

        {list && (
          <div className="flex justify-between">
            <div>
              <div className="flex items-center space-x-2">
                <HeadingElement>{name}</HeadingElement>
                {description && (
                  <TextTooltip title={description} showArrow placement="bottom">
                    <InfoIcon className="w-5 h-5" />
                  </TextTooltip>
                )}
                <div className="flex flex-row gap-2">
                  {isEditable && records_origin !== ListOrigin.Automated && (
                    <Button borderless size="sm" type="button" onClick={openModal}>
                      Edit
                    </Button>
                  )}
                  <SearchListControl
                    size="md"
                    className="text-primary-500 hover:text-primary-600 hover:bg-primary-50"
                    theme="none"
                    list={list}
                  />
                  <SearchListControl
                    size="md"
                    className="text-primary-500 hover:text-primary-600 hover:bg-primary-50"
                    theme="none"
                    list={list}
                    showDataProfile
                  />
                  <OpenListControl
                    size="md"
                    className="text-primary-500 hover:text-primary-600 hover:bg-primary-50"
                    theme="none"
                    list={list}
                  />
                </div>
              </div>
              <div className="flex space-x-2 mt-2">
                <ListStatusBadge list={list} />
                {status === ListStatus.Completed && (
                  <>
                    <Badge label={buildRecordsBadgeLabel()} color="amber" size="sm" />
                    <Badge
                      label={records_origin === ListOrigin.Automated ? 'Automated' : records_origin}
                      color={records_origin === ListOrigin.Automated ? 'emerald' : 'indigo'}
                      size="sm"
                      className="capitalize"
                    />
                    {is_global_exclusion && (
                      <Badge label="Global suppression list" color="amber" size="sm" />
                    )}
                  </>
                )}
              </div>
            </div>

            {records_origin !== ListOrigin.Automated && (
              <div className="isolate">
                <Collaboration
                  userList={usersList}
                  showAddUserIcon={isEditable && visibility !== Visibility.Public}
                  sharedWithOrganization={visibility === Visibility.Public}
                  hideOrganizationOptions={owner.username !== user?.username}
                  onAddUserClick={openModal}
                  onOrganizationOptionsClick={openModal}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {list && (
        <ListRecordEditActionModal
          open={open}
          onClose={closeModal}
          afterDelete={handleDeleted}
          {...list}
        />
      )}
    </>
  );
};

export default ListDetailsHeader;
