import { HtmlHTMLAttributes } from 'react';
import ListIcon from '@demandscience/ui/icons/list-02';
import OnboardingBox from './OnboardingBox';

const OnboardingButtonNo1 = (props: HtmlHTMLAttributes<HTMLDivElement>) => {
  return (
    <OnboardingBox
      Icon={ListIcon}
      title={'Create a new\nprospect list'}
      subtitle="Enter your search criteria"
      {...props}
    />
  );
};

export default OnboardingButtonNo1;
