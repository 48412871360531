import React from 'react';
import { createRoot } from 'react-dom/client';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';
import { AxiosError } from 'axios';

import '../node_modules/@demandscience/ui/dist/tailwind.css';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true, // prevent data swap until new data loaded
      retry: (failureCount, error) => {
        if (error instanceof AxiosError) {
          const { response: { status } = {} } = error;
          if (
            status === undefined ||
            status === 400 ||
            status === 403 ||
            status === 404 ||
            status === 500 ||
            status === 504
          ) {
            return false; // no retry in above cases
          }
        }

        return failureCount < 3;
      },
      // useInfiniteQuery options
      getNextPageParam: (lastPage: any) => lastPage.nextIndex,
      getPreviousPageParam: (firstPage: any) => firstPage.prevIndex,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
