import classNames from 'classnames';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import 'react-reflex/styles.css';

import Filters from './Filters';
import Results from './Results';
import Splitter from './Splitter';
import useCustomization from 'components/SearchCustomization/useCustomization';
import useFiltersCollapse from './Filters/useFiltersCollapse';
import CustomizationDrawer from 'components/SearchCustomization/CustomizationDrawer';
import { useCallback } from 'react';
import AiLauncher from 'components/Aissistant/Chat/AiLauncher';
import ChatSearchProvider from 'components/Aissistant/ChatSearchProvider';
import ChatPromptProvider from 'components/Aissistant/ChatPromptProvider';

interface SplitPaneProps {
  className?: string;
  orientation: 'horizontal' | 'vertical';
}

const SplitPane: React.FC<SplitPaneProps> = ({ className, orientation }) => {
  const { filters, onChange } = useCustomization();
  const { collapse: collapseFilters, toggle: toggleFilters } = useFiltersCollapse();
  // using controlled flex value for filters pane so it keeps the same width on position (left/right) change
  const { position, width } = filters;

  const handleResizePane = useCallback(
    (e: any) => {
      const {
        component: {
          props: { flex },
        },
      } = e;

      onChange('filters', { ...filters, width: flex });
    },
    [filters, onChange],
  );

  const handleCloseFilters = useCallback(() => {
    toggleFilters();
  }, [toggleFilters]);

  const filtersElement = (
    <ReflexElement
      minSize={310}
      flex={width}
      onStopResize={handleResizePane}
      className={classNames({ hidden: collapseFilters })}
    >
      <Filters />
    </ReflexElement>
  );
  const splitter = (
    <ReflexSplitter
      className={classNames('w-auto !bg-transparent border-none', {
        hidden: collapseFilters,
      })}
    >
      <Splitter
        onClick={handleCloseFilters}
        tooltip={
          <span>
            <strong>Drag</strong> to resize
            <br />
            <strong>Click</strong> to hide
          </span>
        }
      />
    </ReflexSplitter>
  );

  return (
    <div className={classNames(className, 'flex min-w-0')}>
      <div className="grow min-w-0">
        <ReflexContainer orientation={orientation}>
          {position === 'left' && filtersElement}
          {position === 'left' && splitter}
          <ReflexElement minSize={350} flex={collapseFilters ? 1 : 1 - width}>
            <Results />
            <ChatPromptProvider>
              <ChatSearchProvider>
                <AiLauncher />
              </ChatSearchProvider>
            </ChatPromptProvider>
          </ReflexElement>
          {position === 'right' && splitter}
          {position === 'right' && filtersElement}
        </ReflexContainer>
      </div>
      <CustomizationDrawer />
    </div>
  );
};

export default SplitPane;
