import { useCallback, useMemo, useState } from 'react';
import {
  CompanyTableColumn,
  ContactTableColumn,
  KlarityFeature,
  ModelType,
  SearchCustomization,
} from 'types';
import useSnackbar from 'components/Snackbar/useSnackbar';
import useSearchCustomization from 'components/Account/useSearchCustomization';
import { without } from 'lodash';
import CustomizationContext, { CustomizationContextInterface } from './CustomizationContext';
import useMutateSearchCustomization from 'components/Account/useMutateSearchCustomization';
import CustomizeColumnsOverlay from './CustomizeColumnsOverlay';
import { mergeDefaults } from './utils';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

interface CustomizationProviderProps {
  children: React.ReactNode;
}

const CustomizationProvider: React.FC<CustomizationProviderProps> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [customizeColumns, setCustomizeColumns] = useState({
    open: false,
    type: ModelType.Contact,
  });
  const { showMessage } = useSnackbar();
  const { data, isLoading } = useSearchCustomization();
  const { update } = useMutateSearchCustomization();
  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );

  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);

  const setCustomizeColumnsOpen = useCallback((type: ModelType) => {
    setCustomizeColumns({ open: true, type });
  }, []);

  const handleCloseCustomizeColumns = useCallback(() => {
    setCustomizeColumns((state) => ({ ...state, open: false }));
  }, []);

  const onChange = useCallback(
    async (setting: string, value: any) => {
      try {
        await update.mutateAsync({
          ...data,
          [setting]: value,
        } as SearchCustomization);
      } catch {
        showMessage('Unable to update customization settings', 'error');
      }
    },
    [data, showMessage, update],
  );

  // ignoring api fetching error with failover to defaults
  const { layout, filters, results, exports } = mergeDefaults(data);

  // Remove ContactDetails column
  if (isContactDisabled || isContactRenderDisabled) {
    results.companiesTableColums = without(
      results.companiesTableColums,
      ...[CompanyTableColumn.ContactDetails],
    );
  }

  if (isContactDisabled) {
    results.contactsTableColums = without(
      results.contactsTableColums,
      ...[ContactTableColumn.ContactDetails],
    );
  }

  const value: CustomizationContextInterface = useMemo(
    () => ({
      layout,
      filters,
      results,
      exports,
      onChange,
      open,
      setOpen,
      setCustomizeColumnsOpen,
    }),
    [exports, filters, layout, onChange, open, results, setCustomizeColumnsOpen],
  );

  if (isLoading) {
    // check if here to return some kind of spinner to indicate search customization loading
    return null;
  }

  return (
    <CustomizationContext.Provider value={value}>
      {children}
      <CustomizeColumnsOverlay {...customizeColumns} onClose={handleCloseCustomizeColumns} />
    </CustomizationContext.Provider>
  );
};

export default CustomizationProvider;
