import { ChangeEventHandler, useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  isEmpty,
  map,
  find,
  isEqual,
  omit,
  reject,
  flatten,
  includes,
  split,
  forEach,
  some,
  uniq,
} from 'lodash';
import CheckboxOption from '../Filter/Dropdown/CheckboxMenuItem';
import TextMenuItem from '../Filter/Dropdown/TextMenuItem';
import { FilterProps, Option } from './types';
import {
  ContactFilterName,
  FreeTextFilter,
  JobTitleFilter as ContactJobTitleFilterType,
  badgeDisplayCap,
} from 'types/filters';
import useFilter from './useFilter';
import { getFreetextLabel, getJobTitleLabel } from './utils';
import useJobAutocomplete from './Autocomplete/useJobAutocomplete';
import FilterInput from './FilterInput';
import Inline from 'components/Filter/Inline';

const getBadgeProps = (value: any) => {
  const label = getJobTitleLabel(value);

  return {
    key: label,
    label,
    exclude: value.exclude,
  };
};

const ContactJobTitleFilter = ({ clearInputOnChange, dense }: FilterProps) => {
  const name = ContactFilterName.JobTitle;
  const freeSolo = true;
  const placeholder = 'Enter title';
  const showIncludeExclude = true;

  const { ref, inView } = useInView();
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { value, onChange, onClear } = useFilter(name);
  const { data, isFetching, isError, hasNextPage, fetchNextPage } = useJobAutocomplete(
    { value: query },
    {
      keepPreviousData: false,
      retry: false,
      enabled: !!query.length && !includes(query, ','),
    },
  );
  const suggestions = flatten(data?.pages);

  const freeTextOptions: Option<FreeTextFilter>[] = useMemo(() => {
    let res: Option<FreeTextFilter>[] = [];

    if (freeSolo && query !== '') {
      const tokens = uniq(split(query, /\s*,\s*/));

      forEach(tokens, (token, index) => {
        if (token) {
          const dataFromValue = find(value, (v) =>
            isEqual(omit(v, 'exclude'), { freetext: token }),
          ) as FreeTextFilter;

          const optionValue = dataFromValue || {
            freetext: token,
          };
          const label = getFreetextLabel(optionValue);

          res = [...res, { id: `freetext${index}`, value: optionValue, label }];
        }
      });
    }

    return res;
  }, [freeSolo, query, value]);

  const options: Option<ContactJobTitleFilterType[number]>[] = useMemo(() => {
    // workaround so combobox comparison by reference works (against the values)
    const res: Option<ContactJobTitleFilterType[number]>[] = map(suggestions, (option) => {
      const dataFromValue = find(value, (v) =>
        isEqual(omit(v, 'exclude'), omit(option.value, 'exclude')),
      );

      const optionValue = dataFromValue || omit(option.value, 'exclude');

      return {
        ...option,
        value: optionValue,
      };
    });

    return res;
  }, [suggestions, value]);

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
  }, []);

  const handleChange = useCallback(
    (value: ContactJobTitleFilterType | undefined) => {
      onChange(value);

      // focus input on change
      inputRef.current?.focus();

      // workaround to clear filter input and the suggestions dropdown
      if (clearInputOnChange) {
        handleInputClear();
      }
    },
    [clearInputOnChange, handleInputClear, onChange],
  );

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const {
      target: { value },
    } = e;

    setQuery(value);
  }, []);

  const handleToggle = useCallback(
    (option: Option<any>) => {
      const newValue = map(value, (v) => {
        if (isEqual(option.value, v)) {
          return option.value.exclude
            ? omit(option.value, 'exclude')
            : { ...option.value, exclude: true };
        }

        return v;
      });

      onChange(newValue);
    },
    [onChange, value],
  );

  const handleDelete = useCallback(
    (deleted: any) => {
      const newValue = reject(value, deleted);

      onChange(newValue);
    },
    [onChange, value],
  );

  const handleClear = useCallback(() => {
    handleInputClear();

    onClear();
  }, [handleInputClear, onClear]);

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleEnterKey = useCallback(() => {
    const missing = reject(freeTextOptions, (option) => some(value, option.value));

    if (isEmpty(missing)) {
      // all are present, remove them all
      onChange(
        reject(value, (val) =>
          some(
            freeTextOptions,
            (option) => option.value.freetext === (val as FreeTextFilter).freetext,
          ),
        ),
      );
    } else {
      // add those missing
      onChange([...(value || []), ...map(missing, 'value')]);
    }
  }, [freeTextOptions, onChange, value]);

  useEffect(() => {
    if (inView) {
      handleLoadMore();
    }
  }, [inView, handleLoadMore]);

  return (
    <Inline
      value={value}
      onChange={handleChange}
      multiple
      button={(props) => (
        <FilterInput
          {...props}
          ref={inputRef}
          name={name}
          dense={dense}
          onClear={handleClear}
          onDelete={handleDelete}
          onInputChange={handleInputChange}
          onInputClear={handleInputClear}
          placeholder={placeholder}
          getBadgeProps={getBadgeProps}
          onEnterKey={handleEnterKey}
          badgeDisplayCap={badgeDisplayCap}
        />
      )}
    >
      {isError && <TextMenuItem>Error fetching filtering values</TextMenuItem>}
      {!isError && !isEmpty(query) && (
        <>
          {!isFetching && isEmpty(freeTextOptions) && isEmpty(options) && (
            <TextMenuItem>No match for &quot;{query}&quot;</TextMenuItem>
          )}
          {map(freeTextOptions, (option) => (
            <CheckboxOption
              key={option.id}
              option={option}
              onToggle={showIncludeExclude ? handleToggle : undefined}
            />
          ))}
          {isFetching && <TextMenuItem>Loading...</TextMenuItem>}
          {map(options, (option) => (
            <CheckboxOption
              key={option.id}
              option={option}
              onToggle={showIncludeExclude ? handleToggle : undefined}
            />
          ))}
          {hasNextPage && (
            <TextMenuItem ref={ref} textClassName="text-gray-500">
              Loading...
            </TextMenuItem>
          )}
        </>
      )}
    </Inline>
  );
};

export default ContactJobTitleFilter;
