import { Fragment, useEffect, useState, useRef, useMemo, RefObject, useCallback } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Options } from '@popperjs/core';
import { Portal } from 'react-portal';

import Menu from './Menu';

export interface DropdownProps {
  button: ({
    activeIndex,
    open,
    value,
  }: {
    activeIndex: number | null;
    open: boolean;
    value: any;
  }) => React.ReactNode;
  children: React.ReactNode;
  container?: RefObject<HTMLDivElement>;
  forceClose?: boolean;
  id?: string;
  matchContentSize?: true;
  menuClassName?: string;
  multiple?: boolean;
  offset?: number[];
  onChange: (value: any) => void;
  onClose?: () => void;
  value: any;
}

const DEFAULT_VALUE: any = []; // this is a workaround for dfault value not to be recreated on each render
const DEFAULT_OFFSET: number[] = [0, 0];

const Dropdown = ({
  id,
  value = DEFAULT_VALUE,
  button,
  container,
  onClose,
  matchContentSize,
  menuClassName,
  children,
  forceClose,
  offset = DEFAULT_OFFSET,
  ...props
}: DropdownProps) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState(null);
  const modifiers = useMemo<Options['modifiers']>(
    () => [
      {
        name: 'matchReferenceSize',
        enabled: !matchContentSize,
        fn: ({ state, instance }) => {
          const widthOrHeight =
            state.placement.startsWith('left') || state.placement.startsWith('right')
              ? 'height'
              : 'width';

          if (!state.elements.popper) return;

          const popperSize =
            state.elements.popper[
              `offset${widthOrHeight[0].toUpperCase() + widthOrHeight.slice(1)}` as 'offsetWidth'
            ];
          const referenceSize = state.rects.reference[widthOrHeight];

          if (Math.round(popperSize) === Math.round(referenceSize)) return;

          state.elements.popper.style[widthOrHeight] = `${referenceSize}px`;
          instance.update();
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
    [offset, matchContentSize],
  );

  const { styles, attributes, update } = usePopper(targetElement, popperElement, {
    placement: 'bottom',
    modifiers,
  });

  const handleBeforeEnter = useCallback(() => {
    // poper workaround
    setPopperElement(popperElRef.current);
  }, []);

  const handleAfterLeave = useCallback(() => {
    // poper workaround
    setPopperElement(null);

    if (onClose) onClose();
  }, [onClose]);

  useEffect(() => {
    update && update();
  }, [update, value]);

  return (
    // @ts-expect-error = not sure why multiple prop is causing an error
    <Combobox value={value} {...props}>
      {(props) => (
        <>
          <div id={id} ref={setTargetElement}>
            <Combobox.Button as="div">{button(props)}</Combobox.Button>
          </div>
          <Portal node={container?.current}>
            <div className="z-10" ref={popperElRef} style={styles.popper} {...attributes.popper}>
              <Transition
                show={props.open && forceClose !== true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                beforeEnter={handleBeforeEnter}
                afterLeave={handleAfterLeave}
              >
                <Combobox.Options static as={Fragment} hold>
                  <Menu rootClassName={menuClassName}>{children}</Menu>
                </Combobox.Options>
              </Transition>
            </div>
          </Portal>
        </>
      )}
    </Combobox>
  );
};

export default Dropdown;
