import now from 'lodash/now';
import * as api from 'api/avatar';
import useAuth from 'components/Auth/useAuth';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useMutateAvatar = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const uploadAvatar = useMutation(api.uploadAvatar, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(['members']);

      // if own avatar update, reload account data
      if (user?.username === id) {
        queryClient.invalidateQueries(['account']);
      }

      // LNG-266 fix, workaround to detect that avatar got updated
      queryClient.setQueryData(['account', 'avatarUpdate', id], now());
    },
    ...options,
  });

  const deleteAvatar = useMutation(api.deleteAvatar, {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries(['members']);

      // if own avatar update, reload account data
      if (user?.username === id) {
        queryClient.invalidateQueries(['account']);
      }
    },
    ...options,
  });

  return { uploadAvatar, deleteAvatar };
};

export default useMutateAvatar;
