import { AggregateFilter } from 'types';
import { Modal } from '@demandscience/ui';
import { FC, HTMLAttributes, useCallback } from 'react';
import BookMarkForm from './BookMarkForm';
import { Aggregate as AggregateType } from 'types';

export interface BookMarkOverlayOverlayProps<F extends AggregateFilter>
  extends HTMLAttributes<HTMLDivElement> {
  aggregate: {
    count: number;
    values: AggregateType<F>[];
  };
  aggregateField: string;
  module_type_id: string;
  module_type_name: string;
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
}

const BookMarkOverlay: FC<BookMarkOverlayOverlayProps<AggregateFilter>> = ({
  onSuccess,
  module_type_name,
  aggregate,
  module_type_id,
  aggregateField,
  ...props
}) => {
  const { onClose } = props;

  const handleSuccess = useCallback(() => {
    onClose();
    onSuccess();
  }, [onClose, onSuccess]);

  const handleClose = useCallback(() => {
    onClose();
    onSuccess();
  }, [onClose, onSuccess]);

  return (
    <Modal
      aria-labelledby="create-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="create-list-dialog-title" className="flex mb-4">
        Bookmark this module
      </Modal.Title>
      <BookMarkForm
        onSuccess={handleSuccess}
        onCancel={handleClose}
        module_type_name={module_type_name}
        aggregate={aggregate}
        module_type_id={module_type_id}
        aggregateField={aggregateField}
      />
    </Modal>
  );
};

export default BookMarkOverlay;
