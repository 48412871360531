import { isEmpty, map } from 'lodash';
import { ExportData, ModelType } from 'types';

export const getFileTimestamp = () => new Date().toISOString().replaceAll(':', '_');

export const getExportFilters = (modelType: ModelType, exportData: ExportData) => {
  const { dsid, filters, batchSelection } = exportData;
  let res = { ...filters };

  if (!isEmpty(dsid)) {
    if (modelType === ModelType.Contact) {
      // if batchSelection then dsid's to be excluded
      const filterByIds = map(dsid, (value) => ({ dsid: value, exclude: batchSelection }));

      res = {
        ...res,
        contact_dsid: filterByIds,
      };
    } else if (modelType === ModelType.Company) {
      const { company_name: companyNameFilter = [] } = res;
      // if batchSelection then dsid's to be excluded
      const filterByIds = map(dsid, (value) => ({
        dsid: value,
        exclude: batchSelection,
      }));

      if (batchSelection) {
        res = {
          ...res,
          company_name: [...companyNameFilter, ...filterByIds],
        };
      } else {
        res = {
          ...res,
          company_name: filterByIds,
        };
      }
    }
  }

  return res;
};
