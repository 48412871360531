import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import validator from 'validator';

import { Button, RadioList, Combobox, Spinner } from '@demandscience/ui';
import { ListOption } from '@demandscience/ui/dist/types';
import { Role, InviteResult, allocation, CreditTypes } from 'types';
import size from 'lodash/size';
import find from 'lodash/find';
import every from 'lodash/every';

import useMutateMember from './useMutateMember';
import { AxiosError } from 'axios';
import { InviteEvent } from 'types/tracking';
import { useTracking } from 'react-tracking';
import { filter } from 'lodash';
import CreditsQuotaInputController from 'components/Input/CreditsQuotaInputController';
import Select from 'components/Input/Select';

type FormValues = {
  bulk_credits: { allocation: allocation; quota: number };
  emails: ListOption[];
  role: Role;
};

interface InviteFormProps {
  accountsAvailable: number;
  onCancel: () => void;
  onSuccess: (res: InviteResult[]) => void;
}

const InviteForm: React.FC<InviteFormProps> = ({ accountsAvailable, onSuccess, onCancel }) => {
  const { trackEvent } = useTracking<InviteEvent>({ type: 'event', event: 'invite' });
  const [apiError, setApiError] = useState<string | null>(null);
  const { invite } = useMutateMember();
  const { control, handleSubmit, formState, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      role: Role.User,
      emails: [],
      bulk_credits: { quota: CreditTypes.Medium, allocation: 'monthly' },
    },
    mode: 'onChange',
  });
  const { isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setApiError(null);

    const trimmedEmails = data.emails.map((email) =>
      typeof email.value === 'string' ? email.value.trim() : email.value,
    );

    try {
      const payload = {
        ...data,
        emails: trimmedEmails as string[],
      };

      const response = await invite.mutateAsync(payload);

      onSuccess(response);

      const invitedCount = size(filter(response, { status: 'success' }));

      if (invitedCount > 0) {
        trackEvent({ userType: data.role, numberInvites: invitedCount });
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        // if all emails are already invited/members show the same view as when success
        if (
          e.response?.status === 400 &&
          e.response?.data &&
          every(
            e.response?.data,
            ({ status }) => status === 'already_a_member' || status === 'taken',
          )
        ) {
          onSuccess(e.response.data);
        } else {
          setApiError(e.response?.data?.error || 'Unable to invite new members');
        }
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  const allocation: allocation = watch('bulk_credits.allocation');
  useEffect(() => {
    if (allocation === 'unlimited') {
      setValue('bulk_credits.quota', -1);
    }
  }, [allocation, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classNames({ hidden: isSubmitting })}>
        <div className="space-y-4">
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <RadioList
                options={[
                  { label: 'Invite as user', value: 'user' },
                  { label: 'Invite as manager', value: 'manager' },
                ]}
                {...field}
              />
            )}
          />

          <Controller
            name="emails"
            control={control}
            render={({ field }) => (
              <Combobox
                freeInput
                hidePopupIcon
                multiple
                hideMultipleCheckboxes
                label="Emails"
                placeholder="Add emails"
                options={[]}
                error={errors.emails?.message}
                helperText={
                  accountsAvailable === -1
                    ? undefined
                    : `${accountsAvailable.toLocaleString()} seats available`
                }
                {...field}
              />
            )}
            rules={{
              required: 'Required field',
              validate: (emails) => {
                const invalidEmail = find(emails, ({ value }) => {
                  return typeof value === 'string' ? !validator.isEmail(value.trim()) : true;
                });
                if (invalidEmail) {
                  return `Invalid email: ${invalidEmail.value}`;
                }

                if (accountsAvailable !== -1 && size(emails) > accountsAvailable) {
                  return `Max ${accountsAvailable} emails can be added`;
                }
                if (size(emails) > 10) {
                  return 'Max 10 emails can be added';
                }

                return true;
              },
            }}
          />
          <div className="flex flex-row gap-1">
            <div className="w-1/2">
              <Controller
                name="bulk_credits.allocation"
                control={control}
                render={({ field }) => (
                  <Select
                    label="Credit allocation"
                    component={Combobox}
                    // @ts-expect-error demandscience-ui issue
                    hideClear
                    options={[
                      { label: 'Unlimited', value: 'unlimited' },
                      { label: 'Monthly', value: 'monthly' },
                      { label: 'Fixed', value: 'fixed' },
                    ]}
                    {...field}
                  />
                )}
                rules={{ required: 'Required field' }}
              />
            </div>
            <div className="w-1/2">
              <CreditsQuotaInputController<FormValues>
                allocation={allocation}
                name="bulk_credits.quota"
                defaultValue={CreditTypes.None}
                control={control}
              />
            </div>
          </div>

          {apiError && <div className="text-error-500 text-center">{apiError}</div>}
        </div>

        <div className="flex justify-end space-x-2 mt-4">
          <Button borderless onClick={onCancel} type="button">
            Cancel
          </Button>
          <Button
            type="submit"
            theme="primary"
            disabled={isSubmitting || errors?.emails?.type === 'validate'}
          >
            Invite
          </Button>
        </div>
      </form>
      {isSubmitting && (
        <div className="mt-10">
          <div className="flex flex-col gap-6 items-center mb-16">
            <Spinner className="w-20 h-20" />
            <p className="text-sm text-gray-600 text-center">Sending invitations</p>
          </div>
          <div className="flex justify-end">
            <Button theme="primary" onClick={onCancel}>
              Close
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default InviteForm;
