import { ModelType, Visibility } from 'types';
import { Modal } from '@demandscience/ui';
import { FC, HTMLAttributes, useCallback } from 'react';
import CreateListForm from './CreateListForm';

export interface CreateListOverlayProps extends HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  onSuccess?: (id: string, visibility: Visibility) => void;
  open: boolean;
  type?: ModelType;
}

const CreateListOverlay: FC<CreateListOverlayProps> = ({ type, onSuccess, ...props }) => {
  const { onClose } = props;

  const handleSuccess = useCallback(
    (id: string, visibility: Visibility) => {
      onClose();

      if (onSuccess) onSuccess(id, visibility);
    },
    [onClose, onSuccess],
  );

  return (
    <Modal
      aria-labelledby="create-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="create-list-dialog-title" className="flex mb-4">
        Create a list
      </Modal.Title>
      <CreateListForm onSuccess={handleSuccess} onCancel={onClose} type={type} />
    </Modal>
  );
};

export default CreateListOverlay;
