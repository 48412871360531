import { Select, ToggleSwitch, RadioList, Link, DrawerRootProps } from '@demandscience/ui';
import { SelectOption } from '@demandscience/ui/dist/types';

import AlignJustifyIcon from '@demandscience/ui/icons/align-justify';
import ExpandIcon from '@demandscience/ui/icons/expand';
import StretchErticalIcon from '@demandscience/ui/icons/stretch-ertical';
import PositionLeftIcon from '@demandscience/ui/icons/position-left';
import PositionRightIcon from '@demandscience/ui/icons/position-right';
import TableIcon from '@demandscience/ui/icons/table';

import find from 'lodash/find';
import isArray from 'lodash/isArray';

import ListItemText from 'components/Layout/ListItemText';
import ListSubheader from 'components/Layout/ListSubheader';
import ListDivider from 'components/Layout/ListDivider';
import Drawer from 'components/Drawer';
import useCustomization from './useCustomization';
import { companiesSortOptions, contactsSortOptions, ModelType, ViewLayout } from 'types';
import { useCallback } from 'react';

const filtersPositionOptions = [
  {
    value: 'left',
    label: 'Left',
  },
  {
    value: 'right',
    label: 'Right',
  },
];

const filtersDenseOptions = [
  {
    value: 'normal',
    label: 'Normal',
  },
  {
    value: 'condensed',
    label: 'Compact',
  },
];

const layoutViewOptions = [
  {
    value: ViewLayout.List,
    label: 'List view',
  },
  {
    value: ViewLayout.Table,
    label: 'Table view',
  },
];

const CustomizationDrawer = (props: DrawerRootProps) => {
  const { layout, filters, results, onChange, open, setOpen, setCustomizeColumnsOpen } =
    useCustomization();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleFullWidthChange = useCallback(
    (checked: boolean) => {
      onChange('layout', { ...layout, fullWidth: checked });
    },
    [layout, onChange],
  );

  const handleViewLayoutChange = useCallback(
    (value: string) => {
      onChange('layout', { ...layout, view: value });
    },
    [layout, onChange],
  );

  const handlePositionChange = useCallback(
    (value: string) => {
      onChange('filters', { ...filters, position: value });
    },
    [filters, onChange],
  );

  const handleDenseClick = useCallback(
    (value: string) => {
      onChange('filters', { ...filters, dense: value === 'condensed' });
    },
    [filters, onChange],
  );

  const handleSortFieldChange = useCallback(
    (key: 'contactsSortField' | 'companiesSortField') => (value: SelectOption | SelectOption[]) => {
      if (isArray(value)) {
        return;
      }

      const { value: sortField } = value;

      onChange('results', { ...results, [key]: sortField });
    },
    [onChange, results],
  );

  const handleCustomizeColumns = useCallback(
    (type: ModelType) => () => {
      setCustomizeColumnsOpen(type);
    },
    [setCustomizeColumnsOpen],
  );

  return (
    <Drawer
      id="customize_drawer"
      title={<span className="whitespace-nowrap">Customize your search experience</span>}
      open={open}
      onClose={handleClose}
      anchor="left"
      {...props}
    >
      <ListSubheader title="Page" />
      <ListItemText
        leadingContent={<ExpandIcon className="w-5 h-5" />}
        title="Full width"
        trailingContent={
          <ToggleSwitch size="sm" enabled={layout.fullWidth} onChange={handleFullWidthChange} />
        }
      />
      <ListDivider />

      <ListSubheader title="Density" />
      <RadioList
        value={filters.dense ? 'condensed' : 'normal'}
        onChange={handleDenseClick}
        options={filtersDenseOptions}
      >
        <RadioList.Option className="block" option={filtersDenseOptions[0]}>
          <ListItemText
            condensed
            leadingContent={<StretchErticalIcon className="w-5 h-5" />}
            title={filtersDenseOptions[0].label}
            trailingContent={<RadioList.Icon checked={!filters.dense} theme="primary" />}
          />
        </RadioList.Option>
        <RadioList.Option className="block" option={filtersDenseOptions[1]}>
          <ListItemText
            condensed
            leadingContent={<AlignJustifyIcon className="w-5 h-5" />}
            title={filtersDenseOptions[1].label}
            trailingContent={<RadioList.Icon checked={filters.dense} theme="primary" />}
          />
        </RadioList.Option>
      </RadioList>
      <ListDivider />

      <ListSubheader title="Filters position" />
      <RadioList value={filters.position} onChange={handlePositionChange} options={[]}>
        <RadioList.Option className="block" option={filtersPositionOptions[0]}>
          <ListItemText
            condensed
            leadingContent={<PositionLeftIcon className="w-5 h-5" />}
            title={filtersPositionOptions[0].label}
            trailingContent={
              <RadioList.Icon
                checked={filters.position === filtersPositionOptions[0].value}
                theme="primary"
              />
            }
          />
        </RadioList.Option>
        <RadioList.Option className="block" option={filtersPositionOptions[1]}>
          <ListItemText
            condensed
            leadingContent={<PositionRightIcon className="w-5 h-5" />}
            title={filtersPositionOptions[1].label}
            trailingContent={
              <RadioList.Icon
                checked={filters.position === filtersPositionOptions[1].value}
                theme="primary"
              />
            }
          />
        </RadioList.Option>
      </RadioList>
      <ListDivider />

      <ListSubheader title="Default results view" />
      <RadioList value={layout.view} onChange={handleViewLayoutChange} options={layoutViewOptions}>
        <RadioList.Option className="block" option={layoutViewOptions[0]}>
          <ListItemText
            condensed
            leadingContent={<StretchErticalIcon className="w-5 h-5" />}
            title={layoutViewOptions[0].label}
            trailingContent={
              <RadioList.Icon
                checked={layout.view === layoutViewOptions[0].value}
                theme="primary"
              />
            }
          />
        </RadioList.Option>
        <RadioList.Option className="block" option={layoutViewOptions[1]}>
          <ListItemText
            condensed
            leadingContent={<TableIcon className="w-5 h-5" />}
            title={layoutViewOptions[1].label}
            trailingContent={
              <RadioList.Icon
                checked={layout.view === layoutViewOptions[1].value}
                theme="primary"
              />
            }
          />
        </RadioList.Option>
      </RadioList>
      <ListDivider />

      <ListSubheader title="Table view columns" />
      <ListItemText
        title={
          <div className="space-y-4">
            <Link as="button" onClick={handleCustomizeColumns(ModelType.Contact)}>
              Customize Contact columns
            </Link>
            <Link as="button" onClick={handleCustomizeColumns(ModelType.Company)}>
              Customize Company columns
            </Link>
          </div>
        }
      />
      <ListDivider />

      <ListSubheader title="Default contacts sorting" />
      <ListItemText
        title={
          <Select
            value={find(contactsSortOptions, { value: results.contactsSortField })}
            onChange={handleSortFieldChange('contactsSortField')}
            options={contactsSortOptions}
          />
        }
      />
      <ListSubheader title="Default companies sorting" />
      <ListItemText
        title={
          <Select
            value={find(companiesSortOptions, { value: results.companiesSortField })}
            onChange={handleSortFieldChange('companiesSortField')}
            options={companiesSortOptions}
          />
        }
      />
    </Drawer>
  );
};

export default CustomizationDrawer;
