import Heading from 'layouts/Main/Heading';
import ArrowLeftIcon from '@demandscience/ui/icons/arrow-left';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@demandscience/ui';
import HeadingElement from 'components/Typography/Heading';
import { Download } from '@demandscience/ui/icons';
import { Tooltip } from '@demandscience/ui';
import TilesIcon from '@demandscience/ui/icons/Tiles';
import { AutoUpdate, ResultsView, Visibility } from 'types';
import { downloadExport } from 'api/bookmark';
import useModal from 'hooks/useModal';
import { BookmarkSettings } from './BookmarkSettings';
export const OpenBookmarkHeader = ({
  name,
  filters,
  id,
  automated_updates,
  visibility,
}: {
  automated_updates: AutoUpdate;
  filters: any;
  id: string;
  name: string;
  visibility: Visibility;
}) => {
  const navigate = useNavigate();

  const { open, openModal, closeModal } = useModal();

  const handleNavigation = useCallback(() => {
    navigate('/search/bookmark');
  }, [navigate]);

  const dataProfile = useCallback(() => {
    const tab = ResultsView.DataProfile;

    navigate('/search', { state: { tab, filters: filters.filters } });
  }, [filters.filters, navigate]);

  const download = () => {
    downloadExport(id, name ? `${name}.csv` : 'Bookmark.csv');
  };

  return (
    <div style={{ width: 'fit-content!important', marginRight: '1.25rem' }}>
      <div style={{ height: '4.25rem' }}>
        <Heading
          element={
            <button
              onClick={handleNavigation}
              className="flex items-center text-sm text-blue-500 hover:underline"
            >
              <ArrowLeftIcon className="w-4 h-2 mr-2" />
              All bookmarks
            </button>
          }
        />
      </div>
      <div className="flex justify-between m-2">
        <div>
          <div className="flex items-center space-x-2">
            <HeadingElement>{name ? name : 'Bookmark'}</HeadingElement>

            <div className="flex flex-row gap-2">
              <Tooltip title="Edit" position="bottom" showArrow>
                <Button borderless size="md" type="button" onClick={openModal}>
                  Edit
                </Button>
              </Tooltip>
              <Tooltip title="back to Data Profile" position="bottom" showArrow>
                <Button borderless size="xs" type="button" onClick={dataProfile}>
                  <TilesIcon></TilesIcon>
                </Button>
              </Tooltip>

              <Tooltip title="Download" position="bottom" showArrow>
                <Button borderless size="xs" type="button" onClick={download}>
                  <Download></Download>
                  Download
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <BookmarkSettings
        id={id}
        open={open}
        onClose={closeModal}
        automated_updates={automated_updates}
        name={name}
        visibility={visibility}
      />
    </div>
  );
};
