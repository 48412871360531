const ceilUp = (value, uptoValue) => {
  let ceiledValue = Math.ceil(value / uptoValue) * uptoValue;
  if (ceiledValue > value) {
    return ceiledValue;
  }
  // for a value of 5, display the graph up to along the axis
  return ceiledValue + uptoValue;
};

export const maxDataValueOnAxisForDisplay = (maxValue) => {
  if (!maxValue || maxValue === 0) {
    return 1;
  }
  if (maxValue < 10) {
    return ceilUp(maxValue, 5);
  }
  if (maxValue < 100) {
    return ceilUp(maxValue, 10);
  }
  if (maxValue < 1000) {
    return ceilUp(maxValue, 100);
  }

  const maxValueAsStr = maxValue + '';
  const roundedUpToNearestExponent = Math.pow(10, maxValueAsStr.length - 1);
  const firstDigit = parseInt(maxValueAsStr.slice(0, 1));
  const secondDigit = parseInt(maxValueAsStr.slice(1, 2));

  if (secondDigit < 5) {
    // eg: if between 2000 and 2499, max label to be displayed will be 2500
    return firstDigit * roundedUpToNearestExponent + roundedUpToNearestExponent / 2;
  }
  // eg: if between 2500 and 2999, max label will be 3000
  return (firstDigit + 1) * roundedUpToNearestExponent;
};
