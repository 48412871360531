import { useCallback, useEffect, useMemo, useState } from 'react';
import ChatSearchContext, { ChatSearchContextInterface } from './ChatSearchContext';
import { GptMessageType, Message } from 'types/aissistant';
import useChatCompletions from 'hooks/useChatCompletions';
import { last } from 'lodash';
import useFilters from 'components/Filters/useFilters';
import { modelFiltersToKlarityFilters } from './ChatUtils';
import usePrompt from './usePrompt';

interface ChatSearchProviderProps {
  children: React.ReactNode;
}

const ChatSearchProvider = ({ children }: ChatSearchProviderProps) => {
  const { isSuccess: isLoadPromptSuccess, prompt, promptId } = usePrompt();
  const { isLoading, chatGPTResp, sendChatMessage } = useChatCompletions();
  const [messages, setMessages] = useState<Message[]>();
  const { setFilters } = useFilters();

  useEffect(() => {
    if (isLoadPromptSuccess && prompt) {
      setMessages(prompt);
    }
  }, [isLoadPromptSuccess, prompt]);

  useEffect(() => {
    if (promptId && messages) {
      const lastMessage = last(messages);

      if (lastMessage?.role === GptMessageType.user) {
        sendChatMessage({
          messages,
          prompt_id: promptId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    if (!isLoading && chatGPTResp?.role === GptMessageType.assistant) {
      if (chatGPTResp?.function_call) {
        try {
          const { arguments: args } = chatGPTResp.function_call;
          const rawFilters = JSON.parse(args);
          const tranformedFilters = modelFiltersToKlarityFilters(rawFilters);
          setFilters(tranformedFilters);
          setMessages((state) => {
            if (state) {
              return [...state, chatGPTResp];
            }

            return;
          });
        } catch (error) {
          // Ignore
        }
      } else if (chatGPTResp?.content) {
        setMessages((state) => {
          if (state) {
            return [...state, { ...chatGPTResp, content: chatGPTResp.content }];
          }

          return;
        });
      } else {
        setMessages((state) => {
          if (state) {
            return [
              ...state,
              { ...chatGPTResp, content: 'Sorry! Something went wrong. Please try again.' },
            ];
          }

          return;
        });
      }
    }
  }, [isLoading, chatGPTResp, setFilters]);

  const addMessage = useCallback((message: Message) => {
    setMessages((state) => {
      if (state) {
        return [...state, message];
      }
      return;
    });
  }, []);

  const value: ChatSearchContextInterface = useMemo(() => {
    return {
      addMessage,
      isLoading,
      messages,
    };
  }, [addMessage, isLoading, messages]);

  return <ChatSearchContext.Provider value={value}>{children}</ChatSearchContext.Provider>;
};

export default ChatSearchProvider;
