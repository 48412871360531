import fileDownload from 'js-file-download';
import api from 'api';
import axios from 'axios';
import {
  ExportPayload,
  ExportResponse,
  ExportsResponse,
  Pagination,
  SearchExportsParams,
} from 'types';

export const exportRecords = (payload: ExportPayload): Promise<ExportResponse> =>
  api.post('/search/export', payload);

export const downloadExport = async (id: string, filename: string): Promise<void> => {
  const { data: url } = await api.get(`/search/export/${id}/download`);
  const { data } = await axios.get(url, { responseType: 'blob' });

  fileDownload(data, filename);
};

export const fetchPreviousExports = (params: Pagination): Promise<ExportsResponse> =>
  api.get('/search/export', { params });

export const searchExports = (payload: SearchExportsParams): Promise<ExportsResponse> =>
  api.post('/organization/export', payload);
