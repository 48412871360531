import { useMemo } from 'react';
import ChatPromptContext, { ChatPromptContextInterface } from './ChatPromptContext';
import useChatPrompt from 'hooks/useChatPrompt';

interface ChatPromptProviderProps {
  children: React.ReactNode;
}

const ChatPromptProvider = ({ children }: ChatPromptProviderProps) => {
  const { data, isFetching, isSuccess } = useChatPrompt();

  const value: ChatPromptContextInterface = useMemo(() => {
    return {
      isSuccess,
      isLoading: isFetching,
      model: data?.[0]?.model,
      prompt: [],
      promptId: data?.[0]?.id,
    };
  }, [data, isFetching, isSuccess]);

  return <ChatPromptContext.Provider value={value}>{children}</ChatPromptContext.Provider>;
};

export default ChatPromptProvider;
