import { Routes, Route, Navigate } from 'react-router-dom';
import TrackRoute from 'components/TrackRoute';

import Layout from 'layouts/Auth';
import CompleteAccount from './CompleteAccount';
import Onboarding from './Onboarding';
import FeatureFlagProvider from 'components/FeatureFlag/FeatureFlagProvider';

const WelcomeRoutes = () => (
  <Routes>
    <Route path="/" element={<Layout hideHeaderNavLinks />}>
      <Route
        index
        element={
          <TrackRoute title="Welcome">
            <FeatureFlagProvider>
              <CompleteAccount />
            </FeatureFlagProvider>
          </TrackRoute>
        }
      />
      <Route
        path="onboarding"
        element={
          <TrackRoute title="Onboarding">
            <Onboarding />
          </TrackRoute>
        }
      />
      <Route path="*" element={<Navigate to="./" replace />} />
    </Route>
  </Routes>
);

export default WelcomeRoutes;
