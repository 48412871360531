import { Modal, ModalProps } from '@demandscience/ui';

import { ResultsView } from 'types';
import { Filters } from 'types/filters';

import SaveForm from './SaveForm';

interface SaveOverlayProps extends Omit<ModalProps, 'children'> {
  currentView: ResultsView;
  filters: Filters;
}

const SaveOverlay = ({ filters, currentView, ...props }: SaveOverlayProps) => {
  const { onClose } = props;

  return (
    <Modal
      aria-labelledby="save-search-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      {...props}
    >
      <Modal.Title id="save-search-dialog-title" className="flex mb-4">
        Save search
      </Modal.Title>
      <SaveForm
        filters={filters}
        currentView={currentView}
        onSuccess={onClose}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default SaveOverlay;
