import { ListOrigin, ListParams, ModelType, Show } from 'types';

const defaultAutomatedListParams = (kind: ModelType, count = 25): ListParams => ({
  index: 0,
  count,
  sort: {
    by: 'updated_at',
    descending: true,
  },
  filter: {
    show: Show.Usable,
    records_origin: ListOrigin.Automated,
    records_type: kind,
  },
});

export default defaultAutomatedListParams;
