import { createContext } from 'react';
import { ExportData, ListOrigin, ListRecordsAction } from 'types';

export interface ActionsContextInterface {
  action: (actionType: ListRecordsAction, exportData: ExportData) => void;
  editable: boolean;
  listId: string;
  listOrigin: ListOrigin;
}

const ActionsContext = createContext<ActionsContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  ActionsContext.displayName = 'ActionsContext';
}

export default ActionsContext;
