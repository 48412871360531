import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button, Combobox } from '@demandscience/ui';

import useMutateMember from 'components/Members/useMutateMember';
import { Account, allocation, BulkCredits } from 'types';
import CreditsUsage from './CreditsUsage';
import AvatarWithName from 'components/Account/AvatarWithName';
import useCredits from './useCredits';
import useOrganization from 'components/Organization/useOrganization';
import CreditsQuotaInputController from 'components/Input/CreditsQuotaInputController';
import { AxiosError } from 'axios';
import Select from 'components/Input/Select';

type ManageCreditsRequestFormValues = {
  allocation: allocation;
  bulk_credits: BulkCredits;
};

interface ManageCreditsRequestFormProps {
  onCancel: () => void;
  onSuccess: () => void;
  user: Account;
}

const ManageCreditsRequestForm = ({ user, onSuccess, onCancel }: ManageCreditsRequestFormProps) => {
  const { data: credits } = useCredits({ initialData: { bulk_credits: user.bulk_credits } });
  const { data: organization } = useOrganization(); // loading organization info to get total available credits

  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit, formState, watch, setValue } =
    useForm<ManageCreditsRequestFormValues>({
      defaultValues: {
        bulk_credits: credits?.bulk_credits,
        allocation: credits?.bulk_credits?.allocation,
      },
    });
  const { isSubmitting } = formState;
  const { update } = useMutateMember();
  const currentValue = watch('bulk_credits');

  const formBulkCredits = useMemo(() => {
    if (!organization) {
      return currentValue;
    }

    const { bulk_credits_usage: { available } = { available: 0 } } = organization;

    return {
      ...currentValue,
      available:
        currentValue.quota === -1 || currentValue.quota > available
          ? available
          : currentValue.quota - currentValue.used,
    };
    // only quta is changing and watch result can't be used a a dependency as it triggers no change
  }, [organization, currentValue.quota]); // eslint-disable-line react-hooks/exhaustive-deps

  const allocation: allocation = watch('allocation');
  useEffect(() => {
    if (allocation === 'unlimited') {
      setValue('bulk_credits.quota', -1);
    }
  }, [allocation, setValue]);

  const onSubmit: SubmitHandler<ManageCreditsRequestFormValues> = async (data) => {
    setApiError(null);

    try {
      const payload = {
        bulk_credits: {
          quota: parseInt(data.bulk_credits.quota.toString(), 10),
          allocation: allocation,
        },
      };

      await update.mutateAsync({ id: user.username, ...payload });

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="flex flex-col">
          <div className="py-3">
            <AvatarWithName user={user} />
          </div>
          <div className="py-2">
            <CreditsUsage bulkCredits={formBulkCredits} />
          </div>
          <Controller
            name="allocation"
            control={control}
            render={({ field }) => (
              <Select
                label="Credit allocation"
                component={Combobox}
                options={[
                  { label: 'Unlimited', value: 'unlimited' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Fixed', value: 'fixed' },
                ]}
                // @ts-expect-error demandscience-ui issue

                hideClear
                {...field}
              />
            )}
            rules={{ required: 'Required field' }}
          />
          <div className="py-4">
            <div className="space-y-2">
              <CreditsQuotaInputController<ManageCreditsRequestFormValues>
                allocation={allocation}
                name="bulk_credits.quota"
                control={control}
              />

              {apiError && <div className="text-error-500 text-center">{apiError}</div>}
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-2 mt-6">
          <Button borderless onClick={onCancel} type="button">
            Cancel
          </Button>
          <Button type="submit" theme="primary" disabled={isSubmitting}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ManageCreditsRequestForm;
