import { useMemo } from 'react';
import { DropdownMenu, IconButton } from '@demandscience/ui';
import InfoIcon from '@demandscience/ui/icons/info';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';
import TextTooltip from 'components/Tooltip/TextTooltip';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';
import { KlarityFeature } from 'types';

interface CompanyMenuProps {
  onView: (view: string) => void;
}
type ResItem = {
  action: () => void;
  label: string | JSX.Element;
};

const CompanyMenu: React.FC<CompanyMenuProps> = ({ onView }) => {
  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);
  const options = useMemo(() => {
    const iscontactRendered: boolean = isContactDisabled || isContactRenderDisabled;

    let res: ResItem[] = [
      {
        label: 'View employees',
        action: () => {
          onView('employees');
        },
      },
      {
        label: (
          <>
            View similar companies
            <div className="inline-flex ml-1 align-middle">
              <TextTooltip
                title={
                  <span className="block w-[160px]">
                    View companies with similar company profiles
                  </span>
                }
                placement="left"
              >
                <InfoIcon className="w-4 h-4" />
              </TextTooltip>
            </div>
          </>
        ),
        action: () => {
          onView('similarCompanies');
        },
      },
    ];

    function removeViewEmployees(arr: ResItem[]): ResItem[] {
      return arr.filter((item) => {
        if (typeof item.label === 'string') {
          return item.label !== 'View employees';
        }
        return true;
      });
    }

    if (iscontactRendered) {
      res = removeViewEmployees(res);
    }

    return res;
  }, [isContactDisabled, isContactRenderDisabled, onView]);

  return (
    <DropdownMenu
      options={options}
      buttonType="custom"
      customButton={<IconButton size="sm" theme="default" Icon={MoreVerticalIcon} />}
      // dropdownClassName="overflow-visible" // overflow-visible is here to have nested tooltip visible
      origin="bottom-right"
    />
  );
};

export default CompanyMenu;
