type JobFunctionData = {
  function: string;
};

type JobFunction = {
  count: number;
  data: JobFunctionData;
  value: string;
};

type AggregationResults = {
  [key: string]: JobFunction[];
};

type InputEntry = {
  created_at: string;
  result: {
    aggregation_results: AggregationResults;
  };
};

type AggregatedResult = {
  count: number;
  data: JobFunctionData;
};

type FinalValue = {
  count: number;
  data: JobFunctionData;
  value: string;
};

type FinalFormat = {
  count: number;
  values: FinalValue[];
};

export function aggregateJobFunctions(input: InputEntry[], created_at: string): FinalFormat {
  function filterByCreatedAt(input: InputEntry[], createdAt: string): InputEntry[] {
    return input.filter((entry) => entry.created_at === createdAt);
  }

  function aggregateCounts(filteredInput: InputEntry[]): { [key: string]: AggregatedResult } {
    const aggregatedResults: { [key: string]: AggregatedResult } = {};

    filteredInput.forEach((entry) => {
      Object.values(entry.result.aggregation_results).forEach((aggregation) => {
        aggregation.forEach((jobFunction) => {
          const { value, count, data } = jobFunction;
          if (aggregatedResults[value]) {
            aggregatedResults[value].count += count;
          } else {
            aggregatedResults[value] = { count, data };
          }
        });
      });
    });

    return aggregatedResults;
  }

  const filteredInput = filterByCreatedAt(input, created_at);

  const aggregatedResults = aggregateCounts(filteredInput);

  const finalValues: FinalValue[] = Object.keys(aggregatedResults).map((key) => {
    return {
      value: key,
      count: aggregatedResults[key].count,
      data: aggregatedResults[key].data,
    };
  });

  const totalCount = finalValues.reduce((acc, curr) => acc + curr.count, 0);

  const finalFormat: FinalFormat = {
    values: finalValues,
    count: totalCount,
  };

  return finalFormat;
}
