import { useCallback } from 'react';
import { includes, map, without } from 'lodash';
import { CheckboxIcon, Link } from '@demandscience/ui';
import { Month, monthValues, monthLabels } from 'types';

interface SelectMonthProps {
  onChange: (value?: Array<Month>) => void;
  value?: Array<Month>;
}

const SelectMonth = ({ value, onChange }: SelectMonthProps) => {
  const handleClick = useCallback(
    (month: Month) => () => {
      if (includes(value, month)) {
        onChange(without(value, month));
      } else {
        onChange([...(value || []), month]);
      }
    },
    [onChange, value],
  );

  const handleClearAll = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  return (
    <div className="flex flex-col items-start gap-2">
      <div className="grid grid-cols-3 gap-2">
        {map(monthValues, (month) => (
          <button
            key={month}
            className="flex flex-row gap-2 items-center"
            onClick={handleClick(month)}
          >
            <div className="flex-shrink-0">
              <CheckboxIcon size={18} theme="primary" checked={includes(value, month)} />
            </div>
            <div className="text-sm text-gray-700 ellipsis">{monthLabels[month]}</div>
          </button>
        ))}
      </div>
      <Link size="sm" onClick={handleClearAll}>
        Clear selection
      </Link>
    </div>
  );
};

export default SelectMonth;
