import { Company } from 'types';
import classNames from 'classnames';
import UsersIcon from '@demandscience/ui/icons/user';
import ListItemText from 'components/Layout/ListItemText';
import { overrideTailwindClasses } from 'tailwind-override';

interface HeadcountProps {
  className?: string;
  inline?: boolean;
  row?: Company;
  suffix?: string;
}

const Headcount = ({ className, row, inline, suffix = 'employees' }: HeadcountProps) => {
  if (!row?.employees_size_range) {
    return null;
  }

  if (inline) {
    return (
      <p className={overrideTailwindClasses(classNames('text-sm text-gray-700', className))}>
        {row.employees_size_range.size_range} {suffix}
      </p>
    );
  }

  return (
    <ListItemText
      leadingContent={<UsersIcon className="w-5 h-5" />}
      overline="Headcount"
      title={row.employees_size_range.size_range}
    />
  );
};

export default Headcount;
