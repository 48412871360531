import classNames from 'classnames';
import { IconButton, Tooltip } from '@demandscience/ui';
import PinIcon from '@demandscience/ui/icons/pin';
import PinFilledIcon from '@demandscience/ui/icons/pin-filled';
import { useCallback } from 'react';

interface TogglePinIconButtonProps {
  checked: boolean;
  onClick: () => void;
}

const TogglePinIconButton: React.FC<TogglePinIconButtonProps> = ({
  checked,
  onClick,
  ...props
}) => {
  const handleClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();

      onClick();
    },
    [onClick],
  );

  return (
    <Tooltip title={checked ? 'Unpin action' : 'Pin action'} position="bottom-right" showArrow>
      <IconButton
        size="xs"
        Icon={checked ? PinFilledIcon : PinIcon}
        className={classNames({ 'text-primary-500': checked })}
        onClick={handleClick}
        {...props}
      />
    </Tooltip>
  );
};

export default TogglePinIconButton;
