import map from 'lodash/map';
import some from 'lodash/some';
import filter from 'lodash/filter';
import {
  CompanyFilterName,
  CompanyNameFilter,
  ContactFilterName,
  ContactNameFilter,
  ExcludableFilter,
  Filters,
  ListFilterName,
} from 'types';

export const withoutInclude = (filters: Filters) => {
  const {
    [CompanyFilterName.CompanyName]: companyName,
    [ContactFilterName.ContactName]: contactName,
    [ListFilterName.InclusionList]: inclusionList,
    ...rest
  } = filters;

  let res: Filters = rest;
  if (some(companyName, 'exclude')) {
    const companyNameExcludes = filter(
      companyName,
      (value: ExcludableFilter) => value.exclude,
    ) as CompanyNameFilter;

    res = {
      ...res,
      [CompanyFilterName.CompanyName]: companyNameExcludes,
    };
  }
  if (some(contactName, 'exclude')) {
    const contactNameExcludes = filter(
      contactName,
      (value: ExcludableFilter) => value.exclude, // keep the existing excludes
    ) as ContactNameFilter;

    res = {
      ...res,
      [ContactFilterName.ContactName]: contactNameExcludes,
    };
  }

  return res;
};

export const includeToExclude = (filters: Filters) => {
  const {
    [CompanyFilterName.CompanyName]: companyName,
    [ContactFilterName.ContactName]: contactName,
    [ListFilterName.InclusionList]: inclusionList,
    [ListFilterName.ExclusionList]: exclusionList,
  } = filters;

  let res = {};
  if (companyName) {
    res = {
      ...res,
      [CompanyFilterName.CompanyName]: map(companyName, (filter) => ({ ...filter, exclude: true })), // switch all the filtering values to exclude
    };
  }
  if (contactName) {
    res = {
      ...res,
      [ContactFilterName.ContactName]: map(contactName, (filter) => ({ ...filter, exclude: true })), // switch all the filtering values to exclude
    };
  }
  if (inclusionList) {
    // preserving any existing suppression list
    res = {
      ...res,
      [ListFilterName.ExclusionList]: [...(exclusionList || []), ...inclusionList],
    };
  }

  return res;
};
