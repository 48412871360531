import { useCallback, useState } from 'react';

import { Button, Modal } from '@demandscience/ui';
import useAuth from 'components/Auth/useAuth';
import useSnackbar from 'components/Snackbar/useSnackbar';
import ConfirmOverlay from 'components/ConfirmOverlay';

import { Member } from 'types';
import useMutateMember from './useMutateMember';
import ReactivateOverlay from './ReactivateOverlay';
import { AxiosError } from 'axios';

interface ToggleStateControlProps {
  as: React.ElementType;
  children: React.ReactNode;
  member: Member;
  onSuccess: () => void;
}

const ToggleStateControl: React.FC<ToggleStateControlProps> = ({
  member,
  as: Component = Button,
  onSuccess,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { showMessage } = useSnackbar();
  const { suspend } = useMutateMember();

  const handleSuspend = useCallback(async () => {
    if (user?.username === member.username) {
      showMessage('You cannot suspend your own account', 'error');
    } else {
      try {
        await suspend.mutateAsync(member.username);

        if (onSuccess) onSuccess();
      } catch (e: any) {
        if (e instanceof AxiosError) {
          showMessage(e.response?.data?.error || 'Unable to suspend a member account', 'error');
        } else {
          showMessage('Unexpected error, please try again later', 'error');
        }
      }
    }
  }, [member.username, onSuccess, showMessage, suspend, user?.username]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (member.state === 'active') {
    return (
      <>
        <Component onClick={handleOpen} disabled={suspend.isLoading} {...props} />
        <ConfirmOverlay
          open={open}
          onClose={handleClose}
          onConfirm={handleSuspend}
          confirmLabel="YES, deactivate"
        >
          <Modal.Title className="mb-4">
            Are you sure you want to deactivate this account?
          </Modal.Title>
        </ConfirmOverlay>
      </>
    );
  }

  if (member.state === 'suspended') {
    return (
      <>
        <Component onClick={handleOpen} {...props} />
        <ReactivateOverlay member={member} open={open} onClose={handleClose} />
      </>
    );
  }

  return null;
};

export default ToggleStateControl;
