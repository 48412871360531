import api from 'api';
import axios from 'axios';
import fileDownload from 'js-file-download';

import {
  UploadListPayload,
  SignedUrlParams,
  BounceSignedUrlResponse,
  UploadBounceResponse,
  CreateBouncePayload,
  DeleteBouncePayload,
  ModelType,
  BounceDownloadResponse,
} from 'types';

const getSignedUrl = (params: SignedUrlParams): Promise<BounceSignedUrlResponse> =>
  api.get('/bounce/upload', { params });

export const uploadBounceFile = async ({
  file,
  onUploadProgress,
  controller,
}: UploadListPayload): Promise<UploadBounceResponse> => {
  const file_name = file.name;
  // upload consists of the two calls, one to get signed url, and one to upload the file
  const { signed_url, bounce_id } = await getSignedUrl({
    objectName: file_name,
    records_type: ModelType.Company,
  });

  // upload the file to signed url
  await axios.put(signed_url, file, {
    onUploadProgress,
    signal: controller?.signal,
  });

  return { bounce_id, file_name };
};

export const createBounce = (payload: CreateBouncePayload): Promise<void> =>
  api.post('/bounce/', payload);

export const deleteBounce = ({ bounce_id, ...data }: DeleteBouncePayload): Promise<void> =>
  api.delete(`organization/bounce/${bounce_id}`, { data });

export const downloadBounceFile = async (id: string, filename: string): Promise<void> => {
  const { url }: BounceDownloadResponse = await api.get(`/organization/bounce/${id}/download`);
  const { data } = await axios.get(url, { responseType: 'blob' });
  fileDownload(data, filename);
};
