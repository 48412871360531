import { FilterProps } from './types';
import { CompanyFilterName, badgeDisplayCap } from 'types/filters';
import LocationFilter from './LocationFilter';

const CompanyLocationFilter = (props: FilterProps) => (
  <LocationFilter
    name={CompanyFilterName.CompanyLocation}
    badgeDisplayCap={badgeDisplayCap}
    {...props}
  />
);

export default CompanyLocationFilter;
