import classNames from 'classnames';

import Avatar from 'components/Avatar';
import MemberDropdown from './MemberDropdown';
import { TableColumn } from 'types';
import StateBadge from './StateBadge';
import { truncate } from 'lodash';
import { stopPropagation } from 'utils/event';

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const columns: TableColumn<any>[] = [
  {
    field: 'name',
    headerName: 'Member',
    sortable: true,
    valueGetter: (member) => {
      const { name } = member;

      return (
        <div className="space-x-7">
          <Avatar className="w-8 h-8" user={member} />
          <span>{truncate(name, { length: 36 })}</span>
        </div>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
  },
  {
    field: 'roles',
    headerName: 'Role',
    valueGetter: (member) => <StateBadge member={member} />,
  },
  {
    field: 'bulk_credits.used',
    headerName: 'Credits monthly usage',
    sortable: true,
    valueGetter: ({ bulk_credits }) => {
      const { quota, current_period_used, available } = bulk_credits;
      const total = current_period_used + available;

      return (
        <span className={classNames({ 'text-error-500': available < 10 })}>
          {quota === -1
            ? `${current_period_used.toLocaleString()} / Unlimited`
            : `${current_period_used.toLocaleString()} / ${total.toLocaleString()}`}
        </span>
      );
    },
  },
  {
    field: 'last_signin_at',
    headerName: 'Last Sign-in',
    sortable: true,
    valueGetter: ({ last_signin_at }) =>
      last_signin_at ? new Date(last_signin_at).toLocaleDateString(undefined, dateOptions) : null,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    className: 'flex justify-end',
    valueGetter: (row) => (
      <div className="text-right" onClick={stopPropagation}>
        <MemberDropdown member={row} withPortal />
      </div>
    ),
  },
];

export default columns;
