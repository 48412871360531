import { Navigate } from 'react-router-dom';

import useAuth from 'components/Auth/useAuth';
import AccountComplete from 'components/Account/AccountComplete';
import Heading from 'layouts/Auth/Heading';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';
import { KlarityFeature } from 'types';

const CompleteAccount = () => {
  const { user } = useAuth();

  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);

  const iscontactRendered = isContactDisabled || isContactRenderDisabled;

  if (user!.name) {
    // if this is the 1st signin, show onboarding view
    if (user!.number_of_signins === 1 && !iscontactRendered) {
      return <Navigate to="/welcome/onboarding" replace={true} />;
    }

    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <Heading
        title="One last thing..."
        description="Provide your name to complete your account."
      />
      <AccountComplete />
    </>
  );
};

export default CompleteAccount;
