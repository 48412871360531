import classNames from 'classnames';
import JobTitle from 'components/Contact/JobTitle';
import Industry from 'components/Company/Industry';
import { Checkbox } from '@demandscience/ui';
import Headcount from 'components/Company/Headcount';
import ContactName from 'components/Contact/ContactName';
import { Contact, KlarityFeature, ModelRowProps, ModelType, RowLayout } from 'types';
import ContactAvatar from 'components/Contact/ContactAvatar';
import { DataGridRecordActions } from './DataGridRecordActions';
import ContactDetailsCell from 'components/DataGrid/ContactDetailsCell';
import ContactCompanyCell from 'components/DataGrid/ContactCompanyCell';
import ContactSocialProfiles from 'components/Contact/ContactSocialProfiles';
import { stopPropagation } from 'utils/event';
import ExportProvider from 'components/Export/ExportProvider';
import InstallTopic from 'components/Company/InstallTopic';
import IntentTopic from 'components/Company/IntentTopic';
import Specialities from 'components/Company/Specialities';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

const NUMBER_OF_ROWS_TO_DISPLAY_DETAILS_ON_TOP = 5;

const ContactRow = ({
  active,
  dense,
  row,
  selected,
  selectionDisabled,
  onSelect,
  onClick,
  rowLayout = RowLayout.Narrow,
  lastItem,
  nbOfRows = 0,
}: ModelRowProps<Contact>) => {
  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);

  const company = row.companies[0];

  const displayContactDetailsOnTop =
    lastItem && nbOfRows >= NUMBER_OF_ROWS_TO_DISPLAY_DETAILS_ON_TOP;

  return (
    <li
      className={classNames('px-2 transition border-b cursor-pointer', {
        'py-4': !dense,
        'py-2': dense,
        'bg-primary-50 hover:bg-primary-100': selected,
        'bg-primary-100': selected && active,
        'hover:bg-gray-50': !selected,
        'bg-gray-50': !selected && active,
      })}
      onClick={onClick}
    >
      <div
        className={classNames('grid gap-4 text-sm', {
          'md:grid-cols-6': rowLayout === RowLayout.Narrow,
          'md:grid-cols-12': rowLayout === RowLayout.Wide,
        })}
      >
        {/* Name & Avatar */}
        <div
          className={classNames('flex space-x-2', {
            'md:col-span-2': rowLayout === RowLayout.Narrow,
            'md:col-span-3': rowLayout === RowLayout.Wide,
          })}
        >
          <div onClick={stopPropagation}>
            <Checkbox
              checked={selected}
              onChange={onSelect}
              className="self-start"
              disabled={selectionDisabled}
            />
          </div>
          <ContactAvatar className="hidden md:grid w-10 h-10 text-sm" row={row} colored />
          <div>
            <ContactName row={row} />
            {rowLayout === RowLayout.Narrow && <JobTitle row={row} />}
            <ContactSocialProfiles className="mt-2" inline row={row} onClick={stopPropagation} />
          </div>
        </div>

        {/* Position */}
        {rowLayout === RowLayout.Wide && (
          <div className="md:col-span-2">
            <JobTitle row={row} />
          </div>
        )}

        {/* Contact */}
        {!isContactDisabled && !isContactRenderDisabled && (
          <div
            className={classNames('ml-9 md:ml-0', {
              'md:col-span-1': rowLayout === RowLayout.Narrow,
              'md:col-span-2': rowLayout === RowLayout.Wide,
            })}
          >
            <ContactDetailsCell
              className="cursor-default md:flex md:justify-center"
              row={row}
              displayContactDetailsOnTop={displayContactDetailsOnTop}
            />
          </div>
        )}

        {/* Company */}
        <div className="md:col-span-2 ml-11 md:ml-0">
          <ContactCompanyCell row={company} rowLayout={rowLayout} />
        </div>

        {/* Industry & headcount (wide layout only) */}
        {rowLayout === RowLayout.Wide && (
          <div className="md:col-span-2 ml-11 md:ml-0">
            <Industry inline className="text-xs font-medium ellipsis" row={company} />
            <Headcount inline className="text-xs text-gray-500" row={company} />
          </div>
        )}

        {/* Actions */}
        <div
          className="md:col-span-1 flex items-start justify-end ml-11 md:ml-0 cursor-default"
          onClick={stopPropagation}
        >
          <ExportProvider modelType={ModelType.Contact} selectionCount={1}>
            <DataGridRecordActions
              record={row}
              displayContactDetailsOnTop={displayContactDetailsOnTop}
            />
          </ExportProvider>
        </div>
      </div>
      <div className="flex flex-row gap-2 items-center justify-end">
        <Specialities inline row={row.companies[0]} />
        <InstallTopic inline row={row.companies[0]} />
        <IntentTopic inline row={row.companies[0]} />
      </div>
    </li>
  );
};

export default ContactRow;
