import { useRef } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import some from 'lodash/some';
import map from 'lodash/map';
import { SearchResponse } from 'types';

const useQueryAggregate = (
  queries: UseQueryResult[],
  dataMapper: undefined | string | ((data: UseQueryResult<SearchResponse>) => any) = 'data',
) => {
  const data = useRef<any>([]);
  const isLoading = some(queries, (query) => query.isLoading);
  const isFetching = some(queries, (query) => query.isFetching);
  const isError = some(queries, (query) => query.isError);
  const isPreviousData = some(queries, (query) => query.isPreviousData);

  // update data when all query have data fetched
  if (!isLoading && !isFetching && !isError) {
    // @ts-expect-error
    data.current = map(queries, dataMapper);
  }

  return {
    isLoading,
    isFetching,
    isError,
    isPreviousData,
    data: data.current,
  };
};

export default useQueryAggregate;
