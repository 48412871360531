import { useQuery } from '@tanstack/react-query';

import { fetchCredits } from 'api/account';

const useCredits = (options?: Record<string, any>) => {
  const query = useQuery(['account', 'credits'], fetchCredits, {
    staleTime: 60 * 1000, // 1 minute
    ...options,
  });

  return query;
};

export default useCredits;
