import {
  Contact,
  Filters,
  Model,
  ModelType,
  SortBy,
  AccountBase,
  PaginationResponse,
  SearchParams,
  Constraints,
} from 'types';

export type ExportType =
  | 'salesforce'
  | 'csv'
  | 'toList'
  | 'reveal'
  | 'linkedin'
  | 'marketo'
  | 'hubspot';

export type ExportMapping =
  | 'contacts to contacts'
  | 'contacts to leads'
  | 'companies to accounts'
  | 'companies to companies';

export type AdvancedSelection = {
  limit?: {
    max_records?: number;
  };
};

export const DEFAULT_MAX_RECORDS: AdvancedSelection['limit'] = {
  max_records: 100000,
};

type RevealWithSearchPayload = {
  advanced_selection?: AdvancedSelection;
  constraints?: Constraints;
  dry_run?: boolean;
  filters: Filters;
  sort?: SortBy;
};

type RevealResponse = {
  already_acquired: number;
  can_be_revealed: number;
  error?: string;
  requested: number;
  revealed: number;
};

export type RevealContactsPayload = RevealWithSearchPayload;

export type RevealContactsResponse = {
  hits: Contact[];
  status?: 'processing';
} & RevealResponse;

export type ExportPayload = {
  exclude_acquired_records?: true;
  export_mapping?: ExportMapping;
  export_name?: string;
  export_type?: ExportType;
  record_type: ModelType;
} & RevealWithSearchPayload;

export type ExportResponse = {
  export_id?: string;
  hits?: Model[];
  status?: 'processing';
} & RevealResponse;

export enum MessageType {
  CheckInstallation = 'EXTERNAL_MESSAGE_CHECK_INSTALLATION',
  GoToList = 'EXTERNAL_MESSAGE_GO_TO_LIST',
  SelectedRecords = 'EXTERNAL_MESSAGE_SELECTED_RECORDS',
}

type ExtensionMessage<T extends MessageType> = {
  type: T;
};

export type CheckInstallationExtensionMessage = ExtensionMessage<MessageType.CheckInstallation>;

type ExtensionMessageWithPayload<T extends MessageType, P extends any> = ExtensionMessage<T> & {
  payload: P;
  userId: string;
};

export type FilterRecordsExtensionMessage = ExtensionMessageWithPayload<
  MessageType.SelectedRecords,
  {
    advancedSelection?: AdvancedSelection;
    constraints?: Constraints;
    filters: Filters;
    sort?: SortBy;
    type: ModelType;
  }
>;

export type OpenListExtensionMessage = ExtensionMessageWithPayload<
  MessageType.GoToList,
  {
    description?: string;
    id: string;
    name: string;
  }
>;

export type ExtensionMessageResponse = {
  payload: {
    message: string;
    status: 'success';
  };
  type: 'EXTERNAL_MESSAGE_ACKNOWLEDGE';
};

export const exportDestinations = {
  csv: 'CSV',
  'salesforce:contacts to contacts': 'Salesforce (contacts)',
  'salesforce:contacts to leads': 'Salesforce (leads)',
  'salesforce:companies to accounts': 'Salesforce (accounts)',
  'hubspot:contacts to contacts': 'Hubspot (contacts)',
  'hubspot:companies to companies': 'Hubspot (companies)',
  'marketo:contacts to leads': 'Marketo (leads)',
};

export type ExportDestination = keyof typeof exportDestinations;

export enum ExportStatus {
  Completed = 'completed',
  Error = 'error',
  Processing = 'processing',
}

export type Export = {
  account?: AccountBase;
  created_at: string;
  destination: ExportDestination;
  id: string;
  identifier: string;
  records: number;
  status: ExportStatus;
  type: ModelType;
};

export type ExportsResponse = PaginationResponse & {
  exports: Export[];
};

export type ExportsFilter = {
  account_freetext?: string;
  destinations?: ExportDestination[];
  record_types?: ModelType[];
};

export type SearchExportsParams = SearchParams<ExportsFilter>;
