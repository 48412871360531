import { useCallback, useEffect, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { AxiosError, CanceledError } from 'axios';
import { round } from 'lodash';

import { Link, CircularProgress } from '@demandscience/ui';

import FilePlusIcon from '@demandscience/ui/icons/file-plus';
import CheckIcon from '@demandscience/ui/icons/check';
import XIcon from '@demandscience/ui/icons/x';
import AlertTriangleIcon from '@demandscience/ui/icons/alert-triangle';

import { ListRecordsLimit, ModelType, UploadListResponse } from 'types';
import { stopPropagation } from 'utils/event';

import useMutateList from './useMutateList';

interface UploadListFileProps {
  onSuccess: (res: UploadListResponse) => void;
  onUploading?: (value: boolean) => void;
  typeSelection: ModelType;
}

const UploadListFile = ({ typeSelection, onUploading, onSuccess }: UploadListFileProps) => {
  const controllerRef = useRef<AbortController | null>(null);
  const [fileRejectionError, setFileRejectionError] = useState<'length' | 'content' | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const { upload } = useMutateList();
  const { reset } = upload;

  const handleUploadProgress = (event: ProgressEvent) => {
    setProgress(round((event.loaded * 100) / event.total));
  };

  const handleFileChange = async (files: File[]) => {
    setProgress(0);

    try {
      // instantiate new controller for each upload
      controllerRef.current = new AbortController();

      const [file] = files;
      const response = await upload.mutateAsync({
        file,
        records_type: typeSelection,
        onUploadProgress: handleUploadProgress,
        controller: controllerRef.current,
      });

      onSuccess(response);
    } catch (e: any) {
      if (e instanceof CanceledError) {
        // ignoring cancel error and just reset the upload
        reset();
      } else if (e instanceof AxiosError) {
        if (e.response?.data?.error_code === 'limit_exceeded') {
          setFileRejectionError('length');
        }
      }
    }
  };

  const handleFileRejection = (files: FileRejection[]) => {
    if (files?.length > 0) setFileRejectionError('content');
  };

  const handleCancel = useCallback(() => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    setFileRejectionError(null);
  }, []);

  const handleTryAgain = useCallback(() => {
    reset();
    setFileRejectionError(null);
  }, [reset]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileChange,
    maxFiles: 1,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    onDropRejected: handleFileRejection,
  });

  useEffect(() => {
    if (onUploading) onUploading(upload.isLoading);
  }, [onUploading, upload.isLoading]);

  useEffect(() => {
    handleTryAgain();
  }, [typeSelection, handleTryAgain]);

  return (
    <div className="border border-blue-400 rounded bg-gray-50 min-h-[200px]">
      {upload.isSuccess && (
        <div className="flex flex-col items-center gap-2 pt-8">
          <div className="bg-success-50 text-success-500 rounded-full p-3">
            <CheckIcon size={32} />
          </div>
          <div className="text-sm text-gray-700 text-center">File uploaded successfully</div>
        </div>
      )}
      {upload.isError && !fileRejectionError && (
        <div className="flex flex-col items-center gap-2 pt-8">
          <div className="bg-error-100 text-error-500 rounded-full p-3">
            <XIcon size={32} />
          </div>
          <div className="text-sm text-gray-700 text-center">
            There was a problem uploading your list{' '}
            <Link className="font-normal" as="button" type="button" onClick={handleTryAgain}>
              Try again
            </Link>
          </div>
        </div>
      )}
      {fileRejectionError === 'length' && (
        <div className="flex flex-col items-center gap-2 pt-8">
          <div className="bg-warning-100 text-warning-500 rounded-full p-3">
            <AlertTriangleIcon size={32} />
          </div>
          <div className="text-sm text-gray-700 text-center">
            Your file has too many items
            <br />
            {typeSelection === ModelType.Company &&
              `A list can contain a max of ${Number(
                ListRecordsLimit.company,
              ).toLocaleString()} companies.`}
            <br />
            <Link className="font-normal" as="button" type="button" onClick={handleTryAgain}>
              Try again
            </Link>
          </div>
        </div>
      )}
      {fileRejectionError === 'content' && (
        <div className="flex flex-col items-center gap-2 pt-8">
          <div className="bg-error-100 text-error-500 rounded-full p-3">
            <XIcon size={32} />
          </div>
          <div className="text-sm text-gray-700 text-center">
            An invalid file type was selected.
            <br /> Please select an Excel or a CSV file to upload. <br />
            <Link className="font-normal" as="button" type="button" onClick={handleTryAgain}>
              Try again
            </Link>
          </div>
        </div>
      )}
      {upload.isLoading && (
        <div className="flex flex-col items-center gap-2 pt-5">
          <CircularProgress value={progress} />
          <div className="text-sm text-gray-700 text-center">
            Uploading file...{' '}
            <Link className="font-normal" as="button" type="button" onClick={handleCancel}>
              Cancel
            </Link>
          </div>
        </div>
      )}
      {upload.isIdle && (
        <div
          {...getRootProps({
            className: 'flex flex-col items-center pt-8 cursor-pointer',
          })}
        >
          <input {...getInputProps()} />
          <FilePlusIcon className="text-blue-500 stroke-1" size={35} />
          <div className="my-4 text-center space-y-1 max-w-[400px]">
            <div className="text-xs text-gray-600">
              Drop your Excel or CSV file here or <span className="text-blue-500">browse</span>
            </div>
            <div className="text-xs text-gray-700 py-4 space-y-1">
              {typeSelection === ModelType.Contact && (
                <>
                  <p>We support email addresses or LinkedIn urls of contacts.</p>
                  <p className="font-semibold">
                    Values can be mixed but must be in the first column.
                  </p>
                </>
              )}
              {typeSelection === ModelType.Company && (
                <>
                  <p>We support domains, names or LinkedIn urls of companies.</p>
                  <p className="font-semibold">
                    Values can be mixed but must be in the first column.
                  </p>
                </>
              )}
              <Link
                className="text-xs"
                href={`/download/${
                  typeSelection === ModelType.Contact ? 'contacts' : 'companies'
                }.xlsx`}
                onClick={stopPropagation}
                download
              >
                Download example.
              </Link>
            </div>
            <div className="text-xs text-gray-500 pt-2">
              {typeSelection === ModelType.Company &&
                `Max ${Number(ListRecordsLimit.company).toLocaleString()} companies`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadListFile;
