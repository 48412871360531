import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@demandscience/ui';
import ChevronRightIcon from '@demandscience/ui/icons/chevron-right';
import { ReactNode } from 'react';

export interface SectionProps {
  action?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  title: string | ReactNode;
  titleClassName?: string;
  to?: string;
}

const Section: React.FC<SectionProps> = ({
  className,
  title,
  titleClassName,
  to,
  action,
  children,
}) => (
  <div className={className}>
    {(title || to) && (
      <div className="flex justify-between mb-4">
        <div
          className={overrideTailwindClasses(
            classNames('text-lg font-medium text-gray-700', titleClassName),
          )}
        >
          {title}
        </div>
        {to && (
          <Link className="no-underline" as={RouterLink} to={to}>
            View all <ChevronRightIcon className="w-4 h-4 ml-1" />
          </Link>
        )}
        {action}
      </div>
    )}
    {children}
  </div>
);

export default Section;
