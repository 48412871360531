import { cloneElement } from 'react';

import { Spinner } from '@demandscience/ui';
import FiltersProvider from 'components/Filters/FiltersProvider';

import map from 'lodash/map';
import reduce from 'lodash/reduce';

import useFieldsAggregate from './useFieldsAggregate';
import { CompanyFilters, Filters } from 'types/filters';
import { aggregateFieldsToFilterName } from 'hooks/useFieldAggregate';
import { AggregateField } from 'types';
import { isEmpty } from 'lodash';

interface ProvidersProps {
  children: React.ReactElement;
  companyFilters: CompanyFilters;
  filters: Filters;
}

const Providers = ({ filters, companyFilters, children }: ProvidersProps) => {
  const { aggregates, isFetching } = useFieldsAggregate({
    contactsFilters: filters,
    companiesFilters: companyFilters,
  });

  if (isFetching) {
    return (
      <div className="py-4">
        <Spinner className="mx-auto" size="lg" />
      </div>
    );
  }

  const tamFilters = reduce(
    aggregates,
    (res, value, key) =>
      !isEmpty(value.values)
        ? {
            ...res,
            [aggregateFieldsToFilterName[key as AggregateField]]:
              value.values &&
              map(value.values, ({ data }) => ({
                ...data,
                exclude: false,
              })),
          }
        : res,
    {},
  );

  return (
    <FiltersProvider filters={tamFilters}>
      {cloneElement(children, { aggregates, searchFilters: filters })}
    </FiltersProvider>
  );
};

export default Providers;
