import { useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Button, Spinner, DrawerRootProps, TextField } from '@demandscience/ui';
import SearchIcon from '@demandscience/ui/icons/search';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';

import Drawer from 'components/Drawer';
import useSavedSearches from './useSavedSearches';
import SavedSearchesList from './SavedSearchesList';
import RecentSearchesList from 'components/RecentSearches/RecentSearchesList';
import useRecentSearches from 'components/RecentSearches/useRecentSearches';
import { KlarityFeature, Show } from 'types';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';

type Tabs = 'All' | 'Yours' | 'Shared with you' | 'History';

const searchFilters = [
  {
    value: Show.All,
    label: 'All',
  },
  {
    value: Show.Own,
    label: 'Yours',
  },
  {
    value: Show.Shared,
    label: 'Shared with you',
  },
];

interface SavedSearchesSidebarProps extends DrawerRootProps {
  featureContext?: KlarityFeature;
  tab?: Tabs;
}

const SavedSearchesSidebar = ({ tab, featureContext, ...props }: SavedSearchesSidebarProps) => {
  const [currentTab, setCurrentTab] = useState<Tabs>(tab as Tabs);
  const [show, setShow] = useState<Show>(Show.All);
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');
  const {
    data: savedSearchesData,
    isLoading,
    isFetching: savedSearchesIsFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useSavedSearches({ filter: { show, is_a_persona: false } });

  let searches = flatten(map(savedSearchesData?.pages, 'searches'));

  // Temporarily filter out searches containing a contact_location filter
  searches = searches.filter((search) => {
    const { filters } = search;
    if (filters?.hasOwnProperty('contact_location')) {
      return false;
    }
    return search;
  });

  const { ref, inView } = useInView();

  const { data: recentSearchesData, isFetching: recentSearchesIsFetching } = useRecentSearches({
    enabled: currentTab === 'History',
  });

  const handleFilterClick = useCallback(
    (value: Show, label: Tabs) => () => {
      setShow(value);
      setCurrentTab(label);
    },
    [],
  );

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleTabClick = useCallback(
    (tab: Tabs) => () => {
      setCurrentTab(tab);
    },
    [],
  );

  useEffect(() => {
    if (inView) {
      handleLoadMore();
    }
  }, [inView, handleLoadMore]);

  const isFetching = savedSearchesIsFetching || recentSearchesIsFetching;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = e.target.value.toLowerCase();
    setSearchQuery(searchString);
  };

  return (
    <Drawer id="searches_drawer" title="Your searches" loading={isFetching} {...props}>
      <div className="px-6 py-4 border-b flex flex-row gap-6 items-center">
        {map(searchFilters, ({ value, label }) => (
          <Button
            key={label}
            borderless
            size="md"
            theme="white"
            noRing
            className={classNames('px-0 py-0 text-gray-500 font-normal', {
              'text-gray-700 font-semibold': show === value && currentTab !== 'History',
            })}
            onClick={handleFilterClick(value, label as Tabs)}
          >
            {label}
          </Button>
        ))}
        <span className="text-gray-500">|</span>
        <Button
          borderless
          size="md"
          theme="white"
          noRing
          className={classNames('px-0 py-0 ring-0 text-gray-700 font-normal', {
            'text-gray-700 font-semibold': currentTab === 'History',
          })}
          onClick={handleTabClick('History')}
        >
          History
        </Button>
      </div>
      {isLoading && (
        <div className="p-4">
          <Spinner size="lg" />
        </div>
      )}

      <div className="px-6 pt-4">
        <div className="border-b border-gray-200 pb-4">
          <TextField
            leadingIcon={<SearchIcon className="stroke-gray-400" size={20} />}
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Find a search"
            type="text"
            variant="basic"
            hideLabel
          />
        </div>
      </div>

      {currentTab !== 'History' && (
        <>
          {isEmpty(searches) && (
            <EmptyResults
              message="Nothing here yet"
              illustration={<Illustration.NotFound className="w-36 h-36 mb-6" />}
            />
          )}
          <SavedSearchesList
            data={searches}
            showDivider={false}
            featureContext={featureContext}
            searchQuery={searchQuery}
          />
        </>
      )}

      {currentTab === 'History' && (
        <>
          {isEmpty(recentSearchesData) && (
            <EmptyResults
              message="Nothing here yet"
              illustration={<Illustration.NotFound className="w-36 h-36 mb-6" />}
            />
          )}
          <RecentSearchesList
            data={recentSearchesData}
            showDivider={false}
            featureContext={featureContext}
            searchQuery={searchQuery}
          />
        </>
      )}

      {hasNextPage && (
        <div ref={ref} className="text-center p-4">
          <Button onClick={handleLoadMore} outline disabled={isFetchingNextPage}>
            Load more
          </Button>
        </div>
      )}
    </Drawer>
  );
};

export default SavedSearchesSidebar;
