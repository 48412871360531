import { forwardRef, useCallback } from 'react';
import { Select as BaseSelect, SelectProps } from '@demandscience/ui';

import find from 'lodash/find';

export interface Props extends Omit<SelectProps, 'value' | 'onChange'> {
  component?: React.ElementType;
  onChange: (value: any) => void;
  value: any;
}

const Select = forwardRef(
  ({ value, onChange, component: Component = BaseSelect, ...props }: Props, ref) => {
    const { options } = props;
    const selectedOption = value ? find(options, { value }) || { value, label: value } : {};

    const handleChange = useCallback(
      (option?: any) => {
        onChange(option?.value);
      },
      [onChange],
    );

    return <Component ref={ref} value={selectedOption} onChange={handleChange} {...props} />;
  },
);

Select.displayName = 'Select';

export default Select;
