import { createContext } from 'react';

export interface ConsentManagerContextInterface {
  advertising: boolean;
  functional: boolean;
  noDecision: boolean;
  required: boolean;
  unknown: boolean;
}

const ConsentManagerContext = createContext<ConsentManagerContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  ConsentManagerContext.displayName = 'ConsentManagerContext';
}

export default ConsentManagerContext;
