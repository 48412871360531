import { useQuery } from '@tanstack/react-query';
import { searchCreditsActivity } from 'api/organization';

import { SearchCreditsActivityParams } from 'types';

const useCreditsSearch = (params: SearchCreditsActivityParams, options?: Record<string, any>) => {
  const query = useQuery(['credits', 'search', params], () => searchCreditsActivity(params), {
    staleTime: 60 * 1000, // 1 minute
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export default useCreditsSearch;
