import { FC } from 'react';
import { BadgeSharedProps } from 'types';
import UsersIcon from '@demandscience/ui/icons/users';
import TextTooltip from 'components/Tooltip/TextTooltip';

const BadgeShared: FC<BadgeSharedProps> = ({ title }) => {
  return (
    <TextTooltip showArrow placement="bottom" title={title}>
      <div className="text-gray-600 bg-gray-100 px-2 py-[2px] rounded-[2.5px]">
        <UsersIcon size={14} />
      </div>
    </TextTooltip>
  );
};

export default BadgeShared;
