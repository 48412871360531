import { useQuery } from '@tanstack/react-query';

import { fetchModulesCustomization } from 'api/dataModules';

const useFetchModulesCustomization = (options?: Record<string, any>) => {
  const query = useQuery(['modulesCustomization'], fetchModulesCustomization, {
    keepPreviousData: true, // prevent data swap until new data loaded
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
    ...options,
  });

  return query;
};

export default useFetchModulesCustomization;
