import { useQuery } from '@tanstack/react-query';

import { fetchCurrentUser } from 'api/account';
import { useTracking } from 'react-tracking';
import { DataLayerData } from 'types/tracking';

const useAccount = (options?: Record<string, any>) => {
  const { trackEvent } = useTracking<DataLayerData>({ type: 'event', event: 'user_info' });

  const query = useQuery(['account'], fetchCurrentUser, {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const totalCreditsUsed = data.bulk_credits?.used;

      trackEvent({ totalCreditsUsed });
    },
    ...options,
  });

  return query;
};

export default useAccount;
