import { useQuery } from '@tanstack/react-query';

import { fetchMonthlyActivity } from 'api/organization';

const useActivity = (options?: Record<string, any>) => {
  const query = useQuery(['activity', 'monthly'], fetchMonthlyActivity, {
    refetchOnWindowFocus: true,
    staleTime: 60 * 1000,
    ...options,
  });

  return query;
};

export default useActivity;
