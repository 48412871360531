import { IconButton, Link } from '@demandscience/ui';
import MinusIcon from '@demandscience/ui/icons/minus';
import useFilters from 'components/Filters/useFilters';
import { hasAllAcquiredCompaniesFilter } from 'components/Filters/utils';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Company, ResultsView } from 'types';

const DataGridSubheader = () => {
  const [show, setShow] = useState<{
    allAcquiredCompanies: boolean;
    company: Company | null;
  } | null>(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { filters } = useFilters();

  const handleGoBack = useCallback(() => {
    const tab = ResultsView.Companies;
    const storedFilterString = sessionStorage.getItem('filter');
    let storedFilter;

    if (storedFilterString) {
      storedFilter = JSON.parse(storedFilterString);
    }
    navigate('/search', { state: { tab, filters: storedFilter } });
  }, [navigate]);

  const handleClose = useCallback(() => {
    setShow(null);
  }, []);

  useEffect(() => {
    if (isEqual(filters, state?.filters) && state?.contactsOf) {
      setShow({
        company: state?.contactsOf as Company,
        allAcquiredCompanies: false,
      });
    } else if (hasAllAcquiredCompaniesFilter(filters)) {
      setShow({
        company: null,
        allAcquiredCompanies: true,
      });
    } else {
      setShow(null);
    }
  }, [state, filters]);

  if (!show) {
    return null;
  }

  return (
    <div className="flex flex-row items-center gap-4 border border-gray-200 bg-gray-50 p-4">
      <div className="w-[30px] flex-shrink-0"></div>
      <div className="text-sm text-center grow">
        {show.company && (
          <>
            <span className="font-semibold">{show.company.company_name}</span> added to Company name
            filter
            <Link className="ml-2" as="button" onClick={handleGoBack}>
              Go back
            </Link>
          </>
        )}
        {show.allAcquiredCompanies && (
          <>
            Note: The list filter <span className="font-semibold">All acquired companies</span> is
            not applied to contacts search
          </>
        )}
      </div>
      <IconButton
        className="flex-shrink-0"
        Icon={MinusIcon}
        onClick={handleClose}
        theme="default"
        size="sm"
      />
    </div>
  );
};

export default DataGridSubheader;
