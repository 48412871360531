import { FC, useCallback } from 'react';
import { Slider } from '@demandscience/ui';
import MenuItem from './MenuItem';

interface RangeMenuItemProps {
  className?: string;
  max?: number;
  min?: number;
  onChange: (value: number[]) => void;
  value?: number[];
}

const RangeMenuItem: FC<RangeMenuItemProps> = ({
  className,
  min = 0,
  max = 100,
  value,
  onChange,
}) => {
  const handleChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, value: number[]) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <MenuItem
      className={className}
      title={
        <Slider
          min={min}
          max={max}
          value={value || [min, max]}
          // @ts-expect-error demandscience-ui issue with Slider props type
          onChange={handleChange}
          showLabels={false}
        />
      }
    />
  );
};

export default RangeMenuItem;
