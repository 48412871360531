import { useContext } from 'react';
import ChatSearchContext from '../ChatSearchContext';
import { filter, map } from 'lodash';
import ScrollToBottom from 'react-scroll-to-bottom';
import { GptMessageType } from 'types/aissistant';
import ChatInput from './ChatInput';
import ChatBubble from './ChatBubble';

const ChatHistory = () => {
  const { isLoading, messages } = useContext(ChatSearchContext) || {};

  const displayMessages = filter(
    messages,
    (message) => message.role !== GptMessageType.system && message.role !== GptMessageType.function,
  );

  return (
    <div className="overflow-y-hidden">
      <div className="h-full border my-4 mx-6 rounded-md">
        <ScrollToBottom className="flex flex-col h-[calc(100vh-9rem)] overflow-y-auto">
          <ChatBubble
            message={{
              role: GptMessageType.assistant,
              content: 'Describe your search',
            }}
          />
          {map(displayMessages, (message, index) => (
            <ChatBubble key={index} message={message} />
          ))}
          {isLoading && (
            <div className="p-2">
              <div className="flex gap-4 justify-start items-end">
                <div className="chat-bubble-ai">
                  <div className="typing">
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </ScrollToBottom>
      </div>
      <ChatInput />
    </div>
  );
};

export default ChatHistory;
