import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateViewCustomization } from 'api/dataModules';
import { UpdateDataProfileViews } from 'types';

const useMutateDataModules = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();

  const updateViews = useMutation(
    ({ id, payload }: UpdateDataProfileViews) => updateViewCustomization(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['views']);
      },
      ...options,
    },
  );

  return {
    updateViews,
  };
};

export default useMutateDataModules;
