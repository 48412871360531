import { Modal, ModalProps } from '@demandscience/ui';
import DeletePersonaForm from './DeletePersonaForm';
import { SavedSearch } from 'types';

interface DeletePersonaOverlayProps extends Omit<ModalProps, 'children'> {
  data?: SavedSearch;
}

const DeletePersonaOverlay = ({ data, ...props }: DeletePersonaOverlayProps) => {
  const { onClose } = props;
  const personaVisibility = data?.visibility;
  const personaId = data?.id;
  return (
    <Modal
      aria-labelledby="delete-persona-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="delete-persona-dialog-title" className="mb-4">
        Are you sure you want to delete this persona?
      </Modal.Title>
      <DeletePersonaForm
        id={personaId}
        visibility={personaVisibility}
        onSuccess={onClose}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default DeletePersonaOverlay;
