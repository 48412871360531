import { useListRecords } from 'components/Lists/useListRecords';
import { cloneElement, useMemo } from 'react';
import { CompanyFilterName, ContactFilterName, ListFilterName, ListRecord, ModelType } from 'types';
import SelectionProvider from './SelectionProvider';

interface ListRecordsSelectionProviderProps {
  children: React.ReactElement;
  list: ListRecord;
}

const ListRecordsSelectionProvider = ({ list, children }: ListRecordsSelectionProviderProps) => {
  const listRecords = useListRecords(list.id, list.records_type);
  const filters = useMemo(() => {
    const filterByNameKey =
      list.records_type === ModelType.Contact
        ? ContactFilterName.ContactName
        : CompanyFilterName.CompanyName;
    let filterByNameValue;

    if (listRecords.search) {
      filterByNameValue = [{ freetext: listRecords.search }];
    }

    return {
      [ListFilterName.InclusionList]: [{ list_id: list.id }],
      [filterByNameKey]: filterByNameValue,
    };
  }, [list.id, list.records_type, listRecords.search]);

  const recordsCount = listRecords.query.data?.pagination.count ?? list.number_of_records;

  return (
    <SelectionProvider filters={filters} recordsCount={recordsCount} kind={list.records_type}>
      {cloneElement(children, { listRecords })}
    </SelectionProvider>
  );
};

export default ListRecordsSelectionProvider;
