import { useLayoutEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import SplitPane from './SplitPane';
import useCustomization from 'components/SearchCustomization/useCustomization';
import CollapseProvider from './Filters/CollapseProvider';
import ExpandButton from './Filters/ExpandButton';

interface SearchProps {
  collapseFilters?: boolean;
}

const Search: React.FC<SearchProps> = ({ collapseFilters }) => {
  const { setFullWidth } = useOutletContext<{ setFullWidth: (v: boolean) => void }>();
  const {
    layout: { fullWidth },
  } = useCustomization();

  useLayoutEffect(() => {
    setFullWidth(fullWidth);

    return () => {
      // only this view has full width support, so it's required
      // to change it back as the layout is shared across the views
      setFullWidth(false);
    };
  }, [fullWidth, setFullWidth]);

  return (
    <CollapseProvider collapse={collapseFilters}>
      <div className="flex flex-row h-full">
        <ExpandButton className="flex-shrink-0" expand="right" />
        <SplitPane className="grow" orientation="vertical" />
        <ExpandButton className="flex-shrink-0" expand="left" />
      </div>
    </CollapseProvider>
  );
};

export default Search;
