import { Modal, Alert, Button } from '@demandscience/ui';
import { HTMLAttributes, useCallback, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AxiosError } from 'axios';
import useAuth from 'components/Auth/useAuth';
import useSnackbar from 'components/Snackbar/useSnackbar';
import useMutateAccount from './useMutateAccount';
import { UpdateEmailPayload } from 'types';

export interface EmailConfirmOverlayProps extends HTMLAttributes<HTMLDivElement> {
  isSubmitting?: boolean;
  newEmail?: string;
  onClose: () => void;
  open: boolean;
  setEdit: (state: boolean) => void;
}

const EmailConfirmOverlay = (props: EmailConfirmOverlayProps) => {
  const { onClose, newEmail, isSubmitting, setEdit, ...rest } = props;
  const [errorConflict, setErrorConflict] = useState(false);
  const { showMessage } = useSnackbar();
  const { signOut } = useAuth();
  const { updateEmail } = useMutateAccount();

  const { handleSubmit } = useForm<UpdateEmailPayload>({
    mode: 'onChange',
  });

  const handleSignout = useCallback(async () => {
    try {
      await signOut();
    } catch (e: any) {
      const { message } = e;
      showMessage(message, 'error');
    }
  }, [signOut, showMessage]);

  const onSubmit: SubmitHandler<UpdateEmailPayload> = async () => {
    const payload = {
      new: newEmail,
      confirm: newEmail,
    };
    try {
      await updateEmail.mutateAsync(payload);
      setEdit(false);
      handleSignout();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 409) {
          showMessage(
            'This email is being used by another account. Please type a different email address.',
            'error',
          );
          setErrorConflict((state) => !state);
        } else {
          showMessage(e.response?.data?.error || 'Unable to update the email', 'error');
        }
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  };

  const handleClose = () => {
    onClose();
    setErrorConflict((state) => !state);
  };

  return (
    <Modal
      aria-labelledby="create-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      onClose={onClose}
      {...rest}
    >
      <Modal.Title id="create-list-dialog-title" className="flex mb-4">
        Confirm the email update
      </Modal.Title>
      <div className="flex gap-4 flex-col">
        <p className="text-sm text-gray-700">
          You are about to set <strong>{newEmail}</strong> as your new email.
        </p>

        <Alert severity="info">
          <>
            <Alert.Header>
              <Alert.Title>Next steps</Alert.Title>
            </Alert.Header>
            <div className="text-sm mt-1 text-gray-700 ml-9">
              A <strong>verification code</strong> will be sent to your new email address. Sign in
              to Klarity with the new email address and your current password. To finish confirming
              your update, enter the verification code you copied from the email.
            </div>
          </>
        </Alert>
        <div className="flex items-center justify-end mt-2 gap-2">
          <Button theme="primary" type="button" outline onClick={handleClose}>
            Cancel
          </Button>

          <Button onClick={handleSubmit(onSubmit)} disabled={isSubmitting || errorConflict}>
            Confirm and logout
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EmailConfirmOverlay;
