import { Button } from '@demandscience/ui';

import CheckCircleIcon from '@demandscience/ui/icons/check-circle';
import AlertTriangleIcon from '@demandscience/ui/icons/alert-triangle';
import XOctagonIcon from '@demandscience/ui/icons/x-octagon';

import map from 'lodash/map';

import { InviteResult } from 'types';
import ListItemText from 'components/Layout/ListItemText';

const icons = {
  success: CheckCircleIcon,
  invalid: XOctagonIcon,
  already_a_member: AlertTriangleIcon,
  taken: AlertTriangleIcon,
  technical_error: XOctagonIcon,
};

const messageAccountTaken = `
    User is already part of another team.
    They need to delete their current account before
    you can send a new invite.
`;

const labels = {
  success: 'Invitation sent successfully',
  invalid: 'This email is invalid',
  already_a_member: 'User is already part of the team',
  taken: messageAccountTaken,
  technical_error: 'There has been an error sending the invitation',
};

const iconColor = {
  success: 'text-success-500',
  invalid: 'text-error-500',
  already_a_member: 'text-amber-500',
  taken: 'text-amber-500',
  technical_error: 'text-error-500',
};

interface InviteResultsProps {
  data: InviteResult[];
  onClear: () => void;
  onClose: () => void;
}

const InviteResults: React.FC<InviteResultsProps> = ({ data, onClear, onClose }) => (
  <div className="mt-9">
    <p className="text-sm text-gray-700 font-medium">Sending status</p>
    {map(data, ({ email, status }) => {
      const StatusIcon = icons[status];
      const statusLabel = labels[status];

      return (
        <ListItemText
          key={email}
          leadingContent={<StatusIcon className={`w-5 h-5 ${iconColor[status]}`} />}
          title={email}
          subtitle={statusLabel}
          disableGutters
        />
      );
    })}
    <div className="flex justify-end space-x-2 mt-6">
      <Button borderless onClick={onClear}>
        Send more invitations
      </Button>
      <Button theme="primary" onClick={onClose}>
        Close
      </Button>
    </div>
  </div>
);

export default InviteResults;
