import { isEmpty } from 'lodash';
import { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { PaginationResponse, SearchParams } from 'types';
import { Direction } from '@demandscience/ui/dist/types';

const useDataGridSearch = <T, R extends PaginationResponse>(
  useSearch: (
    params: SearchParams<T>,
    options?: { refetchInterval: number | false },
  ) => UseQueryResult<R>,
  defaultParams: SearchParams<T>,
  options?: { refetchInterval: number | false },
) => {
  const [params, setParams] = useState<SearchParams<T>>(defaultParams);

  const query = useSearch(params, options);

  const handleFilterChange = useCallback((filter: T) => {
    if (isEmpty(filter)) {
      setParams(({ filter, ...state }) => ({ ...state, index: 0 }));
    } else {
      setParams((state) => ({ ...state, index: 0, filter }));
    }
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setParams((state) => ({
      ...state,
      index: page - 1,
    }));
  }, []);

  const handleSortChange = useCallback(
    (field: string | null, order: Direction) => {
      if (field) {
        setParams((state) => ({
          ...state,
          sort: {
            by: field,
            descending: order === 'desc',
          },
        }));
      } else {
        setParams((state) => ({
          ...state,
          sort: defaultParams.sort,
        }));
      }
    },
    [defaultParams.sort],
  );

  useEffect(() => {
    if (
      query.isSuccess &&
      !query.isPreviousData &&
      query.data?.returned === 0 &&
      params.index > 0
    ) {
      handlePageChange(params.index);
    }
  }, [handlePageChange, query.isSuccess, query.isPreviousData, query.data?.returned, params.index]);

  return {
    params,
    handleFilterChange,
    handlePageChange,
    handleSortChange,
    query,
  };
};

export default useDataGridSearch;
