import { Badge } from '@demandscience/ui';
import { capitalize } from 'lodash';
import { TableColumn, Invoice } from 'types';
import InvoiceDownload from './InvoiceDownload';
import { formatPrice } from 'utils/format';

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const columns: TableColumn<Invoice>[] = [
  {
    field: 'created_at',
    headerName: 'Date',
    valueGetter: ({ created_at }) =>
      new Date(created_at).toLocaleDateString(undefined, dateOptions),
  },
  {
    field: 'invoice_id',
    headerName: 'Invoice ID',
  },
  {
    field: 'credits',
    headerName: 'Credits',
    valueGetter: ({ credits }) => credits.toLocaleString(),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    valueGetter: ({ amount, currency }) => formatPrice(amount, currency),
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: ({ status }) => {
      if (status === 'paid') {
        return <Badge label="Paid" color="emerald" />;
      }
      return <Badge label={capitalize(status)} color="rose" />;
    },
  },
  {
    field: 'action',
    headerName: 'Actions',
    headerClassName: 'justify-end',
    valueGetter: ({ invoice_id: id }) => (
      <div className="text-right">
        <InvoiceDownload id={id} />
      </div>
    ),
  },
];
