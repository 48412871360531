import CompassIcon from '@demandscience/ui/icons/compass';
import SearchIcon from '@demandscience/ui/icons/search';
import UsersIcon from '@demandscience/ui/icons/users';
import BuildingIcon from '@demandscience/ui/icons/building';
import DollarSignIcon from '@demandscience/ui/icons/dollar-sign';
import MapPinIcon from '@demandscience/ui/icons/map-pin';
import GridIcon from '@demandscience/ui/icons/grid';
import LayersIcon from '@demandscience/ui/icons/layers';
import UserIcon from '@demandscience/ui/icons/user';
import PhoneIcon from '@demandscience/ui/icons/phone';
import BriefcaseIcon from '@demandscience/ui/icons/briefcase';
import TargetIcon from '@demandscience/ui/icons/target';
import MinusCircleIcon from '@demandscience/ui/icons/minus-circle';
import PlusCircleIcon from '@demandscience/ui/icons/plus-circle';
import ZapIcon from '@demandscience/ui/icons/zap';
import CheckVerifiedIcon from '@demandscience/ui/icons/check-verified-03';
import Building02Icon from '@demandscience/ui/icons/building-02';
import StarIcon from '@demandscience/ui/icons/star';
import ProfileIcon from '@demandscience/ui/icons/profile';

import { IconProps } from '@demandscience/ui/dist/types';

import {
  CompanyFilterName,
  ContactFilterName,
  DataFilterName,
  FilterName,
  ListFilterName,
  SalesSignalsFilterName,
} from 'types/filters';

const icons = {
  [DataFilterName.ConfirmedConnect]: CheckVerifiedIcon,
  [DataFilterName.ContactDetails]: PhoneIcon,
  [DataFilterName.DataAccuracy]: TargetIcon,
  [CompanyFilterName.CompanyEmployees]: UsersIcon,
  [CompanyFilterName.CompanyIndustry]: Building02Icon,
  [CompanyFilterName.CompanyLocation]: MapPinIcon,
  [CompanyFilterName.CompanyName]: BuildingIcon,
  [CompanyFilterName.CompanyRevenue]: DollarSignIcon,
  [CompanyFilterName.CompanySpeciality]: StarIcon,
  [ContactFilterName.ContactLocation]: MapPinIcon,
  [ContactFilterName.ContactName]: UserIcon,
  [ContactFilterName.JobFunction]: GridIcon,
  [ContactFilterName.JobLevel]: LayersIcon,
  [ContactFilterName.JobTitle]: BriefcaseIcon,
  [SalesSignalsFilterName.InstallTopic]: CompassIcon,
  [SalesSignalsFilterName.IntentTopic]: ZapIcon,
  [ListFilterName.ExclusionList]: MinusCircleIcon,
  [ListFilterName.InclusionList]: PlusCircleIcon,
  Personas: ProfileIcon,
};

const DefaultIcon = SearchIcon;

interface FilterIconProps extends IconProps {
  name: FilterName | 'Personas';
}

const FilterIcon = ({ name, ...props }: FilterIconProps) => {
  if (name === ContactFilterName.ContactId) {
    return null;
  }

  const { [name]: Icon = DefaultIcon } = icons;

  return <Icon {...props} />;
};

export default FilterIcon;
