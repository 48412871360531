import { useCallback, useEffect, useMemo, useState } from 'react';
import ExportContext, { ExportContextInterface } from './ExportContext';
import {
  AdvancedSelection,
  Constraints,
  DataTypeOptions,
  ExportData,
  ExportType,
  Filters,
  IntegrationOptions,
  KlarityFeature,
  ModelType,
  SortBy,
} from 'types';
import LinkedInExtensionInstallOverlay from '../LinkedInExtension/InstallOverlay';
import ExportToListOverlay from 'components/Lists/ExportToListOverlay';
import RevealMultipleContactsOverlay from 'components/RevealContacts/RevealMultipleContactsOverlay';
import CreditsProvider from 'components/Credits/CreditsProvider';
import ExportCsvOverlay from './ExportCsvOverlay';
import useIntegration from 'components/Organization/useIntegrations';
import { get } from 'lodash';
import ExportSalesforceOverlay from './ExportSalesforceOverlay';
import ExportMarketoOverlay from './ExportMarketoOverlay';
import ExportHubspotOverlay from './ExportHubspotOverlay';
import useLinkedInExtension from 'components/LinkedInExtension/useLinkedInExtension';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { getExportFilters } from './utils';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

interface ExportProviderProps {
  advancedSelection?: AdvancedSelection;
  children: React.ReactNode;
  constraints?: Constraints;
  modelType: ModelType;
  selectionCount: number;
  sort?: SortBy;
}

const ExportProvider = ({
  modelType,
  sort,
  selectionCount,
  advancedSelection,
  constraints,
  children,
}: ExportProviderProps) => {
  const [exportType, setExportType] = useState<ExportType | null>(null);
  const [exportFilters, setExportFilters] = useState<Filters | undefined>(undefined);
  const { showMessage } = useSnackbar();
  const { installed, exportRecords } = useLinkedInExtension();
  const { data: integration } = useIntegration();
  const { featureStatus, requestFeatures } = useFeatureFlag(KlarityFeature.Contacts);

  const handleExportToLinkedInExtension = useCallback(
    async (filters: Filters) => {
      try {
        await exportRecords(
          modelType,
          filters,
          sort,
          advancedSelection,
          constraints,
          selectionCount,
        );
      } catch (e: any) {
        showMessage(e.message, 'error');
      }
    },
    [exportRecords, modelType, sort, advancedSelection, constraints, selectionCount, showMessage],
  );

  const handleExportOpen = useCallback(
    (exportType: ExportType, exportData: ExportData) => {
      if (modelType === ModelType.Contact && featureStatus) {
        requestFeatures();
        return;
      }

      if (exportType === 'linkedin') {
        if (installed) {
          const filters = getExportFilters(modelType, exportData);

          handleExportToLinkedInExtension(filters);
        } else {
          setExportType(exportType);
        }
      } else {
        setExportFilters(getExportFilters(modelType, exportData));
        setExportType(exportType);
      }
    },
    [modelType, featureStatus, requestFeatures, installed, handleExportToLinkedInExtension],
  );

  const handleExportClose = useCallback(() => {
    setExportType(null);
  }, []);

  const handleClearData = useCallback(() => {
    setExportFilters(undefined);
  }, []);

  const hasIntegrationType = useCallback(
    (dataType: DataTypeOptions) => {
      return get(integration, `${dataType}.enabled`, false);
    },
    [integration],
  );

  const hasIntegration = useCallback(
    (provider: IntegrationOptions) => {
      if (provider === 'salesforce') {
        if (modelType === ModelType.Contact) {
          return (
            hasIntegrationType('salesforce-contacts-to-contacts') ||
            hasIntegrationType('salesforce-contacts-to-leads')
          );
        }

        return hasIntegrationType('salesforce-companies-to-accounts');
      } else if (provider === 'marketo') {
        return hasIntegrationType('marketo-contacts-to-leads');
      } else if (provider === 'hubspot') {
        if (modelType === ModelType.Contact) {
          return hasIntegrationType('hubspot-contacts-to-contacts');
        }

        return hasIntegrationType('hubspot-companies-to-companies');
      } else if (provider === 'linkedin') {
        return installed;
      }

      return false;
    },
    [installed, modelType, hasIntegrationType],
  );

  useEffect(() => {
    // close linkedin install overlay if install state changes
    if (installed && exportType === 'linkedin') {
      handleExportClose();
    }
  }, [installed, exportType, handleExportClose]);

  const value: ExportContextInterface = useMemo(
    () => ({
      exportTo: handleExportOpen,
      modelType,
      hasIntegration,
      hasIntegrationType,
    }),
    [handleExportOpen, hasIntegration, hasIntegrationType, modelType],
  );

  return (
    <ExportContext.Provider value={value}>
      {children}
      <CreditsProvider>
        <LinkedInExtensionInstallOverlay
          open={exportType === 'linkedin'}
          onClose={handleExportClose}
          afterLeave={handleClearData}
        />
        <ExportSalesforceOverlay
          open={exportType === 'salesforce'}
          onClose={handleExportClose}
          afterLeave={handleClearData}
          type={modelType}
          filters={exportFilters}
          count={selectionCount}
          advancedSelection={advancedSelection}
          constraints={constraints}
          sort={sort}
        />
        <ExportMarketoOverlay
          open={exportType === 'marketo'}
          onClose={handleExportClose}
          afterLeave={handleClearData}
          type={modelType}
          filters={exportFilters}
          count={selectionCount}
          advancedSelection={advancedSelection}
          constraints={constraints}
          sort={sort}
        />
        <ExportHubspotOverlay
          open={exportType === 'hubspot'}
          onClose={handleExportClose}
          afterLeave={handleClearData}
          type={modelType}
          filters={exportFilters}
          count={selectionCount}
          advancedSelection={advancedSelection}
          constraints={constraints}
          sort={sort}
        />
        <ExportCsvOverlay
          open={exportType === 'csv'}
          onClose={handleExportClose}
          afterLeave={handleClearData}
          type={modelType}
          sort={sort}
          filters={exportFilters}
          count={selectionCount}
          advancedSelection={advancedSelection}
          constraints={constraints}
        />
        <ExportToListOverlay
          open={exportType === 'toList'}
          onClose={handleExportClose}
          afterLeave={handleClearData}
          type={modelType}
          sort={sort}
          filters={exportFilters}
          count={selectionCount}
          advanced_selection={advancedSelection}
          constraints={constraints}
        />
        <RevealMultipleContactsOverlay
          open={exportType === 'reveal'}
          onClose={handleExportClose}
          afterLeave={handleClearData}
          sort={sort}
          filters={exportFilters}
          count={selectionCount}
          advancedSelection={advancedSelection}
          constraints={constraints}
        />
      </CreditsProvider>
    </ExportContext.Provider>
  );
};

export default ExportProvider;
