import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAuth from './useAuth';

interface RequireNoAuthProps {
  children: React.ReactElement;
}

const RequireNoAuth: React.FC<RequireNoAuthProps> = ({ children }) => {
  const { user, signOut } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // run navigate on mount only (that's why dependencies array is empty)
  useEffect(() => {
    if (user) {
      // if path is to impersonate user, sign out from the current session
      // if path is to do new authentication, sign out from the current session
      if (
        location.pathname.startsWith('/auth/impersonate') ||
        location.pathname.startsWith('/auth/new')
      ) {
        signOut();
      } else {
        navigate('/', { replace: true });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // handle initial rendered before above effect kicks in
  if (user) {
    return null;
  }

  return children;
};

export default RequireNoAuth;
