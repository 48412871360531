import { Link as RouterLink } from 'react-router-dom';
import Header from 'layouts/Header';
import { Link, Button } from '@demandscience/ui';

import useAuth from 'components/Auth/useAuth';
import useSnackbar from 'components/Snackbar/useSnackbar';
import Signout from 'components/Auth/Signout';
import { useCallback } from 'react';

const SimpleHeader = () => {
  const { showMessage } = useSnackbar();
  const { user } = useAuth();

  const handleSignoutError = useCallback(
    (message: string) => {
      showMessage(message, 'error');
    },
    [showMessage],
  );

  return (
    <Header className="fixed top-4 right-4">
      {user && (
        <>
          <Link as={RouterLink} className="mr-2" to="/">
            Dashboard
          </Link>
          {/* @ts-expect-error demandscience-ui issue */}
          <Button as={Signout} onError={handleSignoutError}>
            Signout
          </Button>
        </>
      )}
      {!user && (
        <Button as={RouterLink} to="/auth/signin">
          Sign in
        </Button>
      )}
    </Header>
  );
};

export default SimpleHeader;
