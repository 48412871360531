import { useCallback, useMemo, useState } from 'react';
import CopyOrMoveToListOverlay from 'components/ListRecords/CopyOrMoveActionOverlay';
import ActionsContext, { ActionsContextInterface } from './ActionsContext';
import {
  Account,
  AdvancedSelection,
  ExportData,
  Filters,
  ListOrigin,
  ListRecordsAction,
  ModelType,
  SortBy,
  Visibility,
} from 'types';
import { getExportFilters } from 'components/Export/utils';
import DeleteActionOverlay from './DeleteActionOverlay';
import useSelection from 'components/DataGrid/Selection/useSelection';
import useAuth from 'components/Auth/useAuth';

interface ActionsProviderProps {
  advancedSelection?: AdvancedSelection;
  children: React.ReactNode;
  listId: string;
  listOrigin: ListOrigin;
  modelType: ModelType;
  owner?: Account;
  sort?: SortBy;
  visibility: Visibility;
}

const ActionsProvider = ({
  listId,
  listOrigin,
  modelType,
  sort,
  advancedSelection,
  children,
  owner,
  visibility,
}: ActionsProviderProps) => {
  const [action, setAction] = useState<ListRecordsAction | null>(null);
  const [exportFilters, setExportFilters] = useState<Filters | undefined>(undefined);
  const selection = useSelection();
  const { user, isManager } = useAuth();
  const editable =
    owner?.username === user?.username || (isManager && visibility === Visibility.Public);

  const handleAction = useCallback(
    (newAction: ListRecordsAction, exportData: ExportData) => {
      const filters = getExportFilters(modelType, exportData);

      setExportFilters(filters);
      setAction(newAction);
    },
    [modelType],
  );

  const handleClose = useCallback(() => {
    setAction(null);
  }, []);

  const handleSuccess = useCallback(() => {
    handleClose();

    // remove selected records from selection state
    if (action === ListRecordsAction.Move || action === ListRecordsAction.Remove) {
      if (exportFilters?.inclusion_list) {
        selection?.clearAll();
      } else if (exportFilters?.contact_dsid) {
        selection?.remove(exportFilters?.contact_dsid);
      }
    }
  }, [selection, exportFilters, action, handleClose]);

  const handleClearData = useCallback(() => {
    setExportFilters(undefined);
  }, []);

  const value: ActionsContextInterface = useMemo(
    () => ({
      action: handleAction,
      listId,
      listOrigin,
      editable,
    }),
    [editable, handleAction, listId, listOrigin],
  );

  return (
    <ActionsContext.Provider value={value}>
      {children}
      <DeleteActionOverlay
        open={action === ListRecordsAction.Remove}
        onSuccess={handleSuccess}
        onClose={handleClose}
        afterLeave={handleClearData}
        listId={listId}
        filters={exportFilters}
        sort={sort}
        advanced_selection={advancedSelection}
      />
      <CopyOrMoveToListOverlay
        open={action === ListRecordsAction.Copy}
        method={ListRecordsAction.Copy}
        onSuccess={handleSuccess}
        onClose={handleClose}
        afterLeave={handleClearData}
        type={modelType}
        listId={listId}
        filters={exportFilters}
        sort={sort}
        advanced_selection={advancedSelection}
        count={selection.selectionCount}
      />
      <CopyOrMoveToListOverlay
        open={action === ListRecordsAction.Move}
        method={ListRecordsAction.Move}
        onSuccess={handleSuccess}
        onClose={handleClose}
        afterLeave={handleClearData}
        type={modelType}
        listId={listId}
        filters={exportFilters}
        sort={sort}
        advanced_selection={advancedSelection}
        count={selection.selectionCount}
      />
    </ActionsContext.Provider>
  );
};

export default ActionsProvider;
