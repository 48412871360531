import { Link as RouterLink, useLocation, Routes, Route } from 'react-router-dom';
import { Button } from '@demandscience/ui';

import Logo from './Logo';

// Uncomment after the launch of self-service
// import { showSignUp } from 'utils/auth';
// const GetStarted = () => {
//   const location = useLocation();
//   return (
//     <div className="flex items-center gap-2">
//       <p className="text-sm text-gray-700">Don&apos;t have an account?</p>
//       <Button
//         id="link_signup"
//         size="md"
//         outline
//         as={RouterLink}
//         to="../signup"
//         state={location.state}
//       >
//         Get started
//       </Button>
//     </div>
//   );
// };

const Signin = () => {
  const location = useLocation();

  return (
    <div className="flex items-center gap-2">
      <p className="text-sm text-gray-700">Already have an account?</p>
      <Button
        id="link_signin"
        size="md"
        outline
        as={RouterLink}
        to="../signin"
        state={location.state}
      >
        Sign in
      </Button>
    </div>
  );
};

interface AuthHeaderProps {
  hideNavLinks?: boolean;
}

const AuthHeader = ({ hideNavLinks = false }: AuthHeaderProps) => (
  <div className="flex flex-row items-center h-16 justify-between">
    <Logo />
    {!hideNavLinks && (
      <div className="xl:absolute xl:right-14">
        <div className="flex items-center gap-4">
          <Routes>
            {/* Revert to element={showSignUp ? <GetStarted /> : null} after self-service launch */}
            <Route path="/signin" element={null} />
            {/* Revert to element={showSignUp ? <GetStarted /> : null} after self-service launch */}
            <Route path="/forgotPassword" element={null} />
            <Route path="/new" element={null} />
            <Route path="*" element={<Signin />} />
          </Routes>
        </div>
      </div>
    )}
  </div>
);

export default AuthHeader;
