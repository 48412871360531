import { useEffect, useState } from 'react';
import { getLabels } from './utils';
import { flatten, join, map } from 'lodash';
import CompareContext from './CompareContext';
import { CompareSearch } from 'types/compare';
import { FilterName, Filters, RecentSearch, SavedSearch, isSavedSearch } from 'types';

interface CompareProviderProps {
  children: React.ReactNode;
}

export const COMPARE_SEARCH_LIST_INITIAL_STATE: CompareSearch[] = [
  { filters: {}, isActive: true, label: 'Search 1', value: '1' },
];

export const COMPARE_LIST_SIZE_LIMIT = 4;

/**
 * Context provider for Compare logic.
 */
const CompareProvider = ({ children }: CompareProviderProps) => {
  /** Controls the the saved search & history drawer */
  const [compareDrawerOpen, setCompareDrawerOpen] = useState(false);

  /** Controls the the Radiolist rendering and option state */
  const [compare, setCompare] = useState(false);
  const [searchNumber, setSearchNumber] = useState(2);

  const [compareSearchList, setCompareSearchList] = useState<CompareSearch[]>(
    COMPARE_SEARCH_LIST_INITIAL_STATE,
  );

  useEffect(() => {
    if (compareSearchList.length >= COMPARE_LIST_SIZE_LIMIT) {
      setCompareDrawerOpen(false);
    }
  }, [compareSearchList]);

  /** Check whether a search contains filters or not */
  const searchHasFilters = ({ filters }: CompareSearch) => {
    return !!Object.keys(filters).length;
  };

  /** Initialize a Compare list */
  const handleBlankSearch = (filters: Filters) => {
    setCompare(true);
    setSearchNumber(searchNumber + 1);

    const newEmptySearch: CompareSearch = {
      filters: {},
      isActive: false,
      label: `Search ${searchNumber}`,
      value: `${searchNumber}`,
    };

    if (!searchHasFilters(compareSearchList[0])) {
      setCompareSearchList([
        { filters: { ...filters }, isActive: true, label: 'Search 1', value: '1' },
        newEmptySearch,
      ]);
    } else {
      setCompareSearchList((prevSearches) => {
        return [...prevSearches, newEmptySearch];
      });
    }
  };

  /** Duplicate current search */
  const handleDuplicateSearch = (filters: Filters) => {
    setCompare(true);
    setSearchNumber(searchNumber + 1);

    const newSearch: CompareSearch = {
      filters,
      isActive: false,
      label: `Search ${searchNumber} (Duplicate)`,
      value: `${compareSearchList.length + 1}`,
    };

    if (!searchHasFilters(compareSearchList[0])) {
      setCompareSearchList([
        { filters: { ...filters }, isActive: true, label: `Search ${searchNumber}`, value: '1' },
        newSearch,
      ]);
    } else {
      setCompareSearchList((prevSearches) => {
        return [...prevSearches, newSearch];
      });
    }
  };

  /** Add a search to the Compare list */
  const addSearchToCompareList = (search: SavedSearch | RecentSearch, filters: Filters) => {
    const getName = (filters: Filters) => {
      const labels = map(filters, (filter, filterName: FilterName) =>
        getLabels(filterName, filter),
      );

      let name = join(flatten(labels), ', ');

      if (name?.length > 40) {
        name = name.substring(0, 40) + '...';
      }

      return name;
    };

    if (!compare) {
      setCompare(true);
    }

    const newSearch: CompareSearch = {
      filters,
      isActive: false,
      label: isSavedSearch(search) ? search.name : getName(filters),
      value: `${compareSearchList.length + 1}`,
    };

    setCompareSearchList((prevSearches) => {
      return [...prevSearches, newSearch];
    });
  };

  /**
   * Clear filters & set the radiolist to its initial state.
   */
  const clearCompareList = () => {
    setCompare(false);
    setCompareSearchList(COMPARE_SEARCH_LIST_INITIAL_STATE);
  };

  return (
    <CompareContext.Provider
      value={{
        compareDrawerOpen,
        setCompareDrawerOpen,
        compare,
        setCompare,
        addSearchToCompareList,
        handleBlankSearch,
        handleDuplicateSearch,
        setSearchNumber,
        clearCompareList,
        compareSearchList,
        setCompareSearchList,
        searchHasFilters,
      }}
    >
      {children}
    </CompareContext.Provider>
  );
};

export default CompareProvider;
