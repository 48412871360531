import { TablCellProps, TableHeaderCell } from 'components/Layout/Table';
import useCustomization from 'components/SearchCustomization/useCustomization';
import TextTooltip from 'components/Tooltip/TextTooltip';
import React, { useCallback } from 'react';
import { CompanyTableColumn, ContactTableColumn, ModelType, tableColumns } from 'types';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';

interface DataGridTableHeaderCellProps extends TablCellProps {
  column: CompanyTableColumn | ContactTableColumn;
  initialWidth: number;
  isList?: boolean;
  onResize: React.MouseEventHandler<HTMLDivElement>;
  type: ModelType;
}

const DataGridTableHeaderCell = ({
  column,
  type,
  onResize,
  initialWidth,
  isList,
  ...props
}: DataGridTableHeaderCellProps) => {
  const { setCustomizeColumnsOpen } = useCustomization();

  const handleClick = useCallback(() => {
    setCustomizeColumnsOpen(type);
  }, [setCustomizeColumnsOpen, type]);

  const header = (
    <TextTooltip title="Click to edit columns" placement="top" showArrow>
      <button onClick={handleClick}>{tableColumns[column]}</button>
    </TextTooltip>
  );
  const FirstColumnStyle =
    column === 'contact_name' || column === 'company_name' ? 'sticky left-[52px] z-[10]' : 'z-[2]';

  return (
    <TableHeaderCell
      {...props}
      onMouseDown={!isList ? onResize : undefined}
      className={overrideTailwindClasses(
        classNames(
          `relative px-4 border-r border-transparent hover:border-gray-300 ${FirstColumnStyle} ${
            initialWidth ? `w-[${initialWidth}px]` : ''
          }`,
          { [`sticky bg-inherit transition-shadow`]: true },
        ),
      )}
    >
      {header}
      {!isList && (
        <div
          className="absolute right-0 top-0 h-full w-2 cursor-col-resize opacity-0 hover:opacity-100 transition-opacity"
          onMouseDown={onResize}
          style={{ transform: 'translateX(50%)' }}
        />
      )}
    </TableHeaderCell>
  );
};

export default DataGridTableHeaderCell;
