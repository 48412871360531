import Email from './Email';
import Phone from './Phone';
import { Contact } from 'types';
import JobTitle from './JobTitle';
import classNames from 'classnames';
import ContactName from './ContactName';
import { Button } from '@demandscience/ui';
import ContactAvatar from './ContactAvatar';
import ListItemText from 'components/Layout/ListItemText';
import ExportControl from 'components/Export/ExportControl';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';
import RevealSingleContactControl from 'components/RevealContacts/RevealSingleContactControl';

interface BasicInfoProps {
  row: Contact;
  showAvatar?: boolean;
}

const BasicInfo = ({ row, showAvatar }: BasicInfoProps) => (
  <>
    <ListItemText
      leadingContent={showAvatar && <ContactAvatar className="w-8 h-8" row={row} colored />}
      title={
        <ContactName
          className={classNames({ 'font-normal': !showAvatar, 'font-semibold': showAvatar })}
          row={row}
        />
      }
      subtitle={<JobTitle className="text-xs text-inherit" row={row} />}
    />
    <Email row={row} />
    <Phone row={row} />
    <Phone mobile row={row} />
    <ListItemText
      title={
        <div className="flex flex-row gap-4">
          {!row.revealed && (
            <RevealSingleContactControl
              id={row.dsid}
              className="w-full whitespace-nowrap"
              size="sm"
            >
              Show info
            </RevealSingleContactControl>
          )}
          <ExportControl
            record={row}
            as={Button}
            className="w-full whitespace-nowrap"
            outline
            // @ts-expect-error demandscience-ui issue
            size="sm"
            rightIcon={<ChevronDownIcon size={18} />}
          >
            Add contact to
          </ExportControl>
        </div>
      }
    />
  </>
);

export default BasicInfo;
