import { FC } from 'react';
import { Contact } from 'types';
import ListItemText from 'components/Layout/ListItemText';
import BriefcaseIcon from '@demandscience/ui/icons/briefcase';
import { isEmpty } from 'lodash';
import { overrideTailwindClasses } from 'tailwind-override';
import classNames from 'classnames';

interface JobFunctionProps {
  className?: string;
  inline?: boolean;
  row: Contact;
}

const JobFunction: FC<JobFunctionProps> = ({ className, inline, row }) => {
  const { jobs } = row;

  if (isEmpty(jobs)) {
    return null;
  }

  // showing only the first job
  const [
    {
      details: [{ function: jobFunction }],
    },
  ] = jobs;

  if (!jobFunction) {
    return null;
  }

  if (inline) {
    return (
      <p className={overrideTailwindClasses(classNames('text-sm text-gray-700', className))}>
        {jobFunction}
      </p>
    );
  }

  return (
    <ListItemText
      leadingContent={<BriefcaseIcon className="w-5 h-5" />}
      overline="Function &amp; Department"
      title={jobFunction}
    />
  );
};

export default JobFunction;
