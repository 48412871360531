import { noop } from 'lodash';
import { DataModule, moduleColumns } from 'types';
import ColumnBox from './ColumnBox';
import DragHandle from '../SortableList/DragHandle';

interface DragPreviewProps {
  module: DataModule;
}

const DragPreview = ({ module }: DragPreviewProps) => (
  <div className="cursor-grabbing">
    <ColumnBox label={moduleColumns[module]} checked handle={<DragHandle />} onChange={noop} />
  </div>
);

export default DragPreview;
