import { useState, useEffect, useRef } from 'react';
import { Badge, Button, DropdownMenu, IconButton } from '@demandscience/ui';
import TextTooltip from 'components/Tooltip/TextTooltip';

import CornerUpLeftIcon from '@demandscience/ui/icons/corner-up-left';
import CornerUpRightIcon from '@demandscience/ui/icons/corner-up-right';

import useFilters from 'components/Filters/useFilters';

import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';

import useMutateRecentSearch from 'components/RecentSearches/useMutateRecentSearch';
import useTabs from '../Tabs/useTabs';
import useFiltersCollapse from './useFiltersCollapse';
import { KlarityFeature, ResultsView } from 'types';
import { useLocation, useNavigate } from 'react-router-dom';
import { omit } from 'lodash';

import PlusIcon from '@demandscience/ui/icons/plus';
import CompareMenuItems from './CompareMenuItems';
import useCompare from 'components/Filters/useCompare';
import SavedSearchesSidebar from 'components/SavedSearches/SavedSearchesSidebar';
import { COMPARE_LIST_SIZE_LIMIT } from 'components/Filters/CompareProvider';

const Header = () => {
  const [recentSearchID, setRecentSearchID] = useState('');
  const {
    filters,
    clearAll,
    undoFilter,
    redoFilter,
    hasUndo,
    hasRedo,
    filterCleared,
    setFilterCleared,
  } = useFilters();
  const { compareDrawerOpen, setCompareDrawerOpen, compareSearchList } = useCompare();
  const navigate = useNavigate();
  const location = useLocation();
  const filtersCount = size(filters);
  const { tab } = useTabs();
  const { hideEmpty, toggleHideEmpty } = useFiltersCollapse();

  const { update, create } = useMutateRecentSearch();
  const { compare, clearCompareList } = useCompare();

  const handleClearAll = () => {
    setRecentSearchID('');

    if (compare) {
      clearCompareList();
    }

    if (location.state?.filters) {
      // clear the location state, that will in turn clear the current filters
      navigate(omit(location, 'state'));
    } else {
      clearAll();
    }
  };

  const usePrevious = (value: ResultsView) => {
    const ref = useRef<ResultsView>();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const prevTab = usePrevious(tab);

  useEffect(() => {
    const payload = {
      filters,
      current_view: tab,
    };

    async function handleRecentSearch() {
      if (filterCleared || prevTab !== tab) {
        setRecentSearchID('');
      }
      if (!recentSearchID) {
        if (!isEmpty(filters)) {
          const res = await create.mutateAsync({ created_at: new Date().toJSON(), ...payload });

          setRecentSearchID(res.id);
          setFilterCleared(false);
        }
      } else if (!isEmpty(filters) && recentSearchID) {
        await update.mutateAsync({ id: recentSearchID, ...payload });
      }
    }
    handleRecentSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, tab, filterCleared]);

  const compareButtonDisabled = compareSearchList.length >= COMPARE_LIST_SIZE_LIMIT;

  return (
    <>
      <div className="h-[70px] border-b flex-shrink-0 flex flex-row items-center gap-2 overflow-y-visible">
        <TextTooltip
          title={hideEmpty ? 'Click to view all' : 'Click to view only selected'}
          placement="right"
          showArrow
        >
          <button
            className="flex flex-row items-center gap-1 flex-nowrap"
            onClick={toggleHideEmpty}
          >
            <span className="text-sm font-medium text-gray-600">Filters</span>
            <Badge label={`${filtersCount}`} size="sm" color="blue" />
          </button>
        </TextTooltip>
        <div className="flex flex-rows space-x-2 w-full">
          {!isEmpty(filters) && (
            <button
              id="action_filters_clear_all"
              className="text-xs font-medium text-blue-500 hover:underline whitespace-nowrap"
              onClick={handleClearAll}
            >
              Clear all
            </button>
          )}
          {(!isEmpty(filters) || compareSearchList.length === 0) && (
            <DropdownMenu
              buttonType="custom"
              className="w-full"
              customButton={
                <TextTooltip showArrow placement="bottom" title="Compare multiple searches">
                  <Button
                    borderless
                    onClick={() => {}}
                    leftIcon={<PlusIcon className="w-4 h-4 -mr-1" />}
                    className="text-xs font-medium w-[83px]"
                    disabled={compareButtonDisabled}
                  >
                    Compare
                  </Button>
                </TextTooltip>
              }
              disabled={compareButtonDisabled}
            >
              <CompareMenuItems />
            </DropdownMenu>
          )}
        </div>
        <div className="grow" />
        <div className="flex flex-row">
          <TextTooltip title={hasRedo || !hasUndo ? '' : 'Undo'} placement="bottom" showArrow>
            <IconButton
              id="action_filters_undo"
              size="sm"
              onClick={undoFilter}
              disabled={!hasUndo}
              Icon={CornerUpLeftIcon}
              aria-label="undo filter"
            />
          </TextTooltip>
          <TextTooltip
            title={hasUndo || !hasRedo ? '' : 'Redo'}
            placement="bottom"
            showArrow
            className="z-50"
          >
            <IconButton
              id="action_filters_redo"
              size="sm"
              onClick={redoFilter}
              disabled={!hasRedo}
              Icon={CornerUpRightIcon}
              aria-label="redo filter"
            />
          </TextTooltip>
        </div>
      </div>
      <SavedSearchesSidebar
        open={compareDrawerOpen}
        onClose={() => setCompareDrawerOpen(false)}
        featureContext={KlarityFeature.CompareSearches}
      />
    </>
  );
};

export default Header;
