import { useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import CheckIcon from '@demandscience/ui/icons/check';

import { Button, Spinner, ToggleSwitch, DrawerRootProps } from '@demandscience/ui';
import Drawer from 'components/Drawer';

import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import map from 'lodash/map';
import flatten from 'lodash/flatten';

import IllustrationNoUnreadNotification from 'components/Illustrations/IllustrationNoUnreadNotification';
import useNotifications from './useNotifications';
import List from './List';
import { useLocalStorage } from 'react-use';

const NotificationsDrawer = (props: DrawerRootProps) => {
  const [, setLocalUnreadNotifications] = useLocalStorage<Notification[]>(
    'KlarityUnreadNotifications',
    [],
  );
  const { query, markAsRead, showReadNotifications, setShowReadNotifications } = useNotifications();

  const {
    data,
    isLoading,
    isFetching,
    isError,
    error,
    isFetchingNextPage,
    isPreviousData,
    hasNextPage,
    fetchNextPage,
  } = query;

  const notifications = flatten(map(data?.pages, 'notifications'));
  const unreadNotifications = filter(notifications, ({ read_at }) => !read_at);

  const { ref, inView } = useInView();

  const handleToggle = useCallback(() => {
    setShowReadNotifications((state) => !state);
  }, [setShowReadNotifications]);

  const handleMarkAllAsRead = useCallback(() => {
    forEach(unreadNotifications, ({ id }) => markAsRead.mutate(id));
    setLocalUnreadNotifications([]);
  }, [markAsRead, unreadNotifications, setLocalUnreadNotifications]);

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    if (inView) {
      handleLoadMore();
    }
  }, [inView, handleLoadMore]);

  return (
    <Drawer id="notifications_drawer" title="Notifications" loading={isFetching} {...props}>
      <>
        <div className="px-6 py-[14px] border-b text-sm flex flex-row items-center justify-between">
          <div>
            Only show unread
            <ToggleSwitch
              className="align-bottom ml-2"
              enabled={!showReadNotifications}
              onChange={handleToggle}
            />
          </div>
          <div>
            <Button
              theme="primary"
              size="md"
              borderless
              leftIcon={<CheckIcon className="w-5 h-5" />}
              onClick={handleMarkAllAsRead}
              disabled={markAsRead.isLoading || every(notifications, 'read_at')}
            >
              Mark all as read
            </Button>
          </div>
        </div>
        {isLoading && (
          <div className="p-4">
            <Spinner size="lg" />
          </div>
        )}
        {isError && (
          <p className="p-4 text-sm text-error-500">
            Unable to load notifications:{' '}
            {(error instanceof Error && error?.message) || 'Unexpected error'}
          </p>
        )}
        {!isError && !isLoading && (
          <>
            {isEmpty(notifications) && !isPreviousData && (
              <div className="pt-[100px] flex flex-col items-center gap-6">
                <IllustrationNoUnreadNotification />
                <div className="text-sm text-center">
                  <p className="text-gray-700">
                    {showReadNotifications && 'No notifications to read.'}
                    {!showReadNotifications && "You're all caught up!"}
                  </p>
                  <p className="text-gray-500 font-light">So what’s next?</p>
                </div>
              </div>
            )}
            <List items={notifications} markAsRead={markAsRead} />
          </>
        )}
        {hasNextPage && (
          <div ref={ref} className="p-4 text-center">
            <Button onClick={handleLoadMore} outline disabled={isFetchingNextPage}>
              Load more
            </Button>
          </div>
        )}
      </>
    </Drawer>
  );
};

export default NotificationsDrawer;
