import { useQuery } from '@tanstack/react-query';
import { fetchPlans } from 'api/organization';

const usePlans = () => {
  const query = useQuery(['plans'], () => fetchPlans(), {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  return query;
};

export default usePlans;
