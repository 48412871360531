import { Routes, Route } from 'react-router-dom';

import TrackRoute from 'components/TrackRoute';

import Main from './Main';
import NotFound from 'pages/NotFound';

import SimpleLayout from 'layouts/Simple';

const HomeRoutes = () => (
  <Routes>
    <Route path="/" element={<SimpleLayout />}>
      <Route
        index
        element={
          <TrackRoute title="Home">
            <Main />
          </TrackRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);

export default HomeRoutes;
