import { Badge, Button } from '@demandscience/ui';
import { SingleSubscriptionPlan } from 'types';
import useSnackbar from 'components/Snackbar/useSnackbar';
import useMutateSubscriptions from './useMutateSubscriptions';
import { formatPrice } from 'utils/format';

interface PlanProps {
  forwardedKey?: number;
  mostPopular?: boolean;
  plan: SingleSubscriptionPlan;
}

const Plan = ({ forwardedKey, plan, mostPopular }: PlanProps) => {
  const { showMessage } = useSnackbar();

  const { id, number_of_credits, price } = plan;
  const { price: planPrice, validity, id: priceId, name: planName } = price;

  const formattedPrice = formatPrice(planPrice);

  const formattedValidity = () => {
    const unit = validity.substring(0, 1);
    const period = validity.substring(2);

    let formattedValidity = `${unit} ${period.startsWith('month') ? 'month' : 'year'}`;

    return (formattedValidity += unit !== '1' ? 's' : ''); // NOSONAR
  };

  const { create } = useMutateSubscriptions();

  const handleBuyPlan = async () => {
    try {
      const payload = {
        plan_id: id,
        plan_name: planName,
        price_id: priceId,
      };

      const { url } = await create.mutateAsync(payload);

      window.location.assign(url);
    } catch (e: any) {
      showMessage('Unexpected error, please try again later', 'error');
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center">
      {mostPopular && <Badge color="amber" size="lg" label="Most popular" />}
      <div data-testid="plan" key={forwardedKey} className="w-44 border rounded text-gray-700 p-4">
        <div data-testid="plan-credits" className="text-sm">
          {number_of_credits.toLocaleString()} credits
        </div>
        <div data-testid="plan-price" className="text-lg font-bold">
          {formattedPrice}
        </div>
        <div data-testid="plan-validity" className="text-xs text-gray-500 mt-2 mb-4">
          Validity: {formattedValidity()}
        </div>
        <Button data-testid="plan-buy-button" className="w-full" onClick={handleBuyPlan} size="sm">
          Buy
        </Button>
      </div>
    </div>
  );
};

export default Plan;
