import { useCallback, useRef, useState } from 'react';

import { isEmpty, uniq, map, find, isEqual, omit, reject, isArray, some } from 'lodash';
import CheckboxOption from '../Filter/Dropdown/CheckboxMenuItem';
import LinkMenuItem from '../Filter/Dropdown/LinkMenuItem';
import TextMenuItem from '../Filter/Dropdown/TextMenuItem';
import { FilterProps, Option } from './types';
import useFilter from './useFilter';
import { CompanyFilterName, ContactFilterName, ExcludableFilter, Filter, FilterName } from 'types';
import * as utils from './utils';
import { UseQueryResult } from '@tanstack/react-query';
import FilterInput from './FilterInput';
import Inline from 'components/Filter/Inline';
import Dropdown from 'components/Filter/Dropdown';

interface SelectFilterProps extends FilterProps {
  autocomplete?: boolean;
  hideInput?: boolean;
  name: FilterName;
  optionsQuery: UseQueryResult<Option<any>[]>;
  placeholder: string;
  showIncludeExclude?: boolean;
  showSelectAll?: boolean;
}

const getBadgeProps = (name: FilterName) => (value: any) => {
  let label = 'n/a';
  if (name === CompanyFilterName.CompanyEmployees) {
    label = utils.getEmployeesLabel(value);
  } else if (name === CompanyFilterName.CompanyRevenue) {
    label = utils.getRevenueLabel(value);
  } else if (name === ContactFilterName.JobFunction) {
    label = utils.getJobFunctionLabel(value);
  } else if (name === ContactFilterName.JobLevel) {
    label = utils.getJobLevelLabel(value);
  }

  return {
    key: label,
    label,
    exclude: value.exclude,
  };
};

const matchesLabel = (label: string, query: string) => {
  return label.toLowerCase().includes(query.toLowerCase());
};

const SelectFilter = ({
  name,
  placeholder,
  showSelectAll,
  showIncludeExclude,
  dense,
  variant,
  autocomplete,
  optionsQuery,
  clearInputOnChange,
  container,
  hideInput,
}: SelectFilterProps) => {
  const { value, onChange, onClear } = useFilter(name);

  const [query, setQuery] = useState('');

  const tokens = query?.split(/\s*,\s*/);

  const inputRef = useRef<HTMLInputElement>(null);

  const { data = [], isFetching, isError } = optionsQuery;

  const filterOptions = () => {
    const options = data.filter((option) => {
      for (const token of tokens) {
        if (matchesLabel(option.label, token)) {
          return token;
        }
      }

      return false;
    });

    return options;
  };

  let options = query === '' || autocomplete ? data : filterOptions();

  // workaround so combobox comparison by reference works (against the values)
  options = map(options, (option) => {
    const dataFromValue = find(value, (v) =>
      isEqual(omit(v as ExcludableFilter, 'exclude'), omit(option.value, 'exclude')),
    );

    if (dataFromValue) {
      return {
        ...option,
        value: dataFromValue,
      };
    }

    return {
      ...option,
      value: omit(option.value, 'exclude'),
    };
  });

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
  }, []);

  const handleChange = useCallback(
    (value: Filter | undefined) => {
      onChange(value);

      // focus input on change
      inputRef.current?.focus();

      // workaround to clear filter input and the suggestions dropdown
      if (clearInputOnChange) {
        handleInputClear();
      }
    },
    [clearInputOnChange, handleInputClear, onChange],
  );

  const handleInputChange = useCallback((e: React.SyntheticEvent) => {
    const { target } = e;

    setQuery((target as HTMLInputElement).value);
  }, []);

  const handleSelectAll = useCallback(() => {
    if (isArray(value) || value === undefined) {
      onChange(uniq([...(value || []), ...map(options, 'value')]));
    }
  }, [onChange, options, value]);

  const handleClose = useCallback(() => {
    // this is workaround for no trigger of handleChange after dropdown close eventhough input value changed
    setQuery('');
  }, []);

  const handleToggle = useCallback(
    (option: Option<any>) => {
      const newValue = map(value, (v) => {
        if (isEqual(option.value, v)) {
          return option.value.exclude
            ? omit(option.value, 'exclude')
            : { ...option.value, exclude: true };
        }

        return v;
      });

      onChange(newValue);
    },
    [onChange, value],
  );

  const handleDelete = useCallback(
    (deleted: any) => {
      const newValue = reject(value, deleted);

      onChange(newValue);
    },
    [onChange, value],
  );

  const handleClear = useCallback(() => {
    handleInputClear();

    onClear();
  }, [handleInputClear, onClear]);

  const handleEnterKey = useCallback(() => {
    const matches = reject(options, (option) => some(value, option.value));
    const hasMatches = !!matches.length;

    const clearMatches = () => {
      onChange(reject(value, (val) => some(options, (option) => option.value === val)));
    };

    const addMatches = () => {
      if (isArray(value) || value === undefined) {
        onChange(uniq([...(value || []), ...map(matches, 'value')]));
      }
    };

    if (hasMatches) {
      addMatches();
    } else {
      clearMatches();
    }
  }, [onChange, options, value]);

  const Component = variant === 'plain' ? Dropdown : Inline;

  return (
    <Component
      value={value}
      onChange={handleChange}
      multiple
      button={(props) => (
        <FilterInput
          {...props}
          ref={inputRef}
          variant={variant}
          name={name}
          dense={dense}
          onClear={handleClear}
          onDelete={handleDelete}
          onInputChange={handleInputChange}
          onInputClear={handleInputClear}
          onEnterKey={handleEnterKey}
          placeholder={placeholder}
          getBadgeProps={getBadgeProps(name)}
          hideInput={hideInput}
        />
      )}
      container={Component === Dropdown ? container : undefined}
      onClose={Component === Dropdown ? handleClose : undefined}
    >
      {isError && <TextMenuItem>Error fetching filtering values</TextMenuItem>}
      {!isError && (!autocomplete || !isEmpty(query)) && (
        <>
          {isFetching && <TextMenuItem>Loading...</TextMenuItem>}
          {!isFetching && isEmpty(options) && (
            <TextMenuItem>No match for &quot;{query}&quot;</TextMenuItem>
          )}
          {!isEmpty(options) && (
            <>
              {showSelectAll && (
                <LinkMenuItem className="sticky top-0 bg-white" onClick={handleSelectAll}>
                  Select all
                </LinkMenuItem>
              )}
              {map(options, (option) => (
                <CheckboxOption
                  key={option.id}
                  option={option}
                  onToggle={showIncludeExclude ? handleToggle : undefined}
                />
              ))}
            </>
          )}
        </>
      )}
    </Component>
  );
};

export default SelectFilter;
