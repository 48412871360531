/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import useCompare from 'components/Filters/useCompare';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { EditFormValues } from './utils';
import { stopPropagation } from 'utils/event';
import { Button, TextField } from '@demandscience/ui';
import CheckIcon from '@demandscience/ui/icons/check';
import CloseIcon from '@demandscience/ui/icons/x';

interface RadioListInputProps {
  defaultValue: string;
  handleCloseInput: () => void;
  index: number;
}

export const RadioListInput = ({ handleCloseInput, defaultValue, index }: RadioListInputProps) => {
  const { compareSearchList, setCompareSearchList } = useCompare();
  const { handleSubmit, control, formState } = useForm<EditFormValues>({
    defaultValues: { name: defaultValue },
  });

  const { errors } = formState;
  const inputRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => {
    if (inputRef.current) {
      // Click outside the input, trigger handleSubmit
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<EditFormValues> = (data) => {
    const updatedSearches = [...compareSearchList];

    updatedSearches[index] = { ...updatedSearches[index], label: data.name };

    setCompareSearchList(updatedSearches);
    handleCloseInput();
  };

  return (
    <div
      className="w-full mb-2"
      onClick={(e) => stopPropagation(e)}
      onKeyDown={(e) => stopPropagation(e)}
      ref={inputRef}
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            hideLabel
            type="text"
            variant="line"
            trailingIcon={
              <div className="flex flex-row items-center mt-2">
                <Button
                  className="text-gray-300 hover:text-red-400 hover:bg-transparent"
                  size="sm"
                  aria-label="Close"
                  theme="white"
                  onClick={() => handleCloseInput()}
                >
                  <CloseIcon size={17} />
                </Button>
                <Button
                  className="text-gray-300 hover:text-green-400 hover:bg-transparent"
                  size="sm"
                  aria-label="Submit"
                  theme="white"
                  onClick={handleSubmit(onSubmit)}
                >
                  <CheckIcon size={17} />
                </Button>
              </div>
            }
            error={errors.name?.message}
            {...field}
          />
        )}
        rules={{
          required: 'Required field',
        }}
      />
    </div>
  );
};
