import { Company, RowLayout } from 'types';
import Industry from 'components/Company/Industry';
import Headcount from 'components/Company/Headcount';
import CompanyName from 'components/Company/CompanyName';
import CompanyLocation from 'components/Company/CompanyLocation';

interface ContactCompanyCellProps {
  row: Company;
  rowLayout?: RowLayout;
}

const ContactCompanyCell = ({ row, rowLayout }: ContactCompanyCellProps) => (
  <div>
    <CompanyName row={row} className="font-medium" />
    <CompanyLocation inline className="text-xs text-gray-500" row={row} />
    {rowLayout === RowLayout.Narrow && (
      <>
        <Industry inline className="text-xs text-gray-500" row={row} />
        <Headcount inline className="text-xs text-gray-500" row={row} />
      </>
    )}
  </div>
);

export default ContactCompanyCell;
