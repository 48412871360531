import { useQuery } from '@tanstack/react-query';
import { getSearchSettings } from 'api/search';

const useSearchSettings = (options?: any) => {
  const query = useQuery(['search', 'settings'], getSearchSettings, {
    staleTime: 60 * 60 * 1000, // 1 hour
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export default useSearchSettings;
