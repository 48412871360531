import { Search as BaseSearch, ComboboxProps as BaseSearchProps } from '@demandscience/ui';
import { SelectOption } from '@demandscience/ui/dist/types';
import { useCallback } from 'react';

interface SearchProps extends Omit<BaseSearchProps, 'value' | 'onChange'> {
  onChange: (value?: string) => void;
  value?: string;
}

const Search = ({ value, onChange, ...props }: SearchProps) => {
  const handleChange = useCallback(
    (option: SelectOption | undefined) => {
      if (option) {
        onChange(option.value as string);
      } else {
        onChange();
      }
    },
    [onChange],
  );

  const searchValue = value === undefined ? value : { value, label: value };

  // applying a div wrapper and borderless Search component as border is making search 2px higher than it should be
  // the height of Search component should be fixed at some point in the UI lib
  return (
    <div className="px-4 rounded ring-inset ring-1 ring-gray-300 hover:ring-gray-700 focus-within:ring-primary-500 focus-within:hover:ring-primary-500">
      <BaseSearch
        value={searchValue}
        // @ts-expect-error demandscience-ui issue
        onChange={handleChange}
        freeInput={false}
        borderless
        {...props}
      />
    </div>
  );
};

export default Search;
