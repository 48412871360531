import { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';

import { Spinner, SpinnerProps } from '@demandscience/ui';

export interface SplashProps extends SpinnerProps {
  show?: boolean;
  timeout: number;
}

const Splash: React.FC<SplashProps> = ({ show: defaultShow, timeout, ...props }) => {
  const [show, setShow] = useState(defaultShow);
  const time = useRef(Date.now());

  useEffect(() => {
    const delayHide = async (mseconds: number) => {
      await new Promise((resolve) => setTimeout(resolve, mseconds));

      setShow(false);
    };

    if (!defaultShow) {
      const diff = Date.now() - time.current;

      if (diff > timeout) {
        setShow(false);
      } else {
        delayHide(timeout - diff);
      }
    }
  }, [defaultShow, timeout]);

  return (
    <Transition
      show={show}
      leave="transition-opacity duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="bg-white fixed inset-0 z-50 flex justify-center items-center">
        <Spinner size="lg" {...props} />
      </div>
    </Transition>
  );
};

export default Splash;
