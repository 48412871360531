import { Model, ModelType } from 'types';
import { Button, DropdownMenu, Tooltip } from '@demandscience/ui';
import ContactBasicInfo from 'components/Contact/BasicInfo';
import CompanyBasicInfo from 'components/Company/BasicInfo';
import MailIcon from '@demandscience/ui/icons/mail';
import PhoneIcon from '@demandscience/ui/icons/phone';
import SmartphoneIcon from '@demandscience/ui/icons/smartphone';
import RevealSingleContactProvider from 'components/RevealContacts/RevealSingleContactProvider';
import ExportProvider from 'components/Export/ExportProvider';
import { every, isEmpty, some } from 'lodash';
import classNames from 'classnames';
import { Origin } from '@demandscience/ui/dist/types';
import { stopPropagation } from 'utils/event';

interface ContactDetailsCellProps {
  className?: string;
  displayContactDetailsOnTop?: boolean;
  origin?: Origin;
  row: Model;
}

const ContactDetailsCell = ({
  className,
  displayContactDetailsOnTop,
  row,
  origin = 'bottom-left',
}: ContactDetailsCellProps) => {
  // only contact row has first_name
  const isContact = 'first_name' in row;

  if ((!isContact || isEmpty(row.emails)) && isEmpty(row.phones)) {
    return null;
  }

  if (displayContactDetailsOnTop) {
    origin = 'top-left';
  }

  return (
    <div className={className} onClick={stopPropagation}>
      <ExportProvider modelType={ModelType.Contact} selectionCount={1}>
        <RevealSingleContactProvider>
          <DropdownMenu
            dropdownClassName="z-[1]"
            buttonType="custom"
            customButton={
              <Tooltip
                tooltipClassName={`group-[[aria-expanded="true"]]:hidden`}
                title="View contact info"
                position="bottom"
                showArrow
              >
                <Button
                  className={classNames(
                    'px-2 py-0 bg-transparent hover:bg-transparent align-middle',
                    {
                      'text-gray-500 hover:text-primary-500': !row.revealed,
                      'text-emerald-500 hover:text-emerald-600': row.revealed,
                    },
                  )}
                  borderless
                  theme="white"
                  tabIndex={-1}
                >
                  <div className="flex-shrink-0 flex flex-row gap-3">
                    {isContact && !isEmpty(row.emails) && <MailIcon className="w-5 h-5" />}
                    {!every(row.phones, { phone_type: 'Mobile' }) && (
                      <PhoneIcon className="w-5 h-5" />
                    )}
                    {some(row.phones, { phone_type: 'Mobile' }) && (
                      <SmartphoneIcon className="w-5 h-5" />
                    )}
                  </div>
                </Button>
              </Tooltip>
            }
            origin={origin}
          >
            <div className="w-[350px]">
              {isContact && <ContactBasicInfo row={row} />}
              {!isContact && <CompanyBasicInfo row={row} />}
            </div>
          </DropdownMenu>
        </RevealSingleContactProvider>
      </ExportProvider>
    </div>
  );
};

export default ContactDetailsCell;
