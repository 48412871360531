import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchSavedSearches } from 'api/savedSearch';
import { isEqual, isNumber } from 'lodash';
import { useTracking } from 'react-tracking';
import { SavedSearchParams, Show } from 'types';
import { DataLayerData } from 'types/tracking';

const useSavedSearches = (params: Partial<SavedSearchParams>, options?: any) => {
  const { trackEvent } = useTracking<DataLayerData>({ type: 'event', event: 'user_info' });

  const query = useInfiniteQuery(
    ['savedSearches', params],
    ({ pageParam: index = 0 }) =>
      fetchSavedSearches({
        count: 25,
        sort: { by: 'created_at', descending: true },
        ...params,
        index,
      }),
    {
      staleTime: 60 * 1000, // 1 minute
      refetchOnWindowFocus: true,
      onSuccess: (data) => {
        const count = data.pages.at(0)?.count;

        if (isNumber(count) && isEqual(params.filter, { show: Show.All, is_a_persona: false })) {
          trackEvent({
            countSearches: count,
          });
        }
      },
      ...options,
    },
  );

  return query;
};

export default useSavedSearches;
