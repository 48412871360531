import { AggregateFilter } from 'types';
import useModal from 'hooks/useModal';
import { Button } from '@demandscience/ui';
import { ElementType, ReactElement } from 'react';
import BookMarkOverlay from './BookMarkOverlay';
import { Aggregate as AggregateType } from 'types';

interface BookMarkControlControlProps<F extends AggregateFilter> {
  aggregate: {
    count: number;
    values: AggregateType<F>[];
  };
  aggregateField: string;
  as?: ElementType;
  borderless: boolean;
  className: string;
  leftIcon?: ReactElement;
  module_type_id: string;
  module_type_name: string;
  onSuccess: () => void;
}

const BookMarkControl = <F extends AggregateFilter>({
  as: Component = Button,
  onSuccess,
  module_type_name,
  aggregate,
  aggregateField,
  module_type_id,
  ...props
}: BookMarkControlControlProps<F>) => {
  const { open, openModal, closeModal } = useModal();

  return (
    <>
      <Component type="button" onClick={openModal} {...props} />
      <BookMarkOverlay
        open={open}
        onClose={closeModal}
        onSuccess={onSuccess}
        module_type_name={module_type_name}
        aggregate={aggregate}
        module_type_id={module_type_id}
        aggregateField={aggregateField}
      />
    </>
  );
};

export default BookMarkControl;
