import { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import replace from 'lodash/replace';

import { Button, TextField } from '@demandscience/ui';

import Alert from 'components/Alert';
import PasswordField from './PasswordField';
import useAuth from './useAuth';
import EmailNotConfirmedException from './Cognito/EmailNotConfirmedException';
import ChallengeException from './Cognito/ChallengeException';
import PrivacyAndTerms from './PrivacyAndTerms';
import { AxiosError } from 'axios';
import { trim } from 'lodash';
import { CodeDeliveryDetails } from 'types';

type SigninFormValues = {
  password: string;
  username: string;
};

export interface SigninProps {
  onEmailConfirm: (CodeDeliveryDetails: any) => void;
  onNewPasswordRequired: (challengeParam: any) => void;
  onSignin: (user: any) => void;
  onSignupConfirm: (username: string, codeDeliveryDetails: CodeDeliveryDetails) => void;
  passwordHelperText?: string;
  showPrivacyAndTerms?: boolean;
}

const Signin = ({
  showPrivacyAndTerms,
  passwordHelperText,
  onSignin,
  onSignupConfirm,
  onEmailConfirm,
  onNewPasswordRequired,
}: SigninProps) => {
  const [privacyAndTermsChecked, setPrivacyAndTermsChecked] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const { signIn, resendSignUp } = useAuth();
  const { control, handleSubmit, formState } = useForm({
    defaultValues: { username: '', password: '' },
  });
  const { isSubmitting, errors } = formState;

  const handleUserNotConfirmedException = async (_e: Error, username: string) => {
    try {
      const { CodeDeliveryDetails: codeDeliveryDetails } = await resendSignUp(username);

      onSignupConfirm(username, codeDeliveryDetails);
    } catch (e: any) {
      const { message } = e;

      setApiError(message);
    }
  };

  const handleNotAuthorizedException = (e: Error) => {
    setApiError(e.message);
  };

  const hadleChallengeException = (e: ChallengeException) => {
    const { message, challengeName, challengeParam } = e;

    if (challengeName === 'NEW_PASSWORD_REQUIRED') {
      onNewPasswordRequired(challengeParam);
    } else {
      setApiError(`${message}: ${challengeName}`);
    }
  };

  const handleEmailNotConfiguredException = (e: EmailNotConfirmedException) => {
    const { email } = e;
    const CodeDeliveryDetails = {
      AttributeName: 'email',
      Destination: replace(email, /^(.).+@(.).+/, '$1***@$2***'),
    };

    onEmailConfirm(CodeDeliveryDetails);
  };

  const handleApiError = (e: AxiosError<any>) => {
    const { message, response } = e;
    const errorCode = response?.data?.error_code;
    let error = message;

    if (errorCode === 'inactive') {
      error = 'Signin forbidden, your trial period has ended';
    } else if (errorCode === 'suspended') {
      error = 'Signin forbidden, your account has been suspended';
    }

    setApiError(error);
  };

  const onSubmit: SubmitHandler<SigninFormValues> = async (data) => {
    const { username, password } = data;
    setApiError(null);

    try {
      const user = await signIn(trim(username), trim(password));

      if (onSignin) onSignin(user);
    } catch (e: any) {
      const { name } = e;

      if (name === 'UserNotConfirmedException') {
        handleUserNotConfirmedException(e, username);
      } else if (name === 'NotAuthorizedException') {
        handleNotAuthorizedException(e);
      } else if (e instanceof EmailNotConfirmedException) {
        handleEmailNotConfiguredException(e);
      } else if (e instanceof ChallengeException) {
        hadleChallengeException(e);
      } else if (e instanceof AxiosError) {
        handleApiError(e);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="space-y-4">
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextField
              id="input_email"
              label="Email"
              placeholder="Enter an email"
              variant="outlined"
              size="lg"
              required
              autoComplete="email"
              error={errors.username?.message}
              {...field}
            />
          )}
          rules={{ required: 'Required field' }}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <PasswordField
              id="input_password"
              label="Password"
              placeholder="Enter a password"
              variant="outlined"
              size="lg"
              required
              autoComplete="current-password"
              error={errors.password?.message}
              helperText={passwordHelperText}
              {...field}
            />
          )}
          rules={{ required: 'Required field' }}
        />
        {showPrivacyAndTerms && (
          <PrivacyAndTerms value={privacyAndTermsChecked} onChange={setPrivacyAndTermsChecked} />
        )}
        {apiError && <Alert message={apiError} />}
      </div>

      <Button
        id="action_signin"
        className="w-full mt-6"
        size="lg"
        type="submit"
        disabled={isSubmitting || (showPrivacyAndTerms && !privacyAndTermsChecked)}
      >
        Sign in
      </Button>
    </form>
  );
};

export default Signin;
