import { useState, forwardRef, useCallback } from 'react';
import EyeIcon from '@demandscience/ui/icons/eye';
import EyeOffIcon from '@demandscience/ui/icons/eye-off';
import { TextField, TextFieldProps, IconButton } from '@demandscience/ui';

const PasswordField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const [show, setShow] = useState(false);

  const handleClick = useCallback(() => {
    setShow((state) => !state);
  }, []);

  return (
    <TextField
      ref={ref}
      {...props}
      type={show ? 'text' : 'password'}
      trailingIcon={
        <IconButton
          type="button"
          size="xs"
          theme="default"
          round
          onClick={handleClick}
          Icon={show ? EyeOffIcon : EyeIcon}
        />
      }
      trailingOverrideError
    />
  );
});

PasswordField.displayName = 'PasswordField';

export default PasswordField;
