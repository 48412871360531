import { createContext } from 'react';
import { Notification } from 'types';

export interface CreditsContextInterface {
  acceptCreditsRequest: (notification: Notification) => void;
  buyCredits: () => void;
  manageCredits: () => void;
  rejectCreditsRequest: (notification: Notification) => void;
  requestCredits: () => void;
}

const CreditsContext = createContext<CreditsContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  CreditsContext.displayName = 'CreditsContext';
}

export default CreditsContext;
