import { Badge } from '@demandscience/ui';
import { drop, truncate } from 'lodash';
import { exportDestinations, ExportStatus, ModelType, Export, TableColumn } from 'types';
import PreviousExportsDropdown from './PreviousExportsDropdown';
import ParagraphWithOverflowTooltip from 'components/Typography/ParagraphWithOverflowTooltip';

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const columns: TableColumn<Export>[] = [
  {
    field: 'created_at',
    headerName: 'Date',
    valueGetter: ({ created_at }: Export) =>
      new Date(created_at).toLocaleDateString(undefined, dateOptions),
  },
  {
    field: 'records',
    headerName: 'Records',
  },
  {
    field: 'type',
    headerName: 'Type',
    valueGetter: ({ type }: Export) => (type === ModelType.Company ? 'Companies' : 'Contacts'),
  },
  {
    field: 'destination',
    headerName: 'Destination',
    valueGetter: ({ destination }: Export) => exportDestinations[destination],
  },
  {
    field: 'identifier',
    headerName: 'Identifier',
    valueGetter: ({ identifier, status }: Export) => (
      <div className="flex flex-row gap-3 items-center max-w-sm">
        <ParagraphWithOverflowTooltip className="ellipsis" tooltipClassName="max-w-sm break-words">
          {identifier}
        </ParagraphWithOverflowTooltip>
        {(status === ExportStatus.Processing || status === ExportStatus.Error) && (
          <Badge
            color="rose"
            size="sm"
            label={`${status.charAt(0).toUpperCase() + status.substring(1)}`}
          />
        )}
      </div>
    ),
  },
  {
    field: '',
    headerName: '',
    valueGetter: ({ id, status, identifier }) => {
      if (status !== ExportStatus.Completed) {
        return;
      }
      return <PreviousExportsDropdown id={id} identifier={identifier} />;
    },
  },
];

export const columnsWithAccount: TableColumn<Export>[] = [
  columns[0],
  {
    field: 'name',
    headerName: 'Member',
    valueGetter: ({ account }) => <span>{truncate(account?.name, { length: 36 })}</span>,
  },
  ...drop(columns),
];
