import { useState, useEffect, useCallback, useMemo } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { Link, ToggleSwitch, Button, Modal, Badge, Spinner } from '@demandscience/ui';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import useMutateIntegration from 'components/Organization/useMutateIntegration';
import useSnackbar from 'components/Snackbar/useSnackbar';
import showPopup from 'pages/Dashboard/Settings/Integrations/showPopup';
import Cookies from 'universal-cookie';
import { IntegrationOptions, DataIntegrationSchema } from 'types';
import { AxiosError } from 'axios';
import ConfirmOverlay from 'components/ConfirmOverlay';
import classNames from 'classnames';

interface CardIntegrationProps {
  data?: DataIntegrationSchema;
  disabled?: boolean;
  icon?: React.ReactElement;
  integrationType?: IntegrationOptions;
  linkText?: string;
  refetch: () => Promise<UseQueryResult>;
  text?: string;
  title?: string;
  url?: string;
}

const CardIntegration = ({
  icon,
  title,
  text,
  data,
  integrationType,
  url,
  linkText,
  disabled,
  refetch,
}: CardIntegrationProps) => {
  const { showMessage } = useSnackbar();
  const [toggle, setToggle] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [open, setOpen] = useState(false);
  const addedCookies = useMemo(() => new Cookies(), []);

  const isIntegrated = data?.[`${integrationType!}-authenticate`]?.enabled === true;

  const { connect } = useMutateIntegration({ type: integrationType });
  const { disconnect } = useMutateIntegration();
  const navigate = useNavigate();

  const isLoading = connect.isLoading || disconnect.isLoading;

  const handleConnect = useCallback(async () => {
    if (!integrationType) {
      return;
    }

    try {
      const response = await connect.mutateAsync({ type: integrationType });
      if (response.auth_required) {
        showPopup(
          response.auth_url,
          showMessage,
          setToggle,
          undefined,
          undefined,
          refetch,
          setRedirect,
        );
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to connect, please try again', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  }, [connect, integrationType, showMessage, refetch]);

  const handleDisconnect = useCallback(async () => {
    try {
      setToggle(false);
      addedCookies.remove(integrationType!, { path: '/' });
      setOpen(false);
      await disconnect.mutateAsync(integrationType!);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to disconnect, please try again', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  }, [addedCookies, disconnect, integrationType, showMessage]);

  const handleConfirmDisconnect = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleToggle = useCallback(() => {
    if (isIntegrated && toggle) {
      handleConfirmDisconnect();
    } else handleConnect();
  }, [handleConnect, isIntegrated, toggle]);

  useEffect(() => {
    refetch();
    if (isIntegrated) {
      setToggle(true);
    } else {
      setToggle(false);
    }
    redirect && navigate(`/settings/integrations/${integrationType}`, { replace: true });
  }, [isIntegrated, refetch, redirect, navigate, integrationType]);

  return (
    <div className="flex flex-col gap-y-4 border border-gray-200 rounded p-4">
      <header className="flex gap-x-2 items-center justify-between">
        <h3 className="text-sm text-gray-700 flex items-center gap-2">
          {icon}
          {title}
        </h3>
        {!disabled && !url && (
          <div className="flex justify-center items-center">
            {isLoading && (
              <div className="mr-2">
                <Spinner size="sm" />
              </div>
            )}

            <ToggleSwitch
              enabled={toggle}
              onChange={handleToggle}
              size="sm"
              theme="primary"
              disabled={isLoading}
            />
          </div>
        )}
        {disabled && (
          <Badge className="uppercase" size="sm" theme="primary">
            Coming soon
          </Badge>
        )}
      </header>
      <p className="text-sm text-gray-500 grow">{text}</p>
      <div className={classNames('self-end text-xs text-right', { invisible: disabled })}>
        {integrationType && (
          <Link
            className={!toggle ? 'pointer-events-none' : ''}
            as={RouterLink}
            to={`./${integrationType}`}
            disabled={!toggle}
          >
            View settings
          </Link>
        )}

        {url && (
          <Button as="a" href={url} theme="primary" target="_blank" className="ml-1">
            {linkText}
          </Button>
        )}
      </div>
      <ConfirmOverlay
        open={open}
        onClose={handleClose}
        onConfirm={handleDisconnect}
        confirmLabel="YES, disconnect"
      >
        <Modal.Title className="mb-4 text-lg">
          Are you sure you want to disconnect {title}?
        </Modal.Title>
      </ConfirmOverlay>
    </div>
  );
};

export default CardIntegration;
