import { Company } from 'types';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';
import { stopPropagation } from 'utils/event';

interface CompanyNameProps {
  className?: string;
  clickable?: boolean;
  row?: Company;
}

const CompanyName = ({ row, className, clickable }: CompanyNameProps) => {
  const [domain] = row?.main_domains || [];
  const name = row?.company_name;
  const link = clickable && domain;

  return (
    <Paragraph className={className} title={clickable ? `${name}\n${domain}` : undefined}>
      {link && (
        <a
          className="text-blue-500 hover:underline"
          href={`https://${domain}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={stopPropagation}
        >
          {name}
        </a>
      )}
      {!link && row?.company_name}
    </Paragraph>
  );
};

export default CompanyName;
