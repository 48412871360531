import { createContext } from 'react';
import { GptModel, Message } from 'types/aissistant';

export interface ChatSearchContextInterface {
  addMessage: (message: Message) => void;
  isLoading: boolean;
  messages?: Message[];
  model?: GptModel;
}

const ChatSearchContext = createContext<ChatSearchContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  ChatSearchContext.displayName = 'ChatSearchContext';
}

export default ChatSearchContext;
