import { Avatar } from '@demandscience/ui';
import BellIcon from '@demandscience/ui/icons/bell';

const SystemAvatar = () => (
  <Avatar className="w-8 h-8 bg-gray-200 text-gray-600">
    <BellIcon size={20} />
  </Avatar>
);

export default SystemAvatar;
