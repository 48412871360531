import { useQuery } from '@tanstack/react-query';
import { aiChatPrompt } from 'api/aiSearch';

const useChatPrompt = (options?: Record<string, any>) => {
  const query = useQuery(['chatPrompt'], () => aiChatPrompt(), {
    refetchOnWindowFocus: false, // no need to refetch on window focus
    staleTime: Infinity,
    ...options,
  });

  return query;
};

export default useChatPrompt;
