import useList from './useList';
import { Button } from '@demandscience/ui';
import { useEffect, useState } from 'react';
import { ListOrigin, ListStatus } from 'types';
import { Link, useParams } from 'react-router-dom';
import ListDetailsHeader from './ListDetailsHeader';
import ListDetailsRecords from './ListDetailsRecords';
import ListDetailsProcessing from './ListDetailsProcessing';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import ListRecordsSelectionProvider from 'components/DataGrid/Selection/ListRecordsSelectionProvider';
import CustomizationProvider from 'components/SearchCustomization/CustomizationProvider';
import FeatureFlagProvider from 'components/FeatureFlag/FeatureFlagProvider';

type ListDetailsParams = {
  id: string;
};

const ListDetails = () => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const { id } = useParams<keyof ListDetailsParams>() as ListDetailsParams;

  const getRefetchInterval = (records_origin: ListOrigin, status: ListStatus) => {
    let interval: number | false = false;

    if (status === ListStatus.Processing && records_origin === ListOrigin.Search) {
      interval = 5 * 1000;
    } else if (status === ListStatus.Processing && records_origin === ListOrigin.Upload) {
      interval = 60 * 1000;
    }

    return interval;
  };

  const { data, isLoading, isError, remove } = useList(id, { refetchInterval });

  const { records_origin, records_type, status, owner, visibility } = data || {};

  useEffect(() => {
    if (!records_origin || !status) return;
    setRefetchInterval(getRefetchInterval(records_origin, status));
  }, [records_origin, status]);

  // remove query from cache on unmount of list that's in processing state
  useEffect(() => {
    return () => {
      if (data?.status === ListStatus.Processing) {
        remove();
      }
    };
  }, [remove, data?.status]);

  const header = <ListDetailsHeader list={data} className="mb-6" />;

  if (!isLoading && isError) {
    return (
      <>
        {header}
        <EmptyResults
          message="No list found here"
          illustration={<Illustration.Empty className="w-40 h-40 mb-6" />}
        >
          <p className="text-sm text-gray-500 mt-2">Maybe the owner deleted it...</p>
          <div className="flex items-center mt-4">
            <Button
              className="flex-shrink-0 px-1 py-1 border-none group text-blue-500 hover:bg-primary-200 disabled:text-gray-300 disabled:hover:bg-transparent focus:ring-0"
              borderless
              aria-label="See all your lists"
              as={Link}
              to="/lists"
            >
              See all your lists
            </Button>
          </div>
        </EmptyResults>
      </>
    );
  }

  if (status === ListStatus.Processing) {
    return (
      <>
        {header}
        <ListDetailsProcessing />
      </>
    );
  }

  return (
    <FeatureFlagProvider>
      <CustomizationProvider>
        {header}
        {records_type && records_origin && (
          <ListRecordsSelectionProvider list={data!}>
            <ListDetailsRecords
              id={id}
              owner={owner!}
              visibility={visibility!}
              records_origin={records_origin}
              records_type={records_type}
              status={status}
              className="mb-8"
            />
          </ListRecordsSelectionProvider>
        )}
      </CustomizationProvider>
    </FeatureFlagProvider>
  );
};

export default ListDetails;
