import map from 'lodash/map';
import { CompanyFilterName, ContactFilterName, DataFilterName, ResultsView } from 'types';
import { useNavigate } from 'react-router-dom';
import IconBox from 'components/Layout/IconBox';
import ListItem from 'components/Layout/ListItem';
import StartIcon from '@demandscience/ui/icons/star';

const exampleSearches = [
  {
    title: 'Automotive C-Level executives in the UK',
    subtitle: 'Contacts',
    filters: {
      [CompanyFilterName.CompanyIndustry]: [{ category: 'Automotive' }],
      [ContactFilterName.JobLevel]: [{ level: 'C-Level' }],
      [ContactFilterName.ContactLocation]: [
        {
          country: 'United Kingdom',
          designation: 'country',
          global_region: 'Europe',
        },
      ],
    },
    tab: ResultsView.Contacts,
  },
  {
    title: 'Companies with 1-9 employees in the Financial industry',
    subtitle: 'Companies',
    filters: {
      [CompanyFilterName.CompanyIndustry]: [{ category: 'Banking/Accounting/Financial' }],
      [CompanyFilterName.CompanyEmployees]: [
        {
          range_max: 4,
          range_min: 1,
          size_range: '1-4',
        },
        {
          range_max: 9,
          range_min: 5,
          size_range: '5-9',
        },
      ],
    },
    tab: ResultsView.Companies,
  },
  {
    title: 'IT managers in New-York with a mobile phone',
    subtitle: 'Contacts',
    filters: {
      [DataFilterName.ContactDetails]: {
        operator: 'AND',
        contact_mobile_phone: true,
      },
      [ContactFilterName.ContactLocation]: [
        {
          city: 'New York',
          country: 'United States',
          county: 'Queens',
          designation: 'city',
          global_region: 'North America',
          state: 'New York',
        },
      ],
      [ContactFilterName.JobTitle]: [
        {
          freetext: 'IT manager',
        },
      ],
    },
    tab: ResultsView.Contacts,
  },
];

const ExampleSearchList = () => {
  const navigate = useNavigate();

  const handleClick = (search: any) => () => {
    const { filters, tab } = search;

    navigate('/search', { state: { filters, tab } });
  };

  return (
    <div className="divide-y">
      {map(exampleSearches, (search) => (
        <ListItem
          key={search.title}
          className="group cursor-pointer"
          role="button"
          onClick={handleClick(search)}
          leadingContent={
            <IconBox className="px-2 py-2 bg-emerald-100">
              <StartIcon className="w-4 h-4 text-emerald-500" />
            </IconBox>
          }
          title={search.title}
          subtitle={search.subtitle}
        />
      ))}
    </div>
  );
};

export default ExampleSearchList;
