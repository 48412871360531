import useFilters from 'components/Filters/useFilters';
import { omit, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { ContactFilterName, Filters } from 'types';

const usePersonasFilters = () => {
  const { setFilters, filters } = useFilters();

  const nonPersonaFilters = useMemo(() => {
    // get currently selected NON contact filters
    return omit(filters, [
      ContactFilterName.ContactName,
      ContactFilterName.JobLevel,
      ContactFilterName.JobTitle,
      ContactFilterName.JobFunction,
    ]);
  }, [filters]);

  const current = useMemo(() => {
    // get currently selected contact filters
    return pick(filters, [
      ContactFilterName.ContactName,
      ContactFilterName.JobLevel,
      ContactFilterName.JobTitle,
      ContactFilterName.JobFunction,
    ]);
  }, [filters]);

  const clear = useCallback(() => {
    // remove all existing contact filters
    setFilters(nonPersonaFilters);
  }, [nonPersonaFilters, setFilters]);

  const apply = useCallback(
    (personaFilters: Filters) => {
      // remove all existing contact filters and apply given
      setFilters({
        ...nonPersonaFilters,
        ...personaFilters,
      });
    },
    [nonPersonaFilters, setFilters],
  );

  return { current, clear, apply };
};

export default usePersonasFilters;
