import { AggregationResult, ApiResponse } from 'types/BookMark';

const getColorFromName = (name: string) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};
const formatData = (apiResponse: ApiResponse, includeNow: boolean, aggregate: any) => {
  const categories = apiResponse.search_results.map((result) => {
    const d = new Date(result.created_at);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    return `${day}.${month}`;
  });

  if (includeNow) {
    const now = new Date();
    const nowDay = String(now.getDate()).padStart(2, '0');
    const nowMonth = String(now.getMonth() + 1).padStart(2, '0');
    categories.push(`${nowDay}.${nowMonth}`);
  }

  const dataMap = new Map<string, { color: string; data: number[]; name: string }>();

  apiResponse.search_results.forEach((result) => {
    Object.values(result.result.aggregation_results).forEach((aggregation: AggregationResult[]) => {
      aggregation.forEach((entry) => {
        if (!dataMap.has(entry.value)) {
          dataMap.set(entry.value, {
            name: entry.value,
            data: [],
            color: getColorFromName(entry.value),
          });
        }
        dataMap.get(entry.value)!.data.push(entry.count);
      });
    });
  });

  if (includeNow) {
    aggregate.values.forEach((newEntry: any) => {
      if (!dataMap.has(newEntry.value)) {
        dataMap.set(newEntry.value, {
          name: newEntry.value,
          data: Array(categories.length - 1).fill(0), // Fill with 0s for the previous data points
          color: getColorFromName(newEntry.value),
        });
      }
      dataMap.get(newEntry.value)!.data.push(newEntry.count);
    });
  }

  const data = Array.from(dataMap.values());

  const sidebarCategories = [...categories];
  if (includeNow) {
    sidebarCategories.pop();
  }

  return { categories, data, sidebarCategories };
};
export default formatData;
