import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';

export const dirtyValues = (
  dirtyFields: any,
  allValues: Record<string, any>,
): Record<string, any> => {
  if (dirtyFields === true || isArray(dirtyFields)) return allValues;

  return reduce(
    keys(dirtyFields),
    (res, key) => ({
      ...res,
      [key]: dirtyValues(dirtyFields[key], allValues[key]),
    }),
    {},
  );
};
