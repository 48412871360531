import { Text } from '@demandscience/ui';
import { GptMessageType, Message } from 'types/aissistant';
import { getFilterText, modelFiltersToKlarityFilters } from '../ChatUtils';

interface ChatBubbleProps {
  message: Message;
}

const ChatBubble = ({ message }: ChatBubbleProps) => {
  if (message.role === GptMessageType.system) {
    return null;
  }

  if (message.role === GptMessageType.assistant) {
    let messageText = message.content;
    if (message.function_call) {
      const { arguments: args } = message.function_call;
      const rawFilters = JSON.parse(args);
      const tranformedFilters = modelFiltersToKlarityFilters(rawFilters);
      messageText = `Filters applied: ${getFilterText(tranformedFilters)}`;
    }

    return (
      <div className="pl-4 pr-16 p-2">
        <div className="flex gap-4 justify-start items-end">
          <div className="chat-bubble-ai">
            <Text className="text-sm">{messageText}</Text>
          </div>
        </div>
      </div>
    );
  } else if (message.role === GptMessageType.user) {
    return (
      <div className="pr-4 pl-16 p-2">
        <div className="flex gap-4 justify-end items-end">
          <div className="chat-bubble-me">
            <Text className="text-sm">{message.content}</Text>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ChatBubble;
