import api from 'api';
import {
  SearchPayload,
  SearchResponse,
  AutocompleteFieldName,
  AutocompleteParams,
  AutocompleteResponse,
  RevealContactsPayload,
  RevealContactsResponse,
  AggregatesResponse,
  ModelType,
  SearchSettingsResponse,
  AggregateSearchPayload,
} from 'types';

export const revealContacts = (payload: RevealContactsPayload): Promise<RevealContactsResponse> =>
  api.patch('/search/contact/reveal', payload);

export const fetchAutocomplete = <T extends AutocompleteFieldName>(
  name: T,
  params: AutocompleteParams,
): Promise<AutocompleteResponse[T]> => api.get(`/search/autocomplete/${name}`, { params });

export const fetchSearch = (
  kind: ModelType,
  params: AggregateSearchPayload | SearchPayload,
  signal?: AbortSignal,
): Promise<SearchResponse> => api.post(`/search/${kind}`, params, { signal });

export const fetchAggregateSearch = (
  kind: ModelType,
  params: AggregateSearchPayload,
): Promise<AggregatesResponse> =>
  fetchSearch(kind, params).then((data) => {
    // transform search response to aggregate data
    // count is number of matched records
    const {
      aggregation_results,
      pagination: { count },
    } = data;

    return { ...aggregation_results, count };
  });

export const getSearchSettings = (): Promise<SearchSettingsResponse> => api.get('/search/settings');
