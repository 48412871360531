import { map } from 'lodash';
import { useCallback, useState } from 'react';
import useMutateList from './useMutateList';
import ListFormFields from './ListFormFields';
import { Button, RadioList } from '@demandscience/ui';
import CheckIcon from '@demandscience/ui/icons/check';
import useApiValidation from 'hooks/useApiValidation';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ModelType,
  Visibility,
  CreateListPayload,
  CreateListFormValues,
  UploadListResponse,
} from 'types';
import UploadListFile from './UploadListFile';
import useKlarityFeatures from 'hooks/useKlarityFeatures';

interface UploadListFormProps {
  onCancel: () => void;
  type?: ModelType;
}

const UploadListForm = ({ onCancel, type }: UploadListFormProps) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [typeSelection, setTypeSelection] = useState<ModelType>(type || ModelType.Company);
  const [uploadResponse, setUploadResponse] = useState<UploadListResponse | undefined>();
  const [step, setStep] = useState(1);
  const { control, handleSubmit, formState, watch, setValue, setError, ...form } =
    useForm<CreateListFormValues>({
      defaultValues: { name: '', description: '', visibility: Visibility.Private },
    });
  const { isSubmitting } = formState;
  const { apiError, setApiError, handleApiValidation } =
    useApiValidation<CreateListFormValues>(setError);

  const { create } = useMutateList();

  const handleTypeSelection = useCallback(
    (type: string) => {
      setTypeSelection(type as ModelType);

      setApiError(null);
    },
    [setApiError],
  );

  const handleNextClick = useCallback(() => {
    setStep(2);
  }, []);

  const handleUploadAgainClick = useCallback(() => {
    form.reset();
    create.reset();

    setUploadResponse(undefined);
    setStep(1);
  }, [create, form]);

  const { availableListTypeOptions } = useKlarityFeatures();

  const buildPayload = ({ members, notification_message, ...data }: CreateListFormValues) => {
    const visibility = data.visibility;

    let payload: CreateListPayload = {
      records_type: typeSelection,
      ...data,
    };

    if (uploadResponse) {
      payload = {
        ...payload,
        ID: uploadResponse?.list_id,
        file_name: uploadResponse?.file_name,
      };
    }

    if (
      (visibility === Visibility.Public || visibility === Visibility.Restricted) &&
      notification_message
    ) {
      payload = { notification_message, ...payload };
    }

    if (visibility === Visibility.Restricted) {
      const share_with = members?.map(({ value }) => value);

      payload = { share_with, ...payload };
    }

    return payload;
  };

  const onSubmit: SubmitHandler<CreateListFormValues> = async (data) => {
    setApiError(null);

    const payload: CreateListPayload = buildPayload(data);

    try {
      await create.mutateAsync(payload);

      setStep(3);
    } catch (e: any) {
      handleApiValidation(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        {step === 1 && (
          <>
            {!type && (
              <>
                <p className="text-sm mb-3">What kind of list are you uploading?</p>
                <RadioList
                  value={typeSelection}
                  onChange={handleTypeSelection}
                  options={availableListTypeOptions}
                >
                  <div className="space-y-4">
                    {map(availableListTypeOptions, (option) => (
                      <RadioList.Option
                        key={option.value}
                        option={option}
                        disabled={uploading || !!uploadResponse}
                      >
                        <RadioList.Icon
                          theme="primary"
                          checked={option.value === typeSelection}
                          className="mr-2"
                        />
                        <RadioList.Label className="text-md">{option.label}</RadioList.Label>
                      </RadioList.Option>
                    ))}
                  </div>
                </RadioList>
              </>
            )}

            <UploadListFile
              typeSelection={typeSelection}
              onUploading={setUploading}
              onSuccess={setUploadResponse}
            />
          </>
        )}

        {step === 2 && (
          <div className="space-y-4">
            <div className="text-sm text-gray-700">Name your list</div>
            <ListFormFields
              control={control}
              formState={formState}
              watch={watch}
              setValue={setValue}
            />
          </div>
        )}

        {step === 3 && (
          <div className="flex flex-col items-center gap-2 py-8">
            <div className="bg-success-50 text-success-500 rounded-full p-4">
              <CheckIcon size={40} />
            </div>
            <div className="text-sm text-gray-700 text-center">
              Your list is being processed.
              <br />
              It will appear automatically in your lists when done.
            </div>
          </div>
        )}

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-8">
        {(step === 1 || step === 2) && (
          <Button borderless onClick={onCancel} type="button">
            Cancel
          </Button>
        )}
        {step === 1 && (
          <Button
            type="button"
            onClick={handleNextClick}
            theme="primary"
            disabled={!uploadResponse}
          >
            Next
          </Button>
        )}
        {step === 2 && (
          <Button type="submit" theme="primary" disabled={isSubmitting || create.isSuccess}>
            Submit
          </Button>
        )}
        {step === 3 && (
          <>
            <Button borderless onClick={handleUploadAgainClick} type="button">
              Upload another list
            </Button>
            <Button theme="primary" onClick={onCancel} type="button">
              Close
            </Button>
          </>
        )}
      </div>
    </form>
  );
};

export default UploadListForm;
