import { useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { Button, TextField } from '@demandscience/ui';

import Alert from 'components/Alert';
import useAuth from './useAuth';

type ForgotPasswordConfirmFormValues = {
  code: string;
  password: string;
};

export interface ForgotPasswordConfirmProps {
  onForgotPasswordConfirm: () => void;
  username: string;
}

const ForgotPasswordConfirm = ({
  username,
  onForgotPasswordConfirm,
}: ForgotPasswordConfirmProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { forgotPasswordSubmit } = useAuth();
  const { control, handleSubmit, formState, setError } = useForm({
    defaultValues: { code: '', password: '' },
  });
  const { isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<ForgotPasswordConfirmFormValues> = async (data) => {
    setApiError(null);

    try {
      const { code, password } = data;
      await forgotPasswordSubmit(username, code, password);

      if (onForgotPasswordConfirm) onForgotPasswordConfirm();
    } catch (e: any) {
      const { message, name } = e;

      if (name === 'CodeMismatchException') {
        setError('code', { type: 'api', message });
      } else if (name === 'InvalidPasswordException') {
        setError('password', { type: 'api', message });
      } else {
        setApiError(message);
      }
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="space-y-4">
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <TextField
              label="Verification code"
              placeholder="Enter a code"
              variant="outlined"
              size="lg"
              required
              autoComplete="off"
              error={errors.code?.message}
              {...field}
            />
          )}
          rules={{ required: 'Required field' }}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              label="New password"
              placeholder="Enter a new password"
              variant="outlined"
              size="lg"
              required
              type="password"
              autoComplete="new-password"
              error={errors.password?.message}
              {...field}
            />
          )}
          rules={{ required: 'Required field' }}
        />
        {apiError && <Alert message={apiError} />}
      </div>

      <Button className="w-full mt-6" size="lg" type="submit" disabled={isSubmitting}>
        Confirm password
      </Button>
    </form>
  );
};

export default ForgotPasswordConfirm;
