import { useNavigate, Outlet } from 'react-router-dom';

import MembersTable from 'components/Members/Table';

import { Member } from 'types';
import { useCallback } from 'react';
import MembersCountTitle from 'components/Organization/MembersCountTitle';

const MembersDetails = () => {
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (row: Member) => {
      const { username } = row;

      navigate(`./${username}`);
    },
    [navigate],
  );

  return (
    <div className="flex flex-col gap-4">
      <MembersCountTitle />
      <MembersTable onRowClick={handleRowClick} />
      <Outlet />
    </div>
  );
};

export default MembersDetails;
