import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { Link } from '@demandscience/ui';
import AuthForgotPassword from 'components/Auth/ForgotPassword';
import Heading from 'layouts/Auth/Heading';
import { CodeDeliveryDetails } from 'types';
import { useCallback } from 'react';

const ForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleForgotPassword = useCallback(
    (username: string, codeDeliveryDetails: CodeDeliveryDetails) => {
      const state = location.state as Record<string, any>;
      const from = state?.from;

      navigate('../forgotPassword/confirm', {
        replace: true,
        state: { codeDeliveryDetails, username, from },
      });
    },
    [location.state, navigate],
  );

  return (
    <>
      <Heading
        title="Forgot your password?"
        description="No problem - provide your email address and we'll send you a code allowing you to reset your password"
      />
      <AuthForgotPassword onForgotPassword={handleForgotPassword} />
      <div className="mt-4 text-center">
        <Link id="action_back" as={RouterLink} to="../signin">
          Back to sign in
        </Link>
      </div>
    </>
  );
};

export default ForgotPassword;
