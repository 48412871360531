import { Modal, ModalProps } from '@demandscience/ui';

import { SavedSearch, Visibility } from 'types';
import Form from './EditForm';
import useSavedSearch from './useSavedSearch';

interface EditOverlayProps extends Omit<ModalProps, 'children'> {
  data: SavedSearch;
}

const EditOverlay = ({ data: defaultData, open, ...props }: EditOverlayProps) => {
  const { onClose } = props;
  // for restricted saved search need to load saved search by id in order to get shared_with property
  // given property is not present when saved search is fetched as part of the list of searches
  const { data = defaultData, isFetching } = useSavedSearch(defaultData.id, {
    enabled: defaultData.visibility === Visibility.Restricted,
  });

  return (
    <Modal
      aria-labelledby="edit-search-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      open={open && !isFetching} // wait to open overlay after data is loaded
      {...props}
    >
      <Modal.Title id="edit-search-dialog-title" className="flex mb-4">
        Edit search
      </Modal.Title>
      <Form data={data} onSuccess={onClose} onCancel={onClose} />
    </Modal>
  );
};

export default EditOverlay;
