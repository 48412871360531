import { SyntheticEvent, useState } from 'react';
import { Badge } from '@demandscience/ui';
import { isEmpty, map } from 'lodash';
import { Size } from '@demandscience/ui/src/types';

export interface BadgesProps {
  badgeDisplayCap?: number;
  badgeSize?: Size;
  className?: string;
  getBadgeProps: (value: any) => {
    exclude?: boolean;
    key: string;
    label: string;
  };
  onDelete: (value: any) => void;
  value?: any;
}

const Badges = ({
  className,
  value,
  onDelete,
  getBadgeProps,
  badgeSize,
  badgeDisplayCap,
}: BadgesProps) => {
  const [isShowAll, setIsShowAll] = useState(false);

  const handleDelete = (option: any) => (e: SyntheticEvent) => {
    e.stopPropagation();

    onDelete(option);
  };

  const handleToggleShowMore = (e: SyntheticEvent) => {
    e.stopPropagation();
    setIsShowAll(!isShowAll);
  };

  if (isEmpty(value)) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex flex-row flex-wrap items-center gap-[6px]">
        {map(isShowAll ? value : value.slice(0, badgeDisplayCap), (v) => {
          const { key, label, exclude, ...rest } = getBadgeProps(v);

          return (
            <Badge
              key={key}
              size={badgeSize || 'sm'}
              onDelete={handleDelete(v)}
              color={exclude ? 'rose' : 'emerald'}
              {...rest}
            >
              {label}
            </Badge>
          );
        })}
      </div>

      {badgeDisplayCap && badgeDisplayCap < value.length ? (
        <button className="text-xs text-blue-500" onClick={handleToggleShowMore}>
          {isShowAll ? 'Show less' : `Show more (${value.length - badgeDisplayCap})`}
        </button>
      ) : null}
    </div>
  );
};

export default Badges;
