import { useCallback, useState } from 'react';
import { filter, isEmpty, map, reduce, reject } from 'lodash';
import Icon from '../Filter/FilterIcon';
import Label from '../Filter/FilterLabel';
import Inline from '../Filter/Inline';
import Badges from '../Filter/Inline/Badges';
import OperatorSwitchMenuItem from '../Filter/Dropdown/OperatorSwitchMenuItem';
import CheckboxOption, { Option } from '../Filter/Dropdown/CheckboxMenuItem';
import useFilter from './useFilter';
import { DataFilterName, Operator, ContactDetailsProperty } from 'types/filters';
import { FilterProps } from './types';
import classNames from 'classnames';
import Button from 'components/Filter/Inline/Button';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';

const options = [
  { id: '1', label: 'Business email', value: 'contact_business_email' },
  { id: '2', label: 'Phone', value: 'contact_phone' },
  { id: '3', label: 'Mobile phone', value: 'contact_mobile_phone' },
  { id: '4', label: 'LinkedIn', value: 'contact_linkedin_handle' },
] as Array<Option<ContactDetailsProperty>>;

const getBadgeProps = (value: any) => ({ key: value.id, ...value });

const ContactDetailsFilter = ({ dense }: FilterProps) => {
  const { value: filterValue, onChange, onClear } = useFilter(DataFilterName.ContactDetails);
  const [operator, setOperator] = useState<Operator>(filterValue?.operator || 'AND');
  const value = filter(options, (value) => filterValue && filterValue[value.value]) as Array<
    Option<ContactDetailsProperty>
  >;

  const handleChange = useCallback(
    (newValue: Array<ContactDetailsProperty>) => {
      if (isEmpty(newValue)) {
        onClear();
      } else {
        const newFilterValue = reduce(newValue, (res, value) => ({ ...res, [value]: true }), {
          operator,
        });

        onChange(newFilterValue);
      }
    },
    [onChange, onClear, operator],
  );

  const handleComboboxInputDelete = useCallback(
    (deleted: Option<ContactDetailsProperty>) => {
      const newValue = reject(value, deleted);

      handleChange(map(newValue, 'value'));
    },
    [handleChange, value],
  );

  const handleOperatorChange = useCallback(
    (operator: Operator) => {
      setOperator(operator);

      if (!isEmpty(filterValue)) {
        onChange({
          ...filterValue,
          operator,
        });
      }
    },
    [filterValue, onChange],
  );

  return (
    <Inline
      value={map(value, 'value')}
      onChange={handleChange}
      multiple
      button={({ open }) => (
        <Button
          icon={<Icon name={DataFilterName.ContactDetails} />}
          label={<Label name={DataFilterName.ContactDetails} />}
          filled={!isEmpty(value)}
          dense={dense}
          expanded={open}
          onClear={onClear}
        />
      )}
      info={
        <Badges
          className="px-4 pb-2"
          value={value}
          onDelete={handleComboboxInputDelete}
          getBadgeProps={getBadgeProps}
        />
      }
    >
      <TextMenuItem className="-mt-2">Select required contact details</TextMenuItem>
      <OperatorSwitchMenuItem value={operator} onChange={handleOperatorChange} />
      {map(options, (option) => (
        <CheckboxOption
          key={option.value}
          option={option}
          className={classNames({ hidden: option.id === '2' })} // hide filter by phone
        />
      ))}
    </Inline>
  );
};

export default ContactDetailsFilter;
