import { Badge, IconButton, Pagination, Progress } from '@demandscience/ui';
import { BounceFilter, BounceStatus, BouncesListResponse } from 'types';
import Mail from '@demandscience/ui/icons/mail';
import UploadCloudIcon from '@demandscience/ui/icons/upload-cloud';
import { isEmpty, map } from 'lodash';

import Dropdown from './BounceDropdown';
import IconBox from 'components/Layout/IconBox';
import ListItem from 'components/Layout/ListItem';
import ListItemSkeleton from 'components/Lists/Skeletons/ListItemSkeleton';
import MessageOverlay from './MessageOverlay';
import Section from 'components/Layout/Section';
import { Theme } from '@demandscience/ui/dist/types';
import TimeAgo from 'react-timeago';
import classNames from 'classnames';
import defaultListParams from './DefaultListParams';
import { useBouncesLists } from './useBouncesLists';
import useDataGridSearch from 'components/DataGrid/useDataGridSearch';
import useModal from 'hooks/useModal';
import { useCallback, useState } from 'react';
import { stopPropagation } from 'utils/event';

const defaultParams = defaultListParams(25);

interface ListsShortListProps {
  title: string;
}

const Lists = ({ title }: ListsShortListProps) => {
  const {
    open: openMessage,
    openModal: openMessageModal,
    closeModal: closeMessageModal,
  } = useModal();
  const {
    params,
    handlePageChange: onPageChange,
    query,
  } = useDataGridSearch<BounceFilter, BouncesListResponse>(useBouncesLists, defaultParams);
  const { data, isLoading, isFetching, isError } = query;

  const { index, count } = params;

  const [modalMessage, setModalMessage] = useState('');

  const handleMailIconClick = useCallback(
    (reporterMessage: string) => () => {
      setModalMessage(reporterMessage);
      openMessageModal();
    },
    [openMessageModal],
  );

  const handlePageChange = useCallback(
    (_: any, page: number) => {
      onPageChange(page);
    },
    [onPageChange],
  );

  const handleBadgeTheme = (status: BounceStatus): Theme => {
    switch (status) {
      case 'open':
        return 'primary';
      case 'in_review':
        return 'amber' as Theme;
      case 'resolved':
        return 'emerald' as Theme;
      default:
        return 'primary';
    }
  };

  if (isLoading) {
    return (
      <Section title={title}>
        <div className="divide-y">
          <ListItemSkeleton count={5} />
        </div>
      </Section>
    );
  }

  if (isError) {
    return (
      <div className="text-sm text-error-500">Unable to load bounces, please try again later.</div>
    );
  }

  return (
    <>
      <Section title={title}>
        {isEmpty(data?.bounces) && (
          <div className="text-sm font-medium text-gray-500 pb-2">
            No previously reported bounces exists.
          </div>
        )}
        <div className="divide-y">
          {map(data?.bounces, (list) => (
            <ListItem
              key={list.id}
              role="button"
              leadingContent={
                <IconBox className="px-2 py-2 bg-indigo-100">
                  <UploadCloudIcon className="w-4 h-4 text-indigo-500" />
                </IconBox>
              }
              title={
                <div className="flex flex-row items-center gap-2">
                  <span>{list.filename}</span>
                </div>
              }
              subtitle={
                <>
                  Uploaded <TimeAgo date={list.created_at} /> by {list?.reporter?.name}
                </>
              }
              trailingContent={
                <div onClick={stopPropagation} className="flex flex-row gap-1">
                  {list?.status && (
                    <Badge
                      label={list?.status}
                      theme={handleBadgeTheme(list?.status)}
                      size="sm"
                      className="self-center h-[20px]"
                    />
                  )}
                  {list?.message_to_customer && (
                    <IconButton
                      type="button"
                      theme="default"
                      className=" hover:bg-gray-100"
                      onClick={handleMailIconClick(list?.message_to_customer)}
                      Icon={Mail}
                    />
                  )}
                  <Dropdown data={list} />
                </div>
              }
            />
          ))}
          {data?.returned !== data?.count && (
            <div className="flex justify-end pt-4 relative">
              <Progress
                theme="primary"
                className={classNames('absolute top-0 bg-white h-[1px] invisible', {
                  visible: isFetching,
                })}
              />
              <Pagination.Combined
                size="sm"
                count={data?.count}
                page={index + 1}
                rowsPerPage={count}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </Section>
      <MessageOverlay open={openMessage} message={modalMessage} onClose={closeMessageModal} />
    </>
  );
};

export default Lists;
