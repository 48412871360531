import Phone from './Phone';
import { Company } from 'types';
import CompanyName from './CompanyName';
import CompanyLocation from './CompanyLocation';
import ListItemText from 'components/Layout/ListItemText';

interface BasicInfoProps {
  row: Company;
}

const BasicInfo = ({ row }: BasicInfoProps) => (
  <>
    <ListItemText
      title={<CompanyName row={row} />}
      subtitle={<CompanyLocation className="text-xs text-inherit" row={row} inline />}
    />
    <Phone row={row} />
  </>
);

export default BasicInfo;
