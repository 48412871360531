import { useState } from 'react';
import { Button, TextField, Skeleton } from '@demandscience/ui';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import useMutateBilling from './useMutateBilling';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { AxiosError } from 'axios';
import useBillingInfo from './useBillingInfo';
import { BillingInfoPayload } from 'types';

const BillingEmail = () => {
  const { data: billingData, isLoading: isBillingInfoLoading } = useBillingInfo();
  const [update, setUpdate] = useState(false);

  const { control, handleSubmit, reset, formState, watch, setValue } = useForm({
    defaultValues: {
      billing_info: {
        billing_address: {
          email: '',
        },
      },
    },
  });

  const { updateBillingEmail } = useMutateBilling();
  const { showMessage } = useSnackbar();
  const { isSubmitting, errors } = formState;

  const emailValue = watch('billing_info.billing_address.email');
  const customerEmail = billingData?.billing_info?.billing_address?.email;
  const isInitialEmailEmpty = customerEmail === undefined;
  const isEmailEmpty = emailValue === '';

  const onSubmit: SubmitHandler<BillingInfoPayload> = async (data) => {
    try {
      const payload = {
        billing_info: {
          billing_address: {
            email: data.billing_info?.billing_address.email,
            city: billingData?.billing_info?.billing_address?.city,
            company: billingData?.billing_info?.billing_address?.company,
            country: billingData?.billing_info?.billing_address?.country,
            first_name: billingData?.billing_info?.billing_address?.first_name,
            last_name: billingData?.billing_info?.billing_address?.last_name,
            line1: billingData?.billing_info?.billing_address?.line1,
            line2: billingData?.billing_info?.billing_address?.line2,
            line3: billingData?.billing_info?.billing_address?.line3,
            phone: billingData?.billing_info?.billing_address?.phone,
            state: billingData?.billing_info?.billing_address?.state,
            zip: billingData?.billing_info?.billing_address?.zip,
          },
          vat_number: billingData?.billing_info?.vat_number,
        },
      };
      await updateBillingEmail.mutateAsync(payload);

      setUpdate(false);

      showMessage('Email was successfully changed', 'success');
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e instanceof AxiosError) {
          showMessage(e.response?.data?.error || 'Unable to update the email', 'error');
        } else {
          showMessage('Unexpected error, please try again later', 'error');
        }
      }
    }
  };

  const handleUpdateClick = () => {
    setValue(
      'billing_info.billing_address.email',
      billingData?.billing_info?.billing_address?.email!,
    );
    setUpdate((state) => !state);
  };

  const handleClose = () => {
    setUpdate((state) => !state);
  };

  const emailHoldingText = isInitialEmailEmpty ? 'No email on file' : customerEmail;
  const updateBtnText = isInitialEmailEmpty ? 'Add' : 'Change';

  const skeleton = (
    <Skeleton className="inline-block align-top mt-3" variant="circle" height={5} width={80} />
  );

  return (
    <div className="flex flex-col gap-4 max-w-xl">
      <p className="text-lg text-gray-700 font-medium">Billing Email</p>
      {isBillingInfoLoading && skeleton}
      {!isBillingInfoLoading && (
        <div className="flex flex-row justify-between items-center">
          <p className="text-sm text-gray-700 whitespace-pre-line">
            {update ? 'Change email address' : emailHoldingText}
          </p>

          <Button
            className="whitespace-nowrap"
            theme="primary"
            size="md"
            borderless
            type="button"
            onClick={handleUpdateClick}
            disabled={isSubmitting}
          >
            {update ? 'Cancel' : updateBtnText}
          </Button>
        </div>
      )}
      {update && (
        <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="billing_info.billing_address.email"
            control={control}
            render={({ field }) => (
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                placeholder="Enter the billing email"
                error={errors.billing_info?.billing_address?.email?.message}
                {...field}
              />
            )}
          />

          <div className="flex gap-4 space-x-3 justify-between">
            <div>
              <Button
                id="action_filters_clear_all"
                borderless
                className="text-xs font-medium text-blue-500 hover:underline whitespace-nowrap"
                onClick={() => reset()}
                disabled={isEmailEmpty}
              >
                Clear
              </Button>
            </div>
            <div className="space-x-3">
              <Button theme="primary" type="button" outline onClick={handleClose}>
                Cancel
              </Button>
              <Button theme="primary" type="submit" disabled={isSubmitting || !formState.isDirty}>
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default BillingEmail;
