import Section, { SectionProps } from 'components/Layout/Section';
import { Link } from '@demandscience/ui';
import { useCallback } from 'react';
import OnboardingList from './OnboardingList';
import useOnboardingCookie from './useOnboardingCookie';

const OnboardingListSection = (props: SectionProps) => {
  const { cookie, updateCookie } = useOnboardingCookie();

  const handleHide = useCallback(() => {
    updateCookie('hideHomeSection', true);
  }, [updateCookie]);

  if (cookie?.hideHomeSection) {
    return null;
  }

  return (
    <Section
      {...props}
      action={
        <Link as="button" onClick={handleHide}>
          Hide
        </Link>
      }
    >
      <OnboardingList />
    </Section>
  );
};

export default OnboardingListSection;
