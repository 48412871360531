import { Outlet } from 'react-router-dom';
import ConsentManagerControl from 'components/ConsentManager/ConsentManagerControl';
import { Link } from '@demandscience/ui';

import Header from './Header';

interface AuthLayoutProps {
  hideHeaderNavLinks?: boolean;
}

const AuthLayout = ({ hideHeaderNavLinks = false }: AuthLayoutProps) => (
  <div className="w-full h-screen relative">
    <main className="flex flex-col h-full px-8 xl:px-32 2xl:mx-auto 2xl:px-0 2xl:max-w-7xl">
      <Header hideNavLinks={hideHeaderNavLinks} />
      <div className="grow pb-16 lg:grid lg:grid-cols-12 lg:gap-6 lg:content-center">
        <div className="lg:col-start-4 lg:col-span-6">
          <Outlet />
        </div>
      </div>
      <div className="px-6 py-4 flex flex-row justify-center gap-4">
        <ConsentManagerControl id="action_cookie_settings" as={Link}>
          Cookie Settings
        </ConsentManagerControl>
        <Link
          href="https://demandscience.com/privacy-policy-ccpa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Do Not Sell my Info
        </Link>
        <Link
          href="https://demandscience.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </Link>
        <Link
          href="https://demandscience.com/terms-of-use/klarity"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms
        </Link>
      </div>
    </main>
  </div>
);

export default AuthLayout;
