import {
  ChangeEventHandler,
  forwardRef,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
} from 'react';
import Icon from 'components/Filter/FilterIcon';
import Label from 'components/Filter/FilterLabel';
import { ComboboxInputProps } from 'components/Filter/Dropdown/ComboboxInput';
import ListItem from 'components/Filter/ListItem';
import { isEmpty, last } from 'lodash';
import { FilterName } from 'types';
import { Combobox } from '@headlessui/react';
import Badges from 'components/Filter/Inline/Badges';
import TextField from 'components/Input/TextField';
import classNames from 'classnames';

interface FilterInputProps extends ComboboxInputProps {
  activeIndex: number | null;
  badgeDisplayCap?: number;
  dense?: boolean;
  hideInput?: boolean;
  name: FilterName | 'Personas';
  onClear: () => void;
  onEnterKey?: () => void;
  onInputClear?: () => void;
  variant?: 'plain';
}

const FilterInput = forwardRef<HTMLInputElement, FilterInputProps>(
  (
    {
      open,
      value,
      dense,
      onClear,
      onDelete,
      name,
      placeholder,
      variant,
      onInputChange,
      onInputClear,
      getBadgeProps,
      hideInput,
      activeIndex,
      onEnterKey,
      badgeDisplayCap,
    },
    ref,
  ) => {
    const handleClick: MouseEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        if (open) {
          e.stopPropagation();
        }
      },
      [open],
    );

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        onInputChange && onInputChange(e);
      },
      [onInputChange],
    );

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        const { key, target, repeat } = e;

        // delete last value element when Backspace key pressed
        if (!(target as HTMLInputElement).value && key === 'Backspace' && !repeat) {
          onDelete(last(value));
        }

        // prevent close when hitting Enter key and no value selected
        if (key === 'Enter') {
          if (activeIndex === null || (activeIndex === 0 && onEnterKey)) {
            e.preventDefault();

            if (onEnterKey) {
              onEnterKey();
            }
          }
        }
      },
      [onDelete, onEnterKey, value, activeIndex],
    );

    useEffect(() => {
      if (!open && onInputClear) {
        onInputClear();
      }
    }, [open, onInputClear]);

    if (variant === 'plain') {
      return (
        <div className="space-y-2" onClick={handleClick}>
          <div className="max-h-20 overflow-auto">
            <Badges
              badgeDisplayCap={badgeDisplayCap}
              value={value}
              onDelete={onDelete}
              getBadgeProps={getBadgeProps}
            />
          </div>
          <Combobox.Input
            ref={ref}
            as={TextField}
            onChange={handleInputChange}
            onClear={onInputClear}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
          />
        </div>
      );
    }

    return (
      <ListItem
        className="cursor-pointer select-none"
        icon={<Icon name={name} />}
        label={<Label name={name} />}
        filled={!isEmpty(value)}
        expanded={open}
        dense={dense}
        onClear={onClear}
      >
        <Badges
          className={classNames({
            'px-4 pb-4': !(dense ?? open),
            'px-4 pb-2': dense ?? open,
          })}
          badgeDisplayCap={badgeDisplayCap}
          value={value}
          onDelete={onDelete}
          getBadgeProps={getBadgeProps}
        />
        {open && (
          <div
            className={classNames({ 'px-4 pb-4': !hideInput, 'h-0 overflow-hidden': hideInput })}
            onClick={handleClick}
          >
            <Combobox.Input
              ref={ref}
              as={TextField}
              onChange={handleInputChange}
              onClear={onInputClear}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
            />
          </div>
        )}
      </ListItem>
    );
  },
);

FilterInput.displayName = 'FilterInput';

export default FilterInput;
