import { useCallback, useContext } from 'react';
import { Button, ButtonOwnProps } from '@demandscience/ui';

import RevealSingleContactContext, {
  RevealSingleContactContextInterface,
} from './RevealSingleContactContext';

interface RevealSingleContactControlProps extends ButtonOwnProps {
  as?: React.ElementType;
  children: React.ReactNode;
  id: string;
}

const RevealSingleContactControl = ({
  id,
  as: Component = Button,
  ...props
}: RevealSingleContactControlProps) => {
  const { revealSingleContact, isLoading } = useContext(
    RevealSingleContactContext,
  ) as RevealSingleContactContextInterface;

  const handleClick = useCallback(() => {
    revealSingleContact(id);
  }, [id, revealSingleContact]);

  return <Component onClick={handleClick} {...props} disabled={isLoading} />;
};

export default RevealSingleContactControl;
