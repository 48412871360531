import { useQuery } from '@tanstack/react-query';

import { fetchCurrent } from 'api/organization';

const useOrganization = (options?: Record<string, any>) => {
  const query = useQuery(['organization'], fetchCurrent, {
    staleTime: 60 * 1000, // 1 minute
    ...options,
  });

  return query;
};

export default useOrganization;
