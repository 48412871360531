import { Company } from 'types';
import ListItemText from 'components/Layout/ListItemText';
import CreditCardIcon from '@demandscience/ui/icons/credit-card';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';

interface IndustryProps {
  className?: string;
  inline?: boolean;
  row?: Company;
}
const Industry = ({ className, row, inline }: IndustryProps) => {
  if (!row?.industry) {
    return null;
  }

  if (inline) {
    return <Paragraph className={className}>{row.industry.category}</Paragraph>;
  }

  return (
    <ListItemText
      leadingContent={<CreditCardIcon className="w-5 h-5" />}
      overline="Industry"
      title={row.industry.category}
    />
  );
};

export default Industry;
