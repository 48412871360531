import { ModelType } from 'types';
import useFilters from 'components/Filters/useFilters';
import useSearchCounts from 'components/Search/useSearchCounts';
import SelectionProvider from './SelectionProvider';

interface SearchRecordsSelectionProviderProps {
  children: React.ReactNode;
  kind: ModelType;
}

const SearchRecordsSelectionProvider = ({
  kind,
  children,
}: SearchRecordsSelectionProviderProps) => {
  const { contactCount, companyCount } = useSearchCounts();
  const { filters } = useFilters();

  const recordsCount = kind === ModelType.Contact ? contactCount : companyCount;

  return (
    <SelectionProvider filters={filters} recordsCount={recordsCount} kind={kind}>
      {children}
    </SelectionProvider>
  );
};

export default SearchRecordsSelectionProvider;
