import { Link } from 'react-router-dom';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import { Button } from '@demandscience/ui';

const ListDetailsProcessing = () => (
  <EmptyResults
    message="Your list is still being processed"
    illustration={<Illustration.EmptyAlt className="w-40 h-40 mb-6" />}
  >
    <div className="text-sm text-gray-500 mb-4">Your records will appear as soon as it is done</div>
    <Button as={Link} to="./.." borderless>
      Back to All lists
    </Button>
  </EmptyResults>
);

export default ListDetailsProcessing;
