import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

const Paragraph = forwardRef<HTMLParagraphElement, HTMLAttributes<HTMLParagraphElement>>(
  ({ children, className, ...props }, ref) => (
    <p
      ref={ref}
      className={overrideTailwindClasses(classNames('text-sm text-gray-700', className))}
      {...props}
    >
      {children}
    </p>
  ),
);

Paragraph.displayName = 'Paragraph';

export default Paragraph;
