import classNames from 'classnames';
import { Button, Checkbox, CheckboxProps, Modal, Progress, Tooltip } from '@demandscience/ui';
import SimplePagination, { SimplePaginationProps } from 'components/DataGrid/SimplePagination';
import SelectionMenu from './SelectBookmark';
import { useBookmarkSelection } from './useBookmarkSelection';
import { Download, Trash2 } from '@demandscience/ui/icons';
import useModal from 'hooks/useModal';
import { BookmarkDownload } from '../BookmarkDownload';
import { useCallback, useState } from 'react';
import useSnackbar from 'components/Snackbar/useSnackbar';
import useMutateBookMark from '../useMutateBookMark';
import { AxiosError } from 'axios';
import SortByMenu from './SortBookmark';
import { SortBy } from 'types';

interface SelectionBarProps {
  allBookmarkIds: string[];
  checkboxProps: CheckboxProps;
  loading: boolean;
  paginationProps: SimplePaginationProps;
  setSort: (newSort: SortBy) => void;
  sort: SortBy;
}

const SelectionBar = ({
  loading,
  paginationProps,
  checkboxProps,
  allBookmarkIds,
  setSort,
  sort,
}: SelectionBarProps) => {
  const { selectedBookmarks, selectAllBookmarks, clearSelection, getName } = useBookmarkSelection();
  const handleMainCheckboxChange = () => {
    if (selectedBookmarks.size === allBookmarkIds?.length) {
      clearSelection();
    } else {
      selectAllBookmarks(allBookmarkIds);
    }
  };

  const { open, openModal, closeModal } = useModal();

  const { showMessage } = useSnackbar();
  const { deleteListBookmark } = useMutateBookMark();
  const [openDelete, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = useCallback(async () => {
    try {
      await deleteListBookmark.mutateAsync({ bookmark_ids: Array.from(selectedBookmarks) });
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to delete Bookmark', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
    clearSelection();
    onClose();
  }, [clearSelection, deleteListBookmark, selectedBookmarks, showMessage]);

  return (
    <div className="bg-white relative">
      <div className="h-[30px] border-b flex-shrink-0 flex flex-row items-center justify-between px-0">
        <div className="flex flex-row items-center gap-2">
          <div className="flex items-center mb-3">
            <div onClick={handleMainCheckboxChange}>
              <Tooltip title="Select" position="bottom" showArrow>
                <Checkbox
                  size="sm"
                  checked={selectedBookmarks.size > 0}
                  checkType={selectedBookmarks.size === allBookmarkIds?.length ? 'check' : 'minus'}
                  {...checkboxProps}
                />
              </Tooltip>
            </div>

            <SelectionMenu onSelectPage={handleMainCheckboxChange} onClearPage={clearSelection} />
            {selectedBookmarks.size > 0 && (
              <>
                <div className="text-sm text-gray-700 font-medium">
                  {selectedBookmarks.size} selected
                </div>
                <Button borderless size="xs" onClick={clearSelection}>
                  Clear
                </Button>
                <Tooltip title="Download" position="bottom" showArrow>
                  <Button borderless size="xs" type="button" onClick={openModal}>
                    <Download></Download>
                    Download
                  </Button>
                </Tooltip>

                <Tooltip title="Delete Bookmark" position="bottom" showArrow>
                  <Button
                    borderless
                    size="xs"
                    type="button"
                    onClick={handleOpen}
                    disabled={deleteListBookmark.isLoading}
                  >
                    <Trash2></Trash2>
                    Delete
                  </Button>
                </Tooltip>
                <Modal
                  aria-labelledby="delete-list-dialog-title"
                  className="max-w-md sm:max-w-md overflow-visible"
                  rootClassName="z-30"
                  open={openDelete}
                  onClose={onClose}
                >
                  <Modal.Title id="delete-list-dialog-title" className="mb-8 break-words">
                    Are you sure you want to delete this Bookmark ?
                  </Modal.Title>
                  {/* <div className="space-y-4 mt-10">
                  {apiError && <div className="text-error-500 text-center mt-8">{apiError}</div>}
                </div> */}

                  <div className="flex justify-end space-x-4 mt-4">
                    <Button type="button" borderless onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      onClick={handleDelete}
                      disabled={deleteListBookmark.isLoading}
                    >
                      Delete
                    </Button>
                  </div>
                </Modal>
              </>
            )}
          </div>
        </div>
        <div className="space-x-4">
          <SortByMenu value={sort} onChange={setSort} />
          <SimplePagination {...paginationProps} />
        </div>
      </div>
      <Progress
        theme="primary"
        className={classNames('absolute bottom-0 bg-white h-[1px] invisible', {
          visible: loading,
        })}
      />
      <BookmarkDownload
        open={open}
        onClose={closeModal}
        selectedBookmarks={Array.from(selectedBookmarks)}
        getName={getName}
      />
    </div>
  );
};

export default SelectionBar;
