import { ListRecord } from 'types';
import useModal from 'hooks/useModal';
import { IconButton } from '@demandscience/ui';
import TrashIcon from '@demandscience/ui/icons/trash';
import RemoveExclusionListOverlay from './RemoveExclusionListOverlay';

const DeleteAction = (props: ListRecord) => {
  const {
    open: deleteConfirmationModalOpen,
    openModal: openDeleteConfirmationModal,
    closeModal: closeDeleteConfirmationModal,
  } = useModal();

  return (
    <>
      <IconButton
        className="hover:bg-gray-100"
        size="sm"
        theme="default"
        Icon={TrashIcon}
        onClick={openDeleteConfirmationModal}
      />
      <RemoveExclusionListOverlay
        open={deleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        {...props}
      />
    </>
  );
};

export default DeleteAction;
