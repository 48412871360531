import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Button, Checkbox, TextField } from '@demandscience/ui';
import { useLists } from 'components/Lists/useLists';
import defaultAutomatedListParams from 'components/Lists/DefaultAutomatedListParams';

import { AdvancedSelection, DEFAULT_MAX_RECORDS, ExclusionListFilter, ModelType } from 'types';
import { getRecordsLabel } from 'utils/string';
import { stopPropagation } from 'utils/event';
import { useMemo } from 'react';
import { first } from 'lodash';

interface AdvancedSelectionFormProps {
  advancedSelection?: AdvancedSelection;
  excludeList?: ExclusionListFilter[number];
  kind: ModelType;
  maxContactsPerCompany?: number;
  onCancel: () => void;
  onChangeAdvancedSelection: (advancedSelection?: AdvancedSelection) => void;
  onChangeExcludeList?: (excludeList?: ExclusionListFilter[number]) => void;
  onChangeMaxContactsPerCopany?: (
    maxContactsPerCompany?: number,
    advancedSelection?: AdvancedSelection,
  ) => void;
}

type FormValues = {
  max_contacts_per_company: string;
  max_records: string;
  only_net_new: boolean;
};

const AdvancedSelectionForm = ({
  kind,
  advancedSelection,
  onChangeAdvancedSelection,
  maxContactsPerCompany,
  onChangeMaxContactsPerCopany,
  excludeList,
  onChangeExcludeList,
  onCancel,
}: AdvancedSelectionFormProps) => {
  const { control, handleSubmit, formState, reset } = useForm<FormValues>({
    defaultValues: {
      max_records:
        !advancedSelection?.limit?.max_records ||
        advancedSelection?.limit?.max_records === DEFAULT_MAX_RECORDS?.max_records
          ? ''
          : advancedSelection?.limit?.max_records?.toString(),
      max_contacts_per_company: maxContactsPerCompany?.toString() ?? '',
      only_net_new: !!excludeList,
    },
  });

  const { errors, isDirty } = formState;

  const { data: lists, isLoading } = useLists(defaultAutomatedListParams(kind));
  const currentExcludeList = useMemo(() => {
    const list = first(lists?.lists);

    if (list) {
      return {
        list_id: list.id,
        name: list.name,
        records_type: list.records_type,
      };
    }

    return;
  }, [lists]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const { max_records, max_contacts_per_company, only_net_new } = data;

    let advancedSelection = undefined;
    let maxContactsPerCompany = undefined;

    if (max_records) {
      advancedSelection = { limit: { max_records: parseInt(max_records) } };
    }
    if (max_contacts_per_company) {
      maxContactsPerCompany = parseInt(max_contacts_per_company);
      advancedSelection ||= { limit: DEFAULT_MAX_RECORDS };
    }

    onChangeAdvancedSelection(advancedSelection);
    onChangeMaxContactsPerCopany?.(maxContactsPerCompany, advancedSelection);
    onChangeExcludeList?.(only_net_new ? currentExcludeList : undefined);

    reset(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-4 pt-2 space-y-4">
        <Controller
          name="max_records"
          control={control}
          render={({ field }) => (
            <TextField
              label={`Number of ${getRecordsLabel(kind)} to select`}
              placeholder={`Max ${Number(100000).toLocaleString()}`}
              variant="outlined"
              size="md"
              error={errors.max_records?.message}
              autoComplete="off"
              autoFocus
              {...field}
            />
          )}
          rules={{
            pattern: {
              value: /^\d*$/,
              message: 'Please enter a number',
            },
            validate: (value) => {
              if (value) {
                const intValue = parseInt(value);
                if (intValue <= 0) {
                  return 'Value must be positive';
                } else if (intValue > 100000) {
                  return `${Number(100000).toLocaleString()} max`;
                }
              }

              return true;
            },
          }}
        />

        {kind === ModelType.Contact && onChangeMaxContactsPerCopany && (
          <Controller
            name="max_contacts_per_company"
            control={control}
            render={({ field }) => (
              <TextField
                label="Maximum per company"
                placeholder="Leave blank for unlimited"
                variant="outlined"
                size="md"
                error={errors.max_contacts_per_company?.message}
                autoComplete="off"
                {...field}
              />
            )}
            rules={{
              pattern: {
                value: /^\d*$/,
                message: 'Please enter a number',
              },
              validate: (value) => {
                if (value) {
                  const intValue = parseInt(value);
                  if (intValue <= 0) {
                    return 'Value must be positive';
                  }
                }

                return true;
              },
            }}
          />
        )}
        <div className="-ml-2">
          <Controller
            name="only_net_new"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={`Select only net new ${getRecordsLabel(kind)}`}
                onChange={field.onChange}
                checked={field.value}
                onClick={stopPropagation}
                disabled={isLoading || !currentExcludeList}
              />
            )}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <Button size="sm" type="button" borderless onClick={onCancel}>
            Cancel
          </Button>
          <Button size="sm" theme="primary" disabled={!isDirty}>
            Apply
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AdvancedSelectionForm;
