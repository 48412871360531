import RadioButtonOption from '../Filter/Dropdown/RadioButtonMenuItem';
import PersonaInfo from './PersonaInfo';
import PersonaDropdownActions from './PersonaDropdownActions';
import { stopPropagation } from 'utils/event';
import { map } from 'lodash';
import useAuth from 'components/Auth/useAuth';
import useModelPermissions from 'hooks/useModelPermissions';

import { SavedSearch, Visibility } from 'types';
import { AccountBase } from 'types/globals';

interface Props {
  openDeleteModal: () => void;
  openEditModal: () => void;
  options: {
    label: string;
    owner: AccountBase;
    value: SavedSearch;
    visibility: Visibility;
  }[];
  setPersonaSelected: (data: SavedSearch) => void;
}

const SavedPersonasList = ({
  options,
  openEditModal,
  openDeleteModal,
  setPersonaSelected,
}: Props) => {
  const { user } = useAuth();
  const { canEditModel } = useModelPermissions();
  return (
    <>
      {map(options, (option) => {
        const isOwnSearch = user?.username === option.owner.username;
        const isEditable = canEditModel(user, option.owner, option.visibility);
        const personaInfo = option.value;

        return (
          <RadioButtonOption
            key={option.value.id}
            option={option}
            useValue
            trailingContent={
              <div className="flex flex-row gap-2" onClick={stopPropagation}>
                <div className="invisible group-hover:visible">
                  <PersonaInfo data={option.value} showAccountInfo={!isOwnSearch} />
                </div>
                {isEditable && (
                  <PersonaDropdownActions
                    openEditModal={openEditModal}
                    openDeleteModal={openDeleteModal}
                    setPersonaSelected={setPersonaSelected}
                    data={personaInfo}
                  />
                )}
              </div>
            }
          />
        );
      })}
    </>
  );
};

export default SavedPersonasList;
