import { useCallback, useState } from 'react';
import { useInterval, useEvent } from 'react-use';
import { getPrivacyManagerAPI } from './privacyManagerAPI';

const useConsent = (domain: string) => {
  const action = 'getConsentDecision';
  const [consent, setConsent] = useState<Array<number> | null>(null);

  const handleCheck = useCallback(() => {
    const privacyManagerAPI = getPrivacyManagerAPI();

    if (privacyManagerAPI) {
      const apiObject = {
        PrivacyManagerAPI: {
          action,
          timestamp: new Date().getTime(),
          self: window.location.host,
        },
      };

      window?.top?.postMessage(JSON.stringify(apiObject), '*');

      setConsent([1]);
    }
  }, []);

  const handleMessage = useCallback(
    (e: MessageEvent) => {
      const privacyManagerAPI = getPrivacyManagerAPI();

      try {
        if (e.data) {
          const {
            PrivacyManagerAPI: { capabilities, action: messageAction },
          } = JSON.parse(e.data);

          if (capabilities && messageAction === action && privacyManagerAPI) {
            const { consentDecision } = privacyManagerAPI.callApi('getGDPRConsentDecision', domain);

            setConsent(consentDecision);
          }
        }
      } catch (err) {
        // ignore
      }
    },
    [domain],
  );

  // check for privacy manager API presence every 100ms
  useInterval(handleCheck, consent === null ? 100 : null);

  // handle privacy manager API message
  useEvent('message', handleMessage);

  return consent;
};

export default useConsent;
