import {
  Visibility,
  NotificationMessagePayload,
  AutoUpdate,
  SortBy,
  VisibilityBookmarks,
} from 'types';

export type BookMarkFormValues = {
  automated_updates: AutoUpdate | null;
  comment: string;
  id?: string;
  members?: Array<{ label: string; value: string }>;
  name: string;
  notify?: boolean;
  share_with?: string[];
  visibility: Visibility;
} & NotificationMessagePayload;

export type EditBookMarkFormValues = {
  id?: string;
} & BookMarkFormValues;

export type BookMarkPayload = {
  Comment?: string;
  automated_updates?: AutoUpdate | '';
  module_type_id?: string;
  module_type_name?: string;
  name?: string;
  record_type: string;
  search_filters?: Record<string, any>;
  search_result?: Record<string, any>;
  share_with?: Array<string>;
  view_id: string;
  visibility: Visibility;
} & NotificationMessagePayload;

export type BookmarkListParams = {
  count: number;
  filter: {
    module_type_id?: string[];
    show: VisibilityBookmarks;
  };
  index: number;

  sort: SortBy;
};

export interface AggregationResult {
  count: number;
  data: {
    level: string;
  };
  value: string;
}

interface SearchResult {
  created_at: string;
  result: {
    aggregation_results: {
      [key: string]: AggregationResult[];
    };
  };
}
export interface SearchResult2 {
  id: string;
  search_result: {
    aggregation_results: {
      [key: string]: AggregationResult[];
    };
  };
}

export interface ApiResponse {
  automated_updates: string;
  created_at: string;
  filters: {
    aggregations: {
      field_name: string;
    }[];
    filters: {
      [key: string]: any;
    };
  };
  id: string;
  module_type_id: string;
  module_type_name: string;
  name: string;
  search_results: SearchResult[];
  share_with?: string[];
  updated_at: string[];
  view_id: string;
  visibility: string;
}

export type FormData = {
  automated_updates?: 'bi-weekly' | 'monthly' | 'quarterly' | null;
  comment: string;
  members?: Array<{ label: string; value: string }> | null;
  name?: string | null;
  notification_message?: string;
  notify?: boolean;
  share_with?: string[] | null;
  visibility?: Visibility;
} & NotificationMessagePayload;

export interface Comment {
  comment: string;
  created_at: string;
  read: boolean;
  user: string;
}
export enum CompaniesSort {
  CompanyHeadcount = 'company_headcount',
  CompanyIndustry = 'company_industry',
  CompanyLocation = 'company_location',
  ContactDepartment = 'contact_department',
  ContactJobLevel = 'contact_job_level',
  CreatedAt = 'created_at',
  Name = 'name',
}

export const SortOptions = [
  {
    value: CompaniesSort.ContactJobLevel,
    label: 'Job level',
  },
  {
    value: CompaniesSort.ContactDepartment,
    label: 'Department',
  },
  {
    value: CompaniesSort.CompanyLocation,
    label: 'Company location',
  },
  {
    value: CompaniesSort.CompanyHeadcount,
    label: 'Headcount',
  },
  {
    value: CompaniesSort.CompanyIndustry,
    label: 'Industry',
  },
];
export const moduleMap: Record<string, string> = {
  Overview: 'search_counters',
  Department: 'contact_department',
  Headcount: 'company_headcount',
  'Job level': 'contact_job_level',
  'Company location': 'company_location',
  Industry: 'company_industry',
  Companies: 'companies_list',
  Revenue: 'company_revenue',
  Function: 'contact_job_area',
  Contacts: 'contacts_list',
};
