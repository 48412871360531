import { useTracking } from 'react-tracking';
import useConsentManager from 'components/ConsentManager/useConsentManager';
import tracking from './tracking';
import useChameleon from './Chameleon/useChameleon';
import useGTM from './useGTM';

interface TrackingProps {
  children: React.ReactNode;
}

const Tracking = ({ children }: TrackingProps) => {
  const { functional } = useConsentManager();
  const chameleon = useChameleon(
    'StQDvu4JUrtkBwtaY10vjebhcLA3LL1pDJUlQxayyQVfUm-1NCxZd-DziWoaeYfRXKd8Hw',
    {
      enabled: functional,
    },
  );
  const gtm = useGTM({ enabled: functional });

  // trackers are provided via trackingData parameter, couldn't find any other way to have trackers available in dispatch
  const { Track } = useTracking({ trackers: { chameleon, gtm } }, tracking);

  return <Track>{children}</Track>;
};

export default Tracking;
