import { Modal, ModalProps } from '@demandscience/ui';
import { Notification } from 'types';

import Form from './RejectCreditsRequestForm';

interface RejectCreditsRequestOverlayProps extends Omit<ModalProps, 'children'> {
  notification: Notification;
}

const RejectCreditsRequestOverlay = ({
  notification,
  ...props
}: RejectCreditsRequestOverlayProps) => {
  const { onClose } = props;

  return (
    <Modal
      aria-labelledby="reject-credits-request-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="reject-credits-request-dialog-title" className="flex mb-7">
        Decline credits request
      </Modal.Title>
      <Form id={notification.id} onSuccess={onClose} onCancel={onClose} />
    </Modal>
  );
};

export default RejectCreditsRequestOverlay;
