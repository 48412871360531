import { ReactNode } from 'react';
import { Button } from '@demandscience/ui';
import ArrowLeft from '@demandscience/ui/icons/arrow-left';

interface QuestionProps {
  button: ReactNode;
  children: React.ReactNode;
  onBack?: () => void;
  question: string;
  questionNo: number;
  questionsTotal: number;
}

const Question = ({
  button,
  questionNo,
  questionsTotal,
  question,
  onBack,
  children,
}: QuestionProps) => (
  <div className="flex flex-col h-full gap-3">
    <div className="text-gray-700 space-y-1">
      {questionsTotal > 1 && (
        <div className="text-sm">
          Question {questionNo} of {questionsTotal}
        </div>
      )}
      <div className="text-xl font-semibold">{question}</div>
    </div>
    <div className="grow">{children}</div>
    <div className="flex flex-row-reverse justify-between">
      {button}
      {onBack && (
        <Button onClick={onBack} borderless leftIcon={<ArrowLeft size={20} />}>
          Back
        </Button>
      )}
    </div>
  </div>
);

export default Question;
