import {
  Member,
  Role,
  MemberState,
  BulkCredits,
  SearchParams,
  PaginationResponse,
  allocation,
} from 'types';
import { SelectOption } from '@demandscience/ui/dist/types';
import { map } from 'lodash';

export type InvitePayload = {
  emails: string[];
  role: Role;
};

export type OrganizationsFilter = {
  freetext?: string;
  roles?: Role[];
  state?: MemberState[];
};

export type SearchMembersParams = SearchParams<OrganizationsFilter>;

export type FetchMembersParams = {
  filter: OrganizationsFilter;
};

export type EditMemberFormValues = {
  allocation: allocation;
  bulk_credits: BulkCredits;
  email: string;
  name: string;
  role: Role;
};

export type UpdateMemberPayload = {
  bulk_credits: Pick<BulkCredits, 'quota'>;
  email: string;
  id: string;
  name: string;
  roles: Role[];
};

export type MembersResponse = {
  members: Member[];
} & PaginationResponse;

export enum CreditTypes {
  None = 0,
  XSmall = 100,
  Small = 500,
  Medium = 1000,
  Large = 1500,
  XLarge = 2000,
  Unlimited = -1,
}

export const creditOptions: SelectOption[] = map(
  [
    CreditTypes.None,
    CreditTypes.XSmall,
    CreditTypes.Small,
    CreditTypes.Medium,
    CreditTypes.Large,
    CreditTypes.XLarge,
    CreditTypes.Unlimited,
  ],
  (value) => ({
    label: value === CreditTypes.Unlimited ? 'Unlimited' : value.toLocaleString(),
    value,
  }),
);

export type MonthlyActivityResponse = {
  exports_created: number;
  lists_created: number;
  saved_searches_created: number;
};

export type AcknowledgeSubscriptionPayload = {
  hosted_page_id: string;
};

export type SingleSubscriptionPlan = {
  description?: string;
  id: string;
  name: string;
  number_of_credits: number;
  price: {
    currency: string;
    description?: string;
    id: string;
    name: string;
    price: number;
    validity: string;
  };
};

export type SubscriptionPlansResponse = SingleSubscriptionPlan[];

export type CreateSubscriptionPayload = {
  plan_id: string;
  plan_name: string;
  price_id: string;
};

export type CreateSubscriptionResponse = {
  url: string;
};

export type BillingInfo = {
  billing_address: {
    city?: string;
    company?: string;
    country?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    line1?: string;
    line2?: string;
    line3?: string;
    phone?: string;
    state?: string;
    zip?: string;
  };
  vat_number?: string;
};

export type BillingInfoPayload = {
  billing_info?: BillingInfo;
};

export type OrganizationInfoPayload = {
  name?: string;
};

export type OrganizationRequestFeaturePayload = {
  message: string;
};
