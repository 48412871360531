import {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import { Location, LocationFilter } from 'types';
import useLocationAutocomplete from './Autocomplete/useLocationAutocomplete';
import { debounce, filter, isEqual, last, omit, reject } from 'lodash';
import Dropdown from 'components/Filter/Dropdown';
import CheckboxOption from 'components/Filter/Dropdown/CheckboxMenuItem';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';
import { useInView } from 'react-intersection-observer';
import { Combobox } from '@headlessui/react';
import { TextField } from '@demandscience/ui';
import { Option } from './types';
import Inline from 'components/Filter/Inline';
import classNames from 'classnames';

interface LocationSelectFilterProps {
  InputComponent?: React.ElementType;
  autoFocus?: boolean;
  clearInputOnChange?: boolean;
  container: React.RefObject<HTMLDivElement>;
  field?: Location['designation'];
  label?: string;
  lazyLoad?: boolean;
  onChange: (value: LocationFilter[]) => void;
  onClose?: () => void;
  placeholder: string;
  showIncludeExclude?: boolean;
  value?: LocationFilter[];
  variant?: 'plain';
}

const LocationSelectFilter = ({
  label,
  placeholder,
  autoFocus,
  value,
  onChange,
  onClose,
  showIncludeExclude,
  container,
  field,
  lazyLoad,
  clearInputOnChange,
  InputComponent = TextField,
  variant,
}: LocationSelectFilterProps) => {
  const { ref, inView } = useInView();
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { data, isFetching, isError, hasNextPage, fetchNextPage } = useLocationAutocomplete(
    { value: query, fields: field ? [field] : undefined },
    {
      keepPreviousData: false,
      retry: false,
    },
  );

  const suggestions = flatten(data?.pages);
  let options = filter(
    suggestions || [],
    ({ value }) => field === undefined || value.designation === field,
  );

  // workaround so combobox comparison by reference works (against the values)
  options = map(options, (option) => {
    const dataFromValue = find(value, (v) =>
      isEqual(omit(v, 'exclude'), omit(option.value, 'exclude')),
    );

    if (dataFromValue) {
      return {
        ...option,
        value: dataFromValue,
      };
    }

    return {
      ...option,
      value: omit(option.value, 'exclude'),
    };
  });

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
  }, []);

  const handleChange = useCallback(
    (value: LocationFilter[]) => {
      onChange(value);

      // focus input on change
      inputRef.current?.focus();

      // workaround to clear filter input and the suggestions dropdown
      if (clearInputOnChange) {
        handleInputClear();
      }
    },
    [clearInputOnChange, handleInputClear, onChange],
  );

  const debuncedInput = useMemo(
    () =>
      debounce((value: string) => {
        setQuery(value);
      }, 150),
    [],
  );

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;

      debuncedInput(value);
    },
    [debuncedInput],
  );

  const handleDelete = useCallback(
    (deleted: any) => {
      const newValue = reject(value, deleted);

      onChange(newValue);
    },
    [onChange, value],
  );

  const handleKeyDown = useCallback(
    (activeIndex: number | null): KeyboardEventHandler<HTMLInputElement> =>
      (e) => {
        const { key, target, repeat } = e;

        if (!(target as HTMLInputElement).value && key === 'Backspace' && !repeat) {
          handleDelete(last(value));
        }

        if (activeIndex === null && key === 'Enter') {
          e.preventDefault();
        }

        if (key === 'Escape' && !query && onClose) {
          onClose();
        }
      },
    [handleDelete, onClose, query, value],
  );

  const handleClose = () => {
    // this is workaround for no trigger of handleChange after dropdown close eventhough input value changed
    setQuery('');
  };

  const handleToggle = (option: Option<any>) => {
    const newValue = map(value, (v) => {
      if (isEqual(option.value, v)) {
        return option.value.exclude
          ? omit(option.value, 'exclude')
          : { ...option.value, exclude: true };
      }

      return v;
    });

    onChange(newValue);
  };

  const handleClick =
    (open: boolean): MouseEventHandler<HTMLInputElement> =>
    (e) => {
      if (open) {
        e.stopPropagation();
      }
    };

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    if (inView && lazyLoad) {
      handleLoadMore();
    }
  }, [inView, lazyLoad, handleLoadMore]);

  const Component = variant === 'plain' ? Dropdown : Inline;
  const inline = variant !== 'plain';

  return (
    <Component
      value={value}
      onChange={handleChange}
      multiple
      button={({ open, activeIndex }) => (
        <div onClick={handleClick(open)} className={classNames({ 'px-4 pb-2': inline })}>
          <Combobox.Input
            ref={inputRef}
            as={InputComponent}
            // @ts-ignore
            variant="outlined"
            autoComplete="off"
            label={label}
            placeholder={placeholder}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown(activeIndex)}
            autoFocus={autoFocus}
            onClear={handleInputClear}
          />
        </div>
      )}
      container={Component === Dropdown ? container : undefined}
      onClose={Component === Dropdown ? handleClose : undefined}
      className={inline ? 'bg-white' : undefined}
      menuClassName="max-h-40 mx-0"
      offset={[0, variant === 'plain' ? 0 : -12]}
      nested={inline}
    >
      {isError && <TextMenuItem>Error fetching filtering values</TextMenuItem>}
      {!isError && !isEmpty(query) && (
        <>
          {isFetching && <TextMenuItem>Loading...</TextMenuItem>}
          {!isFetching && isEmpty(options) && (
            <TextMenuItem>No match for &quot;{query}&quot;</TextMenuItem>
          )}
          {map(options, (option) => (
            <CheckboxOption
              key={option.id}
              option={option}
              avatarClassName="w-5 h-auto rounded-none"
              onToggle={showIncludeExclude ? handleToggle : undefined}
            />
          ))}
          {lazyLoad && hasNextPage && (
            <TextMenuItem ref={ref} textClassName="text-gray-500">
              Loading...
            </TextMenuItem>
          )}
        </>
      )}
    </Component>
  );
};

LocationSelectFilter.displayName = 'LocationSelectFilter';

export default LocationSelectFilter;
