import { map, range } from 'lodash';
import { Skeleton } from '@demandscience/ui';

interface PreviousExportsSkeletonProps {
  count: number;
}

const PreviousExportsSkeleton = ({ count }: PreviousExportsSkeletonProps) => {
  return (
    <div data-testid="previous-exports-skeleton" className="divide-y">
      {map(range(count), (index) => (
        <div key={index} className="flex justify-between items-center p-4">
          <Skeleton variant="rectangle" width={40} height={3} className="rounded bg-gray-200" />
          <Skeleton variant="rectangle" width={5} height={3} className="rounded bg-gray-200" />
          <Skeleton variant="rectangle" width={28} height={3} className="rounded bg-gray-200" />
          <Skeleton variant="rectangle" width={20} height={3} className="rounded bg-gray-200" />
          <Skeleton variant="rectangle" width={60} height={3} className="rounded bg-gray-200" />
          <Skeleton
            variant="rectangle"
            width={5}
            height={5}
            className="rounded bg-gray-200 self-end mr-4"
          />
        </div>
      ))}
    </div>
  );
};

export default PreviousExportsSkeleton;
