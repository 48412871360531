import {
  AggregateField,
  CompanyFilterName,
  CompanyFilters,
  CompanyRevenueFilter,
  Filter,
  FilterName,
  ModelType,
} from 'types';
import DollarSignIcon from '@demandscience/ui/icons/dollar-sign';

import useFieldAggregate from 'hooks/useFieldAggregate';
import Aggregate from 'components/DataProfile/Aggregate';
import { isEmpty } from 'lodash';
import { getRevenueLabel } from 'components/Filters/utils';
import { useCallback } from 'react';

interface CompanyRevenueAggregateProps {
  companyFilters: CompanyFilters;
  setFilter: (name: FilterName, value?: Filter) => void;
}

const CompanyRevenueAggregate = ({ companyFilters, setFilter }: CompanyRevenueAggregateProps) => {
  const { aggregate, isFetching } = useFieldAggregate(
    ModelType.Company,
    AggregateField.CompanyRevenue,
    companyFilters,
  );
  const { [CompanyFilterName.CompanyRevenue]: filter = [] } = companyFilters;

  const handleFilterChange = useCallback(
    (value: CompanyRevenueFilter) => {
      setFilter(CompanyFilterName.CompanyRevenue, isEmpty(value) ? undefined : value);
    },
    [setFilter],
  );

  return (
    <Aggregate
      label="Revenue"
      icon={DollarSignIcon}
      value={filter}
      onChange={handleFilterChange}
      loading={isFetching}
      aggregate={aggregate}
      getLabel={getRevenueLabel}
      mouseOverFilterId={CompanyFilterName.CompanyRevenue}
      aggregateField={AggregateField.CompanyRevenue}
    />
  );
};

export default CompanyRevenueAggregate;
