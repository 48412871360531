import { useCallback, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Button, Checkbox, Transition } from '@demandscience/ui';

import {
  DeleteSavedSearchFormValues,
  DeleteSavedSearchPayload,
  SavedSearch,
  Visibility,
} from 'types';
import useMutateSavedSearch from './useMutateSavedSearch';
import MessageInputController from 'components/Input/MessageInputController';
import { AxiosError } from 'axios';

interface DeleteFormProps {
  data: SavedSearch;
  onCancel: () => void;
  onSuccess: () => void;
}

const DeleteForm: React.FC<DeleteFormProps> = ({ data, onSuccess, onCancel }) => {
  const [shouldNotify, setShouldNotify] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit } = useForm<DeleteSavedSearchFormValues>({
    defaultValues: { notification_message: '' },
  });
  const { destroy } = useMutateSavedSearch();

  const handleNotificationCheckbox = useCallback(
    (value: boolean) => {
      if (!value) {
        control.unregister('notification_message');
      }

      setShouldNotify(!shouldNotify);
    },
    [control, shouldNotify],
  );

  const onSubmit: SubmitHandler<DeleteSavedSearchFormValues> = async ({ notification_message }) => {
    setApiError(null);

    try {
      let payload: DeleteSavedSearchPayload = {
        id: data.id,
        notify: data.visibility !== Visibility.Private && shouldNotify,
      };

      if (notification_message !== '') {
        payload = { notification_message, ...payload };
      }

      await destroy.mutateAsync(payload);

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || 'Unable to delete saved search');
      } else {
        setApiError('Unexpected error, please try again');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4 mt-10">
        {(data.visibility === Visibility.Public || data.visibility === Visibility.Restricted) && (
          <>
            <Checkbox
              checked={shouldNotify}
              onChange={handleNotificationCheckbox}
              label="Notify users"
              labelClassName="select-none"
            />
            <Transition.Collapse show={shouldNotify} as="div">
              <MessageInputController<DeleteSavedSearchFormValues>
                name="notification_message"
                control={control}
              />
            </Transition.Collapse>
          </>
        )}
        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button type="submit" theme="primary" disabled={destroy.isLoading || destroy.isSuccess}>
          Delete search
        </Button>
      </div>
    </form>
  );
};

export default DeleteForm;
