import Tooltip from 'components/Tooltip';
import { HTMLAttributes, useEffect, useState } from 'react';
import Paragraph from './Paragraph';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';

interface ParagraphWithOverflowTooltipProps extends HTMLAttributes<HTMLParagraphElement> {
  tooltipClassName?: string;
}

const ParagraphWithOverflowTooltip = ({
  title,
  tooltipClassName,
  ...props
}: ParagraphWithOverflowTooltipProps) => {
  const [ref, setRef] = useState<HTMLParagraphElement | null>(null);
  const [open, setOpen] = useState(false);
  const [overflown, setOverflown] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (ref) {
      setOverflown(ref.clientWidth < ref.scrollWidth);
    }
  }, [ref]);

  const content = (
    <Paragraph
      ref={setRef}
      {...props}
      onMouseEnter={overflown || title ? handleMouseEnter : undefined}
      onMouseLeave={overflown || title ? handleMouseLeave : undefined}
    />
  );

  if (overflown || title) {
    return (
      <>
        {content}
        <Tooltip
          anchorEl={ref}
          rootClassName="z-10"
          className={overrideTailwindClasses(
            classNames('bg-gray-800 px-3 py-2 rounded', tooltipClassName),
          )}
          open={open}
          placement="top"
        >
          <Paragraph className="text-gray-100 whitespace-pre-line text-center">
            {title || props.children}
          </Paragraph>
        </Tooltip>
      </>
    );
  }

  return content;
};

export default ParagraphWithOverflowTooltip;
