import { useState, useEffect, useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { fetchSearch } from 'api/search';
import isEqual from 'lodash/isEqual';
import {
  AdvancedSelection,
  CompaniesSort,
  ContactsSort,
  DEFAULT_MAX_RECORDS,
  ExclusionListFilter,
  Filters,
  ModelType,
  SearchPayload,
  SearchResponse,
  SortBy,
} from 'types';
import { mergeExclusionListFilter, omitExclusionListFilter } from './utils';

const useSearchResults = (
  kind: ModelType,
  filters: Filters,
  {
    sortField = kind === ModelType.Company ? CompaniesSort.Revenue : ContactsSort.JobLevel,
    pageSize = 25,
    ...options
  }: { pageSize?: number; sortField?: CompaniesSort | ContactsSort } & Record<string, any> = {},
) => {
  const [excludeList, setExcludeList] = useState<ExclusionListFilter[number]>();
  const queryClient = useQueryClient();
  const [params, setParams] = useState<SearchPayload>({
    pagination: {
      index: 0,
      count: pageSize,
    },
    filters,
    // this is applicable only for contact search
    constraints: undefined,
    // for a company revenue the sort direction is by default descending
    sort: {
      by: sortField,
      descending:
        kind === ModelType.Company
          ? sortField === CompaniesSort.Revenue
          : sortField === ContactsSort.JobLevel,
    },
  });
  const {
    sort,
    pagination: { index: page },
    constraints,
  } = params;

  const handleSetPage = useCallback((index: number) => {
    setParams((state) => ({ ...state, pagination: { ...state.pagination, index } }));
  }, []);

  const handleSetSort = useCallback((newSort: SortBy) => {
    setParams((state) => ({ ...state, sort: newSort }));
  }, []);

  const handleSetMaxContactsPerCompany = useCallback(
    (maxContactsPerCompany?: number, advancedSelection?: AdvancedSelection) => {
      const constraints = maxContactsPerCompany
        ? {
            max_contacts_per_company: maxContactsPerCompany,
            advanced_selection: advancedSelection || { limit: DEFAULT_MAX_RECORDS }, // use max limit if not set otherwise
          }
        : undefined;
      setParams((state) => ({
        ...state,
        pagination: { ...state.pagination, index: 0 },
        constraints,
      }));
    },
    [],
  );

  useEffect(() => {
    if (!excludeList) {
      if (!isEqual(params.filters, filters)) {
        setParams((state) => ({
          ...state,
          pagination: { ...state.pagination, index: 0 },
          filters,
        }));
      }
    } else {
      // merge excludeList value to the existing filters
      const newFilters = mergeExclusionListFilter(filters, excludeList);

      if (!isEqual(params.filters, newFilters)) {
        setParams((state) => ({
          ...state,
          pagination: { ...state.pagination, index: 0 },
          filters: newFilters,
        }));
      }
    }
  }, [params.filters, filters, excludeList]);

  useEffect(() => {
    setParams((state) => ({
      ...state,
      // for a company revenue the sort direction is by default descending
      sort: {
        by: sortField,
        descending:
          kind === ModelType.Company
            ? sortField === CompaniesSort.Revenue
            : sortField === ContactsSort.JobLevel,
      },
    }));
  }, [kind, sortField]);

  const query = useQuery<SearchResponse>(
    ['search', kind, { params }],
    ({ signal }) => fetchSearch(kind, params, signal),
    {
      refetchOnWindowFocus: false, // no need to refetch on window focus
      staleTime: 5 * 60 * 1000, // cache search queries for max 5 minutes
      ...options,
      select: (data) => {
        if (params.constraints || excludeList) {
          // when constraints present that means that advanced selection (x contacts per company) is in use
          // in that case there must be in a cached count for given filters and that value to be set
          // as a totalCount which indicates number of contacts without advanced selection constraints set
          // the same is when excludeList is present (this can be present both for contacts and companies)

          let filters = params.filters;
          if (excludeList) {
            filters = omitExclusionListFilter(filters, excludeList);
          }

          const totalCount = queryClient.getQueryData<number>(['count', kind, filters]);

          if (totalCount) {
            data.pagination.totalCount = totalCount;
          }
        } else {
          // store total count for possible use later when query is constrained by max contacts per company
          const count = data.pagination.count;
          const key = ['count', kind, params.filters];

          if (queryClient.getQueryDefaults(key) === undefined) {
            queryClient.setQueryDefaults(key, { cacheTime: Infinity });
          }
          queryClient.setQueryData(key, count);
        }

        return options?.select ? options?.select(data) : data;
      },
    },
  );

  return {
    query,
    page,
    setPage: handleSetPage,
    pageSize,
    sort,
    setSort: handleSetSort,
    maxContactsPerCompany: constraints?.max_contacts_per_company,
    setMaxContactsPerCompany: handleSetMaxContactsPerCompany,
    excludeList,
    setExcludeList,
  };
};

export default useSearchResults;
