import XIcon from '@demandscience/ui/icons/x';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';

import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import Search from 'components/Input/Search';

import { MemberState, OrganizationsFilter, Role } from 'types';
import Dropdown from 'components/Filter/Dropdown';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';
import CheckboxMenuItem from 'components/Filter/Dropdown/CheckboxMenuItem';
import DividerMenuItem from 'components/Filter/Dropdown/DividerMenuItem';

import { includes, isEqual, join, map, reduce } from 'lodash';
import classNames from 'classnames';
import { useCallback } from 'react';

const LABELS: Record<string, string> = {
  manager: 'Manager',
  user: 'User',
  active: 'Active',
  suspended: 'Deactivated',
  invited: 'Pending',
};

const getLabel = (value: any[]) => {
  if (isEqual(value, ['all'])) {
    return 'All team members';
  }

  return join(
    map(value, (key: string) => LABELS[key]),
    ', ',
  );
};

const getSelectValue = (value?: OrganizationsFilter) => {
  let res: Array<'all' | Role | MemberState> = [];

  if (isEmpty(value?.roles) && isEmpty(value?.state)) {
    res = ['all'];
  } else {
    if (value?.roles) {
      res = [...res, ...value.roles];
    }
    if (value?.state) {
      res = [...res, ...value.state];
    }
  }

  return res;
};

interface FiltersProps {
  onChange: (value: OrganizationsFilter) => void;
  value?: OrganizationsFilter;
}

const Filters: React.FC<FiltersProps> = ({ value, onChange }) => {
  const selectValue = getSelectValue(value);

  const handleChange = useCallback(
    (field: string, fieldValue: any) => {
      if (isEmpty(fieldValue)) {
        onChange(omit(value, field));
      } else {
        onChange({
          ...value,
          [field]: fieldValue,
        });
      }
    },
    [onChange, value],
  );

  const handleSearchChange = useCallback(
    (freetext: string | undefined) => {
      handleChange('freetext', freetext);
    },
    [handleChange],
  );

  const handleSelectChange = useCallback(
    (newValue: any) => {
      if (isEmpty(newValue) || (!includes(selectValue, 'all') && includes(newValue, 'all'))) {
        onChange(omit(value, 'state', 'roles'));
      } else {
        const res = reduce(
          newValue,
          (res, val) => {
            if (val === 'all') {
              return res;
            }

            const key = includes(Role, val) ? 'roles' : 'state';

            return {
              ...res,
              [key]: [...(res[key] || []), val],
            };
          },
          {} as OrganizationsFilter,
        );

        onChange({
          ...omit(value, 'state', 'roles'),
          ...res,
        });
      }
    },
    [onChange, selectValue, value],
  );

  const handleClearAll = useCallback(() => {
    onChange({});
  }, [onChange]);

  return (
    <>
      <div className="md:w-64">
        <Search
          placeholder="Find team member"
          value={value?.freetext}
          onChange={handleSearchChange}
        />
      </div>
      <Dropdown
        menuClassName="max-h-auto"
        value={selectValue}
        onChange={handleSelectChange}
        multiple
        button={({ open, value }) => (
          <button className="px-4 py-2 text-sm text-gray-700 text-left w-full md:w-52 flex flex-row items-center gap-1 border-none rounded ring-inset ring-1 ring-gray-300 hover:ring-gray-700 focus:ring-primary-500 focus:hover:ring-primary-500 focus:outline-none">
            <div className="grow ellipsis">{getLabel(value)}</div>
            {!isEqual(value, ['all']) && (
              <XIcon size={18} className="flex-shrink-0 text-gray-500" onClick={handleClearAll} />
            )}
            <ChevronDownIcon
              size={18}
              className={classNames('flex-shrink-0', { 'rotate-180': open })}
            />
          </button>
        )}
      >
        <CheckboxMenuItem option={{ id: 'all', label: 'All team members', value: 'all' }} />
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Roles</TextMenuItem>
        {map([Role.Manager, Role.User], (value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label: LABELS[value], value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Status</TextMenuItem>
        {map([MemberState.Active, MemberState.Suspended, MemberState.Invited], (value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label: LABELS[value], value }} />
        ))}
      </Dropdown>
    </>
  );
};

export default Filters;
