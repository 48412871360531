import { useCallback, useRef } from 'react';
import first from 'lodash/first';
import classNames from 'classnames';
import { Button } from '@demandscience/ui';
import useMutateAvatar from './useMutateAvatar';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { overrideTailwindClasses } from 'tailwind-override';
import { AxiosError } from 'axios';

export interface AvatarUploadProps {
  className?: string;
  hasAvatar?: boolean;
  id: string;
  size?: 'sm' | 'md';
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({ className, id, hasAvatar, size = 'md' }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { showMessage } = useSnackbar();
  const { uploadAvatar, deleteAvatar } = useMutateAvatar();

  const handleDelete = useCallback(async () => {
    try {
      await deleteAvatar.mutateAsync(id);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to delete avatar', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  }, [deleteAvatar, id, showMessage]);

  const handleUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = e;

      try {
        await uploadAvatar.mutateAsync({ id, payload: first(files) });
      } catch (e: any) {
        if (e instanceof AxiosError) {
          showMessage(e.response?.data?.error || 'Unable to upload avatar', 'error');
        } else {
          showMessage('Unexpected error, please try again later', 'error');
        }
      }
    },
    [id, showMessage, uploadAvatar],
  );

  const handleClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = ''; // workaround to be able to upload successively files with the same filename
      inputRef.current.click();
    }
  }, []);

  return (
    <div
      className={overrideTailwindClasses(classNames('flex flex-col sm:flex-row gap-3', className))}
    >
      {hasAvatar && (
        <Button
          className="whitespace-nowrap order-last sm:order-first"
          theme="primary"
          size={size}
          borderless
          type="button"
          onClick={handleDelete}
          disabled={uploadAvatar.isLoading || deleteAvatar.isLoading}
        >
          Delete photo
        </Button>
      )}
      <Button
        className="whitespace-nowrap cursor-pointer w-full"
        theme="primary"
        size={size}
        outline
        type="button"
        onClick={handleClick}
        disabled={uploadAvatar.isLoading || deleteAvatar.isLoading}
      >
        Upload photo
      </Button>
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        className="hidden"
        id="avatar-upload-input"
        onChange={handleUpload}
      />
    </div>
  );
};

export default AvatarUpload;
