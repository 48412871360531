import XIcon from '@demandscience/ui/icons/x';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';

import { CreditsActivityFilter, CreditActivityContext } from 'types';

import Dropdown from 'components/Filter/Dropdown';
import { includes, isEmpty, isEqual, map, omit } from 'lodash';
import Search from 'components/Input/Search';
import classNames from 'classnames';
import CheckboxMenuItem from 'components/Filter/Dropdown/CheckboxMenuItem';
import DividerMenuItem from 'components/Filter/Dropdown/DividerMenuItem';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';
import { useCallback } from 'react';
import {
  creditActivityActions,
  creditActivityContexts,
  creditActivityRecordTypes,
  getFilterValue,
  getSelectLabel,
  getSelectValue,
  SelectType,
} from './utils';

interface FiltersProps {
  onChange: (value: CreditsActivityFilter) => void;
  value?: CreditsActivityFilter;
}

const Filters: React.FC<FiltersProps> = ({ value, onChange }) => {
  const selectValue = getSelectValue(value);

  const handleChange = useCallback(
    (field: string, fieldValue: any) => {
      if (isEmpty(fieldValue)) {
        onChange(omit(value, field));
      } else {
        onChange({
          ...value,
          [field]: fieldValue,
        });
      }
    },
    [onChange, value],
  );

  const handleSearchChange = useCallback(
    (freetext: string | undefined) => {
      handleChange('account_freetext', freetext);
    },
    [handleChange],
  );

  const handleSelectChange = useCallback(
    (newValue: SelectType) => {
      if (isEmpty(newValue) || (!includes(selectValue, 'all') && includes(newValue, 'all'))) {
        onChange(omit(value, 'record_types', 'action_contexts', 'actions', 'destinations'));
      } else {
        const res = getFilterValue(newValue);

        onChange({
          ...omit(value, 'action_contexts', 'record_types', 'actions', 'destinations'),
          ...res,
        });
      }
    },
    [onChange, selectValue, value],
  );

  const handleClearAll = useCallback(() => {
    onChange({});
  }, [onChange]);

  return (
    <>
      <div className="md:w-64">
        <Search
          placeholder="Filter list by team member"
          value={value?.account_freetext}
          onChange={handleSearchChange}
        />
      </div>
      <Dropdown
        menuClassName="max-h-auto"
        value={selectValue}
        onChange={handleSelectChange}
        multiple
        matchContentSize
        button={({ open, value }) => (
          <button className="px-4 py-2 text-sm text-gray-700 text-left w-full md:w-52 flex flex-row items-center gap-1 border-none rounded ring-inset ring-1 ring-gray-300 hover:ring-gray-700 focus:ring-primary-500 focus:hover:ring-primary-500 focus:outline-none">
            <div className="grow ellipsis">{getSelectLabel(value)}</div>
            {!isEqual(value, ['all']) && (
              <XIcon size={18} className="flex-shrink-0 text-gray-500" onClick={handleClearAll} />
            )}
            <ChevronDownIcon
              size={18}
              className={classNames('flex-shrink-0', { 'rotate-180': open })}
            />
          </button>
        )}
      >
        <CheckboxMenuItem option={{ id: 'all', label: 'All credits logs', value: 'all' }} />
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Action</TextMenuItem>
        {map(creditActivityActions, (label, value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label, value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Type</TextMenuItem>
        {map(creditActivityRecordTypes, (label, value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label, value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Context</TextMenuItem>
        {map([CreditActivityContext.Webapp, CreditActivityContext.Extension], (value) => (
          <CheckboxMenuItem
            key={value}
            option={{ id: value, value, label: creditActivityContexts[value] }}
          />
        ))}
      </Dropdown>
    </>
  );
};

export default Filters;
