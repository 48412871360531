import { VirtualElement } from '@popperjs/core';

type Rect = Partial<Omit<DOMRect, 'toJSON'>>;

export const generateGetBoundingClientRect = (rect: Rect) => () => ({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  ...rect,
  toJSON: () => '',
});

export const getVirtualElement = (rect: Rect): VirtualElement => ({
  getBoundingClientRect: generateGetBoundingClientRect(rect),
});
