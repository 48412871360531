import classNames from 'classnames';
import Avatar from 'components/Avatar';
import { AccountBase } from 'types';

export interface AvatarWithNameProps {
  className?: string;
  user: AccountBase;
}
const AvatarWithName = ({ className, user }: AvatarWithNameProps) => (
  <div className={classNames('flex gap-3 items-center', className)}>
    <Avatar className="h-8 w-8 rounded-full" user={user} />
    <p className="text-sm text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis">
      {user.name}
    </p>
  </div>
);

export default AvatarWithName;
