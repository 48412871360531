import { ExportToList, ListRecordsLimit, ModelType } from 'types';
import ExportToListForm from './ExportToListForm';
import { Button, Modal, ModalProps } from '@demandscience/ui';
import Alert from 'components/Alert';
import { getRecordsLabel } from 'utils/string';

interface ExportToListOverlayProps extends ExportToList {
  count: number;
}

const ExportToListOverlay = ({
  type,
  filters,
  sort,
  advanced_selection,
  constraints,
  count,
  ...props
}: Omit<ModalProps, 'children'> & ExportToListOverlayProps) => {
  const { onClose } = props;
  const isCompany = type === ModelType.Company;
  const limit = ListRecordsLimit[ModelType.Company];
  const overLimit = isCompany && count > limit;

  return (
    <Modal
      {...props}
      aria-labelledby="export-dialog-title"
      aria-describedby="export-dialog-description"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
    >
      <Modal.Title id="export-dialog-title" className="mb-9">
        Add {count.toLocaleString()} {getRecordsLabel(type, count)} to list
      </Modal.Title>
      {overLimit && (
        <>
          <Alert
            severity="warning"
            message={`A list can contain a max of ${limit.toLocaleString()} ${getRecordsLabel(
              type,
            )}`}
          />
          <div className="flex justify-end mt-7">
            <Button theme="primary" size="md" onClick={onClose}>
              Change selection
            </Button>
          </div>
        </>
      )}
      {!overLimit && (
        <ExportToListForm
          onSuccess={onClose}
          onCancel={onClose}
          type={type}
          filters={filters}
          sort={sort}
          advanced_selection={advanced_selection}
          constraints={constraints}
          count={count}
        />
      )}
    </Modal>
  );
};

export default ExportToListOverlay;
