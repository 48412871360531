export interface HeaderProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
}

const Header = ({ children, ...props }: HeaderProps) => (
  <div {...props}>
    <div className="flex items-center space-x-6">{children}</div>
  </div>
);

export default Header;
