import { Checkbox } from '@demandscience/ui';
import classNames from 'classnames';
import Paragraph from 'components/Typography/Paragraph';
import { ReactElement } from 'react';

export interface ColumnBoxProps {
  checked?: boolean;
  disabled?: boolean;
  handle?: ReactElement;
  label: string;
  onChange: () => void;
}

const ColumnBox = ({ label, checked, disabled, onChange, handle }: ColumnBoxProps) => {
  return (
    <div
      className={classNames('border border-gray-200 rounded px-2 py-1', {
        'bg-white': !disabled,
        'bg-gray-50': disabled,
      })}
    >
      <div className="flex flex-row items-center gap-2">
        <Checkbox size="sm" checked={checked} onChange={onChange} disabled={disabled} />
        <Paragraph className="grow text-gray-700 font-medium">{label}</Paragraph>
        {handle}
      </div>
    </div>
  );
};

export default ColumnBox;
