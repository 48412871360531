import { useQuery } from '@tanstack/react-query';

import { fetchFilterData } from 'api/data';
import map from 'lodash/map';
import { EmployeesSizeRange, FilterDataFieldName } from 'types';
import { Option } from '../types';

const fetchOptions = async () => {
  const ranges = await fetchFilterData(FilterDataFieldName.EmployeesSizeRanges);
  const data = map(
    ranges,
    (value) =>
      ({
        id: value.size_range,
        value,
        label: value.size_range,
      } as Option<EmployeesSizeRange>),
  );

  return data;
};

const useEmployeeSizeOptions = (options?: Record<string, any>) => {
  const query = useQuery(['filterOptions', FilterDataFieldName.EmployeesSizeRanges], fetchOptions, {
    refetchOnWindowFocus: false, // no need to refetch on window focus
    staleTime: Infinity,
    ...options,
  });

  return query;
};

export default useEmployeeSizeOptions;
