import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@demandscience/ui';

import useMutateCredits from './useMutateCredits';
import MessageInputController from 'components/Input/MessageInputController';
import { NotificationMessage } from 'types';
import { AxiosError } from 'axios';

type RequestCreditsFormValues = {
  notification_message: NotificationMessage;
};

interface RequestCreditsFormProps {
  onCancel: () => void;
  onSuccess: () => void;
}

const RequestCreditsForm = ({ onSuccess, onCancel }: RequestCreditsFormProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      notification_message: '',
    },
  });
  const { isSubmitting } = formState;
  const { requestCredits } = useMutateCredits();

  const onSubmit: SubmitHandler<RequestCreditsFormValues> = async (data) => {
    setApiError(null);

    try {
      await requestCredits.mutateAsync(data);

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <div className="text-sm text-gray-700">
          Your team manager will receive a notification of your request. You can add an optional
          message, for example to explain how many credits you need, what for etc.
        </div>
        <div className="flex flex-col space-y-2">
          <MessageInputController<RequestCreditsFormValues>
            name="notification_message"
            control={control}
          />

          {apiError && <div className="text-error-500 text-center">{apiError}</div>}
        </div>

        <div className="flex justify-end space-x-2 mt-8">
          <Button borderless onClick={onCancel} type="button">
            Cancel
          </Button>
          <Button type="submit" theme="primary" disabled={isSubmitting}>
            Send request
          </Button>
        </div>
      </div>
    </form>
  );
};

export default RequestCreditsForm;
