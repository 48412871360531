import { ContactFilterName } from 'types/filters';
import useJobFunctionOptions from './Options/useJobFunctionOptions';
import SelectFilter from './SelectFilter';
import { FilterProps } from './types';

const ContactJobFunctionFilter = (props: FilterProps) => {
  const query = useJobFunctionOptions();

  return (
    <SelectFilter
      {...props}
      name={ContactFilterName.JobFunction}
      placeholder="Enter department or function"
      showIncludeExclude
      optionsQuery={query}
    />
  );
};

export default ContactJobFunctionFilter;
