import TimeAgo from 'react-timeago';
import { useLists } from 'components/Lists/useLists';
import IconBox from 'components/Layout/IconBox';
import Section from 'components/Layout/Section';
import ListIcon from '@demandscience/ui/icons/list-02';
import ListItem from 'components/Layout/ListItem';
import defaultListParams from './DefaultListParams';
import { isEmpty, map } from 'lodash';
import BadgeShared from 'components/Layout/BadgeShared';
import { ListFilter, ListRecord, ListsQueryResponse, ModelType, Visibility } from 'types';
import { useNavigate } from 'react-router-dom';
import RemoveControl from './RemoveExclusionListControl';
import { Pagination, Progress, Skeleton } from '@demandscience/ui';
import useDataGridSearch from 'components/DataGrid/useDataGridSearch';
import classNames from 'classnames';
import ListItemSkeleton from 'components/Lists/Skeletons/ListItemSkeleton';
import { useCallback } from 'react';
import { stopPropagation } from 'utils/event';

const defaultParams = defaultListParams(5);

interface ListsShortListProps {
  title: string;
}

const Lists = ({ title }: ListsShortListProps) => {
  const {
    params,
    handlePageChange: onPageChange,
    query,
  } = useDataGridSearch<ListFilter, ListsQueryResponse>(useLists, defaultParams);
  const { data, isLoading, isFetching, isError } = query;
  const { index, count } = params;

  const navigate = useNavigate();

  const handleClick = useCallback(
    (list: ListRecord) => () => {
      const { id } = list;
      navigate(`/lists/${id}`);
    },
    [navigate],
  );

  const handlePageChage = useCallback(
    (_: any, page: number) => {
      onPageChange(page);
    },
    [onPageChange],
  );

  if (isLoading) {
    return (
      <Section title={<Skeleton variant="text" height={7} width={32} className="bg-gray-200" />}>
        <div className="divide-y">
          <ListItemSkeleton count={5} />
        </div>
      </Section>
    );
  }

  if (isError) {
    return (
      <div className="text-sm text-error-500">
        Unable to load suppression lists, please try again later.
      </div>
    );
  }

  if (isEmpty(data?.lists)) {
    return null;
  }

  return (
    <Section title={title}>
      <div className="divide-y">
        {map(data?.lists, (list) => (
          <ListItem
            key={list.id}
            role="button"
            onClick={handleClick(list)}
            leadingContent={
              <IconBox className="px-2 py-2 bg-amber-100">
                <ListIcon className="w-4 h-4 text-amber-500" />
              </IconBox>
            }
            title={
              <div className="flex flex-row items-center gap-2">
                <span>{list.name}</span>
                {list.visibility !== Visibility.Private && <BadgeShared title="Shared list" />}
              </div>
            }
            subtitle={
              <>
                {list.records_type === ModelType.Contact ? 'Contacts' : 'Companies'}
                {' - '}
                <TimeAgo date={list.created_at} />
              </>
            }
            trailingContent={
              <div onClick={stopPropagation}>
                <RemoveControl {...list} />
              </div>
            }
          />
        ))}
        {data?.returned !== data?.count && (
          <div className="flex justify-end pt-4 relative">
            <Progress
              theme="primary"
              className={classNames('absolute top-0 bg-white h-[1px] invisible', {
                visible: isFetching,
              })}
            />
            <Pagination.Combined
              size="sm"
              count={data?.count}
              page={index + 1}
              rowsPerPage={count}
              onPageChange={handlePageChage}
            />
          </div>
        )}
      </div>
    </Section>
  );
};

export default Lists;
