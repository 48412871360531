import { DropdownMenu, DropdownMenuProps, IconButton, Link } from '@demandscience/ui';

import { Bounce } from 'types';
import DeleteBounceOverlay from './DeleteBounceOverlay';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';
import classNames from 'classnames';
import useModal from 'hooks/useModal';
import { downloadBounceFile } from 'api/bounces';
import { useCallback } from 'react';

interface BounceDropdownProps extends DropdownMenuProps {
  data: Bounce;
}

const BounceDropdown: React.FC<BounceDropdownProps> = ({ data, className, ...props }) => {
  const { open: openDelete, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  const handleDownload = useCallback(
    async () => await downloadBounceFile(data.id, data.filename),
    [data.filename, data.id],
  );

  return (
    <>
      <DropdownMenu
        buttonType="custom"
        customButton={
          <IconButton
            className="hover:bg-gray-100"
            size="sm"
            theme="default"
            Icon={MoreVerticalIcon}
          />
        }
        className={classNames('grid place-items-center', className)}
        origin="bottom-right"
        {...props}
      >
        <DropdownMenu.Item
          as={Link}
          onClick={handleDownload}
          className="no-underline hover:no-underline font-normal hover:font-normal"
        >
          Download File
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={openDeleteModal}>Delete</DropdownMenu.Item>
      </DropdownMenu>
      <DeleteBounceOverlay
        bounce_id={data.id}
        open={openDelete}
        onClose={closeDeleteModal}
        onSuccess={closeDeleteModal}
      />
    </>
  );
};

export default BounceDropdown;
