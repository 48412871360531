import { forwardRef, HTMLAttributes } from 'react';
import classNames from 'classnames';
import XIcon from '@demandscience/ui/icons/x';

interface TextFieldProps extends HTMLAttributes<HTMLInputElement> {
  onClear?: () => void;
  rootClassName?: string;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ rootClassName, className, onClear, ...props }, ref) => (
    <div
      className={classNames(
        'flex flex-row gap-2 py-2 border-b focus-within:border-blue-500 focus-within:hover:border-blue-500 hover:border-gray-700',
        rootClassName,
      )}
    >
      <input
        className={classNames(
          'peer grow p-0 border-0 bg-transparent focus:border-none focus:outline-none focus:ring-0 cursor-auto text-sm text-gray-700 placeholder-gray-400',
          className,
        )}
        ref={ref}
        {...props}
      />
      {onClear && (
        <button className="flex-shrink-0 peer-placeholder-shown:hidden" onClick={onClear}>
          <XIcon className="stroke-gray-500" size={18} />
        </button>
      )}
    </div>
  ),
);

TextField.displayName = 'TextField';

export default TextField;
