import { SubmitHandler, useForm } from 'react-hook-form';
import useMutateBookMark from '../useMutateBookMark';
import { Avatar, TextField, Tooltip } from '@demandscience/ui';
import { Send } from '@demandscience/ui/icons';
import useAuth from 'components/Auth/useAuth';

interface CommentFormProps {
  id: string;
}
interface CommentFormData {
  comment: string;
}

const CommentForm: React.FC<CommentFormProps> = ({ id }) => {
  const { register, handleSubmit, reset } = useForm<CommentFormData>();
  const { newComment } = useMutateBookMark();
  const { mutateAsync } = newComment;
  const { user } = useAuth();

  const onSubmit: SubmitHandler<CommentFormData> = async (data) => {
    try {
      await mutateAsync({ comment: data.comment, id });
      reset();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error submitting comment', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="flex flex-row mt-3">
        <div className="flex-shrink-0 mt-1">
          <Avatar className="w-8 h-8" name={user?.name.charAt(0) as string} />
        </div>
        <TextField
          {...register('comment', { required: 'Comment is required' })}
          className="w-[20.62rem] bottom-0 rounded ml-3 "
          placeholder="Add a comment..."
          variant="line"
          trailingIcon={
            <Tooltip
              position="top-right"
              showArrow
              title="Post Comment"
              tooltipClassName="absolute bottom-full -right-2"
            >
              <button type="submit" className="p-0 mt-2 border-none bg-transparent">
                <Send size={20} />
              </button>
            </Tooltip>
          }
        />
      </div>
    </form>
  );
};

export default CommentForm;
