import useModal from 'hooks/useModal';
import { Button } from '@demandscience/ui';
import { ElementType, ReactElement, ReactNode } from 'react';
import UploadBouncesOverlay from './UploadBounceOverlay';

interface UploadBouncesListControlProps {
  as?: ElementType;
  borderless?: boolean;
  children: ReactNode;
  className?: string;
  leftIcon?: ReactElement;
  size?: string;
  theme?: string;
}

const UploadBouncesListControl = ({
  as: Component = Button,
  ...props
}: UploadBouncesListControlProps) => {
  const { open, openModal, closeModal } = useModal();

  return (
    <>
      <Component onClick={openModal} {...props} />
      <UploadBouncesOverlay open={open} onClose={closeModal} />
    </>
  );
};

export default UploadBouncesListControl;
