import numeral from 'numeral';

export function formatChartLabelNumberAbbreviation(value, decimal) {
  return formatNumberAbbreviationInternal(value, decimal, true);
}
export function formatNumberWCommaRoundOff(value, decimal) {
  return formatNumberAbbreviation(value, decimal);
}
export function formatNumberAbbreviation(value, decimal) {
  return formatNumberAbbreviationInternal(value, decimal, true);
}
function formatNumberAbbreviationInternal(value, decimal, showThousandsAsK) {
  return getNumeralFormattedValueWithAbbreviations(value, decimal, showThousandsAsK);
}
const getNumeralFormattedValueWithAbbreviations = (value, decimal, showThousandsAsK) => {
  const numeralFormat = getNumeralFormat(
    decimal,
    toIncludeAbbreviations(value, showThousandsAsK) ? 'a' : '',
  );
  const formattedValue = getNumeralFormattedValue(value, numeralFormat);
  return formattedValue?.toUpperCase();
};
const getNumeralFormat = (decimal, format) => {
  const defaultFormatWithAbbreviations = '0,0' + getDecimalDigitsFormat(decimal);
  if (format) {
    return defaultFormatWithAbbreviations + format;
  }
  return defaultFormatWithAbbreviations;
};
const toIncludeAbbreviations = (value, showThousandsAsK) =>
  showThousandsAsK || value < 1000 || value >= 1000000;

const getNumeralFormattedValue = (value, numeralFormat) => numeralFun(value).format(numeralFormat);
const getDecimalDigitsFormat = (decimal) => {
  if (!decimal || decimal < 1) {
    return '';
  }
  const decimalDigits = Array(decimal).fill('0').join('');
  return decimalDigits && decimalDigits.length > 0 ? '.[' + decimalDigits + ']' : '';
};
const numeralFun = (value) => numeral(value);
