import { useLocation, useNavigate, Navigate } from 'react-router-dom';

import AuthEmailConfirm from 'components/Auth/EmailConfirm';
import Heading from 'layouts/Auth/Heading';

import useSnackbar from 'components/Snackbar/useSnackbar';
import { CodeDeliveryDetails } from 'types';
import { useCallback } from 'react';

const EmailConfirm = () => {
  const { showMessage } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();

  const state = location.state as Record<string, any>;
  const { codeDeliveryDetails } = state || {};

  const handleEmailConfirm = useCallback(() => {
    showMessage('Email was confirmed', 'success');

    const state = location.state as Record<string, any>;
    const from = state?.from || '../signin';

    navigate(from, { replace: true });
  }, [location.state, navigate, showMessage]);

  if (!codeDeliveryDetails) {
    return <Navigate to="../../signin" replace />;
  }

  const { Destination } = codeDeliveryDetails as CodeDeliveryDetails;

  return (
    <>
      <Heading
        title="Email change confirm"
        description={`Enter the verification code sent to ${Destination} to change the email.`}
      />
      <AuthEmailConfirm onEmailConfirm={handleEmailConfirm} />
    </>
  );
};

export default EmailConfirm;
