import { useState, useEffect, useCallback, useMemo } from 'react';

import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import size from 'lodash/size';
import take from 'lodash/take';

import FiltersContext, { FiltersContextInterface } from './FiltersContext';
import { Filters, Filter, FilterHistory, FilterName } from 'types/filters';
import { getCompanyFilters } from './utils';

interface FiltersProviderProps {
  children: React.ReactNode;
  filters?: Filters;
}

const DEFAULT_FILTERS = {};

const FiltersProvider = ({
  filters: defaultFilters = DEFAULT_FILTERS,
  children,
}: FiltersProviderProps) => {
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const [{ history, index: historyIndex }, setHistory] = useState<FilterHistory>({
    history: [defaultFilters],
    index: 0,
  });
  const [filterCleared, setFilterCleared] = useState(false);

  const hasUndo = historyIndex > 0;
  const hasRedo = historyIndex < size(history) - 1;

  const updateFiltersAndHistory = useCallback(
    (newFilters: Filters, clearHistory: boolean = false) => {
      setFilters(newFilters);
      setHistory((state) => {
        return clearHistory
          ? {
              history: [newFilters],
              index: 0,
            }
          : {
              history: [...take(state.history, state.index + 1), newFilters],
              index: state.index + 1,
            };
      });
    },
    [],
  );

  const setAllFilters = useCallback(
    (newFilters: Filters) => {
      updateFiltersAndHistory(newFilters);
    },
    [updateFiltersAndHistory],
  );

  const setFilter = useCallback(
    (name: FilterName, value?: Filter) => {
      let newFilters;

      if (isUndefined(value)) {
        newFilters = omit(filters, name);
        setFilterCleared(true);
      } else {
        newFilters = { ...filters, [name]: value };
        setFilterCleared(false);
      }

      updateFiltersAndHistory(newFilters);
    },
    [filters, updateFiltersAndHistory],
  );

  const clearAll = useCallback(() => {
    updateFiltersAndHistory({});
  }, [updateFiltersAndHistory]);

  const undoFilter = useCallback(() => {
    const index = historyIndex - 1;
    setFilters(history[index]);
    setHistory((state) => ({ ...state, index }));
  }, [history, historyIndex]);

  const redoFilter = useCallback(() => {
    const index = historyIndex + 1;
    setFilters(history[index]);
    setHistory((state) => ({ ...state, index }));
  }, [history, historyIndex]);

  const resetHistory = useCallback((newFilters: Filters) => {
    setHistory({ history: [newFilters], index: 0 });
  }, []);

  // defaultFilters can change externally
  useEffect(() => {
    updateFiltersAndHistory(defaultFilters, true); // flag is to clear history
  }, [defaultFilters, updateFiltersAndHistory]);

  const companyFilters = getCompanyFilters(filters);

  const value: FiltersContextInterface = useMemo(
    () => ({
      filters,
      companyFilters,
      setFilter,
      setFilters: setAllFilters,
      clearAll,
      hasUndo,
      hasRedo,
      undoFilter,
      redoFilter,
      filterCleared,
      setFilterCleared,
      resetHistory,
    }),
    [
      clearAll,
      companyFilters,
      filterCleared,
      filters,
      hasRedo,
      hasUndo,
      redoFilter,
      resetHistory,
      setAllFilters,
      setFilter,
      undoFilter,
    ],
  );

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

export default FiltersProvider;
