import useFiltersCollapse from './useFiltersCollapse';
import { Link, LinkProps } from '@demandscience/ui';

interface CollapseButtonProps extends LinkProps {}

const CollapseButton: React.FC<CollapseButtonProps> = (props) => {
  const { toggle } = useFiltersCollapse();

  return <Link as="button" {...props} onClick={toggle} />;
};

export default CollapseButton;
