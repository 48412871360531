import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateSavedSearch, createSavedSearch, deleteSavedSearch } from 'api/savedSearch';
import { isEmpty, size } from 'lodash';
import { useTracking } from 'react-tracking';
import { SaveSearchEvent } from 'types/tracking';

const useMutateSavedSearch = (options?: Record<string, any>) => {
  const { trackEvent } = useTracking<SaveSearchEvent>({
    type: 'event',
  });
  const queryClient = useQueryClient();

  const invalidateQuery = () => {
    queryClient.invalidateQueries(['savedSearches']);
  };

  const invalidateActivity = () => {
    // invalidate activity stats query
    queryClient.invalidateQueries(['activity', 'monthly']);
  };

  const update = useMutation(updateSavedSearch, {
    onSuccess: () => {
      invalidateQuery();
    },
    ...options,
  });

  const create = useMutation(createSavedSearch, {
    onSuccess: (_, params) => {
      invalidateQuery();

      invalidateActivity();

      trackEvent({
        event: 'save_search',
        visibility: params.visibility,
        numberFilters: size(params.filters),
        notify: !isEmpty(params.notification_message),
      });
    },
    ...options,
  });

  const destroy = useMutation(deleteSavedSearch, {
    onSuccess: () => {
      invalidateQuery();

      invalidateActivity();
    },
    ...options,
  });

  return { update, create, destroy };
};

export default useMutateSavedSearch;
