import UsersIcon from '@demandscience/ui/icons/users';
import {
  AggregateField,
  CompanyEmployeesFilter,
  CompanyFilterName,
  CompanyFilters,
  Filter,
  FilterName,
  ModelType,
} from 'types';
import useFieldAggregate from 'hooks/useFieldAggregate';
import Aggregate from 'components/DataProfile/Aggregate';
import { isEmpty } from 'lodash';
import { getEmployeesLabel } from 'components/Filters/utils';
import { useCallback } from 'react';

interface CompanyEmployeesAggregateProps {
  companyFilters: CompanyFilters;
  setFilter: (name: FilterName, value?: Filter) => void;
}

const CompanyEmployeesAggregate = ({
  companyFilters,
  setFilter,
}: CompanyEmployeesAggregateProps) => {
  const { aggregate, isFetching } = useFieldAggregate(
    ModelType.Company,
    AggregateField.CompanyEmployees,
    companyFilters,
  );
  const { [CompanyFilterName.CompanyEmployees]: filter = [] } = companyFilters;

  const handleFilterChange = useCallback(
    (value: CompanyEmployeesFilter) => {
      setFilter(CompanyFilterName.CompanyEmployees, isEmpty(value) ? undefined : value);
    },
    [setFilter],
  );

  return (
    <Aggregate
      label="Headcount"
      icon={UsersIcon}
      value={filter}
      onChange={handleFilterChange}
      loading={isFetching}
      aggregate={aggregate}
      getLabel={getEmployeesLabel}
      mouseOverFilterId={CompanyFilterName.CompanyEmployees}
      aggregateField={AggregateField.CompanyEmployees}
    />
  );
};

export default CompanyEmployeesAggregate;
