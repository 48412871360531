import { getLabels } from 'components/Filters/utils';
import { compact, flatten, isEmpty, join, map, reduce } from 'lodash';
import { FilterName, Filters } from 'types';

type Location = {
  city?: string;
  country: string;
  designation: 'city' | 'country' | 'state' | 'global_region';
  global_region: string;
  state: string;
};

type Industry = {
  category: string;
  sub_category?: string[];
};

type ModelFilters = {
  // company related fields
  cmp_e?: Array<'1-10' | '11-50' | '51-250' | '251-500' | '501-1000' | '1001-5000' | '5000+'>;
  cmp_i?: Industry[];
  cmp_l?: Location[];
  cmp_n?: string[];
  cmp_r?: Array<
    '0-1M' | '1-10M' | '10-50M' | '50-100M' | '100-250M' | '250-500M' | '500M-1B' | '>1B'
  >;
  cmp_s?: string[];
  // contact related fields
  cnt_d?: {
    email?: true;
    linkedin?: true;
    mobile_phone?: true;
    operator: 'AND' | 'OR';
  };
  cnt_jf?: string[];
  cnt_jl?: Array<'C-Level' | 'VP' | 'Director' | 'Manager' | 'Individual Contributor' | 'Other'>;
  cnt_jt?: string[];
  cnt_l?: Location[];
  cnt_n?: string[];
  // install/intent related fields
  ins?: string[];
  int?: string[];
};

const SIZE_RANGE = {
  '1-10': {
    size_range: '1-10',
    range_min: 1,
    range_max: 10,
  },
  '11-50': {
    size_range: '11-50',
    range_min: 11,
    range_max: 50,
  },
  '51-250': {
    size_range: '51-250',
    range_min: 51,
    range_max: 250,
  },
  '251-500': {
    size_range: '251-500',
    range_min: 251,
    range_max: 500,
  },
  '501-1000': {
    size_range: '501-1000',
    range_min: 501,
    range_max: 1000,
  },
  '1001-5000': {
    size_range: '1001-5000',
    range_min: 1001,
    range_max: 5000,
  },
  '5000+': {
    size_range: '5000+',
    range_min: 5000,
    range_max: 90000000,
  },
};

const REV_RANGE = {
  '0-1M': {
    rev_range: '0-1M',
    range_min: 0,
    range_max: 1000000,
  },
  '1-10M': {
    rev_range: '1-10M',
    range_min: 1000000,
    range_max: 10000000,
  },
  '10-50M': {
    rev_range: '10-50M',
    range_min: 10000000,
    range_max: 50000000,
  },
  '50-100M': {
    rev_range: '50-100M',
    range_min: 50000000,
    range_max: 100000000,
  },
  '100-250M': {
    rev_range: '100-250M',
    range_min: 100000000,
    range_max: 250000000,
  },
  '250-500M': {
    rev_range: '250-500M',
    range_min: 250000000,
    range_max: 500000000,
  },
  '500M-1B': {
    rev_range: '500M-1B',
    range_min: 500000000,
    range_max: 1000000000,
  },
  '>1B': {
    rev_range: '>1B',
    range_min: 1000000001,
    range_max: 900000000000,
  },
};

const transform: Record<keyof ModelFilters, (value: any) => any> = {
  cmp_e: (value: ModelFilters['cmp_e']) => ({
    employees_size_range: compact(map(value, (key) => SIZE_RANGE[key])),
  }),
  cmp_i: (value: ModelFilters['cmp_i']) => ({
    company_industry: map(value, ({ category, sub_category }) => ({
      category,
      sub_category: isEmpty(sub_category)
        ? undefined
        : map(sub_category, (value) => ({ sub_category: value })),
    })),
  }),
  cmp_l: (value: ModelFilters['cmp_l']) => ({
    company_location: value,
  }),

  cmp_n: (value: ModelFilters['cmp_n']) => ({
    company_name: map(value, (freetext) => ({ freetext })),
  }),
  cmp_r: (value: ModelFilters['cmp_r']) => ({
    company_revenue: compact(map(value, (key) => REV_RANGE[key])),
  }),
  cmp_s: (value: ModelFilters['cmp_s']) => ({
    company_speciality: map(value, (freetext) => ({ freetext })),
  }),
  cnt_d: (value: ModelFilters['cnt_d']) => ({
    contact_details: {
      contact_business_email: value?.email,
      contact_linkedin_handle: value?.linkedin,
      contact_mobile_phone: value?.mobile_phone,
      operator: value?.operator,
    },
  }),
  cnt_jf: (value: ModelFilters['cnt_jf']) => ({
    job_function: map(value, (fn) => ({ function: fn })),
  }),
  cnt_jl: (value: ModelFilters['cnt_jl']) => ({
    job_level: map(value, (level) => ({ level })),
  }),
  cnt_jt: (value: ModelFilters['cnt_jt']) => ({
    job_title: map(value, (freetext) => ({ freetext })),
  }),
  cnt_l: (value: ModelFilters['cnt_l']) => ({
    contact_location: value,
  }),
  cnt_n: (value: ModelFilters['cnt_n']) => ({
    contact_name: map(value, (freetext) => ({ freetext })),
  }),
  ins: (value: ModelFilters['ins']) => ({
    install_topic: {
      install: map(value, (name) => ({ name })),
    },
  }),
  int: (value: ModelFilters['int']) => ({
    intent_topic: {
      intent: map(value, (topic) => ({ topic })),
      range_min: 0,
      range_max: 100,
    },
  }),
};

export const modelFiltersToKlarityFilters = (filters: ModelFilters) =>
  reduce(
    filters,
    (res, value, key) => {
      const fn = transform[key as keyof ModelFilters];

      if (!fn || isEmpty(value)) {
        return res;
      }

      return {
        ...res,
        ...fn(value),
      };
    },
    {},
  );

export const getFilterText = (filters: Filters) => {
  const labels = map(filters, (filter, filterName: FilterName) => getLabels(filterName, filter));

  return join(flatten(labels), ', ');
};
