import { useCallback, useMemo, useRef, useState } from 'react';

import {
  isEmpty,
  uniq,
  map,
  find,
  isEqual,
  reject,
  reduce,
  pick,
  omit,
  parseInt,
  size,
  every,
  some,
  compact,
  filter,
  findIndex,
  differenceBy,
  unionBy,
} from 'lodash';
import NestedCheckboxMenuItem from 'components/Filter/Dropdown/NestedCheckboxMenuItem';
import Dropdown from '../Filter/Dropdown';
import LinkMenuItem from '../Filter/Dropdown/LinkMenuItem';
import TextMenuItem from '../Filter/Dropdown/TextMenuItem';
import { FilterProps, Option, OptionWithNestedOptions } from './types';
import useFilter from './useFilter';
import {
  CompanyFilterName,
  CompanyIndustryFilter,
  ExcludableFilter,
  FilterName,
  IndustryByCategory,
  SubIndustry,
} from 'types';
import { UseQueryResult } from '@tanstack/react-query';
import { getIndustryLabel } from './utils';
import FilterInput from './FilterInput';
import Inline from 'components/Filter/Inline';

export interface NestedSelectFilterProps extends FilterProps {
  autocomplete?: boolean;
  badgeDisplayCap?: number;
  by: 'category';
  clearInputOnChange?: boolean;
  name: CompanyFilterName.CompanyIndustry;
  nestedBy: 'sub_category';
  optionsQuery: UseQueryResult<OptionWithNestedOptions<any, any>[]>;
  placeholder: string;
  showIncludeExclude?: boolean;
  showSelectAll?: boolean;
}

type CompanyIndustryFilterParam =
  | (IndustryByCategory &
      ExcludableFilter & {
        sub_category?: Array<SubIndustry & ExcludableFilter>;
      })
  | undefined;

const getBadgeProps = (name: FilterName) => (value: any) => {
  let label = 'n/a';

  if (name === CompanyFilterName.CompanyIndustry) {
    if (value.sic) {
      label = `SIC: ${value.sic}`;
    } else if (value.naics) {
      label = `NAICS: ${value.naics}`;
    } else {
      label = value.sub_category || value.category;
    }
  }

  return {
    key: label,
    label,
    exclude: value.exclude,
  };
};

const getValueForOption = (by: string, option: Option<any>, value?: any[]) =>
  find(value, (v) => {
    let compareKey = by;
    if ('sic' in v) {
      compareKey = 'sic';
    }
    if ('naics' in v) {
      compareKey = 'naics';
    }

    return isEqual(pick(v, compareKey), pick(option.value, compareKey));
  });

const getOptions = (
  by: string,
  nestedBy: string | null,
  options: OptionWithNestedOptions<any, any>[],
  include: boolean,
  value?: any,
  showIncludeExclude?: boolean,
) => {
  // workaround so combobox comparison by reference works (against the values)
  return map(options, (option) => {
    let res = option;

    const dataFromValue = getValueForOption(by, option, value);

    if (option.options && nestedBy) {
      res = {
        ...res,
        options: getOptions(
          nestedBy,
          null,
          option.options,
          include,
          dataFromValue?.[nestedBy] || [],
          showIncludeExclude,
        ),
      };
    }

    if (dataFromValue) {
      return {
        ...res,
        value: dataFromValue,
      };
    }

    return res;
  });
};

const getBadgeValues = (by: string, nestedBy: string, value?: any): any[] => {
  return reduce(
    value,
    // @ts-expect-error
    (res, filter) => {
      if (isEmpty(filter[nestedBy])) {
        return [...res, filter];
      }

      const nested = map(filter[nestedBy], (v) => ({
        [by]: filter[by],
        ...v,
      }));

      return [...res, ...nested];
    },
    [],
  );
};

const matchesLabel = (label: string, query: string) => {
  return label.toLowerCase().includes(query.toLowerCase());
};

const optionMatchesAToken = (option: Option<any>, tokens: string[]) =>
  some(tokens, (token) => matchesLabel(option.label, token));

const NestedSelectFilter = ({
  name,
  placeholder,
  showSelectAll,
  showIncludeExclude,
  dense,
  variant,
  autocomplete,
  container,
  optionsQuery,
  by,
  nestedBy,
  clearInputOnChange,
  badgeDisplayCap,
}: NestedSelectFilterProps) => {
  const [query, setQuery] = useState('');

  const tokens = useMemo(() => compact(query?.split(/\s*,\s*/)), [query]);

  const inputRef = useRef<HTMLInputElement>(null);

  const { value, onChange, onClear } = useFilter(name);

  const { data = [], isFetching, isError } = optionsQuery;

  const filterOptions = () => {
    const options = data.filter((option) => {
      if (
        optionMatchesAToken(option, tokens) ||
        some(option.options, (option) => optionMatchesAToken(option, tokens))
      ) {
        return true;
      }

      return false;
    });

    return options;
  };

  let options = query === '' || autocomplete ? data : filterOptions();

  if (/^\d+$/.test(query)) {
    const sic = { sic: parseInt(query) };
    const naics = { naics: parseInt(query) };

    // NOTE:
    // this is industry specific options and as NestedSelectFilter is used only as Industry filter
    // it's working like this, but must be refactored so filter component is not aware filter/options
    // type to be selected within
    options = [
      { id: 'sic', value: sic, label: getIndustryLabel(sic) as string },
      { id: 'naics', value: naics, label: getIndustryLabel(naics) as string },
      ...options,
    ];
  }

  // workaround so combobox comparison by reference works (against the values)
  options = getOptions(by, nestedBy, options, false, value, showIncludeExclude);

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
  }, []);

  const handleChange = useCallback(
    (value: CompanyIndustryFilter | undefined) => {
      onChange(value);

      // focus input on change
      inputRef.current?.focus();

      // workaround to clear filter input and the suggestions dropdown
      if (clearInputOnChange) {
        handleInputClear();
      }
    },
    [clearInputOnChange, handleInputClear, onChange],
  );

  const handleInputChange = useCallback((e: React.SyntheticEvent) => {
    const { target } = e;

    setQuery((target as HTMLInputElement).value);
  }, []);

  const handleNestedChange =
    (option: OptionWithNestedOptions<any, any>) => (nestedValue: any[]) => {
      const tmp = reject(value, pick(option.value, by));

      if (isEmpty(nestedValue)) {
        handleChange(tmp as CompanyIndustryFilter);
      } else {
        const total = size(option.options);

        // if all values are selected, and have the same exclude set
        // swap it for main category
        if (
          total === size(nestedValue) &&
          (every(nestedValue, ({ exclude }) => exclude) ||
            every(nestedValue, ({ exclude }) => !exclude))
        ) {
          handleChange([
            ...tmp,
            {
              ...omit(option.value, ['exclude', nestedBy]),
              exclude: nestedValue[0].exclude, // use the same exclude as nested value
            },
          ] as CompanyIndustryFilter);
        } else {
          handleChange([
            ...tmp,
            {
              ...omit(option.value, ['exclude']),
              [nestedBy]: nestedValue,
            },
          ] as CompanyIndustryFilter);
        }
      }
    };

  const handleSelectAll = useCallback(() => {
    onChange(uniq([...(value ?? []), ...map(options, 'value')]));
  }, [onChange, options, value]);

  const handleToggle = (option: Option<any>) => {
    const newValue = map(value, (v) => {
      if (isEqual(option.value, v)) {
        const val = omit(option.value, ['exclude', nestedBy]);

        return option.value.exclude ? val : { ...val, exclude: true };
      }

      return v;
    });

    onChange(newValue as CompanyIndustryFilter);
  };

  const handleClose = () => {
    // this is workaround to avoid triggering handleChange after dropdown closes even though input value changed
    setQuery('');
  };

  const handleDelete = useCallback(
    (deleted: CompanyIndustryFilterParam) => {
      let newValue;

      if (deleted?.[nestedBy]) {
        newValue = reduce(
          value,
          // @ts-expect-error
          (res, filter) => {
            if (by in filter && deleted[by] === filter[by]) {
              const nested = reject(filter[nestedBy], {
                [nestedBy]: deleted[nestedBy],
              });

              if (isEmpty(nested)) {
                return res;
              }

              return [
                ...res,
                {
                  ...filter,
                  [nestedBy]: nested,
                },
              ];
            }

            return [...res, filter];
          },
          [],
        );
      } else {
        newValue = reject(value, deleted);
      }

      onChange(newValue);
    },
    [by, nestedBy, onChange, value],
  );

  const handleClear = useCallback(() => {
    handleInputClear();

    onClear();
  }, [handleInputClear, onClear]);

  const handleEnterKey = useCallback(() => {
    if (isEmpty(tokens)) {
      return;
    }

    // calculate new filter value that matches tokens (the comma separated values)
    // options are already filtered by tokens so options are used to calculate new filter structure
    const newValue = map(options, (option) => {
      // the category filter value for given option
      const value = pick(option.value, [by]) as IndustryByCategory;

      // if any token matches the option label, use the category filter
      if (optionMatchesAToken(option, tokens)) {
        return value;
      }

      // otherwise get all subcategories that match tokens
      const subOptions = filter(option.options, (option) => optionMatchesAToken(option, tokens));

      // return category/subcategory filter value
      return {
        ...value,
        [nestedBy]: map(subOptions, (option) => omit(option.value, 'exclude')),
      } as IndustryByCategory;
    });

    // check if newValue is a subset of value, in that case it's a remove, otherwise it's an add
    const isSubset =
      value &&
      every(newValue, (newFiltervalue) => {
        // find the filter value for given industry category
        const filterValue = find(value, pick(newFiltervalue, [by])) as IndustryByCategory;

        if (filterValue) {
          // get the nested subcategory values
          const filterValueNestedValues = map(filterValue[nestedBy], 'value');

          if (isEmpty(filterValueNestedValues)) {
            // if empted every new value is subset
            return true;
          } else if (isEmpty(newFiltervalue[nestedBy])) {
            // if new filter subcategories are enpty it can't be a subset
            return false;
          }

          // otherwise it's a subset if all new filter subcategories are common with current filter subcategories
          return isEmpty(
            differenceBy(newFiltervalue[nestedBy], filterValueNestedValues, 'sub_category'),
          );
        }

        return false;
      });

    if (isSubset) {
      // when a subset, remove all new filter values from teh current filter
      const diffFilter = reduce(
        newValue,
        (res, filterValue) => {
          const index = findIndex(res, pick(filterValue, [by]));

          if (index !== -1) {
            const currentFilterValue = res[index] as IndustryByCategory;

            if (isEmpty(filterValue[nestedBy])) {
              // remove category
              res.splice(index, 1);
            } else {
              const option = find(options, { value: { [by]: filterValue[by] } });
              const allNested = map(option?.options, 'value');
              // calculate new nested values (from the current remove new ones)
              const nestedValues = differenceBy(
                currentFilterValue[nestedBy] || allNested,
                filterValue[nestedBy] || [],
                'sub_category',
              );

              if (isEmpty(nestedValues)) {
                // again remove category if no nested values to apply
                res.splice(index, 1);
              } else {
                // apply new nested values
                res[index] = {
                  [by]: filterValue[by],
                  [nestedBy]: nestedValues,
                };
              }
            }
          }

          return res;
        },
        [...(value || [])],
      );

      onChange(diffFilter);
    } else {
      // if not a subset, merge all new filter values to the current and apply
      const mergedFilter = reduce(
        newValue,
        (res, filterValue) => {
          const index = findIndex(res, pick(filterValue, [by]));

          if (index !== -1) {
            const currentFilterValue = res[index] as IndustryByCategory;

            if (isEmpty(currentFilterValue[nestedBy])) {
              // bothing to merge as current filter covers industry category and all subcategories
              return res;
            }
            if (!filterValue[nestedBy]) {
              // just set the new filter value as it covers industry category and all subcategories
              res[index] = filterValue;
            } else {
              // otherwise merge two category fiter subcategories
              const option = find(options, { value: { [by]: filterValue[by] } });
              const nestedValues = unionBy(
                currentFilterValue[nestedBy],
                filterValue[nestedBy],
                'sub_category',
              );

              if (size(option?.options) === size(nestedValues)) {
                // if resulting nested values are all possible values, use only filter with the category
                // as that will cover all subcategories
                res[index] = {
                  [by]: filterValue[by],
                };
              } else {
                // set the nested subcategories
                res[index] = {
                  [by]: filterValue[by],
                  [nestedBy]: nestedValues,
                };
              }
            }

            return res;
          }

          return [...res, filterValue];
        },
        [...(value || [])],
      );

      onChange(mergedFilter);
    }
  }, [by, nestedBy, onChange, options, tokens, value]);

  const Component = variant === 'plain' ? Dropdown : Inline;

  return (
    <Component
      value={value}
      onChange={handleChange}
      multiple
      button={(props) => (
        <FilterInput
          {...props}
          ref={inputRef}
          variant={variant}
          name={name}
          value={getBadgeValues(by, nestedBy, value)}
          dense={dense}
          onClear={handleClear}
          onDelete={handleDelete}
          onInputChange={handleInputChange}
          onInputClear={handleInputClear}
          onEnterKey={handleEnterKey}
          placeholder={placeholder}
          getBadgeProps={getBadgeProps(name)}
          badgeDisplayCap={badgeDisplayCap}
        />
      )}
      container={Component === Dropdown ? container : undefined}
      onClose={Component === Dropdown ? handleClose : undefined}
      offset={[0, variant === 'plain' ? 0 : -12]}
    >
      {isError && <TextMenuItem>Error fetching filtering values</TextMenuItem>}
      {!isError && (!autocomplete || !isEmpty(query)) && (
        <>
          {isFetching && <TextMenuItem>Loading...</TextMenuItem>}
          {!isFetching && isEmpty(options) && (
            <TextMenuItem>No match for &quot;{query}&quot;</TextMenuItem>
          )}
          {!isEmpty(options) && (
            <>
              {showSelectAll && (
                <LinkMenuItem className="sticky top-0 bg-white" onClick={handleSelectAll}>
                  Select all
                </LinkMenuItem>
              )}
              {map(options, (option) => {
                const currentValue = getValueForOption(by, option, value);
                const options = isEmpty(tokens)
                  ? option.options!
                  : filter(option.options, (option) => optionMatchesAToken(option, tokens));
                const expanded = !isEmpty(tokens) && !isEmpty(options);

                return (
                  <NestedCheckboxMenuItem
                    key={option.id}
                    option={option}
                    options={options}
                    value={currentValue?.[nestedBy] || []}
                    selected={!!currentValue}
                    onChange={handleNestedChange(option)}
                    onToggle={showIncludeExclude ? handleToggle : undefined}
                    expanded={expanded}
                  />
                );
              })}
            </>
          )}
        </>
      )}
    </Component>
  );
};

export default NestedSelectFilter;
