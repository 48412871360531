import { FC } from 'react';
import { Contact } from 'types';
import { isEmpty } from 'lodash';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';

interface JobTitleProps {
  className?: string;
  row: Contact;
}

const JobTitle: FC<JobTitleProps> = ({ row, className }) => {
  const { jobs } = row;

  if (isEmpty(jobs)) {
    return null;
  }

  // showing only the first job
  const [{ display_title: jobTitle }] = jobs;

  if (!jobTitle) {
    return null;
  }

  return <Paragraph className={className}>{jobTitle}</Paragraph>;
};

export default JobTitle;
