import { useCallback, useLayoutEffect, useState } from 'react';
import { Button, ButtonOwnProps } from '@demandscience/ui';

interface ConsentManagerControlProps extends Omit<ButtonOwnProps, 'onClick'> {
  as?: React.ElementType;
}

const ConsentManagerControl = ({ as: Control = Button, ...props }: ConsentManagerControlProps) => {
  const [link, setLink] = useState<HTMLAnchorElement | null>(null);

  useLayoutEffect(() => {
    const cmButtonContainer = document.getElementById('teconsent');
    const handleLookupLink = () => {
      if (cmButtonContainer) {
        const links = cmButtonContainer.getElementsByTagName('a');

        setLink(links.item(0));
      }
    };

    const observer = new MutationObserver(handleLookupLink);

    if (cmButtonContainer) {
      observer.observe(cmButtonContainer, { childList: true });

      handleLookupLink();
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleOpen = useCallback(() => {
    link?.click();
  }, [link]);

  if (!link) {
    return null;
  }

  return <Control onClick={handleOpen} {...props} />;
};

export default ConsentManagerControl;
