import { Badge, BadgeProps } from '@demandscience/ui';
import pluralize from 'pluralize';
import useOrganization from './useOrganization';

const MembersCountBadge = (props: BadgeProps) => {
  const { data: organization } = useOrganization();

  if (!organization) {
    return null;
  }

  return (
    <Badge size="md" {...props}>
      {organization.active_accounts?.used === 1
        ? '1 member'
        : `${organization.active_accounts?.used.toLocaleString()} ${pluralize(
            'member',
            organization.active_accounts?.used,
          )}`}
    </Badge>
  );
};

export default MembersCountBadge;
