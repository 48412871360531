import { useQuery } from '@tanstack/react-query';

import { fetchSavedSearch } from 'api/savedSearch';

const usePersona = (id: string, options: Record<string, any>) => {
  const query = useQuery(['personas', id], () => fetchSavedSearch(id), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export default usePersona;
