export enum GptModel {
  GPR4_0613 = 'gpt-4-0613',
  GPR4_1106_PREVIEW = 'gpt-4-1106-preview',
}

export enum GptMessageType {
  assistant = 'assistant',
  function = 'function',
  system = 'system',
  user = 'user',
}

export enum GptFunctionName {
  crawlUrl = 'crawlUrl',
  createImage = 'createImage',
  createTalk = 'createTalk',
  fetchArticles = 'fetchArticles',
  fetchGoogleNews = 'fetchGoogleNews',
  renderQuestions = 'renderQuestions',
  renderUI = 'renderUI',
  setKlarityFilters = 'setKlarityFilters',
}

export type FunctionCallResponse = { arguments: string; name: GptFunctionName };

export type BaseMessage<T extends GptMessageType> = {
  role: T;
};

export type SystemMessage = BaseMessage<GptMessageType.system> & {
  content: string;
};

export type UserMessage = BaseMessage<GptMessageType.user> & {
  content: string;
};

export type FunctionMessage = BaseMessage<GptMessageType.function> & {
  content: string;
  name: string;
};

export type AssistantMessage = BaseMessage<GptMessageType.assistant> &
  (
    | {
        content: string | null;
        function_call: FunctionCallResponse;
      }
    | {
        content: string;
        function_call?: never;
      }
  );

export type Message = SystemMessage | UserMessage | AssistantMessage | FunctionMessage;

type SimpleParameter = {
  description?: string;
  type: 'string' | 'number' | 'null';
};

type ArrayParameter<T> = {
  description?: string;
  items: T;
  type: 'array';
};

type ObjectParameter = {
  additionalProperties?: boolean;
  description?: string;
  properties?: Record<string, Parameter>;
  required?: string[];
  type: string;
};

type Parameter = SimpleParameter | ArrayParameter<SimpleParameter> | ObjectParameter;

export type FunctionCall = 'auto' | 'none' | { name: string };

export type Function = {
  description: string;
  name: GptFunctionName;
  parameters?: ObjectParameter;
};

export type ChatCompletionsParams = {
  messages: Array<Message>;
  model?: GptModel;
  prompt_id: string;
};

export type ChatCompletionsPayload = ChatCompletionsParams & {
  stream: boolean;
};

export type ChatCompletionsResponse = {
  choices: [
    {
      finish_reason: string;
      index: number;
      message: Message;
    },
  ];
  id: string;
  model: GptModel;
};

export type ChatPrompt = {
  functions: Array<Function>;
  id: string;
  messages: Array<Message>;
  model: GptModel;
  title: string;
};

export type ChatPromptResponse = Array<ChatPrompt>;
