import { Badge } from '@demandscience/ui';
import { ReactNode, useCallback, useRef, useState } from 'react';
import Tooltip from 'components/Tooltip';

interface TopicBadgeProps {
  Icon: any;
  children: ReactNode;
  className?: string;
  count: number;
  title: string;
}
const TopicBadge = ({ className, count, Icon, title, children }: TopicBadgeProps) => {
  const anchorEl = useRef<HTMLSpanElement>(null);
  const [open, setOpen] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setOpen(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Badge
        className={className}
        color="gray"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        // @ts-expect-error
        label={
          <span ref={anchorEl} className="flex flex-row gap-1 items-center">
            <Icon className="w-[14px] h-[14px] text-gray-500" />
            <span className="text-xs text-gray-900">{count.toLocaleString()}</span>
          </span>
        }
      />
      <Tooltip open={open} anchorEl={anchorEl.current} placement="top-end">
        <div className="rounded p-3 w-60 bg-white shadow text-xs">
          <div className="flex flex-row items-center gap-1 text-gray-700 font-medium mb-2">
            <Icon className="w-3 h-3 flex-shrink-0" />
            <span>{title}</span>
          </div>
          {children}
        </div>
      </Tooltip>
    </>
  );
};

export default TopicBadge;
