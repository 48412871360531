import { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Heading from 'layouts/Main/Heading';
import { Tabs } from '@demandscience/ui';
import { forEach, map } from 'lodash';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';
import { KlarityFeature } from 'types';

const TABS = [
  {
    path: '/manage/plan',
    label: 'Plan & usage',
  },
  {
    path: '/manage/team',
    label: 'Team',
  },
  {
    path: '/manage/suppression-lists',
    label: 'Suppression lists',
  },
  {
    path: '/manage/bounces',
    label: 'Bounces',
  },
  {
    path: '/manage/exports',
    label: 'Exports',
  },
  {
    path: '/manage/credits',
    label: 'Credits',
  },
  {
    path: '/manage/billing',
    label: 'Billing',
  },
];

const Manage = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);

  const filteredTabs = useMemo(() => {
    if (isContactRenderDisabled || isContactDisabled) {
      return TABS.filter((tab) => tab.path !== '/manage/bounces');
    }
    return TABS;
  }, [isContactRenderDisabled, isContactDisabled]);

  const handleTabChange = useCallback(
    (index: number) => {
      const { path } = filteredTabs[index];
      navigate(path);
    },
    [navigate, filteredTabs],
  );

  useEffect(() => {
    forEach(filteredTabs, ({ path }, index) => {
      if (location.pathname.startsWith(path)) {
        setSelectedIndex(index);
      }
    });
  }, [location.pathname, filteredTabs]);

  return (
    <div className="pb-40">
      <Heading>Manager dashboard</Heading>
      <Tabs selectedIndex={selectedIndex} onChange={handleTabChange}>
        <div className="border-b mb-8">
          <Tabs.List>
            {map(filteredTabs, ({ path, label }) => (
              <Tabs.Item key={path}>{label}</Tabs.Item>
            ))}
          </Tabs.List>
        </div>
      </Tabs>
      <Outlet />
    </div>
  );
};

export default Manage;
