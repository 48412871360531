import { Button, ButtonOwnProps } from '@demandscience/ui';
import { SyntheticEvent, useCallback } from 'react';
import useSupportRequestContext from './useSupportRequestContext';

interface SupportRequestControlProps extends Omit<ButtonOwnProps, 'onClick'> {
  action: 'buyCredits' | 'changePlan' | 'confirmPlan' | 'customPlan';
  as?: React.ElementType;
  onClick?: (e: SyntheticEvent) => void;
}

const SupportRequestControl = ({
  action,
  as: Component = Button,
  onClick,
  ...props
}: SupportRequestControlProps) => {
  const { [action]: supportRequestAction } = useSupportRequestContext();

  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      if (onClick) onClick(e);

      supportRequestAction();
    },
    [onClick, supportRequestAction],
  );

  return <Component onClick={handleClick} {...props} />;
};

export default SupportRequestControl;
