import useCustomization from 'components/SearchCustomization/useCustomization';
import { IconButton, Tooltip } from '@demandscience/ui';
import SettingsIcon from '@demandscience/ui/icons/settings';
import { useCallback } from 'react';
import { ModelType, ViewLayout } from 'types';

interface CustomizeColumnsButtonProps {
  kind: ModelType;
}

const CustomizeColumnsButton = ({ kind }: CustomizeColumnsButtonProps) => {
  const { layout, setCustomizeColumnsOpen } = useCustomization();

  const handleClick = useCallback(() => {
    setCustomizeColumnsOpen(kind);
  }, [kind, setCustomizeColumnsOpen]);

  if (layout.view !== ViewLayout.Table) {
    return null;
  }

  return (
    <div className="inline-block">
      <Tooltip title="Customize columns" position="bottom" showArrow>
        <IconButton size="sm" onClick={handleClick} theme="default" Icon={SettingsIcon} />
      </Tooltip>
    </div>
  );
};

export default CustomizeColumnsButton;
