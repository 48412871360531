import { useQuery } from '@tanstack/react-query';
import { openBookmark } from 'api/bookmark';

const staleTime = 60 * 60 * 1000; // 1 hour

const useBookmark = (id: string, options?: { refetchInterval: number | false }) => {
  const query = useQuery(['bookmark', id], () => openBookmark(id), {
    staleTime,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export default useBookmark;
