import { Button, Spinner } from '@demandscience/ui';
import useFieldAggregate from 'hooks/useFieldAggregate';
import { useCallback, useMemo } from 'react';
import {
  AggregateField,
  Company,
  ContactFilterName,
  JobLevel,
  ModelType,
  ResultsView,
} from 'types';
import JobLevelAggregate from './JobLevelAggregate';
import { useNavigate } from 'react-router-dom';
import useFilters from 'components/Filters/useFilters';

interface EmployeesInfoProps {
  row: Company;
}

const EmployeesInfo = ({ row }: EmployeesInfoProps) => {
  const navigate = useNavigate();
  const companyFilters = useMemo(
    () => ({ company_name: [{ dsid: row.dsid, name: row.company_name }] }),
    [row.company_name, row.dsid],
  );
  const { aggregate, isFetching } = useFieldAggregate(
    ModelType.Contact,
    AggregateField.JobLevel,
    companyFilters,
  );
  const { filters } = useFilters() || {};

  const handleButtonClick = useCallback(() => {
    let state = {
      filters: companyFilters,
      tab: ResultsView.Contacts,
    } as any;

    if (filters) {
      state = {
        ...state,
        contactsOf: row,
        prevSearch: {
          filters,
          tab: ResultsView.Companies,
        },
      };
    }

    navigate('/search', {
      state,
    });
  }, [companyFilters, filters, navigate, row]);

  const handleAggregateClick = useCallback(
    (value: JobLevel) => {
      const newFilters = { ...companyFilters, [ContactFilterName.JobLevel]: [value] };

      let state = {
        filters: companyFilters,
        tab: ResultsView.Contacts,
      } as any;

      if (filters) {
        state = {
          ...state,
          contactsOf: row,
          filters: newFilters,
          tab: ResultsView.Contacts,
          prevSearch: {
            filters,
            tab: ResultsView.Companies,
          },
        };
      }

      navigate('/search', {
        state,
      });
    },
    [companyFilters, navigate, filters, row],
  );

  return (
    <div className="space-y-4">
      <Button
        outline
        size="sm"
        className="w-full"
        onClick={handleButtonClick}
        disabled={isFetching || aggregate.count === 0}
      >
        {isFetching && <Spinner size="sm" className="-my-[2px]" />}
        {!isFetching && `View ${aggregate.count.toLocaleString()} contacts`}
      </Button>
      {!isFetching && <JobLevelAggregate aggregate={aggregate} onClick={handleAggregateClick} />}
    </div>
  );
};

export default EmployeesInfo;
