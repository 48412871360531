import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import { stopPropagation } from 'utils/event';
import { Button, RadioList } from '@demandscience/ui';
import useCompare from 'components/Filters/useCompare';
import useFilters from 'components/Filters/useFilters';
import TrashIcon from '@demandscience/ui/icons/trash-2';
import EditIcon from '@demandscience/ui/icons/edit-2';
import { CompareSearch, EditCompareOptions, EditState } from 'types/compare';
import DragHandle from 'components/SortableList/DragHandle';
import SortableItem from 'components/SortableList/SortableItem';
import SortableContainer from 'components/SortableList/SortableContainer';
import classNames from 'classnames';
import { RadioListInput } from './RadioListInput';
import { COMPARE_SEARCH_LIST_INITIAL_STATE } from 'components/Filters/CompareProvider';

export interface RadioListSearchProps {
  className?: string;
  defaultValue: string;
  edit: EditState;
  handle?: React.ReactElement;
  handleChangeSearch: (index: number) => void;
  handleCloseInput: () => void;
  handleDelete: (index: number, event: React.MouseEvent<HTMLButtonElement>) => void;
  handleEditSearch: (index: number, event: React.MouseEvent<HTMLButtonElement>) => void;
  index?: number;
  search: CompareSearch;
  value?: string;
}

export const INITIAL_EDIT_STATE: EditState = {
  [EditCompareOptions.optionOne]: false,
  [EditCompareOptions.optionTwo]: false,
  [EditCompareOptions.optionThree]: false,
  [EditCompareOptions.optionFour]: false,
};

export const RadioListSearch = ({
  className,
  search,
  handle,
  handleChangeSearch,
  handleDelete,
  handleEditSearch,
  handleCloseInput,
  defaultValue,
  index = 0,
  edit,
}: RadioListSearchProps) => {
  return (
    <RadioList.Option
      key={search.value}
      option={search}
      onClick={() => handleChangeSearch(index)}
      className={classNames(
        'flex flex-row w-full justify-between bg-white border border-white rounded-lg p-1',
        className,
      )}
    >
      <div className="flex flex-row text-sm flex-grow items-center">
        <RadioList.Icon className="mr-2" theme="primary" checked={search.isActive} />
        {edit[index as keyof EditState] ? (
          <RadioListInput
            defaultValue={defaultValue}
            index={index}
            handleCloseInput={handleCloseInput}
          />
        ) : (
          <RadioList.Label className="text-md">{search.label}</RadioList.Label>
        )}
      </div>
      <div className="flex items-center">
        {!edit[index as keyof EditState] && (
          <Button
            className="text-gray-200 hover:text-red-400"
            onClick={(event) => handleEditSearch(index, event)}
            size="sm"
            aria-label="Edit search"
            theme="white"
          >
            <EditIcon size={18} />
          </Button>
        )}
        <Button size="sm" aria-label="Reorder search" theme="white">
          {handle}
        </Button>
        <Button
          className="text-gray-200 hover:text-red-400"
          onClick={(event) => handleDelete(index, event)}
          size="md"
          aria-label="Remove search"
          theme="white"
        >
          <TrashIcon size={18} />
        </Button>
      </div>
    </RadioList.Option>
  );
};

const CompareRadioList = () => {
  const { setCompare, setSearchNumber, compareSearchList, setCompareSearchList } = useCompare();

  const { setFilters, filters, resetHistory } = useFilters();

  const [value, setValue] = useState(compareSearchList[0].value);
  const [edit, setEdit] = useState(INITIAL_EDIT_STATE);

  const handleEditSearch = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    stopPropagation(event);
    setEdit(() => ({
      ...INITIAL_EDIT_STATE,
      [index]: true,
    }));
  };

  const handleCloseInput = () => {
    setEdit(INITIAL_EDIT_STATE);
  };

  const handleRadioChange = (value: string) => {
    setValue(value);
  };

  const handleChangeSearch = (index: number) => {
    setCompareSearchList((prevSearches) =>
      prevSearches.map((search, idx) => {
        if (idx === index) {
          return { ...search, isActive: true };
        }
        return { ...search, isActive: false };
      }),
    );
    setFilters(compareSearchList[index].filters);
    resetHistory(compareSearchList[index].filters);
  };

  const handleDelete = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    stopPropagation(event);
    setEdit(INITIAL_EDIT_STATE);

    if (compareSearchList.length >= 2) {
      setCompareSearchList((prevSearches) =>
        prevSearches
          .filter((_, i) => i !== index)
          .map((search, i) => ({ ...search, value: (i + 1).toString() })),
      );

      if (index === 0 || index + 1 === Number(value)) {
        handleChangeSearch(index === 0 ? index + 1 : index - 1);
        handleRadioChange(index === 0 ? (index + 1).toString() : index.toString());
      }
    } else {
      setCompare(false);
      setSearchNumber(2);
      setFilters({});
      setCompareSearchList(COMPARE_SEARCH_LIST_INITIAL_STATE);
    }
  };

  useEffect(() => {
    setCompareSearchList((prevSearches) =>
      prevSearches.map((search) => {
        if (search.isActive) {
          return { ...search, filters: { ...filters } };
        }
        return search;
      }),
    );
  }, [filters, setCompareSearchList]);

  const handleReorder = (newOrder: string[]) => {
    const reorderedCompareSearchList: CompareSearch[] = [];

    for (const [index] of compareSearchList.entries()) {
      reorderedCompareSearchList[index] = compareSearchList.find(
        (search) => search.value === newOrder[index],
      )!;
    }

    setCompareSearchList(reorderedCompareSearchList);
    setEdit(INITIAL_EDIT_STATE);
  };

  return (
    <div className="mt-3 mb-5">
      <RadioList value={value} onChange={handleRadioChange} options={compareSearchList}>
        <SortableContainer<CompareSearch['value']>
          items={compareSearchList.map(({ value }) => value)}
          onChange={handleReorder}
          renderDragPreview={(val: string) => {
            const search = compareSearchList.find(
              ({ value: searchValue }: CompareSearch) => searchValue === val,
            )!;

            return (
              <RadioListSearch
                search={search}
                handleChangeSearch={noop}
                handleDelete={noop}
                handleEditSearch={noop}
                handleCloseInput={noop}
                handle={<DragHandle />}
                value={value}
                className="bg-gray-50 border-gray-200"
                edit={edit}
                defaultValue={''}
              />
            );
          }}
        >
          <div className="space-y-1 overflow-y-scroll">
            {compareSearchList.map((search, index) => {
              return (
                <SortableItem key={search.value} id={search.value}>
                  <RadioListSearch
                    edit={edit}
                    key={search.value}
                    search={search}
                    index={index}
                    handleChangeSearch={handleChangeSearch}
                    handleDelete={handleDelete}
                    handleEditSearch={handleEditSearch}
                    handleCloseInput={handleCloseInput}
                    value={value}
                    defaultValue={compareSearchList[index].label}
                  />
                </SortableItem>
              );
            })}
          </div>
        </SortableContainer>
      </RadioList>
    </div>
  );
};

export default CompareRadioList;
