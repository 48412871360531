import useSearchResults from 'components/Search/useSearchResults';
import useQueryAggregate from 'hooks/useQueryAggregate';
import useFilters from 'components/Filters/useFilters';
import { getCompanyFilters } from 'components/Filters/utils';

import { ModelType, Filters } from 'types';
import { withoutInclude, includeToExclude } from './utils';

const useTAMCounts = ({ searchFilters }: { searchFilters: Filters }) => {
  const { filters } = useFilters(); // these are current TAM filters

  // combination of search filters with includes removed and current TAM filters
  const filtersTotal = {
    ...withoutInclude(searchFilters),
    ...filters,
  };
  // combination of search filters with includes switched to ecludes and current TAM filters
  const filtersNew = {
    ...includeToExclude(searchFilters),
    ...filters,
  };

  // 4 queries for each of the counts
  const contactCountExpressionTotal = filtersTotal;
  const companyCountExpressionTotal = getCompanyFilters(filtersTotal);
  const contactCountExpressionNew = filtersNew;
  const companyCompanyExpressionNew = getCompanyFilters(filtersNew);

  // 4 api calls
  const { query: contactTotal } = useSearchResults(ModelType.Contact, contactCountExpressionTotal, {
    pageSize: 1,
  });
  const { query: companyTotal } = useSearchResults(ModelType.Company, companyCountExpressionTotal, {
    pageSize: 1,
  });
  const { query: contactNew } = useSearchResults(ModelType.Contact, contactCountExpressionNew, {
    pageSize: 1,
  });
  const { query: companyNew } = useSearchResults(ModelType.Company, companyCompanyExpressionNew, {
    pageSize: 1,
  });

  // aggregate data from above 4 calls
  const {
    data: [contactCountTotal, companyCountTotal, contactCountNew, companyCountNew],
    isLoading,
    isFetching,
  } = useQueryAggregate(
    [contactTotal, companyTotal, contactNew, companyNew],
    'data.pagination.count',
  );

  return {
    contactCountTotal,
    companyCountTotal,
    contactCountNew,
    companyCountNew,
    isLoading,
    isFetching,
  };
};

export default useTAMCounts;
