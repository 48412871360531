import JobLevel from './JobLevel';
import BasicInfo from './BasicInfo';
import JobFunction from './JobFunction';
import { Contact, ModelType } from 'types';
import ContactLocation from './ContactLocation';
import ListDivider from 'components/Layout/ListDivider';
import CompanyFullInfo from '../Company/CompanyFullInfo';
import ListSubheader from 'components/Layout/ListSubheader';
import ContactSocialProfiles from './ContactSocialProfiles';
import ExportProvider from 'components/Export/ExportProvider';
import RevealSingleContactProvider from 'components/RevealContacts/RevealSingleContactProvider';
import Specialities from 'components/Company/Specialities';

interface ContactFullInfoProps {
  onView?: (view: string) => void;
  row: Contact;
}

const ContactFullInfo = ({ row, onView }: ContactFullInfoProps) => {
  return (
    <>
      <ExportProvider modelType={ModelType.Contact} selectionCount={1}>
        <RevealSingleContactProvider>
          <BasicInfo showAvatar row={row} />
        </RevealSingleContactProvider>
      </ExportProvider>
      <ContactSocialProfiles row={row} />
      <ContactLocation row={row} />
      <JobLevel row={row} />
      <JobFunction row={row} />
      <Specialities row={row.companies?.[0]} />
      <ListDivider />
      <ListSubheader title="Works at" />
      <CompanyFullInfo
        row={row.companies?.[0]}
        hideContactsFilter
        hideExport
        hidePhone
        hideSocialProfiles
        onView={onView}
      />
    </>
  );
};

export default ContactFullInfo;
