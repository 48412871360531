import useMember from 'components/Members/useMember';
import { Modal, ModalProps } from '@demandscience/ui';
import { Notification } from 'types';

import Form from './AcceptCreditsRequestForm';

interface AcceptCreditsRequestOverlayProps extends Omit<ModalProps, 'children'> {
  notification: Notification;
}

const AcceptCreditsRequestOverlay = ({
  notification,
  ...props
}: AcceptCreditsRequestOverlayProps) => {
  const { onClose } = props;
  const {
    data: member,
    isLoading,
    isError,
  } = useMember(notification.issued_by?.username as string);

  return (
    <Modal
      aria-labelledby="accept-credits-request-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="accept-credits-request-dialog-title" className="flex mb-7">
        Change credits allocation
      </Modal.Title>
      {isLoading && <div className="text-sm text-gray-700">Loading...</div>}
      {isError && <div className="text-sm text-error-500">Unable to load member details</div>}
      {member && <Form id={notification.id} user={member} onSuccess={onClose} onCancel={onClose} />}
    </Modal>
  );
};

export default AcceptCreditsRequestOverlay;
