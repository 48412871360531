import useAuth from 'components/Auth/useAuth';
import Heading from 'layouts/Main/Heading';
import NotificationsShortList from 'components/Notifications/NotificationsShortList';
import ListsShortList from 'components/Lists/ListsShortList';
import SavedSearchesSection from 'components/SavedSearches/SavedSearchesSection';
import LinkedinInExtensionTip from 'components/LinkedInExtension/ExtensionTip';
import useLinkedInExtension from 'components/LinkedInExtension/useLinkedInExtension';
import AccountInfo from './AccountInfo';
import OnboardingListSection from 'components/Onboarding/OnboardingListSection';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';
import { KlarityFeature } from 'types';

const Home = () => {
  const { user, isManager } = useAuth();
  const { installed } = useLinkedInExtension();
  const [firstName] = (user!.name || '').split(' ');

  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);

  const iscontactRendered: boolean = isContactDisabled || isContactRenderDisabled;

  return (
    <>
      <Heading>Welcome {firstName}</Heading>

      <div className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-6 py-6">
        <div className="lg:col-span-8">
          <div className="space-y-10">
            {iscontactRendered || <OnboardingListSection title="Quick start" />}
            <div className="space-y-6">
              <SavedSearchesSection title="Saved searches" />
              <ListsShortList title="Saved Lists" />
            </div>
            {!installed && <LinkedinInExtensionTip />}
          </div>
        </div>
        <div className="lg:col-span-4 space-y-8">
          {isManager && <AccountInfo />}
          <NotificationsShortList />
        </div>
      </div>
      <div className="mb-8" />
    </>
  );
};

export default Home;
