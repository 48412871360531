import { Link } from '@demandscience/ui';
import classNames from 'classnames';
import { map, size, take } from 'lodash';
import { useCallback, useState } from 'react';

interface TopicListProps {
  className?: string;
  items: Array<{ count?: number; label: string }>;
  show?: number;
}

const TopicList = ({ className, show, items }: TopicListProps) => {
  const [showAll, setShowAll] = useState(show === undefined || size(items) <= show);
  const count = showAll ? size(items) : show;

  const handleClick = useCallback(() => {
    setShowAll(true);
  }, []);

  return (
    <div className={classNames(className, 'text-gray-700 space-y-1')}>
      {map(take(items, count), ({ label, count }) => (
        <div key={label} className="flex flex-row gap-4 justify-between ">
          <span className="ellipsis">{label}</span>
          {count !== undefined && (
            <span className="flex-shrink-0 font-medium">{count.toLocaleString()}</span>
          )}
        </div>
      ))}
      {!showAll && (
        <Link as="button" onClick={handleClick}>
          More
        </Link>
      )}
    </div>
  );
};

export default TopicList;
