import api from 'api';
import {
  ConfigureIntegrationPayload,
  ConnectIntegrationPayload,
  DataIntegrationSchema,
  IntegrationConfigure,
  IntegrationConnect,
  IntegrationOptions,
} from 'types';

export const fetchIntegrations = (): Promise<DataIntegrationSchema> => api.get('/integration');

export const connectIntegration = (
  payload: ConnectIntegrationPayload,
): Promise<IntegrationConnect> => api.post(`/integration/connect`, payload);

export const disconnectIntegration = (type: IntegrationOptions): Promise<void> =>
  api.delete(`/integration/disconnect/${type}`);

export const configureIntegration = (
  payload: ConfigureIntegrationPayload,
): Promise<IntegrationConfigure> => api.post(`/integration/configure`, payload);

export const enableMapping = (
  payload: ConfigureIntegrationPayload,
): Promise<IntegrationConfigure> => api.post(`/integration/enable`, payload);

export const disableMapping = (payload: any) => api.post(`/integration/disable`, payload);
