import { Button, Modal } from '@demandscience/ui';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AxiosError } from 'axios';
import useMutateBouncesLists from 'components/Bounces/useMutateBouncesList';
import { useState } from 'react';

interface DeleteBounceOverlayProps {
  bounce_id: string;
  onClose: () => void;
  onSuccess?: () => void;
  open: boolean;
}

const DeleteBounceOverlay = ({ bounce_id, open, onClose, onSuccess }: DeleteBounceOverlayProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const { destroy } = useMutateBouncesLists();

  const onSubmit: SubmitHandler<{}> = async () => {
    setApiError(null);

    try {
      await destroy.mutateAsync({ bounce_id });

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <Modal
      aria-labelledby="delete-bounce-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      open={open}
      onClose={onClose}
    >
      <Modal.Title id="delete-bounce-dialog-title" className="flex mb-8">
        Are you sure you want to delete this file?
      </Modal.Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-4 mt-10">
          {apiError && <div className="text-error-500 text-center mt-8">{apiError}</div>}
        </div>

        <div className="flex justify-end space-x-4 mt-4">
          <Button type="button" borderless onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Delete file
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteBounceOverlay;
