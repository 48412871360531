import { truncate } from 'lodash';
import { TableColumn, CreditsActivity, CreditActivityAction, CreditActivityContext } from 'types';
import PreviousExportsDropdown from './PreviousExportsDropdown';
import {
  creditActivityActions,
  creditActivityContexts,
  creditActivityRecordTypes,
  UICreditActivityAction,
} from './utils';

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const columns: TableColumn<CreditsActivity>[] = [
  {
    field: 'created_at',
    headerName: 'Date',
    valueGetter: ({ created_at }: CreditsActivity) =>
      new Date(created_at).toLocaleDateString(undefined, dateOptions),
  },
  {
    field: 'name',
    headerName: 'Member',
    valueGetter: ({ account, action_context }: CreditsActivity) => {
      if (action_context === CreditActivityContext.Admin) {
        return 'admin';
      }

      return <span>{truncate(account?.name, { length: 36 })}</span>;
    },
  },
  {
    field: 'credits',
    headerName: 'Credits',
    valueGetter: ({ credits_consumed }: CreditsActivity) =>
      Number(credits_consumed).toLocaleString(),
  },
  {
    field: 'action',
    headerName: 'Action',
    valueGetter: ({ action, destination }: CreditsActivity) => {
      if (action === CreditActivityAction.Export) {
        const dest = destination === 'csv' ? 'csv' : 'crm';
        return creditActivityActions[`${action}_${dest}`];
      } else if (
        action === CreditActivityAction.AdminAdd ||
        action === CreditActivityAction.AdminRemove
      ) {
        return creditActivityActions[UICreditActivityAction.AdminAddRemove];
      }

      return creditActivityActions[action];
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    valueGetter: ({ record_type }: CreditsActivity) => creditActivityRecordTypes[record_type],
  },
  {
    field: 'context',
    headerName: 'Context',
    valueGetter: ({ action_context }: CreditsActivity) => creditActivityContexts[action_context],
  },
  {
    field: '',
    headerName: '',
    valueGetter: ({ action, destination, export_id, export_identifier }: CreditsActivity) => {
      if (
        action === CreditActivityAction.Export &&
        destination === 'csv' &&
        export_id &&
        export_identifier
      ) {
        return <PreviousExportsDropdown id={export_id} identifier={export_identifier} />;
      }

      return null;
    },
  },
];
