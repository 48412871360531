import { FC } from 'react';
import { Contact } from 'types';
import LayersIcon from '@demandscience/ui/icons/layers';
import ListItemText from 'components/Layout/ListItemText';
import { isEmpty } from 'lodash';
import { overrideTailwindClasses } from 'tailwind-override';
import classNames from 'classnames';

interface JobLevelProps {
  className?: string;
  inline?: boolean;
  row: Contact;
}

const JobLevel: FC<JobLevelProps> = ({ className, inline, row }) => {
  const { jobs } = row;

  if (isEmpty(jobs)) {
    return null;
  }

  // showing only the first job
  const [
    {
      details: [{ level: jobLevel }],
    },
  ] = jobs;

  if (!jobLevel) {
    return null;
  }

  if (inline) {
    return (
      <p className={overrideTailwindClasses(classNames('text-sm text-gray-700', className))}>
        {jobLevel}
      </p>
    );
  }

  return (
    <ListItemText
      leadingContent={<LayersIcon className="flex-shrink-0 w-5 h-5 text-gray-500" />}
      overline="Job level"
      title={jobLevel}
    />
  );
};

export default JobLevel;
