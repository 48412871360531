import { isArray, mergeWith } from 'lodash';
import {
  ViewLayout,
  ResultsView,
  CompaniesSort,
  defaultCompanyTableColums,
  ContactsSort,
  defaultContactTableColumns,
  SearchCustomization,
} from 'types';

export const DEFAULTS: SearchCustomization = {
  layout: {
    fullWidth: false,
    view: ViewLayout.List,
  },
  filters: {
    position: 'left',
    dense: false,
    width: 0.33,
  },
  results: {
    tab: ResultsView.Contacts,
    companiesSortField: CompaniesSort.Revenue,
    companiesTableColums: defaultCompanyTableColums,
    contactsSortField: ContactsSort.JobLevel,
    contactsTableColums: defaultContactTableColumns,
  },
  exports: {},
};

const mergeCustomizer = (objValue: any, srcValue: any) => {
  if (isArray(objValue)) {
    return srcValue || objValue;
  }

  return undefined;
};

export const mergeDefaults = (data?: SearchCustomization) =>
  mergeWith({}, DEFAULTS, data, mergeCustomizer);
