import { useCallback, useState } from 'react';
import { Modal, ModalProps } from '@demandscience/ui';

import { InviteResult as InviteResultType } from 'types';

import useOrganization from 'components/Organization/useOrganization';
import Form from './InviteForm';
import InviteResult from './InviteResult';
import SupportRequestControl from 'components/SupportRequest/SupportRequestControl';

interface InviteOverlayProps extends Omit<ModalProps, 'children'> {}

const InviteOverlay: React.FC<InviteOverlayProps> = ({ onClose, ...props }) => {
  const [result, setResult] = useState<InviteResultType[] | null>(null);
  const { data: organization } = useOrganization();
  const accountsAvailable = organization?.active_accounts?.available;

  const handleClearResult = useCallback(() => {
    setResult(null);
  }, []);

  const handleClose = useCallback(() => {
    handleClearResult();

    onClose();
  }, [handleClearResult, onClose]);

  return (
    <Modal
      aria-labelledby="invite-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      onClose={handleClose}
      {...props}
    >
      <Modal.Title id="invite-dialog-title" className="mb-4">
        Invite team members
      </Modal.Title>
      {result && <InviteResult data={result} onClear={handleClearResult} onClose={handleClose} />}
      {!result && accountsAvailable !== undefined && (
        <>
          {accountsAvailable !== 0 && (
            <Form accountsAvailable={accountsAvailable} onSuccess={setResult} onCancel={onClose} />
          )}
          {accountsAvailable === 0 && (
            <div className="space-y-4">
              <div className="text-sm text-gray-900">Upgrade your plan to add more seats...</div>
              <div className="flex justify-end space-x-2 mt-4">
                <SupportRequestControl action="changePlan" theme="primary" onClick={handleClose}>
                  Upgrade now
                </SupportRequestControl>
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default InviteOverlay;
