import { useCallback, useState } from 'react';

import { Button, ButtonOwnProps } from '@demandscience/ui';

import SavedSearchesSidebar from './SavedSearchesSidebar';

interface SavedSearchesSidebarControlProps extends ButtonOwnProps {
  as?: React.ElementType;
  children: React.ReactNode;
}

const SavedSearchesSidebarControl = ({
  as: Component = Button,
  ...props
}: SavedSearchesSidebarControlProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Component onClick={handleOpen} {...props} />
      <SavedSearchesSidebar open={open} onClose={handleClose} />
    </>
  );
};

export default SavedSearchesSidebarControl;
