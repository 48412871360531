import { Badge, Text } from '@demandscience/ui';
import Drawer from 'components/Drawer';
import { useContext, useState } from 'react';
import ChatHistory from './ChatHistory';
import FeatureFlagContext, {
  FeatureFlagContextInterface,
} from 'components/FeatureFlag/FeatureFlagContext';

const AiLauncher = () => {
  const featureFlagCtx = useContext(FeatureFlagContext) as FeatureFlagContextInterface;
  const [isOpened, setIsOpened] = useState(false);

  const handleShowModal = () => {
    setIsOpened(true);
  };

  const handleCloseModal = () => {
    setIsOpened(false);
  };

  if (!featureFlagCtx || !featureFlagCtx.isLabEnabled) {
    return null;
  }

  return (
    <>
      <div className="flex fixed top-1/2 right-0 z-[9999] bg-white">
        {isOpened ? null : (
          <div
            className="relative flex justify-center items-center w-9 h-36 border border-r-0 border-blue-500 rounded-tl-md rounded-bl-md shadow-lg drop-shadow-md cursor-pointer"
            onClick={handleShowModal}
          >
            <div className="flex flex-row space-x-3 items-center -rotate-90">
              <Text className="text-xs font-semibold">AIssistant</Text>
              <Badge label="LAB" color="amber" size="sm" className="text-xs font-thin" />
            </div>
          </div>
        )}
      </div>
      <Drawer
        id="aisistant_drawer"
        title={
          <div className="flex flex-row space-x-3 items-center">
            <Text className="text-xs font-semibold">AIssistant</Text>
            <Badge label="LAB" color="amber" size="sm" className="text-xs font-thin" />
          </div>
        }
        open={isOpened}
        onClose={handleCloseModal}
        anchor="right"
      >
        <ChatHistory />
      </Drawer>
    </>
  );
};

export default AiLauncher;
