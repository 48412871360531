import { Contact } from 'types';
import ListItemText from 'components/Layout/ListItemText';
import MapPinIcon from '@demandscience/ui/icons/map-pin';
import { isEmpty, join, compact } from 'lodash';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';

interface ContactLocationProps {
  className?: string;
  inline?: boolean;
  row: Contact;
}

const ContactLocation = ({ className, row, inline }: ContactLocationProps) => {
  const { locations } = row;

  if (isEmpty(locations)) {
    return null;
  }

  // displaying only 1st contact location for now (Nicolas)
  const [{ address_line_1, address_line_2, city, state, country }] = locations;

  let address = join(compact([city, state, country]), ', ');

  if (inline) {
    return <Paragraph className={className}>{address}</Paragraph>;
  }

  return (
    <ListItemText
      leadingContent={<MapPinIcon className="w-5 h-5" />}
      overline="Location"
      title={
        <>
          <div>{address_line_1}</div>
          <div>{address_line_2}</div>
          <div>{address}</div>
        </>
      }
    />
  );
};

export default ContactLocation;
