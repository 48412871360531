import Heading from 'layouts/Main/Heading';
import ArrowLeftIcon from '@demandscience/ui/icons/arrow-left';
import { memo } from 'react';
import BookMarkFilter from './BookMarkFilter';

import SelectionBar from './BookMarkSelectionBar';

const BookMarkHeader = ({
  handleNavigation,
  setPage,
  page,
  count,
  onChange,
  setShow,
  setName,
  allBookmarkIds,
  setSort,
  sort,
}) => {
  return (
    <>
      <div style={{ height: '3.125rem' }}>
        <Heading>Bookmarks</Heading>
      </div>

      <div className="mb-0 md:mt-0 md:mb-0 flex-shrink-0 flex flex-col md:items-center md:flex-row space-y-2 md:space-y-0 md:space-x-4">
        <div style={{ width: 'fit-content!important', marginRight: '1.25rem' }}>
          <Heading
            element={
              <button
                onClick={handleNavigation}
                className="flex items-center text-sm text-blue-500 hover:underline"
              >
                <ArrowLeftIcon className="w-4 h-4 mr-2" />
                Back to data profile
              </button>
            }
          />
        </div>
        <BookMarkFilter onChange={onChange} setShow={setShow} setName={setName}></BookMarkFilter>
      </div>
      <SelectionBar
        loading={false}
        paginationProps={{
          page: page,
          pageSize: 5,
          count: count,
          setPage: setPage,
        }}
        allBookmarkIds={allBookmarkIds}
        setSort={setSort}
        sort={sort}
      />
    </>
  );
};

export default memo(BookMarkHeader);
