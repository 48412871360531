import Tooltip, { TooltipProps } from 'components/Tooltip';
import { IconButton } from '@demandscience/ui';
import XIcon from '@demandscience/ui/icons/x';
import useElementById from 'hooks/useElementById';

export interface OnboardingTooltipProps extends Omit<TooltipProps, 'anchorEl' | 'children'> {
  anchorElId: string;
  button: React.ReactNode;
  info?: string;
  subtitle: string;
  title: string;
}

const OnboardingTooltip = ({
  anchorElId,
  title,
  subtitle,
  info,
  button,
  onClose,
  ...props
}: OnboardingTooltipProps) => {
  const element = useElementById(anchorElId);

  if (!element) {
    return null;
  }

  return (
    <>
      {props.open && (
        <div
          className="fixed inset-0"
          aria-hidden="true"
          data-testid="tooltipBackdrop"
          onClick={onClose}
        />
      )}
      <Tooltip
        anchorEl={element}
        rootClassName="z-10 w-full max-w-xs"
        className="bg-black p-3 rounded"
        {...props}
      >
        <IconButton
          className="float-right -mt-1"
          theme="default"
          round
          size="xs"
          onClick={onClose}
          Icon={XIcon}
          aria-label="Close"
        />
        <div className="text-xs font-semibold text-white mb-1">{title}</div>
        <div className="text-xs text-white">{subtitle}</div>
        <div className="flex flex-row-reverse mt-4 justify-between">
          {button}
          {info && <div className="text-xs text-white">{info}</div>}
        </div>
      </Tooltip>
    </>
  );
};

export default OnboardingTooltip;
