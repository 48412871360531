import { Spinner } from '@demandscience/ui';
import BillingEmail from 'components/Billing/BillingEmail';
import BillingDetails from 'components/Billing/BillingDetails';
import PreviousPurchases from 'components/Billing/PreviousPurchases';
import usePreviousPurchases from 'components/Billing/usePreviousPurchases';
import useBillingInfo from 'components/Billing/useBillingInfo';
import { hasIn } from 'lodash';

const defaultParams = {
  count: 4,
};

const Billing = () => {
  const { data, isLoading: isPurchasesLoading } = usePreviousPurchases(defaultParams);
  const { data: billingData, isLoading: isBillingLoading } = useBillingInfo();

  const isInvoicesNotNull = hasIn(data?.pages[0], 'invoices') && data?.pages[0]?.invoices !== null;
  const isBillingDetailsMissing = billingData === undefined;

  const isChargeBeeAccountMissing = isBillingDetailsMissing && !isInvoicesNotNull;
  const isDataLoading = isPurchasesLoading || isBillingLoading;

  return (
    <div className="flex flex-col gap-10">
      {isDataLoading && (
        <div className="flex justify-center">
          <Spinner size="sm" />
        </div>
      )}
      {!isChargeBeeAccountMissing && !isDataLoading && (
        <>
          <BillingEmail />
          <BillingDetails />
          <PreviousPurchases />
        </>
      )}
      {isChargeBeeAccountMissing && !isDataLoading && (
        <p className="text-gray-500 py-4 text-center">
          Billing for your company is managed by our support team, contact them if you have any
          question.
        </p>
      )}
    </div>
  );
};

export default Billing;
