import { downloadExport } from 'api/export';
import { DropdownMenu, IconButton } from '@demandscience/ui';
import DownloadIcon from '@demandscience/ui/icons/download';
import CsvIcon from '@demandscience/ui/icons/CSV';
import { useCallback } from 'react';

interface PreviousExportsDropdownProps {
  id: string;
  identifier: string;
}

const PreviousExportsDropdown = ({ id, identifier }: PreviousExportsDropdownProps) => {
  const handleClick = useCallback(async () => {
    try {
      await downloadExport(id, `${identifier}.csv`);
    } catch (e: any) {}
  }, [id, identifier]);

  return (
    <DropdownMenu
      buttonType="custom"
      customButton={
        <IconButton className="hover:bg-gray-100" size="sm" theme="default" Icon={DownloadIcon} />
      }
      origin="bottom-right"
    >
      <DropdownMenu.Item onClick={handleClick} className="flex items-center space-x-3">
        <CsvIcon size={18} />
        <span>Download CSV</span>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

export default PreviousExportsDropdown;
