import PasswordChangeEditor from 'components/Account/PasswordChangeEditor';
import AccountDeleteEditor from 'components/Account/AccountDeleteEditor';
import useAuth from 'components/Auth/useAuth';

const Password = () => {
  const { user } = useAuth();

  return (
    <div className="space-y-8">
      <div className="text-base font-medium mb-2">Login</div>
      <div className="max-w-lg">
        <PasswordChangeEditor impersonated={user?.impersonated} />
      </div>
      <div className="text-base font-medium mb-2">Account</div>
      <div className="max-w-lg">
        <AccountDeleteEditor impersonated={user?.impersonated} />
      </div>
    </div>
  );
};

export default Password;
