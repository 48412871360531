import { useState } from 'react';
import DataProfileContext from './DataProfileContext';

interface DataProfileProviderProps {
  children: React.ReactNode;
}

const DataProfileProvider = ({ children }: DataProfileProviderProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [mouseOverFilterId, setMouseOverFilterId] = useState<string | undefined>(undefined);
  const [mouseOverFilterFieldId, setMouseOverFilterFieldId] = useState<string | undefined>(
    undefined,
  );

  return (
    <DataProfileContext.Provider
      value={{
        isMouseOver,
        mouseOverFilterId,
        mouseOverFilterFieldId,
        setIsMouseOver,
        setMouseOverFilterId,
        setMouseOverFilterFieldId,
      }}
    >
      {children}
    </DataProfileContext.Provider>
  );
};

export default DataProfileProvider;
