import { useQuery } from '@tanstack/react-query';

import { fetchFilterData } from 'api/data';
import map from 'lodash/map';
import { FilterDataFieldName, RevenueRange } from 'types';
import { Option } from '../types';

const fetchOptions = async () => {
  const ranges = await fetchFilterData(FilterDataFieldName.RevenueRanges);
  const data = map(
    ranges,
    (value) => ({ id: value.rev_range, value, label: value.rev_range } as Option<RevenueRange>),
  );

  return data;
};

const useRevenueOptions = (options?: Record<string, any>) => {
  const query = useQuery(['filterOptions', FilterDataFieldName.RevenueRanges], fetchOptions, {
    refetchOnWindowFocus: false, // no need to refetch on window focus
    staleTime: Infinity,
    ...options,
  });

  return query;
};

export default useRevenueOptions;
