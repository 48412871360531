import { showList } from 'api/list';
import { useQuery } from '@tanstack/react-query';

const useList = (id: string, options?: { refetchInterval: number | false }) => {
  const query = useQuery(['lists', id], () => showList(id), {
    staleTime: 60 * 60 * 1000, // 1 hour
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export default useList;
