import Section, { SectionProps } from 'components/Layout/Section';

import useRecentSearches from './useRecentSearches';

import RecentSearchesShortList from './RecentSearchesShortList';
import { Skeleton } from '@demandscience/ui';
import ListItemSkeleton from 'components/Lists/Skeletons/ListItemSkeleton';
import { isEmpty } from 'lodash';

const RecentSearchesSection = (props: SectionProps) => {
  const query = useRecentSearches();
  const { data, isLoading } = query;

  if (isLoading) {
    return (
      <Section title={<Skeleton variant="text" height={5} width={28} className="bg-gray-200" />}>
        <div className="divide-y">
          <ListItemSkeleton count={3} />
        </div>
      </Section>
    );
  } else if (isEmpty(data)) {
    return null;
  }

  return (
    <Section {...props}>
      <RecentSearchesShortList query={query} />
    </Section>
  );
};

export default RecentSearchesSection;
