import useSelection from 'components/DataGrid/Selection/useSelection';
import ActionMenuItems from 'components/ListRecords/ActionMenuItems';
import useActions from 'components/ListRecords/useActions';

const ListRecordsActionMenuItems = () => {
  const listActionsContext = useActions();
  const { exportData } = useSelection();

  if (!listActionsContext) {
    return null;
  }

  return <ActionMenuItems exportData={exportData} />;
};

export default ListRecordsActionMenuItems;
