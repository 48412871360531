import { ModelType, Visibility } from 'types';
import useModal from 'hooks/useModal';
import { Button } from '@demandscience/ui';
import CreateListOverlay from './CreateListOverlay';
import { ElementType, ReactElement, ReactNode } from 'react';

interface CreateListControlProps {
  as?: ElementType;
  borderless: boolean;
  children: ReactNode;
  className?: string;
  leftIcon: ReactElement;
  onSuccess?: (id: string, visibility: Visibility) => void;
  size?: string;
  theme?: string;
  type?: ModelType;
}

const CreateListControl = ({
  as: Component = Button,
  type,
  onSuccess,
  ...props
}: CreateListControlProps) => {
  const { open, openModal, closeModal } = useModal();

  return (
    <>
      <Component type="button" onClick={openModal} {...props} />
      <CreateListOverlay open={open} onClose={closeModal} onSuccess={onSuccess} type={type} />
    </>
  );
};

export default CreateListControl;
