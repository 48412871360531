import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchAutocomplete } from 'api/search';
import size from 'lodash/size';
import map from 'lodash/map';

import { AutocompleteFieldName, AutocompleteParams } from 'types';
import { Option } from '../types';

const fetchOptions = async (params: AutocompleteParams) => {
  const intents = await fetchAutocomplete(AutocompleteFieldName.Intent, params);
  const data = map(intents, (value) => {
    const { topic } = value;

    return {
      id: topic,
      value,
      label: topic,
    } as Option<(typeof intents)[number]>;
  });

  return data;
};

const useIntentAutocomplete = (params: AutocompleteParams, options?: Record<string, any>) => {
  const query = useInfiniteQuery(
    ['suggestions', AutocompleteFieldName.Intent, params],
    ({ pageParam: index = 0 }) => fetchOptions({ ...params, index }),
    {
      refetchOnWindowFocus: false, // no need to refetch on window focus
      staleTime: 12 * 60 * 60 * 1000, // 12 hours
      enabled: size(params.value) >= 3, // api endpoints requires min 3 characters
      getNextPageParam: (lastPage: any, pages) =>
        size(lastPage) === (params.count ?? 20) ? size(pages) : undefined,
      ...options,
    },
  );

  return query;
};

export default useIntentAutocomplete;
