import { createContext } from 'react';
import { AdvancedSelection, ExportData, Model, ModelId } from 'types';

export interface SelectionContextInterface {
  add: (row: Model | Model[]) => void;
  batchSelection?: true;
  clearAll: () => void;
  exportData: ExportData;
  isSelected: (row: ModelId, index: number) => boolean;
  limit?: AdvancedSelection['limit'];
  remove: (row: ModelId | ModelId[]) => void;
  selectAll: () => void;
  selection: Model[] | [];
  selectionCount: number;
  selectionDisabled?: boolean;
  setLimit: (limit?: AdvancedSelection['limit']) => void;
  toggleViewSelected: () => void;
  viewSelected: boolean;
}

const SelectionContext = createContext<SelectionContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  SelectionContext.displayName = 'SelectionContext';
}

export default SelectionContext;
