import { useMutation } from '@tanstack/react-query';

import * as organizationApi from 'api/organization';

const useMutateSubscriptionPlan = (options?: Record<string, any>) => {
  const changePlan = useMutation(organizationApi.changePlan, {
    ...options,
  });

  const acknowledgeSubscription = useMutation(organizationApi.acknowledgeSubscription, {
    ...options,
  });

  return { changePlan, acknowledgeSubscription };
};

export default useMutateSubscriptionPlan;
