import GridIcon from '@demandscience/ui/icons/grid';
import {
  AggregateField,
  ContactFilterName,
  Filter,
  FilterName,
  Filters,
  JobFunctionFilter,
  ModelType,
} from 'types';
import useFieldAggregate from 'hooks/useFieldAggregate';
import Aggregate from 'components/DataProfile/Aggregate';
import { isEmpty } from 'lodash';
import { getJobFunctionLabel } from 'components/Filters/utils';
import { useCallback } from 'react';

interface JobFunctionAggregateProps {
  filters: Filters;
  setFilter: (name: FilterName, value?: Filter) => void;
}

const JobFunctionAggregate = ({ filters, setFilter }: JobFunctionAggregateProps) => {
  const { aggregate, isFetching } = useFieldAggregate(
    ModelType.Contact,
    AggregateField.JobFunction,
    filters,
  );
  const { [ContactFilterName.JobFunction]: filter = [] } = filters;

  const handleFilterChange = useCallback(
    (value: JobFunctionFilter) => {
      setFilter(ContactFilterName.JobFunction, isEmpty(value) ? undefined : value);
    },
    [setFilter],
  );

  return (
    <Aggregate
      id="dashboard_data_profile_job_function_filter"
      label="Department"
      icon={GridIcon}
      value={filter}
      onChange={handleFilterChange}
      loading={isFetching}
      aggregate={aggregate}
      getLabel={getJobFunctionLabel}
      mouseOverFilterId={ContactFilterName.JobFunction}
      aggregateField={AggregateField.JobFunction}
    />
  );
};

export default JobFunctionAggregate;
