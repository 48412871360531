import { Notification, NotificationProps } from '@demandscience/ui';

import Counter from './Counter';

const NotificationsBadge = (props: NotificationProps) => (
  <Counter>
    {({ count }) => {
      if (count > 0) {
        return <Notification {...props} />;
      }

      return props.children;
    }}
  </Counter>
);

export default NotificationsBadge;
