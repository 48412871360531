import { ListParams, Show } from 'types';
import { fetchUserLists } from 'api/account';
import { useQuery } from '@tanstack/react-query';
import { useTracking } from 'react-tracking';
import { DataLayerData } from 'types/tracking';
import { isEqual } from 'lodash';

export const useLists = (
  params: ListParams,
  options?: { enabled?: boolean; refetchInterval?: number | false },
) => {
  const { trackEvent } = useTracking<DataLayerData>({ type: 'event', event: 'user_info' });

  const query = useQuery(['lists', 'search', params], () => fetchUserLists(params), {
    staleTime: 60 * 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (isEqual(params.filter, { show: Show.All })) {
        trackEvent({
          countLists: data.count,
        });
      }
    },
    ...options,
  });

  return query;
};
