import { Badge } from '@demandscience/ui';

import map from 'lodash/map';

import { Filters, FilterName } from 'types/filters';
import Icon from './FilterIcon';
import Label from './FilterLabel';
import { getLabelsWithExcludeInfo } from '../Filters/utils';

interface FilterInfoProps {
  data: Filters;
}

const FilterInfo: React.FC<FilterInfoProps> = ({ data }) => (
  <div className="space-y-4 text-gray-500">
    {map(data, (value, name: FilterName) => {
      let labels = getLabelsWithExcludeInfo(name, value);

      return (
        <div key={name} className="flex flex-row gap-6">
          <div className="flex-shrink-0">
            <Icon name={name} className="w-5 h-5" />
          </div>
          <div className="flex-1">
            <Label name={name} className="text-xs mb-1" />
            <div className="flex flex-wrap gap-2">
              {map(labels, ({ label, exclude }, i) => (
                <Badge key={i} size="sm" color={exclude ? 'rose' : 'emerald'}>
                  {label}
                </Badge>
              ))}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export default FilterInfo;
