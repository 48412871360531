import { useCallback, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Button, Checkbox, RadioList } from '@demandscience/ui';

import map from 'lodash/map';
import { capitalize } from 'lodash';

import {
  ResultsView,
  SavedSearch,
  CreateSavedSearchFormValues,
  Visibility,
  EditSavedSearchFormValues,
  CreateSavedSearchPayload,
  UpdateSavedSearchPayload,
  CreateListFormValues,
  EditListFormValues,
} from 'types';
import { Filters } from 'types/filters';

import Combobox from './Combobox';
import FormFields from './FormFields';
import useMutateSavedSearch from './useMutateSavedSearch';
import MessageInputController from 'components/Input/MessageInputController';
import { AxiosError } from 'axios';

const options = [
  { value: 'create', label: 'Save as new search', id: 'radio_new' },
  { value: 'update', label: 'Update existing search', id: 'radio_update' },
];

interface SaveFormProps {
  currentView: ResultsView;
  filters: Filters;
  onCancel: () => void;
  onSuccess: () => void;
}

const SaveForm = ({ onSuccess, onCancel, filters, currentView }: SaveFormProps) => {
  const [selected, setSelected] = useState('create');
  const [notify, setNotify] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState<SavedSearch | undefined>();
  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit, formState, watch, setValue, setError } = useForm<
    CreateSavedSearchFormValues | EditSavedSearchFormValues
  >({
    defaultValues: { name: '', description: '', visibility: Visibility.Private },
  });
  const { isSubmitting } = formState;
  const { update, create } = useMutateSavedSearch();

  const handleSelect = useCallback((value: string) => {
    setSelected(value);

    setApiError(null);
  }, []);

  const handleSelectSearch = useCallback((value: SavedSearch) => {
    setSelectedSearch(value);
  }, []);

  const handleAddMessageClick = useCallback((checked: boolean) => {
    setNotify(checked);
  }, []);

  const handleError = (e: Error) => {
    if (e instanceof AxiosError) {
      const { error_field, error } = e.response?.data;

      if (error_field !== undefined) {
        setError(error_field, { type: 'api', message: capitalize(error) });
        return;
      }

      const { message } = e;
      setApiError(message);
    } else {
      setApiError('Unexpected error, please try again later');
    }
  };

  const onSubmit: SubmitHandler<CreateSavedSearchFormValues | EditSavedSearchFormValues> = async ({
    members,
    notification_message,
    ...data
  }) => {
    setApiError(null);

    try {
      const visibility = data.visibility;

      if (selected === 'create') {
        let payload = { ...data, filters, current_view: currentView } as CreateSavedSearchPayload;

        // when shared publicly (to whole organization) include notification message
        if (
          (visibility === Visibility.Public || visibility === Visibility.Restricted) &&
          notification_message
        ) {
          payload = { notification_message, ...payload };
        }

        if (visibility === Visibility.Restricted) {
          const share_with = members?.map(({ value }) => value);

          payload = { share_with, ...payload };
        }

        await create.mutateAsync(payload);
      } else if (selectedSearch && selected === 'update') {
        let payload = {
          ...selectedSearch,
          filters,
          current_view: currentView,
        } as UpdateSavedSearchPayload;

        // when shared include notify flag if selected and message if provided
        if (
          notify &&
          (selectedSearch?.visibility === Visibility.Public ||
            selectedSearch?.visibility === Visibility.Restricted)
        ) {
          payload = { ...payload, notify: true };
          if (notification_message) {
            payload = { ...payload, notification_message };
          }
        }

        await update.mutateAsync(payload);
      }

      if (onSuccess) onSuccess();
    } catch (e: any) {
      handleError(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        <RadioList value={selected} onChange={handleSelect} options={options}>
          <div className="space-y-4">
            {map(options, ({ id, ...option }) => (
              <div id={id}>
                <RadioList.Option key={option.value} option={option}>
                  <RadioList.Icon
                    className="mr-2"
                    theme="primary"
                    checked={option.value === selected}
                  />
                  <RadioList.Label className="text-md">{option.label}</RadioList.Label>
                </RadioList.Option>
              </div>
            ))}
          </div>
        </RadioList>

        {selected === 'create' && (
          <FormFields control={control} formState={formState} watch={watch} setValue={setValue} />
        )}

        {selected === 'update' && (
          <div>
            <Combobox
              placeholder="Select search to update"
              value={selectedSearch}
              onChange={handleSelectSearch}
            />
            {selectedSearch && selectedSearch.visibility !== Visibility.Private && (
              <div
                id="checkbox_notify"
                className="flex items-center text-sm text-gray-700 pt-6 pb-4"
              >
                <Checkbox
                  checked={notify}
                  onChange={handleAddMessageClick}
                  label="Notify people"
                  size="sm"
                />
              </div>
            )}
            {notify && (
              <div id="input_message">
                <MessageInputController<CreateListFormValues | EditListFormValues>
                  name="notification_message"
                  control={control}
                />
              </div>
            )}
          </div>
        )}

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button
          type="submit"
          theme="primary"
          disabled={isSubmitting || (selected === 'update' && !selectedSearch)}
        >
          {selected === 'update' ? 'Update search' : 'Save search'}
        </Button>
      </div>
    </form>
  );
};

export default SaveForm;
