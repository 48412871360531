import { FC } from 'react';
import classNames from 'classnames';

export interface CopyrightProps {
  className?: string;
}

const Copyright: FC<CopyrightProps> = ({ className }) => (
  <div className={classNames(className, 'text-sm text-gray-500 text-center')}>
    {'Copyright © '}
    {/* As www.leadiro.com is diverting to www.demandscience.com */}
    <a className="color-inherit" href="https://demandscience.com/">
      Klarity
    </a>{' '}
    {new Date().getFullYear()}
    {'.'}
  </div>
);

export default Copyright;
