import { memo, useCallback } from 'react';
import useTabs from '../Tabs/useTabs';
import { Button, Tabs } from '@demandscience/ui';
import { ResultsView, KlarityFeature } from 'types';
import Searches from './Searches';
import SearchProvider from 'components/Search/SearchProvider';
import SavedSearchesSidebarControl from 'components/SavedSearches/SavedSearchesSidebarControl';
import DataProfileDropdownActions from './DataProfileDropdownActions';
import DataProfileViews from 'components/DataProfileViews';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

const EmptyResultTabs = memo(() => {
  const { tab, setTab, getTabName, selectedIndex } = useTabs();
  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );

  const handleChange = useCallback(
    (index: number) => {
      setTab(getTabName(index));
    },
    [setTab, getTabName],
  );

  return (
    <div className="flex flex-col h-full isolate">
      <Tabs selectedIndex={selectedIndex} onChange={handleChange}>
        <div className="h-20 pt-3 border-b flex-shrink-0 flex flex-row items-center gap-2">
          <Tabs.List id="dashboard_search_tabs" className="self-end">
            {!isContactRenderDisabled && <Tabs.Item id="tab_contacts">Contacts</Tabs.Item>}
            <Tabs.Item id="tab_companies">Companies</Tabs.Item>
            <Tabs.Item id="tab_data_profile">
              <span>Data&nbsp;Profile</span>
              {tab === ResultsView.DataProfile && <DataProfileDropdownActions />}
            </Tabs.Item>
          </Tabs.List>
          <div id="dashboard_search_tabs_spacing" className="grow self-end" />
          <div className="flex flex-row gap-4 items-start">
            <SavedSearchesSidebarControl
              as={Button}
              id="action_open_search"
              className="whitespace-nowrap"
              size="sm"
              theme="primary"
              outline
            >
              Open search
            </SavedSearchesSidebarControl>
          </div>
        </div>
        <Tabs.Panels className="grow overflow-y-auto">
          {!isContactRenderDisabled && (
            <Tabs.Panel unmount>
              <Searches />
            </Tabs.Panel>
          )}
          <Tabs.Panel unmount>
            <Searches />
          </Tabs.Panel>
          <Tabs.Panel unmount>
            <SearchProvider>
              <DataProfileViews setTab={setTab} />
            </SearchProvider>
          </Tabs.Panel>
        </Tabs.Panels>
      </Tabs>
    </div>
  );
});

EmptyResultTabs.displayName = 'EmptyResultTabs';

export default EmptyResultTabs;
