import { Company } from 'types';
import classNames from 'classnames';
import { Avatar, AvatarProps } from '@demandscience/ui';
import { getBgColor } from 'components/Contact/ContactAvatar';
import useImage from 'hooks/useImage';

export interface CompanyAvatarProps extends AvatarProps {
  className?: string;
  colored?: boolean;
  row: Company;
}

const CompanyAvatar = ({ row, className, colored, ...props }: CompanyAvatarProps) => {
  const { company_name: cName, logo_url } = row;
  const { loading, loaded } = useImage(logo_url);

  return (
    <Avatar
      className={classNames('text-xs w-6 h-6 text-gray-600', className, {
        'bg-gray-200 hover:bg-gray-200': !colored,
        [getBgColor(cName)]: colored,
        invisible: loading,
      })}
      src={loaded ? logo_url : undefined}
      alt={cName}
      {...props}
    />
  );
};

export default CompanyAvatar;
