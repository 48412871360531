import { useCallback, useEffect, useState } from 'react';

const useModal = (defaultOpen: boolean | undefined = false) => {
  const [open, setOpen] = useState(defaultOpen);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true);
    }
  }, [defaultOpen]);

  return {
    open,
    openModal,
    closeModal,
  };
};

export default useModal;
