import { useMutation } from '@tanstack/react-query';
import { createSubscription } from 'api/organization';

const useMutateSubscriptions = (options?: Record<string, any>) => {
  const create = useMutation(createSubscription, {
    onSuccess: () => {},
    ...options,
  });

  return {
    create,
  };
};

export default useMutateSubscriptions;
