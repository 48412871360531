import TeamName from './TeamName';
import MembersDetails from './MembersDetails';

const Team = () => {
  return (
    <div className="flex flex-col gap-10">
      <TeamName />
      <MembersDetails />
    </div>
  );
};

export default Team;
