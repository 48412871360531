import classNames from 'classnames';
import { DropdownMenu, IconButton } from '@demandscience/ui';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';

import { RecentSearch } from 'types';
import useModal from 'hooks/useModal';
import DeleteOverlay from './DeleteOverlay';
import SaveOverlay from '../SavedSearches/SaveOverlay';

interface RecentSearchDropdownProps {
  className?: string;
  data: RecentSearch;
}

const RecentSearchDropdown = ({ className, data, ...props }: RecentSearchDropdownProps) => {
  const { open: openSave, openModal: openSaveModal, closeModal: closeSaveModal } = useModal();
  const { open: openDelete, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  return (
    <>
      <DropdownMenu
        buttonType="custom"
        customButton={
          <IconButton
            className="hover:bg-gray-100"
            size="sm"
            theme="default"
            Icon={MoreVerticalIcon}
          />
        }
        className={classNames('grid place-items-center', className)}
        origin="bottom-right"
        {...props}
      >
        <DropdownMenu.Item onClick={openSaveModal}>Save</DropdownMenu.Item>
        <DropdownMenu.Item onClick={openDeleteModal}>Delete</DropdownMenu.Item>
      </DropdownMenu>
      <SaveOverlay
        filters={data?.filters}
        currentView={data?.current_view}
        open={openSave}
        onClose={closeSaveModal}
      />
      <DeleteOverlay data={data} open={openDelete} onClose={closeDeleteModal} />
    </>
  );
};

export default RecentSearchDropdown;
