import useModal from 'hooks/useModal';
import { Button } from '@demandscience/ui';
import { ElementType, ReactElement, ReactNode } from 'react';
import EmailConfirmOverlay from './EmailConfirmOverlay';

interface UploadBouncesListControlProps {
  as?: ElementType;
  borderless?: boolean;
  children: ReactNode;
  className?: string;
  confirmedNewEmail?: string;
  disabled?: boolean;
  isSubmitting?: boolean;
  leftIcon?: ReactElement;
  setEdit: (state: boolean) => void;
  size?: string;
  theme?: string;
}

const EmailEditorControl = ({
  as: Component = Button,
  confirmedNewEmail,
  isSubmitting,
  setEdit,
  ...props
}: UploadBouncesListControlProps) => {
  const { open, openModal, closeModal } = useModal();

  return (
    <>
      <Component onClick={openModal} type="button" {...props} />
      <EmailConfirmOverlay
        open={open}
        onClose={closeModal}
        newEmail={confirmedNewEmail}
        isSubmitting={isSubmitting}
        setEdit={setEdit}
      />
    </>
  );
};

export default EmailEditorControl;
