import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@demandscience/ui';

import {
  EditSavedSearchFormValues,
  SavedSearch,
  UpdateSavedSearchPayload,
  Visibility,
} from 'types';
import PersonaFormFields from './PersonaFormFields';
import useMutatePersonas from './useMutatePersonas';
import useAuth from 'components/Auth/useAuth';
import { filter, some, omit, pick, map, capitalize } from 'lodash';
import { AxiosError } from 'axios';

interface EditPersonaFormProps {
  data?: SavedSearch;
  onCancel: () => void;
  onSuccess: () => void;
}

const EditPersonaForm = ({ data: defaultData, onSuccess, onCancel }: EditPersonaFormProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { control, handleSubmit, formState, watch, setError, setValue } =
    useForm<EditSavedSearchFormValues>({
      defaultValues: {
        ...pick(defaultData, ['name', 'description', 'visibility']),
        members: map(
          defaultData?.shared_with,
          ({ username: value, email, name: label = email }) => ({
            value,
            label,
          }),
        ),
      },
    });
  const { isSubmitting } = formState;
  const { update } = useMutatePersonas();
  const { user } = useAuth();

  const buildPayload = ({ members, notification_message, ...data }: EditSavedSearchFormValues) => {
    const visibility = data.visibility;

    let payload: UpdateSavedSearchPayload = {
      ...omit(defaultData, 'shared_with', 'account', 'created_at'),
      ...data,
    };

    if (
      (visibility === Visibility.Public || visibility === Visibility.Restricted) &&
      notification_message
    ) {
      payload = { notification_message, ...payload };
    }

    if (visibility === Visibility.Restricted) {
      const share_with = map(
        filter(members, ({ value }) => !some(defaultData?.shared_with, { username: value })),
        'value',
      );

      const unshare_with = map(
        filter(defaultData?.shared_with, ({ username: value }) => !some(members, { value })),
        'username',
      );

      payload = { share_with, unshare_with, ...payload };
    }

    return payload;
  };

  const onSubmit: SubmitHandler<EditSavedSearchFormValues> = async (data) => {
    setApiError(null);

    try {
      const payload = buildPayload(data);

      await update.mutateAsync(payload);

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        const { error_field, error } = e.response?.data;

        if (error_field !== undefined) {
          setError(error_field, { type: 'api', message: capitalize(error) });
          return;
        }

        const { message } = e;
        setApiError(message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        <PersonaFormFields
          className="mt-2"
          control={control}
          formState={formState}
          watch={watch}
          setValue={setValue}
          editingSomeoneElsesPublicSearch={
            user?.username !== defaultData?.owner.username &&
            defaultData?.visibility === Visibility.Public
          }
          edit
        />

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-4">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button type="submit" theme="primary" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </form>
  );
};

export default EditPersonaForm;
