import { Button, Modal, ModalProps, Text } from '@demandscience/ui';

interface MessageOverlayProps extends Omit<ModalProps, 'children'> {
  message: string;
}

const MessageOverlay = ({ open, message, ...props }: MessageOverlayProps) => {
  const { onClose } = props;

  return (
    <Modal
      aria-labelledby="bounce-message-overlay"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      open={open}
      {...props}
    >
      <Modal.Title id="bounce-message-overlay" className="flex mb-4">
        Message from Klarity
      </Modal.Title>
      <Text variant="body2" className="text-justify pt-4 font-light">
        {message}
      </Text>
      <div className="flex justify-end space-x-2 mt-4">
        <Button onClick={onClose} type="button">
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default MessageOverlay;
