import { ModelType } from 'types';
import useModal from 'hooks/useModal';
import { Button } from '@demandscience/ui';
import { ElementType, ReactElement, ReactNode } from 'react';
import UploadListOverlay from './UploadListOverlay';

interface UploadListControlProps {
  as?: ElementType;
  borderless?: boolean;
  children: ReactNode;
  className?: string;
  leftIcon: ReactElement;
  size?: string;
  theme?: string;
  type?: ModelType;
}

const UploadListControl = ({ as: Component = Button, type, ...props }: UploadListControlProps) => {
  const { open, openModal, closeModal } = useModal();

  return (
    <>
      <Component onClick={openModal} {...props} />
      <UploadListOverlay open={open} onClose={closeModal} type={type} />
    </>
  );
};

export default UploadListControl;
