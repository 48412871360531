import { createContext } from 'react';
import { Filters, Filter, FilterName, CompanyFilters } from 'types/filters';

export interface FiltersContextInterface {
  clearAll: () => void;
  companyFilters: CompanyFilters;
  filterCleared: boolean;
  filters: Filters;
  hasRedo: boolean;
  hasUndo: boolean;
  redoFilter: () => void;
  resetHistory: (filters: Filters) => void;
  setFilter: (name: FilterName, value?: Filter) => void;
  setFilterCleared: (cleared: boolean) => void;
  setFilters: (filters: Filters) => void;
  undoFilter: () => void;
}

const FiltersContext = createContext<FiltersContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  FiltersContext.displayName = 'FiltersContext';
}

export default FiltersContext;
