import { Link } from '@demandscience/ui';
import { ListFilter, Show } from 'types';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import Paragraph from 'components/Typography/Paragraph';

interface ListsEmptyResultsProps {
  filter: ListFilter;
  onClearFilters: () => void;
  onClick: () => void;
}

const ListsEmptyResults = ({ filter, onClick, onClearFilters }: ListsEmptyResultsProps) => {
  const { name, show, records_type, records_origin } = filter;

  const emptyFilters = !name && show === Show.All && !records_type && !records_origin;

  return (
    <>
      {emptyFilters && (
        <EmptyResults
          message="You haven't created any list yet"
          illustration={<Illustration.EmptyAlt className="w-36 h-36 mb-6" />}
          className="mt-36"
        >
          <Paragraph className="text-gray-500 mt-1 text-center whitespace-pre-line">
            Lists are a great way to save and organize contacts or companies of interest.
          </Paragraph>
          <div className="flex items-center mt-4">
            <Link aria-label="Create a list" onClick={onClick}>
              Create your first list now
            </Link>
          </div>
        </EmptyResults>
      )}
      {!emptyFilters && (
        <EmptyResults
          message="No list found matching the criteria."
          illustration={<Illustration.Empty className="w-36 h-36 mb-6" />}
          className="mt-36"
        >
          <Paragraph className="text-gray-500 mt-1 text-center whitespace-pre-line">
            <Link onClick={onClearFilters}>See all your lists</Link>
          </Paragraph>
        </EmptyResults>
      )}
    </>
  );
};

export default ListsEmptyResults;
