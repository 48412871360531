import { useNavigate } from 'react-router-dom';
import { Button, Link, NotificationCard, SharedItem } from '@demandscience/ui';
import ListIcon from '@demandscience/ui/icons/list-02';
import BookmarkIcon from '@demandscience/ui/icons/bookmark';
import TimeAgo from 'react-timeago';

import Avatar from 'components/Avatar';
import SystemAvatar from './SystemAvatar';
import { Notification as NotificationType } from 'types';
import useCreditsContext from 'components/Credits/useCreditsContext';
import { fetchSavedSearch } from 'api/savedSearch';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { downloadExport } from 'api/export';
import useAuth from 'components/Auth/useAuth';
import useNotificationsContext from './useNotificationsContext';
import { useCallback } from 'react';
import { getMessage, getTitle, getUserName } from './utils';
import BuyCreditsButton from 'components/Organization/BuyCreditsButton';
import { useLocalStorage } from 'react-use';
import { downloadExportfromNotification } from 'api/bookmark';

interface NotificationProps {
  markAsRead: UseMutationResult<void, unknown, string, unknown>;
  notification: NotificationType;
}

const Notification = ({ notification, markAsRead }: NotificationProps) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const { closeNotifications } = useNotificationsContext();
  const [localUnreadNotifications, setLocalUnreadNotifications] = useLocalStorage<
    NotificationType[]
  >('KlarityUnreadNotifications', []);
  const { acceptCreditsRequest, rejectCreditsRequest } = useCreditsContext();
  const isCreditsRequest = notification.type === 'credit' && notification.event === 'request';

  const getContent = () => {
    if (notification.type === 'list') {
      if (notification.event === 'share') {
        return (
          <button onClick={handleOpenList}>
            <SharedItem
              title={notification.details?.list_name as string}
              color="amber"
              icon={ListIcon}
            />
          </button>
        );
      }
    } else if (notification.type === 'saved_search') {
      if (notification.event === 'share') {
        return (
          <button onClick={handleOpenSavedSearch}>
            <SharedItem
              title={notification.details?.saved_search_name as string}
              color="emerald"
              icon={BookmarkIcon}
            />
          </button>
        );
      }
    } else if (notification.type === 'export') {
      if (notification.event === 'download') {
        return (
          <Button size="sm" theme="primary" onClick={handleDownloadFile}>
            Download
          </Button>
        );
      }
    } else if (notification.type === 'bounce') {
      if (notification.event === 'resolve') {
        return <Link onClick={handleBounceFile}>Find it here</Link>;
      }
    } else if (notification.type === 'credit') {
      if (notification.event === 'decrease' || notification.event === 'expiry') {
        return <BuyCreditsButton size="sm" />;
      }
    } else if (notification.type === 'welcome') {
      return <BuyCreditsButton size="sm" />;
    } else if (notification.type === 'bookmark_export') {
      if (notification.event === 'download') {
        return (
          <Button size="sm" theme="primary" onClick={handleDownloadFile}>
            Download
          </Button>
        );
      }
    }

    return null;
  };

  const handleOpenList = () => {
    closeNotifications();

    navigate(`/lists/${notification.details?.list_id}`);
  };

  const handleBounceFile = () => {
    closeNotifications();

    navigate(`/manage/bounces`);
  };

  const handleOpenSavedSearch = async () => {
    closeNotifications();

    try {
      // need to fetch saved search to get related info
      const search = await fetchSavedSearch(notification.details?.saved_search_id as string);
      const { filters, current_view } = search;

      navigate('/search', { state: { filters, tab: current_view } });
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 404) {
          showMessage('Given search does not exist any more', 'error');
        } else {
          showMessage('Unable to load search data', 'error');
        }
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  };

  const handleDownloadFile = async () => {
    const id = notification.details?.export_id;

    const bookMarkId = notification?.details?.bookmark_export_id;

    if (id) {
      const filename = notification.details?.file_name;

      await downloadExport(id, filename || 'export-data.csv');
    }

    if (bookMarkId) {
      const filename = notification.details?.file_name;

      await downloadExportfromNotification(bookMarkId, filename);
    }
  };

  const handleMarkAsRead = useCallback(() => {
    markAsRead.mutate(notification.id);
    setLocalUnreadNotifications(() =>
      localUnreadNotifications?.filter(
        (localNotification) => localNotification.id !== notification.id,
      ),
    );
  }, [markAsRead, notification.id, localUnreadNotifications, setLocalUnreadNotifications]);

  const handleAcceptCreditsRequest = useCallback(() => {
    acceptCreditsRequest(notification);
  }, [acceptCreditsRequest, notification]);

  const handleRejectCreditsRequest = useCallback(() => {
    rejectCreditsRequest(notification);
  }, [notification, rejectCreditsRequest]);

  return (
    <NotificationCard
      leadingContent={
        notification.issued_by ? (
          <Avatar className="w-8 h-8" user={notification.issued_by} />
        ) : (
          <SystemAvatar />
        )
      }
      user={getUserName(notification, user)}
      title={getTitle(notification)}
      content={getContent()}
      optionalText={getMessage(notification)}
      notifiedOn={<TimeAgo date={notification.created_at} />}
      read={!!notification.read_at}
      onCheckClick={handleMarkAsRead}
      showAcceptButton={isCreditsRequest}
      onAccept={isCreditsRequest ? handleAcceptCreditsRequest : undefined}
      showRejectButton={isCreditsRequest}
      onReject={isCreditsRequest ? handleRejectCreditsRequest : undefined}
    />
  );
};

export default Notification;
