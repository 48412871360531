export const formatPrice = (amount: number, currency: string = 'USD') => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0,
  });

  return formatter.format(amount / 100);
};
