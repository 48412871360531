import { DataGrid, Link } from '@demandscience/ui';
import { columns } from './PreviousPurchasesTableColumns';
import { RowItem } from '@demandscience/ui/dist/types';
import { flatten, isEmpty, map, hasIn } from 'lodash';
import PreviousPurchasesSkeleton from './PreviousPurchasesSkeleton';
import usePreviousPurchases from './usePreviousPurchases';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import { useCallback } from 'react';

const defaultParams = {
  count: 4,
};

const PreviousPurchases = () => {
  const { data, isLoading, isFetching, isError, hasNextPage, fetchNextPage } =
    usePreviousPurchases(defaultParams);

  const isInvoicesNotNull = hasIn(data?.pages[0], 'invoices') && data?.pages[0]?.invoices !== null;

  const rows = isInvoicesNotNull && flatten(map(data?.pages, 'invoices'));

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  let children;

  if (isError) {
    children = <div className="text-sm text-error-500">Unexpected error loading billing data</div>;
  } else if (isLoading || (isEmpty(data.pages) && isFetching)) {
    children = <PreviousPurchasesSkeleton count={5} />;
  } else if (isEmpty(data.pages) && !isFetching) {
    children = (
      <EmptyResults
        message="No purchase made so far."
        illustration={<Illustration.Empty className="w-36 h-36 mb-6" />}
      />
    );
  } else {
    children = (
      <div className="flex flex-col gap-4">
        <p className="text-lg text-gray-700 font-medium">Previous purchases</p>
        <div className="flex-1 isolate">
          {!isEmpty(rows) && !isFetching && (
            <>
              <DataGrid
                columns={columns}
                rows={rows as RowItem[]}
                loading={isFetching}
                uniqueIdentifier="invoice_id"
                disableInternalComputation
                containerClassName="overflow-visible"
                hideFooter
              />

              {hasNextPage && (
                <div className="py-4">
                  <Link as="button" size="sm" onClick={handleLoadMore}>
                    Show more
                  </Link>
                </div>
              )}
            </>
          )}
          {isEmpty(rows) && !isFetching && !isInvoicesNotNull && (
            <p className="text-gray-500 py-4 text-center">No record found</p>
          )}
        </div>
      </div>
    );
  }

  return <div className="flex flex-col gap-14">{children}</div>;
};

export default PreviousPurchases;
