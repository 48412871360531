import classNames from 'classnames';
import SortByMenu from './SortByMenu';
import SelectionMenu from './SelectionMenu';
import ViewLayoutToggle from './ViewLayoutToggle';
import { AdvancedSelection, Constraints, ModelType, SortBy } from 'types';
import useSelection from './Selection/useSelection';
import ExportOptions from 'components/Export/ExportOptions';
import SimplePagination, { SimplePaginationProps } from './SimplePagination';
import { Button, Checkbox, CheckboxProps, Progress, Tooltip } from '@demandscience/ui';
import ExportProvider from 'components/Export/ExportProvider';
import ListRecordsActionMenuItems from 'components/Export/ListRecordsActionMenuItems';
import { useMemo } from 'react';
import CustomizeColumnsButton from './CustomizeColumnsButton';

interface DataGridHeaderProps {
  checkboxProps: CheckboxProps;
  clearCurrentPage: () => void;
  constraints?: Constraints;
  kind: ModelType;
  loading: boolean;
  paginationProps: SimplePaginationProps;
  selectCurrentPage: () => void;
  setPage: (newPage: number) => void;
  setSort: (newSort: SortBy) => void;
  sort: SortBy;
}

const DataGridHeader = ({
  kind,
  sort,
  setSort,
  selectCurrentPage,
  clearCurrentPage,
  loading,
  paginationProps,
  checkboxProps,
  constraints,
}: DataGridHeaderProps) => {
  const {
    selectionCount,
    batchSelection,
    selectionDisabled,
    viewSelected,
    toggleViewSelected,
    limit,
    clearAll,
  } = useSelection();

  const advancedSelection = useMemo<AdvancedSelection | undefined>(() => {
    let res;

    // WORKAROUND: to have the same numbers as when advanced selection is present
    // to be removed once proper conts are returned for export dry_run
    if (constraints) {
      return { limit: { max_records: selectionCount } };
    }

    if (limit) {
      res = { limit };
    }

    return res;
  }, [constraints, limit, selectionCount]);

  return (
    <>
      {/* NOTE: temporarily comment out bellow alert for ticket LNG-2549 */}
      {/* {kind === ModelType.Contact && featureStatus && (
        <Alert severity="warning" className="mt-4">
          <Alert.Header>
            <Alert.Title>Contacts Data is disabled</Alert.Title>
            <Alert.ActionButton type="button" onClick={requestFeatures}>
              Contact us
            </Alert.ActionButton>
          </Alert.Header>
          <div className="text-sm mt-1 text-gray-700 ml-9">
            Reach out to unlock all data and features
          </div>
        </Alert>
      )} */}

      <div className="bg-white relative z-[4]">
        <div className="h-[70px] border-b flex-shrink-0 flex flex-row items-center justify-between px-2">
          <div className="flex flex-row items-center gap-2">
            <div className="flex items-center">
              <Tooltip title="Select" position="bottom" showArrow>
                <Checkbox size="md" {...checkboxProps} />
              </Tooltip>
              <SelectionMenu
                kind={kind}
                onSelectPage={selectCurrentPage}
                onClearPage={clearCurrentPage}
              />
            </div>
            {selectionCount !== 0 && (
              <>
                {(batchSelection || selectionDisabled) && (
                  <span className="text-sm text-gray-700 font-medium">
                    {selectionCount.toLocaleString()} selected
                  </span>
                )}
                {!batchSelection && !selectionDisabled && (
                  <div className="flex space-x-2">
                    <Tooltip
                      title={viewSelected ? 'Click to view all' : 'Click to view only selected'}
                      position="bottom"
                      showArrow
                    >
                      <button
                        className="text-sm text-gray-700 font-medium"
                        onClick={toggleViewSelected}
                      >
                        {selectionCount.toLocaleString()} selected
                      </button>
                    </Tooltip>
                  </div>
                )}
                <Button borderless size="xs" onClick={clearAll}>
                  Clear
                </Button>
                <ExportProvider
                  modelType={kind}
                  sort={sort}
                  selectionCount={selectionCount}
                  advancedSelection={advancedSelection}
                  constraints={constraints}
                >
                  <ExportOptions>
                    <ListRecordsActionMenuItems />
                  </ExportOptions>
                </ExportProvider>
              </>
            )}
          </div>
          <div className="space-x-4">
            <CustomizeColumnsButton kind={kind} />
            <SortByMenu kind={kind} value={sort} onChange={setSort} />
            <ViewLayoutToggle />
            <SimplePagination {...paginationProps} />
          </div>
        </div>
        <Progress
          theme="primary"
          className={classNames('absolute bottom-0 bg-white h-[1px] invisible', {
            visible: loading,
          })}
        />
      </div>
    </>
  );
};

export default DataGridHeader;
