import { SignUpParams } from '@aws-amplify/auth';
import { createContext } from 'react';
import { Account, ForgotPasswordResponse, ResendSignupResponse, SignupResponse } from 'types';

export interface AuthContextInterface {
  completeNewPassword: (password: string, attributes?: any) => Promise<Account>;
  confirmSignUp: (username: string, code: string) => Promise<void>;
  forgotPassword: (username: string) => Promise<ForgotPasswordResponse>;
  forgotPasswordSubmit: (username: string, code: string, password: string) => Promise<string>;
  impersonate: (targetUsername: string, username: string, token: string) => Promise<Account>;
  isManager: boolean;
  resendSignUp: (username: string) => Promise<ResendSignupResponse>;
  signIn: (username: string, password: string) => Promise<Account>;
  signOut: (global?: boolean) => Promise<void>;
  signUp: (payload: SignUpParams) => Promise<SignupResponse>;
  user?: Account;
  verifyCurrentUserAttribute: (attribute: string) => Promise<void>;
  verifyCurrentUserAttributeSubmit: (attribute: string, code: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  AuthContext.displayName = 'AuthContext';
}

export default AuthContext;
