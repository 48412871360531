import { ChangeEventHandler, useCallback, useState, useEffect, useRef } from 'react';
import UploadCloudIcon from '@demandscience/ui/icons/upload-cloud';
import BuildingIcon from '@demandscience/ui/icons/building';
import UserIcon from '@demandscience/ui/icons/user';
import InfoIcon from '@demandscience/ui/icons/info';
import { useInView } from 'react-intersection-observer';
import { find, flatten, isEmpty, map, reject } from 'lodash';
import CheckboxOption from '../Filter/Dropdown/CheckboxMenuItem';
import TextMenuItem from '../Filter/Dropdown/TextMenuItem';
import MenuItem from '../Filter/Dropdown/MenuItem';
import {
  ExclusionListFilter,
  InclusionListFilter,
  ListFilterName,
  badgeDisplayCap,
} from 'types/filters';
import useFilter from './useFilter';
import useModal from 'hooks/useModal';
import UploadListOverlay from 'components/Lists/UploadListOverlay';
import { Button, Alert, Tooltip, Badge } from '@demandscience/ui';
import { useInfiniteLists } from 'components/Lists/useInfiniteLists';
import { ListStatus, ModelType, Show } from 'types';
import classNames from 'classnames';
import FilterInput from './FilterInput';
import Inline from 'components/Filter/Inline';

interface InExListsFilterProps {
  clearInputOnChange?: boolean;
  container: React.RefObject<HTMLDivElement>;
  dense?: boolean;
  hasGlobalExclusionList?: boolean;
  id?: string;
  name: ListFilterName;
}

const getBadgeProps = ({ list_id, name }: any) => ({
  key: list_id,
  label: name,
  id: `list_${list_id}`,
});

const ListsContent = ({ value, name }: { name?: string; value?: ExclusionListFilter }) => {
  const [recordsProcessing, setRecordsProcessing] = useState(false);
  const { ref, inView } = useInView();
  const { data, isLoading, isSuccess, isError, hasNextPage, fetchNextPage } = useInfiniteLists(
    {
      index: 0,
      count: 25, // keeping 25 as default
      filter: { show: Show.Usable, name },
      sort: {
        by: 'updated_at',
        descending: true,
      },
    },
    {
      refetchInterval: recordsProcessing ? 10000 : false,
    },
  );

  const lists = flatten(map(data?.pages, 'lists'));

  // workaround so combobox comparison by reference works (against the values)
  const options = map(lists, (list) => {
    const dataFromValue = find(value, (v) => v.list_id === list.id);

    if (dataFromValue) {
      return {
        id: list.id,
        value: dataFromValue,
        label: list.name,
      };
    }

    return {
      id: list.id,
      value: {
        list_id: list.id,
        name: list.name,
        records_type: list.records_type,
      },
      label: list.name,
      postLabel:
        list.status === ListStatus.Processing ? (
          <Badge label="Processing" color="rose" size="sm" />
        ) : undefined,
      disabled: list.status !== ListStatus.Completed,
    };
  });

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    if (inView) {
      handleLoadMore();
    }
  }, [inView, handleLoadMore]);

  useEffect(() => {
    setRecordsProcessing(
      data?.pages.some((page) =>
        page.lists.some((list) => list.status === ListStatus.Processing),
      ) || false,
    );
  }, [data]);

  return (
    <>
      {isError && (
        <TextMenuItem textClassName="text-error-500">Unable to load the lists</TextMenuItem>
      )}
      {isLoading && <TextMenuItem textClassName="text-gray-500">Loading...</TextMenuItem>}
      {isSuccess && isEmpty(lists) && (
        <TextMenuItem>
          {name && `No match for '${name}'`}
          {!name && (
            <Alert>
              <Alert.Header>
                <Alert.SubTitle>You don’t have any lists yet</Alert.SubTitle>
              </Alert.Header>
            </Alert>
          )}
        </TextMenuItem>
      )}
      {map(options, (option) => {
        const Icon = option.value.records_type === ModelType.Contact ? UserIcon : BuildingIcon;

        return (
          <CheckboxOption
            key={option.id}
            option={{
              ...option,
              icon: (
                <Icon
                  size={18}
                  className={classNames({
                    'text-gray-700': !option.disabled,
                    'text-gray-400': option.disabled,
                  })}
                />
              ),
            }}
          />
        );
      })}
      {hasNextPage && (
        <TextMenuItem ref={ref} textClassName="text-gray-500">
          Loading...
        </TextMenuItem>
      )}
    </>
  );
};

const InExListsFilter = ({
  id,
  name,
  dense,
  hasGlobalExclusionList,
  clearInputOnChange,
}: InExListsFilterProps) => {
  const { value, onChange, onClear } = useFilter(name);
  const { open, openModal, closeModal } = useModal();
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const {
      target: { value },
    } = e;

    setQuery(value);
  }, []);

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
  }, []);

  const handleChange = useCallback(
    (value: ExclusionListFilter | InclusionListFilter | undefined) => {
      onChange(value);

      // focus input on change
      inputRef.current?.focus();

      // workaround to clear filter input and the suggestions dropdown
      if (clearInputOnChange) {
        handleInputClear();
      }
    },
    [clearInputOnChange, handleInputClear, onChange],
  );

  const handleDelete = useCallback(
    (deleted: any) => {
      const newValue = reject(value, deleted);

      onChange(newValue);
    },
    [onChange, value],
  );

  const handleClear = useCallback(() => {
    handleInputClear();

    onClear();
  }, [handleInputClear, onClear]);

  return (
    <>
      <Inline
        id={id}
        value={value}
        onChange={handleChange}
        multiple
        button={(props) => (
          <FilterInput
            {...props}
            ref={inputRef}
            name={name}
            dense={dense}
            onClear={handleClear}
            onDelete={handleDelete}
            onInputChange={handleInputChange}
            onInputClear={handleInputClear}
            placeholder={
              name === ListFilterName.InclusionList
                ? 'Select a list of records to match'
                : 'Select a list of records to exclude'
            }
            getBadgeProps={getBadgeProps}
            badgeDisplayCap={badgeDisplayCap}
          />
        )}
      >
        <ListsContent value={value} name={query} />

        <div className="sticky bottom-0 bg-white">
          <div className="border-t mx-4" />

          <TextMenuItem className="sticky top-0 bg-white">
            {name === ListFilterName.InclusionList &&
              'Target lists allow you to include records that you already have from other sources.'}
            {name === ListFilterName.ExclusionList &&
              'Suppression lists allow you to exclude records that you already have from other sources.'}
          </TextMenuItem>

          <MenuItem
            title={
              <div
                className={classNames('flex items-center', {
                  'justify-between': hasGlobalExclusionList,
                  'justify-end': !hasGlobalExclusionList,
                })}
              >
                {hasGlobalExclusionList && (
                  <Tooltip
                    title="Your team manager has applied a suppression list for all searches"
                    position="top"
                    showArrow
                    className="max-w-[180px]"
                  >
                    <div className="text-xs text-gray-700">
                      <InfoIcon size={16} className="inline-block align-text-bottom" /> Global
                      suppression list(s) applied
                    </div>
                  </Tooltip>
                )}
                <Button
                  size="sm"
                  leftIcon={<UploadCloudIcon size={18} />}
                  onClick={openModal}
                  className="whitespace-nowrap"
                >
                  Upload list
                </Button>
              </div>
            }
          />
        </div>
      </Inline>
      <UploadListOverlay open={open} onClose={closeModal} />
    </>
  );
};

export default InExListsFilter;
