import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchAutocomplete } from 'api/search';
import size from 'lodash/size';
import map from 'lodash/map';

import { AutocompleteFieldName, AutocompleteParams } from 'types';
import { Option } from '../types';
import { getCompanyLabel } from '../utils';
import { compact, join } from 'lodash';

const fetchOptions = async (params: AutocompleteParams) => {
  const companies = await fetchAutocomplete(AutocompleteFieldName.Company, params);
  const data = map(companies, (value) => {
    const { dsid, domain, logo_url, country } = value;

    return {
      id: dsid,
      value,
      label: getCompanyLabel(value),
      description: join(compact([country, domain]), ' - '),
      avatar: logo_url,
      // eslint-disable-next-line
    } as Option<typeof companies[number]>;
  });

  return data;
};

const useCompanyAutocomplete = (params: AutocompleteParams, options?: Record<string, any>) => {
  const query = useInfiniteQuery(
    ['suggestions', AutocompleteFieldName.Company, params],
    ({ pageParam: index = 0 }) => fetchOptions({ ...params, index }),
    {
      refetchOnWindowFocus: false, // no need to refetch on window focus
      staleTime: 12 * 60 * 60 * 1000, // 12 hours
      getNextPageParam: (lastPage: any, pages) =>
        size(lastPage) === (params.count ?? 20) ? size(pages) : undefined,
      ...options,
      enabled: size(params.value) >= 3 && options?.enabled !== false, // api endpoints requires min 3 characters
    },
  );

  return query;
};

export default useCompanyAutocomplete;
