import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { AdvancedSelection, DEFAULT_MAX_RECORDS, Filters, Model, ModelId, ModelType } from 'types';
import some from 'lodash/some';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import Context, { SelectionContextInterface } from './SelectionContext';
import { map, omit, size } from 'lodash';
import SearchContext from 'components/Search/SearchContext';
import { useUpdateEffect } from 'react-use';

interface SelectionProviderProps {
  children: React.ReactNode;
  filters?: Filters;
  kind: ModelType;
  recordsCount: number;
}

const Provider = ({ filters, recordsCount, kind, children }: SelectionProviderProps) => {
  const [batchSelection, setBatchSelection] = useState<true | undefined>();
  const [{ limit }, setAdvancedSelection] = useState<AdvancedSelection>({});
  const [selection, setSelection] = useState<Model[] | []>([]);
  const [viewSelected, setViewSelected] = useState(false);

  const { [kind]: search } = useContext(SearchContext) || {};
  const maxContactsPerCompany = search?.maxContactsPerCompany;
  const setMaxContactsPerCompany = search?.setMaxContactsPerCompany;
  const excludeList = search?.excludeList;
  const setExcludeList = search?.setExcludeList;
  const isQueryWithTotalCount = search?.query.data?.pagination.totalCount !== undefined;

  const exportData = useMemo(
    () => ({
      filters,
      dsid: map(selection, 'dsid'),
      batchSelection,
    }),
    [filters, selection, batchSelection],
  );

  // selection is disabled when:
  // - limit specified, and no max per company fetching or fetched
  // - max per company fetched
  const selectionDisabled =
    (limit?.max_records !== undefined &&
      maxContactsPerCompany === undefined &&
      !isQueryWithTotalCount) ||
    (maxContactsPerCompany !== undefined && isQueryWithTotalCount) ||
    (excludeList !== undefined && isQueryWithTotalCount);

  let selectionCount = size(selection);
  if (batchSelection) {
    selectionCount = recordsCount - selectionCount;
  }
  if (limit?.max_records && limit?.max_records > 0) {
    if (maxContactsPerCompany !== undefined && !isQueryWithTotalCount) {
      // don't show any counter as max contact per company query is fetched
      selectionCount = 0;
    } else if (selectionCount > limit.max_records) {
      selectionCount = limit.max_records;
    }
  } else if (
    (maxContactsPerCompany !== undefined || excludeList !== undefined) &&
    isQueryWithTotalCount
  ) {
    selectionCount = recordsCount;
  }

  const add = useCallback((row: Model | Model[]) => {
    if (isArray(row)) {
      setSelection((state) => {
        const rows = filter(row, ({ dsid }) => !some(state, { dsid }));

        return [...state, ...rows];
      });
    } else {
      setSelection((state) => [...state, row]);
    }
  }, []);

  const remove = useCallback((row: ModelId | ModelId[]) => {
    if (isArray(row)) {
      setSelection((state) => reject(state, ({ dsid }) => some(row, { dsid })));
    } else {
      const { dsid } = row;

      setSelection((state) => reject(state, { dsid }));
    }
  }, []);

  const selectAll = useCallback(
    (limit: AdvancedSelection['limit'] | undefined = DEFAULT_MAX_RECORDS) => {
      setSelection([]);
      setBatchSelection(true);
      setAdvancedSelection((state) => ({ ...state, limit }));
    },
    [],
  );

  const clearAll = useCallback(() => {
    setSelection([]);
    setBatchSelection(undefined);
    setAdvancedSelection((state) => omit(state, 'limit'));
    setMaxContactsPerCompany?.(undefined);
    setExcludeList?.(undefined);
  }, [setExcludeList, setMaxContactsPerCompany]);

  const toggleViewSelected = useCallback(() => {
    setViewSelected((state) => !state);
  }, []);

  const setLimit = useCallback(
    (value?: AdvancedSelection['limit']) => {
      if (isEmpty(value)) {
        clearAll();
      } else {
        selectAll(value);
      }
    },
    [selectAll, clearAll],
  );

  const isSelected = useCallback(
    (row: ModelId, index: number): boolean => {
      if (limit?.max_records && limit?.max_records > 0) {
        // don't show any selection if limit present and max per company fetched
        if (maxContactsPerCompany !== undefined && !isQueryWithTotalCount) {
          return false;
        }

        return index < limit.max_records;
      }
      // show as selected as max per company contacts are all selected
      if (maxContactsPerCompany !== undefined && isQueryWithTotalCount) {
        return true;
      }
      // show as selected as only net new records are shown
      if (excludeList !== undefined && isQueryWithTotalCount) {
        return true;
      }

      const inSelection = some(selection, { dsid: row.dsid });

      if (batchSelection) {
        // selection containes ids to exclude
        return !inSelection;
      } else {
        return inSelection;
      }
    },
    [
      batchSelection,
      excludeList,
      isQueryWithTotalCount,
      limit?.max_records,
      maxContactsPerCompany,
      selection,
    ],
  );

  // on filters change clear the selection, that's a business rule as it's hard to keep e.g. 'select all'
  // but this to be executed only on filters update, that's why using useUpdateEffect
  useUpdateEffect(() => {
    clearAll();
    setViewSelected(false);
  }, [filters, clearAll]);

  useEffect(() => {
    if (isEmpty(selection)) {
      setViewSelected(false);
    }
  }, [selection]);

  const value: SelectionContextInterface = useMemo(
    () => ({
      selection,
      exportData,
      selectionCount,
      batchSelection,
      add,
      remove,
      selectAll,
      limit,
      setLimit,
      clearAll,
      isSelected,
      selectionDisabled,
      viewSelected,
      toggleViewSelected,
    }),
    [
      add,
      batchSelection,
      clearAll,
      exportData,
      isSelected,
      limit,
      remove,
      selectAll,
      selection,
      selectionCount,
      selectionDisabled,
      setLimit,
      toggleViewSelected,
      viewSelected,
    ],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
