import { AggregateField, CompanyFilters, Filters, ModelType } from 'types';
import useFieldAggregate from 'hooks/useFieldAggregate';

const useFieldsAggregate = ({
  contactsFilters,
  companiesFilters,
}: {
  companiesFilters: CompanyFilters;
  contactsFilters: Filters;
}) => {
  const { aggregate: jobLevel, isFetching: isFetchingJobLevel } = useFieldAggregate(
    ModelType.Contact,
    AggregateField.JobLevel,
    contactsFilters,
  );
  const { aggregate: jobFunction, isFetching: isFetchingJobFunction } = useFieldAggregate(
    ModelType.Contact,
    AggregateField.JobFunction,
    contactsFilters,
  );
  const { aggregate: country, isFetching: isFetchingCountry } = useFieldAggregate(
    ModelType.Company,
    AggregateField.CompanyLocationCountry,
    companiesFilters,
  );
  const { aggregate: employees, isFetching: isFetchingEmplyees } = useFieldAggregate(
    ModelType.Company,
    AggregateField.CompanyEmployees,
    companiesFilters,
  );
  const { aggregate: industry, isFetching: isFetchingIndustry } = useFieldAggregate(
    ModelType.Company,
    AggregateField.CompanyIndustry,
    companiesFilters,
  );

  const isFetching =
    isFetchingJobLevel ||
    isFetchingJobFunction ||
    isFetchingCountry ||
    isFetchingEmplyees ||
    isFetchingIndustry;
  const aggregates = !isFetching
    ? {
        [AggregateField.JobLevel]: jobLevel,
        [AggregateField.JobFunction]: jobFunction,
        [AggregateField.CompanyLocationCountry]: country,
        [AggregateField.CompanyEmployees]: employees,
        [AggregateField.CompanyIndustry]: industry,
      }
    : null;

  return { isFetching, aggregates };
};

export default useFieldsAggregate;
