import { Spinner } from '@demandscience/ui';

import UsersIcon from '@demandscience/ui/icons/users';
import UserIcon from '@demandscience/ui/icons/user';
import OfficeBuildingIcon from '@demandscience/ui/icons/building';

import round from 'lodash/round';

export interface DataProfileProps {
  children?: React.ReactNode;
  companyCount: number;
  contactCount: number;
  iscontactRendered?: boolean;
  loading?: boolean;
}

const DataProfile: React.FC<DataProfileProps> = ({
  contactCount,
  companyCount,
  loading,
  children,
  iscontactRendered,
}) => (
  <div className="flex flex-col min-h-[312px] rounded border">
    <div className="px-[30px] pt-10 pb-4">
      <ul className="space-y-5">
        {iscontactRendered && (
          <li className="flex">
            <UsersIcon className="w-5 h-5 mr-3 text-primary-500" />
            <div className="grow">
              <div className="text-sm text-gray-500 font-medium">Contacts</div>
              <div className="mt-1 text-xl text-gray-700 font-medium">
                {contactCount?.toLocaleString()}
              </div>
            </div>
            {loading && <Spinner theme="primary" size="sm" />}
          </li>
        )}
        <li className="flex">
          <OfficeBuildingIcon className="w-5 h-5 mr-3 text-primary-500" />
          <div className="grow">
            <div className="text-sm text-gray-500 font-medium">Companies</div>
            <div className="mt-1 text-xl text-gray-700 font-medium">
              {companyCount?.toLocaleString()}
            </div>
          </div>
        </li>
        {iscontactRendered && (
          <li className="flex">
            <UserIcon className="w-5 h-5 mr-3 text-primary-500" />
            <div className="grow">
              <div className="text-sm text-gray-500 font-medium">Contacts per company</div>
              <div className="mt-1 text-xl text-gray-700 font-medium">
                {companyCount > 0 ? round(contactCount / companyCount, 2)?.toLocaleString() : 0}
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
    <div className="grow" />
    {children}
  </div>
);

export default DataProfile;
