import { Severity } from '@demandscience/ui/dist/types';
import { UseQueryResult } from '@tanstack/react-query';

export const showPopup = (
  popupUrl: string,
  showMessage: (value: string, severity: Severity) => void,
  setToggle?: (value: boolean) => void,
  setConfigured?: (value: boolean) => void,
  configured?: boolean,
  refetch?: () => Promise<UseQueryResult>,
  setRedirect?: (value: boolean) => void,
) => {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  let width = window.innerWidth;
  if (!width) {
    width = document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
  }
  let height = window.innerHeight;
  if (!height) {
    height = document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;
  }

  const systemZoom = width / window.screen.availWidth;
  const left = (width - 800) / 2 / systemZoom + dualScreenLeft;
  const top = (height - 800) / 2 / systemZoom + dualScreenTop;

  const configWindow = window.open(
    popupUrl,
    '_blank',
    `scrollbars=yes,resizable=yes, width=${800 / systemZoom}, height=${
      800 / systemZoom
    }, top=${top}, left=${left}`,
  );

  // Listen to popup messages
  let configFinished = false;
  const onmessage = (e: any) => {
    if (e.data.type === 'tray.configPopup.error') {
      // Here you can handle error messages
      // For example `Error: ${e.data.err}`
    }
    if (e.data.type === 'tray.configPopup.cancel') {
      configWindow?.close();
    }
    if (e.data.type === 'tray.configPopup.finish') {
      configFinished = true;
      configWindow?.close();
    }
    if (e.data.type === 'tray.configPopup.validate') {
      // Return validation in progress
      configWindow?.postMessage(
        {
          type: 'tray.configPopup.client.validation',
          data: {
            inProgress: true,
          },
        },
        '*',
      );
      setTimeout(() => {
        // Add errors to all inputs
        const errors = e.data.data.visibleSlots.reduce((errors: { [key: string]: string }) => {
          return errors;
        }, {});
        // Return validation
        configWindow?.postMessage(
          {
            type: 'tray.configPopup.client.validation',
            data: {
              inProgress: false,
              errors: errors,
            },
          },
          '*',
        );
      }, 2000);
    }
  };
  window.addEventListener('message', onmessage);
  // Check if popup window has been closed before finishing the configuration.
  // We use a polling function due to the fact that some browsers may not
  // display prompts created in the beforeunload event handler.
  const CHECK_TIMEOUT = 1000;
  const checkWindow = () => {
    const isSetToggle = typeof setToggle === 'function';
    const isSetRedirect = typeof setRedirect === 'function';
    if (configWindow?.closed) {
      // Handle popup closing
      if (!configFinished) {
        if (isSetToggle) {
          setToggle(false);
        }
        if (isSetRedirect) {
          setRedirect(false);
        }
        if (setConfigured !== undefined) {
          setConfigured(false);
        }
        showMessage && showMessage('Integration not finished', 'error');
      } else {
        if (isSetToggle) {
          setToggle(true);
        }
        if (setConfigured !== undefined) {
          setConfigured(true);
        }

        refetch && refetch();

        if (isSetRedirect) {
          setRedirect(true);
        }

        configured
          ? showMessage && showMessage('Integration configured', 'success')
          : showMessage && showMessage('Integration connected', 'success');
      }
      window.removeEventListener('message', onmessage);
    } else {
      setTimeout(checkWindow, CHECK_TIMEOUT);
    }
  };
  checkWindow();
  return configWindow;
};

export default showPopup;
