import { forwardRef, HTMLAttributes } from 'react';
import SixDotsIcon from '@demandscience/ui/icons/six-dots';

interface DragHandleProps extends HTMLAttributes<HTMLSpanElement> {}

const DragHandle = forwardRef<HTMLSpanElement, DragHandleProps>((props, ref) => (
  <span ref={ref} {...props}>
    <SixDotsIcon className="stroke-gray-200" size={20} />
  </span>
));

DragHandle.displayName = 'DragHandle';

export default DragHandle;
