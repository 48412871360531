import Select from 'components/Input/Select';
import { Alert, TextField, Transition } from '@demandscience/ui';
import MembersSelect from 'components/Members/MembersSelect';
import MessageInputController from 'components/Input/MessageInputController';
import { CreateListFormValues, EditListFormValues, visibilityOptions, Visibility } from 'types';
import { Control, Controller, FormState, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { isEmpty, size, some } from 'lodash';
import useAuth from 'components/Auth/useAuth';
import { useCallback } from 'react';

interface ListFormFieldsProps {
  control: Control<CreateListFormValues | EditListFormValues>;
  edit?: boolean;
  editingSharedPublicList?: boolean;
  formState: FormState<CreateListFormValues | EditListFormValues>;
  setValue: UseFormSetValue<CreateListFormValues | EditListFormValues>;
  watch: UseFormWatch<CreateListFormValues | EditListFormValues>;
}

const ListFormFields = ({
  control,
  formState,
  watch,
  setValue,
  editingSharedPublicList,
  edit,
}: ListFormFieldsProps) => {
  const { user } = useAuth();
  const { errors, dirtyFields } = formState;
  const visibility = watch('visibility');
  const members = watch('members');
  const notify = watch('notify');
  const isCurrentUserSharedWith = some(members, ({ value }) => value === user!.username);

  const handleAddMyself = useCallback(() => {
    setValue('members', [
      ...(members || []),
      { value: user!.username, label: user!.name || user!.email },
    ]);
  }, [members, setValue, user]);

  const handleAddMessageClick = () => {
    setValue('notify', true);
  };

  return (
    <div className="space-y-4">
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            label="List name"
            trailingLabel={`${size(field.value)}/100 characters`}
            variant="outlined"
            error={errors.name?.message}
            {...field}
          />
        )}
        rules={{
          required: 'Name is required',
          maxLength: { value: 100, message: '100 characters max' },
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            label="Description"
            trailingLabel={`${size(field.value)}/500 characters`}
            variant="outlined"
            error={errors.description?.message}
            {...field}
          />
        )}
        rules={{
          maxLength: { value: 500, message: '500 characters max' },
        }}
      />
      <Controller
        name="visibility"
        control={control}
        render={({ field }) => (
          <Select
            label="Shared status"
            variant="outlined"
            options={visibilityOptions}
            error={errors.visibility?.message}
            {...field}
          />
        )}
        rules={{ required: 'Required field' }}
      />

      {visibility === Visibility.Restricted && (
        <Controller
          name="members"
          control={control}
          render={({ field }) => (
            <MembersSelect
              label="Share this list with"
              {...field}
              error={errors.members?.message}
            />
          )}
          rules={{ required: 'Required field' }}
        />
      )}

      {editingSharedPublicList && formState.dirtyFields.visibility && (
        <>
          {visibility === Visibility.Private && (
            <Alert severity="warning">
              <Alert.Header>
                <Alert.Title>Beware</Alert.Title>
              </Alert.Header>
              <Alert.Description>
                By changing this setting, you will lose access to this list.
              </Alert.Description>
            </Alert>
          )}
          {visibility === Visibility.Restricted && (
            <Alert severity="warning">
              <Alert.Header>
                <Alert.Title>Beware</Alert.Title>
                {!isCurrentUserSharedWith && (
                  <Alert.ActionButton type="button" onClick={handleAddMyself}>
                    Add myself
                  </Alert.ActionButton>
                )}
              </Alert.Header>
              {!isCurrentUserSharedWith && (
                <Alert.Description>
                  By changing this setting, you will lose access to this list. You can keep access
                  to the list by adding yourself above.
                </Alert.Description>
              )}
              {isCurrentUserSharedWith && (
                <Alert.Description>
                  By changing this setting, you will lose edit-rights to this list.
                </Alert.Description>
              )}
            </Alert>
          )}
        </>
      )}

      <Transition.Collapse
        show={
          (visibility === Visibility.Restricted &&
            !isEmpty(members) &&
            // @ts-expect-error - seems an issue with type coming from react-hook-form
            dirtyFields.members === true) ||
          (visibility === Visibility.Public && dirtyFields.visibility === true)
        }
        as="div"
      >
        <div className="flex items-center text-sm text-gray-700 py-2">
          <p>
            {edit && visibility === Visibility.Restricted
              ? 'New members will be notified.'
              : 'Members will be notified.'}
          </p>
          {!notify && (
            <button
              type="button"
              className="text-blue-500 hover:underline ml-2"
              onClick={handleAddMessageClick}
            >
              Add a message
            </button>
          )}
        </div>
        {notify && (
          <MessageInputController<CreateListFormValues | EditListFormValues>
            name="notification_message"
            control={control}
          />
        )}
      </Transition.Collapse>
    </div>
  );
};

export default ListFormFields;
