import classNames from 'classnames';
import OnboardingNo1 from './OnboardingButtonNo1';
import OnboardingNo2 from './OnboardingButtonNo2';
import OnboardingNo3 from './OnboardingButtonNo3';
import useOnboarding from './useOnboarding';

interface OnboardingListProps {
  className?: string;
}

const OnboardingList = ({ className }: OnboardingListProps) => {
  const { openOnboarding } = useOnboarding();

  const handleClick = (index: 1 | 2 | 3) => () => {
    openOnboarding(index);
  };

  return (
    <div className={classNames('flex flex-col sm:flex-row items-center gap-6', className)}>
      <OnboardingNo1
        className="flex-1"
        data-event="tutorial_begin"
        data-event-value="Prospect list"
        onClick={handleClick(1)}
        role="button"
        tabIndex={0}
      />
      <OnboardingNo2
        className="flex-1"
        data-event="tutorial_begin"
        data-event-value="Visualise pipeline"
        onClick={handleClick(2)}
        role="button"
        tabIndex={0}
      />
      <OnboardingNo3
        className="flex-1"
        data-event="tutorial_begin"
        data-event-value="Target contacts"
        onClick={handleClick(3)}
        role="button"
        tabIndex={0}
      />
    </div>
  );
};

export default OnboardingList;
