import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import { Badge } from '@demandscience/ui';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';

export interface FilterSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  count: number;
  dense?: boolean;
  show: boolean;
  title: string;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  className,
  title,
  count,
  show,
  dense,
  ...props
}) => {
  return (
    <div
      className={overrideTailwindClasses(
        classNames(
          'flex flex-row gap-2 items-center pr-4 group cursor-pointer select-none',
          className,
          {
            'pt-6 pb-4 ': !dense,
            'pt-3 pb-2 ': dense,
          },
        ),
      )}
      {...props}
    >
      <div className="text-sm text-gray-700 group-hover:text-blue-500 font-semibold">{title}</div>
      {count > 0 && (
        <Badge className={classNames('transition', { 'opacity-0': show })} color="blue" size="sm">
          {count}
        </Badge>
      )}
      <div className="grow" />
      <ChevronDownIcon
        size={18}
        className={classNames('transition-transform group-hover:stroke-blue-500', {
          'rotate-180': show,
        })}
      />
    </div>
  );
};

export default FilterSection;
