import React from 'react';
import { noop } from 'lodash';

export interface DataProfileContextProps {
  isMouseOver: boolean;
  mouseOverFilterFieldId?: string;
  mouseOverFilterId?: string;
  setIsMouseOver: (e: boolean) => void;
  setMouseOverFilterFieldId: (e: string | undefined) => void;
  setMouseOverFilterId: (e: string | undefined) => void;
}

const DataProfileContext = React.createContext<DataProfileContextProps>({
  isMouseOver: false,
  mouseOverFilterFieldId: undefined,
  mouseOverFilterId: undefined,
  setIsMouseOver: noop,
  setMouseOverFilterFieldId: noop,
  setMouseOverFilterId: noop,
});

export default DataProfileContext;
