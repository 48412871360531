import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { ExportStatus, Pagination } from 'types';
import { DataGrid } from '@demandscience/ui';
import { RowItem } from '@demandscience/ui/dist/types';
import Paragraph from 'components/Typography/Paragraph';
import { columns } from './ExportsTableColumns';
import { usePreviousExports } from './usePreviousExports';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import PreviousExportsSkeleton from './ExportsSkeleton';

const defaultParams: Pagination = {
  count: 25,
  index: 0,
};

const getRowClassName = ({ status }: RowItem) =>
  classNames('group', { 'text-gray-400': status === ExportStatus.Processing });

const PreviousExports = () => {
  const { query, params, handlePageChange } = usePreviousExports(defaultParams);

  const { data, isLoading, isFetching, isError, isPreviousData } = query;
  const { index, count: rowsPerPage } = params;

  const header = (
    <Paragraph className="mb-14">List of all your previous downloads and exports.</Paragraph>
  );

  if (isLoading) {
    return (
      <>
        {header}
        <PreviousExportsSkeleton count={10} />
      </>
    );
  }

  if (isError) {
    return (
      <>
        {header}
        <div className="text-sm text-gray-500">Unexpected error loading previous exports</div>
      </>
    );
  }

  const { count, exports } = data || {};

  if (isEmpty(exports)) {
    return (
      <>
        {header}
        <EmptyResults
          message="You haven't exported anything yet."
          illustration={<Illustration.EmptyAlt className="w-40 h-40 mb-6" />}
        />
      </>
    );
  }

  return (
    <div className="pb-40">
      {header}
      {!isEmpty(exports) && (
        <DataGrid
          columns={columns}
          rows={exports}
          uniqueIdentifier="id"
          loading={isFetching && isPreviousData}
          disableInternalComputation
          hideFooterRowCount
          page={index + 1}
          rowsPerPage={rowsPerPage}
          count={count}
          onPageChange={handlePageChange}
          paginationVariant="combined"
          containerClassName="overflow-visible"
          rowClassName={getRowClassName}
        />
      )}
    </div>
  );
};

export default PreviousExports;
