import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  updateMember,
  suspendMember,
  reactivateMember,
  deleteMember,
  inviteMembers,
  resendMemberInvite,
} from 'api/organization';
import useAuth from 'components/Auth/useAuth';

const useMutateMember = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const update = useMutation(updateMember, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(['members']);

      // if own avatar update, reload account data and credit
      if (user?.username === id) {
        queryClient.invalidateQueries(['account']);
      }
    },
    ...options,
  });

  const suspend = useMutation(suspendMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(['members']);
      queryClient.invalidateQueries(['organization']);
    },
    ...options,
  });

  const reactivate = useMutation(reactivateMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(['members']);
      queryClient.invalidateQueries(['organization']);
    },
    ...options,
  });

  const destroy = useMutation(deleteMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(['members']);
      queryClient.invalidateQueries(['organization']);
    },
    ...options,
  });

  const invite = useMutation(inviteMembers, {
    onSuccess: () => {
      queryClient.invalidateQueries(['members']);
      queryClient.invalidateQueries(['organization']);
    },
    ...options,
  });

  const resendInvite = useMutation(resendMemberInvite, options);

  return { update, suspend, reactivate, destroy, invite, resendInvite };
};

export default useMutateMember;
