import { createContext } from 'react';

export interface OnboardingContextInterface {
  closeOnboarding: () => void;
  openOnboarding: (index: 1 | 2 | 3) => void;
}

const OnboardingContext = createContext<OnboardingContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  OnboardingContext.displayName = 'OnboardingContext';
}

export default OnboardingContext;
