import { Button } from '@demandscience/ui';
import { useCallback, useEffect, useState } from 'react';
import OnboardingTooltip from './OnboardingTooltip';

interface OnboardingTooltipsNo3Props {
  onClose: () => void;
  open: boolean;
}

const OnboardingTooltipsNo3 = ({ open, onClose }: OnboardingTooltipsNo3Props) => {
  const [index, setIndex] = useState(0);

  const handleClose = useCallback(() => {
    setIndex(0);
    onClose();
  }, [onClose]);

  const handleNext = useCallback(() => {
    setIndex((state) => state + 1);
  }, []);

  useEffect(() => {
    if (open) {
      setIndex(1);
    } else {
      setIndex(0);
    }
  }, [open]);

  useEffect(() => {
    if (index === 2) {
      document.getElementById('filter_by_inclusion_list')?.scrollIntoView();
    }
  }, [index]);

  if (!open) {
    return null;
  }

  return (
    <>
      <OnboardingTooltip
        anchorElId="filter_by_company_name"
        open={index === 1}
        onClose={handleClose}
        placement="right"
        title="Your target accounts were added here as filters"
        subtitle="You can now change or add more filters to create your ideal list of prospects."
        info="1 of 2"
        button={
          <Button size="sm" onClick={handleNext}>
            Next
          </Button>
        }
        showArrow
      />
      <OnboardingTooltip
        anchorElId="filter_by_inclusion_list"
        open={index === 2}
        onClose={handleClose}
        placement="right"
        title="Do you have a large number of target accounts?"
        subtitle="Upload a list of all your target accounts and add it here."
        info="2 of 2"
        button={
          <Button
            size="sm"
            onClick={onClose}
            data-event="tutorial_complete"
            data-event-value="Target contacts"
          >
            Finish
          </Button>
        }
        showArrow
      />
    </>
  );
};

export default OnboardingTooltipsNo3;
