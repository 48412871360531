import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import MenuItem from './MenuItem';
import { Combobox } from '@headlessui/react';
import { Fragment, MouseEventHandler } from 'react';

export type Option<T = any> = {
  description?: string;
  label: string;
  value: T;
};

interface TextOptionProps {
  onToggle?: (option: Option) => void;
  option: Option;
  text?: string;
}

const TextOption = ({ text, option, onToggle }: TextOptionProps) => {
  const handleToggle =
    (selected: boolean): MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      if (selected) {
        e.preventDefault();

        if (onToggle) onToggle(option);
      } else {
        option.value.exclude = true;
      }
    };

  return (
    <Combobox.Option value={option.value} as={Fragment}>
      {({ active, selected }) => (
        <MenuItem
          className={overrideTailwindClasses(
            classNames('group cursor-pointer hover:bg-gray-50', {
              'bg-gray-50': !selected && active,
              'bg-gray-100 hover:bg-gray-200': selected && !active,
              'bg-gray-200 hover:bg-gray-200': selected && active,
            }),
          )}
          titleClassName="ellipsis"
          title={text || option.label}
          subtitleClassName="ellipsis"
          subtitle={option.description}
          trailingContent={
            <div className="flex flex-row gap-4">
              {onToggle && (
                <button
                  className={classNames('text-xs text-gray-500 hidden group-hover:block', {
                    'hover:text-rose-500': !option.value.exclude,
                    'hover:text-emerald-500': option.value.exclude,
                  })}
                  onClick={handleToggle(selected)}
                >
                  {option.value.exclude && 'Include'}
                  {!option.value.exclude && 'Exclude'}
                </button>
              )}
            </div>
          }
        />
      )}
    </Combobox.Option>
  );
};

export default TextOption;
