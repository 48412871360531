import pluralize from 'pluralize';
import useOrganization from './useOrganization';
import AvailableSeatsBadge from './AvailableSeatsBadge';

const MembersCountTitle = () => {
  const { data: organization } = useOrganization();

  if (!organization) {
    return null;
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <span className="text-lg font-medium text-gray-700">
        {organization?.active_accounts?.used.toLocaleString()}{' '}
        {pluralize('member', organization?.active_accounts?.used)}
      </span>
      <AvailableSeatsBadge color="gray" size="lg" />
    </div>
  );
};

export default MembersCountTitle;
