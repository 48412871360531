import { KlarityFeature, ResultsView } from 'types';
import DataProfile from './DataProfile';
import useCompare from 'components/Filters/useCompare';
import DataProfileColumns from './DataProfileColumns';
import DataProfileProvider from './DataProfileProvider';
import ModulesCustomizationProvider from 'components/ModulesCustomization/ModulesCustomizationProvider';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

interface DataProfileViewProps {
  id?: string;
  modules?: {
    type: {
      id: string;
      name: string;
    };
    visual: string;
  }[];
  onTabChange: (tab: ResultsView) => void;
}

const DataProfileView = ({ id, onTabChange, modules }: DataProfileViewProps) => {
  const { compare, compareSearchList } = useCompare();
  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);

  const iscontactRendered = !isContactDisabled && !isContactRenderDisabled;

  if (compare) {
    return (
      <ModulesCustomizationProvider>
        <DataProfileProvider>
          <DataProfileColumns
            id={id}
            compareSearchs={compareSearchList}
            onTabChange={onTabChange}
            modules={modules}
            iscontactRendered={iscontactRendered}
          />
        </DataProfileProvider>
      </ModulesCustomizationProvider>
    );
  }

  return (
    <ModulesCustomizationProvider>
      <DataProfile
        id={id}
        onTabChange={onTabChange}
        modules={modules}
        iscontactRendered={iscontactRendered}
      />
    </ModulesCustomizationProvider>
  );
};

DataProfileView.displayName = 'DataProfiles';

export default DataProfileView;
