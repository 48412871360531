import { useLayoutEffect, useState } from 'react';

const useElementById = (id: string) => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const handleLookup: MutationCallback = () => {
      const elementById = document.getElementById(id);

      setElement(elementById);
    };

    let observer: MutationObserver;

    const elementById = document.getElementById(id);
    if (!elementById) {
      observer = new MutationObserver(handleLookup);
      observer.observe(document, { childList: true, subtree: true });
    } else {
      setElement(elementById);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [id]);

  return element;
};

export default useElementById;
