import includes from 'lodash/includes';
import { Account, Role } from 'types';

import useAuth from './useAuth';
import { Outlet } from 'react-router-dom';

interface RequireAuthRoleProps {
  allowedAccount?: (user: Account) => boolean;
  children?: React.ReactElement;
  renderNoRole: () => React.ReactElement;
  role: Role;
}

const RequireAuthRole: React.FC<RequireAuthRoleProps> = ({
  children,
  role,
  renderNoRole,
  allowedAccount,
}) => {
  const { user } = useAuth();

  if (!user || !includes(user.roles, role) || (allowedAccount && !allowedAccount(user))) {
    return renderNoRole();
  }

  return children || <Outlet />;
};

export default RequireAuthRole;
