import { ListRecord } from 'types';
import useModal from 'hooks/useModal';
import { Modal } from '@demandscience/ui';
import EditListForm from './EditListForm';
import ListRecordDeleteActionModal from './ListRecordDeleteActionModal';
import { useCallback } from 'react';

interface ListRecordEditActionModalProps extends ListRecord {
  afterDelete?: () => void;
  onClose: () => void;
  open: boolean;
}

const ListRecordEditActionModal = ({ afterDelete, ...props }: ListRecordEditActionModalProps) => {
  const { open, onClose, ...deleteModalProps } = props;

  const {
    open: deleteConfirmationModalOpen,
    openModal: openDeleteConfirmationModal,
    closeModal: closeDeleteConfirmationModal,
  } = useModal();

  const handleDeleteConfirmationModal = useCallback(() => {
    openDeleteConfirmationModal();
    onClose();
  }, [onClose, openDeleteConfirmationModal]);

  return (
    <>
      <Modal
        aria-labelledby="edit-list-dialog-title"
        className="max-w-md sm:max-w-md overflow-visible"
        rootClassName="z-30"
        {...props}
      >
        <Modal.Title id="edit-list-dialog-title" className="flex mb-4">
          List settings
        </Modal.Title>
        <EditListForm
          onSuccess={onClose}
          onCancel={onClose}
          onDelete={handleDeleteConfirmationModal}
          {...props}
        />
      </Modal>
      <ListRecordDeleteActionModal
        open={deleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onSuccess={afterDelete}
        {...deleteModalProps}
      />
    </>
  );
};

export default ListRecordEditActionModal;
