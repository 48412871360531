import { Modal, ModalProps } from '@demandscience/ui';

import { RecentSearch } from 'types';
import Form from './DeleteForm';

interface DeleteOverlayProps extends Omit<ModalProps, 'children'> {
  data: RecentSearch;
}

const DeleteOverlay: React.FC<DeleteOverlayProps> = ({ data, ...props }) => {
  const { onClose } = props;

  return (
    <Modal
      aria-labelledby="delete-search-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="delete-search-dialog-title" className="mb-4">
        Are you sure you want to delete this search?
      </Modal.Title>
      <Form data={data} onSuccess={onClose} onCancel={onClose} />
    </Modal>
  );
};

export default DeleteOverlay;
