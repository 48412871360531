import api from 'api';
import {
  ChatCompletionsParams,
  ChatCompletionsResponse,
  ChatPromptResponse,
} from 'types/aissistant';

export const aiChatSearch = (params: ChatCompletionsParams): Promise<ChatCompletionsResponse> =>
  api.post('/search/openai/chat', params);

export const aiChatPrompt = (): Promise<ChatPromptResponse> => api.get('/search/openai/prompts');
