import { FC, useCallback, useState } from 'react';
import { Contact } from 'types';
import ListItemText from 'components/Layout/ListItemText';
import { Link } from '@demandscience/ui';

import PhoneIcon from '@demandscience/ui/icons/phone';
import SmartphoneIcon from '@demandscience/ui/icons/smartphone';
import CopyIcon from '@demandscience/ui/icons/copy';

import { filter, isEmpty, map, reject } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';
import classNames from 'classnames';
import { copyToClipboard } from 'utils/misc';
import TextTooltip from 'components/Tooltip/TextTooltip';

interface PhoneProps {
  mobile?: boolean;
  row: Contact;
}

const Phone: FC<PhoneProps> = ({ mobile, row }) => {
  const [copyText, setCopyText] = useState('copy');
  const { revealed, phones } = row;
  const Icon = mobile ? SmartphoneIcon : PhoneIcon;

  const numbers = mobile
    ? filter(phones, { phone_type: 'Mobile' })
    : reject(phones, { phone_type: 'Mobile' });

  const handleCopy = useCallback(
    (number: string) => () => {
      copyToClipboard(number);
      setCopyText('copied!');
    },
    [],
  );

  const handleClose = useCallback(() => {
    setCopyText('copy');
  }, []);

  if (isEmpty(numbers)) {
    return null;
  }

  return (
    <ListItemText
      leadingContent={
        <div className="flex">
          <TextTooltip
            title={mobile ? 'Mobile phone' : 'Company phone'}
            placement="top-start"
            showArrow
            offset={[-4, 8]}
          >
            <Icon className={classNames('w-5 h-5', { 'text-emerald-500': revealed })} />
          </TextTooltip>
        </div>
      }
      title={
        <div className="flex flex-col items-start">
          {map(numbers, ({ number }) => {
            const parsed = parsePhoneNumber(number);

            if (parsed) {
              return (
                <div key={'contact-' + number} className="flex flex-row gap-2 items-center w-full">
                  <TextTooltip className="grow min-w-0" title="Click to call" placement="top">
                    <Link
                      className="font-normal block ellipsis"
                      href={parsed?.getURI()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {parsed?.formatInternational()}
                    </Link>
                  </TextTooltip>
                  <TextTooltip title={copyText} placement="top-end" showArrow onClose={handleClose}>
                    <button
                      className="text-gray-400 hover:text-gray-500"
                      onClick={handleCopy(number)}
                    >
                      <CopyIcon className="w-5 h-5" />
                    </button>
                  </TextTooltip>
                </div>
              );
            } else return <div key={`${number}`}>{number}</div>;
          })}
        </div>
      }
    />
  );
};

export default Phone;
