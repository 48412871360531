import { ReactElement, useCallback, useState } from 'react';
import classNames from 'classnames';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';
import MenuItem from './MenuItem';
import { Transition } from '@demandscience/ui';

export interface ToggleMenuItemProps {
  children: ReactElement;
  show?: boolean;
  title: string;
}

const ToggleMenuItem = ({ title, show: defaultShow, children }: ToggleMenuItemProps) => {
  const [show, setShow] = useState(defaultShow);

  const handleToggle = useCallback(() => {
    setShow((state) => !state);
  }, []);

  return (
    <>
      <MenuItem
        className="group cursor-pointer hover:bg-gray-50"
        onClick={handleToggle}
        role="button"
        title={title}
        trailingContent={
          <ChevronDownIcon
            size={18}
            className={classNames('transition-transform stroke-gray-500', {
              'rotate-180': show,
            })}
          />
        }
      />
      <Transition.Collapse show={show} as="div">
        {children}
      </Transition.Collapse>
    </>
  );
};

export default ToggleMenuItem;
