import AuthImpersonate from 'components/Auth/Impersonate';
import useSnackbar from 'components/Snackbar/useSnackbar';
import Paragraph from 'components/Typography/Paragraph';
import { truncate } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEvent, useTimeout } from 'react-use';
import { Account, AuthMessage, PostMessageSource } from 'types';

const Impersonate = () => {
  const adminUri = process.env.REACT_APP_KLARITY_ADMIN_URI;
  const [authData, setAuthData] = useState<AuthMessage['payload'] | undefined>();
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();
  const { targetUsername } = useParams();

  const handleSignin = useCallback(
    (user: Account) => {
      if (user.name) {
        showMessage(`Signed in as ${truncate(user.name, { length: 36 })}`, 'success');
      }

      navigate('/', { replace: true });
    },
    [navigate, showMessage],
  );

  const handleMessage = useCallback(
    (e: MessageEvent<AuthMessage>) => {
      const { data, origin } = e;

      if (adminUri?.startsWith(origin) && data?.source === PostMessageSource.KlarityAdminAuth) {
        const { payload } = data;

        // set only the 1st message received
        setAuthData((state) => state || payload);
      }
    },
    [adminUri],
  );

  useEvent('message', handleMessage);
  const [isReady] = useTimeout(5000);

  useEffect(() => {
    if (window.opener) {
      const message: AuthMessage = {
        source: PostMessageSource.KlarityAdminAuth,
      };

      window.opener.postMessage(message, adminUri);
    }
  }, [adminUri]);

  // if page opened directly nothing to do here as it can't communicate with admin
  if (!window.opener) {
    return <Navigate to="/" replace />;
  }

  if (!authData) {
    if (isReady()) {
      return (
        <Paragraph className="text-lg text-center">
          Oops, no auth data received. <br />
          Close this page and retry.
        </Paragraph>
      );
    }
    return <Paragraph className="text-lg text-center">Waiting for auth data....</Paragraph>;
  }

  const { username, token } = authData;

  return (
    <AuthImpersonate
      username={username!}
      token={token!}
      targetUsername={targetUsername!}
      onSignin={handleSignin}
    />
  );
};

export default Impersonate;
