import { Progress, Spinner, CheckboxIcon, IconButton } from '@demandscience/ui';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import reject from 'lodash/reject';

import { Aggregate as AggregateType, AggregateFilter } from 'types';
import { useContext, useState } from 'react';
import DataProfileContext from 'pages/Dashboard/Search/Results/DataProfileContext';
import BookMarkControl from 'components/BookMark/BookMarkControl';
import { Bookmark } from '@demandscience/ui/icons/index';

interface AggregateProps<F extends AggregateFilter> {
  aggregate: {
    count: number;
    values: AggregateType<F>[];
  };
  aggregateField: string;
  getLabel: (value: F) => string;
  icon: React.ElementType;
  id?: string;
  label: string;
  loading?: boolean;
  mouseOverFilterId: string;
  onChange: (value: F[]) => void;
  value?: F[];
}

const Aggregate = <F extends AggregateFilter>({
  label,
  icon,
  aggregate,
  value: selection = [],
  onChange,
  loading,
  getLabel,
  id,
  mouseOverFilterId,
  aggregateField,
}: AggregateProps<F>) => {
  const Icon = icon;

  const dpContext = useContext(DataProfileContext);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnterModule = () => {
    setIsHovered(true);
  };

  // Function to handle mouse leave event
  const handleMouseLeaveModule = () => {
    setIsHovered(false);
  };

  const handleMouseOver = (filterValue: string) => () => {
    if (dpContext) {
      const { setIsMouseOver, setMouseOverFilterId, setMouseOverFilterFieldId } = dpContext;

      setIsMouseOver(true);
      setMouseOverFilterId(mouseOverFilterId);
      setMouseOverFilterFieldId(filterValue);
    }
  };

  const handleMouseLeave = () => {
    if (dpContext) {
      const { setIsMouseOver, setMouseOverFilterId, setMouseOverFilterFieldId } = dpContext;

      setIsMouseOver(false);
      setMouseOverFilterId(undefined);
      setMouseOverFilterFieldId(undefined);
    }
  };

  const handleToggle = (value: F) => () => {
    if (some(selection, value)) {
      onChange(reject(selection, value) as F[]);
    } else {
      onChange([...selection, value]);
    }
  };
  return (
    <div
      id={id}
      className="flex flex-col rounded border"
      onMouseEnter={handleMouseEnterModule} // Handle mouse enter event
      onMouseLeave={handleMouseLeaveModule}
    >
      <div className="p-4 min-h-[310px]">
        <div className="flex flex-row items-center h-[48px] py-[14px]">
          <div className="pl-[13px] pr-3">
            <Icon className="w-5 h-5 text-primary-500" />
          </div>
          <div className="grow">
            <div className="text-sm text-gray-700 font-medium">{label}</div>
          </div>
          {isHovered && aggregate.count ? (
            <div className="h-[48px] flex items-center">
              <BookMarkControl
                module_type_name={label}
                aggregate={aggregate}
                aggregateField={aggregateField}
                module_type_id={mouseOverFilterId}
                borderless
                leftIcon={
                  <IconButton
                    className="hover:bg-gray-100 h-full"
                    size="sm"
                    theme="default"
                    aria-label="show customization options"
                    Icon={Bookmark}
                  />
                }
                className="ml-5"
                onSuccess={handleMouseLeaveModule}
              />
            </div>
          ) : null}
          {loading && <Spinner theme="primary" size="sm" />}
        </div>
        <div className="max-h-[230px] overflow-y-auto">
          {isEmpty(aggregate.values) && (
            <p className="text-sm text-slate-500">No data to display</p>
          )}
          {map(aggregate.values, ({ value, count, data }) => {
            const checked = some(selection, data);

            let mouseOverClass = '';
            if (dpContext) {
              const {
                isMouseOver,
                mouseOverFilterFieldId: ctxMouseOverFilterFieldId,
                mouseOverFilterId: ctxMouseOverFilterId,
              } = dpContext;

              if (
                isMouseOver &&
                value === ctxMouseOverFilterFieldId &&
                mouseOverFilterId === ctxMouseOverFilterId
              ) {
                mouseOverClass = 'bg-gray-100';
              }
            }

            return (
              <button
                key={value}
                className={`flex flex-row items-center gap-3 w-full p-2 transition hover:bg-gray-100 outline-none text-left ${mouseOverClass}`}
                onClick={handleToggle(data)}
                onMouseOver={handleMouseOver(value)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="flex-shrink-0 p-[5px]">
                  <CheckboxIcon size={20} theme="primary" checked={checked} />
                </div>
                <div className="w-full mr-1">
                  <div className="flex justify-between text-xs">
                    <span className="text-gray-800">{getLabel(data)}</span>
                    <span className="text-gray-500">{count.toLocaleString()}</span>
                  </div>
                  <Progress
                    value={Math.floor((count / aggregate.count) * 100)}
                    size="sm"
                    theme={!checked ? 'gray' : 'primary'}
                    variant="solid"
                    className="mt-1"
                  />
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Aggregate;
