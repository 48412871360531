import { forwardRef } from 'react';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';

export interface MenuItemProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title' | 'chidlren'> {
  leadingContent?: React.ReactNode;
  subtitle?: React.ReactNode;
  subtitleClassName?: string;
  title: React.ReactNode;
  titleClassName?: string;
  trailingContent?: React.ReactNode;
}

const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  (
    {
      className,
      leadingContent,
      title,
      subtitle,
      trailingContent,
      titleClassName,
      subtitleClassName,
      ...props
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={classNames('flex flex-row items-center gap-4 px-4 py-2 select-none', className)}
      {...props}
    >
      {leadingContent && <div className="flex-shrink-0">{leadingContent}</div>}
      <div className="flex-1 min-w-0">
        <div
          className={overrideTailwindClasses(classNames('text-sm text-gray-700', titleClassName))}
        >
          {title}
        </div>
        {subtitle && (
          <div
            className={overrideTailwindClasses(classNames('text-xs text-gray-500', titleClassName))}
          >
            {subtitle}
          </div>
        )}
      </div>
      {trailingContent && <div className="flex-shrink-0">{trailingContent}</div>}
    </div>
  ),
);

MenuItem.displayName = 'MenuItem';

export default MenuItem;
