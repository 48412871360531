import { useCallback, useContext } from 'react';
import { Button } from '@demandscience/ui';
import { ExportType, Model } from 'types';
import ExportDropdown, { ExportDropdownProps } from './ExportDropdown';
import ExportContext, { ExportContextInterface } from './ExportContext';

interface ExportControlProps extends ExportDropdownProps {
  as?: React.ElementType;
  record: Model;
}

const ExportControl = ({ record, as: Component = Button, ...props }: ExportControlProps) => {
  const { exportTo } = useContext(ExportContext) as ExportContextInterface;

  const handleExportClick = useCallback(
    (type: ExportType) => () => {
      exportTo(type, { dsid: [record.dsid] });
    },
    [exportTo, record.dsid],
  );

  return (
    <ExportDropdown
      className="block w-full"
      withPortal
      buttonType="custom"
      customButton={<Component {...props} />}
      onClick={handleExportClick}
      hideReveal
    />
  );
};

export default ExportControl;
