import { useState, useMemo, useCallback } from 'react';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import TAMOverlay from './Overlay';
import {
  CompanyFilterName,
  CompanyFilters,
  ContactFilterName,
  Filters,
  ListFilterName,
  ResultsView,
} from 'types';

interface ExploreTAMButtonProps {
  companyCount: number;
  companyFilters: CompanyFilters;
  contactCount: number;
  filters: Filters;
  onTabChange: (tab: ResultsView) => void;
  setFilters: (filters: Filters) => void;
}

const ExploreTAMButton = ({
  filters,
  companyFilters,
  setFilters,
  onTabChange,
  ...props
}: ExploreTAMButtonProps) => {
  const [open, setOpen] = useState(false);

  const showExplore = useMemo(() => {
    const {
      [CompanyFilterName.CompanyName]: companyName,
      [ContactFilterName.ContactName]: contactName,
      [ListFilterName.InclusionList]: inclusionList,
    } = filters;

    return (
      some(companyName, ({ exclude }) => !exclude) ||
      some(contactName, ({ exclude }) => !exclude) ||
      !isEmpty(inclusionList)
    );
  }, [filters]);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSetFilters = useCallback(
    (newFilters: Filters, tab?: ResultsView) => {
      handleClose();

      setFilters(newFilters);

      if (tab) {
        onTabChange(tab);
      }
    },
    [handleClose, onTabChange, setFilters],
  );

  if (!showExplore) {
    return null;
  }

  return (
    <>
      <button
        className="w-full text-sm align-center py-4 text-primary-500 transition bg-primary-100 hover:bg-primary-200"
        onClick={handleClick}
      >
        Explore TAM
      </button>
      <TAMOverlay
        open={open}
        onClose={handleClose}
        filters={filters}
        companyFilters={companyFilters}
        setFilters={handleSetFilters}
        {...props}
      />
    </>
  );
};

export default ExploreTAMButton;
