import CompanyAvatar from 'components/Company/CompanyAvatar';
import CompanyLocation from 'components/Company/CompanyLocation';
import CompanyName from 'components/Company/CompanyName';
import CompanySocialProfiles from 'components/Company/CompanySocialProfiles';
import Headcount from 'components/Company/Headcount';
import Industry from 'components/Company/Industry';
import Revenue from 'components/Company/Revenue';
import SubIndustry from 'components/Company/SubIndustry';
import { TablCellProps, TableCell } from 'components/Layout/Table';
import { Company, CompanyTableColumn } from 'types';
import { stopPropagation } from 'utils/event';
import ContactDetailsCell from './ContactDetailsCell';
import Specialities from 'components/Company/Specialities';
import InstallTopic from 'components/Company/InstallTopic';
import IntentTopic from 'components/Company/IntentTopic';

interface DataGridTableCompanyCellProps extends TablCellProps {
  column: CompanyTableColumn;
  displayContactDetailsOnTop?: boolean;
  row: Company;
}

const DataGridTableCompanyCell = ({
  column,
  row,
  displayContactDetailsOnTop,
  ...props
}: DataGridTableCompanyCellProps) => {
  if (column === 'contact_details') {
    return (
      <TableCell width={32} className="flex" {...props}>
        <ContactDetailsCell
          className="-ml-2"
          row={row}
          displayContactDetailsOnTop={displayContactDetailsOnTop}
        />
      </TableCell>
    );
  }

  if (column === 'industry') {
    return (
      <TableCell width={64} {...props}>
        <Industry inline className="ellipsis text-gray-600" row={row} />
      </TableCell>
    );
  }

  if (column === 'headcount') {
    return (
      <TableCell width={32} {...props}>
        <Headcount className="text-gray-600" inline row={row} suffix="" />
      </TableCell>
    );
  }

  if (column === 'revenue') {
    return (
      <TableCell width={32} {...props}>
        <Revenue className="text-gray-600" inline row={row} />
      </TableCell>
    );
  }

  if (column === 'company_name') {
    return (
      <TableCell className="z-[1] left-[52px]" sticky width={64} {...props}>
        <div className="w-full flex flex-row items-center gap-4">
          <CompanyAvatar className="w-8 h-8 text-sm rounded" row={row} type="square" colored />
          <CompanyName className="grow ellipsis text-gray-600" row={row} clickable />
          <CompanySocialProfiles
            iconWrapperClassName="p-0"
            useBrandColors
            inline
            row={row}
            onClick={stopPropagation}
          />
        </div>
      </TableCell>
    );
  }

  if (column === 'company_location') {
    return (
      <TableCell width={64} {...props}>
        <CompanyLocation className="ellipsis text-gray-600" inline row={row} />
      </TableCell>
    );
  }

  if (column === 'company_address') {
    return (
      <TableCell width={64} {...props}>
        <CompanyLocation
          className="ellipsis text-gray-600"
          inline
          row={row}
          field="address_line_1"
        />
      </TableCell>
    );
  }

  if (column === 'subindustry') {
    return (
      <TableCell width={64} {...props}>
        <SubIndustry className="ellipsis text-gray-600" inline row={row} />
      </TableCell>
    );
  }

  if (column === CompanyTableColumn.IntentTopic) {
    return (
      <TableCell width={64} {...props}>
        <IntentTopic className="ellipsis text-gray-600" inlineText row={row} />
      </TableCell>
    );
  }

  if (column === CompanyTableColumn.InstallTopic) {
    return (
      <TableCell width={64} {...props}>
        <InstallTopic className="ellipsis text-gray-600" inlineText row={row} />
      </TableCell>
    );
  }

  if (column === CompanyTableColumn.Specialities) {
    return (
      <TableCell width={64} {...props}>
        <Specialities className="ellipsis text-gray-600" inlineText row={row} />
      </TableCell>
    );
  }

  return <TableCell width={32} {...props} />;
};

export default DataGridTableCompanyCell;
