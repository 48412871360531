import useAuth from 'components/Auth/useAuth';
import { Modal, ModalProps } from '@demandscience/ui';

import Form from './ManageCreditsForm';

interface AcceptCreditsRequestOverlayProps extends Omit<ModalProps, 'children'> {}

const AcceptCreditsRequestOverlay = (props: AcceptCreditsRequestOverlayProps) => {
  const { onClose } = props;
  const { user } = useAuth();

  return (
    <Modal
      aria-labelledby="accept-credits-request-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="accept-credits-request-dialog-title" className="flex mb-7">
        Change credits allocation
      </Modal.Title>
      <Form user={user!} onSuccess={onClose} onCancel={onClose} />
    </Modal>
  );
};

export default AcceptCreditsRequestOverlay;
