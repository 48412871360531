import { ModelType } from 'types';
import { Modal } from '@demandscience/ui';
import { HTMLAttributes } from 'react';
import UploadListForm from './UploadListForm';

export interface UploadListOverlayProps extends HTMLAttributes<HTMLDivElement> {
  onClose: () => void;
  open: boolean;
  type?: ModelType;
}

const UploadListOverlay = ({ type, ...props }: UploadListOverlayProps) => {
  const { onClose } = props;

  return (
    <Modal
      aria-labelledby="create-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      {...props}
    >
      <Modal.Title id="create-list-dialog-title" className="flex mb-4">
        Upload a list
      </Modal.Title>
      <UploadListForm onCancel={onClose} type={type} />
    </Modal>
  );
};

export default UploadListOverlay;
