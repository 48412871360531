import Drawer from 'components/Drawer';
import React, { useCallback } from 'react';
import { Company, Contact, Model, ModelType, ResultsView } from 'types';
import ContactFullInfo from 'components/Contact/ContactFullInfo';
import CompanyFullInfo from 'components/Company/CompanyFullInfo';
import { IconButton, Checkbox, DrawerRootProps } from '@demandscience/ui';
import ChevronLeftIcon from '@demandscience/ui/icons/chevron-left';
import ChevronRightIcon from '@demandscience/ui/icons/chevron-right';
import { CompanyFilterName } from 'types/filters';
import { useNavigate } from 'react-router-dom';

interface DetailSidebarProps extends DrawerRootProps {
  kind: ModelType;
  onNext?: () => void;
  onPrev?: () => void;
  onSelectChange: (checked: boolean) => void;
  row?: Model;
  selected: boolean;
  selectionDisabled?: boolean;
}

const DetailSidebar: React.FC<DetailSidebarProps> = ({
  kind,
  selected,
  selectionDisabled,
  onSelectChange,
  row,
  onPrev,
  onNext,
  ...props
}) => {
  const navigate = useNavigate();
  const { onClose } = props;

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      const { code } = e;

      if (code === 'ArrowLeft' && onPrev) {
        onPrev();
      } else if (code === 'ArrowRight' && onNext) {
        onNext();
      }
    },
    [onNext, onPrev],
  );

  const handleView = useCallback(
    (view: string) => {
      onClose && onClose();

      const company = kind === ModelType.Company ? (row as Company) : (row as Contact).companies[0];

      if (view === 'employees') {
        const { dsid, company_name } = company;
        const filters = {
          [CompanyFilterName.CompanyName]: [{ dsid, name: company_name }],
        };

        navigate('/search', {
          state: {
            filters,
            tab: ResultsView.Contacts,
            contactsOf: company,
          },
        });
      } else if (view === 'similarCompanies') {
        const { industry, employees_size_range, offices, dsid, company_name } = company;
        const [{ country }] = offices;

        const filters = {
          [CompanyFilterName.CompanyIndustry]: [industry],
          [CompanyFilterName.CompanyEmployees]: [employees_size_range],
          [CompanyFilterName.CompanyLocation]: [{ designation: 'country', country }],
          [CompanyFilterName.CompanyName]: [{ dsid, name: company_name, exclude: true }], // prevent current company to appear in search results
        };

        navigate('/search', {
          state: {
            filters,
            tab: ResultsView.Companies,
          },
        });
      }
    },
    [onClose, kind, row, navigate],
  );

  return (
    <Drawer
      id={kind === ModelType.Contact ? 'contact_detail_drawer' : 'company_detail_drawer'}
      title={kind === ModelType.Contact ? 'Contact profile' : 'Company detail'}
      onKeyDown={handleKeyDown}
      headingContent={
        <div className="flex flex-row">
          <IconButton
            size="sm"
            theme="default"
            onClick={onPrev}
            disabled={!onPrev}
            Icon={ChevronLeftIcon}
          />
          <Checkbox
            size="sm"
            checked={selected}
            onChange={onSelectChange}
            disabled={selectionDisabled}
          />
          <IconButton
            size="sm"
            theme="default"
            onClick={onNext}
            disabled={!onNext}
            Icon={ChevronRightIcon}
          />
        </div>
      }
      {...props}
    >
      <div>
        {kind === ModelType.Contact && row && (
          <ContactFullInfo row={row as Contact} onView={handleView} />
        )}
        {kind === ModelType.Company && row && (
          <CompanyFullInfo row={row as Company} onView={handleView} />
        )}
      </div>
    </Drawer>
  );
};

export default DetailSidebar;
