import { ReactSVG } from 'react-svg';
import illustration from './IllustrationNoUnreadNotification.svg';

const IllustrationNoUnreadNotification = (props: any) => (
  <ReactSVG
    data-testid="illustration-no-unread-notification"
    src={illustration}
    {...(props as any)}
  />
);
export default IllustrationNoUnreadNotification;
