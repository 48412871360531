import { createContext } from 'react';

export interface RevealSingleContactContextInterface {
  isLoading: boolean;
  revealSingleContact: (id: string) => void;
}

const RevealSingleContactContext = createContext<RevealSingleContactContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  RevealSingleContactContext.displayName = 'RevealSingleContactContext';
}

export default RevealSingleContactContext;
