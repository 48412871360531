import { Company } from 'types';
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';
import DollarSignIcon from '@demandscience/ui/icons/dollar-sign';
import ListItemText from 'components/Layout/ListItemText';

interface RevenueProps {
  className?: string;
  inline?: boolean;
  row: Company;
}

const Revenue: React.FC<RevenueProps> = ({ className, row, inline }) => {
  const { revenues } = row;

  if (!revenues) {
    return null;
  }

  if (inline) {
    return (
      <p className={overrideTailwindClasses(classNames('text-sm text-gray-700', className))}>
        {revenues.rev_range}
      </p>
    );
  }

  return (
    <ListItemText
      leadingContent={<DollarSignIcon className="w-5 h-5" />}
      overline="Revenue"
      title={revenues.rev_range}
    />
  );
};

export default Revenue;
