import classNames from 'classnames';
import { Outlet } from 'react-router-dom';

import Copyright from 'components/Copyright';
import Header from './Header';

interface SimpleLayoutProps {
  hideHeader?: boolean;
  maxWidthClass?: string;
}

const SimpleLayout = ({ maxWidthClass = 'max-w-4xl', hideHeader }: SimpleLayoutProps) => (
  <>
    {!hideHeader && <Header />}
    <main className="flex flex-col h-screen">
      <div className={classNames('my-8 mx-auto w-full h-full px-4 md:px-6', maxWidthClass)}>
        <Outlet />
      </div>
      <Copyright className="border-t px-6 py-4 mt-auto" />
    </main>
  </>
);

export default SimpleLayout;
