import { useLocation } from 'react-router-dom';
import useBookmark from './useOpenBookmark';
import BookmarkGraph from './BookmarkGraph';
import { OpenBookmarkHeader } from './OpenBookmarkHeader';
import { useEffect, useState } from 'react';
import { Progress } from '@demandscience/ui';
import classNames from 'classnames';
import LayersIcon from '@demandscience/ui/icons/layers';
import MapPinIcon from '@demandscience/ui/icons/map-pin';
import UsersIcon from '@demandscience/ui/icons/users';
import Building02Icon from '@demandscience/ui/icons/building-02';
import GridIcon from '@demandscience/ui/icons/grid';
import BookmarkSidebar from './BookmarkSidebar';
import { ModelType } from 'types';
import useFieldAggregate from 'hooks/useFieldAggregate';
import formatData from './FormatChartData';

const iconMap: { [key: string]: any } = {
  'Company location': MapPinIcon,
  Headcount: UsersIcon,
  Industry: Building02Icon,
  Department: GridIcon,
  'Job level': LayersIcon,
};

const OpenBookmark = () => {
  const location = useLocation();
  const moduleData = location.state ? location.state.id : null;
  const { data, isLoading } = useBookmark(moduleData);
  const [clickedLabels, setClickedLabelsIndex] = useState<number[]>([0]);
  const [includeNow, setincludeNow] = useState<boolean>(false);
  const [formattedData, setFormattedData] = useState({
    categories: [],
    data: [],
    sidebarCategories: [],
  });
  const [includedNames, setIncludedNames] = useState<string[]>([]);

  const handleToggleName = (name: string) => {
    setIncludedNames((prevNames) =>
      prevNames.includes(name) ? prevNames.filter((n) => n !== name) : [...prevNames, name],
    );
  };

  const shouldRun =
    data && data.filters && data.filters.aggregations && data.filters.aggregations.length > 0;
  const contactorCompany =
    shouldRun && data.filters.aggregations[0].field_name.split('_')[0] === 'company';
  const aggregateFilter = shouldRun ? data.filters.filters : null;

  const { aggregate } = useFieldAggregate(
    contactorCompany ? ModelType.Company : ModelType.Contact,
    shouldRun ? data.filters.aggregations[0].field_name : null,
    aggregateFilter,
    shouldRun,
  );
  useEffect(() => {
    if (data && data?.search_results.length > 0 && includedNames?.length === 0) {
      const initialNames = new Set<string>();
      data.search_results.forEach((result: any) => {
        Object.values(result.result.aggregation_results).forEach((aggregation: any) => {
          aggregation.forEach((entry: any) => {
            if (initialNames.size < 5) {
              initialNames.add(entry.value);
            }
          });
        });
      });
      setIncludedNames(Array.from(initialNames));
    }
  }, [data, data?.search_results, includedNames?.length]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const updatedData = formatData(data, includeNow, aggregate);
    setFormattedData(updatedData as any);
  }, [aggregate, data, includeNow, includedNames]);

  if (isLoading) {
    return (
      <Progress
        theme="primary"
        className={classNames('absolute top-0 bg-white h-[1.5px] invisible', {
          visible: true,
        })}
      />
    );
  }

  let { name, filters, id, automated_updates, visibility } = data;

  if (automated_updates === 'weekly') {
    automated_updates = 'bi-weekly';
  }

  return (
    <>
      <OpenBookmarkHeader
        name={name}
        filters={filters}
        id={id}
        automated_updates={automated_updates}
        visibility={visibility}
      ></OpenBookmarkHeader>
      <div className="flex flex-row gap-2">
        <BookmarkGraph
          formattedData={formattedData}
          clickedLabels={clickedLabels}
          setClickedLabelsIndex={setClickedLabelsIndex}
          label={data?.module_type_name}
          icon={iconMap[data?.module_type_name]}
          id={id}
          aggregate={aggregate}
          fieldName={data?.filters.aggregations[0].field_name}
          includeNow={includeNow}
          handleToggleName={handleToggleName}
          includedNames={includedNames}
        ></BookmarkGraph>
        <BookmarkSidebar
          filters={filters}
          categories={formattedData.sidebarCategories}
          setClickedLabelsIndex={setClickedLabelsIndex}
          comments={data?.comments}
          includeNow={includeNow}
          setincludeNow={setincludeNow}
          data={data}
          automated_updates={automated_updates}
          id={id}
        ></BookmarkSidebar>
      </div>
    </>
  );
};

export default OpenBookmark;
