import { useCallback, useState } from 'react';
import { Button } from '@demandscience/ui';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Show } from 'types';
import ListCombobox from 'components/Lists/ListCombobox';
import { AxiosError } from 'axios';
import useMutateList from 'components/Lists/useMutateList';

interface AddExclusionListFormProps {
  onCancel: () => void;
  onSuccess: () => void;
}

const AddExclusionListForm = ({ onSuccess, onCancel }: AddExclusionListFormProps) => {
  const [selectedListId, setSelectedListId] = useState<string | undefined>();
  const [apiError, setApiError] = useState<string | null>(null);
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const { updateGlobalExclusion } = useMutateList();

  const handleListSelection = useCallback((id: string) => {
    setSelectedListId(id);

    setApiError(null);
  }, []);

  const onSubmit: SubmitHandler<{}> = async () => {
    setApiError(null);

    if (!selectedListId) {
      return;
    }

    try {
      await updateGlobalExclusion.mutateAsync({ id: selectedListId, flag: true });

      onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || e.message);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        <ListCombobox
          value={selectedListId}
          onChange={handleListSelection}
          show={Show.Usable}
          showUploads
        />

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-2 mt-8">
        <Button borderless onClick={onCancel} type="button">
          Cancel
        </Button>
        <Button type="submit" theme="primary" disabled={!selectedListId || isSubmitting}>
          Add list
        </Button>
      </div>
    </form>
  );
};

export default AddExclusionListForm;
