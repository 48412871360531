import React, { cloneElement, forwardRef, MouseEventHandler } from 'react';
import classNames from 'classnames';
import PlusIcon from '@demandscience/ui/icons/plus';
import MinusIcon from '@demandscience/ui/icons/minus';
import Wrapper from './Inline/Wrapper';

interface FilterListItemProps extends React.HTMLAttributes<HTMLDivElement> {
  dense?: boolean;
  expanded?: boolean;
  filled?: boolean;
  icon: React.ReactElement;
  label: React.ReactElement;
  onClear?: () => void;
}

const FilterListItem = forwardRef<HTMLDivElement, FilterListItemProps>(
  ({ icon, label, filled, expanded, dense, onClear, children, className, ...props }, ref) => {
    const handleClear: MouseEventHandler<HTMLButtonElement> = (e) => {
      if (!expanded) {
        e.preventDefault();
      }

      if (onClear) onClear();
    };

    return (
      <Wrapper open={expanded}>
        <div
          ref={ref}
          className={classNames('px-4 flex flex-row gap-4', className, {
            'py-2': dense,
            'py-4': !dense,
          })}
          {...props}
        >
          {icon && (
            <div
              className={classNames('flex-shrink-0', {
                'text-gray-700': !filled && !expanded,
                'text-blue-500': filled || expanded,
              })}
            >
              {cloneElement(icon, { size: 18 })}
            </div>
          )}
          <div className="flex-grow">
            <div className="flex flex-row items-center gap-2">
              <div className="text-sm text-gray-700 font-medium">{label}</div>
              <div className="grow" />
              {filled && onClear && (
                <button className="text-xs text-blue-500" onClick={handleClear}>
                  Clear
                </button>
              )}
              {!expanded && <PlusIcon className="stroke-gray-700" size={18} />}
              {expanded && <MinusIcon className="stroke-gray-700" size={18} />}
            </div>
          </div>
        </div>
        <div className={classNames({ hidden: !filled && !expanded })}>{children}</div>
      </Wrapper>
    );
  },
);

FilterListItem.displayName = 'FilterListItem';

export default FilterListItem;
