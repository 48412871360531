import { useCallback, useEffect } from 'react';
import { ListRecord } from 'types';
import useModal from 'hooks/useModal';
import { IconButton, IconButtonProps } from '@demandscience/ui';
import useLinkedInExtension from 'components/LinkedInExtension/useLinkedInExtension';
import LinkedInExtensionInstallOverlay from 'components/LinkedInExtension/InstallOverlay';
import LinkedinIcon from '@demandscience/ui/icons/LinkedinBrand';
import TextTooltip from 'components/Tooltip/TextTooltip';

interface OpenListControlProps extends IconButtonProps {
  list: ListRecord;
}

const OpenListControl = ({ list, ...props }: OpenListControlProps) => {
  const { open, openModal, closeModal } = useModal();
  const { installed, openList } = useLinkedInExtension();

  const handleClick = useCallback(() => {
    if (installed) {
      openList(list);
    } else {
      openModal();
    }
  }, [installed, list, openList, openModal]);

  useEffect(() => {
    // close linkedin install overlay if install state changes
    if (installed && open) {
      closeModal();
    }
  }, [installed, open, closeModal]);

  return (
    <>
      <TextTooltip title="Browse on LinkedIn" placement="bottom" showArrow>
        <IconButton
          className="hover:bg-gray-100 text-gray-500 stroke-0"
          size="sm"
          theme="default"
          Icon={LinkedinIcon}
          onClick={handleClick}
          {...props}
        />
      </TextTooltip>
      <LinkedInExtensionInstallOverlay open={open} onClose={closeModal} />
    </>
  );
};

export default OpenListControl;
