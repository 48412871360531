import { useMutation, useQueryClient } from '@tanstack/react-query';

import { requestCredits as requestCurrentUserCredits } from 'api/account';
import * as organizationApi from 'api/organization';

const useMutateCredits = (options?: Record<string, any>) => {
  const queryClient = useQueryClient();

  const requestCredits = useMutation(requestCurrentUserCredits, {
    ...options,
  });

  const acceptCreditsRequest = useMutation(organizationApi.acceptCreditsRequest, {
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries(['notifications']);
    },
  });

  const rejectCreditsRequest = useMutation(organizationApi.rejectCreditsRequest, {
    ...options,
    onSettled: () => {
      queryClient.invalidateQueries(['notifications']);
    },
  });

  const acquireExtraCredits = useMutation(organizationApi.acquireExtraCredits, {
    ...options,
  });

  return { requestCredits, acceptCreditsRequest, rejectCreditsRequest, acquireExtraCredits };
};

export default useMutateCredits;
