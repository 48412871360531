import useMutateSubscriptionPlan from 'components/Organization/useMutateSubscriptionPlan';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { Modal, ModalProps } from '@demandscience/ui';
import { useCallback } from 'react';

import Form from './RequestForm';

interface ChangePlanOverlayProps extends Omit<ModalProps, 'children'> {}

const ChangePlanOverlay = ({ title, ...props }: ChangePlanOverlayProps) => {
  const { onClose } = props;
  const { changePlan } = useMutateSubscriptionPlan();
  const { showMessage } = useSnackbar();

  const handleSuccess = useCallback(() => {
    onClose();
    showMessage('Your request has been sent', 'success');
  }, [onClose, showMessage]);

  return (
    <Modal
      aria-labelledby="edit-search-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      {...props}
    >
      <Modal.Title id="edit-search-dialog-title" className="flex mb-7">
        {title}
      </Modal.Title>
      <div className="text-sm text-gray-900 mb-3">
        What more of Klarity? Great, our support team will get back to you.
      </div>
      <Form mutation={changePlan} onSuccess={handleSuccess} onCancel={onClose} />
    </Modal>
  );
};

export default ChangePlanOverlay;
