import { Company } from 'types';
import StarIcon from '@demandscience/ui/icons/star';
import ListItemText from 'components/Layout/ListItemText';
import { isEmpty, join, map, size } from 'lodash';
import TopicBadge from './TopicBadge';
import TopicList from './TopicList';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';

interface SpecialitiesProps {
  className?: string;
  inline?: boolean;
  inlineText?: boolean;
  row: Company;
}
const Specialities = ({ className, row, inline, inlineText }: SpecialitiesProps) => {
  const { business_keywords: keywords } = row;
  const listItems = map(keywords, (keyword) => ({
    label: keyword,
  }));

  if (isEmpty(listItems)) {
    return null;
  }

  if (inlineText) {
    const intentTopics = join(map(listItems, 'label'), ', ');

    return <Paragraph className={className}>{intentTopics}</Paragraph>;
  }

  if (inline) {
    const count = size(listItems);

    return (
      <TopicBadge className={className} Icon={StarIcon} count={count} title="Specialities matches">
        <TopicList items={listItems} />
      </TopicBadge>
    );
  }

  return (
    <ListItemText
      leadingContent={<StarIcon className="w-5 h-5" />}
      overline="Specialities matches"
      title={<TopicList className="text-sm" items={listItems} show={3} />}
    />
  );
};

export default Specialities;
