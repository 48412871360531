import { Contact } from 'types';
import classNames from 'classnames';

import LinkIcon from '@demandscience/ui/icons/link';
import LinkedinIcon from '@demandscience/ui/icons/LinkedinBrand';
import TwitterIcon from '@demandscience/ui/icons/TwitterBrand';
import FacebookIcon from '@demandscience/ui/icons/FacebookBrand';

import { Link, IconButton } from '@demandscience/ui';
import ListItemText from 'components/Layout/ListItemText';
import { isEmpty, map } from 'lodash';
import { overrideTailwindClasses } from 'tailwind-override';

interface ContactSocialProfilesProps {
  className?: string;
  iconWrapperClassName?: string;
  inline?: boolean;
  onClick?: (e: any) => void;
  row: Contact;
  useBrandColors?: boolean;
}

const ContactSocialProfiles = ({
  row,
  className,
  inline,
  useBrandColors,
  iconWrapperClassName,
  ...props
}: ContactSocialProfilesProps) => {
  const { linkedin_profiles, twitter_profiles, facebook_profiles } = row;

  if (isEmpty(linkedin_profiles) && isEmpty(twitter_profiles) && isEmpty(facebook_profiles)) {
    return null;
  }

  const profileHandleIsObfuscated = (profile_handle: string) => {
    return profile_handle.startsWith('***');
  };

  const socialLinks = (
    <div className={classNames('inline-flex flex-row', className)} {...props}>
      {map(linkedin_profiles, ({ profile_handle }) => {
        if (profileHandleIsObfuscated(profile_handle)) {
          return (
            <IconButton
              key={profile_handle}
              className={classNames('w-[24px] h-[24px]', {
                'text-gray-400': inline && !useBrandColors,
                'text-gray-500': !inline && !useBrandColors,
                'text-linkedin hover:text-linkedin': useBrandColors,
              })}
              size="xs"
              theme="default"
            >
              <div className={overrideTailwindClasses(classNames('p-[3px]', iconWrapperClassName))}>
                <LinkedinIcon size={18} />
              </div>
            </IconButton>
          );
        } else {
          return (
            <IconButton
              key={profile_handle}
              className={classNames({
                'text-gray-400': inline && !useBrandColors,
                'text-gray-500': !inline && !useBrandColors,
                'text-linkedin hover:text-linkedin': useBrandColors,
              })}
              size="xs"
              as={Link}
              theme="default"
              href={`https://www.linkedin.com/in/${profile_handle}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={overrideTailwindClasses(classNames('p-[3px]', iconWrapperClassName))}>
                <LinkedinIcon size={18} />
              </div>
            </IconButton>
          );
        }
      })}
      {map(twitter_profiles, ({ profile_handle }) => (
        <IconButton
          key={profile_handle}
          className={classNames({
            'text-gray-400': inline && !useBrandColors,
            'text-gray-500': !inline && !useBrandColors,
            'text-twitter hover:text-twitter': useBrandColors,
          })}
          size="xs"
          as={Link}
          href={`https://twitter.com/${profile_handle}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={overrideTailwindClasses(classNames('p-[3px]', iconWrapperClassName))}>
            <TwitterIcon size={18} />
          </div>
        </IconButton>
      ))}
      {map(facebook_profiles, ({ profile_handle }) => (
        <IconButton
          key={profile_handle}
          className={classNames({
            'text-gray-400': inline && !useBrandColors,
            'text-gray-500': !inline && !useBrandColors,
            'text-facebook hover:text-facebook': useBrandColors,
          })}
          size="xs"
          as={Link}
          href={`https://facebook.com/${profile_handle}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={overrideTailwindClasses(classNames('p-[3px]', iconWrapperClassName))}>
            <FacebookIcon size={18} />
          </div>
        </IconButton>
      ))}
    </div>
  );

  if (inline) {
    return socialLinks;
  }

  return <ListItemText leadingContent={<LinkIcon className="w-5 h-5" />} title={socialLinks} />;
};

export default ContactSocialProfiles;
