import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  createList,
  deleteList,
  updateList,
  updateListContent,
  uploadListFile,
  setGlobalExclusion,
} from 'api/list';
import { defer, isEmpty } from 'lodash';
import { useTracking } from 'react-tracking';
import { ModelType } from 'types';
import { CreateListEvent } from 'types/tracking';

const useMutateList = (options?: Record<string, any>) => {
  const { trackEvent } = useTracking<CreateListEvent>({
    type: 'event',
  });
  const queryClient = useQueryClient();

  const invalidateQuery = () => {
    queryClient.invalidateQueries(['lists', 'search']);
    queryClient.invalidateQueries(['lists', 'infinite']);
  };

  const resetQuery = () => {
    queryClient.resetQueries(['lists', 'search']);
    queryClient.resetQueries(['lists', 'infinite']);
  };

  const invalidateSearchQueries = () => {
    // invalidate search queries as the results might be affected by suppression list change
    queryClient.invalidateQueries(['search', ModelType.Contact]);
    queryClient.invalidateQueries(['search', ModelType.Company]);
  };

  const invalidateActivity = () => {
    // invalidate activity stats query
    queryClient.invalidateQueries(['activity', 'monthly']);
  };

  const create = useMutation(createList, {
    onSuccess: (_, params) => {
      invalidateQuery();

      invalidateActivity();

      trackEvent({
        event: 'create_list',
        upload: !isEmpty(params.ID),
        visibility: params.visibility,
        recordType: params.records_type,
        notify: !isEmpty(params.notification_message),
      });
    },
    ...options,
  });

  const destroy = useMutation(deleteList, {
    onSuccess: () => {
      // query invalidation is deferred to prevent re-fetching of queries for just deleted list
      // that happens when list is deleted from details page
      defer(invalidateQuery);

      // invalidate search queries as deleted list might be global suppression list which affects search results
      invalidateSearchQueries();

      invalidateActivity();
    },
    ...options,
  });

  const update = useMutation(updateList, {
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(['lists', params.id!], { exact: true });

      invalidateQuery();
    },
    ...options,
  });

  const updateGlobalExclusion = useMutation(setGlobalExclusion, {
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(['lists', params.id], { exact: true });

      invalidateQuery();

      invalidateSearchQueries();
    },
    ...options,
  });

  const updateContent = useMutation(updateListContent, {
    onSuccess: (_, params) => {
      if (params.method === 'add' || params.method === 'remove' || params.method === 'move') {
        queryClient.invalidateQueries(['lists', params.source_list_id!], { exact: true });
      }

      if (params.method === 'move' || params.method === 'copy') {
        queryClient.invalidateQueries(['lists', params.target_list_id!]);
      }

      resetQuery();

      // invalidate search queries as updated list might be global suppression list which affects search results
      // NOTE: following line was commented as it was causing advanced search (max contacts per company) count to be refetched
      // after doing an export to a list
      // invalidateSearchQueries();
    },
    ...options,
  });

  const upload = useMutation(uploadListFile, options);

  return {
    create,
    update,
    destroy,
    updateContent,
    upload,
    updateGlobalExclusion,
  };
};

export default useMutateList;
