import Menu from './Menu';
import Phone from './Phone';
import Website from './Website';
import Revenue from './Revenue';
import { Company, KlarityFeature, ModelType } from 'types';
import Industry from './Industry';
import Headcount from './Headcount';
import CompanyName from './CompanyName';
import CompanyAvatar from './CompanyAvatar';
import CompanyLocation from './CompanyLocation';
import ListItemText from 'components/Layout/ListItemText';
import CompanySocialProfiles from './CompanySocialProfiles';
import ExportProvider from 'components/Export/ExportProvider';
import ExportControl from 'components/Export/ExportControl';
import { Button } from '@demandscience/ui';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';
import ListDivider from 'components/Layout/ListDivider';
import EmployeesInfo from './EmployeesInfo';
import IntentTopic from './IntentTopic';
import InstallTopic from './InstallTopic';
import Specialities from './Specialities';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

interface CompanyFullInfoProps {
  hideContactsFilter?: true;
  hideExport?: true;
  hidePhone?: true;
  hideSocialProfiles?: true;
  onView?: (view: string) => void;
  row: Company;
}

const CompanyFullInfo = ({
  row,
  hideContactsFilter,
  hideExport,
  hidePhone,
  hideSocialProfiles,
  onView,
}: CompanyFullInfoProps) => {
  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const { featureStatus: isContactDisabled } = useFeatureFlag(KlarityFeature.Contacts);

  return (
    <>
      <ListItemText
        leadingContent={<CompanyAvatar className="h-8 w-8" row={row} colored />}
        title={<CompanyName className="font-semibold" row={row} />}
        trailingContent={onView && <Menu onView={onView} />}
      />
      <Website row={row} />
      {!hidePhone && <Phone row={row} />}
      {!hideExport && (
        <ExportProvider modelType={ModelType.Company} selectionCount={1}>
          <ListItemText
            title={
              <ExportControl
                record={row}
                as={Button}
                className="w-full whitespace-nowrap"
                outline
                // @ts-expect-error demandscience-ui issue
                size="sm"
                rightIcon={<ChevronDownIcon size={18} />}
              >
                Add company to
              </ExportControl>
            }
          />
        </ExportProvider>
      )}
      {!hideSocialProfiles && <CompanySocialProfiles row={row} />}
      <Industry row={row} />
      <CompanyLocation row={row} />
      <Headcount row={row} />
      <Revenue row={row} />
      <Specialities row={row} />
      <IntentTopic row={row} />
      <InstallTopic row={row} />
      {!hideContactsFilter && !isContactDisabled && !isContactRenderDisabled && (
        <>
          <ListDivider />
          <ListItemText title={<EmployeesInfo row={row} />} />
        </>
      )}
    </>
  );
};

export default CompanyFullInfo;
