import { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button } from '@demandscience/ui';

import { UpdateEmailPayload } from 'types';

import EmailEditorControl from './EmailEditorControl';

export interface EmailEditorProps {
  disabled?: true;
  value: string;
}

const EmailEditor = ({ value: currentEmail, disabled }: EmailEditorProps) => {
  const [edit, setEdit] = useState(false);
  const { control, setValue, formState, watch, reset } = useForm<UpdateEmailPayload>({
    mode: 'onChange',
    defaultValues: { new: undefined, confirm: undefined },
  });
  const { isSubmitting, errors } = formState;

  const emailValue = watch('new');
  const confirmEmailValue = watch('confirm');

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const isEmailNew = currentEmail !== emailValue;
  const isEmailConfirmed = emailValue === confirmEmailValue;
  const isNewEmailConfirmed = isEmailNew && isEmailConfirmed;
  const confirmedNewEmail = isNewEmailConfirmed ? confirmEmailValue : '';
  const isValidEmails = emailRegex.test(emailValue!) && emailRegex.test(confirmEmailValue!);

  const handleEditClick = useCallback(() => {
    if (edit === false) {
      setValue('new', '');
      setValue('confirm', '');
      setEdit((state) => !state);
    }
  }, [edit, setValue]);

  const handleCancel = useCallback(() => {
    setEdit(false);
    setValue('new', currentEmail);
    setValue('confirm', '');
    reset();
  }, [setValue, currentEmail, reset]);

  return (
    <form autoComplete="off">
      <div className="space-y-6">
        <Controller
          name="new"
          control={control}
          defaultValue={currentEmail}
          render={({ field }) => (
            <TextField
              label={edit ? 'New email' : 'Email'}
              required
              error={errors.new?.message}
              variant="outlined"
              type="email"
              placeholder="Email"
              onClick={handleEditClick}
              disabled={disabled}
              {...field}
            />
          )}
          rules={{
            required: 'Required field',
            validate: (value) => {
              return currentEmail !== value || 'Please enter a new email';
            },
            pattern: {
              value: emailRegex,
              message: "Make sure it's a valid email",
            },
          }}
        />
        {edit && (
          <Controller
            name="confirm"
            control={control}
            render={({ field }) => (
              <TextField
                label="Confirm new email"
                required
                error={errors.confirm?.message}
                variant="outlined"
                type="email"
                placeholder="Enter new email"
                onClick={handleEditClick}
                disabled={disabled}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                {...field}
              />
            )}
            rules={{
              required: 'Required field',
              pattern: {
                value: emailRegex,
                message: "Make sure it's a valid email",
              },
            }}
          />
        )}

        {edit && !!confirmEmailValue && isEmailNew && !isNewEmailConfirmed && (
          <div className="my-3">
            <p className="text-red-600 text-sm cursor-default">
              Please make sure the new email and the confirmation email match.
            </p>
          </div>
        )}
      </div>
      {edit && (
        <div className="flex items-center justify-end mt-4 gap-2">
          <Button borderless theme="primary" type="button" outline onClick={handleCancel}>
            Cancel
          </Button>
          <EmailEditorControl
            disabled={!isNewEmailConfirmed || (!emailValue && !confirmEmailValue) || !isValidEmails}
            confirmedNewEmail={confirmedNewEmail}
            isSubmitting={isSubmitting}
            setEdit={setEdit}
          >
            Update
          </EmailEditorControl>
        </div>
      )}
    </form>
  );
};

export default EmailEditor;
