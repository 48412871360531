import { useContext } from 'react';
import { ModelType } from 'types';

import SearchContext, { SearchContextInterface } from './SearchContext';

const useSearch = (kind: ModelType) => {
  const { [kind]: query } = useContext(SearchContext) as SearchContextInterface;

  return query;
};

export default useSearch;
