import { isEmpty } from 'lodash';
import useModal from 'hooks/useModal';
import ListsHeader from './ListsHeader';
import { DataGrid } from '@demandscience/ui';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultListParams from './DefaultListParams';
import CreateListOverlay from './CreateListOverlay';
import ListsEmptyResults from './ListsEmptyResults';
import { useLists } from 'components/Lists/useLists';
import { RowItem } from '@demandscience/ui/dist/types';
import ListsSkeleton from './Skeletons/ListsSkeleton';
import { columns } from 'components/Lists/ListsTableColumns';
import { ListFilter, ListsQueryResponse, ListStatus, Show } from 'types';
import useDataGridSearch from 'components/DataGrid/useDataGridSearch';
import classNames from 'classnames';

const defaultParams = defaultListParams();

const getRowClassName = ({ status }: RowItem) =>
  classNames({ 'text-gray-400': status === ListStatus.Processing });

const Lists = () => {
  const [recordsProcessing, setRecordsProcessing] = useState(false);
  const { open, openModal, closeModal } = useModal();
  const { params, handleFilterChange, handlePageChange, handleSortChange, query } =
    useDataGridSearch<ListFilter, ListsQueryResponse>(useLists, defaultParams, {
      refetchInterval: recordsProcessing ? 5 * 1000 : false,
    });
  const { data, isLoading, isFetching, isError, isPreviousData } = query;
  const navigate = useNavigate();

  const navigateToListDetailsPage = useCallback(
    ({ id, ...listData }: RowItem) => {
      navigate(id, { state: listData });
    },
    [navigate],
  );

  const handleClearFilters = useCallback(
    () => handleFilterChange({ show: Show.All }),
    [handleFilterChange],
  );

  const { lists, count } = data || {};
  const { index, count: rowsPerPage, filter } = params;

  useEffect(() => {
    setRecordsProcessing(lists?.some((list) => list.status === ListStatus.Processing) || false);
  }, [lists]);

  const pageHeader = <ListsHeader filters={params.filter} onChange={handleFilterChange} />;

  if (isLoading) {
    return (
      <>
        {pageHeader}
        <ListsSkeleton count={10} />
      </>
    );
  }

  if (isError) {
    return (
      <>
        {pageHeader}
        <div className="text-sm text-gray-500">Unexpected error loading lists list</div>
      </>
    );
  }

  return (
    <div className="pb-40">
      {pageHeader}
      {!isEmpty(lists) && (
        <DataGrid
          columns={columns}
          rows={lists || []}
          uniqueIdentifier="id"
          loading={isFetching && isPreviousData}
          disableInternalComputation
          sortingMode="server"
          onSortModelChange={handleSortChange}
          onRowClick={navigateToListDetailsPage}
          hideFooterRowCount
          page={index + 1}
          rowsPerPage={rowsPerPage}
          count={count}
          onPageChange={handlePageChange}
          paginationVariant="combined"
          className="isolate cursor-pointer"
          rowClassName={getRowClassName}
        />
      )}
      {isEmpty(lists) && !isFetching && filter && (
        <ListsEmptyResults
          filter={filter}
          onClick={openModal}
          onClearFilters={handleClearFilters}
        />
      )}
      <CreateListOverlay open={open} onClose={closeModal} />
    </div>
  );
};

export default Lists;
