import { MouseEventHandler, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import Paragraph from 'components/Typography/Paragraph';
import Tooltip, { TooltipProps } from './index';
import { getVirtualElement } from './utils';

export interface TextTooltipProps
  extends Pick<TooltipProps, 'placement' | 'showArrow' | 'offset' | 'onClose' | 'rootClassName'> {
  children: ReactElement;
  className?: string;
  delay?: number;
  title?: string | ReactElement;
  virtual?: true;
}

const TextTooltip = ({
  className,
  title,
  virtual,
  delay,
  children,
  ...props
}: TextTooltipProps) => {
  const anchorEl = useRef<HTMLSpanElement>(null);
  const [rect, setRect] = useState({ top: 0, bottom: 0, left: 0, right: 0 });
  const [showTimeout, setShowTimeout] = useState<NodeJS.Timeout | null>(null);
  const virtualEl = useMemo(() => getVirtualElement(rect), [rect]);
  const [open, setOpen] = useState(false);

  const handleMouseEnter: MouseEventHandler = useCallback(
    (e) => {
      // prevent tooltip showing when some of the mouse buttons is pressed
      if (e.buttons === 0) {
        if (delay) {
          const timeout = setTimeout(() => {
            setOpen(true);
          }, delay);

          setShowTimeout(timeout);
        } else {
          setOpen(true);
        }
      }
    },
    [delay],
  );

  const handleMouseMove: MouseEventHandler = useCallback(
    (e) => {
      const { currentTarget, clientX: x, clientY: y } = e;
      const { right, left } = currentTarget.getBoundingClientRect();

      if (props.placement === 'left') {
        setRect({
          top: y,
          right: left,
          bottom: y,
          left,
        });
      } else if (props.placement === 'right') {
        setRect({
          top: y,
          right,
          bottom: y,
          left: right,
        });
      } else {
        setRect({
          top: y,
          right: x,
          bottom: y,
          left: x,
        });
      }
    },
    [props.placement],
  );

  const handleMouseLeave = useCallback(() => {
    setOpen(false);

    if (showTimeout) {
      clearTimeout(showTimeout);
    }
  }, [showTimeout]);

  if (!title) {
    return children;
  }

  return (
    <>
      <span
        ref={anchorEl}
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={virtual ? handleMouseMove : undefined}
        onMouseDown={virtual ? handleMouseLeave : undefined}
      >
        {children}
      </span>
      <Tooltip
        open={open}
        anchorEl={virtual ? virtualEl : anchorEl.current}
        rootClassName="z-10 w-max max-w-xs"
        className="px-3 py-2 bg-gray-900 rounded-lg"
        {...props}
      >
        <Paragraph className="text-xs font-medium text-white">{title}</Paragraph>
      </Tooltip>
    </>
  );
};

export default TextTooltip;
