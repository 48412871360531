import { useState, useEffect } from 'react';
import { includes } from 'lodash';
import { Account, Visibility, Role } from 'types';

const useModelPermissions = (user?: Account, owner?: any, visibility?: Visibility) => {
  const [isEditable, setIsEditable] = useState(false);

  const canEditModel = (user?: Account, owner?: { username: string }, visibility?: Visibility) => {
    const isManager = includes(user?.roles, Role.Manager);
    const userOwnsModel = user && owner && user.username === owner.username;

    return userOwnsModel || (isManager && visibility === Visibility.Public);
  };

  useEffect(() => {
    setIsEditable(canEditModel(user, owner, visibility));
  }, [user, owner, visibility]);

  return {
    isEditable,
    canEditModel,
  };
};

export default useModelPermissions;
