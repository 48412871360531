import { useCallback } from 'react';
import classNames from 'classnames';
import { DropdownMenu, DropdownMenuProps, IconButton } from '@demandscience/ui';
import MoreVerticalIcon from '@demandscience/ui/icons/more-vertical';

import useModulesCustomization from 'components/ModulesCustomization/useModulesCustomization';
import { useNavigate } from 'react-router-dom';

interface DataProfileDropdownActionsProps extends DropdownMenuProps {}

const DataProfileDropdownActions = ({ className, ...props }: DataProfileDropdownActionsProps) => {
  const { setCustomizeModulesOpen } = useModulesCustomization() || {};

  const navigate = useNavigate();

  // Open the Modules Customization modal
  const handleCustomizeView = useCallback(() => {
    setCustomizeModulesOpen();
  }, [setCustomizeModulesOpen]);

  const openBookMarkHome = () => {
    navigate('/search/bookmark');
  };

  return (
    <DropdownMenu
      buttonType="custom"
      customButton={
        <IconButton
          className="hover:bg-gray-100"
          size="sm"
          theme="default"
          Icon={MoreVerticalIcon}
        />
      }
      className={classNames('grid place-items-center', className)}
      origin="bottom-right"
      {...props}
    >
      <DropdownMenu.Item onClick={openBookMarkHome} className="text-gray-700 font-normal">
        Bookmarks
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={handleCustomizeView} className="text-gray-700 font-normal">
        Customize view
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

export default DataProfileDropdownActions;
