import { useQueryClient } from '@tanstack/react-query';

import Splash from 'components/Splash';

import useAccount from 'components/Account/useAccount';
import useCognitoAuth from './Cognito/useCognitoAuth';
import AuthContext, { AuthContextInterface } from './AuthContext';
import { includes } from 'lodash';
import { Role } from 'types';
import { useCallback, useEffect, useMemo } from 'react';
import jwtInterceptor from 'api/jwtInterceptor';

interface AuthProviderProps {
  children: React.ReactNode;
  config: Record<string, any>;
  showSplash?: boolean;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ config, children, showSplash }) => {
  const queryClient = useQueryClient();
  const { loggedin, signOut, ...rest } = useCognitoAuth(config);
  const {
    data: user,
    isFetching,
    isSuccess,
  } = useAccount({
    enabled: loggedin === true,
    staleTime: Infinity, // keep user in cache forever
  });
  const isManager = includes(user?.roles, Role.Manager);
  const isLoading = !isSuccess && isFetching;

  // null loggedin indicates that it's authenticating against cognito
  // isLoading is initial true when account is initially loading
  const authInProgress = loggedin === null || isLoading;

  const handleSignOut = useCallback(
    async (global = false) => {
      await signOut(global);

      // clear query cache on signout
      const cache = queryClient.getQueryCache();
      cache.clear();
    },
    [queryClient, signOut],
  );

  useEffect(() => {
    // configure axios interceptor that will set jwt tokens on api calls
    // and in case of 401 response it will signout user
    jwtInterceptor(handleSignOut);
  }, [handleSignOut]);

  const value: AuthContextInterface = useMemo(
    () => ({
      user: loggedin ? user : undefined,
      isManager,
      signOut: handleSignOut,
      ...rest,
    }),
    [handleSignOut, isManager, loggedin, rest, user],
  );

  return (
    <AuthContext.Provider value={value}>
      {showSplash && <Splash show={authInProgress} timeout={1000} />}
      {!authInProgress && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
