import { useState, useEffect } from 'react';

import uniqBy from 'lodash/uniqBy';
import omit from 'lodash/omit';

import {
  AggregateField,
  AggregateData,
  CompanyFilterName,
  ContactFilterName,
  Filters,
  ModelType,
} from 'types';
import useAggregate from './useAggregate';

export const aggregateFieldsToFilterName = {
  [AggregateField.JobLevel]: ContactFilterName.JobLevel,
  [AggregateField.JobFunction]: ContactFilterName.JobFunction,
  [AggregateField.CompanyEmployees]: CompanyFilterName.CompanyEmployees,
  [AggregateField.CompanyLocationCountry]: CompanyFilterName.CompanyLocation,
  [AggregateField.CompanyIndustry]: CompanyFilterName.CompanyIndustry,
  [AggregateField.CompanyRevenue]: CompanyFilterName.CompanyRevenue,
};

const useFieldAggregate = (
  kind: ModelType,
  field: AggregateField,
  currentFilters: Filters,
  shouldRun = true,
) => {
  const aggregateFilters = omit(currentFilters, aggregateFieldsToFilterName[field]);

  const { data: { [field]: globalAggregate } = {}, isFetching: isFetchingGlobal } = useAggregate(
    kind,
    currentFilters,
  );
  const { data: { [field]: fieldAggregate, count = 0 } = {}, isFetching: isFetchingField } =
    useAggregate(kind, aggregateFilters);

  const [data, setData] = useState<{
    aggregate: {
      count: number;
      values: AggregateData<any>[];
    };
    isFetching: boolean;
  }>({
    aggregate: { values: [], count: 0 },
    isFetching: true,
  });

  useEffect(() => {
    if (!shouldRun) {
      return;
    }
    if (!isFetchingField && !isFetchingGlobal) {
      let aggregate;
      // update only after both aggregates are fetched
      if (globalAggregate && fieldAggregate) {
        if (currentFilters[aggregateFieldsToFilterName[field]]) {
          // combine two aggregates if there's a filter on given field
          aggregate = {
            values: uniqBy([...globalAggregate, ...fieldAggregate], 'value'),
            count,
          };
        } else {
          // use field aggregate only
          aggregate = { values: fieldAggregate, count };
        }
      } else {
        // empty aggregate when no aggregate data
        aggregate = {
          count: 0,
          values: [],
        };
      }

      setData({ aggregate, isFetching: false });
    } else {
      setData((state) => ({ ...state, isFetching: true }));
    }
  }, [
    isFetchingField,
    isFetchingGlobal,
    fieldAggregate,
    globalAggregate,
    count,
    currentFilters,
    field,
    shouldRun,
  ]);

  return data;
};

export default useFieldAggregate;
