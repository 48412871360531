import { createContext } from 'react';

export interface SupportRequestContextInterface {
  buyCredits: () => void;
  changePlan: () => void;
  confirmPlan: () => void;
  customPlan: () => void;
}

const SupportRequestContext = createContext<SupportRequestContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  SupportRequestContext.displayName = 'CreditsContext';
}

export default SupportRequestContext;
