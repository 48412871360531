import { useState, useEffect, useMemo } from 'react';

import { KlarityFeature, ResultsView } from 'types';
import useCustomization from 'components/SearchCustomization/useCustomization';
import TabsContext, { TabsContextInterface } from './TabsContext';
import { useLocation } from 'react-router-dom';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';
import { indexOf } from 'lodash';

interface TabsProviderProps {
  children: React.ReactNode;
}

const TabsProvider: React.FC<TabsProviderProps> = ({ children }) => {
  const { state } = useLocation();
  const {
    results: { tab: defaultTab },
  } = useCustomization();
  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const [tab, setTab] = useState<ResultsView>(
    isContactRenderDisabled && defaultTab === ResultsView.Contacts
      ? ResultsView.Companies
      : defaultTab,
  );

  const tabs: Array<ResultsView> = useMemo(
    () => [
      ...(isContactRenderDisabled ? [] : [ResultsView.Contacts]),
      ResultsView.Companies,
      ResultsView.DataProfile,
    ],
    [isContactRenderDisabled],
  );

  const getTabName = useMemo(
    () =>
      (index: number): ResultsView =>
        tabs[index],
    [tabs],
  );

  const getTabIndex = (tab: ResultsView) => {
    const index = indexOf(tabs, tab);

    return index === -1 ? 0 : index;
  };

  const selectedIndex = getTabIndex(tab);

  useEffect(() => {
    if (isContactRenderDisabled && defaultTab === ResultsView.Contacts) {
      setTab(ResultsView.Companies);
    } else {
      setTab(defaultTab);
    }
  }, [defaultTab, isContactRenderDisabled]);

  // update tab with a value from the current location state
  useEffect(() => {
    if (state?.tab) {
      setTab(state.tab);
    }
  }, [state]);

  const value: TabsContextInterface = useMemo(
    () => ({
      tab,
      setTab,
      getTabName,
      selectedIndex,
    }),
    [tab, getTabName, selectedIndex],
  );

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

export default TabsProvider;
