import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from '@demandscience/ui/icons';
import { AutoUpdate } from 'types';

interface SelectOption {
  label: string;
  value: AutoUpdate;
}

interface DropdownProps {
  onChange: (option: AutoUpdate) => void;
  options: SelectOption[];
  selectedOption: string;
  title: string;
}

const CustomDropdown: React.FC<DropdownProps> = ({ title, options, selectedOption, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div className=" border border-gray-300 rounded-md m-6 mb-2 ml-0">
      <div
        className="flex items-center justify-between cursor-pointer ml-3"
        onClick={toggleDropdown}
      >
        <div className="flex flex-col">
          <span className="text-xs" style={{ color: '#0066FC' }}>
            {title}
          </span>
          <span className="text-gray-800 ml-0 m-1">
            {options.find((option) => option.value === selectedOption)?.label}
          </span>
        </div>
        <div className="text-gray-500 mt-5 mr-3">{isOpen ? <ChevronUp /> : <ChevronDown />}</div>
      </div>
      {isOpen && (
        <div className="border-t border-gray-300">
          {options.map((option) => (
            <div
              key={option.value}
              className={`p-4 cursor-pointer hover:bg-gray-100 ${
                option.value === selectedOption ? 'bg-gray-100' : ''
              }`}
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
