import { CompanyFilterName, badgeDisplayCap } from 'types/filters';
import useIndustryOptions from './Options/useIndustryOptions';
import NestedSelectFilter from './NestedSelectFilter';
import { FilterProps } from './types';

const CompanyIndustryFilter = (props: FilterProps) => {
  const query = useIndustryOptions();

  return (
    <NestedSelectFilter
      {...props}
      name={CompanyFilterName.CompanyIndustry}
      placeholder="Enter industry"
      showIncludeExclude
      optionsQuery={query}
      by="category"
      nestedBy="sub_category"
      badgeDisplayCap={badgeDisplayCap}
    />
  );
};

export default CompanyIndustryFilter;
