interface NotFoundProps {
  className?: string;
}

const NotFound: React.FC<NotFoundProps> = ({ className }) => (
  <div className={className}>
    <h5 className="text-xl text-gray-700">Page not found</h5>
  </div>
);

export default NotFound;
