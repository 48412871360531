interface HeadingProps {
  description: string;
  title: string;
}

const Heading = ({ title, description }: HeadingProps) => (
  <div className="space-y-1 text-gray-700 mb-6">
    <h5 className="text-xl font-semibold">{title}</h5>
    <p className="text-base">{description}</p>
  </div>
);

export default Heading;
