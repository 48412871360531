import React from 'react';

import { ResultsView } from 'types';

export interface TabsContextInterface {
  getTabName: (index: number) => ResultsView;
  selectedIndex: number;
  setTab: (tab: ResultsView) => void;
  tab: ResultsView;
}

const Context = React.createContext<TabsContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  Context.displayName = 'TabsContext';
}

export default Context;
