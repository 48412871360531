import { noop } from 'lodash';
import { CompanyTableColumn, ContactTableColumn, tableColumns } from 'types';
import ColumnBox from './ColumnBox';
import DragHandle from '../../SortableList/DragHandle';

interface DragPreviewProps {
  column: ContactTableColumn | CompanyTableColumn;
}

const DragPreview = ({ column }: DragPreviewProps) => (
  <div className="cursor-grabbing">
    <ColumnBox label={tableColumns[column]} checked handle={<DragHandle />} onChange={noop} />
  </div>
);

export default DragPreview;
