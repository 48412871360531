import { ListFilterName } from 'types/filters';
import InExListsFilter from './InExListsFilter';
import { FilterProps } from './types';

const InclusionListFilter = (props: FilterProps) => {
  return (
    <InExListsFilter id="filter_by_inclusion_list" {...props} name={ListFilterName.InclusionList} />
  );
};

export default InclusionListFilter;
