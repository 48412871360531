import Avatar from 'components/Avatar';
import { ListRecord, Visibility, TableColumn } from 'types';
import ListTypeWidget from './ListTypeWidget';
import ListRecordActions from './ListRecordActions';
import BadgeShared from 'components/Layout/BadgeShared';
import OpenListControl from 'components/LinkedInExtension/OpenListControl';
import SearchListControl from './SearchListControl';
import { stopPropagation } from 'utils/event';

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const columns: TableColumn<ListRecord>[] = [
  {
    field: 'name',
    headerName: 'List name',
    sortable: true,
    valueGetter: ({ name, visibility }) => {
      if (visibility === Visibility.Private) {
        return <span>{name}</span>;
      }

      return (
        <div className="flex flex-row items-center gap-2">
          <span>{name}</span>
          <BadgeShared title="Shared list" />
        </div>
      );
    },
  },
  {
    field: 'owner',
    headerName: 'Owner',
    valueGetter: ({ owner }) => <Avatar user={owner} size="md" />,
  },
  {
    field: 'number_of_records',
    headerName: 'Records',
    sortable: true,
    valueGetter: ({ number_of_records }) => number_of_records.toLocaleString(),
  },
  {
    field: 'updated_at',
    headerName: 'Last modified',
    sortable: true,
    valueGetter: ({ updated_at }) =>
      new Date(updated_at).toLocaleDateString(undefined, dateOptions),
  },
  {
    field: 'records_type',
    headerName: 'Type',
    valueGetter: (list) => {
      return <ListTypeWidget list={list} />;
    },
  },
  {
    field: '',
    headerClassName: 'justify-end',
    headerName: 'Actions',
    valueGetter: (list: ListRecord) => (
      <div
        className="flex flex-row items-center gap-2 justify-end cursor-default"
        onClick={stopPropagation}
      >
        <SearchListControl list={list} />
        <SearchListControl list={list} showDataProfile />
        <OpenListControl size="md" list={list} />
        <ListRecordActions list={list} withPortal />
      </div>
    ),
  },
];
