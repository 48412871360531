import { ListParams, Show } from 'types';

const defaultListParams = (count = 25): ListParams => ({
  index: 0,
  count,
  sort: {
    by: 'updated_at',
    descending: true,
  },
  filter: {
    show: Show.All,
  },
  refresh: true,
});

export default defaultListParams;
