type AuthData = {
  token: string;
  username: string;
};

type PostMessage<S extends PostMessageSource, P extends any> = {
  payload?: P;
  source: S;
};

export enum PostMessageSource {
  KlarityAdminAuth = 'klarity-admin-auth',
}

export type AuthMessage = PostMessage<PostMessageSource.KlarityAdminAuth, AuthData>;
