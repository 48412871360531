import { ReactNode, useCallback, useMemo, useState } from 'react';
import useModal from 'hooks/useModal';

import { Notification } from 'types';
import CreditsContext, { CreditsContextInterface } from './CreditsContext';
import RequestCreditsOverlay from './RequestCreditsOverlay';
import ManageCreditsOverlay from './ManageCreditsOverlay';
import AcceptCreditsRequestOverlay from './AcceptCreditsRequestOverlay';
import RejectCreditsRequestOverlay from './RejectCreditsRequestOverlay';
import BuyCreditsOverlay from 'components/SupportRequest/BuyCreditsOverlay';

interface CreditsProviderProps {
  children: ReactNode;
}

const CreditsProvider = ({ children }: CreditsProviderProps) => {
  const {
    open: openRequestCredits,
    openModal: openRequestCreditsModal,
    closeModal: closeRequestCreditsModal,
  } = useModal();
  const {
    open: openManageCredits,
    openModal: openManageCreditsModal,
    closeModal: closeManageCreditsModal,
  } = useModal();
  const {
    open: openAcceptCredits,
    openModal: openAcceptCreditsModal,
    closeModal: closeAcceptCreditsModal,
  } = useModal();
  const {
    open: openRejectCredits,
    openModal: openRejectCreditsModal,
    closeModal: closeRejectCreditsModal,
  } = useModal();
  const {
    open: openBuyCredits,
    openModal: openBuyCreditsModal,
    closeModal: closeBuyCreditsModal,
  } = useModal();
  const [notification, setNotification] = useState<Notification | null>(null);

  const handleAcceptCreditsRequest = useCallback(
    (notification: Notification) => {
      setNotification(notification);
      openAcceptCreditsModal();
    },
    [openAcceptCreditsModal],
  );

  const handleRejectCreditsRequest = useCallback(
    (notification: Notification) => {
      setNotification(notification);
      openRejectCreditsModal();
    },
    [openRejectCreditsModal],
  );

  const value: CreditsContextInterface = useMemo(
    () => ({
      requestCredits: openRequestCreditsModal,
      manageCredits: openManageCreditsModal,
      buyCredits: openBuyCreditsModal,
      acceptCreditsRequest: handleAcceptCreditsRequest,
      rejectCreditsRequest: handleRejectCreditsRequest,
    }),
    [
      openRequestCreditsModal,
      openManageCreditsModal,
      openBuyCreditsModal,
      handleAcceptCreditsRequest,
      handleRejectCreditsRequest,
    ],
  );

  return (
    <CreditsContext.Provider value={value}>
      {children}
      <RequestCreditsOverlay open={openRequestCredits} onClose={closeRequestCreditsModal} />
      <ManageCreditsOverlay open={openManageCredits} onClose={closeManageCreditsModal} />
      <BuyCreditsOverlay open={openBuyCredits} onClose={closeBuyCreditsModal} />
      {notification && (
        <AcceptCreditsRequestOverlay
          notification={notification}
          open={openAcceptCredits}
          onClose={closeAcceptCreditsModal}
        />
      )}
      {notification && (
        <RejectCreditsRequestOverlay
          notification={notification}
          open={openRejectCredits}
          onClose={closeRejectCreditsModal}
        />
      )}
    </CreditsContext.Provider>
  );
};

export default CreditsProvider;
