import React from 'react';
import useSearchResults from './useSearchResults';

export interface SearchContextInterface {
  company: ReturnType<typeof useSearchResults>;
  contact: ReturnType<typeof useSearchResults>;
}

const SearchContext = React.createContext<SearchContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  SearchContext.displayName = 'SearchContext';
}

export default SearchContext;
