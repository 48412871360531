import { HtmlHTMLAttributes } from 'react';
import UsersIcon from '@demandscience/ui/icons/users';
import OnboardingBox from './OnboardingBox';

const OnboardingButtonNo3 = (props: HtmlHTMLAttributes<HTMLDivElement>) => {
  return (
    <OnboardingBox
      Icon={UsersIcon}
      title={'Find new contacts at\nyour target accounts'}
      subtitle="Enter list of companies"
      {...props}
    />
  );
};

export default OnboardingButtonNo3;
