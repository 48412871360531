import { forwardRef, HTMLAttributes } from 'react';
import MenuItem from './MenuItem';

interface TextMenuItemProps extends HTMLAttributes<HTMLDivElement> {
  textClassName?: string;
}

const TextMenuItem = forwardRef<HTMLDivElement, TextMenuItemProps>(
  ({ textClassName, children, ...props }, ref) => (
    <MenuItem ref={ref} title={children} titleClassName={textClassName} {...props} />
  ),
);

TextMenuItem.displayName = 'TextMenuItem';

export default TextMenuItem;
