import { Filters, ListRecord, ResultsView } from 'types';
import { IconButton, IconButtonProps } from '@demandscience/ui';
import TilesIcon from '@demandscience/ui/icons/Tiles';
import PlusCircleIcon from '@demandscience/ui/icons/plus-circle';
import { useNavigate } from 'react-router-dom';
import TextTooltip from 'components/Tooltip/TextTooltip';
import { getRecordsLabel } from 'utils/string';
import { useCallback } from 'react';

interface SearchListControlProps extends IconButtonProps {
  list: ListRecord;
  showDataProfile?: boolean;
}

const SearchListControl = ({ list, showDataProfile, ...props }: SearchListControlProps) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    const filters: Filters = {
      inclusion_list: [
        {
          list_id: list.id,
          name: list.name,
          records_type: list.records_type,
        },
      ],
    };
    const tab = showDataProfile ? ResultsView.DataProfile : getRecordsLabel(list.records_type);
    navigate('/search', { state: { filters, tab } });
  }, [list.id, list.name, list.records_type, navigate, showDataProfile]);

  return (
    <TextTooltip
      title={showDataProfile ? 'Open in data profile' : 'Include on search'}
      placement="bottom"
      showArrow
    >
      <IconButton
        className="hover:bg-gray-100 text-gray-500 stroke-0"
        size="sm"
        theme="default"
        onClick={handleClick}
        Icon={showDataProfile ? TilesIcon : PlusCircleIcon}
        {...props}
      />
    </TextTooltip>
  );
};

export default SearchListControl;
