import XIcon from '@demandscience/ui/icons/x';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';

import { ExportDestination, exportDestinations, ExportsFilter, ModelType } from 'types';

import Dropdown from 'components/Filter/Dropdown';
import { includes, isEmpty, isEqual, join, map, omit, reduce } from 'lodash';
import Search from 'components/Input/Search';
import classNames from 'classnames';
import CheckboxMenuItem from 'components/Filter/Dropdown/CheckboxMenuItem';
import DividerMenuItem from 'components/Filter/Dropdown/DividerMenuItem';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';
import { useCallback } from 'react';

const LABELS: Record<string, string> = {
  [ModelType.Company]: 'Companies',
  [ModelType.Contact]: 'Contacts',
  ...exportDestinations,
};

const getLabel = (value: any[]) => {
  if (isEqual(value, ['all'])) {
    return 'All exports';
  }

  return join(
    map(value, (key: string) => LABELS[key]),
    ', ',
  );
};

const getSelectValue = (value?: ExportsFilter) => {
  let res: Array<'all' | ModelType | ExportDestination> = [];

  if (isEmpty(value?.record_types) && isEmpty(value?.destinations)) {
    res = ['all'];
  } else {
    if (value?.record_types) {
      res = [...res, ...value.record_types];
    }
    if (value?.destinations) {
      res = [...res, ...value.destinations];
    }
  }

  return res;
};

interface FiltersProps {
  onChange: (value: ExportsFilter) => void;
  value?: ExportsFilter;
}

const Filters: React.FC<FiltersProps> = ({ value, onChange }) => {
  const selectValue = getSelectValue(value);

  const handleChange = useCallback(
    (field: string, fieldValue: any) => {
      if (isEmpty(fieldValue)) {
        onChange(omit(value, field));
      } else {
        onChange({
          ...value,
          [field]: fieldValue,
        });
      }
    },
    [onChange, value],
  );

  const handleSearchChange = useCallback(
    (freetext: string | undefined) => {
      handleChange('account_freetext', freetext);
    },
    [handleChange],
  );

  const handleSelectChange = useCallback(
    (newValue: any) => {
      if (isEmpty(newValue) || (!includes(selectValue, 'all') && includes(newValue, 'all'))) {
        onChange(omit(value, 'record_types', 'destinations'));
      } else {
        const res = reduce(
          newValue,
          (res, val) => {
            if (val === 'all') {
              return res;
            }

            const key = includes(ModelType, val) ? 'record_types' : 'destinations';

            return {
              ...res,
              [key]: [...(res[key] || []), val],
            };
          },
          {} as ExportsFilter,
        );

        onChange({
          ...omit(value, 'record_types', 'destinations'),
          ...res,
        });
      }
    },
    [onChange, selectValue, value],
  );

  const handleClearAll = useCallback(() => {
    onChange({});
  }, [onChange]);

  return (
    <>
      <div className="md:w-64">
        <Search
          placeholder="Filter list by team member"
          value={value?.account_freetext}
          onChange={handleSearchChange}
        />
      </div>
      <Dropdown
        menuClassName="max-h-auto"
        value={selectValue}
        onChange={handleSelectChange}
        multiple
        matchContentSize
        button={({ open, value }) => (
          <button className="px-4 py-2 text-sm text-gray-700 text-left w-full md:w-52 flex flex-row items-center gap-1 border-none rounded ring-inset ring-1 ring-gray-300 hover:ring-gray-700 focus:ring-primary-500 focus:hover:ring-primary-500 focus:outline-none">
            <div className="grow ellipsis">{getLabel(value)}</div>
            {!isEqual(value, ['all']) && (
              <XIcon size={18} className="flex-shrink-0 text-gray-500" onClick={handleClearAll} />
            )}
            <ChevronDownIcon
              size={18}
              className={classNames('flex-shrink-0', { 'rotate-180': open })}
            />
          </button>
        )}
      >
        <CheckboxMenuItem option={{ id: 'all', label: 'All exports', value: 'all' }} />
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Export type</TextMenuItem>
        {map([ModelType.Company, ModelType.Contact], (value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label: LABELS[value], value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">
          Export destination
        </TextMenuItem>
        {map(exportDestinations, (label, value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label, value }} />
        ))}
      </Dropdown>
    </>
  );
};

export default Filters;
