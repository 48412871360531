import useFilters from 'components/Filters/useFilters';
import { Button, ButtonProps } from '@demandscience/ui';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { Filters } from 'types';

interface FinishButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick: (filters: Filters) => void;
}

const FinishButton = ({ onClick, ...props }: FinishButtonProps) => {
  const { filters } = useFilters();

  const handleClick = useCallback(() => {
    onClick(filters);
  }, [filters, onClick]);

  return <Button onClick={handleClick} disabled={isEmpty(filters)} {...props} />;
};

export default FinishButton;
