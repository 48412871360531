import classNames from 'classnames';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Button } from '@demandscience/ui';
import PlusIcon from '@demandscience/ui/icons/plus';
import SavedSearchesList from './SavedSearchesList';

import isEmpty from 'lodash/isEmpty';
import take from 'lodash/take';
import size from 'lodash/size';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import { SavedSearchesResponse } from 'types';
import Paragraph from 'components/Typography/Paragraph';
import ListItemSkeleton from 'components/Lists/Skeletons/ListItemSkeleton';
import EmptyResults, { Illustration } from 'components/Layout/EmptyResults';
import SavedSearchesSidebarControl from './SavedSearchesSidebarControl';

interface SavedSearchesShortListProps {
  count?: number;
  hideCreateNew?: boolean;
  query: UseInfiniteQueryResult<SavedSearchesResponse>;
}

const SavedSearchesShortList = ({
  query,
  hideCreateNew = false,
  count = 3,
}: SavedSearchesShortListProps) => {
  const { data, isLoading } = query;
  let searches = flatten(map(data?.pages, 'searches'));

  // Temporarily filter out searches containing a contact_location filter
  searches = searches.filter((search) => {
    const { filters } = search;
    if (filters?.hasOwnProperty('contact_location')) {
      return false;
    }
    return search;
  });

  if (isLoading) {
    return (
      <div className="divide-y">
        <ListItemSkeleton count={3} />
      </div>
    );
  }

  return (
    <>
      {isEmpty(searches) && (
        <EmptyResults
          message="You don't have any saved searches"
          illustration={<Illustration.Absurd4 className="w-16 h-16 mb-2" />}
          className="mt-4"
        >
          <Paragraph className="text-gray-500 mt-1 text-center whitespace-pre-line">
            {
              'If you are often searching using the same criteria, saving\nyour search will help you save time.'
            }
          </Paragraph>
          {!hideCreateNew && (
            <Button className="mt-4" size="md" borderless as={RouterLink} to="../search">
              <PlusIcon className="w-5 h-5 mr-1" /> Create new search
            </Button>
          )}
        </EmptyResults>
      )}
      {!isEmpty(searches) && (
        <>
          <SavedSearchesList data={take(searches, count)} />
          <div className="pt-4 border-t flex flex-row items-center justify-between">
            <SavedSearchesSidebarControl
              as={Link}
              className={classNames('no-underline hover:underline', {
                invisible: size(searches) <= count,
              })}
            >
              View all
            </SavedSearchesSidebarControl>
            {!hideCreateNew && (
              <Button size="md" borderless as={RouterLink} to="../search">
                <PlusIcon className="w-5 h-5 mr-1" /> Create new search
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SavedSearchesShortList;
