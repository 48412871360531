import FeatureFlagContext, { FeatureFlagContextInterface } from './FeatureFlagContext';
import { useCallback, useMemo } from 'react';
import useModal from 'hooks/useModal';
import RequestFeaturesOverlay from './RequestFeaturesOverlay';
import { KlarityFeature } from 'types';
import useAuth from 'components/Auth/useAuth';

interface FeatureFlagProviderProps {
  children: React.ReactNode;
}

const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const { organization } = user ?? {};
  const { disabled_features, lab_disabled } = organization ?? {};

  const {
    open: openRequestFeatures,
    openModal: openRequestFeaturesModal,
    closeModal: closeRequestFeaturesModal,
  } = useModal();

  const getFeatureFlagStatus = useCallback(
    (feature: KlarityFeature) => {
      return disabled_features?.includes(feature) ?? false; // By default all features are enabled
    },
    [disabled_features],
  );

  const value: FeatureFlagContextInterface = useMemo(
    () => ({
      getFeatureFlagStatus,
      isLabEnabled: !lab_disabled,
      requestFeatures: openRequestFeaturesModal,
    }),
    [getFeatureFlagStatus, lab_disabled, openRequestFeaturesModal],
  );

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
      <RequestFeaturesOverlay open={openRequestFeatures} onClose={closeRequestFeaturesModal} />
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
