import { RadioList } from '@demandscience/ui';
import { SelectOption } from '@demandscience/ui/dist/types';
import { ChevronDown, ChevronUp } from '@demandscience/ui/icons';
import { useState } from 'react';
import CustomDropdown from './AutomaticUpdatesDropdown';
import { ApiResponse } from 'types/BookMark';
import { AutoUpdate, AutoUpdateOptions } from 'types';

interface DateSelectorProps {
  created_at: string;
  data: ApiResponse;
  includeNow: boolean;
  setClickedLabelsIndex: (indexes: number[]) => void;
  setIncludeNow: (value: boolean) => void;
  updated_at: string[];
}

export const DateSelector: React.FC<DateSelectorProps> = ({
  created_at,
  updated_at,
  setClickedLabelsIndex,
  setIncludeNow,
}) => {
  const [showAll, setShowAll] = useState(false);

  const [selectedDate, setSelectedDate] = useState<string>(`creation-0`);

  const handleDateChange = (dateValue: string) => {
    if (dateValue === 'now') {
      setIncludeNow(true);
      setClickedLabelsIndex([updated_at.length + 1]);
    } else {
      setIncludeNow(false);
    }
    setSelectedDate(dateValue);
    const index = dateValue.split('-')[1];
    const value = parseInt(index, 10);
    if (dateValue === `creation-0`) {
      setClickedLabelsIndex([0]);
    } else if (index !== undefined) {
      setClickedLabelsIndex([value + 1]);
    }
  };
  const dateOptions: SelectOption[] = [
    { label: `${created_at} (creation)`, value: `creation-0` },
    ...updated_at.map((date, index) => ({ label: date, value: `${date}-${index}` })),
    { label: 'Now (live)', value: 'now' },
  ];

  return (
    <div className="max-w-md mx-auto">
      <RadioList value={selectedDate} onChange={handleDateChange} options={dateOptions}>
        <div className="space-y-4">
          <RadioList.Option
            key={`creation-0`}
            option={{ value: `creation-0`, label: `${created_at} (creation)` }}
          >
            <RadioList.Icon
              theme="primary"
              checked={selectedDate === `creation-0`}
              className="mr-2"
            />
            <RadioList.Label className="text-md ml-6">{`${created_at} (creation)`}</RadioList.Label>
          </RadioList.Option>
          {showAll ? (
            updated_at.map((date, index) => (
              <RadioList.Option
                key={`${date}-${index}`}
                option={{ value: `${date}-${index}`, label: date }}
              >
                <RadioList.Icon
                  theme="primary"
                  checked={selectedDate === `${date}-${index}`}
                  className="mr-2"
                />
                <RadioList.Label className="text-md ml-6">{date}</RadioList.Label>
              </RadioList.Option>
            ))
          ) : updated_at.length ? (
            <>
              <div className=" pl-0 cursor-pointer flex flex-row" onClick={() => setShowAll(true)}>
                <span className="text-gray-500">{<ChevronDown />}</span>
                <span className="text-md ml-7">View all</span>
              </div>
              <RadioList.Option
                key={`${updated_at[updated_at.length - 1]}-${updated_at.length - 1}`}
                option={{
                  value: `${updated_at[updated_at.length - 1]}-${updated_at.length - 1}`,
                  label: `${updated_at[updated_at.length - 1]} (last update)`,
                }}
              >
                <RadioList.Icon
                  theme="primary"
                  checked={
                    selectedDate === `${updated_at[updated_at.length - 1]}-${updated_at.length - 1}`
                  }
                  className="mr-2"
                />
                <RadioList.Label className="text-md ml-6">{`${
                  updated_at[updated_at.length - 1]
                } (last update)`}</RadioList.Label>
              </RadioList.Option>
            </>
          ) : null}
          <RadioList.Option key="now" option={{ value: 'now', label: 'Now (live)' }}>
            <RadioList.Icon theme="primary" checked={selectedDate === 'now'} className="mr-2" />
            <RadioList.Label className="text-md ml-6">Now (live)</RadioList.Label>
          </RadioList.Option>
        </div>
      </RadioList>
    </div>
  );
};
export const DropdownBox = ({
  icon,
  title,
  count,
  children,
  read = false,
}: {
  children: any;
  count: any;
  icon: any;
  read?: boolean;
  title: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const Icon = icon;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-b border-gray-200">
      <div className="flex items-center p-4 cursor-pointer" onClick={toggleDropdown}>
        <div className="text-sm mr-5 " style={{ color: '#64748B' }}>
          <Icon className="text-sm" />
        </div>
        <div className="flex-grow ml-2 text-lg font-semibold text-gray-800">
          {count} {title}{' '}
          {title === 'Comments' && read && (
            <span className="ml-2 mb-2 h-1 w-1 bg-red-600 rounded-full inline-block"></span>
          )}
        </div>
        <div className="text-gray-500">{isOpen ? <ChevronUp /> : <ChevronDown />}</div>
      </div>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};
interface AutoUpdateOption {
  autoUpdate: AutoUpdate;
  setAutoUpdate: (option: AutoUpdate) => void;
}

export const AutomaticUpdates = ({ setAutoUpdate, autoUpdate }: AutoUpdateOption) => {
  const [selectedoption, setSelectedOption] = useState<AutoUpdate>(autoUpdate);
  const handleOptionChange = (option: AutoUpdate) => {
    setSelectedOption(option);
    setAutoUpdate(option);
  };

  return (
    <CustomDropdown
      title="Automated updates"
      options={AutoUpdateOptions}
      selectedOption={selectedoption}
      onChange={handleOptionChange}
    />
  );
};
