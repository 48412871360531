import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';

import { FilterName, Filters } from 'types/filters';
import useFilters from './useFilters';

const useFilter = <T extends FilterName>(name: T) => {
  const { filters, setFilter } = useFilters();
  const { [name]: value } = filters;

  const onChange = useCallback(
    (value: Filters[T]) => {
      if (isEmpty(value)) {
        setFilter(name, undefined);
      } else {
        setFilter(name, value);
      }
    },
    [name, setFilter],
  );

  const onClear = useCallback(() => {
    setFilter(name, undefined);
  }, [name, setFilter]);

  return { value, onChange, onClear };
};

export default useFilter;
