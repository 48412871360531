import { Contact } from 'types';
import classNames from 'classnames';
import { Avatar, AvatarProps } from '@demandscience/ui';
import UserIcon from '@demandscience/ui/icons/user';
import { endsWith, map, some, sum } from 'lodash';
import useImage from 'hooks/useImage';

export interface ContactAvatarProps extends AvatarProps {
  className?: string;
  colored?: boolean;
  row: Contact;
}

export const getBgColor = (str: string) => {
  const colors = ['gray', 'blue', 'emerald', 'amber', 'indigo', 'rose'];
  // simple calculation of color index based on the input string
  const index = sum(map(str, (chr) => chr.charCodeAt(0))) % colors.length;
  // always use 100 color
  const bgColor = `bg-${colors[index]}-100`;

  return `${bgColor} hover:${bgColor}`;
};

const checkAvatarUrl = (url?: string) => {
  const ignore = [
    '/sc/h/244xhbkr7g40x6bsu4gi6q4ry',
    '/sc/h/djzv59yelk5urv2ujlazfyvrk',
    '/sc/h/ck48xrmh3ctwna0w2y1hos0ln',
    '/ghosts/person/ghost_person_200x200_v1.png',
  ];

  if (some(ignore, (path: string) => endsWith(url, path))) {
    return undefined;
  }

  return url;
};

const ContactAvatar = ({ row, className, colored, ...props }: ContactAvatarProps) => {
  const { first_name: fName, last_name: lName, avatar_url } = row;
  const name = `${fName} ${lName}`;
  const url = checkAvatarUrl(avatar_url);
  const { loading, loaded } = useImage(url);

  return (
    <Avatar
      className={classNames('text-xs w-6 h-6 text-gray-600', className, {
        'bg-gray-200 hover:bg-gray-200': !colored,
        [getBgColor(name)]: colored,
        invisible: loading,
      })}
      src={loaded ? url : undefined}
      alt={name}
      {...props}
    >
      <UserIcon />
    </Avatar>
  );
};

export default ContactAvatar;
