import React, { useMemo } from 'react';
import { includes } from 'lodash';
import ConsentManagerContext, { ConsentManagerContextInterface } from './ConsentManagerContext';
import useConsent from './useConsent';

interface ConsentManagerProviderProps {
  children: React.ReactNode;
}

const ConsentManagerProvider = ({ children }: ConsentManagerProviderProps) => {
  const consent = useConsent('demandscience.com');

  const value: ConsentManagerContextInterface = useMemo(
    () => ({
      unknown: consent === null,
      noDecision: includes(consent, 0),
      required: includes(consent, 1),
      functional: includes(consent, 2),
      advertising: includes(consent, 3),
    }),
    [consent],
  );

  return <ConsentManagerContext.Provider value={value}>{children}</ConsentManagerContext.Provider>;
};

export default ConsentManagerProvider;
