import { useCallback } from 'react';
import { Operator } from 'types/filters';
import Switch from './SwitchMenuItem';

interface OperatorSwitchMenuItemProps {
  onChange: (value: Operator) => void;
  value?: Operator;
}

const OperatorSwitchMenuItem = ({ value = 'AND', onChange }: OperatorSwitchMenuItemProps) => {
  const handleChange = useCallback(
    (switchValue: boolean) => {
      onChange(switchValue ? 'AND' : 'OR');
    },
    [onChange],
  );

  return <Switch value={value === 'AND'} onChange={handleChange} label1="All" label2="Any" />;
};

export default OperatorSwitchMenuItem;
