import { useQuery } from '@tanstack/react-query';

import { fetchFilterData } from 'api/data';
import map from 'lodash/map';
import { FilterDataFieldName, JobLevel } from 'types';
import { Option } from '../types';

const fetchOptions = async () => {
  const levels = await fetchFilterData(FilterDataFieldName.JobLevels);
  const data = map(
    levels,
    (value) => ({ id: value.level, value, label: value.level } as Option<JobLevel>),
  );

  return data;
};

const useJobLevelOptions = (options?: Record<string, any>) => {
  const query = useQuery(['filterOptions', FilterDataFieldName.JobLevels], fetchOptions, {
    refetchOnWindowFocus: false, // no need to refetch on window focus
    staleTime: Infinity,
    ...options,
  });

  return query;
};

export default useJobLevelOptions;
