import { createContext, useContext, useState, useCallback, ReactNode } from 'react';

type BookmarkSelectionContextType = {
  clearSelection: () => void;
  getName: (id: string) => string;
  selectAllBookmarks: (ids: string[]) => void;
  selectedBookmarks: Set<string>;
  toggleBookmark: (id: string) => void;
  updateName: (id: string, name: string) => void;
};

const BookmarkSelectionContext = createContext<BookmarkSelectionContextType | undefined>(undefined);

export const BookmarkSelectionProvider = ({ children }: { children: ReactNode }) => {
  const [selectedBookmarks, setSelectedBookmarks] = useState<Set<string>>(new Set());
  const [bookmarkNames, setBookmarkNames] = useState<Record<string, string>>({});

  const toggleBookmark = useCallback((id: string) => {
    setSelectedBookmarks((prev) => {
      const updated = new Set(prev);
      if (updated.has(id)) {
        updated.delete(id);
      } else {
        updated.add(id);
      }
      return updated;
    });
  }, []);

  const updateName = useCallback((id: string, name: string) => {
    setBookmarkNames((prev) => ({
      ...prev,
      [id]: name,
    }));
  }, []);

  const getName = useCallback(
    (id: string) => {
      return bookmarkNames[id];
    },
    [bookmarkNames],
  );

  const selectAllBookmarks = useCallback((ids: string[]) => {
    setSelectedBookmarks(new Set(ids));
  }, []);

  const clearSelection = useCallback(() => {
    setSelectedBookmarks(new Set());
  }, []);

  return (
    <BookmarkSelectionContext.Provider
      value={{
        selectedBookmarks,
        toggleBookmark,
        selectAllBookmarks,
        clearSelection,
        getName,
        updateName,
      }}
    >
      {children}
    </BookmarkSelectionContext.Provider>
  );
};

export const useBookmarkSelection = () => {
  const context = useContext(BookmarkSelectionContext);
  if (!context) {
    throw new Error('useBookmarkSelection must be used within a BookmarkSelectionProvider');
  }
  return context;
};
