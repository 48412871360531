import { isEmpty, size, some } from 'lodash';
import classNames from 'classnames';
import Select from 'components/Input/Select';
import useAuth from 'components/Auth/useAuth';
import { Alert, TextField, Transition } from '@demandscience/ui';
import MembersSelect from 'components/Members/MembersSelect';
import MessageInputController from 'components/Input/MessageInputController';
import {
  CreateSavedSearchFormValues,
  EditSavedSearchFormValues,
  Visibility,
  visibilityOptions,
} from 'types';
import { Control, Controller, FormState, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import useCompare from 'components/Filters/useCompare';

interface FormFieldsProps {
  className?: string;
  control: Control<CreateSavedSearchFormValues | EditSavedSearchFormValues>;
  edit?: boolean;
  editingSomeoneElsesPublicSearch?: boolean;
  formState: FormState<CreateSavedSearchFormValues | EditSavedSearchFormValues>;
  setValue: UseFormSetValue<CreateSavedSearchFormValues | EditSavedSearchFormValues>;
  watch: UseFormWatch<CreateSavedSearchFormValues | EditSavedSearchFormValues>;
}

const FormFields = ({
  className,
  control,
  formState,
  watch,
  setValue,
  editingSomeoneElsesPublicSearch,
  edit,
}: FormFieldsProps) => {
  const { user } = useAuth();
  const { errors, dirtyFields } = formState;
  const visibility = watch('visibility');
  const members = watch('members');
  const notify = watch('notify');
  const isCurrentUserSharedWith = some(members, ({ value }) => value === user!.username);
  const { compareSearchList, compare } = useCompare();

  const handleAddMyself = useCallback(() => {
    setValue('members', [
      ...(members || []),
      { value: user!.username, label: user!.name || user!.email },
    ]);
  }, [members, setValue, user]);

  const handleAddMessageClick = () => {
    setValue('notify', true);
  };

  useEffect(() => {
    if (compare) {
      const activeLabel = compareSearchList.find((item) => item.isActive)?.label as string;

      setValue('name', activeLabel);
    }
  }, [compare, compareSearchList, setValue]);

  return (
    <div className={classNames('space-y-4', className)}>
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            label="Search name"
            trailingLabel={`${size(field.value)}/100 characters`}
            placeholder="Use 100 chars max"
            variant="outlined"
            error={errors.name?.message}
            {...field}
          />
        )}
        rules={{
          required: 'Required field',
          maxLength: { value: 100, message: '100 characters max' },
        }}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            label="Description"
            trailingLabel={`${size(field.value)}/500 characters`}
            placeholder="Optional description"
            variant="outlined"
            error={errors.description?.message}
            {...field}
          />
        )}
        rules={{
          maxLength: { value: 500, message: '500 characters max' },
        }}
      />
      <Controller
        name="visibility"
        control={control}
        render={({ field }) => (
          <Select
            label="Shared with"
            variant="outlined"
            withPortal
            optionsClassName="w-content"
            options={visibilityOptions}
            error={errors.visibility?.message}
            {...field}
          />
        )}
        rules={{ required: 'Required field' }}
      />

      {visibility === Visibility.Restricted && (
        <Controller
          name="members"
          control={control}
          render={({ field }) => (
            <MembersSelect
              label="Members"
              placeholder="Enter or select members"
              error={errors.members?.message}
              {...field}
            />
          )}
          rules={{ required: 'Required field' }}
        />
      )}

      {editingSomeoneElsesPublicSearch && formState.dirtyFields.visibility && (
        <>
          {visibility === Visibility.Private && (
            <Alert severity="warning">
              <Alert.Header>
                <Alert.Title>Beware</Alert.Title>
              </Alert.Header>
              <Alert.Description>
                By changing this setting, you will lose access to this search.
              </Alert.Description>
            </Alert>
          )}
          {visibility === Visibility.Restricted && (
            <Alert severity="warning">
              <Alert.Header>
                <Alert.Title>Beware</Alert.Title>
                {!isCurrentUserSharedWith && (
                  <Alert.ActionButton type="button" onClick={handleAddMyself}>
                    Add myself
                  </Alert.ActionButton>
                )}
              </Alert.Header>
              {!isCurrentUserSharedWith && (
                <Alert.Description>
                  By changing this setting, you will lose access to this search. You can keep access
                  to the search by adding yourself above.
                </Alert.Description>
              )}
              {isCurrentUserSharedWith && (
                <Alert.Description>
                  By changing this setting, you will lose edit-rights to this search.
                </Alert.Description>
              )}
            </Alert>
          )}
        </>
      )}

      <Transition.Collapse
        show={
          (visibility === Visibility.Restricted &&
            !isEmpty(members) &&
            // @ts-expect-error seems an issue with type coming from react-hook-form
            dirtyFields.members === true) ||
          visibility === Visibility.Public
        }
        as="div"
      >
        <div className="flex items-center text-sm text-gray-700 py-2">
          <p>
            {edit && visibility === Visibility.Restricted
              ? 'New members will be notified.'
              : 'Members will be notified.'}
          </p>
          {!notify && (
            <button
              type="button"
              className="text-blue-500 hover:underline ml-2"
              onClick={handleAddMessageClick}
            >
              Add a message
            </button>
          )}
        </div>
        {notify && (
          <MessageInputController<CreateSavedSearchFormValues | EditSavedSearchFormValues>
            name="notification_message"
            control={control}
          />
        )}
      </Transition.Collapse>
    </div>
  );
};

export default FormFields;
