import useFilters from 'components/Filters/useFilters';
import ListSelect from './ListSelect';

import {
  AggregateField,
  Aggregations,
  EmployeesSizeRange,
  Industry,
  JobFunction,
  JobLevel,
  Location,
} from 'types';
import {
  CompanyFilterName,
  ContactFilterName,
  FilterName,
  Filter,
  CompanyIndustryFilter,
} from 'types/filters';
import {
  getEmployeesLabel,
  getIndustryLabel,
  getJobFunctionLabel,
  getJobLevelLabel,
  getLocationLabel,
} from 'components/Filters/utils';
import { isEmpty } from 'lodash';

const getLabel = (value: CompanyIndustryFilter[number]) => getIndustryLabel(value, true) as string;

interface FiltersProps {
  aggregates: Aggregations;
}

const Filters = ({ aggregates }: FiltersProps) => {
  const { filters, setFilter } = useFilters();
  const {
    [AggregateField.JobLevel]: jobLevel,
    [AggregateField.JobFunction]: jobFunction,
    [AggregateField.CompanyLocationCountry]: location,
    [AggregateField.CompanyEmployees]: employees,
    [AggregateField.CompanyIndustry]: industry,
  } = aggregates;

  const handleChange = (field: FilterName) => (value: Filter) => {
    setFilter(field, isEmpty(value) ? undefined : value);
  };

  const {
    [ContactFilterName.JobLevel]: jobLevelFilter,
    [ContactFilterName.JobFunction]: jobFunctionFilter,
    [CompanyFilterName.CompanyLocation]: locationFilter,
    [CompanyFilterName.CompanyEmployees]: employeesFilter,
    [CompanyFilterName.CompanyIndustry]: industryFilter,
  } = filters;

  return (
    <>
      <ListSelect<JobLevel>
        label="Job level"
        value={jobLevelFilter}
        onChange={handleChange(ContactFilterName.JobLevel)}
        aggregate={jobLevel?.values || []}
        getLabel={getJobLevelLabel}
      />
      <ListSelect<JobFunction>
        label="Department"
        value={jobFunctionFilter}
        onChange={handleChange(ContactFilterName.JobFunction)}
        aggregate={jobFunction?.values || []}
        getLabel={getJobFunctionLabel}
      />
      <ListSelect<Location>
        label="Country"
        value={locationFilter}
        onChange={handleChange(CompanyFilterName.CompanyLocation)}
        aggregate={location?.values || []}
        getLabel={getLocationLabel}
      />
      <ListSelect<EmployeesSizeRange>
        label="Company size"
        value={employeesFilter}
        onChange={handleChange(CompanyFilterName.CompanyEmployees)}
        aggregate={employees?.values || []}
        getLabel={getEmployeesLabel}
      />
      <ListSelect<Industry>
        label="Industries"
        value={industryFilter}
        onChange={handleChange(CompanyFilterName.CompanyIndustry)}
        aggregate={industry?.values || []}
        getLabel={getLabel}
      />
    </>
  );
};

export default Filters;
