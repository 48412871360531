import { reduce } from 'lodash';

const reqSvgs = require.context('flag-icons/flags/4x3');

const flags: Record<string, string> = reduce(
  reqSvgs.keys(),
  (res, path) => {
    const url: string = reqSvgs(path);
    const code = path.replace('./', '').replace('.svg', '');

    return {
      ...res,
      [code]: url,
    };
  },
  {},
);

export const getFlagUrl = (country_code: string) => {
  const code = country_code.toLowerCase();

  return flags[code];
};
