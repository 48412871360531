import { useQuery } from '@tanstack/react-query';

import { searchMembers } from 'api/organization';
import { SearchMembersParams } from 'types';

const useMembersSearch = (params: SearchMembersParams, options?: Record<string, any>) => {
  const query = useQuery(['members', 'search', params], () => searchMembers(params), {
    staleTime: 60 * 1000, // 1 minute
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};

export default useMembersSearch;
