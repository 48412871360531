import { useCallback, useState } from 'react';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';
import { DropdownMenu, IconButton } from '@demandscience/ui';

interface SelectionMenuProps {
  onClearPage?: () => void;
  onSelectPage: () => void;
}

const SelectionMenu = ({ onSelectPage, onClearPage }: SelectionMenuProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSelectPage = useCallback(() => {
    handleClose();

    onSelectPage();
  }, [handleClose, onSelectPage]);

  const handleClearAll = useCallback(() => {
    handleClose();

    onClearPage && onClearPage();
  }, [handleClose, onClearPage]);

  return (
    <DropdownMenu
      open={open}
      onCloseAction={handleClose}
      buttonType="custom"
      customButton={
        <IconButton
          className="hover:bg-primary-50"
          size="sm"
          theme="default"
          Icon={ChevronDownIcon}
          onClick={handleOpen}
        />
      }
      origin="bottom-left"
      dropdownClassName="py-2 max-h-fit" // this is to overrde max-h-64 within dropdown
    >
      <DropdownMenu.Item onClick={handleSelectPage}>Select entire page</DropdownMenu.Item>
      <DropdownMenu.Item onClick={handleClearAll}>Clear your selection</DropdownMenu.Item>
    </DropdownMenu>
  );
};

export default SelectionMenu;
