import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';
import { overrideTailwindClasses } from 'tailwind-override';

interface DividerMenuItemProps extends HTMLAttributes<HTMLDivElement> {}

const DividerMenuItem = forwardRef<HTMLDivElement, DividerMenuItemProps>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={overrideTailwindClasses(classNames('py-2', className))} {...props}>
      <div className="border-t-[1px]" />
    </div>
  ),
);

DividerMenuItem.displayName = 'DividerMenuItem';

export default DividerMenuItem;
