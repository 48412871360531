import { CompaniesSort, ContactsSort, ModelType } from 'types';
import useSearchResults from './useSearchResults';
import SearchContext, { SearchContextInterface } from './SearchContext';
import useFilters from 'components/Filters/useFilters';
import { isEmpty } from 'lodash';
import { mergeCompanyPhoneNumbers } from './utils';
import { useMemo } from 'react';

interface SearchProviderProps {
  children: React.ReactNode;
  companiesSortField?: CompaniesSort;
  contactsSortField?: ContactsSort;
  ignoreEmptyFilters?: boolean;
}

const SearchProvider: React.FC<SearchProviderProps> = ({
  contactsSortField,
  companiesSortField,
  children,
  ignoreEmptyFilters = false,
}) => {
  const { filters, companyFilters } = useFilters();

  const contact = useSearchResults(ModelType.Contact, filters, {
    sortField: contactsSortField,
    enabled: !ignoreEmptyFilters || !isEmpty(filters),
    select: mergeCompanyPhoneNumbers,
  });
  const company = useSearchResults(ModelType.Company, companyFilters, {
    sortField: companiesSortField,
    enabled: !ignoreEmptyFilters || !isEmpty(filters),
  });

  const value: SearchContextInterface = useMemo(() => ({ contact, company }), [company, contact]);

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export default SearchProvider;
