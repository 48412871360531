import { Badge } from '@demandscience/ui';
import classNames from 'classnames';
import TextTooltip from 'components/Tooltip/TextTooltip';
import { isUndefined, round } from 'lodash';
import { ListRecord, ListStatus } from 'types';

interface ListStatusBadgeProps {
  list: ListRecord;
}

const ListStatusBadge = ({ list }: ListStatusBadgeProps) => {
  const { status } = list;

  if (status === ListStatus.Error || status === ListStatus.Processing) {
    let progress = undefined;
    if (
      status === ListStatus.Processing &&
      list.current_total &&
      !isUndefined(list.cumulative_processed)
    ) {
      progress = round((list.cumulative_processed / list.current_total) * 100);
    }

    return (
      <TextTooltip
        title={`${progress}% completed`}
        placement="top"
        rootClassName={classNames({ hidden: progress === undefined })}
      >
        <Badge
          label={status}
          color="rose"
          size="sm"
          data-testid="list-type-widget-status-badge"
          className="capitalize"
          progress={progress}
        />
      </TextTooltip>
    );
  }

  return null;
};

export default ListStatusBadge;
