export interface ChameleonType {
  clear: () => void;
  identify: (id: string, options: Record<string, any>) => void;
  init: (token: string, options?: Partial<{ fastUrl: string; forceOverride: boolean }>) => void;
  track: (eventName: string) => void;
}

type ChameleonTypeKeys = keyof ChameleonType;

type ChameleonTypeTuples = {
  [K in ChameleonTypeKeys]: [K, Parameters<ChameleonType[K]>];
}[ChameleonTypeKeys];

interface ChameleonWindow extends Window {
  chmln?: ChameleonType;
}

declare let window: ChameleonWindow;

// native chameleon has clear function that's not public on @chamaeleonidae/chmln
const clear = function () {
  window.chmln?.clear();
};

class ChameleonProxy implements ChameleonType {
  private invocations: Array<ChameleonTypeTuples> = [];
  private instance: ChameleonType | null = null;

  init(token: string, options?: Partial<{ fastUrl: string; forceOverride: boolean }> | undefined) {
    if (this.instance) {
      this.instance.init(token, options);
    } else {
      this.invocations.push(['init', [token, options]]);
    }
  }

  clear() {
    if (this.instance) {
      this.instance.clear();
      this.instance = null;
    } else {
      this.invocations = [];
    }
  }

  identify(id: string, options: Record<string, any>) {
    if (this.instance) {
      this.instance.identify(id, options);
    } else {
      this.invocations.push(['identify', [id, options]]);
    }
  }

  track(eventName: string) {
    if (this.instance) {
      this.instance.track(eventName);
    } else {
      this.invocations.push(['track', [eventName]]);
    }
  }

  setInstance(instance: ChameleonType) {
    this.instance = instance;
    // native chameleon has clear function that's not public on @chamaeleonidae/chmln
    instance.clear = clear;

    this.invocations.forEach(([method, args]) => {
      // @ts-expect-error
      instance[method].apply(instance, args);
    });
  }
}

export default ChameleonProxy;
