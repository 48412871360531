import { Filters } from './filters';

export type CompareSearch = {
  filters: Filters;
  isActive: boolean;
  label: string;
  value: string;
};

export const enum EditCompareOptions {
  optionOne = 0,
  optionTwo = 1,
  optionThree = 2,
  optionFour = 3,
}

export type EditState = {
  [key in EditCompareOptions]: boolean;
};
