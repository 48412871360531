import { useState } from 'react';
import { Button, TextField, Skeleton } from '@demandscience/ui';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import useTeamDetails from './useTeamDetails';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { AxiosError } from 'axios';
import { OrganizationInfoPayload } from 'types';
import useOrganization from 'components/Organization/useOrganization';

const TeamName = () => {
  const { data: organization, isLoading: isOrganizationNameLoading } = useOrganization();

  const [update, setUpdate] = useState(false);

  const { control, handleSubmit, reset, formState, watch, setValue } = useForm({
    defaultValues: {
      name: '',
    },
  });

  const { updateOrganizationName } = useTeamDetails();

  const { showMessage } = useSnackbar();
  const { isSubmitting, errors } = formState;

  const nameValue = watch('name');
  const teamName = organization?.name;
  const isInitialNameEmpty = teamName === undefined;
  const isNameEmpty = nameValue === '';

  const onSubmit: SubmitHandler<OrganizationInfoPayload> = async (data) => {
    try {
      const payload = {
        name: data.name,
      };
      await updateOrganizationName.mutateAsync(payload);

      setUpdate(false);

      showMessage('The name was successfully changed', 'success');
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e instanceof AxiosError) {
          showMessage(e.response?.data?.error || 'Unable to update the name', 'error');
        } else {
          showMessage('Unexpected error, please try again later', 'error');
        }
      }
    }
  };

  const handleUpdateClick = () => {
    const typedName = organization?.name!;
    // Set the value and remove multiple spaces
    setValue(
      'name',
      typedName
        .trim()
        .split(/[\s,\t,\n]+/)
        .join(' '),
    );
    setUpdate((state) => !state);
  };

  const handleClose = () => {
    setUpdate((state) => !state);
  };

  const nameHoldingText = isInitialNameEmpty ? 'No team name defined' : teamName;
  const updateBtnText = isInitialNameEmpty ? 'Add' : 'Change';

  const skeleton = (
    <Skeleton className="inline-block align-top mt-3" variant="circle" height={5} width={80} />
  );

  return (
    <div className="flex flex-col gap-4 max-w-xl">
      <p className="text-lg text-gray-700 font-medium">Team name</p>
      {isOrganizationNameLoading && skeleton}

      {!isOrganizationNameLoading && (
        <div className="flex flex-row justify-between items-center">
          <p className="text-sm text-gray-700 whitespace-pre-line">
            {update ? "Change the team's name" : nameHoldingText}
          </p>

          <Button
            className="whitespace-nowrap"
            theme="primary"
            size="md"
            borderless
            type="button"
            onClick={handleUpdateClick}
            disabled={isSubmitting}
          >
            {update ? 'Cancel' : updateBtnText}
          </Button>
        </div>
      )}
      {update && (
        <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                label="Team name"
                variant="outlined"
                placeholder="Enter the team's name"
                error={errors.name?.message}
                {...field}
              />
            )}
            rules={{
              required: 'Required field',
              pattern: {
                value: /^(?!\s*$).+/,
                message: 'Please type a name',
              },
            }}
          />

          <div className="flex gap-4 space-x-3 justify-between">
            <div>
              <Button
                id="action_filters_clear_all"
                borderless
                className="text-xs font-medium text-blue-500 hover:underline whitespace-nowrap"
                onClick={() => reset()}
                disabled={isNameEmpty}
              >
                Clear
              </Button>
            </div>
            <div className="space-x-3">
              <Button theme="primary" type="button" outline onClick={handleClose}>
                Cancel
              </Button>
              <Button theme="primary" type="submit" disabled={isSubmitting || !formState.isDirty}>
                Save
              </Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default TeamName;
