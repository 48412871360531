import { useState } from 'react';
import classNames from 'classnames';

import { CheckboxIcon, Transition } from '@demandscience/ui';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import some from 'lodash/some';
import reject from 'lodash/reject';

import { AggregateFilter, Aggregate } from 'types';

interface ListSelectProps<F extends AggregateFilter> {
  aggregate: Aggregate<F>[];
  className?: string;
  getLabel: (value: F) => string;
  label?: string;
  onChange: (value: F[]) => void;
  value?: F[];
}

const ListSelect = <F extends AggregateFilter>({
  className,
  label,
  value: selection = [],
  onChange,
  aggregate,
  getLabel,
}: ListSelectProps<F>) => {
  const [open, setOpen] = useState(false);
  const selected = !isEmpty(selection);
  const allSelected = selected && size(selection) === size(aggregate);

  const handleClick = () => {
    setOpen((state) => !state);
  };

  const handleToggle = (value: F) => () => {
    if (some(selection, value)) {
      onChange(reject(selection, value) as F[]);
    } else {
      onChange([...selection, value]);
    }
  };

  const handleToggleAll = (e: any) => {
    e.stopPropagation();

    if (selected) {
      onChange([]);
    } else {
      const newValue = map(aggregate, 'data');

      onChange(newValue);
    }
  };

  return (
    <div className={className}>
      <button
        className="flex items-center w-full px-4 py-1 transition hover:bg-gray-100 outline-none text-left"
        onClick={handleClick}
      >
        <div onClick={handleToggleAll}>
          <CheckboxIcon size={18} checkType={allSelected ? 'check' : 'minus'} checked={selected} />
        </div>
        <div className="w-full ml-4 text-sm">
          <div className="flex items-center justify-between my-1">
            <span>{label}</span>
            <ChevronDownIcon
              className={classNames('w-5 h-5 transform transition', {
                'rotate-180 duration-150 ease-out': open,
                'rotate-0 duration-100 ease-in': !open,
              })}
            />
          </div>
        </div>
      </button>
      <Transition.Collapse show={open} as="div">
        {map(aggregate, ({ value, count, data }) => {
          const checked = some(selection, data);

          return (
            <button
              key={value}
              className="flex items-center w-full pl-6 pr-4 py-1 transition hover:bg-gray-100 outline-none text-left"
              onClick={handleToggle(data)}
            >
              <div className="flex-shrink-0">
                <CheckboxIcon size={18} theme="primary" checkType="check" checked={checked} />
              </div>
              <div className="grow min-w-0 ml-4 my-1 text-sm text-ellipsis overflow-hidden">
                {getLabel(data)}
              </div>
              <div className="flex-shrink-0 text-sm">{count?.toLocaleString()}</div>
            </button>
          );
        })}
      </Transition.Collapse>
    </div>
  );
};

export default ListSelect;
