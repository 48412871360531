import { useCallback, useState } from 'react';

import { Button } from '@demandscience/ui';

import InviteOverlay from './InviteOverlay';

interface InviteControlProps {
  as?: React.ElementType;
  children: React.ReactNode;
}

const InviteControl: React.FC<InviteControlProps> = ({ as: Component = Button, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Component onClick={handleOpen} {...props} />
      <InviteOverlay open={open} onClose={handleClose} />
    </>
  );
};

export default InviteControl;
