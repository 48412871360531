import { useState, useRef, useEffect, useCallback } from 'react';
import { Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Placement, VirtualElement } from '@popperjs/core';
import { Portal } from 'react-portal';

export interface TooltipProps {
  anchorEl: Element | VirtualElement | null;
  children: React.ReactNode;
  className?: string;
  offset?: [number, number];
  onClose?: () => void;
  open: boolean;
  placement?: Placement;
  portalNode?: HTMLElement;
  rootClassName?: string;
  showArrow?: boolean;
}

const Tooltip = ({
  rootClassName,
  className,
  offset = [0, 8],
  open,
  onClose,
  placement,
  anchorEl,
  showArrow = false,
  portalNode = document.body,
  children,
}: TooltipProps) => {
  const popperElRef = useRef(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [render, setRender] = useState(false);

  const { styles, attributes, update } = usePopper(anchorEl, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: { offset },
      },
    ],
  });

  const handleBeforeEnter = useCallback(() => {
    // poper workaround
    setPopperElement(popperElRef.current);
  }, []);

  const handleAfterLeave = useCallback(() => {
    // poper workaround
    setPopperElement(null);

    setRender(false);

    if (onClose) onClose();
  }, [onClose]);

  useEffect(() => {
    if (open && anchorEl) {
      setRender(true);
    }
  }, [open, anchorEl]);

  useEffect(() => {
    if (update) update();
  }, [children, update]);

  if (!render) {
    return null;
  }

  return (
    <Portal node={portalNode}>
      <div className={rootClassName} ref={popperElRef} style={styles.popper}>
        <Transition
          appear
          show={open}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          beforeEnter={handleBeforeEnter}
          afterLeave={handleAfterLeave}
        >
          <div className={className} {...attributes.popper}>
            {children}
            {showArrow && <div data-popper-arrow style={styles.arrow} {...attributes.arrow} />}
          </div>
        </Transition>
      </div>
    </Portal>
  );
};

export default Tooltip;
