import { Company } from 'types';
import ZapIcon from '@demandscience/ui/icons/zap';
import ListItemText from 'components/Layout/ListItemText';
import { isEmpty, join, map, size } from 'lodash';
import TopicBadge from './TopicBadge';
import TopicList from './TopicList';
import Paragraph from 'components/Typography/ParagraphWithOverflowTooltip';

interface IntentTopicProps {
  className?: string;
  inline?: boolean;
  inlineText?: boolean;
  row?: Company;
}
const IntentTopic = ({ className, row, inline, inlineText }: IntentTopicProps) => {
  const listItems = map(row?.intents, ({ topic, topic_confidence_score: count }) => ({
    label: topic,
    count,
  }));

  if (isEmpty(listItems)) {
    return null;
  }

  if (inlineText) {
    const intentTopics = join(
      map(listItems, ({ label, count }) => `${label} (${count})`),
      ', ',
    );

    return <Paragraph className={className}>{intentTopics}</Paragraph>;
  }

  if (inline) {
    const count = size(listItems);

    return (
      <TopicBadge className={className} Icon={ZapIcon} count={count} title="Buyer intent matches">
        <TopicList items={listItems} />
      </TopicBadge>
    );
  }

  return (
    <ListItemText
      leadingContent={<ZapIcon className="w-5 h-5" />}
      overline="Buyer intent matches"
      title={<TopicList className="text-sm" items={listItems} show={3} />}
    />
  );
};

export default IntentTopic;
