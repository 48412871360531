import { useEffect, useRef } from 'react';
import ChameleonProxy, { ChameleonType } from './ChameleonTracker';

const modulePromise = (): Promise<{ default: ChameleonType }> =>
  import(/* webpackChunkName: 'chameleon' */ '@chamaeleonidae/chmln');

const useChameleon = (token: string, options?: { enabled: boolean }) => {
  const { enabled } = options || {};
  const { current: tracker } = useRef(new ChameleonProxy());

  useEffect(() => {
    if (!enabled) {
      tracker.clear();
    } else {
      const fetchModule = async () => {
        try {
          const res = await modulePromise();

          tracker.setInstance(res.default);
        } catch (e) {
          // eslint-disable-next-line
          console.warn('Unable to load chameleon library', e);
        }
      };

      fetchModule();
    }
  }, [enabled, tracker]);

  useEffect(() => {
    tracker.init(token);

    return () => {
      tracker.clear();
    };
  }, [tracker, token]);

  return tracker;
};

export default useChameleon;
