import { memo, useCallback, useState } from 'react';

import { Link } from '@demandscience/ui';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import reduce from 'lodash/reduce';

import List from 'components/Filter/List';
import CollapseButton from './CollapseButton';

import useCustomization from 'components/SearchCustomization/useCustomization';
import useFilters from 'components/Filters/useFilters';
import ContactDetailsFilter from 'components/Filters/ContactDetailsFilter';
// import DataAccuracyFilter from 'components/Filters/DataAccuracyFilter';
import ConfirmedConnectFilter from 'components/Filters/ConfirmedConnectFilter';

import CompanyNameFilter from 'components/Filters/CompanyNameFilter';
import CompanyLocationFilter from 'components/Filters/CompanyLocationFilter';
import CompanyIndustryFilter from 'components/Filters/CompanyIndustryFilter';
import CompanyEmployeesFilter from 'components/Filters/CompanyEmployeesFilter';
import CompanyRevenueFilter from 'components/Filters/CompanyRevenueFilter';
import CompanySpecialityFilter from 'components/Filters/CompanySpecialityFilter';

import ContactJobTitleFilter from 'components/Filters/ContactJobTitleFilter';
import ContactJobLevelFilter from 'components/Filters/ContactJobLevelFilter';
// import ContactLocationFilter from 'components/Filters/ContactLocationFilter';
import ContactNameFilter from 'components/Filters/ContactNameFilter';
import ContactJobFunctionFilter from 'components/Filters/ContactJobFunctionFilter';

import InstallTopicFilter from 'components/Filters/InstallTopicFilter';
import IntentTopicFilter from 'components/Filters/IntentTopicFilter';

import InclusionListFilter from 'components/Filters/InclusionListFilter';
import ExclusionListFilter from 'components/Filters/ExclusionListFilter';

import useFiltersCollapse from './useFiltersCollapse';
import Header from './Header';
import {
  CompanyFilterName,
  ContactFilterName,
  DataFilterName,
  Filters as FiltersType,
  ListFilterName,
  SalesSignalsFilterName,
} from 'types/filters';
import { KlarityFeature } from 'types';
import SettingsButton from 'components/SearchCustomization/SettingsButton';
import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';
import Personas from 'components/Personas';
import useCompare from 'components/Filters/useCompare';
import CompareRadioList from './CompareRadioList';
import useFeatureFlag from 'components/FeatureFlag/useFeatureFlag';

const getFiltersCount = (filters: FiltersType, group: string) => {
  let fields;

  if (group === 'data') {
    fields = DataFilterName;
  } else if (group === 'company') {
    fields = CompanyFilterName;
  } else if (group === 'contacts') {
    fields = ContactFilterName;
  } else if (group === 'salesSignals') {
    fields = SalesSignalsFilterName;
  } else if (group === 'lists') {
    fields = ListFilterName;
  }

  return reduce(fields, (sum, field) => (has(filters, field) ? sum + 1 : sum), 0);
};

const Filters = memo(() => {
  const [filtersContainerRef, setFiltersContainer] = useState<React.RefObject<HTMLDivElement>>({
    current: null,
  });
  const {
    filters: { dense },
  } = useCustomization();
  const { collapseGroup, toggleGroup, hideEmpty, toggleHideEmpty } = useFiltersCollapse();
  const { compare } = useCompare();
  const { filters } = useFilters();
  const { width = 0 } = useResizeDetector({ targetRef: filtersContainerRef });

  const handleListToggle = (group: string) => () => {
    toggleGroup(group);
  };

  const handleSetRef = useCallback((ref: HTMLDivElement) => {
    setFiltersContainer({ current: ref });
  }, []);

  const { featureStatus: isContactRenderDisabled } = useFeatureFlag(
    KlarityFeature.ContactsRendered,
  );
  const { featureStatus: isContactsDisabled } = useFeatureFlag(KlarityFeature.Contacts);

  return (
    <div className="flex flex-col h-full pb-4 isolate">
      <div className="h-20 pt-3 border-b flex-shrink-0 flex flex-row items-center gap-2">
        <div className="text-xl text-gray-700 font-semibold">Search</div>
        <div className="grow" />
        <SettingsButton />
        <CollapseButton size="sm">Hide</CollapseButton>
      </div>
      <div id="dashboard_search_filters" className="grow min-h-0 flex flex-col">
        <Header />
        {compare && <CompareRadioList />}
        <div className="grow overflow-x-hidden overflow-y-auto relative" ref={handleSetRef}>
          {filtersContainerRef.current && (
            <div
              className={classNames('group/cols gap-4', {
                'columns-1': width <= 520,
                'columns-2': width > 520 && width <= 800,
                'columns-3': width > 800,
              })}
            >
              {!isContactRenderDisabled && (
                <List
                  className="break-inside-avoid"
                  title="Data"
                  dense={dense}
                  collapse={isContactsDisabled ? !collapseGroup.data : collapseGroup.data}
                  onToggle={handleListToggle('data')}
                  count={getFiltersCount(filters, 'data')}
                >
                  {(!hideEmpty || has(filters, DataFilterName.ContactDetails)) && (
                    <ContactDetailsFilter dense={dense} container={filtersContainerRef} />
                  )}
                  {/* removed until supported by the backend */}
                  {/* {(!hideEmpty || has(filters, DataFilterName.DataAccuracy)) && (
                  <DataAccuracyFilter dense={dense} container={filtersContainerRef} />
                )} */}
                  {(!hideEmpty || has(filters, DataFilterName.ConfirmedConnect)) && (
                    <ConfirmedConnectFilter dense={dense} container={filtersContainerRef} />
                  )}
                </List>
              )}
              <List
                className="break-inside-avoid group-[.columns-2]/cols:break-after-column group-[.columns-3]/cols:break-after-column"
                title="Company"
                dense={dense}
                collapse={collapseGroup.company}
                onToggle={handleListToggle('company')}
                count={getFiltersCount(filters, 'company')}
              >
                {(!hideEmpty || has(filters, CompanyFilterName.CompanyName)) && (
                  <CompanyNameFilter dense={dense} container={filtersContainerRef} />
                )}
                {(!hideEmpty || has(filters, CompanyFilterName.CompanyLocation)) && (
                  <CompanyLocationFilter
                    dense={dense}
                    container={filtersContainerRef}
                    isContactRenderDisabled={isContactRenderDisabled}
                    isContactsDisabled={isContactsDisabled}
                  />
                )}
                {(!hideEmpty || has(filters, CompanyFilterName.CompanyIndustry)) && (
                  <CompanyIndustryFilter dense={dense} container={filtersContainerRef} />
                )}
                {(!hideEmpty || has(filters, CompanyFilterName.CompanyEmployees)) && (
                  <CompanyEmployeesFilter dense={dense} container={filtersContainerRef} />
                )}
                {(!hideEmpty || has(filters, CompanyFilterName.CompanyRevenue)) && (
                  <CompanyRevenueFilter dense={dense} container={filtersContainerRef} />
                )}
                {(!hideEmpty || has(filters, CompanyFilterName.CompanySpeciality)) && (
                  <CompanySpecialityFilter dense={dense} container={filtersContainerRef} />
                )}
              </List>
              {!isContactRenderDisabled && (
                <List
                  className="break-inside-avoid"
                  title="Contacts"
                  dense={dense}
                  collapse={isContactsDisabled ? !collapseGroup.contacts : collapseGroup.contacts}
                  onToggle={handleListToggle('contacts')}
                  count={getFiltersCount(filters, 'contacts')}
                >
                  {(!hideEmpty || has(filters, ContactFilterName.ContactName)) && (
                    <Personas dense={dense} />
                  )}
                  {(!hideEmpty || has(filters, ContactFilterName.ContactName)) && (
                    <ContactNameFilter dense={dense} container={filtersContainerRef} />
                  )}
                  {/* Temporarily hidden */}
                  {/* {(!hideEmpty || has(filters, ContactFilterName.ContactLocation)) && (
                  <ContactLocationFilter dense={dense} container={filtersContainerRef} />
                )} */}
                  {(!hideEmpty || has(filters, ContactFilterName.JobLevel)) && (
                    <ContactJobLevelFilter dense={dense} container={filtersContainerRef} />
                  )}
                  {(!hideEmpty || has(filters, ContactFilterName.JobTitle)) && (
                    <ContactJobTitleFilter dense={dense} container={filtersContainerRef} />
                  )}
                  {(!hideEmpty || has(filters, ContactFilterName.JobFunction)) && (
                    <ContactJobFunctionFilter dense={dense} container={filtersContainerRef} />
                  )}
                </List>
              )}
              <List
                className="break-inside-avoid group-[.columns-3]/cols:break-after-column"
                title="Sales signals"
                dense={dense}
                collapse={collapseGroup.salesSignals}
                onToggle={handleListToggle('salesSignals')}
                count={getFiltersCount(filters, 'salesSignals')}
              >
                {(!hideEmpty || has(filters, SalesSignalsFilterName.IntentTopic)) && (
                  <IntentTopicFilter dense={dense} container={filtersContainerRef} />
                )}
                {(!hideEmpty || has(filters, SalesSignalsFilterName.InstallTopic)) && (
                  <InstallTopicFilter dense={dense} container={filtersContainerRef} />
                )}
              </List>
              <List
                className="break-inside-avoid"
                title="Lists"
                dense={dense}
                collapse={collapseGroup.lists}
                onToggle={handleListToggle('lists')}
                count={getFiltersCount(filters, 'lists')}
              >
                {(!hideEmpty || has(filters, ListFilterName.InclusionList)) && (
                  <InclusionListFilter dense={dense} container={filtersContainerRef} />
                )}
                {(!hideEmpty || has(filters, ListFilterName.ExclusionList)) && (
                  <ExclusionListFilter dense={dense} container={filtersContainerRef} />
                )}
              </List>
              {hideEmpty && isEmpty(filters) && (
                <div className="py-6">
                  <Link as="button" onClick={toggleHideEmpty}>
                    View all filters
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

Filters.displayName = 'Filters';

export default Filters;
