import { createContext } from 'react';
import { DataTypeOptions, ExportData, ExportType, IntegrationOptions, ModelType } from 'types';

export interface ExportContextInterface {
  exportTo: (exporType: ExportType, exportData: ExportData) => void;
  hasIntegration: (provider: IntegrationOptions) => boolean | null;
  hasIntegrationType: (dataType: DataTypeOptions) => boolean;
  modelType: ModelType;
}

const ExportContext = createContext<ExportContextInterface | null>(null);

if (process.env.NODE_ENV !== 'production') {
  ExportContext.displayName = 'ExportContext';
}

export default ExportContext;
