import { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import useSelection from './Selection/useSelection';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';
import { DropdownMenu, IconButton, Transition } from '@demandscience/ui';
import AdvancedSelectionForm from './AdvancedSelectionForm';
import { AdvancedSelection, ModelType } from 'types';
import { stopPropagation } from 'utils/event';
import SearchContext from 'components/Search/SearchContext';

interface SelectionMenuProps {
  kind: ModelType;
  onClearPage: () => void;
  onSelectPage: () => void;
}

const SelectionMenu = ({ kind, onSelectPage }: SelectionMenuProps) => {
  const [open, setOpen] = useState(false);
  const { selectAll, limit, setLimit, clearAll, selectionDisabled } = useSelection();
  const searchContext = useContext(SearchContext);
  const [expanded, setExpanded] = useState(!!limit);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSelectPage = useCallback(() => {
    handleClose();

    onSelectPage();
  }, [handleClose, onSelectPage]);

  const handleSelectAll = useCallback(() => {
    handleClose();

    // reset advanced selection
    if (searchContext?.[kind].maxContactsPerCompany !== undefined) {
      searchContext?.[kind].setMaxContactsPerCompany(undefined);
    }

    selectAll();
  }, [handleClose, kind, searchContext, selectAll]);

  const handleClearAll = useCallback(() => {
    handleClose();

    clearAll();
  }, [clearAll, handleClose]);

  const handleExpandClick = useCallback(() => {
    setExpanded((state) => !state);
  }, []);

  const handleAdvancedSelectinChange = useCallback(
    (advancedSelection?: AdvancedSelection) => {
      handleClose();

      setLimit(advancedSelection?.limit);
    },
    [handleClose, setLimit],
  );

  return (
    <DropdownMenu
      open={open}
      onCloseAction={handleClose}
      buttonType="custom"
      customButton={
        <IconButton
          className="hover:bg-primary-50"
          size="sm"
          theme="default"
          Icon={ChevronDownIcon}
          onClick={handleOpen}
        />
      }
      origin="bottom-left"
      dropdownClassName="py-2 max-h-fit" // this is to overrde max-h-64 within dropdown
    >
      <DropdownMenu.Item onClick={handleSelectPage} disabled={selectionDisabled}>
        Select entire page
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={handleSelectAll} className="group">
        Select all records{' '}
        <span className="text-white group-hover:text-gray-400">
          (Max {Number(100000).toLocaleString()})
        </span>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={handleClearAll}>Clear your selection</DropdownMenu.Item>
      <div className="border-b my-2" />
      <DropdownMenu.Item
        className={classNames('min-w-[300px]', { 'bg-gray-100': expanded })}
        onClick={handleExpandClick}
      >
        Advanced selection
      </DropdownMenu.Item>

      <Transition.Collapse show={expanded}>
        <div onKeyDown={stopPropagation}>
          <AdvancedSelectionForm
            kind={kind}
            onCancel={handleClose}
            advancedSelection={{ limit }}
            onChangeAdvancedSelection={handleAdvancedSelectinChange}
            maxContactsPerCompany={searchContext?.[kind].maxContactsPerCompany}
            onChangeMaxContactsPerCopany={searchContext?.[kind].setMaxContactsPerCompany}
            excludeList={searchContext?.[kind].excludeList}
            onChangeExcludeList={searchContext?.[kind].setExcludeList}
          />
        </div>
      </Transition.Collapse>
    </DropdownMenu>
  );
};

export default SelectionMenu;
