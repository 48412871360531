import { isPlainObject, last, map, omit } from 'lodash';
import { Account } from 'types';
import * as organizationApi from 'api/organization';
import { GTMType } from './useGTM';
import { DataLayerData, ResetDataLayerData } from 'types/tracking';
import { ChameleonType } from './Chameleon/ChameleonTracker';

interface Trackers {
  chameleon: ChameleonType;
  gtm: GTMType;
}

const tracking = {
  // option is for deepmerge used internally by react-tracking
  mergeOptions: {
    // if isMergeableObject not present it will try to merge trackers
    // that was the only vay to pass trackers to dispatch function from the Tracking component
    isMergeableObject: isPlainObject,
  },
  dispatch: async ({ trackers, ...data }: { [x: string]: any; trackers?: Trackers }) => {
    if (process.env.NODE_ENV === 'production') {
      const { type } = data;

      if (type === 'auth') {
        const { user }: { user?: Account } = data;

        if (user) {
          const role = last(user.roles);
          const userData: Partial<DataLayerData> = {
            event: 'user_info',
            userId: user.username,
            orgId: user.organization.id,
            userType: role,
            totalCreditsUsed: user.bulk_credits?.used,
            inviteDate: user.created_at,
          };

          trackers?.gtm.push(userData);

          // push login event
          trackers?.gtm?.push({
            event: 'login',
            method: 'email',
          });

          const organization = await organizationApi.fetchCurrent();
          const orgData: Partial<DataLayerData> = {
            event: 'user_info',
            orgPlan: organization.subscriptions?.[0]?.plan,
            orgPlans: map(organization.subscriptions, 'plan'),
            orgSeats: organization.active_accounts?.quota,
          };

          trackers?.gtm.push(orgData);

          trackers?.chameleon.identify(user.username, {
            email: user.email,
            created: user.created_at,
            name: user.name,
            role,
            logins: user.number_of_signins,
            credits_quota: user.bulk_credits?.quota,
            credits_left: user.bulk_credits?.available,
            company: {
              uid: organization.id,
              created: organization.created_at,
              name: organization.name,
              plan: organization.subscriptions?.[0]?.plan,
              plans: map(organization.subscriptions, 'plan'),
              users_active:
                organization.active_accounts && organization.active_accounts.quota !== -1
                  ? organization.active_accounts.quota - organization.active_accounts.available
                  : undefined,
              users_quota: organization.active_accounts?.quota,
              users_left: organization.active_accounts?.available,
              credits_quota: organization.bulk_credits_usage?.quota,
              credits_left: organization.bulk_credits_usage?.available,
            },
          });
        } else {
          // on signout de-identify the user and stop Chameleon from operating on the page
          trackers?.chameleon.clear();

          // on signout reset data in data layer
          const clearData: ResetDataLayerData = {
            event: 'user_info',
            userId: undefined,
            orgId: undefined,
            userType: undefined,
            totalCreditsUsed: undefined,
            inviteDate: undefined,
            orgPlan: undefined,
            orgPlans: undefined,
            orgSeats: undefined,
            numberRecords: undefined,
            visibility: undefined,
            recordType: undefined,
            countLists: undefined,
            numberInvites: undefined,
            countSearches: undefined,
            numberCredits: undefined,
            consumptionMethod: undefined,
            numberFilters: undefined,
            notify: undefined,
            upload: undefined,
            listView: undefined,
          };

          trackers?.gtm.push(clearData);
        }
      } else if (type === 'event') {
        const event = omit(data, 'type');

        trackers?.gtm.push(event);
      }
    } else {
      // eslint-disable-next-line no-console
      // console.info(data);
    }
  },
};

export default tracking;
