import { useCallback, useState } from 'react';
import { Button } from '@demandscience/ui';

import useAuth from './useAuth';

export interface SignoutProps {
  children: React.ReactNode;
  component?: React.ElementType;
  onError: (message: string) => void;
  onSignout?: () => void;
}

const Signout: React.FC<SignoutProps> = ({
  component: Component = Button,
  onError,
  onSignout,
  ...props
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { signOut } = useAuth();

  const handleSignout = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await signOut();

      if (onSignout) onSignout();
    } catch (e: any) {
      const { message } = e;
      setIsSubmitting(false);

      if (onError) onError(message);
    }
  }, [onError, onSignout, signOut]);

  return <Component onClick={handleSignout} disabled={isSubmitting} {...props} />;
};

export default Signout;
