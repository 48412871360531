import { useCallback, useState } from 'react';
import { Button, Modal, ModalProps } from '@demandscience/ui';
import {
  AdvancedSelection,
  Filters,
  ListRecordsAction,
  SortBy,
  UpdateListContentPayload,
} from 'types';
import { AxiosError } from 'axios';
import useMutateList from 'components/Lists/useMutateList';

interface DeleteActionOverlayProps extends Omit<ModalProps, 'children'> {
  advanced_selection?: AdvancedSelection;
  filters?: Filters;
  listId: string;
  onSuccess?: () => void;
  sort?: SortBy;
}

const DeleteActionOverlay = ({
  listId,
  filters,
  sort,
  advanced_selection,
  open,
  onClose,
  onSuccess,
}: DeleteActionOverlayProps) => {
  const [apiError, setApiError] = useState<string | null>(null);
  const { updateContent } = useMutateList();
  const { mutateAsync } = updateContent;

  const handleRemove = useCallback(async () => {
    try {
      const payload: UpdateListContentPayload = {
        source_list_id: listId,
        method: ListRecordsAction.Remove,
        filters,
        sort,
        advanced_selection,
      };

      await mutateAsync(payload);

      if (onSuccess) onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        setApiError(e.response?.data?.error || `Unable to remove list records`);
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  }, [advanced_selection, filters, listId, mutateAsync, onSuccess, sort]);

  return (
    <Modal
      aria-labelledby="delete-list-dialog-title"
      className="max-w-md sm:max-w-md overflow-visible"
      rootClassName="z-30"
      open={open}
      onClose={onClose}
    >
      <Modal.Title id="delete-list-dialog-title" className="mb-8 break-words">
        Are you sure you want to delete list record(s)?
      </Modal.Title>
      <div className="space-y-4 mt-10">
        {apiError && <div className="text-error-500 text-center mt-8">{apiError}</div>}
      </div>

      <div className="flex justify-end space-x-4 mt-4">
        <Button type="button" borderless onClick={onClose}>
          Cancel
        </Button>
        <Button type="button" onClick={handleRemove} disabled={updateContent.isLoading}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteActionOverlay;
