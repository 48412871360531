import { useMutation } from '@tanstack/react-query';
import { aiChatSearch } from 'api/aiSearch';
import { useState } from 'react';
import { ChatCompletionsParams, Message } from 'types/aissistant';

const useChatCompletions = () => {
  const [chatGPTResp, setChatGPTResp] = useState<Message | undefined>();
  const [error, setError] = useState<any>();

  const mutation = useMutation(aiChatSearch);

  const sendChatMessage = async (payload: ChatCompletionsParams) => {
    setError(undefined);
    setChatGPTResp(undefined);

    try {
      const resp = await mutation.mutateAsync(payload);

      if (resp.choices.length > 0) {
        setChatGPTResp(resp.choices[0].message);
      }
    } catch (err) {
      setError(err);
    }
  };

  return { sendChatMessage, isLoading: mutation.isLoading, chatGPTResp, error };
};

export default useChatCompletions;
