import { ReactNode, useCallback, useMemo } from 'react';

import { Toast, toast } from '@demandscience/ui';
import SnackbarContext, { SnackbarContextInterface } from './SnackbarContext';
import { Severity } from '@demandscience/ui/dist/types';

interface SnackbarProviderProps {
  children: ReactNode;
}

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const showMessage = useCallback(
    (value: string, severity: Severity = 'neutral', options?: any) => {
      toast({ subtitle: value, severity, ...options });
    },
    [],
  );

  const value: SnackbarContextInterface = useMemo(() => ({ showMessage }), [showMessage]);

  return (
    <>
      <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>
      <Toast position="top-right" />
    </>
  );
};

export default SnackbarProvider;
