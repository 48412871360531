import {
  CompanyFilterName,
  ContactFilterName,
  DataFilterName,
  FilterName,
  ListFilterName,
  SalesSignalsFilterName,
} from 'types/filters';

export const labels = {
  [DataFilterName.ConfirmedConnect]: 'Confirmed connect',
  [DataFilterName.ContactDetails]: 'Contact details',
  [DataFilterName.DataAccuracy]: 'Data accuracy (Coming soon)',
  [CompanyFilterName.CompanyEmployees]: 'Headcount',
  [CompanyFilterName.CompanyIndustry]: 'Industry',
  [CompanyFilterName.CompanyLocation]: 'Company location',
  [CompanyFilterName.CompanyName]: 'Company name',
  [CompanyFilterName.CompanyRevenue]: 'Revenue',
  [CompanyFilterName.CompanySpeciality]: 'Specialities',
  [ContactFilterName.ContactLocation]: 'Contact location',
  [ContactFilterName.ContactName]: 'Contact name',
  [ContactFilterName.JobFunction]: 'Department',
  [ContactFilterName.JobLevel]: 'Job level',
  [ContactFilterName.JobTitle]: 'Job title',
  [SalesSignalsFilterName.InstallTopic]: 'Technology install',
  [SalesSignalsFilterName.IntentTopic]: 'Buyer intent',
  [ListFilterName.ExclusionList]: 'Suppression list',
  [ListFilterName.InclusionList]: 'Target list',
  Personas: 'Personas',
  ContactCountry: 'Contact Country',
};

interface FilterLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  name: FilterName | 'Personas' | 'ContactCountry';
}

const FilterLabel = ({ name, ...props }: FilterLabelProps) => {
  if (name === ContactFilterName.ContactId) {
    return null;
  }

  const { [name]: label = name } = labels;

  return <div {...props}>{label}</div>;
};

export default FilterLabel;
