import { useQuery } from '@tanstack/react-query';

import { fetchSearchCustomization } from 'api/account';

const useSearchCustomization = (options?: Record<string, any>) => {
  const query = useQuery(['searchCustomization'], fetchSearchCustomization, {
    keepPreviousData: true, // prevent data swap until new data loaded
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
    ...options,
  });

  return query;
};

export default useSearchCustomization;
