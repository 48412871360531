import { AxiosError } from 'axios';
import Alert from 'components/Alert';
import { Button } from '@demandscience/ui';
import { getRecordsLabel } from 'utils/string';
import PlusIcon from '@demandscience/ui/icons/plus';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import ListCombobox from 'components/Lists/ListCombobox';
import useMutateList from 'components/Lists/useMutateList';
import CreateListControl from 'components/Lists/CreateListControl';
import {
  CopyOrMoveToList,
  ListRecordsLimit,
  ModelType,
  Show,
  UpdateListContentPayload,
} from 'types';

interface CopyOrMoveActionFormProps extends CopyOrMoveToList {
  count: number;
  onCancel: () => void;
  onSuccess: () => void;
}

const CopyOrMoveActionForm = ({
  method,
  listId,
  filters,
  sort,
  advanced_selection,
  type,
  onSuccess,
  onCancel,
}: CopyOrMoveActionFormProps) => {
  const [selectedListId, setSelectedListId] = useState<string | undefined>();
  const [overLimit, setOverLimit] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;
  const { updateContent } = useMutateList();
  const limit = ListRecordsLimit[ModelType.Company];

  const handleListSelection = useCallback((id: string) => {
    setSelectedListId(id);
  }, []);

  const onSubmit: SubmitHandler<{}> = async () => {
    setApiError(null);

    if (!selectedListId || !filters) {
      return;
    }

    const payload: UpdateListContentPayload = {
      source_list_id: listId,
      target_list_id: selectedListId,
      method,
      filters,
      sort,
      advanced_selection,
    };

    try {
      await updateContent.mutateAsync(payload);

      onSuccess();
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.error_code === 'limit_exceeded') {
          setOverLimit(true);
        } else {
          setApiError(e.response?.data?.error || `Unable to ${method} list content`);
        }
      } else {
        setApiError('Unexpected error, please try again later');
      }
    }
  };

  useEffect(() => {
    setOverLimit(false);
    setApiError(null);
  }, [selectedListId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-4">
        {overLimit && (
          <Alert
            severity="warning"
            message={`A list can contain a max of ${limit.toLocaleString()} ${getRecordsLabel(
              type,
            )}. Your selection is adding too many new contacts to this list. Select less contacts or choose another list.`}
          />
        )}
        <ListCombobox
          value={selectedListId}
          onChange={handleListSelection}
          records_type={type}
          show={Show.Editable}
          showCounts
          exclude={[listId]}
        />
        <CreateListControl
          as={Button}
          theme="primary"
          borderless
          size="md"
          leftIcon={<PlusIcon className="w-5 h-5" />}
          type={type}
          onSuccess={handleListSelection}
        >
          Create new list
        </CreateListControl>

        {apiError && <div className="text-error-500 text-center">{apiError}</div>}
      </div>
      <div className="flex justify-end space-x-2 mt-7">
        {!overLimit && (
          <>
            <Button type="button" onClick={onCancel} borderless size="md">
              Cancel
            </Button>
            <Button
              type="submit"
              theme="primary"
              size="md"
              disabled={isSubmitting || !selectedListId}
              className="capitalize"
            >
              {method}
            </Button>
          </>
        )}
        {overLimit && (
          <Button type="button" theme="primary" size="md" onClick={onCancel}>
            Change selection
          </Button>
        )}
      </div>
    </form>
  );
};

export default CopyOrMoveActionForm;
