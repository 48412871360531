import { useQuery } from '@tanstack/react-query';

import { fetchAllViews } from 'api/dataModules';

const useFetchViews = (options?: Record<string, any>) => {
  const query = useQuery(['views'], fetchAllViews, {
    keepPreviousData: true, // prevent data swap until new data loaded
    ...options,
  });

  return query;
};

export default useFetchViews;
