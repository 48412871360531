import CompanyAvatar from 'components/Company/CompanyAvatar';
import CompanyName from 'components/Company/CompanyName';
import ContactAvatar from 'components/Contact/ContactAvatar';
import ContactLocation from 'components/Contact/ContactLocation';
import ContactName from 'components/Contact/ContactName';
import ContactSocialProfiles from 'components/Contact/ContactSocialProfiles';
import JobFunction from 'components/Contact/JobFunction';
import JobLevel from 'components/Contact/JobLevel';
import JobTitle from 'components/Contact/JobTitle';
import { TablCellProps, TableCell } from 'components/Layout/Table';
import { CompanyTableColumn, Contact, ContactTableColumn, isContactTableColum } from 'types';
import { stopPropagation } from 'utils/event';
import ContactDetailsCell from './ContactDetailsCell';
import DataGridTableCompanyCell from './DataGridTableCompanyCell';

interface DataGridTableCellProps extends TablCellProps {
  column: ContactTableColumn | CompanyTableColumn;
  displayContactDetailsOnTop?: boolean;
  row: Contact;
}

const DataGridTableCell = ({
  column,
  row,
  displayContactDetailsOnTop,
  ...props
}: DataGridTableCellProps) => {
  const company = row.companies[0];

  if (!isContactTableColum(column)) {
    return <DataGridTableCompanyCell column={column} row={company} {...props} />;
  }

  if (column === 'contact_details') {
    return (
      <TableCell width={32} className="flex justify-center" {...props}>
        <ContactDetailsCell
          className="-ml-2"
          row={row}
          displayContactDetailsOnTop={displayContactDetailsOnTop}
        />
      </TableCell>
    );
  }

  if (column === 'contact_name') {
    return (
      <TableCell className="z-[1] left-[52px]" sticky width={64} {...props}>
        <div className="w-full flex flex-row items-center gap-4">
          <ContactAvatar className="w-8 h-8 text-sm" row={row} colored />
          <ContactName className="grow ellipsis text-gray-600" row={row} />
          <ContactSocialProfiles
            iconWrapperClassName="p-0"
            useBrandColors
            inline
            row={row}
            onClick={stopPropagation}
          />
        </div>
      </TableCell>
    );
  }

  if (column === 'company') {
    return (
      <TableCell width={64} {...props}>
        <div className="w-full flex flex-row items-center gap-4">
          <CompanyAvatar className="w-8 h-8 text-sm rounded" row={company} type="square" colored />
          <CompanyName className="ellipsis text-gray-600" row={company} clickable />
        </div>
      </TableCell>
    );
  }

  if (column === 'contact_location') {
    return (
      <TableCell width={64} {...props}>
        <ContactLocation className="ellipsis text-gray-600" inline row={row} />
      </TableCell>
    );
  }

  if (column === 'job_title') {
    return (
      <TableCell width={64} {...props}>
        <JobTitle className="ellipsis text-gray-600" row={row} />
      </TableCell>
    );
  }

  if (column === 'job_function') {
    return (
      <TableCell width={64} {...props}>
        <JobFunction className="text-gray-600" inline row={row} />
      </TableCell>
    );
  }

  if (column === 'job_level') {
    return (
      <TableCell width={32} {...props}>
        <JobLevel className="text-gray-600" inline row={row} />
      </TableCell>
    );
  }

  return <TableCell width={32} {...props} />;
};

export default DataGridTableCell;
