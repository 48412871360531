import classNames from 'classnames';

interface IconBoxProps {
  children: React.ReactNode;
  className: string;
}

const IconBox: React.FC<IconBoxProps> = ({ className, children }) => (
  <div className={classNames('flex place-items-center justify-center rounded', className)}>
    {children}
  </div>
);

export default IconBox;
