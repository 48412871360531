import { ChangeEventHandler, useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  isEmpty,
  debounce,
  map,
  find,
  isEqual,
  reject,
  flatten,
  uniq,
  split,
  forEach,
  omit,
  some,
} from 'lodash';
import CheckboxOption from '../Filter/Dropdown/CheckboxMenuItem';
import TextMenuItem from '../Filter/Dropdown/TextMenuItem';
import { FilterProps, Option } from './types';
import {
  SalesSignalsFilterName,
  IntentTopicFilter as IntentTopicFilterType,
  badgeDisplayCap,
  FreeTextFilter,
} from 'types/filters';
import useFilter from './useFilter';
import RangeMenuItem from 'components/Filter/Dropdown/RangeMenuItem';
import useIntentAutocomplete from './Autocomplete/useIntentAutocomplete';
import FilterInput from './FilterInput';
import Inline from 'components/Filter/Inline';
import { getFreetextLabel, getIntentTopicLabel } from './utils';

type IntentType = IntentTopicFilterType['intent'][number];

const getBadgeProps = (value: any) => {
  const label = getIntentTopicLabel(value);

  return {
    key: label,
    label,
    exclude: value.exclude,
  };
};

const IntentTopicFilter = ({ clearInputOnChange, dense }: FilterProps) => {
  const name = SalesSignalsFilterName.IntentTopic;
  const freeSolo = true;
  const placeholder = 'Enter intent name';

  const { ref, inView } = useInView();
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const { value: filterValue, onChange, onClear } = useFilter(name);
  const [range, setRange] = useState<number[] | undefined>(
    filterValue ? [filterValue.range_min, filterValue.range_max] : undefined,
  );
  const value = useMemo(() => filterValue?.intent || [], [filterValue]);
  const { data, isFetching, isError, hasNextPage, fetchNextPage } = useIntentAutocomplete(
    { value: query },
    {
      keepPreviousData: false,
      retry: false,
    },
  );
  const suggestions = flatten(data?.pages);

  const freeTextOptions: Option<FreeTextFilter>[] = useMemo(() => {
    let res: Option<FreeTextFilter>[] = [];

    if (freeSolo && query !== '') {
      const tokens = uniq(split(query, /\s*,\s*/));

      forEach(tokens, (token, index) => {
        if (token) {
          const dataFromValue = find(value, (v) =>
            isEqual(omit(v, 'exclude'), { freetext: token, topic: token }),
          ) as FreeTextFilter;

          const optionValue = dataFromValue || {
            freetext: token,
            topic: token,
          };
          const label = getFreetextLabel(optionValue);

          res = [...res, { id: `freetext${index}`, value: optionValue, label }];
        }
      });
    }

    return res;
  }, [freeSolo, query, value]);

  let options = suggestions || [];

  // workaround so combobox comparison by reference works (against the values)
  options = map(options, (option) => {
    const dataFromValue = find(value, (v) => isEqual(v, option.value));

    if (dataFromValue) {
      return {
        ...option,
        value: dataFromValue,
      };
    }

    return option;
  });

  const handleInputClear = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }

    setQuery('');
  }, []);

  const handleChange = useCallback(
    (newValue: IntentType[]) => {
      if (isEmpty(newValue)) {
        onClear();
      } else {
        onChange({
          range_min: range ? range[0] : 0,
          range_max: range ? range[1] : 100,
          intent: newValue,
        } as IntentTopicFilterType);
      }

      // focus input on change
      inputRef.current?.focus();

      // workaround to clear filter input and the suggestions dropdown
      if (clearInputOnChange) {
        handleInputClear();
      }
    },
    [clearInputOnChange, handleInputClear, onChange, onClear, range],
  );

  const handleRangeChange = debounce((range: number[]) => {
    setRange(range);

    if (filterValue) {
      onChange({
        ...filterValue,
        range_min: range[0],
        range_max: range[1],
      } as IntentTopicFilterType);
    }
  }, 150);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const {
      target: { value },
    } = e;

    setQuery(value);
  }, []);

  const handleDelete = useCallback(
    (deleted: any) => {
      const newValue = reject(value, deleted);

      handleChange(newValue);
    },
    [handleChange, value],
  );

  const handleClear = useCallback(() => {
    handleInputClear();

    onClear();
  }, [handleInputClear, onClear]);

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleEnterKey = useCallback(() => {
    const missing = reject(freeTextOptions, (option) => some(value, option.value));

    if (isEmpty(missing)) {
      // all are present, remove them all
      const newIntent = [
        ...(reject(value, (val) =>
          some(freeTextOptions, (option) => option.value.freetext === val.freetext),
        ) || []),
      ];

      if (isEmpty(newIntent)) {
        onClear();
      } else {
        onChange({
          ...filterValue,
          range_min: range ? range[0] : 0,
          range_max: range ? range[1] : 100,
          intent: newIntent,
        });
      }
    } else {
      // add those missing
      onChange({
        ...filterValue,
        range_min: range ? range[0] : 0,
        range_max: range ? range[1] : 100,
        intent: [
          ...(filterValue?.intent || []),
          ...map(missing, (m) => ({ topic: m.value.freetext, freetext: m.value.freetext })),
        ],
      });
    }
  }, [filterValue, freeTextOptions, onChange, onClear, range, value]);

  useEffect(() => {
    if (inView) {
      handleLoadMore();
    }
  }, [inView, handleLoadMore]);

  return (
    <Inline
      value={value}
      onChange={handleChange}
      multiple
      button={(props) => (
        <FilterInput
          {...props}
          ref={inputRef}
          name={name}
          dense={dense}
          onClear={handleClear}
          onDelete={handleDelete}
          onInputChange={handleInputChange}
          onInputClear={handleInputClear}
          placeholder={placeholder}
          getBadgeProps={getBadgeProps}
          onEnterKey={handleEnterKey}
          badgeDisplayCap={badgeDisplayCap}
        />
      )}
      footer={
        <>
          <div className="border-t mx-4" />
          <TextMenuItem>Select an interest score range</TextMenuItem>
          <RangeMenuItem
            min={0}
            max={100}
            value={filterValue ? [filterValue.range_min, filterValue.range_max] : []}
            onChange={handleRangeChange}
          />
        </>
      }
    >
      {isError && <TextMenuItem>Error fetching filtering values</TextMenuItem>}
      {!isError && !isEmpty(query) && (
        <>
          {!isFetching && isEmpty(freeTextOptions) && isEmpty(options) && (
            <TextMenuItem>No match for &quot;{query}&quot;</TextMenuItem>
          )}
          {map(freeTextOptions, (option) => (
            <CheckboxOption key={option.id} option={option} />
          ))}
          {isFetching && <TextMenuItem>Loading...</TextMenuItem>}
          {map(options, (option) => (
            <CheckboxOption key={option.id} option={option} />
          ))}
          {hasNextPage && (
            <TextMenuItem ref={ref} textClassName="text-gray-500">
              Loading...
            </TextMenuItem>
          )}
        </>
      )}
    </Inline>
  );
};

export default IntentTopicFilter;
