import api from 'api';

import {
  RecentSearchesResponse,
  CreateRecentSearchPayload,
  CreateRecentSearchResponse,
  UpdateRecentSearchPayload,
} from 'types';

export const createRecentSearch = (
  payload: CreateRecentSearchPayload,
): Promise<CreateRecentSearchResponse> => api.post('/recent_search', payload);

export const updateRecentSearch = ({ id, ...data }: UpdateRecentSearchPayload): Promise<void> =>
  api.patch(`/recent_search/${id}`, data);

export const deleteRecentSearch = (id: string): Promise<void> => api.delete(`/recent_search/${id}`);

export const fetchRecentSearches = (): Promise<RecentSearchesResponse> =>
  api.get(`/account/me/recent_search`);
