import { useEffect, useState, useCallback } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { Button, ToggleSwitch, Modal, Spinner } from '@demandscience/ui';
import showPopup from 'pages/Dashboard/Settings/Integrations/showPopup';
import useMutateIntegration from 'components/Organization/useMutateIntegration';
import useSnackbar from 'components/Snackbar/useSnackbar';
import { IntegrationOptions, MappingOptions, DataIntegrationSchema, DataTypeOptions } from 'types';
import { AxiosError } from 'axios';
import ConfirmOverlay from 'components/ConfirmOverlay';

export interface ToggleIntegrationProps {
  data?: DataIntegrationSchema;
  icon?: React.ReactElement;
  integrationType: IntegrationOptions;
  isRefetching: boolean;
  mappingType: MappingOptions;
  refetch: () => Promise<UseQueryResult>;
  text?: string;
}

const ToggleIntegration = ({
  icon,
  text,
  integrationType,
  mappingType,
  data,
  refetch,
  isRefetching,
}: ToggleIntegrationProps) => {
  const { showMessage } = useSnackbar();
  const { configure, enable, disable } = useMutateIntegration({ type: integrationType });
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [configured, setConfigured] = useState(false);

  let dataType: DataTypeOptions;
  switch (mappingType) {
    case 'contacts to contacts':
      dataType = `${integrationType}-contacts-to-contacts`;
      break;
    case 'contacts to leads':
      dataType = `${integrationType}-contacts-to-leads`;
      break;
    case 'companies to accounts':
      dataType = `${integrationType}-companies-to-accounts`;
      break;
    case 'companies to companies':
      dataType = `${integrationType}-companies-to-companies`;
      break;
  }

  // First time you configure
  const isConfigured = data && !!data?.[dataType];
  const isLoading = configure.isLoading || enable.isLoading || disable.isLoading;

  // Once you've configured
  const isEnabled = configured && !!data?.[dataType]?.enabled;

  const handleConfigure = useCallback(async () => {
    try {
      const response = await configure.mutateAsync({ type: integrationType, mapping: mappingType });

      if (response.auth_url) {
        showPopup(response.auth_url, showMessage, setToggle, setConfigured, configured, refetch);
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to configure, please try again', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  }, [configure, integrationType, mappingType, showMessage, refetch, configured]);

  const handleEnable = useCallback(async () => {
    try {
      const response = await enable.mutateAsync({ type: integrationType, mapping: mappingType });

      if (response.auth_url) {
        showPopup(response.auth_url, showMessage, setToggle, setConfigured, configured, refetch);
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to enable, please try again', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  }, [enable, integrationType, mappingType, showMessage, refetch, configured]);

  const handleDisable = useCallback(async () => {
    try {
      await disable.mutateAsync({ type: integrationType, mapping: mappingType });

      setToggle(false);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to disable, please try again', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  }, [disable, integrationType, mappingType, showMessage]);

  const handleDisableExportOption = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleToggle = useCallback(() => {
    if (!configured) {
      handleConfigure();
    } else if (configured && toggle) {
      handleDisableExportOption();
    } else if (configured && !toggle) {
      handleEnable();
    }
    refetch();
  }, [configured, handleConfigure, handleDisableExportOption, handleEnable, toggle, refetch]);

  useEffect(() => {
    if (isConfigured) {
      setConfigured(true);
    } else {
      setConfigured(false);
    }

    if (isEnabled) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  }, [isEnabled, isConfigured]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-9">
        {icon}
        <p>{text}</p>
      </div>
      <div className="flex items-center">
        {configured && (
          <Button
            disabled={!toggle || isLoading || isRefetching}
            borderless
            className="mr-3"
            type="button"
            theme="primary"
            onClick={handleConfigure}
          >
            Edit mapping
          </Button>
        )}
        <div className="flex justify-center items-center relative">
          {isLoading && (
            <div className="absolute w-4 h-4 left-11 bottom-1">
              <Spinner size="sm" />
            </div>
          )}

          <ToggleSwitch
            enabled={toggle}
            onChange={handleToggle}
            size="sm"
            theme="primary"
            disabled={isLoading}
          />
        </div>
        <ConfirmOverlay
          open={open}
          onClose={handleClose}
          onConfirm={handleDisable}
          confirmLabel="YES, disable"
        >
          <Modal.Title className="mb-4">Are you sure you want to disable this mapping?</Modal.Title>
        </ConfirmOverlay>
      </div>
    </div>
  );
};

export default ToggleIntegration;
