import { useCallback, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TextField, Button } from '@demandscience/ui';

import useSnackbar from 'components/Snackbar/useSnackbar';
import useMutateAccount from './useMutateAccount';
import { UpdateNamePayload } from 'types';
import { AxiosError } from 'axios';
import { size } from 'lodash';

export interface NameEditorProps {
  disabled?: true;
  value: string;
}

const NameEditor = ({ value, disabled }: NameEditorProps) => {
  const [edit, setEdit] = useState(false);
  const { control, handleSubmit, setValue, formState } = useForm<UpdateNamePayload>();
  const { updateName } = useMutateAccount();
  const { showMessage } = useSnackbar();
  const { isSubmitting, errors } = formState;

  const handleEditClick = () => {
    if (edit === false) {
      setEdit((state) => !state);
    }
  };

  const handleCancel = useCallback(() => {
    setEdit(false);
    if (formState.isDirty) {
      setValue('name', value);
    }
  }, [formState.isDirty, setValue, value]);

  const onSubmit: SubmitHandler<UpdateNamePayload> = async (data) => {
    try {
      await updateName.mutateAsync(data);

      setEdit(false);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        showMessage(e.response?.data?.error || 'Unable to update a name', 'error');
      } else {
        showMessage('Unexpected error, please try again later', 'error');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        defaultValue={value}
        control={control}
        render={({ field }) => (
          <TextField
            label="Name"
            trailingLabel={`${size(field.value)}/100 characters`}
            required
            error={errors.name?.message}
            variant="outlined"
            type="text"
            placeholder="Enter your full name"
            onClick={handleEditClick}
            disabled={disabled}
            {...field}
          />
        )}
        rules={{
          required: 'Required field',
          maxLength: {
            value: 100,
            message: '100 characters max',
          },
        }}
      />

      {edit && (
        <div className="flex items-center justify-end mt-4 gap-2">
          <Button borderless theme="primary" type="button" outline onClick={handleCancel}>
            Cancel
          </Button>
          <Button theme="primary" type="submit" disabled={isSubmitting || !formState.isDirty}>
            Save
          </Button>
        </div>
      )}
    </form>
  );
};

export default NameEditor;
